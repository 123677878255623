//@ts-nocheck
import React from "react";
import FooterDialogController, { Props } from "./FooterDialogController.web";
import {
  withStyles,
  Grid,
  Typography,
  createStyles,
  Button,
  Paper,
  Link,
  Box,
  Container,
  TextField,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
} from "@material-ui/core";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { boatImage } from "../../user-profile-basic/src/assets";
import { withRouter } from "react-router";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
import { Formik, Form } from "formik";
import * as Yup from "yup";
const Schema = Yup.object().shape({
  message: Yup.string().required("This field is required."),
});
class FooterDialog extends FooterDialogController {
  constructor(props: Props) {
    super(props);
  }
  goToSelectedRoute = (routeName) => {
    const { history } = this.props;
    history.push(routeName);
  };
  closeDialog = (data) => {
    const { onSubmit } = this.props;
    onSubmit(data);
  };
  async componentDidMount() {
    // const authToken = await StorageProvider.get("authToken");
    // if(authToken){
    //     this.setState({
    //         authToken : authToken
    //     })
    // }
    this.getContent();
  }
  render() {
    const { classes } = this.props;
    return (
      <Grid container justify="space-between" spacing={2}>
        <Grid item xs={12}>
          {this.state.data && this.state.data.length > 0 ? (
            <>
              {this.state.data.map((db) => {
                return (
                  <Typography color="textPrimary" variant="body1">
                    {db.attributes.description}
                  </Typography>
                );
              })}
            </>
          ) : (
            <Typography align="center" color="textPrimary" variant="h5">
              No Data to Show
            </Typography>
          )}
        </Grid>
        <Grid item xs={12}>
          <Grid container justify="flex-end">
            <Grid item md={2} xs={3}>
              <Button
                onClick={() => {
                  this.props.onCancel();
                }}
                color="primary"
                variant="contained"
              >
                ok
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}
const FooterDialogWithRouter = withRouter(FooterDialog);
const FooterDialogWithToast = withToast(FooterDialogWithRouter);
const FooterDialogWithLoader = withLoader(FooterDialogWithToast);
const FooterDialogWithAlertBox = withAlertBox(FooterDialogWithLoader);

export default withStyles((theme) =>
  createStyles({
    ownerName: {
      fontFamily: "Montserrat",
      fontSize: "12px",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.19,
      letterSpacing: "normal",
      color: "#000",
    },
    ownerNameHeading: {
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.19,
      letterSpacing: "normal",
      color: "#343a40",
    },
  })
)(FooterDialogWithAlertBox);
