//@ts-nocheck
import React from "react";
import BoatComparisonController, {
  Props,
} from "./BoatComparisonController.web";
import {
  withStyles,
  Grid,
  Typography,
  createStyles,
  Button,
  Paper,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar
} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { withRouter } from "react-router";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
import { boatImage, boatImageDemo1, boatImageDemo2 } from "./assets";

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#ffffff",
      color: "#545454",
    },
  })
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: "#f5f5f5",
      },
    },
  })
)(TableRow);
const HeadingTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: "#f3fcfe",
    },
  })
)(TableRow);
class BoatComparison extends BoatComparisonController {
  constructor(props: Props) {
    super(props);
  }
  async componentDidMount() {
    const authToken = await StorageProvider.get("authToken");
    const boatCompareList = await StorageProvider.get("boatCompareList");
    if (boatCompareList && boatCompareList.length > 0) {
      this.setState(
        {
          authToken: authToken,
          boatCompareList: JSON.parse(boatCompareList),
          duration : this.props.history.location.state.duration
        },
        () => {
          this.getComparedBoatDetails();
        }
      );
    }
  }
  goToSelectedRoute = (routeName,boat) => {
    const { history } = this.props;
    history.push({pathname : routeName,state : {boatId : boat.id}});
  };
  removeComparedItem = (boat) => {
    const boatCompareList = [...this.state.boatCompareList].filter((item)=>{
          return item.id != boat.id
      })
      this.setState({
        boatCompareList
      },async ()=>{
        await StorageProvider.set("boatCompareList",JSON.stringify(this.state.boatCompareList))
      })
  }
  saveCureentPathIfNotLoggedIn = async (action) => {
    if(action && action?.actionType === 'bookBoat'){
      await StorageProvider.set("currentPath","/bookBoat");
      await StorageProvider.set("currentRouteState",JSON.stringify({ boatId: this.state.boatId }));
    } else {
      const currentPath = this.props?.history?.location?.pathname;
      let currentRouteState = this.props?.history?.location?.state || {};
      if(action){
        currentRouteState = action && {...currentRouteState,...action}
      }
      await StorageProvider.set("currentPath",currentPath);
      await StorageProvider.set("currentRouteState",JSON.stringify(currentRouteState));
    }
    
    this.props.history.push("/basicAuth")
  }
  getPrice = (boatPrice1 = {}) => {
    const boatPrice = boatPrice1 ? boatPrice1 : {}
    if(this.state.duration){
      if(this.state.duration === 'half_day'){
        return `$ ${boatPrice["half_day_rate"] || "-"}`
      } else if(this.state.duration === 'full_day'){
        return `$ ${boatPrice["full_day_rate"] || "-"}`
      } else if(this.state.duration === 'twentyfour_hours'){
        return `$ ${boatPrice["twenty_four_hours_rate"] || "-"}`
      } else if(this.state.duration === 'three_days'){
        return `$ ${boatPrice["three_days_rate"] || "-"}`
      }
      else if(this.state.duration === 'seven_days'){
        return `$ ${boatPrice["seven_days_rate"] || "-"}`
      }
    } else {
      return `$ ${boatPrice["half_day_rate"] || "-"}`
    }
  }
  render() {
    const { classes } = this.props;
    return (
      <Grid
        container
        justify="center"
        spacing={3}
        className={classes.marginTop}
      >
        <Grid item xs={10}>
          {this.state.boatCompareDetails.length > 0 ? <TableContainer style={{maxHeight : 800}} component={Paper}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <StyledTableCell>
                    <Grid
                      container
                      spacing={1}
                      justify="space-between"
                      alignContent="center"
                    >
                      <Grid item xs={12}>
                        <Typography gutterBottom className={classes.comparing}>Comparing</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        {this.state.boatCompareDetails.map((boat) => {
                          return (
                            <Typography key={boat.id} gutterBottom className={classes.comparedBoatName}>
                              -{boat?.attributes?.name}
                            </Typography>
                          );
                        })}
                      </Grid>
                      <Grid item xs={12}>
                        <Typography className={classes.compareBoatCount}>{this.state.boatCompareDetails.length} Boats</Typography>
                      </Grid>
                    </Grid>
                  </StyledTableCell>
                  {this.state.boatCompareDetails.map((boat) => {
                    return (
                      <StyledTableCell key={boat.id}>
                        <Grid
                          container
                          spacing={1}
                          justify="space-between"
                          alignContent="center"
                        >
                          <Grid
                            xs={12}
                            item
                          >
                            <div
                              className={classes.hotelMainImage}
                              style={{backgroundImage : `url(${boat?.attributes?.thumbnail_image?.url ?  boat?.attributes?.thumbnail_image?.url : boatImage})`}}
                              
                            >
                            </div>
                          </Grid>
                          <Grid xs={12} item>
                            <Typography gutterBottom>
                              {boat?.attributes?.name}
                            </Typography>
                          </Grid>
                          <Grid xs={12} item>
                            <div style={{ display: "flex" }}>
                              <div style={{ flexGrow: 1 }}>
                                <Button color="primary" onClick={()=>{
                                  if(this.state.authToken){
                                    this.goToSelectedRoute("/bookBoat",boat);
                                  } else {
                                    this.saveCureentPathIfNotLoggedIn({"actionType":"bookBoat"});
                                  }
                                }} variant="contained">
                                  Book Now
                                </Button>
                              </div>
                              <Typography className={classes.boatPrice} style={{ lineHeight: "40px" }}>
                                {this.getPrice(boat?.attributes?.boat_price)}+
                              </Typography>
                            </div>
                          </Grid>
                        </Grid>
                      </StyledTableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                <HeadingTableRow>
                  <StyledTableCell
                    colSpan={this.state.boatCompareDetails.length + 1}
                  >
                    <Typography
                      gutterBottom
                      className={classes.tableHeaderRowHeading}
                    >
                      Overview
                    </Typography>
                  </StyledTableCell>
                </HeadingTableRow>
                {[
                  "Location",
                  "Duration",
                  "Capacity",
                  "Captain",
                  "Boat Specification",
                ].map((row) => {
                  return (
                    <StyledTableRow key={row}>
                      <StyledTableCell align="center">
                        <Typography
                          gutterBottom
                          className={classes.tableHeaderRowSubHeading}
                        >
                          {row}
                        </Typography>
                      </StyledTableCell>
                      {this.state.boatCompareDetails.map((boat) => {
                        const features =
                          boat.attributes?.tags.map((tag) => {
                            return tag.name;
                          }) || [];
                        return (
                          <StyledTableCell key={boat.id}>
                            <Typography
                              align="center"
                              gutterBottom
                              className={classes.tableHeaderRowValue}
                            >
                              {row === "Location" &&
                                (`${boat?.attributes?.address?.city || "-"},${
                                  boat?.attributes?.address?.state || "-"
                                }` ||
                                  "-")}
                            </Typography>
                            <Typography
                              align="center"
                              gutterBottom
                              className={classes.tableHeaderRowValue}
                            >
                              {row === "Captain" &&
                                (boat?.attributes?.boat_operator === "renter"
                                  ? "No Captain Included"
                                  : "Captain Included")}
                            </Typography>
                            <Typography
                              align="center"
                              gutterBottom
                              className={classes.tableHeaderRowValue}
                            >
                              {row === "Duration" &&
                                (boat.attributes?.address?.name || "-")}
                            </Typography>
                            <Typography
                              align="center"
                              gutterBottom
                              className={classes.tableHeaderRowValue}
                            >
                              {row === "Capacity" &&
                                (boat.attributes?.max_passengers || "-")}
                            </Typography>
                            <Typography
                              align="center"
                              gutterBottom
                              className={classes.tableHeaderRowValue}
                            >
                              {row === "Boat Specification" &&
                                (features.join(",") || "-")}
                            </Typography>
                          </StyledTableCell>
                        );
                      })}
                    </StyledTableRow>
                  );
                })}
                <HeadingTableRow>
                  <StyledTableCell
                    colSpan={this.state.boatCompareDetails.length + 1}
                  >
                    <Typography
                      gutterBottom
                      className={classes.tableHeaderRowHeading}
                    >
                      DETAILS
                    </Typography>
                  </StyledTableCell>
                </HeadingTableRow>
                {["Model", "Year", "Type", "Length"].map((row) => (
                  <StyledTableRow key={row}>
                    <StyledTableCell align="center">
                      <Typography
                        gutterBottom
                        className={classes.tableHeaderRowSubHeading}
                      >
                        {row}
                      </Typography>
                    </StyledTableCell>
                    {this.state.boatCompareDetails.map((boat) => {
                      return (
                        <StyledTableCell key={boat.id}>
                          <Typography
                            align="center"
                            gutterBottom
                            className={classes.tableHeaderRowValue}
                          >
                            {row === "Year" &&
                              `${
                                boat?.attributes?.manufactured_year ||
                                "Not Available"
                              }`}
                          </Typography>
                          <Typography
                            align="center"
                            gutterBottom
                            className={classes.tableHeaderRowValue}
                          >
                            {row === "Type" &&
                              `${
                                boat?.attributes?.boat_specification_type || "Not Available"
                              }`}
                          </Typography>
                          <Typography
                            align="center"
                            gutterBottom
                            className={classes.tableHeaderRowValue}
                          >
                            {row === "Length" &&
                              `${
                                boat?.attributes?.boat_length || "Not Available"
                              }`}
                          </Typography>
                          <Typography
                            align="center"
                            gutterBottom
                            className={classes.tableHeaderRowValue}
                          >
                            {row === "Model" &&
                              `${
                                boat?.attributes?.model ||
                                "-"
                              }`}
                          </Typography>
                        </StyledTableCell>
                      );
                    })}
                  </StyledTableRow>
                ))}
                <HeadingTableRow>
                  <StyledTableCell
                    colSpan={this.state.boatCompareDetails.length + 1}
                  >
                    <Typography
                      gutterBottom
                      className={classes.tableHeaderRowHeading}
                    >
                      TOP REVIEWS
                    </Typography>
                  </StyledTableCell>
                </HeadingTableRow>
                {["reviews"].map((row) => (
                  <StyledTableRow key={row}>
                    <StyledTableCell />
                    {this.state.boatCompareDetails.map((boat) => {
                      return (
                        <StyledTableCell key={boat.id}>
                          <Grid container spacing={3} >
                            {boat?.attributes?.reviews?.reviews?.data?.map((item) => {
                              return (
                                <Grid item md={12} xs={12} key={item.id}>
                                  <Paper elevation={0} key={item.id}>
                                      <Grid container spacing={1}>
                                        <Grid item xs={10}>
                                          <ListItem>
                                            <ListItemAvatar>
                                            <Avatar src={item.attributes?.account?.avatar?.url} />
                                            </ListItemAvatar>
                                            <ListItemText primary={item.attributes?.account.user_name}>
                                            </ListItemText>
                                          </ListItem>
                                        </Grid>
                                        <Grid item xs={10}>
                                          <Typography align="left" gutterBottom>
                                              {item.attributes.comment}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    </Paper>
                                </Grid>
                              );
                            })}
                          </Grid>
                        </StyledTableCell>
                      );
                    })}
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
         : <Typography align="center" color="textPrimary" gutterBottom variant="h1">No Boat Data found</Typography>}  
          </Grid>
      </Grid>
    );
  }
}

const BoatComparisonWithRouter = withRouter(BoatComparison);
const BoatComparisonAlertBox = withAlertBox(BoatComparisonWithRouter);
const BoatComparisonLoader = withLoader(BoatComparisonAlertBox);
const BoatComparisonToast = withToast(BoatComparisonLoader);
export default withStyles((theme) =>
  createStyles({
    marginTop: {
      marginTop: "2px",
      overflowX: "hidden",
    },
    comparing: {
      fontFamily: "Roboto",
      fontSize: "20px",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.15,
      letterSpacing: "normal",
      color: "#545454",
    },
    comparedBoatName: {
      fontFamily: "Roboto",
      fontSize: "20px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.15,
      letterSpacing: "normal",
      color: "#545454",
    },
    compareBoatCount: {
      fontFamily: "Roboto",
      fontSize: "16px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.15,
      letterSpacing: "normal",
      color: "#545454",
    },
    boatName: {
      fontFamily: "Roboto",
      fontSize: "24px",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.15,
      letterSpacing: "normal",
      color: "#004277",
    },
    boatPrice: {
      fontFamily: "Roboto",
      fontSize: "16px",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.15,
      letterSpacing: "normal",
      color: "#000",
    },
    tableHeaderRowHeading: {
      fontFamily: "Roboto",
      fontSize: "20px",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.15,
      letterSpacing: "normal",
      color: "#0174cf",
    },
    tableHeaderRowSubHeading: {
      fontFamily: "Roboto",
      fontSize: "16px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.15,
      letterSpacing: "normal",
      color: "#545454",
    },
    reviewValue: {
      fontFamily: "Roboto",
      fontSize: "16px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.15,
      letterSpacing: "normal",
      color: "#545454",
    },
    tableHeaderRowValue: {
      fontFamily: "Roboto",
      fontSize: "16px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.15,
      letterSpacing: "normal",
      color: "#545454",
    },
    primaryOwnerName: {
      fontFamily: "Roboto",
      fontSize: "16px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.15,
      letterSpacing: "normal",
      color: "#545454",
    },
    secondaryOwnerName: {
      fontFamily: "Roboto",
      fontSize: "16px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.15,
      letterSpacing: "normal",
      color: "#545454",
    },
    date: {
      fontFamily: "Roboto",
      fontSize: "16px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.15,
      letterSpacing: "normal",
      color: "#545454",
    },
    hotelMainImage: {
      position: "relative",
      backgroundImage: `url(${boatImageDemo1})`,
      backgroundRepeat: "no-repeat",
      backgroundColor:
        theme.palette.type === "light"
          ? theme.palette.grey[50]
          : theme.palette.grey[900],
      backgroundSize: "cover",
      backgroundPosition: "center",
      height: theme.spacing(15),
      width: theme.spacing(20),
      [theme.breakpoints.down("xs")]: {
        height: theme.spacing(10),
      },
      borderRadius: theme.spacing(1),
    },
  })
)(BoatComparisonToast);
