//@ts-nocheck
import {
  Box,
  Button,
  Checkbox,
  Collapse,
  createStyles,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
  withStyles,
} from "@material-ui/core";
import moment from "moment";
import React from "react";
import { withRouter } from "react-router";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withLoader from "../../../components/src/withLoader.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import { boat, calendar, captionIcon, clock, team, watch } from "./assets";
import CheckinConfirmPopup from "./CheckinConfirmPopup.web";
import CheckInPopupController,{Props} from "./CheckInPopupController.web";
import withConfirmBox from "../../../components/src/withConfirmBox.Web";
import StorageProvider from "../../../framework/src/StorageProvider.web";


class CheckInPopup extends CheckInPopupController {
  constructor(props: Props) {
    super(props);
  }
  async componentDidMount() {
    const authToken = await StorageProvider.get("authToken");
    if (authToken) {
      this.setState(
        {
          authToken: authToken,
        }
      );
    }
  }
  openConfirmDialog = (data) => {
    const onClose = async (result) => {
      if(result === 'Yes'){
        this.makeCheckIn(this.props?.dataToPass?.bookingDetail)
      }
    }
    this.props.openConfirmDialog(
      'Confirmation',`You have been checked in at ${moment().format("MMM DD,YYYY")},${moment().format("hh:mm A")}` ,onClose
    )    
  };
  getHours = (durationFilter) => {
    if(durationFilter){
      if(durationFilter === 'half_day'){
        return "0-4 Hours "
      } else if(durationFilter === 'full_day'){
        return "8 Hours "
      } else if(durationFilter === 'twentyfour_hours'){
        return "24 Hours "
      } else if(durationFilter === 'three_days'){
        return "3 Days "
      }
      else if(durationFilter === 'seven_days'){
        return "7 Days "
      }
    } else {
      return "0-4 Hours "
    }
  }
  
  render() {
    const { classes } = this.props;
    return (
      <Grid
        container
        justify="space-between"
        spacing={2}
        className={classes.mainContainer}
      >
        <Grid container item xs={12} justify="space-between">
          <Grid item xs={12}>
            <Typography
              color="primary"
              gutterBottom
              className={classes.boldText}
            >
              Payment Details
            </Typography>
          </Grid>
          <Grid container item xs={4}>
            <Box mr={1}>
              <Typography display="inline" gutterBottom color="textSecondary">
                Amount Paid{" "}
              </Typography>{" "}
            </Box>
            <Box>
              <Typography
                display="inline"
                gutterBottom
                className={classes.boldText}
              >
               $ {this.props?.dataToPass?.bookingDetail?.attributes.total_amount}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={4} className={classes.flexEnd}>
            <Typography display="inline" gutterBottom color="textSecondary">
              Pay By{" "}
            </Typography>
            <Typography
              display="inline"
              gutterBottom
              className={classes.boldText}
            >
              Card
            </Typography>
          </Grid>
        </Grid>
        <Box p={1.5} />

        <Grid container item xs={12}>
          <Grid item xs={12}>
            <Box pb={1}>
              <Typography
                color="primary"
                gutterBottom
                className={classes.boldText}
              >
                Booking Details
              </Typography>
            </Box>
          </Grid>
          <Grid container item xs={12} justify="space-between" spacing={2}>
            <Grid
              container
              item
              xs={4}
              alignItems="center"
              className={classes.center}
            >
              <Box display="flex" alignItems="center" pb={2}>
                <img src={calendar} width={30} height={30} />
              </Box>
              <Box ml={2.5}>
                <Typography
                  variant="body2"
                  color="primary"
                  className={classes.tagDetails}
                >
                  Trip date
                </Typography>
                <Typography variant="body2" className={classes.tagDetails}>
                  {this.props?.dataToPass?.bookingDetail?.attributes?.trip_date
                    ? moment(
                        this.props?.dataToPass?.bookingDetail?.attributes
                          ?.trip_date
                      ).format("DD/MM/YY")
                    : `Not Available`}
                </Typography>
              </Box>
            </Grid>

            <Grid
              container
              item
              xs={4}
              alignItems="center"
              className={classes.center}
            >
              <Box display="flex" alignItems="center" pb={2}>
                <img src={clock} width={30} height={30} />
              </Box>
              <Box ml={2.5}>
                <Typography
                  variant="body2"
                  color="primary"
                  className={classes.tagDetails}
                >
                  Time
                </Typography>
                <Typography variant="body2" className={classes.tagDetails}>
                  {this.props?.dataToPass?.bookingDetail?.attributes?.start_time
                    ? moment(
                        this.props?.dataToPass?.bookingDetail?.attributes
                          ?.start_time
                      ).format("hh:mm a")
                    : `Not Available`}
                </Typography>
              </Box>
            </Grid>

            <Grid
              container
              item
              xs={4}
              alignItems="center"
              className={classes.center}
            >
              <Box display="flex" alignItems="center" pb={2}>
                <img src={team} width={30} height={30} />
              </Box>
              <Box ml={2.5}>
                <Typography
                  variant="body2"
                  color="primary"
                  className={classes.tagDetails}
                >
                  Passenger
                </Typography>
                <Typography variant="body2" className={classes.tagDetails}>
                  {this.props?.dataToPass?.bookingDetail?.attributes?.passengers
                    ? `${this.props?.dataToPass?.bookingDetail?.attributes?.passengers}`
                    : `Not Available `}{" "}
                  Passengers
                </Typography>
              </Box>
            </Grid>
            <Grid
              container
              item
              xs={4}
              alignItems="center"
              className={classes.center}
            >
              <Box display="flex" alignItems="center" pb={2}>
                <img src={watch} width={30} height={30} />
              </Box>
              <Box ml={2.5}>
                <Typography
                  variant="body2"
                  color="primary"
                  className={classes.tagDetails}
                >
                  Trip{" "}
                </Typography>
                <Typography variant="body2" className={classes.tagDetails}>
                  {this.props?.dataToPass?.bookingDetail?.attributes
                    ?.trip_duration
                    ? 
                      this.getHours(this.props?.dataToPass?.bookingDetail?.attributes?.trip_duration)
                    : `Not Available`}{" "}
                </Typography>
              </Box>
            </Grid>

            <Grid
              container
              item
              xs={4}
              alignItems="center"
              className={classes.center}
            >
              <Box display="flex" alignItems="center" pb={2}>
                <img src={captionIcon} width={30} height={30} />
              </Box>
              <Box ml={2.5}>
                <Typography
                  variant="body2"
                  color="primary"
                  className={classes.tagDetails}
                >
                  Captain
                </Typography>
                <Typography variant="body2" className={classes.tagDetails}>
                  {this.props?.dataToPass?.bookingDetail?.attributes
                    ?.captain_included
                    ? `Included`
                    : `Not Included`}
                </Typography>
              </Box>
            </Grid>

            <Grid
              container
              item
              xs={4}
              alignItems="center"
              className={classes.center}
            >
              <Box display="flex" alignItems="center" pb={2}>
                <img src={boat} width={30} height={30} />
              </Box>
              <Box ml={2.5}>
                <Typography
                  variant="body2"
                  color="primary"
                  className={classes.tagDetails}
                >
                  Gas Tank
                </Typography>
                <Typography variant="body2" className={classes.tagDetails}>
                  Full
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={this.state.messageBox}
                onChange={() => {
                  this.setState({ messageBox: !this.state.messageBox,message : '' });
                }}
                name="messageBox"
                color="primary"
              />
            }
            label={
              <Typography color="textSecondary">
                Send a message to Owner
              </Typography>
            }
          />
        </Grid>
        <Collapse in={this.state.messageBox} className={classes.fullWidth}>
          <Grid item xs={12}>
            <Box width="100%" pb={2}>
              <TextField
                variant="outlined"
                fullWidth
                name="message"
                multiline
                rows={2}
                onChange={(evt)=>{
                  this.setState({
                    message : evt.target.value
                  })
                }}
                value={this.state.message}
              />
            </Box>
          </Grid>
        </Collapse>

        <Grid container item xs={12} justify="flex-end">
          <Box pb={1.5}>
            <Button
              variant="outlined"
              color="primary"
              className={classes.roundCorners}
              onClick={this.props.onCancel}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={`${classes.marginLeft} ${classes.roundCorners}`}
              onClick={() =>
                this.openConfirmDialog(this.props?.dataToPass?.bookingDetail)
              }
            >
              Confirm
            </Button>
          </Box>
        </Grid>
      </Grid>
    );
  }
}
const CheckInPopupWithRouter = withRouter(CheckInPopup);
const CheckInPopupWithToast = withToast(CheckInPopupWithRouter);
const CheckInPopupWithLoader = withLoader(CheckInPopupWithToast);
const CheckInPopupWithAlertBox = withAlertBox(CheckInPopupWithLoader);

export default withStyles((theme) =>
  createStyles({
    mainContainer: {
      marginTop: "0.625rem",
      paddingTop: "15px",
      border: "1px #b8b8b8",
      borderStyle: "solid none none none",
    },
    marginLeft: {
      marginLeft: theme.spacing(2),
    },
    center: {
      [theme.breakpoints.down("xs")]: {
        justifyContent: "center",
        flexDirection: "column",
      },
    },
    tagDetails: {
      [theme.breakpoints.down("xs")]: {
        textAlign: "center",
      },
    },
    roundCorners: {
      borderRadius: "5px",
      borderWidth: "2px",
    },
    boldText: {
      fontWeight: 500,
    },
    flexEnd: {
      alignSelf: "flex-end",
    },
    fullWidth: {
      width: "100%",
    },
  })
)(withConfirmBox((CheckInPopupWithAlertBox)));
