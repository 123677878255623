//@ts-nocheck
import {
  Box,
  Button,
  createStyles,
  Grid,
  Paper,
  TextField,
  Typography,
  withStyles,
} from "@material-ui/core";
import React from "react";
import { withRouter } from "react-router";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withLoader from "../../../components/src/withLoader.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import OwnerBookingComplainController, {
  Props,
} from "./OwnerBookingComplainController.web";

class OwnerBookingComplain extends OwnerBookingComplainController {
  constructor(props: Props) {
    super(props);
  }
  goToSelectedRoute = (routeName) => {
    const { history } = this.props;
    history.push(routeName);
  };
  closeDialog = (data) => {
    const { onSubmit } = this.props;
    onSubmit(data);
  };
  handleChange = (value) => {
    this.setState({
      description: value,
    });
  };
  async componentDidMount() {
    const authToken = await StorageProvider.get("authToken");
    const bookingId = this.props.dataToPass.bookingId;
    if (authToken && bookingId) {
      this.setState({
        authToken: authToken,
        description: "",
      });
    }
  }

  addComplain = () => {
    this.createComplain(this.state.description);
  };
  render() {
    const { classes } = this.props;

    return (
      <Grid container justify="flex-start" spacing={2}>
        <Grid xs={11} item>
          <Box pt={3}>
            <Typography className={classes.mainText} variant="h6">
              Complaint Description
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={11}>
          <Box pb={5}>
            <Paper elevation={1}>
              {" "}
              <TextField
                variant="outlined"
                fullWidth
                name="complain"
                multiline
                rows={6}
                onChange={(event) => this.handleChange(event.target.value)}
              />
            </Paper>
          </Box>
        </Grid>

        <Grid item xs={11}>
          <Grid container justify="flex-end">
            <Button
              onClick={() => {
                this.props.onCancel();
              }}
              variant="outlined"
              color="primary"
              className={classes.roundButton}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              onClick={this.addComplain}
              className={classes.roundButton}
              disabled={this.state.description?.length === 0}
            >
              ADD
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}
const OwnerBookingComplainWithRouter = withRouter(OwnerBookingComplain);
const OwnerBookingComplainWithToast = withToast(OwnerBookingComplainWithRouter);
const OwnerBookingComplainWithLoader = withLoader(
  OwnerBookingComplainWithToast
);
const OwnerBookingComplainWithAlertBox = withAlertBox(
  OwnerBookingComplainWithLoader
);

export default withStyles((theme) =>
  createStyles({
    mainText: {
      fontFamily: "Montserrat",
      fontSize: "1rem",
      fontWeight: 600,
      color: "#343a40",
    },
    roundButton: {
      borderRadius: "5px",
      padding: "0px 40px",
      margin: "0px 10px 10px ",
      border: "solid 2px #0174cf",
    },
  })
)(OwnerBookingComplainWithAlertBox);
