//@ts-nocheck
import React from "react";
import EmailSignupController, {
  Props,
} from "./EmailAccountSignupController.web";
import {
  withStyles,
  Grid,
  Typography,
  createStyles,
  Button,
} from "@material-ui/core";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { google, facebook, arrow } from "./assets";
import  InputField  from "../../../components/src/InputFiled.web";
import { withRouter } from "react-router";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { GoogleLogin } from "react-google-login";

const sighupSchema = Yup.object().shape({
  name: Yup.string().required("This field is required."),
  email: Yup.string()
    .email("Email must be in valid format!")
    .required("This field is required."),
  password: Yup.string()
    .min(8, "Enter atleast 8 digit Password")
    .matches(
      /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      "Password must contain at least 8 characters, one uppercase, one number and one special case character"
    )
    .required("This field is required"),
});
class EmailAccountSignup extends EmailSignupController {
  constructor(props: Props) {
    super(props);
  }
  goToSelectedRoute = (routeName) => {
    const { history } = this.props;
    history.push(routeName);
  };
  responseFacebook = (data) => {
    if(data.email && data.name && data.userID){
      this.handleSocialAccountSignup({
        email: data.email,
        name: data.name,
        unique_auth_id: data.userID,
      });
    }
  };
  responseGoogleSuccess = (data) => {
    const profileData = data?.profileObj;
    if(profileData && profileData?.email && profileData?.googleId){
      this.handleSocialAccountSignup({
        email: profileData?.email,
        name: profileData?.name,
        unique_auth_id: profileData?.googleId,
      });
    }
  }
  responseGoogleFailure = (data) => {
    console.log(data)
  }
  render() {
    const { classes } = this.props;
    return (
      <Formik
        initialValues={{
          name: "",
          email: "",
          password: "",
        }}
        validationSchema={sighupSchema}
        onSubmit={(values, actions) => {
          this.handleSignup(values);
        }}
      >
        {(formikProps) => {
          return (
            <Form autoComplete="off" noValidate>
              <Grid container item xs={12} sm={12} spacing={1}>
                <Grid item xs={12}>
                  <Typography
                    component="div"
                    className={classes.pageHeading}
                    align="left"
                  >
                    Sign up
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  container
                  spacing={1}
                  justify="space-between"
                >
                  <Grid item xs={6}>
                    <GoogleLogin
                      clientId="409793602542-lgacon8uppvhrcva8p5abv68jdtesiuh.apps.googleusercontent.com"
                      render={(renderProps) => (
                        <Button
                          endIcon={<img src={google} />}
                          variant="outlined"
                          onClick={renderProps.onClick}
                          classes={{
                            root: classes.googleLoginButton,
                            label: classes.googleLoginButtonLable,
                          }}
                        >
                          Continue with Google
                        </Button>
                      )}
                      buttonText="Login"
                      onSuccess={this.responseGoogleSuccess}
                      onFailure={this.responseGoogleFailure}
                      cookiePolicy={"single_host_origin"}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FacebookLogin
                      appId="561668421800321"
                      autoLoad={false}
                      fields="name,email"
                      callback={(data) => {
                        this.responseFacebook(data);
                      }}
                      render={(renderProps) => (
                        <Button
                          endIcon={<img src={facebook} />}
                          onClick={renderProps.onClick}
                          variant="outlined"
                          classes={{
                            root: classes.facebookLoginButton,
                            label: classes.facebookLoginButtonLable,
                          }}
                        >
                          Continue with Facebook
                        </Button>
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} container spacing={1} alignItems="center">
                  <Grid item xs={2}>
                    <div style={{ height: 0, border: "solid 1px #c6d2e1" }} />
                  </Grid>
                  <Grid item xs={8}>
                    <Typography
                      variant="caption"
                      color="textSecondary"
                      align="center"
                      className={classes.pageSubheading}
                    >
                      or create account with your Email
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <div style={{ height: 0, border: "solid 1px #c6d2e1" }} />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={InputField}
                    required
                    fullWidth
                    label="Name"
                    name="name"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={InputField}
                    required
                    fullWidth
                    label="Email"
                    name="email"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={InputField}
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                  />
                </Grid>
                <Grid item xs={12}>
                  <div className={classes.marginTop1}>
                    <Button
                      color="secondary"
                      variant="contained"
                      type="submit"
                      endIcon={<img style={{ height: "10px" }} src={arrow} />}
                    >
                      Create account
                    </Button>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    color="textSecondary"
                    display="inline"
                    variant="subtitle1"
                    align="left"
                    className={classes.donttext}
                  >
                    Already Have An Account?
                  </Typography>
                  <Button
                    className={classes.forgetPassword}
                    onClick={() => {
                      this.goToSelectedRoute("/basicAuth/login");
                    }}
                    variant="text"
                  >
                    Login
                  </Button>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
      // </Container>
    );
  }
}
const EmailAccountSignupWithRouter = withRouter(EmailAccountSignup);
const EmailAccountSignupAlertBox = withAlertBox(EmailAccountSignupWithRouter);
const EmailAccountSignupLoader = withLoader(EmailAccountSignupAlertBox);
const EmailAccountSignupToast = withToast(EmailAccountSignupLoader);

export default withStyles((theme) =>
  createStyles({
    paper: {
      margin: "48px auto",
    },
    pageHeading: {
      fontFamily: "Montserrat",
      fontSize: "20px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.15,
      letterSpacing: "normal",
      textTransform: "none",
      color: "#1b4050",
    },
    pageSubheading: {
      fontFamily: "Montserrat",
      fontSize: "14px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.2,
      letterSpacing: "normal",
      textTransform: "none",
      color: "#354f6c",
      textAlign: "center",
    },
    googleLoginButton: {
      borderRadius: "3px",
      border: "solid 2px #d44638",
      backgroundColor: "#ffffff",
      height: "40px",
    },
    googleLoginButtonLable: {
      fontFamily: "Roboto",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.15,
      letterSpacing: "normal",
      textTransform: "none",
      color: "#d44638",
      [theme.breakpoints.down("xs")]: {
        letterSpacing: "normal",
        fontSize: "10px",
      },
    },
    facebookButton: {
      marginLeft: "30px",
      [theme.breakpoints.down("xs")]: {
        marginLeft: "8px",
      },
    },
    facebookLoginButton: {
      borderRadius: "3px",
      border: "solid 2px #4267b2",
      backgroundColor: "#ffffff",
      height: "40px",
    },
    facebookLoginButtonLable: {
      fontFamily: "Roboto",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.15,
      letterSpacing: "normal",
      textTransform: "none",
      color: "#4267b2",
      [theme.breakpoints.down("xs")]: {
        letterSpacing: "normal",
        fontSize: "10px",
      },
    },
    forgetPassword: {
      marginLeft: theme.spacing(4),
    },
    donttext: {
      fontFamily: "Roboto",
      fontSize: "14px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.14,
      letterSpacing: "0.42px",
      textTransform: "none",
      color: "#1b4050",
    },
  })
)(EmailAccountSignupToast);
