import React, { Component } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Switch, Route } from 'react-router';
import EmailAccountLoginSignUpContainer from "../../blocks/email-account-login/src/EmailLoginSignUpContainer.web";
import LandingPage from "../../blocks/email-account-login/src/LandingPage.web";
import HowItWorks from "../../blocks/email-account-login/src/howItWorks.web";
import  PrivateRoute  from "../../components/src/PrivateRoute.web";
import BoatDetailsLandingPage from "../../blocks/user-profile-basic/src/BoatDetailsLandingPage.web.tsx";
import BoatDetailsPricePage from "../../blocks/user-profile-basic/src/BoatDetailsPricePage.web";
import BoatInsuranceDetail from "../../blocks/user-profile-basic/src/BoatInsuranceDetail.web";
import BoatSafetyDetail from "../../blocks/user-profile-basic/src/BoatSafetyDetail.web";
import BoatCategoryDetail from "../../blocks/user-profile-basic/src/BoatCategoryDetails.web";
import BoatAddressDetail from "../../blocks/user-profile-basic/src/BoatAddressDetail.web";
import BoatNameDetails from "../../blocks/user-profile-basic/src/BoatNameDetails.web";
import BoatDescriptionDetail from "../../blocks/user-profile-basic/src/BoatDescriptionDetail.web";
import BoatApprovalDetails from "../../blocks/user-profile-basic/src/BoatApprovalDetails.web";
import BoatCancelationDetails from "../../blocks/user-profile-basic/src/BoatCancelationDetails.web.tsx";
import BoatMainPhotoDetail from "../../blocks/user-profile-basic/src/BoatMainPhotoDetail.web";
import BoatAdditionalPhotoDetail from "../../blocks/user-profile-basic/src/BoatAdditionalPhotoDetail.web";
import BoatPreferencesDetail from "../../blocks/user-profile-basic/src/BoatPreferencesDetail.web.tsx";
import BoatFeatureDetail from "../../blocks/user-profile-basic/src/BoatFeatureDetail.web.tsx";
import BoatLocationOnMap from "../../blocks/user-profile-basic/src/BoatLocationOnMap.web";
import BoatHandsFreeExprience from "../../blocks/user-profile-basic/src/BoatHandsFreeExprience.web.tsx";
import BoatInsuranceDetailForm from "../../blocks/user-profile-basic/src/BoatInsuranceDetailForm.web";
import BoatListingLandingPage from "../../blocks/user-profile-basic/src/BoatListingLandingPage.web";
import BoatAvailableDate from "../../blocks/user-profile-basic/src/BoatAvailableDate.web";
import EmailAccountVerifyAndUploadPicture from "../../blocks/email-account-login/src/EmailAccountVerifyAndUploadPicture.web";
import EmailAccountVerifyMobileNo from "../../blocks/email-account-login/src/EmailAccountVerifyMobileNo.web";
import EmailAccountVerifyOTP from "../../blocks/email-account-login/src/EmailAccountVerifyOTP.web";
import OwnerDashboradContainer from "../../blocks/user-profile-basic/src/OwnerDashboradContainer.web";
import BoatNoOfPassengerDetail from "../../blocks/user-profile-basic/src/BoatNoOfPassengerDetail.web";
import UserDashboradContainerTemp from "../../blocks/user-profile-basic/src/UserDashboradContainerTemp.web";
import BookBoat from "../../blocks/user-profile-basic/src/BookBoat.web";
import BookBoatPayment from "../../blocks/user-profile-basic/src/BookBoatPayment.boat";
import MarinaDashboardContainer from '../../blocks/user-profile-basic/src/MarinaDashboardContainer.web'
import MarinaListContainer from "../../blocks/user-profile-basic/src/MarinaListContainer.web";
// import MarinaDashboardContainer from "../../blocks/user-profile-basic/src/MarinaDashboardContainer.web";
import StorageProvider from "../../framework/src/StorageProvider.web";
import BoatSpecification from "../../blocks/user-profile-basic/src/BoatSpecification.web";
import BoatActivity from "../../blocks/user-profile-basic/src/BoatActivity.web";
import FAQList from "../../blocks/email-account-login/src/FAQList.web";
import TipsForOwner from "../../blocks/email-account-login/src/TipsForOwner.web";
import TipsForRenter from "../../blocks/email-account-login/src/TipsForRenter.web";
class RouteConfig extends Component {
  // async componentDidMount() {
  //   await StorageProvider.clearStorage();
  // }

  render() {
      return (
          <Switch>
            <Route path="/" exact>
              <LandingPage />
            </Route>
            <PrivateRoute path="/bookBoat">
                <BookBoat></BookBoat>
            </PrivateRoute>
            <PrivateRoute path="/bookBoatPayment">
                <BookBoatPayment></BookBoatPayment>
            </PrivateRoute>
            <PrivateRoute path="/ownerDashboard">
                <OwnerDashboradContainer></OwnerDashboradContainer>
            </PrivateRoute>
            <Route path="/userDashboard">
                <UserDashboradContainerTemp></UserDashboradContainerTemp>
            </Route>
            <PrivateRoute path="/marinaDashboard">
                <MarinaDashboardContainer></MarinaDashboardContainer>
            </PrivateRoute>
            <Route path="/basicAuth">
              <EmailAccountLoginSignUpContainer />
            </Route>
            <Route path="/verifyAccount">
               <EmailAccountVerifyAndUploadPicture></EmailAccountVerifyAndUploadPicture>
            </Route>
            <Route path="/verifySignupMobile">
               <EmailAccountVerifyMobileNo></EmailAccountVerifyMobileNo>
            </Route>
            <Route path="/verifySignupMobileOTP">
               <EmailAccountVerifyOTP></EmailAccountVerifyOTP>
            </Route>
            <PrivateRoute path="/boatListingLandingPage">
              <BoatListingLandingPage />
            </PrivateRoute>
            <PrivateRoute path="/boatCategory">
              <BoatCategoryDetail />
            </PrivateRoute>
            <PrivateRoute path="/boatAddress">
              <BoatAddressDetail></BoatAddressDetail>
            </PrivateRoute>
            <PrivateRoute path="/boatLocation">
              <BoatLocationOnMap></BoatLocationOnMap>
            </PrivateRoute>
            <PrivateRoute path="/boatHandsFreeExprience">
              <BoatHandsFreeExprience></BoatHandsFreeExprience>
            </PrivateRoute>
            <PrivateRoute path="/boatTitle">
              <BoatNameDetails></BoatNameDetails>
            </PrivateRoute>
            <PrivateRoute path="/boatDescription">
              <BoatDescriptionDetail></BoatDescriptionDetail>
            </PrivateRoute>
            <PrivateRoute path="/boatActivity">
              <BoatActivity></BoatActivity>
            </PrivateRoute>
            <PrivateRoute path="/boatSpecification">
               <BoatSpecification></BoatSpecification>
            </PrivateRoute>
            <PrivateRoute path="/boatApprovalDetails">
              <BoatApprovalDetails></BoatApprovalDetails>
            </PrivateRoute>
            <PrivateRoute path="/boatCancelationPolicy">
              <BoatCancelationDetails></BoatCancelationDetails>
            </PrivateRoute>
            <PrivateRoute path="/boatPhotoDetail">
              <BoatMainPhotoDetail></BoatMainPhotoDetail>
            </PrivateRoute>
            <PrivateRoute path="/boatAvailableDates">
              <BoatAvailableDate></BoatAvailableDate>
            </PrivateRoute>
            <PrivateRoute path="/boatAdditionalPhotoDetail">
              <BoatAdditionalPhotoDetail></BoatAdditionalPhotoDetail>
            </PrivateRoute>
            <PrivateRoute path="/boatPreferences">
              <BoatPreferencesDetail></BoatPreferencesDetail>
            </PrivateRoute>
            <PrivateRoute path="/boatFeatures">
              <BoatFeatureDetail></BoatFeatureDetail>
            </PrivateRoute>
            <PrivateRoute path="/boatDetails">
              <BoatDetailsLandingPage />
            </PrivateRoute>
            <PrivateRoute path="/boatPrices">
              <BoatDetailsPricePage />
            </PrivateRoute>
            <PrivateRoute path="/boatInsurance">
              <BoatInsuranceDetail />
            </PrivateRoute>
            <PrivateRoute path="/boatInsuranceDetailForm">
              <BoatInsuranceDetailForm></BoatInsuranceDetailForm>
            </PrivateRoute>
            <PrivateRoute path="/boatSafety">
              <BoatSafetyDetail />    
            </PrivateRoute>
            <PrivateRoute path="/boatOwnerDashboradLandingPage">
              <BoatListingLandingPage />    
            </PrivateRoute>
            <PrivateRoute path="/marinaList">
              <MarinaListContainer />    
            </PrivateRoute>
            <PrivateRoute path="/marinaDashborad">
              <MarinaDashboardContainer />    
            </PrivateRoute>
            <Route path="/howItWorks">
                <HowItWorks></HowItWorks>
             </Route>
             <Route path="/faq">
                <FAQList></FAQList>
             </Route>
             <Route path="/tipsForRenters">
                <TipsForRenter></TipsForRenter>
             </Route>
             <Route path="/tipsForOwners">
                <TipsForOwner></TipsForOwner>
             </Route>          
          </Switch>
      );
    }
  }
  export default RouteConfig;
  