//@ts-nocheck
import React from "react";
import BoatDetailsLandingPageController, {
  Props,
} from "./BoatDetailsLandingPageController.web";
import {
  withStyles,
  Grid,
  AppBar,
  Toolbar,
  Typography,
  createStyles,
  Box,
  ListItem,
  List,
  ListItemText,
  Button,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import { logo,boatDetail, caption, therenter, tick } from "./assets";
import { withRouter } from "react-router";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
import withBoatIdComponent from "./withBoatIdComponent.js";

const drawerWidth = 240;
const PageLinks = [
  { displayName: "Support", route: "/faq" },
  { displayName: "Save and exit", route: "saveandexit" },
];

class BoatDetailsLandingPage extends BoatDetailsLandingPageController {
  constructor(props: Props) {
    super(props);
  }
  async componentDidMount() {
    const authToken = await StorageProvider.get("authToken");
    const boatId = await StorageProvider.get("boatId");
    if (authToken) {
      this.setState(
        {
          authToken: authToken,
          boatId: boatId,
        },
        () => {
          this.getBoatDetail();
        }
      );
    }
  }
  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };
  goToSelectedRoute = (routeName) => {
    if (routeName === "saveandexit") {
      this.setState(
        {
          isSaveAndExit: true,
        },
        () => {
          if (this.state.boat_operator) {
            this.updateBoatOpertor();
          } else {
            this.props.showToast({
              type: "error",
              message: "Please select operator type",
            });
          }
        }
      );
    } else {
      const { history } = this.props;
      history.push(routeName);
    }
  };
  handleCategorySelection = (category) => {
    this.setState({
      boat_operator: category,
    });
  };
  render() {
    const { classes } = this.props;
    return (
      <>
        <AppBar
          position="static"
          color="default"
          elevation={0}
          className={classes.appBar}
        >
          <Toolbar className={classes.toolbar}>
            <Box className={classes.toolbarLogo}>
              <img onClick={()=>this.props.history.push("/")}  src={logo} style={{width:'22%'}} alt="boaturu" />
            </Box>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              edge="start"
              onClick={this.handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <Hidden xsDown implementation="css">
              <nav>
                {PageLinks.map((link) => {
                  return (
                    <Typography
                      key={link.displayName}
                      variant="button"
                      className={classes.link}
                      onClick={() => {
                        this.goToSelectedRoute(link.route);
                      }}
                    >
                      {link.displayName}
                    </Typography>
                  );
                })}
              </nav>
            </Hidden>
            <Hidden smUp implementation="css">
              <Drawer
                variant="persistent"
                anchor={"right"}
                open={this.state.mobileOpen}
                onClose={this.handleDrawerToggle}
                classes={{
                  paper: classes.drawerPaper,
                }}
                SlideProps={{ unmountOnExit: true }}
              >
                <IconButton
                  onClick={this.handleDrawerToggle}
                  className={classes.closeMenuButton}
                >
                  <CloseIcon />
                </IconButton>
                <List>
                  {PageLinks.map((link, index) => (
                    <ListItem
                      button
                      key={link.displayName}
                      onClick={() => {
                        this.goToSelectedRoute(link.route);
                      }}
                    >
                      <ListItemText primary={link.displayName} />
                    </ListItem>
                  ))}
                </List>
              </Drawer>
            </Hidden>
          </Toolbar>
        </AppBar>
        <Grid container className={classes.root}>
          <Grid
            item
            xs={false}
            sm={4}
            md={5}
            container
            spacing={3}
            className={classes.image}
          >
            <div className={classes.overlay} />
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <div className={classes.imagetxtcontainer}>
                    <img src={tick} />
                    <Typography
                      display="inline"
                      className={classes.leftPageHeading}
                    >
                      Boat details
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography gutterBottom className={classes.pricing}>
                Pricing
              </Typography>
              <div className={classes.headingline} />
              <Typography gutterBottom className={classes.selectOperator}>
                Select an operator
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography gutterBottom className={classes.leftsidelink}>
                Insurance
              </Typography>
              <Typography gutterBottom className={classes.leftsidelink}>
                Safety and standards
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={8} md={7}>
            <Grid
              container
              justify="center"
              style={{ padding: "16px 32px" }}
              spacing={3}
            >
              <Grid item xs={12}>
                <Box
                  className={classes.backButtonBox}
                  onClick={() => {
                    this.goToSelectedRoute("/boatAvailableDates");
                  }}
                >
                  <Typography className={classes.backButtonBoxContent}>
                    {"<"}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} container justify="center">
                <Grid item>
                  <Typography
                    align="center"
                    gutterBottom
                    className={classes.pageHeading}
                  >
                    You can let your approved renters operate your boat
                  </Typography>
                  <Typography align="center" className={classes.pageSubHeading}>
                    You can also include a Float captain, invite a captain or
                    operate your boat yourself.
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} container style={{ marginTop: "40px" }}>
                <Grid item>
                  <Typography align="left" gutterBottom>
                    Who would you like to operate your boat?
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} container justify={"space-evenly"}>
                <Grid
                  item
                  xs={3}
                  onClick={() => {
                    this.handleCategorySelection("renter");
                  }}
                >
                  <div
                    className={classes.renterTypeBox}
                    style={
                      this.state.boat_operator === "renter"
                        ? {
                            backgroundColor: "#c8e7ff",
                            border: "solid 2px #004277",
                          }
                        : { backgroundColor: "#FFFFFF" }
                    }
                  >
                    <Grid container justify="center" alignItems="center">
                      <Grid item xs={12}>
                        <img
                          src={therenter}
                          className={classes.renterTypeImg}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography align="center">The renters</Typography>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={3}
                  onClick={() => {
                    this.handleCategorySelection("captain");
                  }}
                >
                  <div
                    className={classes.renterTypeBox}
                    style={
                      this.state.boat_operator === "captain"
                        ? {
                            backgroundColor: "#c8e7ff",
                            border: "solid 2px #004277",
                          }
                        : { backgroundColor: "#FFFFFF" }
                    }
                  >
                    <Grid container justify="center" alignItems="center">
                      <Grid item xs={12}>
                        <img src={caption} className={classes.renterTypeImg} />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography align="center">
                          A certified captain{" "}
                        </Typography>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </Grid>
              <Grid
                container
                justify={"flex-end"}
                item
                xs={12}
                style={{ marginTop: "75px" }}
              >
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={!this.state.boat_operator}
                    onClick={() => {
                      this.updateBoatOpertor();
                    }}
                  >
                    Proceed
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }
}
const BoatDetailsLandingPageWithRouter = withRouter(BoatDetailsLandingPage);
const BoatDetailsLandingPageAlertBox = withAlertBox(
  BoatDetailsLandingPageWithRouter
);
const BoatDetailsLandingPageLoader = withLoader(BoatDetailsLandingPageAlertBox);
const BoatDetailsLandingPageToast = withToast(BoatDetailsLandingPageLoader);

export default withStyles((theme) =>
  createStyles({
    root: {
      height: "calc(100vh - 70px)",
    },
    image: {
      position: "relative",
      backgroundImage: `url(${boatDetail})`,
      backgroundRepeat: "no-repeat",
      backgroundColor:
        theme.palette.type === "light"
          ? theme.palette.grey[50]
          : theme.palette.grey[900],
      backgroundSize: "cover",
      backgroundPosition: "center",
      padding: "120px 68px",
    },
    appBar: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      width: "100%",
      boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
      backgroundColor: "#337eb0",
      height: theme.spacing(8.75),
    },
    toolbar: {
      flexWrap: "nowrap",
    },
    toolbarLogo: {
      flexGrow: 1,
    },
    link: {
      margin: theme.spacing(1, 2.5),
      fontFamily: "Roboto",
      fontSize: "14px",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.14,
      letterSpacing: "normal",
      textTransform: "none",
      cursor: "pointer",
      color: "#fff",
    },
    drawer: {
      [theme.breakpoints.up("sm")]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },
    drawerPaper: {
      width: drawerWidth,
    },
    closeMenuButton: {
      marginRight: 0,
      marginLeft: "auto",
    },
    marginTop: {
      marginTop: theme.spacing(4),
    },
    backButtonBox: {
      width: "50px",
      height: "50px",
      borderRadius: "5px",
      border: "solid 2px #0174cf",
      backgroundColor: "#ffffff",
      textAlign: "center",
      cursor: "pointer",
    },
    backButtonBoxContent: {
      color: "#0174cf",
      fontFamily: "Montserrat",
      fontSize: "20px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "50px",
    },
    renterTypeBox: {
      height: "auto",
      borderRadius: "10px",
      border: "solid 1px #004277",
      backgroundColor: "#ffffff",
      cursor: "pointer",
      textAlign: "center",
    },
    renterTypeImg: {
      width: "100%",
      height: "100%",
    },
    pageHeading: {
      fontFamily: "Montserrat",
      fontSize: "20px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.21,
      letterSpacing: "normal",
      textAlign: "center",
      color: "#004277",
    },
    pageSubHeading: {
      fontFamily: "Montserrat",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "normal",
      textAlign: "center",
      color: "#343a40",
    },
    rightRoot: {
      justifyContent: "center",
      display: "flex",
    },
    overlay: {
      position: "absolute",
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
    },
    mainPostContent: {
      position: "relative",
      padding: theme.spacing(3),
      color: "#FFF",
      [theme.breakpoints.up("md")]: {
        padding: theme.spacing(6),
        paddingRight: 0,
      },
    },
    leftPageHeading: {
      fontFamily: "Montserrat",
      fontSize: "20px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.21,
      letterSpacing: "normal",
      color: "#ffffff",
      paddingLeft: "8px",
    },
    pricing: {
      fontFamily: "Montserrat",
      fontSize: "32px",
      fontWeight: "600",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.22,
      letterSpacing: "normal",
      color: "#ffffff",
    },
    selectOperator: {
      fontFamily: "Montserrat",
      fontSize: "40px",
      fontWeight: "600",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.22,
      letterSpacing: "normal",
      color: "#ffffff",
    },
    leftsidelink: {
      fontFamily: "Montserrat",
      fontSize: "20px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.21,
      letterSpacing: "normal",
      color: "#004277",
    },
    headingline: {
      height: 0,
      width: "33%",
      border: "solid 1px #fff",
    },
    imagetxtcontainer: {
      display: "flex",
      alignItems: "center",
      flexDirection: "row",
    },
  })
)(withBoatIdComponent(BoatDetailsLandingPageToast));
