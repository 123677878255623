//@ts-nocheck
import React from "react";
import BoatInsuranceDetailFormController, {
  Props,
} from "./BoatInsuranceDetailFormController.web";
import {
  Grid,
  AppBar,
  Toolbar,
  Typography,
  createStyles,
  Box,
  ListItem,
  List,
  ListItemText,
  Button,
  withStyles,
  MenuItem
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import { logo,boatDetail, tick } from "./assets";
import * as Yup from "yup";
import { withRouter, Route, Redirect } from "react-router";
import { Formik, Form, Field } from "formik";
import InputField from "../../../components/src/InputFiled.web";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
import withBoatIdComponent from "./withBoatIdComponent.js";

const drawerWidth = 240;
const PageLinks = [
  { displayName: "Support", route: "/faq" },
  { displayName: "Save and exit", route: "saveandexit" },
];
const ForgotPasswordSchema = Yup.object().shape({
  top_speed: Yup.string().required("this field is required"),
  engine_year : Yup.string().required("this field is required"),
  engine_type : Yup.string().required("this field is required"),
  number_of_engines: Yup.string().required("this field is required"),
  horsepower : Yup.string().required("this field is required"),
  hull_material : Yup.string().required("this field is required"),
  liability_coverage : Yup.string().required("this field is required"),
  claim_boat : Yup.string().required("this field is required"),
  customized_boat : Yup.string().required("this field is required"),
});
class BoatInsuranceDetailForm extends BoatInsuranceDetailFormController {
  constructor(props: Props) {
    super(props);
  }
  async componentDidMount() {
    const authToken = await StorageProvider.get("authToken");
    const boatId = await StorageProvider.get("boatId");
    if (authToken) {
      this.setState({
        authToken: authToken,
        boatId: boatId,
      },()=>{
        this.getBoatDetail();
      });
    }
  }
  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };
  submitForm = null;
  goToSelectedRoute = (routeName) => {
    if (routeName === "saveandexit") {
      this.setState({
        isSaveAndExit : true
      },()=>{
        this.submitForm && this.submitForm();
      })
    } else {
      const { history } = this.props;
      history.push(routeName);
    }
  };
  getYears = (startYear) => {
    var currentYear = new Date().getFullYear(), years = [];
    startYear = startYear || 1980;  
    while ( startYear <= currentYear ) {
        years.push(startYear++);
    }   
    return years;
  }
  render() {
    const { classes } = this.props;
    return (
      <>
        <AppBar
          position="static"
          color="default"
          elevation={0}
          className={classes.appBar}
        >
          <Toolbar className={classes.toolbar}>
            <Box className={classes.toolbarLogo}>
              <img onClick={()=>this.props.history.push("/")}  src={logo} style={{width:'22%'}} alt="boaturu" />
            </Box>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              edge="start"
              onClick={this.handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <Hidden xsDown implementation="css">
              <nav>
                {PageLinks.map((link) => {
                  return (
                    <Typography
                      key={link.displayName}
                      variant="button"
                      className={classes.link}
                      onClick={() => {
                        this.goToSelectedRoute(link.route);
                      }}
                    >
                      {link.displayName}
                    </Typography>
                  );
                })}
              </nav>
            </Hidden>
            <Hidden smUp implementation="css">
              <Drawer
                variant="persistent"
                anchor={"right"}
                open={this.state.mobileOpen}
                onClose={this.handleDrawerToggle}
                classes={{
                  paper: classes.drawerPaper,
                }}
                SlideProps={{ unmountOnExit: true }}
              >
                <IconButton
                  onClick={this.handleDrawerToggle}
                  className={classes.closeMenuButton}
                >
                  <CloseIcon />
                </IconButton>
                <List>
                  {PageLinks.map((link, index) => (
                    <ListItem
                      button
                      key={link.displayName}
                      onClick={() => {
                        this.goToSelectedRoute(link.route);
                      }}
                    >
                      <ListItemText primary={link.displayName} />
                    </ListItem>
                  ))}
                </List>
              </Drawer>
            </Hidden>
          </Toolbar>
        </AppBar>
        <Grid container className={classes.root}>
          <Grid
            item
            xs={false}
            sm={4}
            md={5}
            container
            className={classes.image}
          >
            <div className={classes.overlay} />
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <div className={classes.imagetxtcontainer}>
                    <img src={tick} />
                    <Typography
                      display="inline"
                      className={classes.leftPageHeading}
                    >
                      Boat details
                    </Typography>
                  </div>
                  <div className={classes.imagetxtcontainer}>
                    <img src={tick} />
                    <Typography
                      display="inline"
                      className={classes.leftPageHeading}
                    >
                      Pricing
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography gutterBottom className={classes.pricing}>
                Great Progress so far!
              </Typography>
              <div className={classes.headingline} />
              <Typography gutterBottom className={classes.selectOperator}>
                Let's discuss insurance
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography gutterBottom className={classes.leftsidelink}>
                Safety
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={8} md={7}>
            <Formik
              enableReinitialize
              initialValues={{
                top_speed: this.state?.boat_insurance?.top_speed ? this.state?.boat_insurance?.top_speed : "",
                engine_year: this.state?.boat_insurance?.engine_year ? this.state?.boat_insurance?.engine_year : "",
                engine_type: this.state?.boat_insurance?.engine_type ? this.state?.boat_insurance?.engine_type : "",
                number_of_engines: this.state?.boat_insurance?.number_of_engines ? this.state?.boat_insurance?.number_of_engines : "",
                horsepower: this.state?.boat_insurance?.horsepower ? this.state?.boat_insurance?.horsepower : "",
                hull_material: this.state?.boat_insurance?.hull_material ? this.state?.boat_insurance?.hull_material : "",
                liability_coverage: this.state?.boat_insurance?.liability_coverage ? "Yes" : "No",
                claim_boat: this.state?.boat_insurance?.claim_boat ? "Yes" : "No",
                customized_boat: this.state?.boat_insurance?.customized_boat ? "Yes" : "No",
              }}
              validationSchema={ForgotPasswordSchema}
              onSubmit={(values, actions) => {
                this.updateBoatInsurance(values);
              }}
            >
              {(formikProps) => {
                const { values,setFieldValue,handleSubmit,...formik } = formikProps;
                this.submitForm = handleSubmit;
                return (
                  <Form autoComplete="off" noValidate>
                    <Grid
                      container
                      style={{ padding: "16px 32px" }}
                      spacing={3}
                    >
                      <Grid item xs={12} container justify="space-between">
                        <Grid item>
                          <Box
                            className={classes.backButtonBox}
                            onClick={() => {
                              this.goToSelectedRoute("/boatInsurance");
                            }}
                          >
                            <Typography
                              className={classes.backButtonBoxContent}
                            >
                              {"<"}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item>
                          <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={!(formik.isValid)}
                          >
                            Proceed
                          </Button>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          gutterBottom
                          className={classes.rightPageHeading}
                        >
                          Please provide additional details for insurance
                          approval
                        </Typography>
                        <Typography className={classes.rightPageSubHeading}>
                          This will help to get to know your boat better.
                        </Typography>
                      </Grid>
                      <Grid item xs={12} container justify="center" spacing={2}>
                        <Grid item xs={6}>
                          <Field
                            component={InputField}
                            required
                            fullWidth
                            label="Top Speed"
                            name="top_speed"
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Field
                            component={InputField}
                            required
                            fullWidth
                            label="Engine Year"
                            name="engine_year"
                            select
                          >
                            {this.getYears(1950).map((year)=>{
                                  return <MenuItem key={year} value={year}>{year}</MenuItem>
                                })}
                          </Field>
                        </Grid>

                        <Grid item xs={6}>
                          <Field
                            component={InputField}
                            required
                            fullWidth
                            label="Engine Type"
                            name="engine_type"
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Field
                            component={InputField}
                            required
                            fullWidth
                            label="Number of engines"
                            name="number_of_engines"
                            select
                          >
                             {[1,2,3,4,5,6].map((year)=>{
                                  return <MenuItem key={year} value={year}>{year}</MenuItem>
                              })}
                          </Field>
                        </Grid>
                        <Grid item xs={6}>
                          <Field
                            component={InputField}
                            required
                            fullWidth
                            label="Horse Power"
                            name="horsepower"
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Field
                            component={InputField}
                            required
                            fullWidth
                            label="Hull material"
                            name="hull_material"
                          />
                        </Grid>
                      </Grid>

                      <Grid item xs={12} container justify="center" spacing={1}>
                        <Grid xs={12} item>
                          <Typography className={classes.questionHeading}>
                            Does your boat has a liability coverage?
                          </Typography>
                        </Grid>
                        <Grid xs={12} item>
                          <Button
                            style={{ marginRight: "16px" }}
                            variant={values.liability_coverage === 'Yes' ? 'contained' : "outlined"}
                            color="primary"
                            onClick={() => {
                              setFieldValue("liability_coverage", "Yes");
                            }}
                          >
                            Yes
                          </Button>
                          <Button
                            variant={values.liability_coverage === 'No' ? 'contained' : "outlined"}
                            color="primary"
                            onClick={() => {
                              setFieldValue("liability_coverage", "No");
                            }}
                          >
                            No
                          </Button>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} container justify="center" spacing={1}>
                        <Grid xs={12} item>
                          <Typography className={classes.questionHeading}>
                            Can a person or an organisation claim your boat when
                            in loss?
                          </Typography>
                        </Grid>
                        <Grid xs={12} item>
                          <Button
                            style={{ marginRight: "16px" }}
                            variant={values.claim_boat === 'Yes' ? 'contained' : "outlined"}
                            color="primary"
                            onClick={() => {
                              setFieldValue("claim_boat", "Yes");
                            }}
                          >
                            Yes
                          </Button>
                          <Button
                            variant={values.claim_boat === 'No' ? 'contained' : "outlined"}
                            color="primary"
                            onClick={() => {
                              setFieldValue("claim_boat", "No");
                            }}
                          >
                            No
                          </Button>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} container justify="center" spacing={1}>
                        <Grid xs={12} item>
                          <Typography className={classes.questionHeading}>
                            Has your boat been customised?
                          </Typography>
                        </Grid>
                        <Grid xs={12} item>
                          <Button
                            style={{ marginRight: "16px" }}
                            variant={values.customized_boat === 'Yes' ? 'contained' : "outlined"}
                            color="primary"
                            onClick={() => {
                              setFieldValue("customized_boat", "Yes");
                            }}
                          >
                            Yes
                          </Button>
                          <Button
                            variant={values.customized_boat === 'No' ? 'contained' : "outlined"}
                            color="primary"
                            onClick={() => {
                              setFieldValue("customized_boat", "No");
                            }}
                          >
                            No
                          </Button>
                        </Grid>
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        container
                        justify="flex-end"
                        spacing={2}
                      >
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          disabled={!(formik.isValid && formik.dirty)}
                        >
                          Proceed
                        </Button>
                      </Grid>
                    </Grid>
                  </Form>
                );
              }}
            </Formik>
          </Grid>
        </Grid>
      </>
    );
  }
}
const BoatInsuranceDetailFormWithRouter = withRouter(BoatInsuranceDetailForm);
const BoatInsuranceDetailFormAlertBox = withAlertBox(
  BoatInsuranceDetailFormWithRouter
);
const BoatInsuranceDetailFormLoader = withLoader(
  BoatInsuranceDetailFormAlertBox
);
const BoatInsuranceDetailFormToast = withToast(BoatInsuranceDetailFormLoader);

export default withStyles((theme) =>
  createStyles({
    root: {
      height: "calc(100vh - 70px)",
      overflowX: "hidden",
    },
    image: {
      position: "relative",
      backgroundImage: `url(${boatDetail})`,
      backgroundRepeat: "no-repeat",
      backgroundColor:
        theme.palette.type === "light"
          ? theme.palette.grey[50]
          : theme.palette.grey[900],
      backgroundSize: "cover",
      backgroundPosition: "center",
      padding: "120px 68px",
      height: "calc(100vh - 72px)",
    },
    appBar: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      width: "100%",
      boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
      backgroundColor: "#337eb0",
      height: theme.spacing(8.75),
    },
    toolbar: {
      flexWrap: "nowrap",
    },
    toolbarLogo: {
      flexGrow: 1,
    },
    link: {
      margin: theme.spacing(1, 2.5),
      fontFamily: "Roboto",
      fontSize: "14px",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.14,
      letterSpacing: "normal",
      textTransform: "none",
      cursor: "pointer",
      color: "#fff",
    },
    margin: {
      margin: theme.spacing(1),
    },
    drawer: {
      [theme.breakpoints.up("sm")]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },
    drawerPaper: {
      width: drawerWidth,
    },
    closeMenuButton: {
      marginRight: 0,
      marginLeft: "auto",
    },
    marginTop: {
      marginTop: theme.spacing(4),
    },
    backButtonBox: {
      width: "50px",
      height: "50px",
      borderRadius: "5px",
      border: "solid 2px #0174cf",
      backgroundColor: "#ffffff",
      textAlign: "center",
      cursor : 'pointer'
    },
    backButtonBoxContent: {
      color: "#0174cf",
      fontFamily: "Montserrat",
      fontSize: "20px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "50px",
    },
    pageHeading: {
      fontFamily: "Montserrat",
      fontSize: "20px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.21,
      letterSpacing: "normal",
      textAlign: "center",
      color: "#004277",
    },
    pageSubHeading: {
      fontFamily: "Montserrat",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "normal",
      textAlign: "center",
      color: "#343a40",
    },
    rightRoot: {
      justifyContent: "center",
      display: "flex",
    },
    overlay: {
      position: "absolute",
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
    },
    mainPostContent: {
      position: "relative",
      padding: theme.spacing(3),
      color: "#FFF",
      [theme.breakpoints.up("md")]: {
        padding: theme.spacing(6),
        paddingRight: 0,
      },
    },
    imagetxtcontainer: {
      display: "flex",
      alignItems: "center",
      flexDirection: "row",
      marginTop: "6px",
    },
    leftPageHeading: {
      fontFamily: "Montserrat",
      fontSize: "20px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.21,
      letterSpacing: "normal",
      color: "#ffffff",
      paddingLeft: "8px",
    },
    pricing: {
      fontFamily: "Montserrat",
      fontSize: "32px",
      fontWeight: "600",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.22,
      letterSpacing: "normal",
      color: "#ffffff",
    },
    selectOperator: {
      fontFamily: "Montserrat",
      fontSize: "40px",
      fontWeight: "600",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.22,
      letterSpacing: "normal",
      color: "#ffffff",
    },
    leftsidelink: {
      fontFamily: "Montserrat",
      fontSize: "20px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.21,
      letterSpacing: "normal",
      color: "#004277",
    },
    headingline: {
      height: 0,
      width: "90%",
      border: "solid 1px #fff",
    },
    listInsurance: {
      fontFamily: "Montserrat",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "normal",
      color: "#343a40",
      textAlign: "center",
    },
    listnumber: {
      fontFamily: "Montserrat",
      fontSize: "32px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.22,
      letterSpacing: "normal",
      color: "#0174cf",
      textAlign: "center",
    },
    rightPageHeading: {
      fontFamily: "Montserrat",
      fontSize: "20px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.21,
      letterSpacing: "normal",
      color: "#004277",
    },
    rightPageSubHeading: {
      fontFamily: "Montserrat",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "normal",
      color: "#343a40",
    },
    questionHeading: {
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "normal",
      color: "#343a40",
    },
  })
)(withBoatIdComponent(BoatInsuranceDetailFormToast));
