//@ts-nocheck

import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { dialogBoxProps  } from "../../../components/src/withDialog.web";
import { withAlertBoxProps } from "../../../components/src/withAlertBox.Web";
import { withToastProps } from "../../../components/src/withSnackBar.Web";
import { withLoaderProps } from "../../../components/src/withLoader.Web";
export const configJSON = require("./config");
import moment from 'moment'
export type Props = RouterProps & dialogBoxProps & withAlertBoxProps & withToastProps & withLoaderProps & {
  id: string;
  openConfirmDialog : string;
  // Customizable Area Start
  // Customizable Area End
};

interface S {
  // Customizable Area Start
  authToken : String,
  overviewData : any;
  tableHeaders : Array<string>
  tableRows : Array<any>,
  chartOption : any
  ratingInfo : any
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class OwnerOverviewController extends BlockComponent<
  Props,
  S,
  SS
> {
  getOwnerOverviewApiCallId : String = "";
  getPersonalInfoApiCallId : String = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
      // Customizable Area End
    ];

    this.state = {
      authToken : '',
      overviewData : {},
      tableHeaders : [],
      tableRows : [],
      chartOption : null,
      ratingInfo : null
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
       if (apiRequestCallId === this.getOwnerOverviewApiCallId) {
        this.props.hideLoader();
        if (responseJson && responseJson?.boat_views_this_year) {
            this.setState({
                overviewData : responseJson

            },()=>{
                this.processTableData(this.state.overviewData?.boat_bookings)
                this.processChartData(this.state.overviewData?.boat_views_this_year)
            })
        } else {
          this.parseApiErrorResponse(responseJson);
        }
      } else if (apiRequestCallId === this.getPersonalInfoApiCallId) {
        if (responseJson && responseJson?.data && responseJson?.data?.id && responseJson?.data?.attributes) {
          this.setState({
               ratingInfo : responseJson?.data?.attributes
          })
        } else {
          this.setState({
            ratingInfo : null
          },()=>{
            this.parseApiErrorResponse()
          })
        }
      } 
    } else if (getName(MessageEnum.AlertMessage) === message.id) {
      const title = message.getData(getName(MessageEnum.AlertTitleMessage));
      var AlertBodyMessage = message.getData(
        getName(MessageEnum.AlertBodyMessage)
      );
      const msg = await this.props.showAlert({title : 'Error',message : AlertBodyMessage})
      if(msg === 'Your session has expired, please login again.' || msg === 'Invalid token'){
        await StorageProvider.remove("authToken")
        await StorageProvider.remove("accountInfo")  
        this.props.history.push("/basicAuth/login")
      }
    }
  }
  processTableData = (data : any) => {
    var months = []
    for(let key in data){
        const temp = {};
        const temp1 = JSON.parse([key])
        temp.month = moment(new Date(temp1[1],temp1[2])).format("MMM YYYY"); 
        temp.boatName =  temp1[0]
        temp.count = data[key]
        months.push(temp)
    }  
    const headers = [];
    for(let index=0;index < months.length;index++){
        if(!(headers.indexOf(months[index].month) > -1)){
                headers.push(months[index].month)
        }
    }
    const values = [];
    for(let index=0;index < months.length;index++){
    const ii = values.findIndex((d)=>(months[index].boatName === d.boatName))
    if((ii > -1)){
            values[ii] = {...values[ii],[months[index].month] : months[index].count}
    } else {
        values.push({boatName : months[index].boatName,[months[index].month] : months[index].count})
    }
   
}  
    this.setState({
        tableHeaders : headers,
        tableRows : values
    })
  }
  processChartData = (data) => {
    var category = []
    var values  = []
    for(let key in data){
        const a = JSON.parse(key)
        category.push(moment(new Date(a[0],a[1])).format("MMM YYYY"));
        values.push(data[key]);
    }
    this.setState({
        chartOption : {
            options: {
              chart: {
                id: "basic-bar",
                toolbar: { show: false },
                type: "bar",
              },
              xaxis: {
                categories: category,
              },
              plotOptions: {
                bar: {
                  borderRadius: 3,
                  columnWidth: 38,
                },
                states: {
                  hover: {
                    filter: {
                      type: "none",
                    },
                  },
                },
              },
              colors: "#37ceff",
              dataLabels: {
                enabled: false,
              },
              grid: {
                show: false,
                yaxis: {
                  lines: {
                    show: true,
                  },
                },
                xaxis: {
                  lines: {
                    show: true,
                  },
                },
              },
              yaxis: {
                show: true,
                axisBorder: {
                  show: true,
                },
              },
            },
            series: [
              {
                name: "views",
                data: values,
              },
            ],
          }
    })
  }
   
  getOwnerOverview() {
    this.props.showLoader();
    const headers = {
      token : this.state.authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getOwnerOverviewApiCallId = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_catalogue/boats/boat_owner_overview_page_data`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getPersonalInfo() {
    this.props.showLoader();
    const headers = {
      "content-type": configJSON.ApiContentType,
      token : this.state.authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getPersonalInfoApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.personalProfieInfo}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  
}
