//@ts-nocheck
import React from "react";
import UserDashboardContainerTempController, {
  Props,
} from "./UserDashboradContainerTempController.web";
import {
  withStyles,
  Grid,
  AppBar,
  Toolbar,
  Typography,
  createStyles,
  Box,
  ListItem,
  List,
  ListItemText,
  ListItemAvatar,
  ListItemIcon,
  Radio,
  Button,
  Avatar,
  Paper,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import AccountCircle from "@material-ui/icons/AccountCircle";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import { logo, boatDetail } from "./assets";
import { withRouter, Route, Switch, Redirect } from "react-router";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
import withConfirmBox from "../../../components/src/withConfirmBox.Web";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";

import CustomerPernoalInfo from "./CustomerPernoalInfo.web";
import CustomerAccountInfo from "./CustomerAccountInfo.web";
import CustomerBookings from "./CustomerBookings.web";
import CustomerInbox from "./CustomerInbox.web";
import CustomerPaymentOptions from "./CustomerPaymentOptions.web";
import CustomerDocumentsInfo from "./CustomerDocumentsInfo.web";
import BoatSearchResult from "./BoatSearchResult.web";
import SelectedBoatDetails from "./SelectedBoatDetails.web";
import BoatComparison from "./BoatComparison.web";
import { NavLink } from "react-router-dom";
import PrivateRoute from "../../../components/src/PrivateRoute.web";
import UserBookings from "./UserBookings.web";
const drawerWidth = 240;
const sideBarLinks = [
  { displayName: "My Documents", route: "/userDashboard/myDocuments" },
  { displayName: "My Bookings", route: "/userDashboard/myBookings" },
  { displayName: "Messages", route: "/userDashboard/messages" },
  {
    displayName: "Personal Information",
    route: "/userDashboard/personalInformation",
  },
  { displayName: "Payment", route: "/userDashboard/payment" },
  { displayName: "Account Settings", route: "/userDashboard/accountSettings" },
];
class UserDashboardContainerTemp extends UserDashboardContainerTempController {
  constructor(props: Props) {
    super(props);
  }
  async componentDidMount() {
    const authToken = await StorageProvider.get("authToken");
    // const accountInfo = await StorageProvider.get("accountInfo");
    if (authToken) {
      this.setState(
        {
          authToken: authToken,
          // accountInfo : JSON.parse(accountInfo)
        },
        () => {
          this.getPersonalInfo();
        }
      );
    }
  }

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };

  goToSelectedRoute = (routeName) => {
    this.handleClose();
    this.setState({ mobileOpen: false });
    if (routeName === "logout") {
      const onClose = async (result) => {
        if (result === "Yes") {
          await StorageProvider.clearStorage();
          this.props.history.push("/");
        }
      };
      this.props.openConfirmDialog(
        "Logout",
        "Are you sure you want to logout from application",
        onClose
      );
    } else {
      const { history } = this.props;
      history.push(routeName);
    }
  };
  handleClose = () => {
    this.setState({
      anchorEl: null,
    });
  };
  handleMenu = (event) => {
    if (this.state.authToken) {
      this.setState({
        anchorEl: event.currentTarget,
      });
    }
  };
  onSelectLocation = (location) => {
    this.setState(
      {
        suggestionloading: false,
        isSearched: true,
      },
      () => {
        this.props.history.push({
          pathname: "/userDashboard/boatSearchResult/" + location?.name,
          state: { ...location, location: location?.name },
        });
      }
    );
  };
  render() {
    const { classes } = this.props;
    let PageLinks = [];
    const menuLinks = [
      { displayName: "My Account", route: "/userDashboard" },
      { displayName: "Logout", route: "logout" },
    ];
    if (this.state.authToken) {
      PageLinks = [
        { displayName: "List your boat", route: "/ownerDashboard" },
        { displayName: "Inbox", route: "/userDashboard/messages" },
        // { displayName: "Bookings", route: "/userDashboard/myBookings" },
      ];
    } else {
      PageLinks = [
        { displayName: "List your boat", route: "/ownerDashboard" },
        { displayName: "Support", route: "/" },
        { displayName: "Login", route: "/basicAuth/login" },
        { displayName: "Signup", route: "/basicAuth/signup" },
      ];
    }
    return (
      <>
        <AppBar
          position="static"
          color="default"
          elevation={0}
          className={classes.appBar}
        >
          <Toolbar className={classes.toolbar}>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              edge="start"
              onClick={this.handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <Hidden smUp>
              <Box className={classes.toolbarLogo}>
                {/* <Typography className={classes.logo}>Logo</Typography> */}
                <img
                  onClick={() => this.props.history.push("/")}
                  src={logo}
                  style={{ width: "40%" }}
                />
              </Box>
            </Hidden>

            <Hidden xsDown>
              <img
                onClick={() => this.props.history.push("/")}
                src={logo}
                style={{ width: "22%" }}
              />
            </Hidden>
            <Hidden xsDown>
              <Autocomplete
                id="search boat"
                freeSolo
                style={{ width: "35%" }}
                filterOptions={(x) => x}
                renderOption={(value) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                      }}
                    >
                      <div style={{ flexGrow: 1 }}>
                        <Typography gutterBottom color="textPrimary">
                          {value?.name}
                        </Typography>
                        <Typography color="textSecondary">
                          {value?.address}
                        </Typography>
                      </div>
                    </div>
                  );
                }}
                getOptionLabel={(option) => option?.name}
                options={this.state.searchSuggestionList}
                loading={false}
                onInputChange={(evt) => {
                  this.getLocationSuggestion(evt.target.value);
                }}
                onChange={(evt, value) => {
                  this.onSelectLocation(value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Where do you want to boat?"
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {this.state.suggestionloading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {
                            <InputAdornment position="end">
                              <IconButton aria-label="search">
                                <SearchIcon />
                              </IconButton>
                            </InputAdornment>
                          }
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </Hidden>
            <Hidden xsDown>
              <div
                style={
                  PageLinks.length > 2
                    ? { marginLeft: "0%" }
                    : { marginLeft: "10%" }
                }
              >
                {PageLinks.map((link) => {
                  return (
                    <Typography
                      key={link.displayName}
                      variant="button"
                      className={classes.link}
                      onClick={() => {
                        this.goToSelectedRoute(link.route);
                      }}
                    >
                      {link.displayName}
                    </Typography>
                  );
                })}
              </div>
            </Hidden>

            <Hidden smUp implementation="css">
              <Drawer
                variant="persistent"
                anchor={"left"}
                open={this.state.mobileOpen}
                onClose={this.handleDrawerToggle}
                classes={{
                  paper: classes.drawerPaper,
                }}
                SlideProps={{ unmountOnExit: true }}
              >
                <IconButton
                  onClick={this.handleDrawerToggle}
                  className={classes.closeMenuButton}
                >
                  <CloseIcon />
                </IconButton>
                <List>
                  {sideBarLinks.map((link, index) => (
                    <ListItem
                      button
                      key={link.displayName}
                      onClick={() => {
                        this.goToSelectedRoute(link.route);
                      }}
                    >
                      <ListItemText primary={link.displayName} />
                    </ListItem>
                  ))}
                </List>
              </Drawer>
            </Hidden>
            <Hidden xsDown>
              {this.state?.accountInfo?.avatar?.url ? (
                <>
                  <Avatar src={this.state?.accountInfo?.avatar?.url} />
                  <Typography
                    display="inline"
                    style={{ paddingLeft: "10px" }}
                    color="textSecondary"
                  >
                    {(this.state.accountInfo?.first_name || "") +
                      " " +
                      (this.state.accountInfo?.last_name || "")}
                  </Typography>
                  <IconButton onClick={this.handleMenu}>
                    <ArrowDropDownIcon />
                  </IconButton>
                </>
              ) : (
                <>
                  <Avatar>{`${this.state.accountInfo?.first_name?.charAt(0) ||
                    ""} ${this.state.accountInfo?.last_name?.charAt(0) ||
                    ""}`}</Avatar>
                  <Typography
                    display="inline"
                    style={{ paddingLeft: "10px" }}
                    color="textSecondary"
                  >
                    {(this.state.accountInfo?.first_name || "") +
                      " " +
                      (this.state.accountInfo?.last_name || "")}
                  </Typography>
                  <IconButton onClick={this.handleMenu}>
                    <ArrowDropDownIcon />
                  </IconButton>
                </>
              )}
              <Menu
                id="menu-appbar"
                anchorEl={this.state.anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={!!this.state.anchorEl}
                onClose={this.handleClose}
              >
                {menuLinks.map((link) => {
                  return (
                    <MenuItem
                      key={link.displayName}
                      classes={{
                        root: classes.menuItem,
                      }}
                      onClick={() => {
                        this.goToSelectedRoute(link.route);
                      }}
                    >
                      {link.displayName}
                    </MenuItem>
                  );
                })}
              </Menu>
            </Hidden>
            <Hidden smUp>
              {this.state?.accountInfo?.avatar?.url ? (
                <Grid container>
                  <Grid item>
                    <Avatar
                      src={this.state?.accountInfo?.avatar?.url}
                      onClick={this.handleMenu}
                    />
                  </Grid>
                  <Grid item>
                    <IconButton>
                      <ArrowDropDownIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              ) : (
                <Grid container>
                  <Grid item>
                    <Avatar
                      onClick={this.handleMenu}
                    >{`${this.state.accountInfo?.first_name?.charAt(0) ||
                      ""} ${this.state.accountInfo?.last_name?.charAt(0) ||
                      ""}`}</Avatar>
                  </Grid>
                  <Grid item>
                    <IconButton>
                      <ArrowDropDownIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              )}
              <Menu
                id="menu-appbar"
                anchorEl={this.state.anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={!!this.state.anchorEl}
                onClose={this.handleClose}
              >
                {PageLinks.concat(menuLinks).map((link) => {
                  return (
                    <MenuItem
                      key={link.displayName}
                      classes={{
                        root: classes.menuItem,
                      }}
                      onClick={() => {
                        this.goToSelectedRoute(link.route);
                      }}
                    >
                      {link.displayName}
                    </MenuItem>
                  );
                })}
              </Menu>
            </Hidden>
          </Toolbar>
        </AppBar>
        <Hidden smUp>
          <Autocomplete
            id="search boat"
            freeSolo
            style={{ width: "100%" }}
            filterOptions={(x) => x}
            renderOption={(value) => {
              return (
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                  }}
                >
                  <div style={{ flexGrow: 1 }}>
                    <Typography gutterBottom color="textPrimary">
                      {value?.name}
                    </Typography>
                    <Typography color="textSecondary">
                      {value?.address}
                    </Typography>
                  </div>
                </div>
              );
            }}
            getOptionLabel={(option) => option?.name}
            options={this.state.searchSuggestionList}
            loading={false}
            onInputChange={(evt) => {
              this.getLocationSuggestion(evt.target.value);
            }}
            onChange={(evt, value) => {
              this.onSelectLocation(value);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Where do you want to boat?"
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {this.state.suggestionloading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {
                        <InputAdornment position="end">
                          <IconButton aria-label="search">
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                    </React.Fragment>
                  ),
                }}
              />
            )}
          />
        </Hidden>
        <Switch>
          <Route path="/userDashboard/boatSearchResult/:id" exact>
            <BoatSearchResult />
          </Route>
          <Route path="/userDashboard/selectedBoatDetails" exact>
            <SelectedBoatDetails />
          </Route>
          <Route path="/userDashboard/boatCompasison" exact>
            <BoatComparison />
          </Route>
          <PrivateRoute path="/userDashboard/myDocuments" exact>
            <CustomerDocumentsInfo />
          </PrivateRoute>
          <PrivateRoute path="/userDashboard/myBookings" exact>
            <UserBookings />
          </PrivateRoute>
          <PrivateRoute path="/userDashboard/messages" exact>
            <CustomerInbox showSideBar={false} />
          </PrivateRoute>
          <PrivateRoute path="/userDashboard/personalInformation" exact>
            <CustomerPernoalInfo />
          </PrivateRoute>
          <PrivateRoute path="/userDashboard/payment" exact>
            <CustomerPaymentOptions />
          </PrivateRoute>
          <PrivateRoute path="/userDashboard/accountSettings" exact>
            <CustomerAccountInfo />
          </PrivateRoute>
          <Redirect
            exact
            from="/userDashboard"
            to="/userDashboard/personalInformation"
          />
        </Switch>
      </>
    );
  }
}
const UserDashboardContainerTempWithRouter = withRouter(
  UserDashboardContainerTemp
);
const UserDashboardContainerTempAlertBox = withAlertBox(
  UserDashboardContainerTempWithRouter
);
const UserDashboardContainerTempLoader = withLoader(
  UserDashboardContainerTempAlertBox
);
const UserDashboardContainerTempToast = withToast(
  UserDashboardContainerTempLoader
);
export default withStyles((theme) =>
  createStyles({
    root: {
      height: "calc(100vh - 70px)",
      overflowX: "hidden",
    },
    layout: {
      width: "auto",
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
        width: "80%",
        marginLeft: "auto",
        marginRight: "auto",
      },
    },
    paper: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      padding: theme.spacing(4),
      [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(6),
        padding: theme.spacing(3),
      },
    },
    sideBar: {
      height: "calc(100vh - 72px)",
      backgroundColor: "#ffffff",
    },
    appBar: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      width: "100%",
      boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
      backgroundColor: " #ffffff",
      height: theme.spacing(8.75),
    },
    toolbar: {
      flexWrap: "nowrap",
    },
    toolbarLogo: {
      flexGrow: 1,
    },
    link: {
      margin: theme.spacing(1, 2.5),
      fontFamily: "Montserrat",
      fontSize: "14px",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "normal",
      textTransform: "none",
      cursor: "pointer",
      color: "#545454",
    },
    menuItem: {
      fontFamily: "Montserrat",
      fontSize: "14px",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "normal",
      textTransform: "none",
      cursor: "pointer",
      color: "#343a40",
    },
    margin: {
      marginRight: theme.spacing(2),
    },
    drawer: {
      [theme.breakpoints.up("sm")]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    menuButton: {
      color: "#000",
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },
    drawerPaper: {
      width: drawerWidth,
    },
    closeMenuButton: {
      marginRight: 0,
      marginLeft: "auto",
    },
    marginTop: {
      marginTop: theme.spacing(4),
    },
    rightRoot: {
      backgroundImage:
        "linear-gradient(180deg, rgba(0,0,255,1) 30%, rgba(255,255,255,1) 30%)",
    },
    sideLinkHeading: {
      fontFamily: "Montserrat",
      fontSize: "20px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "normal",
      color: "#183b56",
    },
    sideLink: {
      fontFamily: "Montserrat",
      fontSize: "12px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.22,
      letterSpacing: "normal",
      color: "#343a40",
      cursor: "pointer",
      textAlign: "center",
      display: "block",
      textDecoration: "none",
      "&:hover": {
        textDecoration: "none",
        textTransform: "none",
      },
    },
    activeSideLink: {
      backgroundColor: "rgb(1,116,207,0.1)",
      color: "#343a40",
      textTransform: "none",
      textDecoration: "none",
      cursor: "pointer",
    },
    primaryListText: {
      fontFamily: "Montserrat",
      fontSize: "20px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.21,
      letterSpacing: "normal",
      color: "#343a40",
    },
    secondaryListText: {
      fontFamily: "Montserrat",
      fontSize: "12px",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "normal",
      color: "#343a40",
      paddingTop: "12px",
    },
    formControl: {
      margin: theme.spacing(3),
    },
    checkBoxRoot: {
      display: "flex",
    },
    checkBoxHeading: {
      fontFamily: "Montserrat",
      fontSize: "18px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.21,
      letterSpacing: "normal",
      color: "#004277",
    },
    checkBoxLabel: {
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "normal",
      color: "#343a40",
    },
    logo: {
      fontFamily: "Roboto",
      fontSize: "20px",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.16,
      letterSpacing: "1px",
      color: "#ffffff",
      marginLeft: "24px",
    },
    sideBarLinks: {
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "1px",
      color: "#343a40",
    },
    sideBarHeading: {
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "1px",
      color: "#004277",
      padding: "12px",
    },
    sideBarButton: {
      backgroundColor: "red",
    },
  })
)(withConfirmBox(UserDashboardContainerTempToast));
