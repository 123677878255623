//@ts-nocheck
import React from "react";
import BoatDescriptionDetailController, {
  Props,
} from "./BoatDescriptionDetailController.web";
import {
  withStyles,
  Grid,
  AppBar,
  Toolbar,
  Typography,
  createStyles,
  Box,
  ListItem,
  List,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Button,
  TextField,
} from "@material-ui/core";

import { Formik, Form, Field } from "formik";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import { logo,boatDetail, commercial } from "./assets";
import * as Yup from "yup";
import { withRouter } from "react-router";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
import withBoatIdComponent from "./withBoatIdComponent.js";

const drawerWidth = 240;
const PageLinks = [
  { displayName: "Support", route: "/faq" },
  { displayName: "Save and Exit", route: "saveandexit" },
];

const boatDesSchema = Yup.object().shape({
  boatDescription: Yup.string().required("this field is required").nullable()
     .test('len', 'Must be less than 1000 characters', val => val && val.length <= 1000)
});
class BoatDescriptionDetail extends BoatDescriptionDetailController {
  constructor(props: Props) {
    super(props);
  }
  submitForm = null;
  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };
  async componentDidMount(){
    const authToken = await StorageProvider.get("authToken");
    const boatId = await StorageProvider.get("boatId");
    if (authToken) {
      this.setState(
        {
          authToken: authToken,
          boatId : boatId
        },()=>{
          this.getBoatDetail();
        }       
      );
    }
  }
  goToSelectedRoute = (routeName) => {
    if (routeName === "saveandexit") {
      this.setState({
        isSaveAndExit : true
      },()=>{
        this.submitForm && this.submitForm();
      })
    } else {
      const { history } = this.props;
      history.push(routeName);
    }
  };
  handleCategorySelection = (category) => {
    this.setState({
      selectedCategory: category,
    });
  };
  
  render() {
    const { classes } = this.props;
    return (
      <>
        <AppBar
          position="static"
          color="default"
          elevation={0}
          className={classes.appBar}
        >
          <Toolbar className={classes.toolbar}>
            <Box className={classes.toolbarLogo}>
              <img onClick={()=>this.props.history.push("/")}  src={logo} style={{width:'22%'}} alt="boaturu" />
            </Box>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              edge="start"
              onClick={this.handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <Hidden xsDown implementation="css">
              <nav>
                {PageLinks.map((link) => {
                  return (
                    <Typography
                      key={link.displayName}
                      variant="button"
                      className={classes.link}
                      onClick={() => {
                        this.goToSelectedRoute(link.route);
                      }}
                    >
                      {link.displayName}
                    </Typography>
                  );
                })}
              </nav>
            </Hidden>
            <Hidden smUp implementation="css">
              <Drawer
                variant="persistent"
                anchor={"right"}
                open={this.state.mobileOpen}
                onClose={this.handleDrawerToggle}
                classes={{
                  paper: classes.drawerPaper,
                }}
                SlideProps={{ unmountOnExit: true }}
              >
                <IconButton
                  onClick={this.handleDrawerToggle}
                  className={classes.closeMenuButton}
                >
                  <CloseIcon />
                </IconButton>
                <div>
                  <List>
                    {PageLinks.map((link, index) => (
                      <ListItem
                        button
                        key={link.displayName}
                        onClick={() => {
                          this.goToSelectedRoute(link.route);
                        }}
                      >
                        <ListItemText primary={link.displayName} />
                      </ListItem>
                    ))}
                  </List>
                </div>
              </Drawer>
            </Hidden>
          </Toolbar>
        </AppBar>
        <Grid container className={classes.root}>
          <Grid
            item
            xs={false}
            sm={4}
            md={5}
            container
            className={classes.image}
          >
            <div className={classes.overlay} />
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={12} />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography gutterBottom className={classes.pricing}>
                Boat Details
              </Typography>
              <div className={classes.headingline} />
              <Typography gutterBottom className={classes.selectOperator}>
              Describe your boat
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography gutterBottom className={classes.leftsidelink}>
                Pricing
              </Typography>
              <Typography gutterBottom className={classes.leftsidelink}>
                Insurance
              </Typography>
              <Typography gutterBottom className={classes.leftsidelink}>
                Safety and standards
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={8} md={7}>
            <Formik
              initialValues={{
                boatDescription: this.state.boatDescription,
              }}
              enableReinitialize
              validationSchema={boatDesSchema}
              onSubmit={async (values, actions) => {
                this.saveBoatDetail(values);
              }}
            >
              {(formikProps) => {
                const { values,errors,touched, handleBlur, handleChange,handleSubmit,isSubmitting,dirty,isValid } = formikProps;
                this.submitForm = handleSubmit;
                return (
                  <Form noValidate autoComplete="off">
                    <Grid
                      container
                      style={{ padding: "16px 32px" }}
                      spacing={3}
                    >
                      <Grid item xs={12} container justify="space-between">
                        <Grid item>
                          <Box className={classes.backButtonBox} onClick={() => {
                              this.goToSelectedRoute("/boatTitle");
                            }}>
                            <Typography
                              className={classes.backButtonBoxContent}
                            >
                              {"<"}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} container justify="center">
                        <Grid item xs={10}>
                          <Typography
                            align="center"
                            className={classes.rightPageHeading}
                            gutterBottom
                          >
                            Tell your renters why your boat is perfect for them
                          </Typography>
                          <Typography
                            align="center"
                            className={classes.rightPageSubHeading}
                            gutterBottom
                          >
                            You can always edit this later
                          </Typography>
                          <TextField
                            InputProps={{
                              classes: {
                                root: classes.input,
                              },
                              maxLength: 1000,
                              inputProps : {
                                maxLenght : 1000
                              }
                            }}
                            multiline
                            rows={10}
                            error={errors.boatDescription && touched.boatDescription ? true: false}
                            helperText={errors.boatDescription && touched.boatDescription ? errors.boatDescription: ""}
                            fullWidth
                            classes={classes.input}
                            value={values.boatDescription}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            variant="outlined"
                            placeholder="Type your boat Description"
                            name="boatDescription"
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={12} container justify="center">
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          disabled={!isValid && dirty}
                        >
                          Proceed
                        </Button>
                      </Grid>
                    </Grid>
                    <Grid container justify="center" style={{ padding: "16px 32px" }}>
                      <Grid item>
                        <Typography variant="h6" className={classes.title}>
                        Try to answer the following questions in description
                        </Typography>
                        <div className={classes.demo}>
                          <List dense={true}>
                            {["How is your boat special?", "What are the features on your boat?", "How far can you take your boat?"].map((feature) => (
                              <ListItem key={feature}>
                                <ListItemText
                                  classes={{
                                    primary: classes.listTitleName,
                                  }}
                                  primary={feature}
                                />
                              </ListItem>
                            ))}
                          </List>
                        </div>
                      </Grid>
                    </Grid>
                  </Form>
                );
              }}
            </Formik>
          </Grid>
        </Grid>
      </>
    );
  }
}
const BoatDescriptionDetailWithRouter = withRouter(BoatDescriptionDetail);
const BoatDescriptionDetailAlertBox = withAlertBox(BoatDescriptionDetailWithRouter);
const BoatDescriptionDetailLoader = withLoader(BoatDescriptionDetailAlertBox);
const BoatDescriptionDetailToast = withToast(BoatDescriptionDetailLoader);
export default withStyles((theme) =>
  createStyles({
    root: {
      height: "calc(100vh - 70px)",
      overflowX: "hidden",
    },
    image: {
      position: "relative",
      backgroundImage: `url(${boatDetail})`,
      backgroundRepeat: "no-repeat",
      backgroundColor:
        theme.palette.type === "light"
          ? theme.palette.grey[50]
          : theme.palette.grey[900],
      backgroundSize: "cover",
      backgroundPosition: "center",
      padding: "120px 68px",
      height: "calc(100vh - 72px)",
    },
    appBar: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      width: "100%",
      boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
      backgroundColor: "#337eb0",
      height: theme.spacing(8.75),
    },
    toolbar: {
      flexWrap: "nowrap",
    },
    toolbarLogo: {
      flexGrow: 1,
    },
    link: {
      margin: theme.spacing(1, 2.5),
      fontFamily: "Roboto",
      fontSize: "14px",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.14,
      letterSpacing: "normal",
      textTransform: "none",
      cursor: "pointer",
      color: "#fff",
    },
    margin: {
      marginRight: theme.spacing(2),
    },
    drawer: {
      [theme.breakpoints.up("sm")]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },
    drawerPaper: {
      width: drawerWidth,
    },
    closeMenuButton: {
      marginRight: 0,
      marginLeft: "auto",
    },
    marginTop: {
      marginTop: theme.spacing(4),
    },
    backButtonBox: {
      width: "50px",
      height: "50px",
      borderRadius: "5px",
      border: "solid 2px #0174cf",
      backgroundColor: "#ffffff",
      textAlign: "center",
      cursor : 'pointer' 
    },
    backButtonBoxContent: {
      color: "#0174cf",
      fontFamily: "Montserrat",
      fontSize: "20px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "50px",
    },
    pageHeading: {
      fontFamily: "Montserrat",
      fontSize: "30px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.23,
      letterSpacing: "normal",
      textAlign: "center",
      color: "#183b56",
    },
    pageSubHeading: {
      fontFamily: "Montserrat",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "normal",
      textAlign: "center",
      color: "#343a40",
    },
    rightRoot: {
      justifyContent: "center",
      display: "flex",
    },
    overlay: {
      position: "absolute",
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
    },
    mainPostContent: {
      position: "relative",
      padding: theme.spacing(3),
      color: "#FFF",
      [theme.breakpoints.up("md")]: {
        padding: theme.spacing(6),
        paddingRight: 0,
      },
    },
    imagetxtcontainer: {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      margin: "auto",
    },
    leftPageHeading: {
      fontFamily: "Montserrat",
      fontSize: "20px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.21,
      letterSpacing: "normal",
      color: "#ffffff",
      paddingLeft: "8px",
    },
    pricing: {
      fontFamily: "Montserrat",
      fontSize: "32px",
      fontWeight: "600",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.22,
      letterSpacing: "normal",
      color: "#ffffff",
    },
    selectOperator: {
      fontFamily: "Montserrat",
      fontSize: "40px",
      fontWeight: "600",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.22,
      letterSpacing: "normal",
      color: "#ffffff",
    },
    leftsidelink: {
      fontFamily: "Montserrat",
      fontSize: "20px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.21,
      letterSpacing: "normal",
      color: "#004277",
    },
    headingline: {
      height: 0,
      width: "90%",
      border: "solid 1px #fff",
    },
    rightPageHeading: {
      fontFamily: "Montserrat",
      fontSize: "20px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.21,
      letterSpacing: "normal",
      color: "#004277",
      textAlign: "center",
    },
    rightPageSubHeading: {
      fontFamily: "Montserrat",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "normal",
      color: "#343a40",
      textAlign: "center",
      paddingTop: "16px",
    },
    input: {
      borderRadius: "6px!important",
    },
    demo: {
      backgroundColor: theme.palette.background.paper,
    },
    title: {
      margin: theme.spacing(4, 0, 2),
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "normal",
      color: "#343a40",
    },
    listTitleName: {
      fontFamily: "Montserrat",
      fontSize: "12px",
      fontWeight: 'normal',
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "normal",
      color: "#343a40",
      textAlign: "center",
    },
  })
)(withBoatIdComponent(BoatDescriptionDetailToast));
