// index.js - WEB
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import registerServiceWorker from '../../components/src/registerServiceWorker';
import { ThemeProvider, CssBaseline } from '@material-ui/core';
import theme from './theme';
import { AlertDialogProvider } from '../../components/src/AlertBoxContext';
import { ToastProvider } from '../../components/src/ToastContext';
import { DialogProvider } from '../../components/src/DialogContext';
import { PayPalScriptProvider } from "@paypal/react-paypal-js";

ReactDOM.render(
  

  <Router>
    <PayPalScriptProvider  options={{ 
      "client-id": "ARNDSepC3aLAEg8gVIR96bZZ-whuIH2FMqoQTtVXlI7mfpbYTVJNQEqphLV7OIgk7opMGomQBbXWpQiU",
      currency: "USD",
      intent: "capture",
       }}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <AlertDialogProvider>
            <DialogProvider>
              <ToastProvider>
                <App />
              </ToastProvider>
            </DialogProvider>
          </AlertDialogProvider>
        </ThemeProvider>
    </PayPalScriptProvider>
    </Router>,
  document.getElementById('root')
);
registerServiceWorker();
