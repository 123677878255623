//@ts-nocheck
import React from "react";
import CreateMarinaController, { Props } from "./CreateMarinaController.web";
import {
  withStyles,
  Grid,
  Typography,
  createStyles,
  Button,
  Paper,
  Link,
  Box,
  Container,
  TextField,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
} from "@material-ui/core";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { boatImage } from "./assets";
import { withRouter } from "react-router";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
import { Formik, Form } from "formik";
import * as Yup from "yup";
const Schema = Yup.object().shape({
  name: Yup.string().required("This field is required."),
  address1: Yup.string().required("This field is required."),
  address2: Yup.string().required("This field is required."),
  state: Yup.string().required("This field is required."),
  city: Yup.string().required("This field is required."),
  zipcode : Yup.string().required("This field is required."),
  latitude: Yup.string().required("This field is required."),
  longitude: Yup.string().required("This field is required."),
  
});
class CreateMarina extends CreateMarinaController {
  constructor(props: Props) {
    super(props);
  }
  goToSelectedRoute = (routeName) => {
    const { history } = this.props;
    history.push(routeName);
  };
  closeDialog = (data) => {
    const { onSubmit } = this.props;
    onSubmit(data);
  };
  async componentDidMount() {
    const authToken = await StorageProvider.get("authToken");
    if(authToken){
        this.setState({
            authToken : authToken
        })
    }
  } 
  render() {
    const { classes } = this.props;
    return (
      <Formik
        initialValues={{
            name: this.props?.dataToPass?.marinaName || "",
            address1: "",
            address2: "",
            state: "",
            city: "",
            latitude: "",
            longitude: "",
            zipcode : ""
        }}
        validationSchema={Schema}
        onSubmit={(values, actions) => {
           this.createMarina(values);
        }}
      >
        {(formikProps) => {
          const {
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            isValid,
            dirty,
          } = formikProps;
          return (
            <Form noValidate autoComplete="off">
              <Grid container justify="space-between" spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    InputProps={{
                      classes: {
                        root: classes.input,
                      },
                    }}
                    fullWidth
                    classes={classes.input}
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.name && touched.name ? errors.name: ""}
                    helperText={errors.name && touched.name ? errors.name: ""}
                    
                    variant="outlined"
                    placeholder="Enter marina name"
                    name="name"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    InputProps={{
                      classes: {
                        root: classes.input,
                      },
                    }}
                    error={errors.address1 && touched.address1 ? errors.address1: ""}
                    helperText={errors.address1 && touched.address1 ? errors.address1: ""}
                    fullWidth
                    classes={classes.input}
                    value={values.address1}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    variant="outlined"
                    placeholder="Enter marina address1"
                    name="address1"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    InputProps={{
                      classes: {
                        root: classes.input,
                      },
                    }}
                    error={errors.address2 && touched.address2 ? errors.address2: ""}
                    helperText={errors.address2 && touched.address2 ? errors.address2: ""}
                    fullWidth
                    classes={classes.input}
                    value={values.address2}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    variant="outlined"
                    placeholder="Enter marina address2"
                    name="address2"
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    InputProps={{
                      classes: {
                        root: classes.input,
                      },
                    }}
                    fullWidth
                    error={errors.zipcode && touched.zipcode ? errors.zipcode: ""}
                    helperText={errors.zipcode && touched.zipcode ? errors.zipcode: ""}
                    classes={classes.input}
                    value={values.zipcode}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    variant="outlined"
                    placeholder="Enter zipcode"
                    name="zipcode"
                  />
                </Grid>


                <Grid item xs={6}>
                  <TextField
                    InputProps={{
                      classes: {
                        root: classes.input,
                      },
                    }}
                    error={errors.city && touched.city ? errors.city: ""}
                    helperText={errors.city && touched.city ? errors.city: ""}
                    fullWidth
                    classes={classes.input}
                    value={values.city}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    variant="outlined"
                    placeholder="Enter city name"
                    name="city"
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    InputProps={{
                      classes: {
                        root: classes.input,
                      },
                    }}
                    error={errors.state && touched.state ? errors.state: ""}
                    helperText={errors.state && touched.state ? errors.state: ""}
                    fullWidth
                    classes={classes.input}
                    value={values.state}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    variant="outlined"
                    placeholder="Enter state name"
                    name="state"
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    InputProps={{
                      classes: {
                        root: classes.input,
                      },
                    }}
                    error={errors.latitude && touched.latitude ? errors.latitude: ""}
                    helperText={errors.latitude && touched.latitude ? errors.latitude: ""}
                    fullWidth
                    type="number"
                    classes={classes.input}
                    value={values.latitude}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    variant="outlined"
                    placeholder="Enter latitude"
                    name="latitude"
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    InputProps={{
                      classes: {
                        root: classes.input,
                      },
                    }}
                    error={errors.longitude && touched.longitude ? errors.longitude: ""}
                    helperText={errors.longitude && touched.longitude ? errors.longitude: ""}
                    fullWidth
                    classes={classes.input}
                    value={values.longitude}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="number"
                    variant="outlined"
                    placeholder="Enter longitude"
                    name="longitude"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Grid container justify="flex-end">
                    <Grid item md={2} xs={3}>
                      <Button onClick={()=>{this.props.onCancel()}} color="primary" variant="outlined">
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item md={2} xs={3}>
                    <Button
                        color="primary"
                        type="submit"
                        variant="contained"
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    );
  }
}
const CreateMarinaWithRouter = withRouter(CreateMarina);
const CreateMarinaWithToast = withToast(CreateMarinaWithRouter);
const CreateMarinaWithLoader = withLoader(CreateMarinaWithToast);
const CreateMarinaWithAlertBox = withAlertBox(CreateMarinaWithLoader);

export default withStyles((theme) =>
  createStyles({
    ownerName: {
      fontFamily: "Montserrat",
      fontSize: "12px",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.19,
      letterSpacing: "normal",
      color: "#000",
    },
    ownerNameHeading: {
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.19,
      letterSpacing: "normal",
      color: "#343a40",
    },
  })
)(CreateMarinaWithAlertBox);
