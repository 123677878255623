import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { dialogBoxProps  } from "../../../components/src/withDialog.web";
import { withAlertBoxProps } from "../../../components/src/withAlertBox.Web";
import { withToastProps } from "../../../components/src/withSnackBar.Web";
import { withLoaderProps } from "../../../components/src/withLoader.Web";
import moment from "moment";
export const configJSON = require("./config");

export type Props = RouterProps & dialogBoxProps & withAlertBoxProps & withToastProps & withLoaderProps & {
  id: string;
  // Customizable Area Start
  // Customizable Area End
};

interface S {
  // Customizable Area Start
  authToken : String,
  transactionList : [],
  totalEarning : number,
  search_key : any,
  start_date : any
  end_date : any

  // Customizable Area End
}

interface SS {
  id: any;
}

export default class OwnerBookingsController extends BlockComponent<
  Props,
  S,
  SS
> {
  getTransactionsApiCallId : String = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
      // Customizable Area End
    ];

    this.state = {
      authToken : '',
      transactionList : [],
      totalEarning : 0,
      search_key : '',
      start_date : '',
      end_date : ''
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
       
      if (apiRequestCallId === this.getTransactionsApiCallId) {
        this.props.hideLoader();
        if (responseJson && responseJson?.data) {
            this.setState({
              transactionList : responseJson?.data
            },()=>{
              let totoal = 0;
              this.state.transactionList.forEach((am:any)=>{
                totoal += +am?.attributes?.amount
              })
              this.setState({
                totalEarning : totoal
              })
            })
        } else {
          this.parseApiErrorResponse(responseJson);
        }
      }  
    } else if (getName(MessageEnum.AlertMessage) === message.id) {
      const title = message.getData(getName(MessageEnum.AlertTitleMessage));
      var AlertBodyMessage = message.getData(
        getName(MessageEnum.AlertBodyMessage)
      );
      const msg = await this.props.showAlert({title : 'Error',message : AlertBodyMessage})
      if(msg === 'Your session has expired, please login again.' || msg === 'Invalid token'){
        await StorageProvider.remove("authToken")
        await StorageProvider.remove("accountInfo")  
        this.props.history.push("/basicAuth/login")
      }
    }
  }
  
  
  getTransactions() {
    this.props.showLoader();
    const headers = {
      "content-type": configJSON.ApiContentType,
      token : this.state.authToken
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTransactionsApiCallId = requestMessage.messageId;
    let url = "bx_block_payments/payments"
    let search_key = 0; 
    let start_date = 0; 
    let end_date = 0; 
    debugger
    if(this.state.search_key){
      url+= url.indexOf("?") > -1 ? "&search_key="+this.state.search_key : "?search_key="+this.state.search_key
    }
    if(this.state.start_date){
      url+= url.indexOf("?") > -1 ? "&start_date="+this.state.start_date : "?start_date="+this.state.start_date
    }
    if(this.state.end_date){
      url+= url.indexOf("?") > -1 ? "&end_date="+this.state.end_date : "?end_date="+this.state.end_date
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      url
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  
}
