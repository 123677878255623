export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const banner = require("../assets/signup.png");
export const google = require("../assets/google.png");
export const facebook = require("../assets/facebook.png");
export const arrow = require("../assets/right-arrow.png");
export const logo = require("../assets/logo.png");
export const backArrow = require("../assets/back-arrow.png");
export const success = require("../assets/success.png");
export const x1 = require("../assets/1.jpg")
export const x2 = require("../assets/2.jpg")
export const x3 = require("../assets/3.jpg")
export const x4 = require("../assets/4.jpg")
export const x5 = require("../assets/5.jpg")
export const x6 = require("../assets/6.jpg")
export const x7 = require("../assets/7.jpg")
export const x8 = require("../assets/8.jpg")
export const x9 = require("../assets/9.jpg")
export const x10 = require("../assets/10.jpg")
export const boatImage = require("../assets/boatImage.jpg")
export const boatImageDemo1 = require("../assets/boatImageDemo1.png")
export const boatImageDemo2 = require("../assets/boatImageDemo2.png")

