//@ts-nocheck
import React from "react";
import RejectBoatRequestController, { Props } from "./RejectBoatRequestController.web";
import {
  withStyles,
  Grid,
  Typography,
  createStyles,
  Button,
  Paper,
  Link,
  Box,
  Container,
  TextField,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
} from "@material-ui/core";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { boatImage } from "./assets";
import { withRouter } from "react-router";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { boatImageDemo1, boatImageDemo2 } from "./assets";
import Rating from "@material-ui/lab/Rating";

const Schema = Yup.object().shape({
  rating: Yup.string().required("This field is required."),
  review: Yup.string().required("This field is required."),
});
class RejectBoatRequest extends RejectBoatRequestController {
  constructor(props: Props) {
    super(props);
  }
  goToSelectedRoute = (routeName) => {
    const { history } = this.props;
    history.push(routeName);
  };
  closeDialog = (data) => {
    const { onSubmit } = this.props;
    onSubmit(data);
  };
  async componentDidMount() {
    const authToken = await StorageProvider.get("authToken");
    const accountInfo = await StorageProvider.get("accountInfo");
    if (authToken && accountInfo) {
      this.setState({
        senderId: JSON.parse(accountInfo).id,
        authToken: authToken,
      });
    }
  }
  render() {
    const { classes } = this.props;
    return (
      <Grid container spacing={2} justify="center" className={classes.mainContainer}>
        <Grid xs={11} item>
          <Typography variant="h5" className={classes.rejectBookingText}>Are you sure you want to reject booking?</Typography>
        </Grid>
        <Grid item xs={11}>
          <Grid container justify="space-between">
            <Grid item >
              <Box>
              <Typography variant='h7' className={classes.detailsText}>Rented By</Typography>
              <ListItem disableGutters>
                <ListItemAvatar>
                  <Avatar src={boatImageDemo1} />
                </ListItemAvatar>
                <ListItemText
                  primary={"user_name"}
                  className={{
                    primary: classes.ownerName,
                    secondary: classes.ownerNameHeading,
                  }}
                />
              </ListItem>
              </Box>
            </Grid>
            <Grid item >
             <Box>
             <Typography variant='h7' className={classes.detailsText} >Booking for</Typography>
              <Typography gutterBottom className={classes.boatName}>
                {`${this.props?.dataToPass?.boatDetails?.name}`}
              </Typography>
              <Typography gutterBottom className={classes.boatAddress}>
                {`${this.props?.dataToPass?.boatDetails?.address?.address1},${this.props?.dataToPass?.boatDetails?.address?.city}`}
              </Typography>
             </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={11}>
          <FormControl component="fieldset">
            <FormLabel component="legend" className={classes.rejectBookingText}>Please mention the reason </FormLabel>
            <RadioGroup aria-label="gender" name="gender1" className={classes.radioList} onClick={(e)=>{console.log(e.target)}}>
              <FormControlLabel
                value="female"
                control={<Radio />}
                label="The documents were missing"
              />
              <FormControlLabel
                value="male"
                control={<Radio />}
                label="Renter did not have a license"
              />
              <FormControlLabel
                value="other"
                control={<Radio />}
                label="The number of people was way more than the limit"
              />
              <FormControlLabel
                value="disabled"
                control={<Radio />}
                label="Other"
               
              />
            </RadioGroup>
            <Paper >
            <TextField
              id="outlined-multiline-static"
             
              multiline
              rows={4}
              defaultValue=""
              variant="outlined"
              fullWidth
            />
            </Paper>
          </FormControl>
        </Grid>
        <Grid item xs={11}>
          <Grid container justify="center">
            <Grid item >
             <Box px={1} pb={1}>
             <Button
                onClick={() => {
                  this.props.onCancel();
                }}
                variant="outlined"
                color="primary"
                className={classes.cancelButton}
              >
                Cancel
              </Button>
              </Box>
            </Grid>
            
            <Grid item >
              <Box px={1} >
                <Button variant="contained" color="secondary" type="submit" onClick={()=>this.AcceptRenterRequest(this.props?.dataToPass?.bookingDetail?.id)} className={classes.rejectButton}>
                Reject
              </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}
const RejectBoatRequestWithRouter = withRouter(RejectBoatRequest);
const RejectBoatRequestWithToast = withToast(RejectBoatRequestWithRouter);
const RejectBoatRequestWithLoader = withLoader(RejectBoatRequestWithToast);
const RejectBoatRequestWithAlertBox = withAlertBox(RejectBoatRequestWithLoader);

export default withStyles((theme) =>
  createStyles({
    hotelMainImage: {
      position: "relative",
      backgroundImage: `url(${boatImageDemo1})`,
      backgroundRepeat: "no-repeat",
      backgroundColor:
        theme.palette.type === "light"
          ? theme.palette.grey[50]
          : theme.palette.grey[900],
      backgroundSize: "cover",
      backgroundPosition: "center",
      height: theme.spacing(15),
      [theme.breakpoints.down("xs")]: {
        height: theme.spacing(10),
      },
      borderRadius: theme.spacing(1),
    },
    mainContainer:{
      marginTop:"10px",
      paddingTop:"25px",
      border:"1px #b8b8b8",
      borderStyle:"solid none none none"
    },
    boatName: {
      fontFamily: "Montserrat",
      fontSize: "1.5rem",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "normal",
      color: "#004277",
    },
    boatAddress: {
      fontFamily: "Montserrat",
      fontSize: "1rem",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "normal",
      color: "#343a40",
    },
    radioList:{
      fontFamily: "Montserrat",
      fontWeight:600,
      color:"#343a40"
    },
    rejectBookingText:{
    fontFamily: "Montserrat",
    fontWeight: 600,
      color: "#004277"
    },
    detailsText:{
      fontSize:"1rem",
      fontWeight:600,
      fontFamily: "Montserrat",
      color:"#343a40"
    },
    cancelButton:{
      borderRadius:"5px",
      border:"2px solid",
      fontFamily:"Montserrat"
    },
    rejectButton:{
      borderRadius:"5px",
      backgroundColor:"#c5283d",
      fontFamily:"Montserrat"
    }
  })
)(RejectBoatRequestWithAlertBox);