import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { dialogBoxProps } from "../../../components/src/withDialog.web";
import { withAlertBoxProps } from "../../../components/src/withAlertBox.Web";
import { withToastProps } from "../../../components/src/withSnackBar.Web";
import { withLoaderProps } from "../../../components/src/withLoader.Web";
export const configJSON = require("./config");

export type Props = RouterProps &
  dialogBoxProps &
  withAlertBoxProps &
  withToastProps &
  withLoaderProps & {
    id: string;
    // Customizable Area Start
    // Customizable Area End
  };

interface S {
  // Customizable Area Start
  frontSideImage: Array<any>;
  rearSideImage: Array<any>;
  leftSideImage: Array<any>;
  rightSideImage: Array<any>;
  frontSideImageServer: Array<any>;
  rearSideImageServer: Array<any>;
  leftSideImageServer: Array<any>;
  rightSideImageServer: Array<any>;
  authToken: String;
  boatId: any;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class BoatAdditionalphotoDetailController extends BlockComponent<
  Props,
  S,
  SS
> {
  uploadImageApiCallId: String = "";
  getBoatDetailApiCallId : String = "";
  removeBoatImageApiCallId : String = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
      // Customizable Area End
    ];

    this.state = {
      frontSideImage: [],
      rearSideImage: [],
      leftSideImage: [],
      rightSideImage: [],
      frontSideImageServer: [],
      rearSideImageServer: [],
      leftSideImageServer: [],
      rightSideImageServer: [],
      boatId: "",
      authToken: "",
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.getBoatDetailApiCallId) {
        this.props.hideLoader();
        if (responseJson && responseJson?.data && responseJson?.data?.id && responseJson?.data?.attributes && responseJson?.data?.attributes?.galleries) {
          const leftSideServerImage = responseJson?.data?.attributes?.galleries.filter((leftSideImg : any)=>{return leftSideImg.direction === 'left'})
          const rightSideServerImage = responseJson?.data?.attributes?.galleries.filter((rightSideImg : any)=>{return rightSideImg.direction === 'right'})
          const frontSideServerImage = responseJson?.data?.attributes?.galleries.filter((frontSideImg : any)=>{return frontSideImg.direction === 'front'})
          const rearSideServerImage = responseJson?.data?.attributes?.galleries.filter((rearSideImg : any)=>{return rearSideImg.direction === 'rear'})
          this.setState({
            frontSideImageServer : frontSideServerImage,
            rearSideImageServer: rearSideServerImage,
            leftSideImageServer: leftSideServerImage,
            rightSideImageServer: rearSideServerImage,
            })
        } else {
          this.parseApiErrorResponse(responseJson);
        }
      } else if(this.removeBoatImageApiCallId === apiRequestCallId){
        this.props.hideLoader();
        if(responseJson.message === 'Deleted.'){
          this.props.showToast({type:'success',message:'Boat Image Remove succssfully'})
        }
      } else if (apiRequestCallId === this.uploadImageApiCallId) {
        this.props.hideLoader();
        if (responseJson && responseJson?.data && responseJson?.meta && responseJson?.meta.message === 'Success.') {
          this.props.showToast({type : 'success',message : 'Boat photo Updated SuccessFully'})
          this.props.history.push("/boatPhotoDetail");
        } else {
          this.parseApiErrorResponse(responseJson);
        }
      }
    } else if (getName(MessageEnum.AlertMessage) === message.id) {
      const title = message.getData(getName(MessageEnum.AlertTitleMessage));
      var AlertBodyMessage = message.getData(
        getName(MessageEnum.AlertBodyMessage)
      );
      this.props.showAlert({
        title: "Error",
        message: AlertBodyMessage,
      });
    }
  }
  getBoatDetail() {
    this.props.showLoader();
    const headers = {
      "content-type": configJSON.ApiContentType,
      token : this.state.authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getBoatDetailApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getBoatDetailPoint}/${this.state.boatId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  uploadImages(values: any) {
    
    if(this.state.rightSideImage.length > 0 || this.state.leftSideImage.length > 0 || this.state.frontSideImage.length > 0 || this.state.rearSideImage.length > 0){
      this.props.showLoader();
    const headers = {
      token: this.state.authToken,
    };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.uploadImageApiCallId = requestMessage.messageId;
      var formData = new FormData();
      formData.append("gallery[boat_id]", this.state.boatId);
      [...this.state.rightSideImage,...this.state.frontSideImage,...this.state.leftSideImage,...this.state.rearSideImage].map((image,index)=>{
        let directionValue = "";
        if(image.direction === 'right'){
          directionValue = "0"
        } else if(image.direction === 'front'){
          directionValue = "1"
        } else if(image.direction === 'left'){
          directionValue = "2"
        } else if(image.direction === 'rear'){
          directionValue = "3"
        }
        formData.append(`gallery[gallery_attributes][${index}][photo]`,image.file)
        formData.append(`gallery[gallery_attributes][${index}][direction]`,directionValue)
      })
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.createAddiionalPhotoEndPoint}`
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.methodPOST
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    } else {
      this.props.history.push("/boatPhotoDetail");
    }
    
    
    
  }
  removeImageFromServer = (id : any)=>{
    this.props.showLoader();
    const headers = {
      "content-type": configJSON.ApiContentType,
      token : this.state.authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.removeBoatImageApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${"bx_block_gallery/gallery"}/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodDELETE
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
}
