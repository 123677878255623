//@ts-nocheck
import React from "react";
import ReviewDocumetController, { Props } from "./ReviewDocumetController.web";
import {
  withStyles,
  Grid,
  Typography,
  createStyles,
  Button,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  FormControlLabel,
  Checkbox
} from "@material-ui/core";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { boatImage } from "./assets";
import { withRouter } from "react-router";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
import { Formik, Form } from "formik";
import * as Yup from "yup";
const Schema = Yup.object().shape({
  message: Yup.string().required("This field is required."),
});
class ReviewDocumet extends ReviewDocumetController {
  constructor(props: Props) {
    super(props);
  }
  goToSelectedRoute = (routeName) => {
    const { history } = this.props;
    history.push(routeName);
  };
  closeDialog = (data) => {
    const { onSubmit } = this.props;
    onSubmit(data);
  };
  async componentDidMount() {
    const authToken = await StorageProvider.get("authToken");
    const accountInfo = await StorageProvider.get("accountInfo");
    if(authToken && accountInfo){
        this.setState({
            senderId : JSON.parse(accountInfo).id,
            authToken : authToken
        })
    }
    
  } 
  render() {
    const { classes } = this.props;
    return (
      <Formik
        initialValues={{
            termsAndConditions: false,
        }}
        validationSchema={Schema}
        onSubmit={(values, actions) => {
          this.setState({
              message : values.message
          },()=>{
              this.createConverSastion(this.state.senderId,this.props?.dataToPass?.boat?.attributes?.account?.id,values.message);
          })  
        }}
      >
        {(formikProps) => {
          const {
            values,
            handleBlur,
            handleChange,
            isValid,
            dirty,
          } = formikProps;
          return (
            <Form noValidate autoComplete="off">
              <Grid container justify="space-between" spacing={2}>
                <Grid item xs={12}>
                  <Grid container spacing={2} justify="space-between">
                      <Grid item xs={2}>
                        <Typography>From :</Typography>  
                      </Grid>
                      <Grid item xs={8}>
                        <ListItem>
                                <ListItemAvatar>
                                    <Avatar src={boatImage} />
                                </ListItemAvatar>
                                <ListItemText
                                    primary={this.props?.dataToPass?.boat?.attributes?.account?.user_name}
                                    secondary="Verified Owner"
                                    classes={{
                                        primary: classes.ownerName,
                                        secondary: classes.ownerNameHeading,
                                    }}
                                />
                        </ListItem> 
                      </Grid>
                   </Grid>  
                </Grid>
                {/* <Grid item xs={12}>
                    <Grid container spacing={2} justify="space-between">
                      <Grid item xs={3}></Grid>
                      <Grid item xs={3}></Grid>
                      <Grid item xs={6}></Grid>
                    </Grid>
                   </Grid>
                </Grid> */}
                <Grid item xs={12}>
                    <Grid justify="space-between" spacing={2} container>
                        <Grid md={3} xs={12} item>
                           <Typography>Documents:</Typography>
                        </Grid>
                        <Grid md={3} xs={12} item>
                           <Typography>Agreement.pdf</Typography>
                        </Grid>
                        <Grid md={6} xs={12} item>
                            <Typography>Review Document</Typography>
                        </Grid>
                     </Grid>
                </Grid>        
                <Grid item xs={12}>
                    <Grid justify="center" container>
                        <Grid md={6} xs={12} item>
                        <FormControlLabel
                            style={{ flexGrow: 1 }}
                            control={<Checkbox />}
                            name="termsAndConditions"
                            checked={values.termsAndConditions}
                            onChange={handleChange}
                            label="I concent to use electronic records and Signatures"
                            labelPlacement="right"
                            classes={{
                              label: classes.labelText,
                            }}
                          />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container justify="center">
                    <Grid item md={4} xs={8}>
                      <Button onClick={()=>{this.props.onCancel()}} color="primary" variant="outlined">
                         Decline
                      </Button>
                      <Button
                        style={{marginLeft : '16px'}} 
                        color="primary"
                        disabled={!values.message}
                        type="submit"
                        variant="contained"
                      >
                        Agree & Sign
                      </Button>
                    </Grid>
                    
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    );
  }
}
const ReviewDocumetWithRouter = withRouter(ReviewDocumet);
const ReviewDocumetWithToast = withToast(ReviewDocumetWithRouter);
const ReviewDocumetWithLoader = withLoader(ReviewDocumetWithToast);
const ReviewDocumetWithAlertBox = withAlertBox(ReviewDocumetWithLoader);

export default withStyles((theme) =>
  createStyles({
    ownerName: {
      fontFamily: "Montserrat",
      fontSize: "12px",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.19,
      letterSpacing: "normal",
      color: "#000",
    },
    ownerNameHeading: {
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.19,
      letterSpacing: "normal",
      color: "#343a40",
    },
    labelText: {
        fontFamily: "Montserrat",
        fontSize: "12px",
        fontStretch: 500,
        fontStyle: "normal",
        lineHeight: 1.43,
        letterSpacing: "normal",
        color: "#343a40",
    },
  })
)(ReviewDocumetWithAlertBox);
