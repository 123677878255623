//@ts-nocheck
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { DialogProps } from "../../../components/src/DialogContext";
import { withAlertBoxProps } from "../../../components/src/withAlertBox.Web";
import { withToastProps } from "../../../components/src/withSnackBar.Web";
import { withLoaderProps } from "../../../components/src/withLoader.Web";
export const configJSON = require("./config");

export type Props = RouterProps &
  DialogProps &
  withAlertBoxProps &
  withToastProps &
  withLoaderProps & {
    id: string;
    // Customizable Area Start
    // Customizable Area End
  };
interface S {
  // Customizable Area Start
  suggestionloading : boolean,
  isSearched : boolean,
  searchSuggestionList : Array<any>,
  authToken : any,
  carouselAvailable : any;
  boatActivities : Array<any>,
  nearDesinations : Array<any>,
  nearboats : any[],
  lat : number,
  lng : number,
  isValidSession : boolean
  anchorEl : any
  testimonial : []
  // Customizable Area End
}

interface SS {
  
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  loginApiEmailCallId: String = "";
  getLocationSearchApiCallId: String = "";
  getCaursoalAvailablityApiCallId : String = "";
  getBoatActivityApiCallId : String = "";
  getNearDesinationApiCallId : String = "";
  getNearboatsApiCallId : String = ""
  getPersonalInfoApiCallId : String = "";
  getTestimonialApiCallId : String = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
      // Customizable Area End
    ];

    this.state = {
      authToken : null,
      searchSuggestionList : [],
      suggestionloading : false,
      isSearched : false,
      carouselAvailable : {destination: false, near_boats: false, activity: false},
      boatActivities : [],
      nearDesinations : [],
      nearboats : [],
      lat : null,
      lng : null,
      isValidSession : false,
      anchorEl : null,
      testimonial : []
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.getLocationSearchApiCallId) {
        this.props.hideLoader();
          if (responseJson && responseJson?.candidates && responseJson?.candidates.length > 0 && responseJson?.status === "OK" ) {
            const locations = responseJson.candidates.map((location : any)=>{
              return {
                name : location?.name || "Not Available",
                latitude : location?.geometry.location.lat,
                longitude : location?.geometry.location.lng,
                address : location?.formatted_address
              }
            }); 
            this.setState({
              searchSuggestionList : locations,
              suggestionloading : false
            })
          } else {
            this.parseApiErrorResponse(responseJson);
            // this.setState({loading:  false})
          }
      } else  if (apiRequestCallId === this.getBoatActivityApiCallId) {
        this.props.hideLoader();
          if (responseJson && responseJson.data) {
            this.setState({
              boatActivities : responseJson.data,
            })
          } else {
            this.parseApiErrorResponse(responseJson);
            // this.setState({loading:  false})
          }
      } else  if (apiRequestCallId === this.getTestimonialApiCallId) {
            this.props.hideLoader();
          if (responseJson && responseJson.data) {
            this.setState({
              testimonial : [...responseJson.data,...responseJson.data,...responseJson.data],
            })
          } else {
            this.setState({
              testimonial : responseJson.data,
            },()=>{
              this.parseApiErrorResponse(responseJson);
            })
            
            // this.setState({loading:  false})
          }
      } else  if (apiRequestCallId === this.getCaursoalAvailablityApiCallId) {
           this.props.hideLoader();
          if (responseJson && responseJson.data) {
            const carouselAvailable = {};
            responseJson.data.forEach((cursoal : {attributes}  )=>{
              carouselAvailable[cursoal.attributes.carousel_type] = cursoal.attributes.active
           })
            this.setState({
              carouselAvailable : carouselAvailable
            },()=>{
              if(this.state.carouselAvailable?.activity){
                 this.getBoatActivity(); 
              }
              if(this.state.carouselAvailable?.destination){
                 this.getNearDesination();
              }
              if(this.state.carouselAvailable?.near_boats){
                this.getNearByboats();
              }
            })
          } else {
            this.parseApiErrorResponse(responseJson);
            // this.setState({loading:  false})
          }
      } else  if (apiRequestCallId === this.getNearDesinationApiCallId) {
        this.props.hideLoader();
          if (responseJson && responseJson.data) {
            this.setState({
              nearDesinations : responseJson.data,
            })
          } else {
            this.parseApiErrorResponse(responseJson);
            // this.setState({loading:  false})
          }
      } else  if (apiRequestCallId === this.getNearboatsApiCallId) {
          this.props.hideLoader();
          
          if (responseJson && responseJson.data) {
            this.setState({
              nearboats : responseJson.data,
            })
          } else {
            this.parseApiErrorResponse(responseJson);
          }
      } else if (apiRequestCallId === this.getPersonalInfoApiCallId) {
        this.props.hideLoader();
        if (responseJson && responseJson?.data && responseJson?.data?.id && responseJson?.data?.attributes) {
          this.setState({
               accountInfo : responseJson?.data?.attributes,
               isValidSession : true
          })
        } else {
          this.setState({
            isValidSession : false
          },()=>{
            this.parseApiErrorResponse(responseJson)
          })
        }
      }
    } else if (getName(MessageEnum.AlertMessage) === message.id) {
      const title = message.getData(getName(MessageEnum.AlertTitleMessage));
      var AlertBodyMessage = message.getData(
        getName(MessageEnum.AlertBodyMessage)
      );
      const msg = await this.props.showAlert({title : 'Error',message : AlertBodyMessage})
      if(msg === 'Your session has expired, please login again.' || msg === 'Invalid token'){
        await StorageProvider.remove("authToken")
        await StorageProvider.remove("accountInfo")  
        this.props.history.push("/basicAuth/login")
      }
    }
  }
  // getLocationSuggestion = (location : any) => {
  //   this.setState({
  //     suggestionloading : true
  //   },()=>{
  //     if(location){
  //       const headers = {
  //         "content-type": configJSON.ApiContentType,
  //         token : this.state.authToken
  //       };
  //       const requestMessage = new Message(
  //         getName(MessageEnum.RestAPIRequestMessage)
  //       );
  //       this.getLocationSearchApiCallId = requestMessage.messageId;
  //       requestMessage.addData(
  //         getName(MessageEnum.RestAPIResponceEndPointMessage),
  //         `${configJSON.getLocationSuggestion}?location=${location}`
  //       );
  //       requestMessage.addData(
  //         getName(MessageEnum.RestAPIRequestHeaderMessage),
  //         JSON.stringify(headers)
  //       );
  //       requestMessage.addData(
  //         getName(MessageEnum.RestAPIRequestMethodMessage),
  //         configJSON.methodGET
  //       );
  //       runEngine.sendMessage(requestMessage.id, requestMessage);
  //     }
  //   })
  // }
  getLocationSuggestion = (location : any) => {
    this.setState({
      suggestionloading : true
    },()=>{
      if(location){
        const headers = {
          // "content-type": configJSON.ApiContentType,
          // token : this.state.authToken
        };
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        let url = `bx_block_elasticsearch/search_address?location=${location}`
        this.getLocationSearchApiCallId = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          url
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(headers)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.methodGET
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
      }
    })
  }

  getCaursoalAvailablity = () => {
    const headers = {
      "content-type": configJSON.ApiContentType,
      "token": this.state.authToken
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCaursoalAvailablityApiCallId = requestMessage.messageId;
    let url = "bx_block_carouseldisplay0/carouseldisplays?";
    if(this.state.lat && this.state.lng){
      url += `latitude=${this.state.lat}&longitude=${this.state.lng}`
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      url
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);    
  }
  
  getBoatActivity = () => {
    const headers = {
      "content-type": configJSON.ApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getBoatActivityApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_carouseldisplay0/activities`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);    
  }
  getNearDesination = () => {
    const headers = {
      "content-type": configJSON.ApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getNearDesinationApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_carouseldisplay0/destinations`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);    
  }
  getNearByboats = () => {
    const headers = {
      "content-type": configJSON.ApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getNearboatsApiCallId = requestMessage.messageId;
    let url = "bx_block_carouseldisplay0/near_boats?";
    if(this.state.lat && this.state.lng){
      url += `latitude=${this.state.lat}&longitude=${this.state.lng}`
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      url
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getPersonalInfo() {
    this.props.showLoader();
    const headers = {
      "content-type": configJSON.ApiContentType,
      token : this.state.authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getPersonalInfoApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.personalProfieInfo}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getTestimonials() {
    this.props.showLoader();
    const headers = {
      "content-type": configJSON.ApiContentType,
      token : this.state.authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTestimonialApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_reviews/testimonials`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
}
