//@ts-nocheck
import React from "react";
import BoatEventDialogController, {
  Props,
} from "./BoatEventDialogController.web";
import {
  withStyles,
  Grid,
  Typography,
  createStyles,
  Button,
  FormControlLabel,
  TextField,
  InputAdornment,
  Checkbox,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  IconButton,
  Menu,
  MenuItem,
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

import StorageProvider from "../../../framework/src/StorageProvider.web";
import { boatImage, clock } from "./assets";
import { withRouter } from "react-router";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import TimeRange from "react-time-range";
import moment from "moment";
const Schema = Yup.object().shape({
  reason: Yup.string().required("This field is required."),
});
class ContactOwner extends BoatEventDialogController {
  constructor(props: Props) {
    super(props);
  }
  goToSelectedRoute = (routeName) => {
    const { history } = this.props;
    history.push(routeName);
  };
  closeDialog = (data) => {
    const { onSubmit } = this.props;
    onSubmit(data);
  };
  openTripTimeMenu = (event, setFieldTouched) => {
    this.setState(
      {
        tripTimeAnchorEl: event.currentTarget,
      },
      () => {
        setFieldTouched("tripTime", true);
      }
    );
  };
  selectTripTimeAndCloseMenu = () => {
    this.setState({
      tripTimeAnchorEl: null,
    });
  };
  render() {
    const { classes } = this.props;
    const shiftDays = this.props.dataToPass?.shifts.map((shift) => {
      const starthours = new Date(shift?.attributes?.start_time).getUTCHours();
      const startMinutes = new Date(shift?.attributes?.start_time).getUTCMinutes();
      const endhours = new Date(shift?.attributes?.end_time).getUTCHours();
      const endMinutes = new Date(shift?.attributes?.end_time).getUTCMinutes();
      const starttime = new Date(this.props.dataToPass.date).setHours(starthours,startMinutes,0);
      const endtime = new Date(this.props.dataToPass.date).setHours(endhours,endMinutes,0);

      return {
        ...shift,
        time: `${moment(starttime).format("hh:mm A")} - ${moment(
          endtime
        ).format("hh:mm A")}`,
        startTime : new Date(starttime).toISOString(),
        endTime : new Date(endtime).toISOString(),  
      };
    });
    return (
      <Formik
        initialValues={{
          customtime: "full_day",
          tripTime: "",
          startTime: new Date(this.props.dataToPass.date).toISOString(),
          endTime: new Date(this.props.dataToPass.date).toISOString(),
        }}
        // validationSchema={Schema}
        onSubmit={(values, actions) => {
          const { onSubmit } = this.props;
          const data = { ...values };
          if (data.customtime === "full_day") {
            data.startTime = new Date(
              new Date(this.props.dataToPass.date).setHours(0, 0, 0)
            ).toISOString();
            data.endTime = new Date(
              new Date(this.props.dataToPass.date).setHours(23, 59, 0)
            ).toISOString();
          } else if (data.customtime === "custom") {
            data.startTime = (data.startTime);
            data.endTime = (data.endTime);
          } else if (data.customtime === "half_day") {
            data.startTime = (data.tripTime.startTime);
            data.endTime = (data.tripTime.endTime);
          }
          onSubmit(data);
        }}
      >
        {(formikProps) => {
          const {
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            setFieldTouched,
            setFieldValue,
            isValid,
            dirty,
          } = formikProps;
          return (
            <Form noValidate autoComplete="off">
              <Grid container justify="space-between" spacing={2}>
                <Grid item xs={12}>
                  <FormControl component="fieldset">
                    <FormLabel
                      className={classes.radioGroupTitle}
                      component="legend"
                      style={{ color: "#343a40" }}
                    >
                      Select Time
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-label="position"
                      name="customtime"
                      onChange={(e) => {
                        setFieldValue("startTime", new Date(this.props.dataToPass.date).toISOString());
                        setFieldValue("endTime", new Date(this.props.dataToPass.date).toISOString());
                        handleChange(e);
                      }}
                      defaultValue={values.customtime}
                    >
                      <FormControlLabel
                        classes={{
                          label: classes.radioButtonLabel,
                        }}
                        value="half_day"
                        control={<Radio color="primary" />}
                        label="Half Day"
                      />
                      <FormControlLabel
                        classes={{
                          label: classes.radioButtonLabel,
                        }}
                        value="full_day"
                        control={<Radio color="primary" />}
                        label="Full Day"
                      />
                      <FormControlLabel
                        classes={{
                          label: classes.radioButtonLabel,
                        }}
                        value="custom"
                        control={<Radio color="primary" />}
                        label="Custom range"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                {values.customtime === "custom" && (
                  <Grid item xs={12}>
                    <TimeRange
                      startMoment={values.startTime}
                      endMoment={values.endTime}
                      onStartTimeChange={(a) => {
                        setFieldValue("startTime", new Date(a.startTime).toISOString());
                      }}
                      onEndTimeChange={(a) => {
                        setFieldValue("endTime", new Date(a.endTime).toISOString());
                      }}
                      sameIsValid={false}
                      showErrors
                      minuteIncrement={60}
                    />
                  </Grid>
                )}
                {values.customtime === "half_day" && (
                  <Grid item xs={12}>
                    <Grid container justify={"space-between"}>
                      <Grid xs={2} item>
                        <img src={clock} width={30} height={30} />
                      </Grid>
                      <Grid xs={9} style={{ alignSelf: "flex-end" }}>
                        <Typography className={classes.selectPickerHeading}>
                          Time
                        </Typography>
                      </Grid>
                      <Grid xs={12}>
                        <div style={{ display: "flex" }}>
                          <div style={{ flexGrow: 1 }}>
                            <Typography
                              display="inline"
                              className={classes.sortBy}
                            >
                              {values.tripTime
                                ? values.tripTime?.time
                                : "select"}
                            </Typography>
                            <IconButton
                              aria-controls="simple-menu"
                              aria-haspopup="true"
                              onClick={(event) => {
                                this.openTripTimeMenu(event, setFieldTouched);
                              }}
                            >
                              <ArrowDropDownIcon />
                            </IconButton>
                            {errors.tripTime && touched.tripTime && (
                              <Typography display="block" color="error">
                                {errors.tripTime}
                              </Typography>
                            )}
                            <Menu
                              id="simple-menu"
                              anchorEl={this.state.tripTimeAnchorEl}
                              keepMounted
                              open={!!this.state.tripTimeAnchorEl}
                              onClose={() => {
                                this.setState({
                                  tripTimeAnchorEl: null,
                                });
                              }}
                            >
                              {shiftDays.map((shift, index) => {
                                return (
                                  <MenuItem
                                    key={index}
                                    onClick={() => {
                                      setFieldValue("tripTime", shift);
                                      this.selectTripTimeAndCloseMenu();
                                    }}
                                  >
                                    {shift.time}
                                  </MenuItem>
                                );
                              })}
                            </Menu>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Grid container justify="flex-end">
                    <Grid item md={2} xs={3}>
                      <Button
                        onClick={() => {
                          this.props.onCancel();
                        }}
                        color="primary"
                        variant="outlined"
                      >
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item md={2} xs={3}>
                      <Button color="primary" type="submit" variant="contained">
                        ok
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    );
  }
}
const ContactOwnerWithRouter = withRouter(ContactOwner);
const ContactOwnerWithToast = withToast(ContactOwnerWithRouter);
const ContactOwnerWithLoader = withLoader(ContactOwnerWithToast);
const ContactOwnerWithAlertBox = withAlertBox(ContactOwnerWithLoader);

export default withStyles((theme) =>
  createStyles({
    ownerName: {
      fontFamily: "Montserrat",
      fontSize: "12px",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.19,
      letterSpacing: "normal",
      color: "#000",
    },
    ownerNameHeading: {
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.19,
      letterSpacing: "normal",
      color: "#343a40",
    },
  })
)(ContactOwnerWithAlertBox);
