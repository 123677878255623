//@ts-check
import React, { PureComponent } from "react";
import { Redirect, RouterProps, Route } from "react-router-dom";
import StorageProvider from "../../../framework/src/StorageProvider.web";

const withBoatIdComponent = (OriginalComponent) => {
  return class extends PureComponent {
    state = {
      boatId: "",
      loading: true,
    };
    async componentDidMount() {
      let boatId = await StorageProvider.get("boatId");
        this.setState({
          boatId: boatId,
          loading: false,
        });
    }

    render() {
      return !this.state.loading ? (
        !this.state.boatId ? (
          <Redirect to="/boatCategory" />
        ) : (
          <OriginalComponent {...this.props} />
        )
      ) : null;
    }
  };
};
export default withBoatIdComponent;
