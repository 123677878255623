//@ts-nocheck
import React from "react";
import CustomerDocumentsInfoController, {
  Props,
} from "./CustomerDocumentsInfoController.web";
import {
  withStyles,
  Grid,
  Typography,
  createStyles,
  Button,
  Avatar,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Hidden,
  Paper,
  FormControl,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio
} from "@material-ui/core";

import { NavLink } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import AccountCircle from "@material-ui/icons/AccountCircle";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import { boatDetail } from "./assets";
import { withRouter } from "react-router";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
import UploadBox from "./UploadBox.Web";
const sideBarLinks = [
  { displayName: "My Documents", route: "/userDashboard/myDocuments" },
  { displayName: "My Bookings", route: "/userDashboard/myBookings" },
  { displayName: "Messages", route: "/userDashboard/messages" },
  {
    displayName: "Personal Information",
    route: "/userDashboard/personalInformation",
  },
  { displayName: "Payment", route: "/userDashboard/payment" },
  { displayName: "Account Settings", route: "/userDashboard/accountSettings" },
];

class CustomerDocumentsInfo extends CustomerDocumentsInfoController {
  fileInputRef = React.createRef([]);
  constructor(props: Props) {
    super(props);
  }
  async componentDidMount() {
    const authToken = await StorageProvider.get("authToken");
    if (authToken) {
      this.setState(
        {
          authToken: authToken,
        },
        () => {
          this.getAllDocument();
        }
      );
    }
  }
  addDocument = () => {
    this.setState({
      documentList: [{},...this.state.documentList],
      license_type : ["boating_license",...this.state.license_type]
    });
  };

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };

  goToSelectedRoute = (routeName) => {
    this.handleClose();
    this.setState({ mobileOpen: false });
    const { history } = this.props;
    history.push(routeName);
  };
  fileInputClicked(index) {
    this.fileInputRef.current[index]?.click();
    // this.fileInputRef.current[index]
  }
  filesSelected = (index,type) => {
    if (this.fileInputRef.current[index]?.files?.length) {
      this.handleFiles(this.fileInputRef.current[index]?.files, index,type);
    }
  };
  toBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () =>
        resolve({
          base64: reader.result,
          file: file,
          id: new Date().getTime(),
        });
      reader.onerror = (error) => reject(error);
    });
  };

  tobase64Handler = async (files, index,type) => {
    const filePathsPromises = [];
    [...files].forEach((file) => {
      filePathsPromises.push(this.toBase64(file));
    });
    const filePaths = await Promise.all(filePathsPromises);
    var doucmentList = [...this.state.documentList];

    doucmentList[index]["profilePicturePreView"] = [...filePaths];
    this.setState({
      doucmentList: doucmentList,
    },()=>{
      if(type){
        this.updateDocument([...filePaths],type,index)
      }
    });
  };

  handleFiles(files: FileList, index,type) {
    const filepaths = this.tobase64Handler([...files], index,type);
  }

  removeDocuentFromServer = (id) => {
    this.setState({
      documentList : this.state.documentList.filter((document)=>{ return document.id !== id })
    },()=>{
      this.removeDocuent(id);
    })
  }

  render() {
    const { classes } = this.props;

    return (
      <Grid container className={classes.root}>
        <>
          <Hidden xsDown>
            <Grid
              item
              xs={0}
              sm={1}
              md={2}
              container
              className={classes.sideBar}
            >
              <Grid item xs={12}>
                <Typography align="center" className={classes.sideBarHeading}>
                  My Account
                </Typography>
                <List dense={false}>
                  {sideBarLinks.map((item) => {
                    return (
                      <NavLink
                        to={item.route}
                        className={classes.sideLink}
                        activeClassName={classes.activeSideLink}
                        key={item.displayName}
                      >
                        <ListItem>
                          <ListItemAvatar>
                            <AccountCircle />
                          </ListItemAvatar>
                          <ListItemText
                            primary={item.displayName}
                            classes={{ primary: classes.sideLink }}
                          />
                        </ListItem>
                      </NavLink>
                    );
                  })}
                </List>
              </Grid>
            </Grid>
          </Hidden>
          <Grid item xs={12} sm={11} md={10} className={classes.rightRoot}>
            <main className={classes.layout}>
              <Paper className={classes.paper}>
                <>
                  <Grid container spacing>
                    <Grid item xs={10}>
                      <Typography className={classes.pageHeading} gutterBottom>
                        My Documents
                      </Typography>
                      <Typography className={classes.pageSubHeading}>
                        Please provide your documents for quick booking
                        approvals
                      </Typography>
                    </Grid>
                    <Grid xs={2} style={{ alignSelf: "center" }}>
                      <Button
                        onClick={() => {
                          this.addDocument();
                        }}
                        variant="contained"
                        color="primary"
                      >
                        Add
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    style={{ marginTop: "30px" }}
                    spacing={8}
                    justify={"space-between"}
                  >
                    {this.state.documentList.map((document, index) => {
                      return (
                        <Grid item xs={12} sm={12} md={12} key={index}>
                          <Typography
                            className={classes.sectionHeading}
                            gutterBottom
                          >
                            {index + 1}. Document{" "}
                            {index > 9 ? `0` + (index + 1) : index + 1}
                          </Typography>
                          <Grid container justify="space-around" spacing={2}>
                          {document?.id && <Grid item xs={6}>
                            <div style={{textAlign : 'end'}}>
                                <IconButton onClick={()=>{
                                  this.removeDocuentFromServer(document?.id)
                                }}>
                                  <DeleteOutline></DeleteOutline>
                               </IconButton>  
                            </div>
                           </Grid>  }  
                          <Grid item xs={12}>
                         {document.id ? <Typography>Document type : {document?.attributes?.type || "Not Available"}</Typography> : <FormControl component="fieldset">
                                    <FormLabel
                                      className={classes.radioGroupTitle}
                                      component="legend"
                                    >
                                      Document type
                                    </FormLabel>
                                    <RadioGroup
                                      row
                                      aria-label="position"
                                      name="license_type"
                                      onChange={
                                        (event)=>{
                                          const license_type = [...this.state.license_type];
                                          license_type[index] = event.target.value;
                                          this.setState({
                                            license_type : license_type
                                         })
                                      }
                                    }
                                    defaultValue={this.state.license_type[index]}
                                    >
                                      <FormControlLabel
                                        classes={{
                                          label: classes.radioButtonLabel,
                                        }}
                                        value="boating_license"
                                        control={
                                          <Radio
                                            // disabled={!values.isRenterOperates}
                                            color="primary"
                                          />
                                        }
                                        label="Boating License"
                                      />
                                      <FormControlLabel
                                        classes={{
                                          label: classes.radioButtonLabel,
                                        }}
                                        value="state_license"
                                        control={
                                          <Radio
                                            // disabled={!values.isRenterOperates}
                                            color="primary"
                                          />
                                        }
                                        label="State License"
                                      />
                                       <FormControlLabel
                                        classes={{
                                          label: classes.radioButtonLabel,
                                        }}
                                        value="special_license"
                                        control={
                                          <Radio
                                            // disabled={!values.isRenterOperates}
                                            color="primary"
                                          />
                                        }
                                        label="Special License"
                                      />
                                    </RadioGroup>
                                  </FormControl>
                         } 
                                
                          </Grid>
                            <Grid item xs={3}>
                              <Typography
                                className={classes.preView}
                                gutterBottom
                              >
                                Preview
                              </Typography>
                              {document?.id && !document?.profilePicturePreView && <Avatar
                                  style={{ width: "100%", height: "100px" }}
                                  src={
                                    document?.attributes?.document.url
                                  }
                                />}
                              {document?.profilePicturePreView?.length > 0 ? (
                                <Avatar
                                  style={{ width: "100%", height: "100px" }}
                                  src={
                                    document?.profilePicturePreView[0].base64
                                  }
                                />
                              ) : (
                                document?.id ? null :
                                <UploadBox
                                  multiple={false}
                                  uploadMsg="Select or drag and drop a file"
                                  acceptedFormat={"image/*"}
                                  onTouched={() => {}}
                                  onSelectFile={(files: any) => {
                                    this.tobase64Handler(files, index);
                                  }}
                                ></UploadBox>
                              )}
                            </Grid>
                            <Grid item xs={3}>
                              {document.id && (
                                <>
                                  <Button
                                    variant="text"
                                    color="primary"
                                    onClick={() => {
                                      this.fileInputClicked(index, document?.attributes?.type);
                                    }}
                                  >
                                    Update
                                  </Button>
                                  <input
                                    // ref={this.fileInputRef[index]}
                                    ref={el => { if(this.fileInputRef.current){
                                      this.fileInputRef.current[index] = el
                                    }
                                    }} 
                                    className={classes.fileInput}
                                    type="file"
                                    accept="image/*"
                                    multiple={false}
                                    onChange={this.filesSelected.bind(
                                      this,
                                      index,
                                      document
                                    )}
                                  />
                                </>
                              )}
                            </Grid>
                            <Grid item xs={3}>
                              {!document.id && (
                                <>
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => this.updateDocument(document?.profilePicturePreView,null,index)}
                                    disabled={!document?.profilePicturePreView}
                                  >
                                    Save
                                  </Button>
                                </>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      );
                    })}
                  </Grid>
                </>
              </Paper>
            </main>
          </Grid>
        </>
      </Grid>
    );
  }
}
const CustomerDocumentsInfoWithRouter = withRouter(CustomerDocumentsInfo);
const CustomerDocumentsInfoAlertBox = withAlertBox(
  CustomerDocumentsInfoWithRouter
);
const CustomerDocumentsInfoLoader = withLoader(CustomerDocumentsInfoAlertBox);
const CustomerDocumentsInfoToast = withToast(CustomerDocumentsInfoLoader);
export default withStyles((theme) =>
  createStyles({
    pageHeading: {
      fontFamily: "Montserrat",
      fontSize: "20px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.21,
      letterSpacing: "normal",
      color: "#343a40",
    },
    pageSubHeading: {
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "normal",
      color: "#000000",
    },
    fileInput: {
      display: "none",
    },
    sectionHeading: {
      fontFamily: "Montserrat",
      fontSize: "20px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.21,
      letterSpacing: "normal",
      color: "#004277",
    },
    preView: {
      fontFamily: "Montserrat",
      fontSize: "12px",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.21,
      letterSpacing: "normal",
      color: "#000000",
    },
    primaryText: {
      fontFamily: "Montserrat",
      fontSize: "10px",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.21,
      letterSpacing: "normal",
      color: "#343a40",
    },
    secondaryText: {
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.21,
      letterSpacing: "normal",
      color: "#343a40",
    },
    root: {
      height: "calc(100vh - 70px)",
      overflowX: "hidden",
    },
    layout: {
      width: "auto",
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
        width: "80%",
        marginLeft: "auto",
        marginRight: "auto",
      },
    },
    paper: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      padding: theme.spacing(4),
      [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(6),
        padding: theme.spacing(3),
      },
    },
    sideBar: {
      height: "calc(100vh - 72px)",
      backgroundColor: "#ffffff",
    },
    rightRoot: {
      backgroundImage:
        "linear-gradient(180deg, rgba(0,0,255,1) 30%, rgba(255,255,255,1) 30%)",
    },
    sideLinkHeading: {
      fontFamily: "Montserrat",
      fontSize: "20px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "normal",
      color: "#183b56",
    },
    sideLink: {
      fontFamily: "Montserrat",
      fontSize: "12px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.22,
      letterSpacing: "normal",
      color: "#343a40",
      cursor: "pointer",
      textAlign: "center",
      display: "block",
      textDecoration: "none",
      "&:hover": {
        textDecoration: "none",
        textTransform: "none",
      },
    },
    activeSideLink: {
      backgroundColor: "rgb(1,116,207,0.1)",
      color: "#343a40",
      textTransform: "none",
      textDecoration: "none",
      cursor: "pointer",
    },
  })
)(CustomerDocumentsInfoToast);
