//@ts-nocheck
import React from "react";
import BoatAddressDetailController, {
  Props,
} from "./BoatAddressDetailController.web";
import {
  withStyles,
  Grid,
  AppBar,
  Toolbar,
  Typography,
  createStyles,
  Box,
  ListItem,
  List,
  ListItemText,
  ListItemAvatar,
  Button,
  InputAdornment,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";

import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Formik, Form, Field } from "formik";
import InputBox from "./InputBox.web";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import { logo, boatDetail, commercial } from "./assets";
import * as Yup from "yup";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
import { withRouter } from "react-router";
import withBoatIdComponent from "./withBoatIdComponent.js";
const drawerWidth = 240;
const PageLinks = [
  { displayName: "Support", route: "/faq" },
  { displayName: "Save and exit", route: "saveandexit" },
];
const boatAddressSchema = Yup.object().shape({
  zipcode: Yup.string()
    .matches(/(^\d{5}$)|(^\d{6}$)|(^\d{5}-\d{4}$)/, "zipcode is not valid")
    .required("this field is required"),
  address: Yup.string().required("this field is required"),
  addressline2: Yup.string().nullable(),
  city: Yup.string()
    .matches(
      /^[a-zA-Z\u0080-\u024F\s\/\-\)\(\`\.\"\']+$/,
      "city name is not valid"
    )
    .required("this field is required"),
  state: Yup.string()
    .matches(
      /^[a-zA-Z\u0080-\u024F\s\/\-\)\(\`\.\"\']+$/,
      "city name is not valid"
    )
    .required("this field is required"),
  locationType: Yup.string().required("this field is required"),
});

class BoatAddressDetail extends BoatAddressDetailController {
  constructor(props: Props) {
    super(props);
  }
  submitForm = null;
  async componentDidMount() {
    const authToken = await StorageProvider.get("authToken");
    const boatId = await StorageProvider.get("boatId");
    if (authToken) {
      this.setState(
        {
          authToken: authToken,
          boatId: boatId,
        },
        () => {
          this.getBoatDetail();
          this.getMarinaList();
        }
      );
    }
  }
  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };
  goToSelectedRoute = (routeName) => {
    if (routeName === "saveandexit") {
      this.setState(
        {
          isSaveAndExit: true,
        },
        () => {
          this.submitForm && this.submitForm();
        }
      );
    } else {
      const { history } = this.props;
      history.push(routeName);
    }
  };
  onSelectLocation = (location) => {
    this.setState({
      boatAddressDetail: {
        zipcode: "",
        address: location?.address || "",
        addressline2: "",
        city:
          location?.address.split(", ").length > 0
            ? location.address.split(", ")[0]
            : "",
        state:
          location?.address.split(", ").length > 0
            ? location.address.split(", ")[1]
            : "",
        locationType: "personalLocation",
        latitude: location?.latitude,
        longitude: location?.longitude,
      },
    });
  };
  setMarinaLocation = (event, marina) => {
    event.stopPropagation();

    this.setState({
      boatAddressDetail: {
        zipcode: marina?.attributes?.address?.zipcode || null,
        address: marina?.attributes?.address?.address1 || null,
        addressline2: marina?.attributes?.address?.address2 || null,
        city: marina?.attributes?.address?.city || null,
        state: marina?.attributes?.address?.state || null,
        locationType: "marinaLocation",
        latitude: marina?.attributes?.address?.latitude || null,
        longitude: marina?.attributes?.address?.longitude || null,
      },
      marina: marina,
    });
  };
  render() {
    const { classes } = this.props;
    return (
      <>
        <AppBar
          position="static"
          color="default"
          elevation={0}
          className={classes.appBar}
        >
          <Toolbar className={classes.toolbar}>
            <Box className={classes.toolbarLogo}>
              <img
                onClick={() => this.props.history.push("/")}
                src={logo}
                style={{ width: "22%" }}
                alt="boaturu"
              />
            </Box>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              edge="start"
              onClick={this.handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <Hidden xsDown implementation="css">
              <nav>
                {PageLinks.map((link) => {
                  return (
                    <Typography
                      key={link.displayName}
                      variant="button"
                      className={classes.link}
                      onClick={() => {
                        this.goToSelectedRoute(link.route);
                      }}
                    >
                      {link.displayName}
                    </Typography>
                  );
                })}
              </nav>
            </Hidden>
            <Hidden smUp implementation="css">
              <Drawer
                variant="persistent"
                anchor={"right"}
                open={this.state.mobileOpen}
                onClose={this.handleDrawerToggle}
                classes={{
                  paper: classes.drawerPaper,
                }}
                SlideProps={{ unmountOnExit: true }}
              >
                <IconButton
                  onClick={this.handleDrawerToggle}
                  className={classes.closeMenuButton}
                >
                  <CloseIcon />
                </IconButton>
                <List>
                  {PageLinks.map((link, index) => (
                    <ListItem
                      button
                      key={link.displayName}
                      onClick={() => {
                        this.goToSelectedRoute(link.route);
                      }}
                    >
                      <ListItemText primary={link.displayName} />
                    </ListItem>
                  ))}
                </List>
              </Drawer>
            </Hidden>
          </Toolbar>
        </AppBar>
        <Grid container className={classes.root}>
          <Grid
            item
            xs={false}
            sm={4}
            md={5}
            container
            className={classes.image}
          >
            <div className={classes.overlay} />
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={12} />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography gutterBottom className={classes.pricing}>
                Boat Details
              </Typography>
              <div className={classes.headingline} />
              <Typography gutterBottom className={classes.selectOperator}>
                Locate your boat
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography gutterBottom className={classes.leftsidelink}>
                Pricing
              </Typography>
              <Typography gutterBottom className={classes.leftsidelink}>
                Insurance
              </Typography>
              <Typography gutterBottom className={classes.leftsidelink}>
              Safety and standards
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={8} md={7}>
            <Formik
              initialValues={this.state.boatAddressDetail}
              enableReinitialize={true}
              validationSchema={!this.state.isSaveAndExit && boatAddressSchema}
              onSubmit={async (values, actions) => {
                this.updateBoatAddress(values);
              }}
            >
              {(formikProps) => {
                const {
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleSubmit,
                  handleBlur,
                  isValid,
                  isSubmitting,
                  dirty,
                  setFieldValue,
                } = formikProps;
                this.submitForm = handleSubmit;
                return (
                  <Form autoComplete="off">
                    <Grid
                      container
                      style={{ padding: "16px 32px" }}
                      spacing={5}
                    >
                      <Grid item xs={12} container justify="space-between">
                        <Grid item>
                          <Box
                            className={classes.backButtonBox}
                            onClick={() => {
                              this.goToSelectedRoute("/boatCategory");
                            }}
                          >
                            <Typography
                              className={classes.backButtonBoxContent}
                            >
                              {"<"}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item>
                          <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={!isValid && dirty}
                          >
                            Proceed
                          </Button>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} container justify="center">
                        <Grid item>
                          <Typography
                            align="center"
                            className={classes.rightPageHeading}
                          >
                            Please provide your boat's address for renter's to
                            locate it
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} container justify="center">
                        <Grid item xs={6}>
                          <Autocomplete
                            id="search boat"
                            filterOptions={(x) => x}
                            freeSolo
                            style={{ width: 300 }}
                            getOptionLabel={(option) => option?.name}
                            options={this.state.searchSuggestionList}
                            loading={false}
                            onInputChange={(evt) => {
                              this.getLocationSuggestion(evt.target.value);
                            }}
                            onChange={(evt, value) => {
                              this.onSelectLocation(value);
                            }}
                            disabled={values.locationType === "marinaLocation"}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Search Location"
                                variant="outlined"
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <React.Fragment>
                                      {this.state.suggestionloading ? (
                                        <CircularProgress
                                          color="inherit"
                                          size={20}
                                        />
                                      ) : null}
                                      {
                                        <InputAdornment position="end">
                                          <IconButton aria-label="search">
                                            <SearchIcon />
                                          </IconButton>
                                        </InputAdornment>
                                      }
                                    </React.Fragment>
                                  ),
                                }}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={12} container justify="center">
                        <Grid item>
                          <Typography
                            align="center"
                            className={classes.rightPageSubHeading}
                          >
                            For your privacy concerns, we provide the exact boat
                            location once the renter's has confirmed the booking
                            after your approval
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} container justify="center" alignItems="center" alignContent="center">
                        <Grid item xs={3}>
                          <Typography
                            className={classes.inputLable}
                            align="center"
                            display="inline"
                          >
                            Zipcode
                          </Typography>
                          <Typography
                            align="center"
                            display="inline"
                            color="error"
                          >
                            *
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            fullWidth
                            disabled={values.locationType === "marinaLocation"}
                            name="zipcode"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            label=""
                            value={values.zipcode}
                            error={
                              errors.zipcode && touched.zipcode ? true : false
                            }
                            helperText={
                              errors.zipcode && touched.zipcode
                                ? errors.zipcode
                                : ""
                            }
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={12} container justify="center" alignItems="center" alignContent="center">
                        <Grid item xs={3}>
                          <Typography
                            className={classes.inputLable}
                            align="center"
                            display="inline"
                          >
                            Address
                          </Typography>
                          <Typography
                            align="center"
                            display="inline"
                            color="error"
                          >
                            *
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            fullWidth
                            name="address"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            label=""
                            value={values.address}
                            error={
                              errors.address && touched.address ? true : false
                            }
                            helperText={
                              errors.address && touched.address
                                ? errors.address
                                : ""
                            }
                            disabled={values.locationType === "marinaLocation"}
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>

                      <Grid item xs={12} container justify="center" alignItems="center" alignContent="center">
                        <Grid item xs={3}>
                          <Typography
                            className={classes.inputLable}
                            align="center"
                            display="inline"
                          >
                            Address line 2
                          </Typography>
                          <Typography
                            align="center"
                            display="inline"
                            color="error"
                          >
                            {" "}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            label=""
                            fullWidth
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.addressline2}
                            error={
                              errors.addressline2 && touched.addressline2
                                ? true
                                : false
                            }
                            helperText={
                              errors.addressline2 && touched.addressline2
                                ? errors.addressline2
                                : ""
                            }
                            name="addressline2"
                            disabled={values.locationType === "marinaLocation"}
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>

                      <Grid item xs={12} container justify="center" alignItems="center" alignContent="center">
                        <Grid item xs={3}>
                          <Typography
                            className={classes.inputLable}
                            align="center"
                            display="inline"
                          >
                            City
                          </Typography>
                          <Typography
                            align="center"
                            display="inline"
                            color="error"
                          >
                            *
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            label=""
                            fullWidth
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.city}
                            error={errors.city && touched.city ? true : false}
                            helperText={
                              errors.city && touched.city ? errors.city : ""
                            }
                            disabled={values.locationType === "marinaLocation"}
                            name="city"
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>

                      <Grid item xs={12} container justify="center" alignItems="center" alignContent="center">
                        <Grid item xs={3}>
                          <Typography
                            className={classes.inputLable}
                            align="center"
                            display="inline"
                          >
                            State
                          </Typography>
                          <Typography
                            align="center"
                            display="inline"
                            color="error"
                          >
                            *
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            label=""
                            fullWidth
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.state}
                            error={errors.state && touched.state ? true : false}
                            helperText={
                              errors.state && touched.state ? errors.state : ""
                            }
                            disabled={values.locationType === "marinaLocation"}
                            name="state"
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={12} container justify="center" alignItems="center" alignContent="center">
                        <Grid item xs={3}>
                          <Typography
                            className={classes.inputLable}
                            align="center"
                            display="inline"
                          >
                            Location Type
                          </Typography>
                          <Typography
                            align="center"
                            display="inline"
                            color="error"
                          >
                            *
                          </Typography>
                        </Grid>
                        <Grid item>
                          <RadioGroup
                            row
                            aria-label="position"
                            name="locationType"
                            onChange={(event) => {
                              handleChange(event);
                              this.setState({
                                boatAddressDetail: {
                                  locationType: event.target.value,
                                },
                                marina: {},
                              },()=>{
                                this.setState({
                                  boatAddressDetail: {
                                    ...this.state.boatAddressDetail,
                                    zipcode:  "",
                                    address:  "",
                                    addressline2:  "",
                                    city:  "",
                                    state:  "",
                                    latitude:  "",
                                    longitude:  "",
                                  },
                                })
                              });
                            }}
                            value={values.locationType}
                          >
                            <FormControlLabel
                              classes={{
                                label: classes.radioButtonLabel,
                              }}
                              value="personalLocation"
                              control={<Radio color="primary" />}
                              label="Personal Location"
                            />
                            <FormControlLabel
                              classes={{
                                label: classes.radioButtonLabel,
                              }}
                              value="marinaLocation"
                              control={<Radio color="primary" />}
                              label="Marina Location"
                            />
                          </RadioGroup>
                        </Grid>
                      </Grid>

                      {values.locationType === "marinaLocation" && (
                        <Grid item xs={12} container justify="center">
                          <Grid item xs={3}>
                            <Typography
                              className={classes.inputLable}
                              align="center"
                              display="inline"
                            >
                              List of Marina
                            </Typography>
                            <Typography
                              align="center"
                              display="inline"
                              color="error"
                            >
                              *
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <div className={classes.demo}>
                              <List dense={true}>
                                {this.state.marinaList.map((marina) => {
                                  return (
                                    <ListItem
                                      onClick={(event) => {
                                        this.setMarinaLocation(event, marina);
                                      }}
                                      classes={{
                                        root:
                                          this.state?.marina?.id == marina.id
                                            ? classes.selectedMarina
                                            : "",
                                      }}
                                      key={marina.id}
                                    >
                                      <ListItemAvatar>
                                        <img
                                          style={{
                                            width: "70px",
                                            height: "50px",
                                          }}
                                          src={
                                            marina?.attributes?.image?.url ||
                                            commercial
                                          }
                                        />
                                      </ListItemAvatar>
                                      <ListItemText
                                        classes={{
                                          primary: classes.listTitleName,
                                        }}
                                        primary={marina.attributes.name}
                                      />
                                    </ListItem>
                                  );
                                })}
                              </List>
                            </div>
                          </Grid>
                        </Grid>
                      )}

                      <Grid item xs={12} container justify="flex-end">
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          disabled={!isValid && dirty}
                        >
                          Proceed
                        </Button>
                      </Grid>
                    </Grid>
                  </Form>
                );
              }}
            </Formik>
          </Grid>
        </Grid>
      </>
    );
  }
}
const BoatAddressDetailWithRouter = withRouter(BoatAddressDetail);
const BoatAddressDetailAlertBox = withAlertBox(BoatAddressDetailWithRouter);
const BoatAddressDetailLoader = withLoader(BoatAddressDetailAlertBox);
const BoatAddressDetailToast = withToast(BoatAddressDetailLoader);
export default withStyles((theme) =>
  createStyles({
    root: {
      height: "calc(100vh - 70px)",
      overflowX: "hidden",
    },
    image: {
      position: "relative",
      backgroundImage: `url(${boatDetail})`,
      backgroundRepeat: "no-repeat",
      backgroundColor:
        theme.palette.type === "light"
          ? theme.palette.grey[50]
          : theme.palette.grey[900],
      backgroundSize: "cover",
      backgroundPosition: "center",
      padding: "120px 68px",
      height: "calc(100vh - 72px)",
    },
    appBar: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      width: "100%",
      boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
      backgroundColor: "#337eb0",
      height: theme.spacing(8.75),
    },
    toolbar: {
      flexWrap: "nowrap",
    },
    toolbarLogo: {
      flexGrow: 1,
    },
    link: {
      margin: theme.spacing(1, 2.5),
      fontFamily: "Roboto",
      fontSize: "14px",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.14,
      letterSpacing: "normal",
      textTransform: "none",
      cursor: "pointer",
      color: "#fff",
    },
    margin: {
      marginRight: theme.spacing(2),
    },
    drawer: {
      [theme.breakpoints.up("sm")]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },
    drawerPaper: {
      width: drawerWidth,
    },
    closeMenuButton: {
      marginRight: 0,
      marginLeft: "auto",
    },
    marginTop: {
      marginTop: theme.spacing(4),
    },
    backButtonBox: {
      width: "50px",
      height: "50px",
      borderRadius: "5px",
      border: "solid 2px #0174cf",
      backgroundColor: "#ffffff",
      textAlign: "center",
      cursor: "pointer",
    },
    backButtonBoxContent: {
      color: "#0174cf",
      fontFamily: "Montserrat",
      fontSize: "20px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "50px",
    },
    pageHeading: {
      fontFamily: "Montserrat",
      fontSize: "30px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.23,
      letterSpacing: "normal",
      textAlign: "center",
      color: "#183b56",
    },
    pageSubHeading: {
      fontFamily: "Montserrat",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "normal",
      textAlign: "center",
      color: "#343a40",
    },
    rightRoot: {
      justifyContent: "center",
      display: "flex",
    },
    overlay: {
      position: "absolute",
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
    },
    mainPostContent: {
      position: "relative",
      padding: theme.spacing(3),
      color: "#FFF",
      [theme.breakpoints.up("md")]: {
        padding: theme.spacing(6),
        paddingRight: 0,
      },
    },
    imagetxtcontainer: {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      margin: "auto",
    },
    leftPageHeading: {
      fontFamily: "Montserrat",
      fontSize: "20px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.21,
      letterSpacing: "normal",
      color: "#ffffff",
      paddingLeft: "8px",
    },
    pricing: {
      fontFamily: "Montserrat",
      fontSize: "32px",
      fontWeight: "600",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.22,
      letterSpacing: "normal",
      color: "#ffffff",
    },
    selectOperator: {
      fontFamily: "Montserrat",
      fontSize: "40px",
      fontWeight: "600",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.22,
      letterSpacing: "normal",
      color: "#ffffff",
    },
    leftsidelink: {
      fontFamily: "Montserrat",
      fontSize: "20px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.21,
      letterSpacing: "normal",
      color: "#004277",
    },
    headingline: {
      height: 0,
      width: "90%",
      border: "solid 1px #fff",
    },
    rightPageHeading: {
      fontFamily: "Montserrat",
      fontSize: "20px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.21,
      letterSpacing: "normal",
      color: "#004277",
      textAlign: "center",
    },
    rightPageSubHeading: {
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "normal",
      color: "#343a40",
      textAlign: "center",
    },
    inputLable: {
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.2,
      letterSpacing: "normal",
      color: "#343a40",
      textAlign: "center",
    },
    listTitleName: {
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "normal",
      color: "#004277",
      textAlign: "center",
      paddingLeft: "32px",
      cursor: "pointer",
    },
    selectedMarina: {
      backgroundColor: theme.palette.primary.main,
    },
  })
)(withBoatIdComponent(BoatAddressDetailToast));
