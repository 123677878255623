import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { dialogBoxProps  } from "../../../components/src/withDialog.web";
import { withAlertBoxProps } from "../../../components/src/withAlertBox.Web";
import { withToastProps } from "../../../components/src/withSnackBar.Web";
import { withLoaderProps } from "../../../components/src/withLoader.Web";
export const configJSON = require("./config");

export type Props = RouterProps & dialogBoxProps & withAlertBoxProps & withToastProps & withLoaderProps & {
  id: string;
  openConfirmDialog : string;
  // Customizable Area Start
  // Customizable Area End
};

interface S {
  // Customizable Area Start
  authToken : String,
  boatListCopy : Array<any>,
  boatList : Array<any>,
  showMap : boolean,
  compareBoatList : Array<any>,
  sortValue : string,
  filter : any,
  sortType : string,
  sortValueHeading : string,
  actionType : any
  markers : Array<any>
  lat : any,
  lng : any,
  searchType : any
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CustomerAccountInfoController extends BlockComponent<
  Props,
  S,
  SS
> {
  updateAccountInfoApiCallId: String = "";
  getBoatListApiCallId : String = "";
  likeaboatCallId : String = "";
  getBoatListByActivityApiCallId : string = "";
  getBoatListByAreaApiCallId : string = "";
  getALLBoatListApiCallId : string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
      // Customizable Area End
    ];

    this.state = {
      authToken : '',
      boatList : [],
      boatListCopy : [],
      showMap : true,
      compareBoatList : [],
      sortValue : 'price',
      sortType : "desc",
      sortValueHeading : "Lowest Price",
      filter : {
        passengerFilter : 1,
        durationFilter : 'half_day'
      },
      actionType : null,
      markers : [],
      lat : null,
      lng : null,
      searchType : 'location'
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.getBoatListApiCallId) {
        this.props.hideLoader();
        
        if (responseJson && responseJson?.data) {
          const markers = (responseJson?.data && responseJson?.data.length > 0) ? responseJson?.data.map((location : any)=>{return location?.attributes?.address?.latitude && {
              lat : location?.attributes?.address?.latitude,
              lng : location?.attributes?.address?.longitude,
              name : location?.attributes?.name,
              id : location.id,
              location_type : location.attributes.location_type
          }}).filter((a: any)=>{return a}) : [];

          
          this.setState({
              boatList : responseJson?.data,
              boatListCopy : responseJson?.data,
              markers : markers,
              // lat : lat/markers.length,
              // lng : lng/markers.length,
              
          },async ()=>{
            const boatData : any = this.props?.history?.location?.state || {};
            if(boatData?.actionType && boatData?.actionType === 'LikeABoat'){
              await StorageProvider.remove("currentPath");
              await StorageProvider.remove("currentRouteState");
              this.likeAndUnLikeAboat(true,boatData.boatDetails.id);
            }
          })
        } else {
          this.parseApiErrorResponse(responseJson);
        }
        }  else if (apiRequestCallId === this.likeaboatCallId) {
        this.props.hideLoader();
        if (responseJson && responseJson?.data && responseJson?.data?.id && responseJson?.data?.attributes) {
         this.getBoatListing(this.state.filter)
        } else {
          this.parseApiErrorResponse(responseJson);
        }
      } else if(apiRequestCallId === this.getBoatListByAreaApiCallId){
        this.props.hideLoader();
        if (responseJson && responseJson?.data) {
          this.setState({
              boatList : responseJson?.data,
              boatListCopy : responseJson?.data
          })
        } else {
          this.parseApiErrorResponse(responseJson);
        }
       } else if(apiRequestCallId === this.getBoatListByActivityApiCallId){
        this.props.hideLoader();
        if (responseJson && responseJson?.data) {
          this.setState({
              boatList : responseJson?.data,
              boatListCopy : responseJson?.data
          })
        } else {
          this.parseApiErrorResponse(responseJson);
        }
       } else if(this.getALLBoatListApiCallId === apiRequestCallId){
        this.props.hideLoader();
        if (responseJson && responseJson?.data) {
          this.setState({
              boatList : responseJson?.data,
              boatListCopy : responseJson?.data
          })
        } else {
          this.parseApiErrorResponse(responseJson);
        }
       }
    } else if (getName(MessageEnum.AlertMessage) === message.id) {
      const title = message.getData(getName(MessageEnum.AlertTitleMessage));
      var AlertBodyMessage = message.getData(
        getName(MessageEnum.AlertBodyMessage)
      );
      const msg = await this.props.showAlert({title : 'Error',message : AlertBodyMessage})
      if(msg === 'Your session has expired, please login again.' || msg === 'Invalid token'){
        await StorageProvider.remove("authToken")
        await StorageProvider.remove("accountInfo")  
        this.props.history.push("/basicAuth/login")
      }
    }
  }
  getBoatListing(filters : any,sortValue?:any,sortType?:any) {
    this.props.showLoader();
    const headers = {
      "content-type": configJSON.ApiContentType,
      "token" : this.state.authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getBoatListApiCallId = requestMessage.messageId;
    let url = configJSON.boatSearch;
    if(filters?.location){
      url += url.indexOf("?") > -1 ? "&location="+filters?.location : "?location="+filters?.location
    }  
    if(filters?.captainFilter){
      url += url.indexOf("?") > -1 ? "&operator_type="+(filters?.captainFilter === 'caption' ? 1 : 0) : "?operator_type="+(filters?.captainFilter === 'caption' ? 1 : 0)
    }
    if(filters?.passengerFilter){
      url += url.indexOf("?") > -1 ? "&number_of_guests="+filters?.passengerFilter : "?number_of_guests="+filters?.passengerFilter
    }
    if(filters?.dateFilter){
      url += url.indexOf("?") > -1 ? "&date="+filters?.dateFilter : "?date="+filters?.dateFilter
    }
    if(filters.durationFilter){
      url += url.indexOf("?") > -1 ? "&duration="+filters?.durationFilter : "?duration="+filters?.durationFilter
    }
    if(this.state.sortValue && this.state.sortType){
      url += url.indexOf("?") > -1 ? "&order_by="+this.state.sortValue+"&direction="+this.state.sortType : "?order_by="+this.state.sortValue+"&direction="+this.state.sortType
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${url}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  likeAndUnLikeAboat(like:boolean,boatId : any) {
    if(this.state.authToken){
    const headers = {
      "Content-Type": configJSON.ApiContentType,
      "token" : this.state.authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.likeaboatCallId = requestMessage.messageId;
    const httpBody = {
      "data": {
          "type": !like ?  "like" : "dislike",
          "attributes": {
              "boat_id": boatId
          }
      }
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_catalogue/like_boats`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPOST
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }
  getBoatsByArea = (id : any,latlng : any) => {
    this.props.showLoader();
    const headers = {
      "content-type": configJSON.ApiContentType,
      "token" : this.state.authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getBoatListByAreaApiCallId = requestMessage.messageId;
    let url = `bx_block_carouseldisplay0/destinations/${id}/boats?latitude=${latlng.lat}&longitude=${latlng.lng}`;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${url}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getBoatsByActivity = (id : any,latlng : any) => {
    this.props.showLoader();
    const headers = {
      "content-type": configJSON.ApiContentType,
      "token" : this.state.authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getBoatListByActivityApiCallId = requestMessage.messageId;
    let url = `bx_block_carouseldisplay0/activities/${id}/boats?latitude=${latlng.lat}&longitude=${latlng.lng}`;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${url}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getAllBoat(){
    this.props.showLoader();
    const headers = {
      "content-type": configJSON.ApiContentType,
      "token" : this.state.authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getALLBoatListApiCallId = requestMessage.messageId;
    let url = `bx_block_catalogue/boats/all_boats`;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${url}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
}
