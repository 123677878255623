export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const boatDetail = require("../assets/boatDetail.png");
export const caption = require("../assets/caption.png");
export const therenter = require("../assets/therenter.png");
export const tick = require("../assets/tick.png");
export const logo = require("../assets/logo.png");
export const captainTransparent = require("../assets/captain-transparent.png")
export const person = require("../assets/person.png")
export const upload = require("../assets/upload-3.png")
export const commercial = require("../assets/transportation.png")
export const boat = require("../assets/boatsvg.png")
export const pin = require("../assets/pin.png")
export const priview = require("../assets/image-priview-boat.jpg") 
export const boatImage = require("../assets/boatImage.jpg")
export const boatImageDemo1 = require("../assets/boatImageDemo1.png")
export const boatImageDemo2 = require("../assets/boatImageDemo2.png")
export const boatBackground = require("../assets/boatBackground.jpg")
export const Boat = require("../assets/boat.png")
export const boatImage1 = require("../assets/boatImage1.png")
export const MarinaBayBackground = require("../assets/MarinaBayBackground.png")
export const quoteSign = require("../assets/quoteSign.png")


export const calendar = require("../assets/calendar.png");
export const hook = require("../assets/hook.png");
export const captionIcon = require("../assets/captionIcon.png");
export const clock = require("../assets/clock.png");
export const team = require("../assets/team.png");
export const watch = require("../assets/watch.png");

export const shield  = require("../assets/shield.png");
export const error = require("../assets/error.png");

export const marinaIcon = require("../assets/marinaIcon.png");
export const ownerPin = require("../assets/owner.png");