import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import SignUpVerifyLinkDialog from "./SignUpVerifyLinkDialog.web";

import { RouterProps } from "react-router";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { dialogBoxProps } from "../../../components/src/withDialog.web";
import { withAlertBoxProps } from "../../../components/src/withAlertBox.Web";
import { withToastProps } from "../../../components/src/withSnackBar.Web";
import { withLoaderProps } from "../../../components/src/withLoader.Web";
export const configJSON = require("./config");

export type Props = RouterProps &
  dialogBoxProps &
  withAlertBoxProps &
  withToastProps &
  withLoaderProps & {
    id: string;
    // Customizable Area Start
    // Customizable Area End
  };
interface S {
  // Customizable Area Start
  email : String;
  signupToken : String;
  loading : boolean;
  verificationToken : string;
  selectedAvatar : any;
  selectedFilePriview : any;
  selectedFile : any;
  selectedDate : any;
  selectAvatarName : String;
  mobileOpen : boolean

  // Customizable Area End
}

interface SS {
  id: any;
}

export default class EmailAccountVerifyAndUploadPictureController extends BlockComponent<
  Props,
  S,
  SS
> {
  resendResetPassowrdLinkCallId: String = "";
  verifyAccountEndPointCallId : String = "";
  upoadProfictureLinkCallId : String = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
      // Customizable Area End
    ];
    this.state = {
      email : "",
      signupToken : "",
      loading : true,
      verificationToken : "",
      selectedAvatar : null,
      selectedFilePriview : null,
      selectedFile : null,
      selectedDate : null,
      selectAvatarName : "",
      mobileOpen : false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.resendResetPassowrdLinkCallId) {
        this.props.hideLoader();
        if (responseJson && responseJson.message) {
          this.props.showToast({
            type: "success",
            message: "Verification link sent successfully",
          });
        } else {
          this.parseApiErrorResponse(responseJson);
        }
      } else if(apiRequestCallId === this.verifyAccountEndPointCallId){
        this.props.hideLoader();
        if (responseJson && responseJson?.meta && responseJson?.meta?.message) {
              this.resendLink();
        } else {
          this.parseApiErrorResponse(responseJson);
        }
      } else if(apiRequestCallId === this.upoadProfictureLinkCallId){
        this.props.hideLoader();
        if (responseJson && responseJson?.meta && responseJson?.meta?.message) {
          if(responseJson?.data?.attributes){
            this.props.showToast({
              type: "success",
              message: responseJson?.meta?.message,
            }); 
            await StorageProvider.set("signuptoken",this.state.verificationToken)
            this.props.history.push("/verifySignupMobile")
          }
        } else {
          this.parseApiErrorResponse(responseJson);
        }
      }
    } else if (getName(MessageEnum.AlertMessage) === message.id) {
      const title = message.getData(getName(MessageEnum.AlertTitleMessage));
      var AlertBodyMessage = message.getData(
        getName(MessageEnum.AlertBodyMessage)
      );
      this.props.showAlert({title: 'Error',message : AlertBodyMessage})
    }
  }
  resendLink = () => {
    this.props.openDialogBox({
      title: "",
      dataToPass: {},
      renderedComponent: SignUpVerifyLinkDialog,
      withCustomDialog: true,
      width : '538px',
      height : '316px',
      catchOnCancel : true
    }).then((data : any)=>{
      if(data){
        const { history } = this.props;
        history.push("/basicAuth/login");  
      }
     })
  };
  goToSelectedRoute = (routeName : string) => {
    const { history } = this.props;
    history.push(routeName);
  };
  verifyAccount(){
    this.props.showLoader();
    const headers = {
      "Content-Type": configJSON.ApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.verifyAccountEndPointCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.verifyAccountEndPoint}?token=${this.state.verificationToken}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  
  resendVerifyLink(values: any) {
    this.props.showLoader();
    const headers = {
      "Content-Type": configJSON.ApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.resendResetPassowrdLinkCallId = requestMessage.messageId;
    const httpBody = {
      data: {
        attributes: {
          email: this.state.email,
        },
      },
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.resendResetPasswordLinkEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPOST
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  uploadProfilePictureAndDate = () =>{
    this.props.showLoader();
    const headers = {
      "token" : this.state.verificationToken
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.upoadProfictureLinkCallId = requestMessage.messageId;
    const httpBody =  new FormData();
    if(this.state.selectedAvatar){
      httpBody.append("account[avatar]",this.state.selectedAvatar)
    } else if(this.state.selectedFilePriview) {
      httpBody.append("account[avatar]",this.state.selectedFilePriview.file)
    }
    httpBody.append("account[date_of_birth]",this.state.selectedDate)
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "accounts/profile_and_date_of_birth"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      httpBody
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPOST
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);    
  }
}
