//@ts-nocheck
import React from "react";
import EmailSignupController, {
  Props,
} from "./EmailAccountSignupController.web";
import {
  withStyles,
  Grid,
  Typography,
  createStyles,
  Button,
  TextField,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { google, facebook, arrow } from "./assets";
import InputField from "../../../components/src/InputFiled.web";
import { withRouter } from "react-router";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import SearchIcon from "@material-ui/icons/Search";
import withDialog from "../../../components/src/withDialog.web";
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
const filter = createFilterOptions();
const sighupSchema = Yup.object().shape({
  firstName: Yup.string().required("This field is required."),
  lastName: Yup.string().required("This field is required."),
  email: Yup.string()
    .email("Email must be in valid format!")
    .required("This field is required."),
  password: Yup.string()
    .min(8, "Enter atleast 8 digit Password")
    .matches(
      /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      "Password must contain at least 8 characters, one uppercase, one number and one special case character"
    )
    .required("This field is required"),
  confirmPassowrd: Yup.string()
    .min(8, "Enter atleast 8 digit Password")
    .matches(
      /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      "Password must contain at least 8 characters, one uppercase, one number and one special case character"
    )
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("This field is required"),
});
import CreateMarina from "./CreateMarina.web";
import StorageProvider from "../../../framework/src/StorageProvider.web";

class MarinaEmailAccountSignUp extends EmailSignupController {
  constructor(props: Props) {
    super(props);
  }
  async componentDidMount() {
    const authToken = await StorageProvider.get("authToken");
    if (authToken) {
      this.setState(
        {
          authToken: authToken,
        }
      );
    }
  }
  goToSelectedRoute = (routeName) => {
    const { history } = this.props;
    history.push(routeName);
  };
  createMarina = (value) => {
      this.props
        .openDialogBox({
          title: "Add Marina",
          withCustomDialog: false,
          renderedComponent: CreateMarina,
          dataToPass: { marinaName : value },
          catchOnCancel: true,
        })
        .then((data: any) => {
          if (data) {
            this.setState({
              selectedMarina : data
            },()=>{
              this.props.showToast({
                type: "success",
                message: "Marina added successfully",
              });
            })
          }
        });
  };
  render() {
    const { classes } = this.props;
    return (
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
          password: "",
          confirmPassowrd: "",
        }}
        validationSchema={sighupSchema}
        onSubmit={(values, actions) => {
          this.handleMarinaSignup(values);
        }}
      >
        {(formikProps) => {
          return (
            <Form autoComplete="off" noValidate>
              <Grid container item xs={12} sm={12} spacing={1}>
                <Grid item xs={12}>
                  <Typography
                    component="div"
                    className={classes.pageHeading}
                    align="left"
                  >
                    Sign up
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Field
                    component={InputField}
                    required
                    fullWidth
                    label="First Name"
                    name="firstName"
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    component={InputField}
                    required
                    fullWidth
                    label="Last Name"
                    name="lastName"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={InputField}
                    required
                    fullWidth
                    label="Email"
                    name="email"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={InputField}
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={InputField}
                    required
                    fullWidth
                    name="confirmPassowrd"
                    label="Confirm Password"
                    type="password"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    id="search marina"
                    freeSolo
                    style={{ width: "100%" }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    renderOption={(option) => option?.attributes?.name}
                    getOptionLabel={(option) => {
                      if (typeof option === "string") {
                        return option;
                      }
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      return option?.attributes?.name;
                    }}
                    options={this.state.searchSuggestionList}
                    onInputChange={(evt) => {
                      this.getMarinaSuggestion(evt?.target?.value);
                    }}
                    onChange={(event, newValue) => {
                      if (typeof newValue === "string") {
                        // timeout to avoid instant validation of the dialog's form.
                        setTimeout(() => {
                          // toggleOpen(true);
                          this.setState({
                            selectedMarina : newValue
                          })
                        });
                      } else if (newValue && newValue.inputValue) {
                        // toggleOpen(true);
                        this.setState({
                          selectedMarina : newValue
                        },()=>{
                          this.createMarina(newValue.inputValue)
                        })
                      } else {
                        this.setState({
                          selectedMarina : newValue
                        })
                      }
                    }}
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);

                      if (params.inputValue !== "") {
                        filtered.push({
                          inputValue: params.inputValue,
                          attributes : {
                            name: `Add "${params.inputValue}"`,
                          }
                        });
                      }

                      return filtered;
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="search marina"
                        variant="outlined"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {this.state.suggestionloading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {
                                <InputAdornment position="end">
                                  <IconButton aria-label="search">
                                    <SearchIcon />
                                  </IconButton>
                                </InputAdornment>
                              }
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <div className={classes.marginTop1}>
                    <Button
                      color="secondary"
                      variant="contained"
                      type="submit"
                      endIcon={<img style={{ height: "10px" }} src={arrow} />}
                    >
                      Create account
                    </Button>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    color="textSecondary"
                    display="inline"
                    variant="subtitle1"
                    align="left"
                    className={classes.donttext}
                  >
                    Already Have An Account?
                  </Typography>
                  <Button
                    className={classes.forgetPassword}
                    onClick={() => {
                      this.goToSelectedRoute("/basicAuth/login");
                    }}
                    variant="text"
                  >
                    Login
                  </Button>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
      // </Container>
    );
  }
}
const MarinaEmailAccountSignUpWithRouter = withRouter(MarinaEmailAccountSignUp);
const MarinaEmailAccountSignUpAlertBox = withAlertBox(
  MarinaEmailAccountSignUpWithRouter
);
const MarinaEmailAccountSignUpLoader = withLoader(
  MarinaEmailAccountSignUpAlertBox
);
const MarinaEmailAccountSignUpToast = withToast(MarinaEmailAccountSignUpLoader);

export default withStyles((theme) =>
  createStyles({
    paper: {
      margin: "48px auto",
    },
    pageHeading: {
      fontFamily: "Montserrat",
      fontSize: "20px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.15,
      letterSpacing: "normal",
      textTransform: "none",
      color: "#1b4050",
    },
    pageSubheading: {
      fontFamily: "Montserrat",
      fontSize: "14px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.2,
      letterSpacing: "normal",
      textTransform: "none",
      color: "#354f6c",
      textAlign: "center",
    },
    googleLoginButton: {
      borderRadius: "3px",
      border: "solid 2px #d44638",
      backgroundColor: "#ffffff",
      height: "40px",
    },
    googleLoginButtonLable: {
      fontFamily: "Roboto",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.15,
      letterSpacing: "normal",
      textTransform: "none",
      color: "#d44638",
      [theme.breakpoints.down("xs")]: {
        letterSpacing: "normal",
        fontSize: "10px",
      },
    },
    facebookButton: {
      marginLeft: "30px",
      [theme.breakpoints.down("xs")]: {
        marginLeft: "8px",
      },
    },
    facebookLoginButton: {
      borderRadius: "3px",
      border: "solid 2px #4267b2",
      backgroundColor: "#ffffff",
      height: "40px",
    },
    facebookLoginButtonLable: {
      fontFamily: "Roboto",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.15,
      letterSpacing: "normal",
      textTransform: "none",
      color: "#4267b2",
      [theme.breakpoints.down("xs")]: {
        letterSpacing: "normal",
        fontSize: "10px",
      },
    },
    forgetPassword: {
      marginLeft: theme.spacing(4),
    },
    donttext: {
      fontFamily: "Roboto",
      fontSize: "14px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.14,
      letterSpacing: "0.42px",
      textTransform: "none",
      color: "#1b4050",
    },
  })
)(withDialog(MarinaEmailAccountSignUpToast));
