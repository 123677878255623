import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import SignUpVerifyLinkDialog from "./SignUpVerifyLinkDialog.web";

import { RouterProps } from "react-router";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { dialogBoxProps } from "../../../components/src/withDialog.web";
import { withAlertBoxProps } from "../../../components/src/withAlertBox.Web";
import { withToastProps } from "../../../components/src/withSnackBar.Web";
import { withLoaderProps } from "../../../components/src/withLoader.Web";
export const configJSON = require("./config");

export type Props = RouterProps &
  dialogBoxProps &
  withAlertBoxProps &
  withToastProps &
  withLoaderProps & {
    id: string;
    // Customizable Area Start
    // Customizable Area End
  };
interface S {
  // Customizable Area Start
  loading : boolean;
  verificationToken : string;
  mobileNo : string;
  mobileOpen : boolean
  countaryCode : string
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class EmailAccountVerifyMobileNoController extends BlockComponent<
  Props,
  S,
  SS
> {
  resendResetPassowrdLinkCallId: String = "";
  verifyAccountEndPointCallId : String = "";
  sendOTPLinkCallId : String = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
      // Customizable Area End
    ];
    this.state = {
      loading : true,
      verificationToken : "",
      mobileNo : "",
      countaryCode : "",
      mobileOpen : false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if(apiRequestCallId === this.sendOTPLinkCallId){
        this.props.hideLoader();
        if (responseJson && responseJson?.meta && responseJson?.meta?.pin && responseJson?.meta?.token)  {
          this.props.showToast({
            type: "success",
            message: "OTP sent successfully",
          });
          await StorageProvider.set("otpToken",responseJson?.meta?.token)
          await StorageProvider.set("mobile",this.state.mobileNo)
          this.props.history.push("/verifySignupMobileOTP")
        } else {
          this.parseApiErrorResponse(responseJson);
        }
      }
      
    } else if (getName(MessageEnum.AlertMessage) === message.id) {
      const title = message.getData(getName(MessageEnum.AlertTitleMessage));
      var AlertBodyMessage = message.getData(
        getName(MessageEnum.AlertBodyMessage)
      );
      this.props.showAlert({title: 'Error',message : AlertBodyMessage})
    }
  }
  resendLink = () => {
    this.props.openDialogBox({
      title: "",
      dataToPass: {},
      renderedComponent: SignUpVerifyLinkDialog,
      withCustomDialog: true,
      width : '538px',
      height : '316px',
      catchOnCancel : true
    }).then(()=>{
      const { history } = this.props;
          history.push("/basicAuth/login");  
    })
  };
  goToSelectedRoute = (routeName : string) => {
    const { history } = this.props;
    history.push(routeName);
  };
  sendOTP = () => {
    this.props.showLoader();
    const headers = {
      "token" : this.state.verificationToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.sendOTPLinkCallId = requestMessage.messageId;
    const httpBody =  new FormData();
      httpBody.append("full_phone_number",`+${this.state.countaryCode}${this.state.mobileNo}`)
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "accounts/phone_number"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      (httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPOST
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  
}
