// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen.web";
import TopNav from "../../components/src/TopNav.web";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import MixpanelIntegration from "../../blocks/MixpanelIntegration/src/MixpanelIntegration";
import AdminConsole from "../../blocks/AdminConsole/src/AdminConsole";
import Reservations from "../../blocks/Reservations/src/Reservations";
import DocusignIntegration from "../../blocks/DocusignIntegration/src/DocusignIntegration";
import Reviews from "../../blocks/Reviews/src/Reviews";
import Gallery from "../../blocks/Gallery/src/Gallery";
import Sorting from "../../blocks/sorting/src/Sorting";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration.web";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import Chat from "../../blocks/Chat/src/Chat";
import RolesPermissions from "../../blocks/RolesPermissions/src/RolesPermissions";
import StripeIntegration from "../../blocks/StripeIntegration/src/StripeIntegration";
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import ElasticSearch from "../../blocks/ElasticSearch/src/ElasticSearch";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import Notifications from "../../blocks/Notifications/src/Notifications";
import Sms from "../../blocks/Sms/src/Sms";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import EmailNotifications from "../../blocks/EmailNotifications/src/EmailNotifications";
import Maps from "../../blocks/Maps/src/Maps";
import Payments from "../../blocks/Payments/src/Payments";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import CarouselDisplay0 from "../../blocks/CarouselDisplay0/src/CarouselDisplay0";
import PostCreation from "../../blocks/postcreation/src/PostCreation";
import Posts from "../../blocks/postcreation/src/Posts";
import PostDetails from "../../blocks/postcreation/src/PostDetails";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailLoginSignUpContainer.web";
import StoreLocator from "../../blocks/StoreLocator/src/StoreLocator";
import ApplePayIntegration from "../../blocks/ApplePayIntegration/src/ApplePayIntegration";
import PasswordEncryption from "../../blocks/PasswordEncryption/src/PasswordEncryption";

import RouteConfig from "./routeConfig";

const routeMap = {
SocialMediaAccountRegistrationScreen:{
 component:SocialMediaAccountRegistrationScreen,
path:"/SocialMediaAccountRegistrationScreen"},
MixpanelIntegration:{
 component:MixpanelIntegration,
path:"/MixpanelIntegration"},
AdminConsole:{
 component:AdminConsole,
path:"/AdminConsole"},
Reservations:{
 component:Reservations,
path:"/Reservations"},
DocusignIntegration:{
 component:DocusignIntegration,
path:"/DocusignIntegration"},
Reviews:{
 component:Reviews,
path:"/Reviews"},
Gallery:{
 component:Gallery,
path:"/Gallery"},
Sorting:{
 component:Sorting,
path:"/Sorting"},
UserProfileBasicBlock:{
 component:UserProfileBasicBlock,
path:"/UserProfileBasicBlock"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
Chat:{
 component:Chat,
path:"/Chat"},
RolesPermissions:{
 component:RolesPermissions,
path:"/RolesPermissions"},
StripeIntegration:{
 component:StripeIntegration,
path:"/StripeIntegration"},
Filteritems:{
 component:Filteritems,
path:"/Filteritems"},
Filteroptions:{
 component:Filteroptions,
path:"/Filteroptions"},
SocialMediaAccountLoginScreen:{
 component:SocialMediaAccountLoginScreen,
path:"/SocialMediaAccountLoginScreen"},
ElasticSearch:{
 component:ElasticSearch,
path:"/ElasticSearch"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
Notifications:{
 component:Notifications,
path:"/Notifications"},
Sms:{
 component:Sms,
path:"/Sms"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
EmailNotifications:{
 component:EmailNotifications,
path:"/EmailNotifications"},
Maps:{
 component:Maps,
path:"/Maps"},
Payments:{
 component:Payments,
path:"/Payments"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
CarouselDisplay0:{
 component:CarouselDisplay0,
path:"/CarouselDisplay0"},
PostCreation:{
 component:PostCreation,
path:"/PostCreation"},
Posts:{
 component:Posts,
path:"/Posts"},
PostDetails:{
 component:PostDetails,
path:"/PostDetails"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
StoreLocator:{
 component:StoreLocator,
path:"/StoreLocator"},
ApplePayIntegration:{
 component:ApplePayIntegration,
path:"/ApplePayIntegration"},
PasswordEncryption:{
 component:PasswordEncryption,
path:"/PasswordEncryption"},

  Home: {
    component: HomeScreen,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      // <View style={{ height: '100vh', width: '100vw' }}>
      //   <TopNav />
      //   {WebRoutesGenerator({ routeMap })}
      //   <ModalContainer />
      // </View>
          <RouteConfig></RouteConfig>
    );
  }
}

export default App;