//@ts-nocheck
import React from "react";
import VerifyOTPController, { Props } from "./verifyOTPController.web";
import {
  withStyles,
  Grid,
  Typography,
  createStyles,
  Button,
  Paper,
  Link,
  Box,
  Container,
  TextField,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
} from "@material-ui/core";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { boatImage } from "./assets";
import { withRouter } from "react-router";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import OtpInput from "../../../components/src/OtpInput.web";
const Schema = Yup.object().shape({
    otp: Yup.string().required("This field is required."),
});
class VerifyOTP extends VerifyOTPController {
  constructor(props: Props) {
    super(props);
  }
  goToSelectedRoute = (routeName) => {
    const { history } = this.props;
    history.push(routeName);
  };
  closeDialog = (data) => {
    const { onSubmit } = this.props;
    onSubmit(data);
  };
   
  render() {
    const { classes } = this.props;
    return (
              <Grid container justify="center" spacing={2}>
                <Grid item xs={12}>
                  <Typography>
                      Please enter otp send to your new phoen number or email {this.props.dataToPass.phoneNumber}/{this.props.dataToPass.email}
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <OtpInput OTPLength={4} value={this.state.otp} onOTPChange={(otp)=>{this.setState({otp : otp})}}></OtpInput>
                </Grid>
                <Grid item xs={12}>
                  <Grid container justify="flex-end">
                    <Grid item xs={2}>
                      <Button onClick={()=>{this.props.onCancel()}} color="primary" variant="outlined">
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item xs={2}>
                    <Button
                        color="primary"
                        disabled={this.state.otp.length != 4}
                        onClick={()=>{this.verifyOTP(this.state.otp)}}
                        variant="contained"
                      >
                        Verify OTP
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
          );
  }
}
const VerifyOTPWithRouter = withRouter(VerifyOTP);
const VerifyOTPWithToast = withToast(VerifyOTPWithRouter);
const VerifyOTPWithLoader = withLoader(VerifyOTPWithToast);
const VerifyOTPWithAlertBox = withAlertBox(VerifyOTPWithLoader);

export default withStyles((theme) =>
  createStyles({
    ownerName: {
      fontFamily: "Montserrat",
      fontSize: "12px",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.19,
      letterSpacing: "normal",
      color: "#000",
    },
    ownerNameHeading: {
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.19,
      letterSpacing: "normal",
      color: "#343a40",
    },
  })
)(VerifyOTPWithAlertBox);
