Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.ACCOUNT_TYPE_EMAIL = "EmailAccount";
exports.ACCOUNT_TYPE_SOCIAL = "SocialAccount";
exports.ACCOUNT_TYPE_PHONE = "SmsAccount";

exports.contentTypeApiUpdateUser = "application/json";
exports.apiEndPointUpdateUser = "profile/profile";
exports.apiUpdateUserType = "PUT";

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";

exports.contenttypeApiValidateMobileNo = "application/json";
exports.endPointApiValidateMobileNo = "profile/change_phone_validation";
exports.callTypeApiValidateMobileNo = "POST";

exports.endPointApiGetUserProfile = "profile/profile";
exports.contentTypeApiGetUserProfile = "application/json";
exports.methodTypeApiGetUserProfile = "GET";

// Customizable Area Start
exports.placeHolderEmail = "Email";
exports.labelHeader =
  "This is your profile, Here you can see and update your personal information.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelArea = "Area";
exports.labelMobile = "Mobile";
exports.labelEmail = "Email";
exports.labelCurrentPassword = "Current password";
exports.labelNewPassword = "New Password";
exports.labelRePassword = "Re-Type Password";
exports.btnTextCancelPasswordChange = "Cancel";
exports.btnTextSaveChanges = "Save Changes";
exports.btnTextChangePassword = "Change Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorMobileNoNotValid = "Phone number is not valid.";
exports.errorTitle = "Error";
exports.errorBothPasswordsNotSame = "Passwords must match.";
exports.errorCurrentNewPasswordMatch = "New password cannot match current password.";
exports.errorCurrentPasswordNotValid = "Current password not valid.";
exports.errorNewPasswordNotValid = "New password not valid.";
exports.errorReTypePasswordNotValid = "Re-type password not valid.";
exports.hintCountryCode = "Select Country";
exports.errorBlankField = "can't be blank";
exports.errorEmailNotValid = "Email not valid.";


exports.createBoatEndPoint = "bx_block_catalogue/boats"
exports.getBoatDetailPoint = "bx_block_catalogue/boats"
exports.getBoatListPoint = "bx_block_catalogue/boats"
exports.getPreferencesEndPoint = "bx_block_catalogue/preferences"
exports.getCancelPolicies = "bx_block_catalogue/cancellation_policies"
exports.getFeaturesEndPoint = "bx_block_catalogue/tags"
exports.createTagEndPoint = "bx_block_catalogue/tags"
exports.createAddiionalPhotoEndPoint = "bx_block_gallery/gallery"
exports.getMarinaListEndPoint = "bx_block_address_management/marinas"
exports.getLocationSuggestion = "bx_block_elasticsearch/search_address"
exports.getInsurancePolicy = "bx_block_catalogue/insurance_policies"
exports.getSafetyPolicy = "bx_block_catalogue/safety_policies"
exports.personalProfieInfo = "bx_block_profile/personal_information"
exports.accounSetting = "bx_block_profile/password"
exports.boatSearch = "bx_block_filter_items/filtering_boat"
exports.createconversations = "bx_block_chat/conversations"
exports.getMessagesPoint = "bx_block_chat/conversations/conversation_id/messages"
exports.documentEndPoint = "bx_block_docusignintegration/documents"
exports.bookingEndPoint = "bx_block_reservations/bookings"
exports.boatReviewEndPoint = "bx_block_catalogue/reviews"
exports.boatHistoryEndPoint = "bx_block_catalogue/boats"
exports.bookingComplainsEndPoint="bx_block_reservations/complaints"
exports.checkPromoCodePoint="bx_block_reservations/bookings/37/apply_promocode?promocode="
exports.methodGET = "GET"
exports.methodPOST = "POST"
exports.methodPUT = "PUT"
exports.methodDELETE = "DELETE"
exports.ApiContentType = "application/json";
// Customizable Area End



