import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { dialogBoxProps } from "../../../components/src/withDialog.web";
import { withAlertBoxProps } from "../../../components/src/withAlertBox.Web";
import { withToastProps } from "../../../components/src/withSnackBar.Web";
import { withLoaderProps } from "../../../components/src/withLoader.Web";
export const configJSON = require("./config");

export type Props = RouterProps &
  dialogBoxProps &
  withAlertBoxProps &
  withToastProps &
  withLoaderProps & {
    id: string;
    // Customizable Area Start
    // Customizable Area End
  };

  
interface S {
  // Customizable Area Start
  searchSuggestionList : Array<any>,
  suggestionloading : boolean,
  selectedMarina : any
  authToken : any
  // Customizable Area End
}

interface SS {
  id: any;
}
export type signupInputType = {
  name: String;
  email: String;
  password?: String;
  unique_auth_id? : String
};
export default class EmailAccountSignupController extends BlockComponent<
  Props,
  S,
  SS
> {
  signupApiCallId: String = "";
  signupSocialApiCallId : String = "";
  marinaSignupApiCallId : String = "";
  getMarinaSearchApiCallId : String = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
      // Customizable Area End
    ];

    this.state = {
      searchSuggestionList : [],
      suggestionloading : false,
      selectedMarina : null,
      authToken : ''
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.signupApiCallId) {
        const {history} = this.props;
        this.props.hideLoader();
        if (responseJson && responseJson.meta && responseJson.meta.token) {
          const email = responseJson.data.attributes.email;
          this.props.showToast({
            type: "success",
            message: "Verification link sent successfully",
          });
          const token = responseJson.meta.token;
          await StorageProvider.set("signupToken", token);
          await StorageProvider.set("signupEmail", email);
          // history.push("/basicAuth/verifyAccount")
        } else {
          this.parseApiErrorResponse(responseJson);
        }
      } else if (apiRequestCallId === this.signupSocialApiCallId) {
        this.props.hideLoader();
        const {history} = this.props;
        if (responseJson && responseJson.meta && responseJson.meta.token) {
          const email = responseJson.data.attributes.email;
          const token = responseJson.meta.token;
          this.props.showToast({
            type: "success",
            message: "Signed Up Successfully",
          });
          await StorageProvider.set("signupToken", token);
          await StorageProvider.set("signupEmail", email);
          // history.push("/basicAuth/verifyAccount")
        } else {
          this.parseApiErrorResponse(responseJson);
        }
      } if (apiRequestCallId === this.marinaSignupApiCallId) {
        const {history} = this.props;
        this.props.hideLoader();
        if (responseJson && responseJson.meta && responseJson.meta.token) {
          const email = responseJson.data.attributes.email;
          this.props.showToast({
            type: "success",
            message: "Verification link sent successfully",
          });
          const token = responseJson.meta.token;
          await StorageProvider.set("signupToken", token);
          await StorageProvider.set("signupEmail", email);
          // history.push("/basicAuth/verifyAccount")
        } else {
          this.parseApiErrorResponse(responseJson);
        }
      } else if (apiRequestCallId === this.getMarinaSearchApiCallId) {
        if (responseJson && responseJson.data) {
          this.setState({
            searchSuggestionList : responseJson.data,
            suggestionloading : false
          })
        } else {
          this.parseApiErrorResponse(responseJson);
          // this.setState({loading:  false})
        }
      } 
    } else if (getName(MessageEnum.AlertMessage) === message.id) {
      const title = message.getData(getName(MessageEnum.AlertTitleMessage));
      var AlertBodyMessage = message.getData(
        getName(MessageEnum.AlertBodyMessage)
      );
      this.props.showAlert({title: 'Error',message : AlertBodyMessage})
    }
  }
  handleSocialAccountSignup = (values: signupInputType) => {
    this.props.showLoader();
    const headers = {
      "Content-Type": configJSON.ApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.signupSocialApiCallId = requestMessage.messageId;
    const httpBody = {
      data: {
        type: "social_account",
        attributes: {
          user_name: values.name,
          email: values.email,
          unique_auth_id: values.unique_auth_id,
        },
      },
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.signInAPiEmailEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPOST
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleSignup(values: signupInputType) {
    this.props.showLoader();
    const headers = {
      "Content-Type": configJSON.ApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.signupApiCallId = requestMessage.messageId;
    const httpBody = {
      data: {
        type: "email_account",
        attributes: {
          user_name: values.name,
          email: values.email,
          password: values.password,
        },
      },
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.signInAPiEmailEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPOST
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleMarinaSignup = (values : any) => {
    this.props.showLoader();
    const headers = {
      "Content-Type": configJSON.ApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.marinaSignupApiCallId = requestMessage.messageId;
    const httpBody = {
      data: {
        type: "email_account",
        attributes: {
          user_name: (values.firstName || "") + " " + (values.lastName || ""),
          email: values.email,
          password: values.password,
        },
      },
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.signInAPiEmailEndPoint}?marina_manager=true&marina_id=${this.state?.selectedMarina?.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPOST
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);    
  }
  getMarinaSuggestion = (location : any) => {
     if(location){
        this.setState({
          suggestionloading : true
        },()=>{
          const headers = {
            "content-type": configJSON.ApiContentType,
            "token" : this.state.authToken
          };
          const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
          );
          this.getMarinaSearchApiCallId = requestMessage.messageId;
          requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${"bx_block_address_management/marinas"}?search=${location}`
          );
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
          );
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.methodGET
          );
          runEngine.sendMessage(requestMessage.id, requestMessage);
        })
      }
  }
}
