//@ts-nocheck
import React from "react";
import BoatCategoryDetailsController, {
  Props,
} from "./BoatCategoryDetailsController.web";
import {
  withStyles,
  Grid,
  AppBar,
  Toolbar,
  Typography,
  createStyles,
  Box,
  ListItem,
  List,
  ListItemText,
  Button,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import { logo,boatDetail, commercial, boat } from "./assets";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import  withAlertBox  from "../../../components/src/withAlertBox.Web";
import  withToast  from "../../../components/src/withSnackBar.Web";
import  withLoader  from "../../../components/src/withLoader.Web";
import { withRouter } from "react-router";
const drawerWidth = 240;

class BoatCategoryDetails extends BoatCategoryDetailsController {
  constructor(props: Props) {
    super(props);
  }
  async componentDidMount(){
    const queryParams = new URLSearchParams(this.props.location.search);
    let boatId = queryParams.get("boatId");
    const mode = queryParams.get("mode");
    if(!boatId){
       boatId = await StorageProvider.get("boatId");
    }
    const authToken = await StorageProvider.get("authToken");
    if (authToken) {
      this.setState(
        {
          authToken: authToken
        },()=>{
          if(!mode && boatId){
            this.setState({
              boatId : boatId
            },()=>{
              this.getBoatDetail()
            })
          }  
        }       
      );
    }
    if(mode && mode === 'edit' && boatId){
      this.setState({
        boatId : boatId,
      },()=>{
        this.getBoatDetail();
      })
    }
    
  }
  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };
  goToSelectedRoute = async (routeName) => {
    await StorageProvider.remove("boatId")
    const { history } = this.props;
    history.push(routeName);
  };
  saveAndProcessBoatDetail = async () => {
    this.createBoat();
  }
  handleCategorySelection = (category) => {
      this.setState({
          selectedCategory : category
      })
  };
  render() {
    const { classes } = this.props;
    let PageLinks = [
      { displayName: "List your boat", route: "/boatListingLandingPage" },
      { displayName: "Inbox", route: "/ownerDashboard/messages" },
      { displayName: "Bookings", route: "/ownerDashboard/myBookings" },
    ];;
    if(this.state.authToken){
      PageLinks = [
        { displayName: "List your boat", route: "/boatListingLandingPage" },
        { displayName: "Inbox", route: "/ownerDashboard/messages" },
        { displayName: "Bookings", route: "/ownerDashboard/myBookings" },
      ];
    } else {
      PageLinks = [
        { displayName: "List your boat", route: "/boatListingLandingPage" },
        { displayName: "Support", route: "/" },
        { displayName: "Login", route: "/basicAuth/login" },
        { displayName: "Signup", route: "/basicAuth/signup" },
      ];
    }
    return (
      <>
        <AppBar
          position="static"
          color="default"
          elevation={0}
          className={classes.appBar}
        >
          <Toolbar className={classes.toolbar}>
            <Box className={classes.toolbarLogo}>
              <img onClick={()=>this.props.history.push("/")}  src={logo} style={{width:'22%'}} alt="boaturu" />
            </Box>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              edge="start"
              onClick={this.handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <Hidden xsDown implementation="css">
              <nav>
                {PageLinks.map((link) => {
                  return (
                    <Typography
                      key={link.displayName}
                      variant="button"
                      className={classes.link}
                      onClick={() => {
                        this.goToSelectedRoute(link.route);
                      }}
                    >
                      {link.displayName}
                    </Typography>
                  );
                })}
              </nav>
            </Hidden>
            <Hidden smUp implementation="css">
              <Drawer
                variant="persistent"
                anchor={"right"}
                open={this.state.mobileOpen}
                onClose={this.handleDrawerToggle}
                classes={{
                  paper: classes.drawerPaper,
                }}
                SlideProps={{ unmountOnExit: true }}
              >
                <IconButton
                  onClick={this.handleDrawerToggle}
                  className={classes.closeMenuButton}
                >
                  <CloseIcon />
                </IconButton>
                <div>
                  <List>
                    {PageLinks.map((link, index) => (
                      <ListItem button key={link.displayName}>
                        <ListItemText primary={link.displayName} />
                      </ListItem>
                    ))}
                  </List>
                </div>
              </Drawer>
            </Hidden>
          </Toolbar>
        </AppBar>
        <Grid container className={classes.root}>
          <Grid
            item
            xs={false}
            sm={4}
            md={5}
            container
            className={classes.image}
          >
            <div className={classes.overlay} />
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={12} />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography gutterBottom className={classes.pricing}>
                Boat Details
              </Typography>
              <div className={classes.headingline} />
              <Typography gutterBottom className={classes.selectOperator}>
                Let's list your boat!
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography gutterBottom className={classes.leftsidelink}>
                Pricing
              </Typography>
              <Typography gutterBottom className={classes.leftsidelink}>
                Insurance
              </Typography>
              <Typography gutterBottom className={classes.leftsidelink}>
                Safety and standards
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={8} md={7}>
            <Grid container style={{ padding: "16px 32px" }} spacing={5}>
              <Grid item xs={12} container justify="flex-start">
                <Grid item>
                  <Box className={classes.backButtonBox} onClick={()=>{this.goToSelectedRoute("/boatListingLandingPage")}}>
                    <Typography className={classes.backButtonBoxContent}>
                      {"<"}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              <Grid item xs={12} container justify="center">
                <Grid item>
                  <Typography align="center" className={classes.pageHeading}>
                    {" "}
                    What kind of boat are you listing?{" "}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} container justify="space-around">
                <Grid item xs={4} container className={classes.categoryBox}  onClick={()=>{this.handleCategorySelection('personal')}} style={this.state.selectedCategory === 'personal' ? {backgroundColor : '#c8e7ff',border : 'solid 2px #004277'} : {backgroundColor : '#FFFFFF'}}>
                  <div className={classes.imagetxtcontainer} onClick={()=>{this.handleCategorySelection('personal')}}>
                    <img src={boat} />
                    <Typography className={classes.categoryBoxTitle}>
                      Personal
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={4} container className={classes.categoryBox} onClick={()=>{this.handleCategorySelection('commercial')}} style={this.state.selectedCategory === 'commercial' ? {backgroundColor : '#c8e7ff',border : 'solid 2px #004277'} : {backgroundColor : '#FFFFFF'}}>
                  <div className={classes.imagetxtcontainer} onClick={()=>{this.handleCategorySelection('commercial')}}>
                    <img src={commercial} />
                    <Typography className={classes.categoryBoxTitle}>
                      Commercial
                    </Typography>
                  </div>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                style={{ paddingTop: "0px", paddingBottom: "0px" }}
                container
                justify="space-around"
              >
                <Grid item xs={4}>
                  <Typography
                    align="center"
                    className={classes.categoryBoxHelperText}
                  >
                    Choose this if you have personal insurance
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    align="center"
                    className={classes.categoryBoxHelperText}
                  >
                    Choose this if you have commercial insurance
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} container justify="center">
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={!this.state.selectedCategory}
                  onClick={()=>{this.saveAndProcessBoatDetail()}}
                >
                  Proceed
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }
}
const BoatCategoryDetailsWithRouter = withRouter(BoatCategoryDetails);
const BoatCategoryDetailsAlertBox = withAlertBox(BoatCategoryDetailsWithRouter);
const BoatCategoryDetailsLoader = withLoader(BoatCategoryDetailsAlertBox);
const BoatCategoryDetailsToast = withToast(BoatCategoryDetailsLoader)

export default withStyles((theme) =>
  createStyles({
    root: {
      height: "calc(100vh - 70px)",
      overflowX: "hidden",
    },
    image: {
      position: "relative",
      backgroundImage: `url(${boatDetail})`,
      backgroundRepeat: "no-repeat",
      backgroundColor:
        theme.palette.type === "light"
          ? theme.palette.grey[50]
          : theme.palette.grey[900],
      backgroundSize: "cover",
      backgroundPosition: "center",
      padding: "120px 68px",
      height: "calc(100vh - 72px)",
    },
    appBar: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      width: "100%",
      boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
      backgroundColor: "#337eb0",
      height: theme.spacing(8.75),
    },
    toolbar: {
      flexWrap: "nowrap",
    },
    toolbarLogo: {
      flexGrow: 1,
    },
    link: {
      margin: theme.spacing(1, 2.5),
      fontFamily: "Roboto",
      fontSize: "14px",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.14,
      letterSpacing: "normal",
      textTransform: "none",
      cursor: "pointer",
      color: "#fff",
    },
    margin: {
      margin: theme.spacing(1),
    },
    drawer: {
      [theme.breakpoints.up("sm")]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },
    drawerPaper: {
      width: drawerWidth,
    },
    closeMenuButton: {
      marginRight: 0,
      marginLeft: "auto",
    },
    marginTop: {
      marginTop: theme.spacing(4),
    },
    backButtonBox: {
      width: "50px",
      height: "50px",
      borderRadius: "5px",
      border: "solid 2px #0174cf",
      backgroundColor: "#ffffff",
      textAlign: "center",
      cursor : 'pointer'
    },
    backButtonBoxContent: {
      color: "#0174cf",
      fontFamily: "Montserrat",
      fontSize: "20px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "50px",
    },
    pageHeading: {
      fontFamily: "Montserrat",
      fontSize: "30px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.23,
      letterSpacing: "normal",
      textAlign: "center",
      color: "#183b56",
    },
    pageSubHeading: {
      fontFamily: "Montserrat",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "normal",
      textAlign: "center",
      color: "#343a40",
    },
    rightRoot: {
      justifyContent: "center",
      display: "flex",
    },
    overlay: {
      position: "absolute",
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
    },
    mainPostContent: {
      position: "relative",
      padding: theme.spacing(3),
      color: "#FFF",
      [theme.breakpoints.up("md")]: {
        padding: theme.spacing(6),
        paddingRight: 0,
      },
    },
    imagetxtcontainer: {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      margin: "auto",
    },
    leftPageHeading: {
      fontFamily: "Montserrat",
      fontSize: "20px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.21,
      letterSpacing: "normal",
      color: "#ffffff",
      paddingLeft: "8px",
    },
    pricing: {
      fontFamily: "Montserrat",
      fontSize: "32px",
      fontWeight: "600",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.22,
      letterSpacing: "normal",
      color: "#ffffff",
    },
    selectOperator: {
      fontFamily: "Montserrat",
      fontSize: "40px",
      fontWeight: "600",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.22,
      letterSpacing: "normal",
      color: "#ffffff",
    },
    leftsidelink: {
      fontFamily: "Montserrat",
      fontSize: "20px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.21,
      letterSpacing: "normal",
      color: "#004277",
    },
    headingline: {
      height: 0,
      width: "90%",
      border: "solid 1px #fff",
    },
    rightPageHeading: {
      fontFamily: "Montserrat",
      fontSize: "24px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.21,
      letterSpacing: "normal",
      color: "#004277",
      paddingLeft: "48px",
    },
    categoryBox: {
      borderRadius: "15px",
      border: "solid 1px #004277",
      height: "150px",
      backgroundColor: "#ffffff",
      cursor: "pointer",
    },
    categoryBoxTitle: {
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.21,
      letterSpacing: "normal",
      color: "#004277",
      paddingTop: "12px",
    },
    categoryBoxHelperText: {
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "normal",
      color: "#343a40",
    },
  })
)(BoatCategoryDetailsToast);
