//@ts-nocheck
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import React from "react";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { dialogBoxProps  } from "../../../components/src/withDialog.web";
import { withAlertBoxProps } from "../../../components/src/withAlertBox.Web";
import { withToastProps } from "../../../components/src/withSnackBar.Web";
import { withLoaderProps } from "../../../components/src/withLoader.Web";
export const configJSON = require("./config");

export type Props = RouterProps & dialogBoxProps & withAlertBoxProps & withToastProps & withLoaderProps & {
  id: string;
  // Customizable Area Start
  // Customizable Area End
};

interface S {
  // Customizable Area Start
  authToken : String,
  profilePicturePreView : any;
  documentList : Array<any>,
  license_type : Array<any>
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CustomerDocumentsInfoController extends BlockComponent<
  Props,
  S,
  SS
> {
  updateDocumentApiCallId: String = "";
  getDocumentApiCallId : String = "";
  removeDocumentApiCallId : String = "";
  fileInputRef = React.createRef();
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
      // Customizable Area End
    ];

    this.state = {
      authToken : '',
      profilePicturePreView : null,
      documentList : [],
      license_type : []
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.updateDocumentApiCallId) {
        this.props.hideLoader();
        if (responseJson && responseJson?.data && responseJson?.data?.id && responseJson?.data?.attributes) {
          this.props.showToast({
            type: "success",
            message: "Document uploaded successfully",
          });
        } else {
          this.parseApiErrorResponse(responseJson);
        }
      } else if (apiRequestCallId === this.getDocumentApiCallId) {
        this.props.hideLoader();
        if (responseJson && responseJson?.data) {
          this.fileInputRef.current = new Array(responseJson?.data?.length)
          this.setState({
            documentList : responseJson?.data
          })
        } else {
          this.parseApiErrorResponse(responseJson);
        }
      } else if (apiRequestCallId === this.removeDocumentApiCallId) {
        this.props.hideLoader();
        if (responseJson && responseJson?.success) {
           this.props.showToast({type : 'success',message : 'document deleted successfully'})
        } else {
          this.parseApiErrorResponse(responseJson);
        }
      } 
    } else if (getName(MessageEnum.AlertMessage) === message.id) {
      const title = message.getData(getName(MessageEnum.AlertTitleMessage));
      var AlertBodyMessage = message.getData(
        getName(MessageEnum.AlertBodyMessage)
      );
      const msg = await this.props.showAlert({title : 'Error',message : AlertBodyMessage})
      if(msg === 'Your session has expired, please login again.' || msg === 'Invalid token'){
        await StorageProvider.remove("authToken")
        await StorageProvider.remove("accountInfo")  
        this.props.history.push("/basicAuth/login")
      }
    }
  }
  getAllDocument = () => {
    this.props.showLoader();
    const headers = {
      token : this.state.authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getDocumentApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.documentEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  } 
  
  updateDocument = (profilePicturePreView : any,document:any,index) => {
    this.props.showLoader();
    const headers = {
      token : this.state.authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateDocumentApiCallId = requestMessage.messageId;
    const fromdata = new FormData();
    if(profilePicturePreView?.length > 0){
        fromdata.append("document",profilePicturePreView[0].file);
        fromdata.append("doc_type",this.state.license_type[index] || (document.attributes.type || "boating_license"));
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          document?.id ? `${configJSON.documentEndPoint}/${document?.id}` : `${configJSON.documentEndPoint}`
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(headers)
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          fromdata
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          document?.id ? configJSON.methodPUT : configJSON.methodPOST
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    
  }
  removeDocuent = (id : any) => {
    this.props.showLoader();
    const headers = {
      token : this.state.authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.removeDocumentApiCallId = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          id ? `${configJSON.documentEndPoint}/${id}` : `${configJSON.documentEndPoint}`
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(headers)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.methodDELETE
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }        

  
}
