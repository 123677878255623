
//@ts-nocheck
import React, { Component } from "react";
import { createMuiTheme } from "@material-ui/core/styles";
import FAQController, { Props } from "./staticPageController.web";
import clsx from "clsx";
import {
  withStyles,
  Grid,
  Typography,
  createStyles,
  Button,
  Paper,
  AppBar,
  Toolbar,
  Menu,
  MenuItem,
  Box,
  Avatar,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemAvatar,
  ListItemSecondaryAction,
} from "@material-ui/core";
import InstagramIcon from "@material-ui/icons/Instagram";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import { logo } from "../../user-profile-basic/src/assets";

import { withRouter } from "react-router";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import StepConnector from "@material-ui/core/StepConnector";
import moment from "moment";
import { boatImageDemo1 } from "../../user-profile-basic/src/assets";
import withConfirmBox from "../../../components/src/withConfirmBox.Web";
import Footer from "./footer.web";

function generate(element: React.ReactElement) {
  return [
    {
      id: 1,
      name: "How do you communicate, pay and receive payment through Boaturu?",
      value:
        "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ad minima, quae quaerat voluptatum dolorem  voluptates dolores sed, pariatur quis explicabo unde adipisci rerum ducimus fugit earum? Doloremque sit  minima itaque aut illo quis quia? Delectus cumque,debitis numquam illo neque vel voluptatum inventore  consectetur eos enim corporis, itaque rerum esse totam. A molestias omnis blanditiis facilis enim  molestiae consequatur distinctio excepturi porro!",
    },
    {
      id: 2,
      name: "Where is Boaturu located?",
      value:
        "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ad minima, quae quaerat voluptatum dolorem  voluptates dolores sed, pariatur quis explicabo unde adipisci rerum ducimus fugit earum? Doloremque sit  minima itaque aut illo quis quia? Delectus cumque,debitis numquam illo neque vel voluptatum inventore  consectetur eos enim corporis, itaque rerum esse totam. A molestias omnis blanditiis facilis enim  molestiae consequatur distinctio excepturi porro!",
    },
    {
      id: 3,
      name: "How do I get my boaters license?",
      value:
        "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ad minima, quae quaerat voluptatum dolorem  voluptates dolores sed, pariatur quis explicabo unde adipisci rerum ducimus fugit earum? Doloremque sit  minima itaque aut illo quis quia? Delectus cumque,debitis numquam illo neque vel voluptatum inventore  consectetur eos enim corporis, itaque rerum esse totam. A molestias omnis blanditiis facilis enim  molestiae consequatur distinctio excepturi porro!",
    },
    {
      id: 4,
      name: "Does Boaturu offer insurance?",
      value:
        "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ad minima, quae quaerat voluptatum dolorem  voluptates dolores sed, pariatur quis explicabo unde adipisci rerum ducimus fugit earum? Doloremque sit  minima itaque aut illo quis quia? Delectus cumque,debitis numquam illo neque vel voluptatum inventore  consectetur eos enim corporis, itaque rerum esse totam. A molestias omnis blanditiis facilis enim  molestiae consequatur distinctio excepturi porro!",
    },
    {
      id: 5,
      name: "What is the cancellation policy for renters and owners?",
      value:
        "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ad minima, quae quaerat voluptatum dolorem  voluptates dolores sed, pariatur quis explicabo unde adipisci rerum ducimus fugit earum? Doloremque sit  minima itaque aut illo quis quia? Delectus cumque,debitis numquam illo neque vel voluptatum inventore  consectetur eos enim corporis, itaque rerum esse totam. A molestias omnis blanditiis facilis enim  molestiae consequatur distinctio excepturi porro!",
    },
    {
      id: 6,
      name: "How to report damage and lost items?",
      value:
        "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ad minima, quae quaerat voluptatum dolorem  voluptates dolores sed, pariatur quis explicabo unde adipisci rerum ducimus fugit earum? Doloremque sit  minima itaque aut illo quis quia? Delectus cumque,debitis numquam illo neque vel voluptatum inventore  consectetur eos enim corporis, itaque rerum esse totam. A molestias omnis blanditiis facilis enim  molestiae consequatur distinctio excepturi porro!",
    },
    {
      id: 7,
      name: "Requirements to rent a boat?",
      value:
        "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ad minima, quae quaerat voluptatum dolorem  voluptates dolores sed, pariatur quis explicabo unde adipisci rerum ducimus fugit earum? Doloremque sit  minima itaque aut illo quis quia? Delectus cumque,debitis numquam illo neque vel voluptatum inventore  consectetur eos enim corporis, itaque rerum esse totam. A molestias omnis blanditiis facilis enim  molestiae consequatur distinctio excepturi porro!",
    },
    {
      id: 8,
      name: "Benefits of listing on Boaturu?",
      value:
        "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ad minima, quae quaerat voluptatum dolorem  voluptates dolores sed, pariatur quis explicabo unde adipisci rerum ducimus fugit earum? Doloremque sit  minima itaque aut illo quis quia? Delectus cumque,debitis numquam illo neque vel voluptatum inventore  consectetur eos enim corporis, itaque rerum esse totam. A molestias omnis blanditiis facilis enim  molestiae consequatur distinctio excepturi porro!",
    },
    {
      id: 9,
      name: "What is the vetting process for renters?",
      value:
        "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ad minima, quae quaerat voluptatum dolorem  voluptates dolores sed, pariatur quis explicabo unde adipisci rerum ducimus fugit earum? Doloremque sit  minima itaque aut illo quis quia? Delectus cumque,debitis numquam illo neque vel voluptatum inventore  consectetur eos enim corporis, itaque rerum esse totam. A molestias omnis blanditiis facilis enim  molestiae consequatur distinctio excepturi porro!",
    },
    {
      id: 10,
      name: "How to set a booking price?",
      value:
        "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ad minima, quae quaerat voluptatum dolorem  voluptates dolores sed, pariatur quis explicabo unde adipisci rerum ducimus fugit earum? Doloremque sit  minima itaque aut illo quis quia? Delectus cumque,debitis numquam illo neque vel voluptatum inventore  consectetur eos enim corporis, itaque rerum esse totam. A molestias omnis blanditiis facilis enim  molestiae consequatur distinctio excepturi porro!",
    },
    {
      id: 11,
      name: "What is the fuel policy?",
      value:
        "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ad minima, quae quaerat voluptatum dolorem  voluptates dolores sed, pariatur quis explicabo unde adipisci rerum ducimus fugit earum? Doloremque sit  minima itaque aut illo quis quia? Delectus cumque,debitis numquam illo neque vel voluptatum inventore  consectetur eos enim corporis, itaque rerum esse totam. A molestias omnis blanditiis facilis enim  molestiae consequatur distinctio excepturi porro!",
    },
    {
      id: 12,
      name: "What are the different additional fees of renting through Boaturu?",
      value:
        "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ad minima, quae quaerat voluptatum dolorem  voluptates dolores sed, pariatur quis explicabo unde adipisci rerum ducimus fugit earum? Doloremque sit  minima itaque aut illo quis quia? Delectus cumque,debitis numquam illo neque vel voluptatum inventore  consectetur eos enim corporis, itaque rerum esse totam. A molestias omnis blanditiis facilis enim  molestiae consequatur distinctio excepturi porro!",
    },
    {
      id: 13,
      name: "What is the food, alcohol, pet and smoking policy for rentals?",
      value:
        "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ad minima, quae quaerat voluptatum dolorem  voluptates dolores sed, pariatur quis explicabo unde adipisci rerum ducimus fugit earum? Doloremque sit  minima itaque aut illo quis quia? Delectus cumque,debitis numquam illo neque vel voluptatum inventore  consectetur eos enim corporis, itaque rerum esse totam. A molestias omnis blanditiis facilis enim  molestiae consequatur distinctio excepturi porro!",
    },
    {
      id: 14,
      name: "What is the check-in and check-out process?",
      value:
        "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ad minima, quae quaerat voluptatum dolorem  voluptates dolores sed, pariatur quis explicabo unde adipisci rerum ducimus fugit earum? Doloremque sit  minima itaque aut illo quis quia? Delectus cumque,debitis numquam illo neque vel voluptatum inventore  consectetur eos enim corporis, itaque rerum esse totam. A molestias omnis blanditiis facilis enim  molestiae consequatur distinctio excepturi porro!",
    },
  ].map((value) =>
    React.cloneElement(element, {
      key: value.id,
      name : value.name,
      value : value.value
    })
  );
}

class FAQ extends FAQController {
  constructor(props: Props) {
    super(props);
  }
  handleClose = () => {
    this.setState({
      anchorEl: null,
    });
  };
  handleMenu = (event) => {
    if (this.state.authToken) {
      this.setState({
        anchorEl: event.currentTarget,
      });
    }
  };

  goToSelectedRoute = (routeName) => {
    if (routeName === "logout") {
      const onClose = async (result) => {
        if (result === "Yes") {
          await StorageProvider.clearStorage();
          this.props.history.push("/");
          window.location.reload();
        }
      };
      this.props.openConfirmDialog(
        "Logout",
        "Are you sure you want to logout from application",
        onClose
      );
    } else {
      const { history } = this.props;
      history.push(routeName);
    }
  };

  render() {
    const { classes } = this.props;
    const { theme } = this.props;
    const menuLinks = [
      { displayName: "My Account", route: "/userDashboard" },
      { displayName: "Logout", route: "logout" },
    ];
    return (
      <>
        <Paper
          className={classes.mainPost}
          style={{
            backgroundImage: `url('https://source.unsplash.com/random')`,
          }}
        >
          {
            <img
              style={{ display: "none" }}
              src={"https://source.unsplash.com/random"}
              alt={"aaaaaaaaaaaaaaaa"}
            />
          }
          <div className={classes.overlay} />
          <AppBar
            position="relative"
            color="default"
            elevation={0}
            className={classes.appBar}
          >
            <Toolbar className={classes.toolbar}>
              <Grid container spacing={5}>
                <Grid xs={2} md={6} item>
                  <img
                    onClick={() => this.props.history.push("/")}
                    src={logo}
                    style={{ width: "40%" }}
                    alt="boaturu"
                  />
                </Grid>

                <Grid xs={12} md={6} item>
                  <Grid container justify="space-between" alignItems="center">
                    <Grid item xs={2}>
                      <Typography
                        align="center"
                        className={classes.link}
                        onClick={() => {
                          if (this.state.isValidSession) {
                            this.goToSelectedRoute("/ownerDashboard/myBoats");
                          } else {
                            this.goToSelectedRoute("/basicAuth/login");
                          }
                        }}
                      >
                        List your boat
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography align="center" className={classes.link} onClick={()=>{this.props.history.push("/faq")}}>
                        Support
                      </Typography>
                    </Grid>

                    {this.state.isValidSession ? (
                      <Grid item xs={2}>
                        {/* <Typography
                        align="center"
                        onClick={() => {
                          const onClose = async (result) => {
                            if(result === 'Yes'){
                              await StorageProvider.clearStorage();
                              this.props.history.push("/")
                              window.location.reload();
                            }
                          }
                          this.props.openConfirmDialog(
                            'Logout','Are you sure you want to logout from application',onClose
                          )
                        }}
                        className={classes.link}
                      >
                        Logout
                      </Typography> */}
                        {this.state?.accountInfo?.avatar?.url ? (
                          <Avatar
                            src={this.state?.accountInfo?.avatar?.url}
                            onClick={this.handleMenu}
                          />
                        ) : (
                          <Avatar onClick={this.handleMenu}>{`${
                            this.state.accountInfo?.first_name?.charAt(0) || ""
                          } ${
                            this.state.accountInfo?.last_name?.charAt(0) || ""
                          }`}</Avatar>
                        )}

                        <Menu
                          id="menu-appbar"
                          anchorEl={this.state.anchorEl}
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                          keepMounted
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                          open={!!this.state.anchorEl}
                          onClose={this.handleClose}
                        >
                          {menuLinks.map((link) => {
                            return (
                              <MenuItem
                                key={link.displayName}
                                classes={{
                                  root: classes.menuItem,
                                }}
                                onClick={() => {
                                  this.goToSelectedRoute(link.route);
                                }}
                              >
                                {link.displayName}
                              </MenuItem>
                            );
                          })}
                        </Menu>
                      </Grid>
                    ) : (
                      <Grid item xs={2}>
                        <Typography
                          align="center"
                          onClick={() => {
                            this.goToSelectedRoute("/basicAuth/login");
                          }}
                          className={classes.link}
                        >
                          Login
                        </Typography>
                      </Grid>
                    )}
                    {!this.state.isValidSession && (
                      <Grid item xs={2}>
                        <Button
                          variant="contained"
                          onClick={() => {
                            this.goToSelectedRoute("/basicAuth/signup");
                          }}
                          className={classes.loginButton}
                        >
                          Signup
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
          <Grid container justify="center" alignItems="center">
            <Grid item md={12}>
              <div className={classes.mainPostContent}>
                <Typography
                  component="h1"
                  variant="h6"
                  style={{
                    color: "#fff",
                  }}
                  align="center"
                  gutterBottom
                >
                  Boaturu
                </Typography>
                <Box>
                  <Typography
                    variant="h4"
                    align="center"
                    style={{
                      color: "#fff",
                    }}
                  >
                    Because Life is better on the water
                  </Typography>
                </Box>
              </div>
            </Grid>
          </Grid>
        </Paper>

        <Grid container justify="space-around">
          {/* Boats Near me */}

          {/* Getaway */}
          <Grid item xs={11}>
            <Grid container className={classes.contentContainer} spacing={2}>
              <Grid item xs={12}>
                <List dense={false}>
              {  
              [
    {
      id: 1,
      name: "Just as we tell our owners, we suggest taking a picture of the vessel you are chartering before and after your rental. This helps ensure that any claims that may occur after a rental receive an honest evaluation",
      value:
        "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ad minima, quae quaerat voluptatum dolorem  voluptates dolores sed, pariatur quis explicabo unde adipisci rerum ducimus fugit earum? Doloremque sit  minima itaque aut illo quis quia? Delectus cumque,debitis numquam illo neque vel voluptatum inventore  consectetur eos enim corporis, itaque rerum esse totam. A molestias omnis blanditiis facilis enim  molestiae consequatur distinctio excepturi porro!",
    },
    {
      id: 2,
      name: "Be safe! Make sure you are comfortable with the boat and water laws. ",
      value:
        "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ad minima, quae quaerat voluptatum dolorem  voluptates dolores sed, pariatur quis explicabo unde adipisci rerum ducimus fugit earum? Doloremque sit  minima itaque aut illo quis quia? Delectus cumque,debitis numquam illo neque vel voluptatum inventore  consectetur eos enim corporis, itaque rerum esse totam. A molestias omnis blanditiis facilis enim  molestiae consequatur distinctio excepturi porro!",
    },
    {
      id: 3,
      name: "Arrive Early. If possible, arrive 10-15 minutes early to ensure your experience is as simple as possible. Also maximizing your time on the water! ",
      value:
        "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ad minima, quae quaerat voluptatum dolorem  voluptates dolores sed, pariatur quis explicabo unde adipisci rerum ducimus fugit earum? Doloremque sit  minima itaque aut illo quis quia? Delectus cumque,debitis numquam illo neque vel voluptatum inventore  consectetur eos enim corporis, itaque rerum esse totam. A molestias omnis blanditiis facilis enim  molestiae consequatur distinctio excepturi porro!",
    },
    {
      id: 4,
      name: "Relax and Enjoy yourself as boaturu provides you a fun experience for your family, friends, ad business associates.",
      value:
        "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ad minima, quae quaerat voluptatum dolorem  voluptates dolores sed, pariatur quis explicabo unde adipisci rerum ducimus fugit earum? Doloremque sit  minima itaque aut illo quis quia? Delectus cumque,debitis numquam illo neque vel voluptatum inventore  consectetur eos enim corporis, itaque rerum esse totam. A molestias omnis blanditiis facilis enim  molestiae consequatur distinctio excepturi porro!",
    }
  ].map((q)=>{
    return <ListItem key={q.id}>
    <ListItemIcon>{q.id}</ListItemIcon>
    <ListItemText
      classes={{
        primary : classes.question,
        secondary : classes.answer,
      }}
      primary={q.name}
    //   secondary={q.value}
    />
  </ListItem>
  })}
                  
                </List>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Footer></Footer>
      </>
    );
  }
}
const FAQWithRouter = withRouter(FAQ);

export default withStyles(
  (theme) =>
    createStyles({
      ItemContainer: {
        boxShadow: "0 3px 8px 0 rgba(0, 0, 0, 0.1)",
        border: "solid 1px #b8b8b8",
        borderRadius: "10px",
        backgroundColor: "#ffffff",
        height: "auto",
        marginTop: "20px",
      },
      mainPost: {
        position: "relative",
        backgroundColor: theme.palette.grey[800],
        color: theme.palette.common.white,
        marginBottom: theme.spacing(4),
        backgroundImage: "url(https://source.unsplash.com/random)",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        height: theme.spacing(50),
      },
      overlay: {
        position: "absolute",
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        backgroundColor: "rgba(0,0,0,.3)",
      },
      mainPostContent: {
        position: "relative",
        padding: theme.spacing(2),
        color: "#FFF",
        fontFamily: "Montserrat",
        [theme.breakpoints.up("md")]: {
          padding: theme.spacing(3),
          paddingRight: 0,
        },
      },
      mainPostSecondaryContent: {
        position: "relative",
        padding: theme.spacing(1),
        top: "50%",

        fontFamily: "Montserrat",
        [theme.breakpoints.down("sm")]: {
          borderRadius: "0px",
        },
        borderRadius: "8px",
        boxShadow: "0 10px 12px 0 rgba(0, 0, 0, 0.11)",
        backgroundColor: "rgb(1, 116, 207)",
      },
      boatNearme: {
        [theme.breakpoints.up("xs")]: {
          marginTop: "280px",
        },
        [theme.breakpoints.up("sm")]: {
          marginTop: "165px",
        },
        [theme.breakpoints.up("md")]: {
          marginTop: "0px",
        },
      },
      contentContainer: {
        marginBottom: "50px !important",
      },
      ListTourPost: {
        position: "relative",
        backgroundColor: theme.palette.grey[800],
        color: theme.palette.common.white,
        backgroundImage: "url(https://source.unsplash.com/random)",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        height: theme.spacing(50),
      },
      ListTourPostContent: {
        position: "relative",
        padding: theme.spacing(2),
        color: "#FFF",
        paddingTop: theme.spacing(6),
        [theme.breakpoints.up("md")]: {
          paddingLeft: theme.spacing(6),
        },
      },
      appBar: {
        background: "transparent",
      },
      toolbar: {
        display: "flex",
      },
      toolbarTitle: {
        flexGrow: 1,
      },
      link: {
        fontFamily: "Montserrat",
        fontSize: "14px",
        fontWeight: "bold",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1.33,
        letterSpacing: "normal",
        color: "#FFF",
        cursor: "pointer",
      },
      loginButton: {
        backgroundColor: "#fff",
        color: "blue",
        fontFamily: "Montserrat",
        fontSize: "12px",
        fontWeight: "bold",
        fontStretch: "normal",
        fontStyle: "normal",
        letterSpacing: "normal",
        color: "#1565d8",
        borderRadius: "5px",
      },
      inputfieledroot: {
        borderRadius: "10px",
        backgroundColor: "rgb(52, 144, 217)",
        color: "#fff",
      },
      input: {
        fontSize: "16px",
        fontFamily: "Montserrat",
        fontWeight: 600,
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1.19,
        letterSpacing: "normal",
        color: "#fff",
      },
      lableroot: {
        fontFamily: "Montserrat",
        fontSize: "14px",
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1.14,
        letterSpacing: "normal",
        color: "#fff",
      },
      boatImage: {
        position: "relative",
        backgroundImage: `url(${boatImageDemo1})`,
        backgroundRepeat: "no-repeat",
        backgroundColor:
          theme.palette.type === "light"
            ? theme.palette.grey[50]
            : theme.palette.grey[900],
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: theme.spacing(30),
        borderRadius: "5px",
      },
      boatImage1: {
        position: "relative",
        backgroundImage: `url(${boatImageDemo1})`,
        backgroundRepeat: "no-repeat",
        backgroundColor:
          theme.palette.type === "light"
            ? theme.palette.grey[50]
            : theme.palette.grey[900],
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: theme.spacing(50),
        borderRadius: "16px",
      },
      footerLinkHeading: {
        fontFamily: "Montserrat",
        fontSize: "16px",
        fontWeight: "bold",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1.15,
        marginBottom: "0.9rem",
        marginTop: "2rem",
        letterSpacing: "normal",
        color: "#545454",
      },
      footerLink: {
        fontFamily: "Montserrat",
        fontSize: "16px",
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1.15,
        letterSpacing: "normal",
        color: "#545454",
      },
      footer: {
        // padding: theme.spacing(0, 3.5),
        marginTop: "50px",
        // backgroundColor: "#f6f6f6",
      },
      footerTable: {
        [theme.breakpoints.up("sm")]: {
          display: "block",
        },
      },
      mediaButtonBox: {
        paddingTop: "1.8rem",
        [theme.breakpoints.down("sm")]: {
          paddingTop: "0.8rem",
        },
      },
      searchHeading: {
        fontFamily: "Montserrat",
      },
      subHeading: {
        fontFamily: "Montserrat",
        fontWeight: 600,
      },
      seeButton: {
        fontFamily: "Montserrat",
        borderRadius: "5px",
        borderWidth: "2px",
      },
      getStartedButton: {
        fontFamily: "Montserrat",
        borderRadius: "3px",
        borderWidth: "2px",
      },
      listButton: {
        fontFamily: "Montserrat",
        borderRadius: "3px",
        backgroundColor: "#FFFFFF",
        borderWidth: "2px",
        color: "#0174cf",
      },
      learnButton: {
        fontFamily: "Montserrat",
        borderRadius: "3px",
        backgroundColor: "transparent",
        color: "#FFFFFF",
        border: "solid 2px #FFFFFF",
      },
      smallHeading: {
        fontFamily: "Montserrat",
        fontWeight: 600,
        color: "#0FA6E0",
      },
      roundCorner: {
        borderRadius: "5px",
      },
      nearMeSlider: {
        width: "95%",
        height: "400px",
        borderRadius: "5px",
      },
      nearYouSlider: {
        width: "95%",
        borderRadius: "5px",
      },
      boatsInArea: {
        width: "95%",
        height: theme.spacing(20),
        margin: "2% 10%",
        borderRadius: "5px",
        cursor: "pointer",
      },
      seeAllxsUp: {
        fontFamily: "Montserrat",
        borderRadius: "5px",
        borderWidth: "2px",
        [theme.breakpoints.down("sm")]: {
          display: "none",
        },
      },
      seeAllxsDown: {
        display: "none",
        fontFamily: "Montserrat",
        borderRadius: "5px",
        borderWidth: "2px",
        [theme.breakpoints.down("sm")]: {
          display: "inherit",
        },
      },
      StepIcon: {
        root: {
          height: "1.5em",
          width: "1.5em",
        },
      },
      breakpoints: {
        lg: theme.breakpoints.values.lg,
      },
      customStepper: {
        display: "flex",
        margin: "10px",
        justifContent: "center",
        alignItems: "center",
        color: "white",
        backgroundColor: "#00A1DF",
        height: "2em",
        width: "2em",
        fontSize: "1rem",
        borderRadius: "50%",
        border: "5px #CCE3F6 solid",
      },
      activityImage: {
        position: "relative",
        color: theme.palette.common.black,
        marginBottom: theme.spacing(1),
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        height: theme.spacing(50),
        width: "95%",
      },
      papularImage: {
        position: "relative",
        color: theme.palette.common.black,
        marginBottom: theme.spacing(1),
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        height: theme.spacing(30),
        width: "95%",
      },
      primaryListText: {
        fontFamily: "Montserrat",
        fontSize: "20px",
        fontWeight: "bold",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1.21,
        letterSpacing: "normal",
        color: "#fff",
      },
      secondaryListText: {
        fontFamily: "Montserrat",
        fontSize: "14px",
        fontWeight: 500,
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1.25,
        letterSpacing: "normal",
        color: "#fff",
      },
      primaryStepText: {
        fontFamily: "Montserrat",
        fontSize: "20px",
        fontWeight: "bold",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1.21,
        letterSpacing: "normal",
        color: "#004277",
      },
      secondaryStepText: {
        fontFamily: "Montserrat",
        fontSize: "14px",
        fontWeight: 900,
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1.25,
        letterSpacing: "normal",
      },
      question : {
        fontFamily: "Montserrat",
        fontSize: "18px",
        fontWeight: "bold",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1.21,
        letterSpacing: "normal",
        color: "#004277",
      },
      answer : {
        fontFamily: "Montserrat",
        fontSize: "14px",
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        letterSpacing: "normal",
      }
    }),
  { withTheme: true }
)(FAQWithRouter);
