//@ts-nocheck
import {
  Avatar,
  Box,
  Button,
  Collapse,
  Container,
  Grid,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/core/styles";
import React from "react";
import { withRouter } from "react-router";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withLoader from "../../../components/src/withLoader.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { Boat } from "./assets";
import BoatBookingHistoryController from "./BoatBookingHistoryController.web";
import moment from "moment";

const InnerTableCell = withStyles({
  root: {
    borderBottom: "0px",
    fontFamily: "Montserrat",
    fontSize: "1rem",
    fontWeight: 500,
    lineHeight: 1.56,
    color: "#343a40",
  },
})(TableCell);
const OuterTableCell = withStyles({
  head: {
    borderBottom: "0px",
    fontFamily: "Montserrat",
    fontSize: "0.75rem",
    fontWeight: 600,
    lineHeight: 1.56,
    color: "#8b98a9;",
    backgroundColor: "#f7f9fc",
  },
  body: {
    fontFamily: "Montserrat",
    fontSize: "0.875rem",
    fontWeight: 500,
    lineHeight: 1.21,
    color: "#343a40;",
    cursor: "pointer",
    borderBottom: "solid 1px #eaecef",
  },
})(TableCell);

class Row2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = { open: false };
  }
  onRowClick = () => {
    this.setState({ open: !this.state.open });
  };
  render() {
    const { classes, row } = this.props;

    return (
      <>
        <TableRow onClick={this.onRowClick}>
          <OuterTableCell>
            {moment(row.created_at).format("DD/MM/YYYY")}
          </OuterTableCell>
          <OuterTableCell>
            <Avatar className={classes.avatar} />
            <Typography component="span">{row.account_id}</Typography>
          </OuterTableCell>
          <OuterTableCell>{row.payment_status}</OuterTableCell>
          <OuterTableCell>{row.id}</OuterTableCell>
          <OuterTableCell>{row.approval_status}</OuterTableCell>
          <OuterTableCell>{row.id}</OuterTableCell>
        </TableRow>
        <TableRow>
          <InnerTableCell className={classes.extraDetails} colSpan={6}>
            <Collapse in={this.state.open} timeout="auto" unmountOnExit>
              <Box p={3.75} className={classes.tripDetailsBox}>
                <Grid container>
                  <Grid item xs={2} spacing={2}>
                    <Typography
                      className={classes.tripDetailsTitle}
                      align="center"
                    >
                      Trip Details
                    </Typography>
                  </Grid>
                  <Grid item container xs={5} spacing={2}>
                    <Grid item xs={5}>
                      <Typography
                        className={classes.tripDetailsHead}
                        gutterBottom
                      >
                        Date:
                      </Typography>
                      <Typography
                        className={classes.tripDetailsHead}
                        gutterBottom
                      >
                        Passengers:
                      </Typography>
                      <Typography
                        className={classes.tripDetailsHead}
                        gutterBottom
                      >
                        Duration:
                      </Typography>
                      <Typography
                        className={classes.tripDetailsHead}
                        gutterBottom
                      >
                        Start Time:
                      </Typography>
                      {row.captain_included && (
                        <Typography
                          className={classes.tripDetailsHead}
                          gutterBottom
                        >
                          Captain Included
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={5}>
                      <Typography
                        className={classes.tripDetailsText}
                        gutterBottom
                      >
                        {moment(row.created_at).format("DD/MM/YYYY")}
                      </Typography>
                      <Typography
                        className={classes.tripDetailsText}
                        gutterBottom
                      >
                        {row.passengers}
                      </Typography>
                      <Typography
                        className={classes.tripDetailsText}
                        gutterBottom
                      >
                        {row.trip_duration ? row.trip_duration : "0"}
                      </Typography>
                      <Typography
                        className={classes.tripDetailsText}
                        gutterBottom
                      >
                        {moment(row.start_time).format("HH:mm")}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={5}
                    alignContent="flex-end"
                    spacing={2}
                  >
                    <Grid item xs={4}>
                      <Typography
                        className={classes.tripDetailsHead}
                        gutterBottom
                      >
                        Check in date
                      </Typography>
                      <Typography
                        className={classes.tripDetailsText}
                        gutterBottom
                      >
                        {moment(row.trip_date).format("DD/MM/YYYY")}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography
                        className={classes.tripDetailsHead}
                        gutterBottom
                      >
                        Check out date
                      </Typography>
                      <Typography
                        className={classes.tripDetailsText}
                        gutterBottom
                      >
                        {moment(row.trip_date).format("DD/MM/YYYY")}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography
                        className={classes.tripDetailsHead}
                        gutterBottom
                      >
                        Fuel level
                      </Typography>
                      <Typography
                        className={classes.tripDetailsText}
                        gutterBottom
                      >
                        {row.id}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Box pt={5}>
                        <Typography
                          className={classes.tripDetailsHead}
                          gutterBottom
                        >
                          Comments
                        </Typography>
                        <Typography
                          className={classes.tripDetailsText}
                          gutterBottom
                        >
                          {row.message}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Collapse>
          </InnerTableCell>
        </TableRow>
      </>
    );
  }
}

class BoatBookingHistory extends BoatBookingHistoryController {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      expandedData: {},
    };
  }
  async componentDidMount() {
    const urlParams = new URLSearchParams(this.props.location.search);
    const boatId = urlParams.get("boatId");

    const authToken = await StorageProvider.get("authToken");
    if (authToken) {
      this.setState(
        {
          authToken: authToken,
          boatId: boatId,
        },
        () => {
          this.getBoatDetails();
          this.getBoatHistory();
        }
      );
    }
  }
  goToSelectedRoute = (routeName) => {
    const { history } = this.props;
    history.push(routeName);
  };
  click = (id: string) => {
    const data = rows.find((row) => row.transactionId === id);
    if (data) {
      this.setState({
        open: !this.state.open,
        expandedData: data,
      });
    }
  };
  editBoat = (boatId) => {
    this.goToSelectedRoute(`/boatCategory?boatId=${boatId}&mode=${"edit"}`);
  };
  render() {
    const { classes } = this.props;
    return (
      <>
        <Container maxWidth="lg">
          {console.log(this.state.boatHistory, "hist")}
          <Box pb={4}>
            <Typography variant="h4" className={classes.PageHead}>
              My Boats/ Boat Details
            </Typography>
          </Box>
          <Grid container spacing={4}>
            <Grid item xs={12} md={4}>
              <img
                src={this.state?.boatDetails?.thumbnail_image?.url || Boat}
                alt="boat pic"
                width="100%"
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Grid item xs={12}>
                <Box pt={1} pb={3}>
                  <Typography variant="h6" className={classes.blueHeading}>
                    {this.state?.boatDetails?.name || "Not Avaialble"}
                  </Typography>
                </Box>
              </Grid>
              <Box
                display="flex"
                flexWrap="wrap"
                justifyContent="space-between"
                pr={4}
              >
                <Box>
                  <Typography variant="h6" className={classes.detailValue}>
                    {this.state?.boatDetails?.view_count || 0}
                  </Typography>
                  <Typography className={classes.detailName}>Views</Typography>
                </Box>
                <Box>
                  <Typography variant="h6" className={classes.detailValue}>
                    Not Available
                  </Typography>
                  <Typography className={classes.detailName}>
                    Bookings
                  </Typography>
                </Box>
              </Box>
              <Box mt={5}>
                <Typography className={classes.detailName}>
                  Date of listing Boat
                </Typography>
                <Typography variant="subtitle1" component="div">
                  Not Available
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Grid item xs={12}>
                <Box pt={1} pb={3.4}>
                  <Typography
                    variant="h6"
                    className={classes.completed}
                    align="right"
                  >
                    {this.state?.boatDetails?.complete
                      ? "COMPLETE"
                      : "INCOMPLETE"}
                  </Typography>
                </Box>
              </Grid>
              <Box
                display="flex"
                flexWrap="wrap"
                justifyContent="space-between"
              >
                <Box>
                  <Typography variant="h6" className={classes.detailValue}>
                    Not Available
                  </Typography>
                  <Typography className={classes.detailName}>
                    Earnings
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="subtitle2" component="div">
                    Not Visible for Audience
                  </Typography>
                  <Switch
                    size="small"
                    checked={this.state.visible}
                    onChange={(event) => {
                      this.setState({ visible: event.target.checked }, () => {
                        this.updateNameDetail(
                          this.state.boatId,
                          this.state.visible
                        );
                      });
                    }}
                  />
                </Box>
              </Box>
              <Box mt={5}>
                <Typography className={classes.detailName}>
                  Insurance Expiry date
                </Typography>
                <Typography variant="subtitle1" component="div">
                  Not Available
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Box
            display="flex"
            flexWrap="wrap"
            justifyContent="space-between"
            alignItems="center"
            my={2}
          >
            <Typography variant="h6">Booking History</Typography>
            <Button
              variant="outlined"
              color="primary"
              className={classes.editButton}
              onClick={() => {
                this.editBoat(this.state.boatId);
              }}
            >
              Edit Listing
            </Button>
          </Box>
          <Box>
            <TableContainer>
              <Table className={classes.table} aria-label="simple table">
                <TableHead className={classes.tableHead}>
                  <TableRow>
                    <OuterTableCell>Booking Date</OuterTableCell>
                    <OuterTableCell>Renter</OuterTableCell>
                    <OuterTableCell>Amount</OuterTableCell>
                    <OuterTableCell>Transaction id</OuterTableCell>
                    <OuterTableCell>Status</OuterTableCell>
                    <OuterTableCell>Actions</OuterTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.boatHistory && this.state.boatHistory.length > 0 
                    ? this.state.boatHistory.map((item, index) => (
                        <Row2
                          row={item.attributes}
                          classes={classes}
                          key={index}
                        />
                      ))
                    : <TableRow ><OuterTableCell colSpan={6} align="center">No Bookings Available</OuterTableCell></TableRow>}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Container>
      </>
    );
  }
}

const BoatBookingHistoryWithRouter = withRouter(BoatBookingHistory);
const BoatBookingHistoryAlertBox = withAlertBox(BoatBookingHistoryWithRouter);
const BoatBookingHistoryLoader = withLoader(BoatBookingHistoryAlertBox);
const BoatBookingHistoryToast = withToast(BoatBookingHistoryLoader);

export default withStyles((theme) =>
  createStyles({
    PageHead: {
      fontFamily: "Montserrat",
      color: "#343a40",
      lineHeight: 1.21,
      fontWeight: 600,
    },
    blueHeading: {
      fontFamily: "Montserrat",
      color: "#004277",
      lineHeight: 1.21,
      fontWeight: 600,
    },
    completed: {
      fontFamily: "Montserrat",
      fontWeight: 600,
      fontSize: "0.875rem",
      color: "#00a676",
    },
    avatar: {
      height: "20px",
      width: "20px",
      margin: "5px",
      display: "inline-block",
      verticalAlign: "bottom",
    },
    InnerTableCellflex: {
      display: "flex",
      alignItems: "center",
    },
    detailValue: {
      fontFamily: "Montserrat",
      fontSize: "20px",
      fontWeight: 600,
      lineHeight: 1.2,
      color: "#000000",
    },
    detailName: {
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: "600",
      color: "#343a40",
    },
    tripDetailsBox: {
      backgroundColor: "#e4e4e430",
      margin: "0 -16px",
    },
    tripDetailsTitle: {
      paddingTop: "0.25rem",
      color: "#183b56",
      fontFamily: "Montserrat",
      fontSize: "1rem",
      fontWeight: 600,
      lineHeight: 1.56,
    },
    tripDetailsHead: {
      fontFamily: "Montserrat",
      fontSize: "1rem",
      fontWeight: 600,
      color: "#343a40",
      lineHeight: 2,
    },
    tripDetailsText: {
      fontFamily: "Montserrat",
      fontSize: "1rem",
      fontWeight: 500,
      color: "#343a40",
      lineHeight: 2,
    },
    removeBorder: {
      borderCollapse: "collapse",
    },
    editButton: {
      border: "3px solid ",
      borderRadius: "7px",
    },
    extraDetails: {
      paddingTop: "0px",
      paddingBottom: "0px",
    },
  })
)(BoatBookingHistoryToast);
