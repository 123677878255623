import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { dialogBoxProps } from "../../../components/src/withDialog.web";
import { withAlertBoxProps } from "../../../components/src/withAlertBox.Web";
import { withToastProps } from "../../../components/src/withSnackBar.Web";
import { withLoaderProps } from "../../../components/src/withLoader.Web";
export const configJSON = require("./config");

export type Props = RouterProps &
  dialogBoxProps &
  withAlertBoxProps &
  withToastProps &
  withLoaderProps & {
    id: string;
    openConfirmDialog: string;
    // Customizable Area Start
    // Customizable Area End
  };

interface S {
  // Customizable Area Start
  authToken: String;
  boatHistory: Array<any>;
  boatId: String;
  boatDetails: any;
  visible : boolean
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class BoatBookingHistoryController extends BlockComponent<
  Props,
  S,
  SS
> {
  getBoatHistoryApiCallId: String = "";
  getBoatDetailsApiCallId: String = "";
  updateBoatVisiblityDetailApiId : String = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
      // Customizable Area End
    ];

    this.state = {
      authToken: "",
      boatHistory: [],
      boatId: "",
      boatDetails: {},
      visible : false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.getBoatHistoryApiCallId) {
        this.props.hideLoader();
        if (responseJson && responseJson?.data) {
          this.setState(
            {
              boatHistory: responseJson?.data,
            },
            () => console.log(this.state.boatHistory, "hist1")
          );
        } else {
          this.parseApiErrorResponse(responseJson);
        }
      } else if (apiRequestCallId === this.getBoatDetailsApiCallId) {
        this.props.hideLoader();
        if (responseJson && responseJson?.data) {
          this.setState({
            boatDetails: responseJson?.data.attributes,
            visible : !responseJson?.data?.attributes?.visible_for_audience,
          });
        } else {
          this.parseApiErrorResponse(responseJson);
        }
      } else if (apiRequestCallId === this.updateBoatVisiblityDetailApiId) {
        this.props.hideLoader();
        if (responseJson && responseJson?.data && responseJson?.data?.id && responseJson?.data?.attributes) {
          this.props.showToast({
            type: "success",
            message: "Boat visibility updated successfully",
          });
        } else {
          this.parseApiErrorResponse(responseJson);
        } 
      }
    } else if (getName(MessageEnum.AlertMessage) === message.id) {
      const title = message.getData(getName(MessageEnum.AlertTitleMessage));
      var AlertBodyMessage = message.getData(
        getName(MessageEnum.AlertBodyMessage)
      );
      const msg = await this.props.showAlert({
        title: "Error",
        message: AlertBodyMessage,
      });
      if (
        msg === "Your session has expired, please login again." ||
        msg === "Invalid token"
      ) {
        await StorageProvider.remove("authToken");
        await StorageProvider.remove("accountInfo");
        this.props.history.push("/basicAuth/login");
      }
    }
  }
  getBoatHistory() {
    this.props.showLoader();
    const headers = {
      "content-type": configJSON.ApiContentType,
      token: this.state.authToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getBoatHistoryApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.boatHistoryEndPoint}/${this.state.boatId}/booking_history`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getBoatDetails() {
    this.props.showLoader();
    const headers = {
      "content-type": configJSON.ApiContentType,
      token: this.state.authToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getBoatDetailsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getBoatDetailPoint}/${this.state.boatId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  updateNameDetail(boatId : string,visible : boolean) {
    this.props.showLoader();
    const headers = {
      "content-type": configJSON.ApiContentType,
      token : this.state.authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    this.updateBoatVisiblityDetailApiId = requestMessage.messageId;
    const httpBody = {
      visible_for_audience : visible
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      boatId ? `${configJSON.createBoatEndPoint}/${boatId}` : `${configJSON.createBoatEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      boatId ? configJSON.methodPUT : configJSON.methodPOST
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
}
