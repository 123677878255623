//@ts-nocheck
import React from "react";
import CustomerInboxController, { Props } from "./CustomerInboxController.web";
import {
  withStyles,
  Grid,
  Typography,
  createStyles,
  Button,
  Hidden,
  Paper,
  Divider,
  TextField,
} from "@material-ui/core";
import { Box, Grow } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import FolderIcon from "@material-ui/icons/Folder";
import DeleteIcon from "@material-ui/icons/Delete";

import { NavLink } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import AccountCircle from "@material-ui/icons/AccountCircle";
import { boatDetail } from "./assets";
import { withRouter } from "react-router";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import InputField from "../../../components/src/InputFiled.web";
const sideBarLinks = [
  { displayName: "My Documents", route: "/userDashboard/myDocuments" },
  { displayName: "My Bookings", route: "/userDashboard/myBookings" },
  { displayName: "Messages", route: "/userDashboard/messages" },
  {
    displayName: "Personal Information",
    route: "/userDashboard/personalInformation",
  },
  { displayName: "Payment", route: "/userDashboard/payment" },
  { displayName: "Account Settings", route: "/userDashboard/accountSettings" },
];

class CustomerInbox extends CustomerInboxController {
  fileInputRef = React.createRef();
  constructor(props: Props) {
    super(props);
  }
  async componentDidMount() {
    const authToken = await StorageProvider.get("authToken");
    const accountInfo = await StorageProvider.get("accountInfo");
    if (authToken && accountInfo) {
      this.setState(
        {
          authToken: authToken,
          senderId: JSON.parse(accountInfo)?.id,
        },
        () => {
          this.getChatList();
        }
      );
    }
  }

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };

  goToSelectedRoute = (routeName) => {
    this.handleClose();
    this.setState({ mobileOpen: false });
    const { history } = this.props;
    history.push(routeName);
  };
  openConversationForSelectedChat = (id, chat) => {
    this.setState(
      {
        conversastionId: id,
        reciverName:
          chat?.attributes?.chat[0]?.first_name +
          " " +
          (chat?.attributes?.chat[0]?.last_name || ""),
      },
      () => {
        this.getMessageList();
      }
    );
  };
  backToChat = () => {
    this.setState({
      conversastionId: null,
      reciverName: null,
    });
  };
  render() {
    const { classes } = this.props;
    return (
      <Grid container className={classes.root}>
        <>
         {!this.props.showSideBar && <Hidden xsDown>
            <Grid
              item
              xs={false}
              sm={1}
              md={2}
              container
              className={classes.sideBar}
            >
              <Grid item xs={12}>
                <Typography align="center" className={classes.sideBarHeading}>
                  My Account
                </Typography>
                <List dense={false}>
                  {sideBarLinks.map((item) => {
                    return (
                      <NavLink
                        to={item.route}
                        className={classes.sideLink}
                        activeClassName={classes.activeSideLink}
                        key={item.displayName}
                      >
                        <ListItem>
                          <ListItemAvatar>
                            <AccountCircle />
                          </ListItemAvatar>
                          <ListItemText
                            primary={item.displayName}
                            classes={{ primary: classes.sideLink }}
                          />
                        </ListItem>
                      </NavLink>
                    );
                  })}
                </List>
              </Grid>
            </Grid>
          </Hidden>
         } 
          <Grid item xs={this.props.showSideBar ? 12 : 12} sm={this.props.showSideBar ? 12 : 11} md={this.props.showSideBar ? 12 : 10} className={classes.rightRoot}>
            <main className={classes.layout}>
              <Paper className={classes.paper}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <div style={{ display: "flex" }}>
                      <div style={{ flexGrow: 1, lineHeight: "40px" }}>
                        <Typography className={classes.inbox}>
                          Inbox Messages
                        </Typography>
                      </div>
                      <Hidden smUp>
                        {this.state.conversastionId && (
                          <Button
                            variant="outlined"
                            onClick={() => {
                              this.backToChat();
                            }}
                            color="primary"
                          >
                            compose
                          </Button>
                        )}
                      </Hidden>
                      <Hidden smDown>
                        {this.state.conversastionId && (
                          <Button
                            variant="outlined"
                            onClick={() => {
                              this.backToChat();
                            }}
                            color="primary"
                          >
                            compose
                          </Button>
                        )}
                      </Hidden>
                    </div>
                  </Grid>
                  <Hidden xsDown>
                    <Grid item xs={4}>
                      <Typography className={classes.chats}>Chats</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography className={classes.chats}>
                        Message from {this.state.reciverName}
                      </Typography>
                    </Grid>
                  </Hidden>

                  <Grid item xs={12}>
                    <Grid container className={classes.chatBox}>
                      <Hidden smUp>
                        {this.state.conversastionId && (
                          <Grid item xs={12} className={classes.chatBox}>
                            <Typography className={classes.chats}>
                              Message from {this.state.reciverName}
                            </Typography>
                          </Grid>
                        )}
                      </Hidden>
                      <Hidden xsDown>
                        <Grid item xs={12} md={4} className={classes.chatBox}>
                          <List dense={true} className={classes.Listroot}>
                            {this.state.chatList && this.state.chatList.length > 0 ? 
                            this.state.chatList.map((chat) => {
                              return (
                                <ListItem
                                  divider
                                  key={chat.id}
                                  button
                                  onClick={() => {
                                    this.openConversationForSelectedChat(
                                      chat.id,
                                      chat
                                    );
                                  }}
                                >
                                  <ListItemAvatar>
                                    <Avatar src={boatDetail} />
                                  </ListItemAvatar>
                                  <ListItemText
                                    primary={
                                      chat?.attributes?.chat[0]?.first_name +
                                      " " +
                                      (chat?.attributes?.chat[0]?.last_name ||
                                        "")
                                    }
                                    secondary={
                                      chat?.attributes?.last_message?.message ||
                                      ""
                                    }
                                  />
                                  <ListItemSecondaryAction>
                                    <Typography className={classes.timeText}>
                                      {chat?.attributes?.last_message
                                        ?.updated_at &&
                                        new Date(
                                          chat?.attributes?.last_message?.updated_at
                                        ).toDateString()}
                                    </Typography>
                                  </ListItemSecondaryAction>
                                </ListItem>
                              );
                            }): <Typography align="center" variant="h4">No chats available</Typography>}
                          </List>
                        </Grid>
                      </Hidden>
                      <Hidden smUp>
                        {!this.state.conversastionId && (
                          <Grid item xs={12} md={4} className={classes.chatBox}>
                            <List dense={true} className={classes.Listroot}>
                              {this.state.chatList && this.state.chatList.length > 0 ? 
                              this.state.chatList.map((chat) => {
                                return (
                                  <ListItem
                                    divider
                                    key={chat.id}
                                    button
                                    onClick={() => {
                                      this.openConversationForSelectedChat(
                                        chat.id,
                                        chat
                                      );
                                    }}
                                  >
                                    <ListItemAvatar>
                                      <Avatar src={boatDetail} />
                                    </ListItemAvatar>
                                    <ListItemText
                                      primary={
                                        chat?.attributes?.chat[0]?.first_name +
                                        " " +
                                        (chat?.attributes?.chat[0]?.last_name ||
                                          "")
                                      }
                                      secondary={
                                        chat?.attributes?.last_message
                                          ?.message || ""
                                      }
                                    />
                                    <ListItemSecondaryAction>
                                      <Typography className={classes.timeText}>
                                        {chat?.attributes?.last_message
                                          ?.updated_at &&
                                          new Date(
                                            chat?.attributes?.last_message?.updated_at
                                          ).toDateString()}
                                      </Typography>
                                    </ListItemSecondaryAction>
                                  </ListItem>
                                );
                              }) : "No messages available"}
                            </List>
                          </Grid>
                        )}
                      </Hidden>

                      {/* <Divider orientation="vertical" flexItem /> */}
                      <Hidden smUp>
                        {this.state.conversastionId && (
                          <>
                            <Grid item xs={12} md={8}>
                              <div className={classes.chatRoot}>
                                {this.state.messageList.map((message) => {
                                  const l =
                                    message?.attributes?.account_id ==
                                    this.state.senderId
                                      ? "20%"
                                      : "4%";
                                  const r =
                                    message?.attributes?.account_id ==
                                    this.state.senderId
                                      ? "4%"
                                      : "20%";
                                  const bgcolor =
                                    message?.attributes?.account_id ==
                                    this.state.senderId
                                      ? "rgba(184, 184, 184,0.2)"
                                      : "#ffffff";
                                  const justifyContent =
                                    message?.attributes?.account_id ==
                                    this.state.senderId
                                      ? "flex-end"
                                      : "flex-start";
                                  return (
                                    <Box
                                      key={message.id}
                                      id={message.id}
                                      flex="0 0 auto"
                                      my={1}
                                      pl={l}
                                      pr={r}
                                      display="flex"
                                      justifyContent={justifyContent}
                                    >
                                      {message?.attributes?.account_id ==
                                        this.state.senderId && (
                                        <Avatar src={boatDetail} />
                                      )}
                                      <Box
                                        minWidth={0}
                                        py={1}
                                        px={2}
                                        bgcolor={bgcolor}
                                        borderRadius={16}
                                        className={message?.attributes?.account_id !=
                                          this.state.senderId?classes.messageTextBoxSender:classes.messageTextBoxRecipient}
                                      >
                                        <Typography
                                          variant="body1"
                                          className={classes.msgText}
                                          style={{
                                            overflowWrap: "break-word",
                                            whiteSpace: "pre-wrap",
                                          }}
                                        >
                                        {message?.attributes?.message}
                                        </Typography>
                                      </Box>
                                      {message?.attributes?.account_id !=
                                        this.state.senderId && (
                                        <Avatar src={boatDetail} />
                                      )}
                                    </Box>
                                  );
                                })}
                              </div>
                              <Formik
                                initialValues={{
                                  msgInput: "",
                                }}
                                enableReinitialize
                                onSubmit={async (values, { resetForm }) => {
                                  this.sendMessage(values.msgInput);
                                  resetForm({ msgInput: "" });
                                }}
                              >
                                {(formikProps) => {
                                  const {
                                    values,
                                    handleBlur,
                                    handleChange,
                                    handleSubmit,
                                    setFieldValue,
                                  } = formikProps;
                                  this.submitForm = handleSubmit;
                                  return (
                                    <Form noValidate autoComplete="off">
                                      <TextField
                                        InputProps={{
                                          classes: {
                                            root: classes.input,
                                          },
                                        }}
                                        onKeyPress={(ev) => {
                                          if (ev.key === "Enter") {
                                            ev.preventDefault();
                                            if (values.msgInput) {
                                              handleSubmit();
                                            }
                                          }
                                        }}
                                        fullWidth
                                        classes={classes.input}
                                        value={values.msgInput}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        variant="outlined"
                                        placeholder="Type your message"
                                        name="msgInput"
                                      />
                                    </Form>
                                  );
                                }}
                              </Formik>
                            </Grid>
                          </>
                        )}
                      </Hidden>
                      <Hidden xsDown>
                        {this.state.conversastionId && (
                          <Grid item xs={12} md={8}>
                            <div className={classes.chatRoot}>
                              {this.state.messageList.map((message) => {
                                const l =
                                  message?.attributes?.account_id ==
                                  this.state.senderId
                                    ? "20%"
                                    : "4%";
                                const r =
                                  message?.attributes?.account_id ==
                                  this.state.senderId
                                    ? "4%"
                                    : "20%";
                                const bgcolor =
                                  message?.attributes?.account_id ==
                                  this.state.senderId
                                    ? "rgba(184, 184, 184,0.2)"
                                    : "#ffffff";
                                const justifyContent =
                                  message?.attributes?.account_id ==
                                  this.state.senderId
                                    ? "flex-end"
                                    : "flex-start";
                                return (
                                  <Box
                                    key={message.id}
                                    id={message.id}
                                    flex="0 0 auto"
                                    my={2}
                                    pl={l}
                                    pr={r}
                                    display="flex"
                                    justifyContent={justifyContent}
                                  >
                                    {message?.attributes?.account_id !=
                                      this.state.senderId && (
                                      <Avatar src={boatDetail} />
                                    )}
                                    <Box
                                      minWidth={0}
                                      py={1}
                                      px={2}
                                      bgcolor={bgcolor}
                                      borderRadius={16}
                                      className={message?.attributes?.account_id !=
                                        this.state.senderId?classes.messageTextBoxSender:classes.messageTextBoxRecipient}
                                    >
                                      <Typography
                                        variant="body1"
                                        className={classes.msgText}
                                        style={{
                                          overflowWrap: "break-word",
                                          whiteSpace: "pre-wrap",
                                        }}
                                      >
                                       
                                        {message?.attributes?.message}
                                      </Typography>
                                    </Box>
                                    {message?.attributes?.account_id ==
                                      this.state.senderId && (
                                      <Avatar src={boatDetail} />
                                    )}
                                  </Box>
                                );
                              })}
                            </div>
                            <Formik
                              initialValues={{
                                msgInput: "",
                              }}
                              enableReinitialize
                              onSubmit={async (values, { resetForm }) => {
                                this.sendMessage(values.msgInput);
                                resetForm({ msgInput: "" });
                              }}
                            >
                              {(formikProps) => {
                                const {
                                  values,
                                  handleBlur,
                                  handleChange,
                                  handleSubmit,
                                  setFieldValue,
                                } = formikProps;
                                this.submitForm = handleSubmit;
                                return (
                                  <Form noValidate autoComplete="off">
                                    <TextField
                                      InputProps={{
                                        classes: {
                                          root: classes.input,
                                        },
                                      }}
                                      onKeyPress={(ev) => {
                                        if (ev.key === "Enter") {
                                          ev.preventDefault();
                                          if (values.msgInput) {
                                            handleSubmit();
                                          }
                                        }
                                      }}
                                      fullWidth
                                      classes={classes.input}
                                      value={values.msgInput}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      variant="outlined"
                                      placeholder="Type your message"
                                      name="msgInput"
                                    />
                                  </Form>
                                );
                              }}
                            </Formik>
                          </Grid>
                        )}
                      </Hidden>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </main>
          </Grid>
        </>
      </Grid>
    );
  }
}
const CustomerInboxWithRouter = withRouter(CustomerInbox);
const CustomerInboxAlertBox = withAlertBox(CustomerInboxWithRouter);
const CustomerInboxLoader = withLoader(CustomerInboxAlertBox);
const CustomerInboxToast = withToast(CustomerInboxLoader);
export default withStyles((theme) =>
  createStyles({
    pageHeading: {
      fontFamily: "Montserrat",
      fontSize: "20px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.21,
      letterSpacing: "normal",
      color: "#343a40",
    },
    pageSubHeading: {
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "normal",
      color: "#000000",
    },
    fileInput: {
      display: "none",
    },
    sectionHeading: {
      fontFamily: "Montserrat",
      fontSize: "20px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.21,
      letterSpacing: "normal",
      color: "#004277",
    },
    preView: {
      fontFamily: "Montserrat",
      fontSize: "12px",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.21,
      letterSpacing: "normal",
      color: "#000000",
    },
    primaryText: {
      fontFamily: "Montserrat",
      fontSize: "10px",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.21,
      letterSpacing: "normal",
      color: "#343a40",
    },
    secondaryText: {
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.21,
      letterSpacing: "normal",
      color: "#343a40",
    },
    root: {
      height: "calc(100vh - 70px)",
      overflowX: "hidden",
    },
    layout: {
      width: "auto",
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
        width: "80%",
        marginLeft: "auto",
        marginRight: "auto",
      },
    },
    paper: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      padding: theme.spacing(4),
      [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(6),
        padding: theme.spacing(3),
      },
    },
    sideBar: {
      height: "calc(100vh - 72px)",
      backgroundColor: "#ffffff",
    },
    rightRoot: {
      backgroundImage:
        "linear-gradient(180deg, rgba(0,0,255,1) 30%, rgba(255,255,255,1) 30%)",
    },
    sideLinkHeading: {
      fontFamily: "Montserrat",
      fontSize: "20px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "normal",
      color: "#183b56",
    },
    sideLink: {
      fontFamily: "Montserrat",
      fontSize: "12px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.22,
      letterSpacing: "normal",
      color: "#343a40",
      cursor: "pointer",
      textAlign: "center",
      display: "block",
      textDecoration: "none",
      "&:hover": {
        textDecoration: "none",
        textTransform: "none",
      },
    },
    activeSideLink: {
      backgroundColor: "rgb(1,116,207,0.1)",
      color: "#343a40",
      textTransform: "none",
      textDecoration: "none",
      cursor: "pointer",
    },
    chatBox: {
      border: "solid 1px #b8b8b8",
      backgroundColor: "#ffffff",
    },
    inbox: {
      fontFamily: "Montserrat",
      fontSize: "20px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.21,
      letterSpacing: "normal",
      color: "#343a40",
    },
    chats: {
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.21,
      letterSpacing: "normal",
      color: "#343a40",
    },
    timeText: {
      fontFamily: "Montserrat",
      fontSize: "12px",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.56,
      letterSpacing: "normal",
      color: "#343a40",
      margin:"0px 10px"
    },
    msgText: {
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.56,
      letterSpacing: "normal",
      color: "#343a40",
      margin:"0px 10px"
    },
    Listroot: {
      overflow: "auto",
      height: "calc(100vh - 300px)",
    },
    chatRoot: {
      overflow: "auto",
      height: "calc(100vh - 300px)",
    },
    messageTextBoxSender:{
      borderRadius:"0px 8px 8px 8px",
      margin:"0 10px",
      border:"1px solid #707070"

    },
    messageTextBoxRecipient:{
      borderRadius:"8px 0 8px 8px ",
      margin:"0 10px",
      

    }
  })
)(CustomerInboxToast);