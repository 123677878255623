//@ts-nocheck
import React from "react";
import ContactOwnerController, { Props } from "./ContactOwnerController.web";
import {
  withStyles,
  Grid,
  Typography,
  createStyles,
  Button,
  Paper,
  Link,
  Box,
  Container,
  TextField,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
} from "@material-ui/core";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { boatImage } from "./assets";
import { withRouter } from "react-router";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
import { Formik, Form } from "formik";
import * as Yup from "yup";
const Schema = Yup.object().shape({
  message: Yup.string().required("This field is required."),
});
class ContactOwner extends ContactOwnerController {
  constructor(props: Props) {
    super(props);
  }
  goToSelectedRoute = (routeName) => {
    const { history } = this.props;
    history.push(routeName);
  };
  closeDialog = (data) => {
    const { onSubmit } = this.props;
    onSubmit(data);
  };
  async componentDidMount() {
    const authToken = await StorageProvider.get("authToken");
    const accountInfo = await StorageProvider.get("accountInfo");
    if(authToken && accountInfo){
        this.setState({
            senderId : JSON.parse(accountInfo).id,
            authToken : authToken
        })
    }
    
  } 
  render() {
    const { classes } = this.props;
    return (
      <Formik
        initialValues={{
          message: "",
        }}
        validationSchema={Schema}
        onSubmit={(values, actions) => {
          this.setState({
              message : values.message
          },()=>{
              this.createConverSastion(this.state.senderId,this.props?.dataToPass?.boat?.attributes?.account?.id,values.message);
          })  
        }}
      >
        {(formikProps) => {
          const {
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            isValid,
            dirty,
          } = formikProps;
          return (
            <Form noValidate autoComplete="off">
              <Grid container justify="space-between" spacing={2}>
                <Grid item xs={6}>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar src={boatImage} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={this.props?.dataToPass?.boat?.attributes?.account?.user_name}
                      secondary="Verified Owner"
                      classes={{
                        primary: classes.ownerName,
                        secondary: classes.ownerNameHeading,
                      }}
                    />
                  </ListItem>
                </Grid>
                <Grid item xs={6}>
                  <ListItem>
                    <ListItemText
                      primary={"Boat owned"}
                      secondary={this.props?.dataToPass?.boat?.attributes?.name}
                      classes={{
                        primary: classes.ownerName,
                        secondary: classes.ownerNameHeading,
                      }}
                    />
                  </ListItem>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    InputProps={{
                      classes: {
                        root: classes.input,
                      },
                    }}
                    multiline
                    rows={10}
                    fullWidth
                    classes={classes.input}
                    helperText={errors.message && touched.message ? errors.message : ""}
                    error={errors.message && touched.message ? true : false}
                    value={values.message}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    variant="outlined"
                    placeholder="Type your message"
                    name="message"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Grid container justify="flex-end">
                    <Grid item md={2} xs={3}>
                      <Button onClick={()=>{this.props.onCancel()}} color="primary" variant="outlined">
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item md={2} xs={3}>
                    <Button
                        disabled={!isValid}
                        color="primary"
                        type="submit"
                        variant="contained"
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    );
  }
}
const ContactOwnerWithRouter = withRouter(ContactOwner);
const ContactOwnerWithToast = withToast(ContactOwnerWithRouter);
const ContactOwnerWithLoader = withLoader(ContactOwnerWithToast);
const ContactOwnerWithAlertBox = withAlertBox(ContactOwnerWithLoader);

export default withStyles((theme) =>
  createStyles({
    ownerName: {
      fontFamily: "Montserrat",
      fontSize: "12px",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.19,
      letterSpacing: "normal",
      color: "#000",
    },
    ownerNameHeading: {
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.19,
      letterSpacing: "normal",
      color: "#343a40",
    },
  })
)(ContactOwnerWithAlertBox);
