//@ts-nocheck
import {
  Box,
  Button,
  Checkbox,
  Collapse,
  createStyles,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
  withStyles,
  Link,
  Paper,
} from "@material-ui/core";
import moment from "moment";
import React from "react";
import { withRouter } from "react-router";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withLoader from "../../../components/src/withLoader.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import { boat, calendar, captionIcon, clock, team, watch } from "./assets";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ViewCustomerDocumentController, {
  Props,
} from "./ViewCustomerDocumentController.web";
import withConfirmBox from "../../../components/src/withConfirmBox.Web";
import StorageProvider from "../../../framework/src/StorageProvider.web";

class ViewCustomerDocument extends ViewCustomerDocumentController {
  constructor(props: Props) {
    super(props);
  }
  async componentDidMount() {
    const authToken = await StorageProvider.get("authToken");
    if (authToken) {
      this.setState({
        authToken: authToken,
      });
    }
  }
  openConfirmDialog = (data) => {
    const onClose = async (result) => {
      if (result === "Yes") {
        this.makeCheckIn(this.props?.dataToPass?.bookingDetail);
      }
    };
    this.props.openConfirmDialog(
      "Confirmation",
      `You have been checked in at ${moment().format(
        "MMM DD,YYYY"
      )},${moment().format("hh:mm A")}`,
      onClose
    );
  };

  viewDocumnet = (url) => {
      window.open(url,"_blank")
  }

  render() {
    const { classes } = this.props;
    return (
      <Grid container justify="space-between" spacing={2}>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Document Type</TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.props.dataToPass.Documents.map((doc) => (
                  <TableRow key={doc.id}>
                    <TableCell component="th" scope="row">
                      {doc.doc_type}
                    </TableCell>
                    <TableCell align="right">
                      <Link
                        onClick={() => {
                          this.viewDocumnet(doc.document.url);
                        }}
                      >
                        View
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    );
  }
}
const ViewCustomerDocumentWithRouter = withRouter(ViewCustomerDocument);
const ViewCustomerDocumentWithToast = withToast(ViewCustomerDocumentWithRouter);
const ViewCustomerDocumentWithLoader = withLoader(
  ViewCustomerDocumentWithToast
);
const ViewCustomerDocumentWithAlertBox = withAlertBox(
  ViewCustomerDocumentWithLoader
);

export default withStyles((theme) =>
  createStyles({
    mainContainer: {
      marginTop: "0.625rem",
      paddingTop: "15px",
      border: "1px #b8b8b8",
      borderStyle: "solid none none none",
    },
    marginLeft: {
      marginLeft: theme.spacing(2),
    },
    center: {
      [theme.breakpoints.down("xs")]: {
        justifyContent: "center",
        flexDirection: "column",
      },
    },
    tagDetails: {
      [theme.breakpoints.down("xs")]: {
        textAlign: "center",
      },
    },
    roundCorners: {
      borderRadius: "5px",
      borderWidth: "2px",
    },
    boldText: {
      fontWeight: 500,
    },
    flexEnd: {
      alignSelf: "flex-end",
    },
    fullWidth: {
      width: "100%",
    },
    table: {
        minWidth: 650,
      }
  })
)(withConfirmBox(ViewCustomerDocumentWithAlertBox));
