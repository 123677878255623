import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { dialogBoxProps  } from "../../../components/src/withDialog.web";
import { withAlertBoxProps } from "../../../components/src/withAlertBox.Web";
import { withToastProps } from "../../../components/src/withSnackBar.Web";
import { withLoaderProps } from "../../../components/src/withLoader.Web";
import moment from "moment";
export const configJSON = require("./config");

export type Props = RouterProps & dialogBoxProps & withAlertBoxProps & withToastProps & withLoaderProps & {
  id: string;
  // Customizable Area Start
  // Customizable Area End
};

interface S {
  // Customizable Area Start
  authToken : String,
  selectedBookingType : any,
  ownerBookings : Array<any>
  ownerCurrentBookings : Array<any>,
  ownerPastBookings : Array<any>,
  ownerUpcomingBookings : Array<any>
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class OwnerBookingsController extends BlockComponent<
  Props,
  S,
  SS
> {
  getBoatDetailsApiCallId : String = "";
  getOwnerBookingApiCallId : String = "";
  checkInBookingCallId : String = "";
  checkOutBookingCallId : String = "";
  getOwnerCuurentBookingApiCallId: String = "";
  getOwnerPastBookingApiCallId : String = "";
  getOwnerUpcomingBookingApiCallId : String = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
      // Customizable Area End
    ];

    this.state = {
      authToken : '',
      selectedBookingType : 0,
      ownerBookings : [],
      ownerCurrentBookings : [],
      ownerPastBookings : [],
      ownerUpcomingBookings : []
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.getOwnerCuurentBookingApiCallId) {
        this.props.hideLoader();
        if (responseJson && responseJson?.data) {
            this.setState({
              ownerCurrentBookings : responseJson?.data
            })
        } else {
          this.parseApiErrorResponse(responseJson);
        }
      } 
      if (apiRequestCallId === this.getOwnerPastBookingApiCallId) {
        this.props.hideLoader();
        if (responseJson && responseJson?.data) {
            this.setState({
              ownerPastBookings : responseJson?.data
            })
        } else {
          this.parseApiErrorResponse(responseJson);
        }
      } else
      if (apiRequestCallId === this.getOwnerUpcomingBookingApiCallId) {
        this.props.hideLoader();
        if (responseJson && responseJson?.data) {
            this.setState({
              ownerUpcomingBookings : responseJson?.data
            })
        } else {
          this.parseApiErrorResponse(responseJson);
        }
      } else
      if (apiRequestCallId === this.getBoatDetailsApiCallId) {
        this.props.hideLoader();
        if (responseJson && responseJson?.data) {
        //   this.setState({
        //       boatDetails : responseJson?.data
        //   })
        } else {
          this.parseApiErrorResponse(responseJson);
         }
        } else if(apiRequestCallId === this.getOwnerBookingApiCallId){
          this.props.hideLoader();
          if (responseJson && responseJson?.data) {
              this.setState({
                ownerBookings : responseJson?.data
              })
            } else {
              this.parseApiErrorResponse(responseJson);
             }
        } else if (apiRequestCallId === this.checkOutBookingCallId) {
          this.props.hideLoader();
          if (responseJson && responseJson?.data) {
          //   this.setState({
          //       boatDetails : responseJson?.data
          //   })
          } else {
            this.parseApiErrorResponse(responseJson);
           }
          } else if (apiRequestCallId === this.checkInBookingCallId) {
            this.props.hideLoader();
            if (responseJson && responseJson?.data) {
            //   this.setState({
            //       boatDetails : responseJson?.data
            //   })
            } else {
              this.parseApiErrorResponse(responseJson);
             }
            }  
    } else if (getName(MessageEnum.AlertMessage) === message.id) {
      const title = message.getData(getName(MessageEnum.AlertTitleMessage));
      var AlertBodyMessage = message.getData(
        getName(MessageEnum.AlertBodyMessage)
      );
      const msg = await this.props.showAlert({title : 'Error',message : AlertBodyMessage})
      if(msg === 'Your session has expired, please login again.' || msg === 'Invalid token'){
        await StorageProvider.remove("authToken")
        await StorageProvider.remove("accountInfo")  
        this.props.history.push("/basicAuth/login")
      }
    }
  }
  getBoatDetails() {
    this.props.showLoader();
    const headers = {
      "content-type": configJSON.ApiContentType,
      token : this.state.authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getBoatDetailsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getBoatDetailPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getOwnerApprovedBooking() {
    this.props.showLoader();
    const headers = {
      "content-type": configJSON.ApiContentType,
      token : this.state.authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getOwnerCuurentBookingApiCallId = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_reservations/bookings/approved_bookings`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getOwnerUpcomingtBooking() {
    this.props.showLoader();
    const headers = {
      "content-type": configJSON.ApiContentType,
      token : this.state.authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getOwnerUpcomingBookingApiCallId = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_reservations/bookings/ready_to_approve_bookings`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getOwnerPastBooking() {
    this.props.showLoader();
    const headers = {
      "content-type": configJSON.ApiContentType,
      token : this.state.authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getOwnerPastBookingApiCallId = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_reservations/bookings/past_bookings`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  makeCheckIn = (bookingDetial : any) => {
    this.props.showLoader();
    const headers = {
      "Content-Type": configJSON.ApiContentType,
      "token" : this.state.authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.checkInBookingCallId = requestMessage.messageId;
    const httpBody = {
      "visit": 
      {
          "checkin_status": "completed", /** pending /completed  **/
          "booking_id": bookingDetial.id,
          "checkin_date": moment().format("YYYY-MM-DD")
      }
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_reservations/visits/checkin`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPOST
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  
  makeCheckOut = () => {
    this.props.showLoader();
    const headers = {
      "Content-Type": configJSON.ApiContentType,
      "token" : this.state.authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.checkOutBookingCallId = requestMessage.messageId;
    const httpBody = {
      "visit": 
      {
          
          "booking_id": 6,
          "checkout_status": "completed", /** pending / completed  **/
          "checkout_date": "2021-06-10 15:01:24",
          "fuellevel": "11 gallon",
          "comments": "test comments"
      }
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_reservations/visits/checkout`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPOST
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  
  }
}
