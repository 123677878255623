import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { dialogBoxProps  } from "../../../components/src/withDialog.web";
import { withAlertBoxProps } from "../../../components/src/withAlertBox.Web";
import { withToastProps } from "../../../components/src/withSnackBar.Web";
import { withLoaderProps } from "../../../components/src/withLoader.Web";
import moment from "moment";
import EmailLoginSignUpContainerWeb from "../../email-account-login/src/EmailLoginSignUpContainer.web";
export const configJSON = require("./config");

export type Props = RouterProps & dialogBoxProps & withAlertBoxProps & withToastProps & withLoaderProps & {
  id: string;
  openConfirmDialog : string;
  // Customizable Area Start
  // Customizable Area End
};

interface S {
  // Customizable Area Start
  authToken : String,
  userCurrentBookings : Array<any>,
  userPastBookings : Array<any>,
  userUpcomingBookings : Array<any>
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CustomerBookingsController extends BlockComponent<
  Props,
  S,
  SS
> {
  getUserCuurentBookingApiCallId: String = "";
  getUserPastBookingApiCallId : String = "";
  getUserUpcomingBookingApiCallId : String = "";
  checkInBookingCallId : String = "";
  checkOutBookingCallId :  String = "";
  cancelBookingCallId : String = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
      // Customizable Area End
    ];

    this.state = {
      authToken : '',
      userCurrentBookings : [],
      userPastBookings : [],
      userUpcomingBookings : []
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.getUserCuurentBookingApiCallId) {
        this.props.hideLoader();
        if (responseJson && responseJson?.data) {
            this.setState({
              userCurrentBookings : responseJson?.data
            })
        } else {
          this.parseApiErrorResponse(responseJson);
        }
      } EmailLoginSignUpContainerWeb
      if (apiRequestCallId === this.getUserPastBookingApiCallId) {
        this.props.hideLoader();
        if (responseJson && responseJson?.data) {
            this.setState({
              userPastBookings : responseJson?.data
            })
        } else {
          this.parseApiErrorResponse(responseJson);
        }
      } else
      if (apiRequestCallId === this.getUserUpcomingBookingApiCallId) {
        this.props.hideLoader();
        if (responseJson && responseJson?.data) {
            this.setState({
              userUpcomingBookings : responseJson?.data
            })
        } else {
          this.parseApiErrorResponse(responseJson);
        }
      } else if (apiRequestCallId === this.checkInBookingCallId) {
        this.props.hideLoader();
        if (responseJson && responseJson?.data) {
            // this.props.showToast({type:'success',message:'Checked In Successfully'})
        } else {
          this.parseApiErrorResponse(responseJson);
        }
      } else if (apiRequestCallId === this.checkOutBookingCallId) {
        this.props.hideLoader();
        if (responseJson && responseJson?.data) {
          this.props.showToast({type:'success',message:'Checked Out Successfully'})
        } else {
          this.parseApiErrorResponse(responseJson);
        }
      } else if (apiRequestCallId === this.cancelBookingCallId) {
        this.props.hideLoader();
        if (responseJson && responseJson?.data) {
          this.props.showToast({type:'success',message:'Your booking has canceled sucessfully'})
          this.getUserCurrentBooking();
          this.getUserUpcomingtBooking();
          this.getUserPastBooking();
        } else {
          this.parseApiErrorResponse(responseJson);
        }
      } 
    } else if (getName(MessageEnum.AlertMessage) === message.id) {
      const title = message.getData(getName(MessageEnum.AlertTitleMessage));
      var AlertBodyMessage = message.getData(
        getName(MessageEnum.AlertBodyMessage)
      );
      const msg = await this.props.showAlert({title : 'Error',message : AlertBodyMessage})
      if(msg === 'Your session has expired, please login again.' || msg === 'Invalid token'){
        await StorageProvider.remove("authToken")
        await StorageProvider.remove("accountInfo")  
        this.props.history.push("/basicAuth/login")
      }
    }
  }
  cancelBooking = (bookingDetial : any) => {
    this.props.showLoader();
    const headers = {
      "Content-Type": configJSON.ApiContentType,
      "token" : this.state.authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.cancelBookingCallId = requestMessage.messageId;
    // const httpBody = {
    //   "visit": 
    //   {
    //       "checkin_status": "completed", /** pending /completed  **/
    //       "booking_id": bookingDetial.id,
    //       "checkin_date": moment().format("YYYY-MM-DD hh:mm:ss")
    //   }
    // };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_reservations/bookings/cancel_booking?rejected_by_type=renter&id=${bookingDetial.id}&reject_reason=${"plane chnaged"}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    // requestMessage.addData(
    //   getName(MessageEnum.RestAPIRequestBodyMessage),
    //   JSON.stringify(httpBody)
    // );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  makeCheckIn = (bookingDetial : any) => {
    this.props.showLoader();
    const headers = {
      "Content-Type": configJSON.ApiContentType,
      "token" : this.state.authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.checkInBookingCallId = requestMessage.messageId;
    const httpBody = {
      "visit": 
      {
          "checkin_status": "completed", /** pending /completed  **/
          "booking_id": bookingDetial.id,
          "checkin_date": moment().format("YYYY-MM-DD hh:mm:ss")
      }
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_reservations/visits/checkin`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPOST
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  
  makeCheckOut = (bookingDetail: any) => {
    this.props.showLoader();
    const headers = {
      "Content-Type": configJSON.ApiContentType,
      "token" : this.state.authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.checkOutBookingCallId = requestMessage.messageId;
    const httpBody = {
      "visit": 
      {
          
          "booking_id": bookingDetail?.id,
          "checkout_status": "completed", /** pending / completed  **/
          "checkout_date": "2021-06-10 15:01:24",
          "fuellevel": "11 gallon",
          "comments": bookingDetail?.formValues?.reviews
      }
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_reservations/visits/checkout`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPOST
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  
  }
  
  getUserCurrentBooking() {
    this.props.showLoader();
    const headers = {
      "content-type": configJSON.ApiContentType,
      token : this.state.authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getUserCuurentBookingApiCallId = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_reservations/bookings/current_renter_bookings`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getUserUpcomingtBooking() {
    this.props.showLoader();
    const headers = {
      "content-type": configJSON.ApiContentType,
      token : this.state.authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getUserUpcomingBookingApiCallId = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_reservations/bookings/upcoming_renter_bookings`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getUserPastBooking() {
    this.props.showLoader();
    const headers = {
      "content-type": configJSON.ApiContentType,
      token : this.state.authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getUserPastBookingApiCallId = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_reservations/bookings/past_renter_bookings`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
   
  
}
