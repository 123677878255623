//@ts-nocheck
import React from "react";
import CustomerPaymentOptionsController, {
  Props,
} from "./CustomerPaymentOptionsController.web";
import {
  withStyles,
  Grid,
  Typography,
  createStyles,
  Button,
  Avatar,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Hidden,
  Paper
} from "@material-ui/core";

import { NavLink } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import AccountCircle from "@material-ui/icons/AccountCircle";
import { boatDetail } from "./assets";
import { withRouter } from "react-router";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import InputField from "../../../components/src/InputFiled.web";
const sideBarLinks = [
  { displayName: "My Documents", route: "/userDashboard/myDocuments" },
  { displayName: "My Bookings", route: "/userDashboard/myBookings" },
  { displayName: "Messages", route: "/userDashboard/messages" },
  {
    displayName: "Personal Information",
    route: "/userDashboard/personalInformation",
  },
  { displayName: "Payment", route: "/userDashboard/payment" },
  { displayName: "Account Settings", route: "/userDashboard/accountSettings" },
];


class CustomerPaymentOptions extends CustomerPaymentOptionsController {
  fileInputRef = React.createRef();  
  constructor(props: Props) {
    super(props);
  }
  async componentDidMount() {
    const authToken = await StorageProvider.get("authToken");
    if (authToken) {
      this.setState({
        authToken: authToken,
      })
    }
  }

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };

  goToSelectedRoute = (routeName) => {
    this.handleClose();
    this.setState({ mobileOpen: false });
    const { history } = this.props;
    history.push(routeName);
  };
  
  render() {
    const { classes } = this.props;
    
    return <Grid container className={classes.root}>
    <>
      <Hidden xsDown>
        <Grid item xs={0} sm={1} md={2} container className={classes.sideBar}>
          <Grid item xs={12}>
            <Typography align="center" className={classes.sideBarHeading}>
              My Account
            </Typography>
            <List dense={false}>
              {sideBarLinks.map((item) => {
                return (
                  <NavLink
                    to={item.route}
                    className={classes.sideLink}
                    activeClassName={classes.activeSideLink}
                    key={item.displayName}
                  >
                    <ListItem>
                      <ListItemAvatar>
                        <AccountCircle />
                      </ListItemAvatar>
                      <ListItemText
                        primary={item.displayName}
                        classes={{ primary: classes.sideLink }}
                      />
                    </ListItem>
                  </NavLink>
                );
              })}
            </List>
          </Grid>
        </Grid>
      </Hidden>
      <Grid item xs={12} sm={11} md={10} className={classes.rightRoot}>
        <main className={classes.layout}>
          <Paper className={classes.paper}>
          </Paper>
        </main>
      </Grid>
    </>
    </Grid>
    
  }
}
const CustomerPaymentOptionsWithRouter = withRouter(CustomerPaymentOptions);
const CustomerPaymentOptionsAlertBox = withAlertBox(CustomerPaymentOptionsWithRouter);
const CustomerPaymentOptionsLoader = withLoader(CustomerPaymentOptionsAlertBox);
const CustomerPaymentOptionsToast = withToast(CustomerPaymentOptionsLoader);
export default withStyles((theme) =>
  createStyles({
    pageHeading: {
      fontFamily: "Montserrat",
      fontSize: "20px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.21,
      letterSpacing: "normal",
      color: "#343a40",
    },
    pageSubHeading: {
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "normal",
      color: "#000000",
    },
    fileInput : {
        display: 'none'
    },
    sectionHeading : {
      fontFamily: "Montserrat",
      fontSize: "20px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.21,
      letterSpacing: "normal",
      color: "#004277",
    },
    preView : {
      fontFamily: "Montserrat",
      fontSize: "12px",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.21,
      letterSpacing: "normal",
      color: "#000000"
    },
    primaryText : {
      fontFamily: "Montserrat",
      fontSize: "10px",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.21,
      letterSpacing: "normal",
      color: "#343a40"
    },
    secondaryText : {
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.21,
      letterSpacing: "normal",
      color: "#343a40"
    },
    root: {
      height: "calc(100vh - 70px)",
      overflowX: "hidden",
    },
    layout: {
      width: "auto",
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
        width: "80%",
        marginLeft: "auto",
        marginRight: "auto",
      },
    },
    paper: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      padding: theme.spacing(4),
      [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(6),
        padding: theme.spacing(3),
      },
    },
    sideBar: {
      height: "calc(100vh - 72px)",
      backgroundColor: "#ffffff",
    },
    rightRoot: {
      backgroundImage:
        "linear-gradient(180deg, rgba(0,0,255,1) 30%, rgba(255,255,255,1) 30%)",
    },
    sideLinkHeading: {
      fontFamily: "Montserrat",
      fontSize: "20px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "normal",
      color: "#183b56",
    },
    sideLink: {
      fontFamily: "Montserrat",
      fontSize: "12px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.22,
      letterSpacing: "normal",
      color: "#343a40",
      cursor: "pointer",
      textAlign: "center",
      display: "block",
      textDecoration: "none",
      "&:hover": {
        textDecoration: "none",
        textTransform: "none",
      },
    },
    activeSideLink: {
      backgroundColor: "rgb(1,116,207,0.1)",
      color: "#343a40",
      textTransform: "none",
      textDecoration: "none",
      cursor: "pointer",
    },
  })
)(CustomerPaymentOptionsToast);
