//@ts-nocheck
import React from "react";
import OwnerTransactionController, { Props } from "./OwnerTransactionController.web";

import { withStyles, createStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import {
  Grid,
  Avatar,
  Container,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Collapse,
  TableCell,
  Paper,
  TextField,
  InputBase,
} from "@material-ui/core/";
import moment from "moment";
import SearchIcon from "@material-ui/icons/Search";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import Button from "@material-ui/core/Button";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
import withDialog from "../../../components/src/withDialog.web";
import { withRouter } from "react-router";

const past = [
  {
    boatName: "2014 Sea Ray 52'",
    boatAddress: "Test Address",
    boatCity: "Test City",
    renterFirstName: "Vishal",
    rentertLastName: "Mathur",
    approvalStatus: "approved",
    boatOwnerName: "John Doe",
    tripDate: "2021-07-12T00:00:00.000Z",
    checkInDate: "2021-07-12T00:00:00.000Z",
    checkoutDate: "2021-07-12T00:00:00.000Z",
    rentAmount: "1500",
    action: "",
  },
  {
    boatName: "2014 Sea Ray 52'",
    boatAddress: "Test Address",
    boatCity: "Test City",
    renterFirstName: "Vishal",
    rentertLastName: "Mathur",
    approvalStatus: "pending",
    boatOwnerName: "John Doe",
    tripDate: "2021-07-12T00:00:00.000Z",
    checkInDate: "2021-07-12T00:00:00.000Z",
    checkoutDate: "2021-07-12T00:00:00.000Z",
    rentAmount: "1500",
    action: "",
  },
  {
    boatName: "2014 Sea Ray 52'",
    boatAddress: "Test Address",
    boatCity: "Test City",
    renterFirstName: "Vishal",
    rentertLastName: "Mathur",
    approvalStatus: "approved",
    boatOwnerName: "John Doe",
    tripDate: "2021-07-12T00:00:00.000Z",
    checkInDate: "2021-07-12T00:00:00.000Z",
    checkoutDate: "2021-07-12T00:00:00.000Z",
    rentAmount: "1500",
    action: "",
  },
  {
    boatName: "2014 Sea Ray 52'",
    boatAddress: "Test Address",
    boatCity: "Test City",
    renterFirstName: "Vishal",
    rentertLastName: "Mathur",
    approvalStatus: "pending",
    boatOwnerName: "John Doe",
    tripDate: "2021-07-12T00:00:00.000Z",
    checkInDate: "2021-07-12T00:00:00.000Z",
    checkoutDate: "2021-07-12T00:00:00.000Z",
    rentAmount: "1500",
    action: "",
  },
];
const OuterTableCell = withStyles({
  head: {
    borderBottom: "0px",
    fontFamily: "Montserrat",
    fontSize: "0.75rem",
    fontWeight: 600,
    lineHeight: 1.56,
    color: "#8b98a9;",
    backgroundColor: "#f7f9fc",
  },
  body: {
    fontFamily: "Montserrat",
    fontSize: "0.875rem",
    fontWeight: 500,
    lineHeight: 1.21,
    color: "#343a40;",
    cursor: "pointer",
    borderBottom: "solid 1px #eaecef",
  },
})(TableCell);

class OwnerTransaction extends OwnerTransactionController {
  constructor(props: Props) {
    super(props);
  }
  //   handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
  //     this.setState({ selectedBookingType: newValue });
  //   };
    async componentDidMount() {
      const authToken = await StorageProvider.get("authToken");
      if (authToken) {
        this.setState({
          authToken: authToken,
        },()=>{
          this.getTransactions();
        });
      }
    }

  render() {
    const { classes } = this.props;

    return (
      <Container maxWidth="lg">
        <Box my={5}>
          <Typography variant="h4" className={classes.title}>
            Transaction History
          </Typography>
        </Box>
        <Grid container spacing={1} justify="space-between">
          <Grid container item xs={12} md={9} spacing={2}>
            <Grid item xs={12} md={4}>
              <div className={classes.searchField}>
                <div className={classes.searchIcon}>
                  <SearchIcon />
                </div>
                <InputBase
                  placeholder="Search Boat"
                  name="boatName"
                  value={this.state.search_key}
                  onChange={(evt)=>{
                    this.setState({
                      search_key : evt.target.value
                    })
                  }}
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                  inputProps={{ "aria-label": "search" }}
                />
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
            <TextField
                id="date"
                type="date"
                name="start_date"
                  value={this.state.start_date}
                  onChange={(evt)=>{
                    this.setState({
                      start_date : evt.target.value
                    })
                  }}
                label="Start Date"
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
              />
              <TextField
                id="date"
                type="date"
                label="End Date"
                name="end_date"
                  value={this.state.end_date}
                  onChange={(evt)=>{
                    this.setState({
                      end_date : evt.target.value
                    })
                  }}
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
              />
              
            </Grid>
            <Grid item xs={12} md={1}>
              <Button
                color="primary"
                variant="outlined"
                className={classes.searchButton}
                size="large"
                onClick={()=>{this.getTransactions()}}
              >
                Search
              </Button>
            </Grid>
          </Grid>

          <Grid container item xs={12} md={3}>
            <Box
              display="flex"
              justifyContent="flex-end"
              textAlign="end"
              flexDirection="column"
            >
              <Typography variant="h6">Selected Period Income</Typography>
              <Typography color="primary" variant="h5">
                $ {this.state.totalEarning}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Box pt={5} />

        <TableContainer>
          <Table>
            <TableHead className={classes.tableHead}>
              <TableRow>
                <OuterTableCell>Booking Date</OuterTableCell>
                <OuterTableCell>Renter</OuterTableCell>
                <OuterTableCell>Boat Name</OuterTableCell>
                <OuterTableCell>Amount</OuterTableCell>
                <OuterTableCell>Status</OuterTableCell>
                <OuterTableCell align="center">Action</OuterTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.transactionList.length > 0 && this.state.transactionList.map((item, ind) => (
                <TableRow key={item.id}>
                  <OuterTableCell>
                    {moment(item?.attributes?.created_at).format("DD MMM YYYY")}
                  </OuterTableCell>
                  <OuterTableCell>
                    <Avatar className={classes.avatar} />
                    <Typography component="span" >{`${item.attributes?.payer_name} (${
                      item?.attributes?.payee_email
                    })`}</Typography>
                  </OuterTableCell>
                  <OuterTableCell>{item?.attributes?.boat_info?.name || "NA"}</OuterTableCell>
                  <OuterTableCell>{item?.attributes?.currency_code} {item?.attributes.amount}</OuterTableCell>
                  <OuterTableCell>{item?.attributes.status}</OuterTableCell>
                  <OuterTableCell align="center">---</OuterTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    );
  }
}
const OwnerTransactionWithRouter = withRouter(OwnerTransaction);
const OwnerTransactionAlertBox = withAlertBox(OwnerTransactionWithRouter);
const OwnerTransactionLoader = withLoader(OwnerTransactionAlertBox);
const OwnerTransactionToast = withToast(OwnerTransactionLoader);
export default withStyles((theme) =>
  createStyles({
    title: {
      fontFamily: "Montserrat",
      fontSize: "2rem",
      color: "#343a40",
    },
    tableHead: {
      fontFamily: "Montserrat",
      backgroundColor: "#f7f9fc",
    },

    avatar: {
      height: "20px",
      width: "20px",
      margin: "5px",
      display: "inline-block",
      verticalAlign: "middle",
    },
    searchButton: {
      border: "solid 2px #0174cf",
      fontFamily: "Montserrat",
      borderRadius: "5px",
      paddingTop: "26px",
      paddingBottom: "26px",
    },
    searchField: {
      maxWidth: "300px",
      border: "solid 1px #d7e2ec",
      fontFamily: "Montserrat",
      backgroundColor: "rgba(247,249,252,0.5)",
      boxShadow: "2px 2px #e8eaed",
      position: "relative",
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    inputRoot: {
      color: "inherit",
    },
    inputInput: {
      padding: theme.spacing(2, 1, 2, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("md")]: {
        width: "20ch",
      },
    },
  })
)(withDialog(OwnerTransactionToast));
