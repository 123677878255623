//@ts-nocheck
import React, { isValidElement } from "react";
import BoatDetailsPricePageController, {
  Props,
} from "./BoatDetailsPricePageController.web";
import {
  withStyles,
  Grid,
  AppBar,
  Toolbar,
  Typography,
  createStyles,
  Box,
  ListItem,
  List,
  ListItemText,
  Button,
  Switch,
  FormControlLabel,
  TextField,
  InputAdornment,
  Checkbox,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
} from "@material-ui/core";
import InputBox from "./InputBox.web";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import {
  logo,boatDetail,
  captainTransparent,
  therenter,
  tick,
  person,
} from "./assets";
import * as Yup from "yup";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import { withRouter, Route, Redirect } from "react-router";
import { Formik, Form, Field } from "formik";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";

const drawerWidth = 240;
const PageLinks = [
  { displayName: "Support", route: "/faq" },
  { displayName: "Save and exit", route: "saveandexit" },
];


const boatDetailsSchema = Yup.object().shape({
  isRenterOperates: Yup.boolean().required("this field is required"),
  is4hourrenterchecked: Yup.boolean(),
  is4hourrenteramount: Yup.string().when(
    ["isRenterOperates", "is4hourrenterchecked"],
    {
      is: (isRenterOperates, is4hourrenterchecked) =>
        isRenterOperates && is4hourrenterchecked,
      then: Yup.string().required("This field is required"),
    }
  ),
  is8hourrenterchecked: Yup.boolean(),
  is8hourrenteramount: Yup.string().when(
    ["isRenterOperates", "is8hourrenterchecked"],
    {
      is: (isRenterOperates, is8hourrenterchecked) =>
        isRenterOperates && is8hourrenterchecked,
      then: Yup.string().required("This field is required"),
    }
  ),
  is24hourrenterchecked: Yup.boolean(),
  is24hourrenteramount: Yup.string().when(
    ["isRenterOperates", "is24hourrenterchecked"],
    {
      is: (isRenterOperates, is24hourrenterchecked) =>
        isRenterOperates && is24hourrenterchecked,
      then: Yup.string().required("This field is required"),
    }
  ),
  is3daysrenterchecked: Yup.boolean(),
  is3daysrenteramount: Yup.string().when(
    ["isRenterOperates", "is3daysrenterchecked"],
    {
      is: (isRenterOperates, is3daysrenterchecked) =>
        isRenterOperates && is3daysrenterchecked,
      then: Yup.string().required("This field is required"),
    }
  ),
  is7daysrenterchecked: Yup.boolean(),
  is7daysrenteramount: Yup.string().when(
    ["isRenterOperates", "is7daysrenterchecked"],
    {
      is: (isRenterOperates, is7daysrenterchecked) =>
        isRenterOperates && is7daysrenterchecked,
      then: Yup.string().required("This field is required"),
    }
  ),
  renterfuelpolicy: Yup.string(),
  isCaptionOperates: Yup.boolean().required("this field is required"),
  is4hourcaptionchecked: Yup.boolean(),
  is4hourcaptionamount: Yup.string().when(
    ["isCaptionOperates", "is4hourcaptionchecked"],
    {
      is: (isCaptionOperates, is4hourcaptionchecked) =>
        isCaptionOperates && is4hourcaptionchecked,
      then: Yup.string().required("This field is required"),
    }
  ),
  is8hourcaptionchecked: Yup.boolean(),
  is8hourcaptionamount: Yup.string().when(
    ["isCaptionOperates", "is8hourcaptionchecked"],
    {
      is: (isCaptionOperates, is8hourcaptionchecked) =>
        isCaptionOperates && is8hourcaptionchecked,
      then: Yup.string().required("This field is required"),
    }
  ),
  is24hourcaptionchecked: Yup.boolean(),
  is24hourcaptionamount: Yup.string().when(
    ["isCaptionOperates", "is24hourcaptionchecked"],
    {
      is: (isCaptionOperates, is24hourcaptionchecked) =>
        isCaptionOperates && is24hourcaptionchecked,
      then: Yup.string().required("This field is required"),
    }
  ),
  is3dayscaptionchecked: Yup.boolean(),
  is3dayscaptionamount: Yup.string().when(
    ["isCaptionOperates", "is3dayscaptionchecked"],
    {
      is: (isCaptionOperates, is3dayscaptionchecked) =>
        isCaptionOperates && is3dayscaptionchecked,
      then: Yup.string().required("This field is required"),
    }
  ),
  is7dayscaptionchecked: Yup.boolean(),
  is7dayscaptionamount: Yup.string().when(
    ["isCaptionOperates", "is7dayscaptionchecked"],
    {
      is: (isCaptionOperates, is7dayscaptionchecked) =>
        isCaptionOperates && is7dayscaptionchecked,
      then: Yup.string().required("This field is required"),
    }
  ),
  captionfuelpolicy: Yup.string(),
});
const CustomSwitch = withStyles({
  switchBase: {
    color: "#f5f5f5",
    "&$checked": {
      color: "#0174cf",
    },
    "&$checked + $track": {
      backgroundColor: "#0174cf",
    },
  },
  checked: {},
  track: {},
})(Switch);
import withBoatIdComponent from "./withBoatIdComponent.js";

class BoatDetailsPricePage extends BoatDetailsPricePageController {
  constructor(props: Props) {
    super(props);
  }
  async componentDidMount() {
    const authToken = await StorageProvider.get("authToken");
    const boatId = await StorageProvider.get("boatId");
    if (authToken) {
      this.setState({
        authToken: authToken,
        boatId: boatId,
      },
      () => {
        this.getBoatDetail();
      })
    }
  }
  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };
  submitForm = null;
  goToSelectedRoute = (routeName) => {
    if (routeName === "saveandexit") {
      this.setState({
        isSaveAndExit : true
      },()=>{
        this.submitForm && this.submitForm();
      })
    } else {
      const { history } = this.props;
      history.push(routeName);
    }
  };
  
  render() {
    const { classes } = this.props;
    return (
      <>
        <AppBar
          position="static"
          color="default"
          elevation={0}
          className={classes.appBar}
        >
          <Toolbar className={classes.toolbar}>
            <Box className={classes.toolbarLogo}>
              <img onClick={()=>this.props.history.push("/")}  src={logo} style={{width:'22%'}} alt="boaturu" />
            </Box>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              edge="start"
              onClick={this.handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <Hidden xsDown implementation="css">
              <nav>
                {PageLinks.map((link) => {
                  return (
                    <Typography
                      key={link.displayName}
                      variant="button"
                      className={classes.link}
                      onClick={() => {
                        this.goToSelectedRoute(link.route);
                      }}
                    >
                      {link.displayName}
                    </Typography>
                  );
                })}
              </nav>
            </Hidden>
            <Hidden smUp implementation="css">
              <Drawer
                variant="persistent"
                anchor={"right"}
                open={this.state.mobileOpen}
                onClose={this.handleDrawerToggle}
                classes={{
                  paper: classes.drawerPaper,
                }}
                SlideProps={{ unmountOnExit: true }}
              >
                <IconButton
                  onClick={this.handleDrawerToggle}
                  className={classes.closeMenuButton}
                >
                  <CloseIcon />
                </IconButton>
                <List>
      {PageLinks.map((link, index) => (
        <ListItem button key={link.displayName} onClick={() => {
          this.goToSelectedRoute(link.route);
        }}>
          <ListItemText primary={link.displayName} />
        </ListItem>
      ))}
    </List>
              </Drawer>
            </Hidden>
          </Toolbar>
        </AppBar>
        <Grid container className={classes.root}>
          <Grid
            item
            xs={false}
            sm={4}
            md={5}
            container
            className={classes.image}
          >
            <div className={classes.overlay} />
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <div className={classes.imagetxtcontainer}>
                    <img src={tick} />
                    <Typography
                      display="inline"
                      className={classes.leftPageHeading}
                    >
                      Boat details
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography gutterBottom className={classes.pricing}>
                Pricing
              </Typography>
              <div className={classes.headingline} />
              <Typography gutterBottom className={classes.selectOperator}>
                Add your prices
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography gutterBottom className={classes.leftsidelink}>
                Insurance
              </Typography>
              <Typography gutterBottom className={classes.leftsidelink}>
                Safety and standards
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={8} md={7}>
            <Formik
              enableReinitialize
              initialValues={{
                isRenterOperates: this.state.boat_operator === 'renter' ? true : false,
                isCaptionOperates: this.state.boat_operator === 'captain' ? true : false,
                is4hourrenterchecked: this.state.boat_operator === 'renter'  ? true : false,
                is4hourrenteramount: this.state.boat_operator === 'renter' && this.state?.boat_price_detail?.half_day_rate ? this.state?.boat_price_detail?.half_day_rate : "",
                is8hourrenterchecked: this.state.boat_operator === 'renter'  ? true : false,
                is8hourrenteramount: this.state.boat_operator === 'renter' && this.state?.boat_price_detail?.full_day_rate ? this.state?.boat_price_detail?.full_day_rate : "",
                is24hourrenterchecked: this.state.boat_operator === 'renter' ? true : false,
                is24hourrenteramount: this.state.boat_operator === 'renter' && this.state?.boat_price_detail?.twenty_four_hours_rate ? this.state?.boat_price_detail?.twenty_four_hours_rate : "",
                is3daysrenterchecked: this.state.boat_operator === 'renter'  ? true : false,
                is3daysrenteramount: this.state.boat_operator === 'renter' && this.state?.boat_price_detail?.three_days_rate ? this.state?.boat_price_detail?.three_days_rate : "",
                is7daysrenterchecked: this.state.boat_operator === 'renter'  ? true : false,
                is7daysrenteramount: this.state.boat_operator === 'renter' && this.state?.boat_price_detail?.seven_days_rate ? this.state?.boat_price_detail?.seven_days_rate : "",
                renterfuelpolicy: this.state.boat_operator === 'renter' && this.state?.boat_price_detail?.fuel_policy === 'renter' ? "renterPays" : "ownerPays",
                is4hourcaptionchecked: this.state.boat_operator === 'captain'  ? true : false,
                is4hourcaptionamount: this.state.boat_operator === 'captain' && this.state?.boat_price_detail?.half_day_rate ? this.state?.boat_price_detail?.half_day_rate : "",
                is8hourcaptionchecked: this.state.boat_operator === 'captain'  ? true : false,
                is8hourcaptionamount: this.state.boat_operator === 'captain' && this.state?.boat_price_detail?.full_day_rate ? this.state?.boat_price_detail?.full_day_rate : "",
                is24hourcaptionchecked: this.state.boat_operator === 'captain' ? true : false,
                is24hourcaptionamount: this.state.boat_operator === 'captain' && this.state?.boat_price_detail?.twenty_four_hours_rate ? this.state?.boat_price_detail?.twenty_four_hours_rate : "",
                is3dayscaptionchecked: this.state.boat_operator === 'captain'  ? true : false,
                is3dayscaptionamount: this.state.boat_operator === 'captain' && this.state?.boat_price_detail?.three_days_rate ? this.state?.boat_price_detail?.three_days_rate : "",
                is7dayscaptionchecked: this.state.boat_operator === 'captain'  ? true : false,
                is7dayscaptionamount: this.state.boat_operator === 'captain' && this.state?.boat_price_detail?.seven_days_rate ? this.state?.boat_price_detail?.seven_days_rate : "",
                captionfuelpolicy: this.state.boat_operator === 'captain' && this.state?.boat_price_detail?.fuel_policy === 'renter' ? "renterPays" : "ownerPays"
              }}
              validationSchema={boatDetailsSchema}
              onSubmit={(values, actions) => {
                this.updateBoatOpertor(values);
              }}
            >
              {(formikProps) => {
                const {
                  values,
                  handleBlur,
                  handleSubmit,
                  handleChange,
                  setFieldValue,
                  dirty,
                  isSubmitting,
                  isValid,
                  errors,
                  touched

                } = formikProps;
                this.submitForm = handleSubmit;
                return (
                  <Form  autoComplete="off">
                    <Grid
                      container
                      justify="center"
                      style={{ padding: "16px 32px" }}
                      spacing={3}
                    >
                      <Grid item xs={12}>
                        <Box className={classes.backButtonBox} onClick={() => {
                              this.goToSelectedRoute("/boatDetails");
                            }}>
                          <Typography className={classes.backButtonBoxContent}>
                            {"<"}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12} container justify="center">
                        <Grid item>
                          <Typography
                            align="center"
                            gutterBottom
                            className={classes.pageHeading}
                          >
                            You can let your approved renters operate your boat
                          </Typography>
                          <Typography
                            align="center"
                            className={classes.pageSubHeading}
                          >
                            You can also include a Float captain, invite a
                            captain or operate your boat yourself.
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        container
                        style={{ marginTop: "40px" }}
                      >
                        <Grid item xs={12}>
                          <Card className={classes.cardRoot}  variant="outlined">
                            <CardHeader
                              style={{
                                backgroundColor: values.isRenterOperates
                                  ? "#ceddff"
                                  : "#d9d9d9",
                              }}
                              avatar={
                                <img
                                  src={person}
                                  style={{
                                    height: "40px",
                                  }}
                                />
                              }
                              classes={{
                                action: classes.marginTop0,
                                title: classes.cardHeading,
                              }}
                              action={
                                <CustomSwitch
                                  checked={values.isRenterOperates}
                                  onChange={(event) => {
                                    this.setState({
                                      boat_operator : 'renter'
                                    })
                                    handleChange(event);
                                    setFieldValue(
                                      "isCaptionOperates",
                                      !event.target.checked
                                    );
                                  }}
                                  name="isRenterOperates"
                                />
                              }
                              title="Renter operates my boat"
                            />
                            <CardContent>
                              <Typography
                                variant="body2"
                                className={classes.cardContentHeading}
                                style={
                                  values.isRenterOperates
                                    ? { color: "#004277" }
                                    : { color: "#343a40" }
                                }
                              >
                                Set your rates for :
                              </Typography>
                              <Grid
                                container
                                justify="space-between"
                                style={{ marginTop: "8px" }}
                                spacing={2}
                              >
                                <Grid item xs={12} md={6}>
                                  <Grid container justify="space-between">
                                    <Grid item xs={5}>
                                      <FormControlLabel
                                        classes={{
                                          label: classes.checkboxlabel,
                                        }}
                                        control={
                                          <Checkbox
                                            disabled={true}
                                            checked={
                                              values.is4hourrenterchecked
                                            }
                                            onChange={handleChange}
                                            name="is4hourrenterchecked"
                                            color="primary"
                                          />
                                        }
                                        label="4 hours"
                                      />
                                    </Grid>
                                    <Grid item xs={7}>
                                      <Field
                                        component={InputBox}
                                        helperText="Potential earning  $"
                                        type="number"
                                        required
                                        fullWidth
                                        disabled={
                                          !values.is4hourrenterchecked ||
                                          !values.isRenterOperates
                                        }
                                        name="is4hourrenteramount"
                                        InputProps={{
                                          startAdornment: (
                                            <InputAdornment disableTypography className={classes.inputEndorment}   position="start">
                                              $
                                            </InputAdornment>
                                          ),
                                        }}
                                      />
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                  <Grid container justify="space-between">
                                    <Grid item xs={5}>
                                      <FormControlLabel
                                        classes={{
                                          label: classes.checkboxlabel,
                                        }}
                                        control={
                                          <Checkbox
                                            disabled={true}
                                            checked={
                                              values.is3daysrenterchecked
                                            }
                                            onChange={handleChange}
                                            name="is3daysrenterchecked"
                                            color="primary"
                                          />
                                        }
                                        label="3 Days"
                                      />
                                    </Grid>
                                    <Grid item xs={7}>
                                      <Field
                                        component={InputBox}
                                        helperText="Potential earning  $"
                                        type="number"
                                        disabled={
                                          !values.is3daysrenterchecked ||
                                          !values.isRenterOperates
                                        }
                                        required
                                        fullWidth
                                        name="is3daysrenteramount"
                                        InputProps={{
                                          startAdornment: (
                                            <InputAdornment disableTypography className={classes.inputEndorment} position="start">
                                              $
                                            </InputAdornment>
                                          ),
                                        }}
                                      />
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                  <Grid container justify="space-between">
                                    <Grid item xs={5}>
                                      <FormControlLabel
                                        classes={{
                                          label: classes.checkboxlabel,
                                        }}
                                        control={
                                          <Checkbox
                                            disabled={true}
                                            checked={
                                              values.is8hourrenterchecked
                                            }
                                            onChange={handleChange}
                                            name="is8hourrenterchecked"
                                            color="primary"
                                          />
                                        }
                                        label="8 hours"
                                      />
                                    </Grid>
                                    <Grid item xs={7}>
                                      <Field
                                        component={InputBox}
                                        helperText="Potential earning  $"
                                        type="number"
                                        disabled={
                                          !values.is8hourrenterchecked ||
                                          !values.isRenterOperates
                                        }
                                        required
                                        fullWidth
                                        name="is8hourrenteramount"
                                        InputProps={{
                                          startAdornment: (
                                            <InputAdornment disableTypography className={classes.inputEndorment} position="start">
                                              $
                                            </InputAdornment>
                                          ),
                                        }}
                                      />
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                  <Grid container justify="space-between">
                                    <Grid item xs={5}>
                                      <FormControlLabel
                                        classes={{
                                          label: classes.checkboxlabel,
                                        }}
                                        control={
                                          <Checkbox
                                            disabled={true}
                                            checked={
                                              values.is7daysrenterchecked
                                            }
                                            onChange={handleChange}
                                            name="is7daysrenterchecked"
                                            color="primary"
                                          />
                                        }
                                        label="7 Days"
                                      />
                                    </Grid>
                                    <Grid item xs={7}>
                                      <Field
                                        component={InputBox}
                                        helperText="Potential earning  $"
                                        type="number"
                                        required
                                        disabled={
                                          !values.is7daysrenterchecked ||
                                          !values.isRenterOperates
                                        }
                                        fullWidth
                                        name="is7daysrenteramount"
                                        InputProps={{
                                          startAdornment: (
                                            <InputAdornment disableTypography className={classes.inputEndorment} position="start">
                                              $
                                            </InputAdornment>
                                          ),
                                        }}
                                      />
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                  <Grid container justify="space-between">
                                    <Grid item xs={5}>
                                      <FormControlLabel
                                        classes={{
                                          label: classes.checkboxlabel,
                                        }}
                                        control={
                                          <Checkbox
                                            disabled={true}
                                            checked={
                                              values.is24hourrenterchecked
                                            }
                                            onChange={handleChange}
                                            name="is24hourrenterchecked"
                                            color="primary"
                                          />
                                        }
                                        label="24 Hours"
                                      />
                                    </Grid>
                                    <Grid item xs={7}>
                                      <Field
                                        component={InputBox}
                                        helperText="Potential earning  $"
                                        type="number"
                                        required
                                        disabled={
                                          !values.is24hourrenterchecked ||
                                          !values.isRenterOperates
                                        }
                                        fullWidth
                                        name="is24hourrenteramount"
                                        InputProps={{
                                          startAdornment: (
                                            <InputAdornment disableTypography className={classes.inputEndorment} position="start">
                                              $
                                            </InputAdornment>
                                          ),
                                        }}
                                      />
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                  <FormControl component="fieldset">
                                    <FormLabel
                                      className={classes.radioGroupTitle}
                                      component="legend"
                                      style={
                                        values.isRenterOperates
                                          ? { color: "#004277" }
                                          : { color: "#343a40" }
                                      }
                                    >
                                      Fuel Policy
                                    </FormLabel>
                                    <RadioGroup
                                      row
                                      aria-label="position"
                                      name="renterfuelpolicy"
                                      onChange={handleChange}
                                      defaultValue={values.renterfuelpolicy}
                                    >
                                      <FormControlLabel
                                        classes={{
                                          label: classes.radioButtonLabel,
                                        }}
                                        value="renterPays"
                                        control={
                                          <Radio
                                            disabled={!values.isRenterOperates}
                                            color="primary"
                                          />
                                        }
                                        label="Renter Pays"
                                      />
                                      <FormControlLabel
                                        classes={{
                                          label: classes.radioButtonLabel,
                                        }}
                                        value="ownerPays"
                                        control={
                                          <Radio
                                          disabled={!values.isRenterOperates}
                                            color="primary"
                                          />
                                        }
                                        label="Owner Pays"
                                      />
                                    </RadioGroup>
                                  </FormControl>
                                </Grid>
                              </Grid>
                            </CardContent>
                          </Card>
                        </Grid>
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        container
                        style={{ marginTop: "40px" }}
                      >
                        <Grid item xs={12}>
                          <Card className={classes.cardRoot} variant="outlined">
                            <CardHeader
                              style={{
                                backgroundColor: values.isCaptionOperates
                                  ? "#ceddff"
                                  : "#d9d9d9",
                              }}
                              avatar={
                                <img
                                  src={captainTransparent}
                                  style={{
                                    height: "40px",
                                  }}
                                />
                              }
                              classes={{
                                action: classes.marginTop0,
                                title: classes.cardHeading,
                              }}
                              action={
                                <CustomSwitch
                                  checked={values.isCaptionOperates}
                                  onChange={(event) => {
                                    this.setState({
                                      boat_operator : 'captain'
                                    })
                                    handleChange(event);
                                    setFieldValue(
                                      "isRenterOperates",
                                      !event.target.checked
                                    );
                                    
                                  }}
                                  name="isCaptionOperates"
                                />
                              }
                              title="Certified captain operates my boat"
                            />
                            <CardContent>
                              <Typography
                                variant="body2"
                                className={classes.cardContentHeading}
                                style={
                                  values.isCaptionOperates
                                    ? { color: "#004277" }
                                    : { color: "#343a40" }
                                }
                              >
                                Set your rates for :
                              </Typography>
                              <Grid
                                container
                                justify="space-between"
                                style={{ marginTop: "8px" }}
                                spacing={2}
                              >
                                <Grid item xs={12} md={6}>
                                  <Grid container justify="space-between">
                                    <Grid item xs={5}>
                                      <FormControlLabel
                                        classes={{
                                          label: classes.checkboxlabel,
                                        }}
                                        control={
                                          <Checkbox
                                            disabled={true}
                                            checked={
                                              values.is4hourcaptionchecked
                                            }
                                            onChange={handleChange}
                                            name="is4hourcaptionchecked"
                                            color="primary"
                                          />
                                        }
                                        label="4 hours"
                                      />
                                    </Grid>
                                    <Grid item xs={7}>
                                      <Field
                                        component={InputBox}
                                        helperText="Potential earning  $"
                                        type="number"
                                        required
                                        fullWidth
                                        disabled={
                                          !values.is4hourcaptionchecked ||
                                          !values.isCaptionOperates
                                        }
                                        name="is4hourcaptionamount"
                                        InputProps={{
                                          startAdornment: (
                                            <InputAdornment disableTypography className={classes.inputEndorment}
                                              variant="filled"
                                              position="start"
                                            >
                                              $
                                            </InputAdornment>
                                          ),
                                        }}
                                      />
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                  <Grid container justify="space-between">
                                    <Grid item xs={5}>
                                      <FormControlLabel
                                        classes={{
                                          label: classes.checkboxlabel,
                                        }}
                                        control={
                                          <Checkbox
                                            disabled={true}
                                            checked={
                                              values.is3dayscaptionchecked
                                            }
                                            onChange={handleChange}
                                            name="is3dayscaptionchecked"
                                            color="primary"
                                          />
                                        }
                                        label="3 Days"
                                      />
                                    </Grid>
                                    <Grid item xs={7}>
                                      <Field
                                        component={InputBox}
                                        helperText="Potential earning  $"
                                        type="number"
                                        disabled={
                                          !values.is3dayscaptionchecked ||
                                          !values.isCaptionOperates
                                        }
                                        required
                                        fullWidth
                                        name="is3dayscaptionamount"
                                        InputProps={{
                                          startAdornment: (
                                            <InputAdornment disableTypography className={classes.inputEndorment} position="start">
                                              $
                                            </InputAdornment>
                                          ),
                                        }}
                                      />
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                  <Grid container justify="space-between">
                                    <Grid item xs={5}>
                                      <FormControlLabel
                                        classes={{
                                          label: classes.checkboxlabel,
                                        }}
                                        control={
                                          <Checkbox
                                            disabled={true}
                                            checked={
                                              values.is8hourcaptionchecked
                                            }
                                            onChange={handleChange}
                                            name="is8hourcaptionchecked"
                                            color="primary"
                                          />
                                        }
                                        label="8 hours"
                                      />
                                    </Grid>
                                    <Grid item xs={7}>
                                      <Field
                                        component={InputBox}
                                        helperText="Potential earning  $"
                                        type="number"
                                        disabled={
                                          !values.is8hourcaptionchecked ||
                                          !values.isCaptionOperates
                                        }
                                        required
                                        fullWidth
                                        name="is8hourcaptionamount"
                                        InputProps={{
                                          startAdornment: (
                                            <InputAdornment disableTypography className={classes.inputEndorment} position="start">
                                              $
                                            </InputAdornment>
                                          ),
                                        }}
                                      />
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                  <Grid container justify="space-between">
                                    <Grid item xs={5}>
                                      <FormControlLabel
                                        classes={{
                                          label: classes.checkboxlabel,
                                        }}
                                        control={
                                          <Checkbox
                                            disabled={true}
                                            checked={
                                              values.is7dayscaptionchecked
                                            }
                                            onChange={handleChange}
                                            name="is7dayscaptionchecked"
                                            color="primary"
                                          />
                                        }
                                        label="7 Days"
                                      />
                                    </Grid>
                                    <Grid item xs={7}>
                                      <Field
                                        component={InputBox}
                                        helperText="Potential earning  $"
                                        type="number"
                                        required
                                        disabled={
                                          !values.is7dayscaptionchecked ||
                                          !values.isCaptionOperates
                                        }
                                        fullWidth
                                        name="is7dayscaptionamount"
                                        InputProps={{
                                          startAdornment: (
                                            <InputAdornment disableTypography className={classes.inputEndorment} position="start">
                                              $
                                            </InputAdornment>
                                          ),
                                        }}
                                      />
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                  <Grid container justify="space-between">
                                    <Grid item xs={5}>
                                      <FormControlLabel
                                        classes={{
                                          label: classes.checkboxlabel,
                                        }}
                                        control={
                                          <Checkbox
                                            disabled={true}
                                            checked={
                                              values.is24hourcaptionchecked
                                            }
                                            onChange={handleChange}
                                            name="is24hourcaptionchecked"
                                            color="primary"
                                          />
                                        }
                                        label="24 Hours"
                                      />
                                    </Grid>
                                    <Grid item xs={7}>
                                      <Field
                                        component={InputBox}
                                        helperText="Potential earning  $"
                                        type="number"
                                        required
                                        disabled={
                                          !values.is24hourcaptionchecked ||
                                          !values.isCaptionOperates
                                        }
                                        fullWidth
                                        name="is24hourcaptionamount"
                                        InputProps={{
                                          startAdornment: (
                                            <InputAdornment disableTypography className={classes.inputEndorment} position="start">
                                              $
                                            </InputAdornment>
                                          ),
                                        }}
                                      />
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                  <FormControl component="fieldset">
                                    <FormLabel
                                      className={classes.radioGroupTitle}
                                      component="legend"
                                      style={
                                        values.isCaptionOperates
                                          ? { color: "#004277" }
                                          : { color: "#343a40" }
                                      }
                                    >
                                      Fuel Policy
                                    </FormLabel>
                                    <RadioGroup
                                      row
                                      aria-label="position"
                                      name="renterfuelpolicy"
                                      onChange={handleChange}
                                      defaultValue={values.renterfuelpolicy}
                                    >
                                      <FormControlLabel
                                        classes={{
                                          label: classes.radioButtonLabel,
                                        }}
                                        value="renterPays"
                                        control={
                                          <Radio
                                            disabled={!values.isCaptionOperates}
                                            color="primary"
                                          />
                                        }
                                        label="Renter Pays"
                                      />
                                      <FormControlLabel
                                        classes={{
                                          label: classes.radioButtonLabel,
                                        }}
                                        value="ownerPays"
                                        control={
                                          <Radio
                                          disabled={!values.isCaptionOperates}
                                            color="primary"
                                          />
                                        }
                                        label="Owner Pays"
                                      />
                                    </RadioGroup>
                                  </FormControl>
                                </Grid>
                              </Grid>
                            </CardContent>
                          </Card>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        justify={"flex-end"}
                        item
                        xs={12}
                        style={{ marginTop: "75px" }}
                      >
                        <Grid item>
                          <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={!isValid}
                          >
                            Proceed
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Form>
                );
              }}
            </Formik>
          </Grid>
        </Grid>
      </>
    );
  }
}
const BoatDetailsPricePageWithRouter = withRouter(BoatDetailsPricePage);
const BoatDetailsPricePageAlertBox = withAlertBox(
  BoatDetailsPricePageWithRouter
);
const BoatDetailsPricePageLoader = withLoader(BoatDetailsPricePageAlertBox);
const BoatDetailsPricePageToast = withToast(BoatDetailsPricePageLoader);

export default withStyles((theme) =>
  createStyles({
    root: {
      height: "calc(100vh - 70px)",
      overflowX : 'hidden'
    },
    image: {
      position: "relative",
      backgroundImage: `url(${boatDetail})`,
      backgroundRepeat: "no-repeat",
      backgroundColor:
        theme.palette.type === "light"
          ? theme.palette.grey[50]
          : theme.palette.grey[900],
      backgroundSize: "cover",
      backgroundPosition: "center",
      padding: "120px 68px",
      height: "calc(100vh - 50px)",
    },
    appBar: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      width: "100%",
      boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
      backgroundColor: "#337eb0",
      height: theme.spacing(8.75),
    },
    toolbar: {
      flexWrap: "nowrap",
    },
    toolbarLogo: {
      flexGrow: 1,
    },
    link: {
      margin: theme.spacing(1, 2.5),
      fontFamily: "Roboto",
      fontSize: "14px",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.14,
      letterSpacing: "normal",
      textTransform: "none",
      cursor: "pointer",
      color: "#fff",
    },
    margin: {
      margin: theme.spacing(1),
    },
    drawer: {
      [theme.breakpoints.up("sm")]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },
    drawerPaper: {
      width: drawerWidth,
    },
    closeMenuButton: {
      marginRight: 0,
      marginLeft: "auto",
    },
    marginTop: {
      marginTop: theme.spacing(4),
    },
    backButtonBox: {
      width: "50px",
      height: "50px",
      borderRadius: "5px",
      border: "solid 2px #0174cf",
      backgroundColor: "#ffffff",
      textAlign: "center",
      cursor : 'pointer'
    },
    backButtonBoxContent: {
      color: "#0174cf",
      fontFamily: "Montserrat",
      fontSize: "20px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "50px",
    },
    renterTypeBox: {
      height: "150px",
      borderRadius: "10px",
      border: "solid 1px #004277",
      backgroundColor: "#ffffff",
      cursor: "pointer",
      textAlign: "center",
    },
    renterTypeImg: {
      width: "100%",
      height: "100%",
    },
    pageHeading: {
      fontFamily: "Montserrat",
      fontSize: "20px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.21,
      letterSpacing: "normal",
      textAlign: "center",
      color: "#004277",
    },
    pageSubHeading: {
      fontFamily: "Montserrat",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "normal",
      textAlign: "center",
      color: "#343a40",
    },
    rightRoot: {
      justifyContent: "center",
      display: "flex",
    },
    overlay: {
      position: "absolute",
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
    },
    mainPostContent: {
      position: "relative",
      padding: theme.spacing(3),
      color: "#FFF",
      [theme.breakpoints.up("md")]: {
        padding: theme.spacing(6),
        paddingRight: 0,
      },
    },
    imagetxtcontainer : {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row' 
    },
    leftPageHeading: {
      fontFamily: "Montserrat",
      fontSize: "20px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.21,
      letterSpacing: "normal",
      color: "#ffffff",
      paddingLeft : '8px' 

    },
    pricing: {
      fontFamily: "Montserrat",
      fontSize: "32px",
      fontWeight: "600",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.22,
      letterSpacing: "normal",
      color: "#ffffff",
    },
    selectOperator: {
      fontFamily: "Montserrat",
      fontSize: "40px",
      fontWeight: "600",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.22,
      letterSpacing: "normal",
      color: "#ffffff",
    },
    leftsidelink: {
      fontFamily: "Montserrat",
      fontSize: "20px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.21,
      letterSpacing: "normal",
      color: "#004277",
    },
    headingline: {
      height: 0,
      width: "33%",
      border: "solid 1px #fff",
    },
    cardHeaderTitle: {
      fontFamily: "Montserrat",
      fontSize: "20px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.2,
      letterSpacing: "normal",
      color: "#343a40",
    },
    chechboxLabel: {
      fontFamily: "Montserrat",
      fontSize: "24px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.21,
      letterSpacing: "normal",
      color: "#343a40",
    },
    inputEndorment: {
      fontFamily: "Montserrat",
      fontSize: "20px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.21,
      letterSpacing: "normal",
      color: "#004277",
    },
    helperText: {
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "normal",
      color: "#000000",
    },
    marginTop0: {
      marginTop: "0px",
    },
    checkboxlabel: {
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.21,
      letterSpacing: "normal",
      color: "#004277",
    },
    radioButtonLabel: {
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "normal",
      color: "#000000",
    },
    cardHeading: {
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.2,
      letterSpacing: "normal",
      color: "#004277",
    },
    cardContentHeading: {
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.2,
      letterSpacing: "normal",
      color: "#004277",
    },
    radioGroupTitle: {
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.2,
      letterSpacing: "normal",
      color: "#004277",
    },
  })
)(withBoatIdComponent(BoatDetailsPricePageToast));
