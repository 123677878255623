//@ts-nocheck
import React from "react";
import EmailAccountVerifyAndUploadPictureController, {
  Props,
} from "./EmailAccountVerifyAndUploadPictureController.web";
import {
  withStyles,
  Grid,
  Typography,
  createStyles,
  Button,
  AppBar,
  Toolbar,
  Box,
  IconButton,
  Hidden,
  Drawer,
  ListItem,
  List,
  ListItemText,
} from "@material-ui/core";
import Footer from "./footer.web";
import { KeyboardDatePicker,MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { arrow, logo, x1, x2, x3, x4, x5, x6, x7, x8, x9, x10 } from "./assets";
import { withRouter, Redirect } from "react-router";
import withDialogBox from "../../../components/src/withDialog.web";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import AddSharpIcon from '@material-ui/icons/AddSharp';

const drawerWidth = 240;
const PageLinks = [
  { displayName: "Login", route: "/basicAuth/login" },
  { displayName: "Signup", route: "/basicAuth/signup" },
  { displayName: "How it works", route: "/howItWorks" },
  { displayName: "Support", route: "/faq" },
];

class EmailAccountVerifyAndUploadPicture extends EmailAccountVerifyAndUploadPictureController {
  constructor(props: Props) {
    super(props);
  }
  fileInputRef = React.createRef();
  async componentDidMount() {
    let verificationToken = new URLSearchParams(this.props.location.search).get(
      "token"
    );
    if (verificationToken) {
      this.setState(
        {
          verificationToken: verificationToken,
        },
        () => {
          this.verifyAccount();
        }
      );
    }
    const signupToken = await StorageProvider.get("signupToken");
    const signupEmail = await StorageProvider.get("signupEmail");
    if (signupEmail && signupToken) {
      this.setState({
        email: signupEmail,
        signupToken: signupToken,
        loading: false,
      });
    }
  }
  dataUrlToFile = async (dataUrl,fileName) => {
    const res = await fetch(dataUrl);
    const blob = await res.blob();
    return new File([blob], fileName, { type: 'image/jpeg' });
  }
  selectAvatar = (base64,name) => {
    this.setState({selectAvatarName : name},()=>{
      this.dataUrlToFile(base64,"avatar.jpg")
      .then((result)=>{
          this.setState({selectedAvatar : result,selectedFilePriview : null})
      })
    })
  }
  
  fileInputClicked() {
    this.fileInputRef?.current?.click();
  }

  filesSelected() {
    if (this.fileInputRef?.current?.files?.length) {
        this.handleFiles(this.fileInputRef?.current?.files);
    }
  }

   handleFiles = async (files) => {
    const filePathsPromises = [];
    [...files].forEach(file => {
      filePathsPromises.push(this.toBase64(file));
    });
    const filePaths = await Promise.all(filePathsPromises);
    this.setState({
      selectedFilePriview : filePaths[0],
      selectedAvatar : null,
      selectAvatarName : ""
    })
  }
  toBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve({base64 : reader.result,file : file,id : new Date().getTime()});
      reader.onerror = error => reject(error);
    });
  };
  removeUploadedImage = () => {
    this.setState({
      selectedFilePriview : null
    })
  }
  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };
  
  render() {
    const { classes } = this.props;
    return !this.state.loading ? (
      this.state.signupToken === null ? (
        <Redirect to="/basicAuth/signup" />
      ) : (
        <>
          <AppBar
            position="static"
            color="default"
            elevation={0}
            className={classes.appBar}
          >
            <Toolbar className={classes.toolbar}>
              <Box className={classes.toolbarLogo}>
                <img onClick={()=>this.props.history.push("/")}  src={logo} style={{width:'40%'}} alt="boaturu" />
              </Box>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                edge="start"
                onClick={this.handleDrawerToggle}
                className={classes.menuButton}
              >
                <MenuIcon />
              </IconButton>
              <Hidden xsDown implementation="css">
                <nav>
                  {PageLinks.map((link) => {
                    return (
                      <Typography
                        key={link.displayName}
                        variant="button"
                        color="secondary"
                        className={classes.link}
                        onClick={() => {
                          this.goToSelectedRoute(link.route);
                        }}
                      >
                        {link.displayName}
                      </Typography>
                    );
                  })}
                </nav>
              </Hidden>
              <Hidden smUp implementation="css">
                <Drawer
                  variant="persistent"
                  anchor={"right"}
                  open={this.state.mobileOpen}
                  onClose={this.handleDrawerToggle}
                  classes={{
                    paper: classes.drawerPaper,
                  }}
                  SlideProps={{ unmountOnExit: true }}
                >
                  <IconButton
                    onClick={this.handleDrawerToggle}
                    className={classes.closeMenuButton}
                  >
                    <CloseIcon />
                  </IconButton>
                  <div>
                    <List>
                      {PageLinks.map((link, index) => (
                        <ListItem button key={link.displayName} onClick={() => {
                          this.goToSelectedRoute(link.route);
                        }}>
                          <ListItemText 
                            className={classes.link}
                            primary={link.displayName} 
                          />
                        </ListItem>
                      ))}
                    </List>
                  </div>
                </Drawer>
              </Hidden>
            </Toolbar>
          </AppBar>
          <Grid
            container
            justify="center"
            style={{ paddingTop: "20px" }}
          >
            <Grid md={5} xs={12} item container spacing={3}>
            <Grid item xs={12} container spacing={1}>
              <Grid xs={12} item>
                <Typography
                  className={classes.verifyAccountHeading}
                  gutterBottom
                >
                  Please verify your account
                </Typography>
                <Typography className={classes.verifyAccountSubHeading}>
                  In order to rent or list a boat on Float, we need to verify
                  your account
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} container spacing={1} justify="center">
              <Grid item xs={12}>
                <div style={{ height: 0, border: "solid 1px #0174cf" }} />
              </Grid>
            </Grid>
            <Grid item xs={12} container spacing={1} justify="space-between">
              <Grid xs={12} item>
                <Typography className={classes.sectionHeading} gutterBottom>
                  1. Please provide your photo to identify (Optional)
                </Typography>
              </Grid>
              <Grid item xs={5} container
                justify="flex-start"
                spacing={0}
                alignItems="center">
                    <Grid
                  item
                  container
                  justify="space-between"
                  alignItems="center"
                  xs={12}
                >
                {this.state.selectedFilePriview ? <div  style={{ position: "relative" }}>
                              <CloseIcon
                                onClick={() => {
                                   this.removeUploadedImage();
                                }}
                                className={classes.crossOverImage}
                              />
                              <img
                                src={this.state.selectedFilePriview.base64}
                                style={{
                                  height: "100%",
                                  width: "100%",
                                  borderRadius: "6px",
                                }}
                              />
                            </div> : <>
                  <div
                    className={classes.DropContainer}
                    onClick={this.fileInputClicked.bind(this)}
                  >
                    <div className={classes.dropMessage}>
                      <AddSharpIcon />
                      <div className={classes.marginTop2}> {this.props.uploadMsg ? this.props.uploadMsg : ''}</div>
                    </div>
                  </div>
                  <input
                    ref={this.fileInputRef}
                    className={classes.fileInput}
                    type="file"
                    accept={"image/*"}
                    multiple={false}
                    onChange={this.filesSelected.bind(this)}
                  />
                </>
                }  
                </Grid>
                <Grid
                  item
                  container
                  justify="space-between"
                  alignItems="center"
                  xs={12}
                >
                  <Grid item xs={12}>
                    <Typography align="center" className={classes.imgHeading}>Add Photo</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid xs={2} style={{ alignSelf: "center" }}>
                <Typography align="center"> Or</Typography>
              </Grid>
              <Grid
                item
                xs={5}
                container
                justify="flex-start"
                spacing={0}
                alignItems="center"
              >
                <Grid
                  item
                  container
                  justify="space-between"
                  alignItems="center"
                  xs={12}
                >
                  <Grid item xs={2}>
                    <img className={this.state.selectAvatarName === 'x1' && classes.selectedAvatar} src={x2} onClick={()=>{this.selectAvatar(x1,"x1")}}/>
                  </Grid>
                  <Grid item xs={2}>
                    <img className={this.state.selectAvatarName === 'x2' && classes.selectedAvatar} src={x2} onClick={()=>{this.selectAvatar(x2,"x2")}}/>
                  </Grid>
                  <Grid item xs={2}>
                    <img className={this.state.selectAvatarName === 'x3' && classes.selectedAvatar} src={x3} onClick={()=>{this.selectAvatar(x3,"x3")}}/>
                  </Grid>
                  <Grid item xs={2}>
                    <img className={this.state.selectAvatarName === 'x4' && classes.selectedAvatar} src={x4} onClick={()=>{this.selectAvatar(x4,"x4")}}/>
                  </Grid>
                  <Grid item xs={2}>
                    <img className={this.state.selectAvatarName === 'x5' && classes.selectedAvatar} src={x5} onClick={()=>{this.selectAvatar(x5,"x5")}}/>
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  justify="space-between"
                  alignItems="center"
                  xs={12}
                >
                  <Grid item xs={2}>
                    <img className={this.state.selectAvatarName === 'x6' && classes.selectedAvatar} src={x6} onClick={()=>{this.selectAvatar(x6,"x6")}}/>
                  </Grid>
                  <Grid item xs={2}>
                    <img className={this.state.selectAvatarName === 'x7' && classes.selectedAvatar} src={x7} onClick={()=>{this.selectAvatar(x7,"x7")}}/>
                  </Grid>
                  <Grid item xs={2}>
                    <img className={this.state.selectAvatarName === 'x8' && classes.selectedAvatar} src={x8} onClick={()=>{this.selectAvatar(x8,"x8")}}/>
                  </Grid>
                  <Grid item xs={2}>
                    <img className={this.state.selectAvatarName === 'x9' && classes.selectedAvatar} src={x9} onClick={()=>{this.selectAvatar(x9,"x9")}}/>
                  </Grid>
                  <Grid item xs={2}>
                    <img className={this.state.selectAvatarName === 'x10' && classes.selectedAvatar} src={x10} onClick={()=>{this.selectAvatar(x10,"x10")}}/>
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  justify="space-between"
                  alignItems="center"
                  xs={12}
                >
                  <Grid item xs={12}>
                    <Typography align="center" className={classes.imgHeading}>Select your avatar</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} container spacing={1} justify="space-between">
              <Grid xs={12} item>
                <Typography className={classes.sectionHeading} gutterBottom>
                  2. Select your date of birth
                </Typography>
              </Grid>
              <Grid xs={6} item>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                id="date-picker-inline"
                label="Choose a date"
                value={this.state.selectedDate}
                onChange={(evt)=>{this.setState({selectedDate : evt})}}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
              </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
            <Grid item xs={12} container spacing={1} justify="center">
              <Grid xs={8} item>
                <Button color="primary" variant="contained" style={{width : '350px'}}  onClick={()=>{this.uploadProfilePictureAndDate()}}>
                  Proceed
                </Button>
              </Grid>
            </Grid>
           </Grid>
          </Grid>
          <Footer></Footer>  
             </>
      )
    ) : null;
  }
}
const EmailAccountVerifyAndUploadPictureWithRouter = withRouter(
  EmailAccountVerifyAndUploadPicture
);
const EmailAccountVerifyAndUploadPictureWithDialogBox = withDialogBox(
  EmailAccountVerifyAndUploadPictureWithRouter
);
const EmailAccountVerifyAndUploadPictureAlertBox = withAlertBox(
  EmailAccountVerifyAndUploadPictureWithDialogBox
);
const EmailAccountVerifyAndUploadPictureLoader = withLoader(
  EmailAccountVerifyAndUploadPictureAlertBox
);
const EmailAccountVerifyAndUploadPictureToast = withToast(
  EmailAccountVerifyAndUploadPictureLoader
);
export default withStyles((theme) =>
  createStyles({
    paper: {
      margin: "48px auto",
    },
    pageHeading: {
      fontFamily: "Montserrat",
      fontSize: "20px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.15,
      letterSpacing: "normal",
      textTransform: "none",
      color: "#1b4050",
    },
    pageSubheading: {
      fontFamily: "Montserrat",
      fontSize: "15px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.2,
      letterSpacing: "0.08px",
      textTransform: "none",
      color: "#354f6c",
    },
    googleLoginButton: {
      borderRadius: "3px",
      border: "solid 2px #d44638",
      backgroundColor: "#ffffff",
      height: "40px",
    },
    googleLoginButtonLable: {
      fontFamily: "Roboto",
      fontSize: "13px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.15,
      letterSpacing: "0.13px",
      textTransform: "none",
      color: "#d44638",
      [theme.breakpoints.down("xs")]: {
        letterSpacing: "normal",
        fontSize: "10px",
      },
    },
    facebookButton: {
      marginLeft: "30px",
      [theme.breakpoints.down("xs")]: {
        marginLeft: "8px",
      },
    },
    facebookLoginButton: {
      borderRadius: "3px",
      border: "solid 2px #4267b2",
      backgroundColor: "#ffffff",
      height: "40px",
    },
    facebookLoginButtonLable: {
      fontFamily: "Roboto",
      fontSize: "13px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.15,
      letterSpacing: "0.13px",
      textTransform: "none",
      color: "#4267b2",
      [theme.breakpoints.down("xs")]: {
        letterSpacing: "normal",
        fontSize: "10px",
      },
    },
    forgetPassword: {
      marginLeft: theme.spacing(4),
    },
    donttext: {
      fontFamily: "Roboto",
      fontSize: "14px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.14,
      letterSpacing: "0.42px",
      textTransform: "none",
      color: "#1b4050",
    },
    marginTop1: {
      marginTop: "30px",
    },
    marginTop2: {
      marginTop: "50px",
    },
    appBar: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      width: "100%",
      boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
      backgroundColor: "#ffffff",
      height: theme.spacing(7.5),
    },
    toolbar: {
      flexWrap: "nowrap",
    },
    toolbarLogo: {
      flexGrow: 1,
    },
    link: {
      margin: theme.spacing(1, 2.5),
      fontFamily: "Roboto",
      fontSize: "14px",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.14,
      letterSpacing: "normal",
      textTransform: "none",
      color : '#545454',
      cursor: "pointer",
      opacity: 0.6
    },
    verifyAccountHeading: {
      fontFamily: "Montserrat",
      fontSize: "24px",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.19,
      letterSpacing: "normal",
      color: "#183b56",
    },
    verifyAccountSubHeading: {
      fontFamily: "Roboto",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.19,
      letterSpacing: "normal",
      color: "#545454",
    },
    sectionHeading: {
      fontFamily: "Roboto",
      fontSize: "16px",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.15,
      letterSpacing: "normal",
      color: "#545454",
    },

    drawer: {
      [theme.breakpoints.up("sm")]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },
    drawerPaper: {
      width: drawerWidth,
    },
    closeMenuButton: {
      marginRight: 0,
      marginLeft: "auto",
    },
    marginTop: {
      marginTop: theme.spacing(4),
    },
    DropContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: "auto",
        width: "50%",
        height: "100px",
        border:"dashed 1px #707070",
        backgroundColor : '#f1f1f1',
        flexDirection:"column",
        borderRadius:'6px',
        padding:'20px 10px'
      },
      uploadIcon: {
        // width: '25px',
        // height: '30px',
        // objectFit: 'contain',
        // background: `url(${x2}) no-repeat center center`,
        // backgroundSize: "100%",
        textAlign: "center",
        margin: "0 auto",
        paddingTop: "30px",
      },
      dropMessage: {
        fontSize: '16px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.25,
        letterSpacing: 'normal',
        textAlign: 'center',
        color: "#343a40",
        fontFamily: "Montserrat",
        marginTop : '16px'
      },
      marginTop2 : {
        marginTop : theme.spacing(2)
      },
      fileInput : {
          display: 'none'
      },
      imgHeading : {
        fontFamily: "Roboto",
        fontSize: "12px",
        fontWeight: 'normal',
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1.19,
        letterSpacing: "normal",
        color: "#343a40",
      },
      crossOverImage: {
        right: "0px",
        top: "-2px",
        position: "absolute",
        backgroundColor: "#fff",
        width: "16px",
        height: "16px",
        borderRadius: "50%",
      },
      selectedAvatar : {
        border: 'solid 2px #0174cf',
        borderRadius: '50%'
      },
      footerLinkHeading: {
        fontFamily: "Roboto",
        fontSize: "16px",
        fontWeight: "bold",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1.15,
        letterSpacing: "normal",
        color: "#545454",
      },
      footerLink: {
        fontFamily: "Roboto",
        fontSize: "16px",
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1.15,
        letterSpacing: "normal",
        color: "#545454",
      },
      footer: {
        padding: theme.spacing(3, 2),
        marginTop: "120px",
        backgroundColor: "#f6f6f6",
      },
  })
)(EmailAccountVerifyAndUploadPictureToast);
