//@ts-nocheck
import React from "react";
import OwnerDashboardController, {
  Props,
} from "./OwnerDashboradController.web";
import {
  withStyles,
  Grid,
  AppBar,
  Toolbar,
  Typography,
  createStyles,
  Box,
  ListItem,
  List,
  ListItemText,
  ListItemAvatar,
  ListItemIcon,
  Radio,
  Button,
  Avatar,
} from "@material-ui/core";
import  PrivateRoute  from "../../../components/src/PrivateRoute.web";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import { logo,boatDetail } from "./assets";
import { withRouter, Route, Switch, Redirect } from "react-router";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import OwnerBoatList from "./OwnerBoatList.web";
import OwnerOverview from "./OwnerOverview.web";
import CustomerInbox from "./CustomerInbox.web";
import BoatBookingHistory from "./BoatBookingHistory.web";
import OwnerBookings from "./OwnerBookings.web";
import OwnerTransaction from "./OwnerTransaction.web";
import withConfirmBox from "../../../components/src/withConfirmBox.Web";
import OwnerTermsAndCondition from "./OwnerTerms.web"
import OwnerTax from "./OwnerTax.web";
const drawerWidth = 240;
const PageLinks = [
  { displayName: "List your boat", route: "/ownerDashboard" },
  { displayName: "Inbox", route: "/ownerDashboard/messages" },
  { displayName: "Bookings", route: "/ownerDashboard/bookings" },
];
const sideBarLinks = [
  { displayName: "Overview", route: "/ownerDashboard/overview" },
  { displayName: "Bookings", route: "/ownerDashboard/bookings" },
  { displayName: "Messages", route: "/ownerDashboard/messages" },
  { displayName: "My boats", route: "/ownerDashboard/myBoats" },
  { displayName: "Payment", route: "/ownerDashboard/payment" },
  {
    displayName: "Transaction History",
    route: "/ownerDashboard/transactionHistory",
  },
  { displayName: "Tax forms", route: "/ownerDashboard/taxforms" },
  {
    displayName: "Terms & Agreement",
    route: "/ownerDashboard/termsAndAgreement",
  },
];
class OwnerDashboardContainer extends OwnerDashboardController {
  constructor(props: Props) {
    super(props);
  }
  async componentDidMount() {
    const authToken = await StorageProvider.get("authToken");
    // const accountInfo = await StorageProvider.get("accountInfo");
    if (authToken) {
      this.setState({
        authToken: authToken,
      },()=>{
        this.getPersonalInfo()
      });
    }
  }

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };

  goToSelectedRoute = (routeName) => {
    this.handleClose();
    this.setState({ mobileOpen: false });
    if(routeName === "logout"){
      const onClose = async (result) => {
        if(result === 'Yes'){
          await StorageProvider.clearStorage();
          this.props.history.push("/")
        }
      }
      this.props.openConfirmDialog(
        'Logout','Are you sure you want to logout from application',onClose
      )
    } else {
      const { history } = this.props;
      history.push(routeName); 
    }
  };
  handleClose = () => {
    this.setState({
      anchorEl : null
    })
  };
  handleMenu = (event) => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };
  render() {
    const { classes } = this.props;
    let PageLinks = [];
    const menuLinks = [{ displayName: "My Account", route: "/userDashboard" },
    { displayName: "Logout", route: "logout" }]
    if(this.state.authToken){
      PageLinks = [
        { displayName: "List your boat", route: "/ownerDashboard" },
        { displayName: "Inbox", route: "/ownerDashboard/messages" },
        { displayName: "Bookings", route: "/ownerDashboard/bookings" },
      ]
    } else {
      PageLinks = [
        { displayName: "List your boat", route: "/ownerDashboard" },
        { displayName: "Login", route: "/basicAuth/login" },
        { displayName: "Signup", route: "/basicAuth/signup" },
      ];
    }
    return (
      <>
        <AppBar
          position="static"
          color="default"
          elevation={0}
          className={classes.appBar}
        >
          <Toolbar className={classes.toolbar}>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              edge="start"
              onClick={this.handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <Box className={classes.toolbarLogo}>
                <img onClick={()=>this.props.history.push("/")}  src={logo} style={{width:'22%'}} alt="boaturu" />
            </Box>
            <Hidden xsDown>
            <nav>
              {PageLinks.map((link) => {
                return (
                  <Typography
                    key={link.displayName}
                    variant="button"
                    className={classes.link}
                    onClick={() => {
                      this.goToSelectedRoute(link.route);
                    }}
                  >
                    {link.displayName}
                  </Typography>
                );
              })}
            </nav>
            </Hidden>
            
            <Hidden smUp implementation="css">
              <Drawer
                variant="persistent"
                anchor={"left"}
                open={this.state.mobileOpen}
                onClose={this.handleDrawerToggle}
                classes={{
                  paper: classes.drawerPaper,
                }}
                SlideProps={{ unmountOnExit: true }}
              >
                <IconButton
                  onClick={this.handleDrawerToggle}
                  className={classes.closeMenuButton}
                >
                  <CloseIcon />
                </IconButton>
                <List>
                  {sideBarLinks.map((link, index) => (
                    <ListItem
                      button
                      key={link.displayName}
                      onClick={() => {
                        this.goToSelectedRoute(link.route);
                      }}
                    >
                      <ListItemText primary={link.displayName} />
                    </ListItem>
                  ))}
                </List>
              </Drawer>
            </Hidden>
            <div>
            <Hidden xsDown>
             {this.state?.accountInfo?.avatar?.url ? <Avatar
                src={this.state?.accountInfo?.avatar?.url}
                onClick={this.handleMenu}
              /> : <Avatar onClick={this.handleMenu} >{`${this.state.accountInfo?.first_name?.charAt(0) || ""} ${this.state.accountInfo?.last_name?.charAt(0) || ""}`}</Avatar>} 
              <Menu
                id="menu-appbar"
                anchorEl={this.state.anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={!!this.state.anchorEl}
                onClose={this.handleClose}
              >
                {menuLinks.map((link) => {
                return (
                  <MenuItem 
                  key={link.displayName}
                  classes={{
                    root : classes.menuItem
                  }}
                  onClick={() => {
                    this.goToSelectedRoute(link.route);
                  }}>{link.displayName}</MenuItem>
                );
              })}
              </Menu>
            </Hidden>
            <Hidden smUp>
            
            {this.state?.accountInfo?.avatar?.url ? <Avatar
                src={this.state?.accountInfo?.avatar?.url}
                onClick={this.handleMenu}
              /> : <Avatar onClick={this.handleMenu} >{`${this.state.accountInfo?.first_name?.charAt(0) || ""} ${this.state.accountInfo?.last_name?.charAt(0) || ""}`}</Avatar>} 
              <Menu
                id="menu-appbar"
                anchorEl={this.state.anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={!!this.state.anchorEl}
                onClose={this.handleClose}
              >
                {PageLinks.concat(menuLinks).map((link) => {
                return (
                  <MenuItem 
                  key={link.displayName}
                  classes={{
                    root : classes.menuItem
                  }}
                  onClick={() => {
                    this.goToSelectedRoute(link.route);
                  }}>{link.displayName}</MenuItem>
                );
              })}
              </Menu>
            </Hidden>
            </div>
            </Toolbar>
        </AppBar>
        <Grid container className={classes.root}>
          <Hidden xsDown>
            <Grid
              item
              xs={0}
              sm={1}
              md={2}
              container
              className={classes.sideBar}
            >
              <Grid item xs={12}>
                <Typography align="center" className={classes.sideBarHeading}>
                  Owner's Dashboard
                </Typography>
                <List dense={false}>
                  {sideBarLinks.map((item) => {
                    return (
                      <ListItem
                        button
                        key={item.displayName}
                        classes={{
                          selected : classes.sideBarButton
                        }}
                        onClick={() => {
                          this.goToSelectedRoute(item.route);
                        }}
                      >
                        <ListItemAvatar>
                          <AccountCircle />
                        </ListItemAvatar>
                        <ListItemText
                          primary={item.displayName}
                          classes={{
                            primary: classes.sideBarLinks
                          }}
                        />
                      </ListItem>
                    );
                  })}
                </List>
              </Grid>
            </Grid>
          </Hidden>
           <Grid item  xs={12} sm={11} md={10}>
            <Switch>
                <PrivateRoute path="/ownerDashboard/overview" exact>
                  <OwnerOverview />
                </PrivateRoute>
                <PrivateRoute path="/ownerDashboard/bookings" exact>
                  <OwnerBookings />
                </PrivateRoute>
                <PrivateRoute path="/ownerDashboard/messages" exact>
                  <CustomerInbox showSideBar={true}/>
                </PrivateRoute>
                <PrivateRoute path="/ownerDashboard/myBoats" exact>
                  <OwnerBoatList />
                </PrivateRoute>
                <PrivateRoute path="/ownerDashboard/boatDetails" exact>
                  <BoatBookingHistory />
                </PrivateRoute>
                <PrivateRoute path="/ownerDashboard/payment" exact>
                  <OwnerTax name="Payment" />
                </PrivateRoute>
                <PrivateRoute path="/ownerDashboard/transactionHistory" exact>
                  <OwnerTransaction />
                </PrivateRoute>
                <PrivateRoute path="/ownerDashboard/taxforms" exact>
                  <OwnerTax name="Tax" />
                </PrivateRoute>
                <PrivateRoute path="/ownerDashboard/termsAndAgreement" exact>
                  <OwnerTermsAndCondition />
                </PrivateRoute>
                <Redirect exact from="/ownerDashboard" to="/ownerDashboard/myBoats" />
              </Switch>
          </Grid>
        </Grid>
      </>
    );
  }
}
const OwnerDashboardContainerWithRouter = withRouter(OwnerDashboardContainer);
const OwnerDashboardContainerAlertBox = withAlertBox(
  OwnerDashboardContainerWithRouter
);
const OwnerDashboardContainerLoader = withLoader(
  OwnerDashboardContainerAlertBox
);
const OwnerDashboardContainerToast = withToast(OwnerDashboardContainerLoader);
export default withStyles((theme) =>
  createStyles({
    root: {
      height: "calc(100vh - 70px)",
      overflowX: "hidden",
    },
    sideBar: {
      height: "calc(100vh - 72px)",
      backgroundColor: "#f5f5f5",
    },
    appBar: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      width: "100%",
      boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
      backgroundColor: "#337eb0",
      height: theme.spacing(8.75),
    },
    toolbar: {
      flexWrap: "nowrap",
    },
    toolbarLogo: {
      flexGrow: 1,
    },
    link: {
      margin: theme.spacing(1, 2.5),
      fontFamily: "Montserrat",
      fontSize: "14px",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "normal",
      textTransform: "none",
      cursor: "pointer",
      color: "#fff",
    },
    menuItem : {
      fontFamily: "Montserrat",
      fontSize: "14px",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "normal",
      textTransform: "none",
      cursor: "pointer",
      color: "#343a40",
    },
    margin: {
      marginRight: theme.spacing(2),
    },
    drawer: {
      [theme.breakpoints.up("sm")]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      color : '#fff',
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },
    drawerPaper: {
      width: drawerWidth,
    },
    closeMenuButton: {
      marginRight: 0,
      marginLeft: "auto",
    },
    marginTop: {
      marginTop: theme.spacing(4),
    },
    backButtonBox: {
      width: "50px",
      height: "50px",
      borderRadius: "5px",
      border: "solid 2px #0174cf",
      backgroundColor: "#ffffff",
      textAlign: "center",
      cursor: "pointer",
    },
    backButtonBoxContent: {
      color: "#0174cf",
      fontFamily: "Montserrat",
      fontSize: "20px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "50px",
    },
    pageHeading: {
      fontFamily: "Montserrat",
      fontSize: "30px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.23,
      letterSpacing: "normal",
      textAlign: "center",
      color: "#183b56",
    },
    pageSubHeading: {
      fontFamily: "Montserrat",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "normal",
      textAlign: "center",
      color: "#343a40",
    },
    rightRoot: {
      justifyContent: "center",
      display: "flex",
    },
    overlay: {
      position: "absolute",
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
    },
    mainPostContent: {
      position: "relative",
      padding: theme.spacing(3),
      color: "#FFF",
      [theme.breakpoints.up("md")]: {
        padding: theme.spacing(6),
        paddingRight: 0,
      },
    },
    imagetxtcontainer: {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      margin: "auto",
    },
    leftPageHeading: {
      fontFamily: "Montserrat",
      fontSize: "20px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.21,
      letterSpacing: "normal",
      color: "#ffffff",
      paddingLeft: "8px",
    },
    pricing: {
      fontFamily: "Montserrat",
      fontSize: "32px",
      fontWeight: "600",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.22,
      letterSpacing: "normal",
      color: "#ffffff",
    },
    selectOperator: {
      fontFamily: "Montserrat",
      fontSize: "40px",
      fontWeight: "600",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.22,
      letterSpacing: "normal",
      color: "#ffffff",
    },
    leftsidelink: {
      fontFamily: "Montserrat",
      fontSize: "20px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.21,
      letterSpacing: "normal",
      color: "#004277",
    },
    headingline: {
      height: 0,
      width: "90%",
      border: "solid 1px #fff",
    },
    rightPageHeading: {
      fontFamily: "Montserrat",
      fontSize: "20px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.21,
      letterSpacing: "normal",
      color: "#004277",
      textAlign: "center",
    },
    rightPageSubHeading: {
      fontFamily: "Montserrat",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "normal",
      color: "#343a40",
      textAlign: "center",
      paddingTop: "16px",
    },
    inputLable: {
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.2,
      letterSpacing: "normal",
      color: "#343a40",
      textAlign: "center",
      padding: "12px",
    },
    approvalBox: {
      width: "100%",
      height: "120px",
      border: "solid 2px #343a40",
      borderRadius: "6px",
    },
    primaryListText: {
      fontFamily: "Montserrat",
      fontSize: "20px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.21,
      letterSpacing: "normal",
      color: "#343a40",
    },
    secondaryListText: {
      fontFamily: "Montserrat",
      fontSize: "12px",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "normal",
      color: "#343a40",
      paddingTop: "12px",
    },
    formControl: {
      margin: theme.spacing(3),
    },
    checkBoxRoot: {
      display: "flex",
    },
    checkBoxHeading: {
      fontFamily: "Montserrat",
      fontSize: "18px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.21,
      letterSpacing: "normal",
      color: "#004277",
    },
    checkBoxLabel: {
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "normal",
      color: "#343a40",
    },
    logo: {
      fontFamily: "Roboto",
      fontSize: "20px",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.16,
      letterSpacing: "1px",
      color: "#ffffff",
      marginLeft: "24px",
    },
    sideBarLinks: {
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "1px",
      color: "#343a40"
    },
    sideBarHeading: {
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "1px",
      color: "#004277",
      padding : '12px'
    },
    sideBarButton : {
      backgroundColor : 'red'
    }
  })
)(withConfirmBox(OwnerDashboardContainerToast));
