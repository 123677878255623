//@ts-nocheck
import React from "react";
import BoatInsuranceDetailController, {
  Props,
} from "./BoatInsuranceDetailController.web";
import {
  withStyles,
  Grid,
  AppBar,
  Toolbar,
  Typography,
  createStyles,
  Box,
  Button,
  ListItem,
  List,
  ListItemText,
  
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import {
  logo,boatDetail,
  tick,
} from "./assets";
import { withRouter } from "react-router";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
import withBoatIdComponent from "./withBoatIdComponent.js";

const drawerWidth = 240;
const PageLinks = [
  { displayName: "Support", route: "/faq" },
  { displayName: "Save and exit", route: "saveandexit" },
];

class BoatInsuranceDetail extends BoatInsuranceDetailController {
  constructor(props: Props) {
    super(props);
  }
  async componentDidMount() {
    const authToken = await StorageProvider.get("authToken");
    if (authToken) {
      this.setState({
        authToken: authToken,
      },()=>{
        this.getInsurancePolicy();
      })
    }
  }
  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };
  goToSelectedRoute = (routeName) => {
    if (routeName === "saveandexit") {
    } else {
      const { history } = this.props;
      history.push(routeName);
    }
  };
  
  render() {
    const { classes } = this.props;
    return (
      <>
        <AppBar
          position="static"
          color="default"
          elevation={0}
          className={classes.appBar}
        >
          <Toolbar className={classes.toolbar}>
            <Box className={classes.toolbarLogo}>
              <img onClick={()=>this.props.history.push("/")}  src={logo} style={{width:'22%'}} alt="boaturu" />
            </Box>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              edge="start"
              onClick={this.handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <Hidden xsDown implementation="css">
              <nav>
                {PageLinks.map((link) => {
                  return (
                    <Typography
                      key={link.displayName}
                      variant="button"
                      className={classes.link}
                      onClick={() => {
                        this.goToSelectedRoute(link.route);
                      }}
                    >
                      {link.displayName}
                    </Typography>
                  );
                })}
              </nav>
            </Hidden>
            <Hidden smUp implementation="css">
              <Drawer
                variant="persistent"
                anchor={"right"}
                open={this.state.mobileOpen}
                onClose={this.handleDrawerToggle}
                classes={{
                  paper: classes.drawerPaper,
                }}
                SlideProps={{ unmountOnExit: true }}
              >
                <IconButton
                  onClick={this.handleDrawerToggle}
                  className={classes.closeMenuButton}
                >
                  <CloseIcon />
                </IconButton>
                <List>
      {PageLinks.map((link, index) => (
        <ListItem button key={link.displayName} onClick={() => {
          this.goToSelectedRoute(link.route);
        }}>
          <ListItemText primary={link.displayName} />
        </ListItem>
      ))}
    </List>
              </Drawer>
            </Hidden>
          </Toolbar>
        </AppBar>
        <Grid container className={classes.root}>
          <Grid
            item
            xs={false}
            sm={4}
            md={5}
            container
            className={classes.image}
          >
            <div className={classes.overlay} />
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <div className={classes.imagetxtcontainer}>
                    <img src={tick} />
                    <Typography
                      display="inline"
                      className={classes.leftPageHeading}
                    >
                      Boat details
                    </Typography>
                  </div>
                  <div className={classes.imagetxtcontainer}>
                    <img src={tick} />
                    <Typography
                      display="inline"
                      className={classes.leftPageHeading}
                    >
                      Pricing
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography gutterBottom className={classes.pricing}>
                Great Progress so far!
              </Typography>
              <div className={classes.headingline} />
              <Typography gutterBottom className={classes.selectOperator}>
                Let's discuss insurance
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography gutterBottom className={classes.leftsidelink}>
                Safety
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={8} md={7}>
            <Grid container style={{ padding: "16px 32px" }} spacing={10}>
              <Grid item xs={12} container justify="space-between">
                <Grid item>
                  <Box className={classes.backButtonBox} onClick={() => {
                              this.goToSelectedRoute("/boatPrices");
                            }}>
                    <Typography className={classes.backButtonBoxContent}>
                      {"<"}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item>
                  <Button
                    
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={()=>{this.goToSelectedRoute("boatInsuranceDetailForm")}}
                  >
                    Proceed
                  </Button>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.rightPageHeading}>You can get insurance by</Typography>
              </Grid>
              <Grid item xs={12} container style={{marginLeft : '18px'}} justify="space-between" spacing={5}>
                {this.state.policyList.map((policy,index)=>{
                  return <>
                   <Grid item xs={1}>
                  <Typography className={classes.listnumber}>{"0"+(index+1)}</Typography>
                </Grid>
                
                <Grid item xs={11}>
                  <Typography
                    display="inline"
                    className={classes.listInsurance}
                  >
                    {policy?.attributes?.content}
                  </Typography>
                </Grid>
               
                  </>
                })}
                </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }
}
const BoatInsuranceDetailWithRouter = withRouter(BoatInsuranceDetail);
const BoatInsuranceDetailAlertBox = withAlertBox(
  BoatInsuranceDetailWithRouter
);
const BoatInsuranceDetailLoader = withLoader(BoatInsuranceDetailAlertBox);
const BoatInsuranceDetailToast = withToast(BoatInsuranceDetailLoader);

export default withStyles((theme) =>
  createStyles({
    root: {
      height: "calc(100vh - 70px)",
      overflowX: "hidden",
    },
    image: {
      position: "relative",
      backgroundImage: `url(${boatDetail})`,
      backgroundRepeat: "no-repeat",
      backgroundColor:
        theme.palette.type === "light"
          ? theme.palette.grey[50]
          : theme.palette.grey[900],
      backgroundSize: "cover",
      backgroundPosition: "center",
      padding: "120px 68px",
      height: "calc(100vh - 72px)",
    },
    appBar: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      width: "100%",
      boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
      backgroundColor: "#337eb0",
      height: theme.spacing(8.75),
    },
    toolbar: {
      flexWrap: "nowrap",
    },
    toolbarLogo: {
      flexGrow: 1,
    },
    link: {
      margin: theme.spacing(1, 2.5),
      fontFamily: "Roboto",
      fontSize: "14px",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.14,
      letterSpacing: "normal",
      textTransform: "none",
      cursor: "pointer",
      color: "#fff",
    },
    margin: {
      margin: theme.spacing(1),
    },
    drawer: {
      [theme.breakpoints.up("sm")]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },
    drawerPaper: {
      width: drawerWidth,
    },
    closeMenuButton: {
      marginRight: 0,
      marginLeft: "auto",
    },
    marginTop: {
      marginTop: theme.spacing(4),
    },
    backButtonBox: {
      width: "50px",
      height: "50px",
      borderRadius: "5px",
      border: "solid 2px #0174cf",
      backgroundColor: "#ffffff",
      textAlign: "center",
      cursor : 'pointer'
    },
    backButtonBoxContent: {
      color: "#0174cf",
      fontFamily: "Montserrat",
      fontSize: "20px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "50px",
    },
    pageHeading: {
      fontFamily: "Montserrat",
      fontSize: "20px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.21,
      letterSpacing: "normal",
      textAlign: "center",
      color: "#004277",
    },
    pageSubHeading: {
      fontFamily: "Montserrat",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "normal",
      textAlign: "center",
      color: "#343a40",
    },
    rightRoot: {
      justifyContent: "center",
      display: "flex",
    },
    overlay: {
      position: "absolute",
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
    },
    mainPostContent: {
      position: "relative",
      padding: theme.spacing(3),
      color: "#FFF",
      [theme.breakpoints.up("md")]: {
        padding: theme.spacing(6),
        paddingRight: 0,
      },
    },
    imagetxtcontainer: {
      display: "flex",
      alignItems: "center",
      flexDirection: "row",
      marginTop: "6px",
    },
    leftPageHeading: {
      fontFamily: "Montserrat",
      fontSize: "20px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.21,
      letterSpacing: "normal",
      color: "#ffffff",
      paddingLeft: "8px",
    },
    pricing: {
      fontFamily: "Montserrat",
      fontSize: "32px",
      fontWeight: "600",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.22,
      letterSpacing: "normal",
      color: "#ffffff",
    },
    selectOperator: {
      fontFamily: "Montserrat",
      fontSize: "40px",
      fontWeight: "600",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.22,
      letterSpacing: "normal",
      color: "#ffffff",
    },
    leftsidelink: {
      fontFamily: "Montserrat",
      fontSize: "20px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.21,
      letterSpacing: "normal",
      color: "#004277",
    },
    headingline: {
      height: 0,
      width: "90%",
      border: "solid 1px #fff",
    },
    listInsurance: {
      fontFamily: "Montserrat",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "normal",
      color: "#343a40",
      textAlign: "center",
    },
    listnumber: {
      fontFamily: "Montserrat",
      fontSize: "32px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.22,
      letterSpacing: "normal",
      color: "#0174cf",
      textAlign: "center",
    },
    rightPageHeading : {
        fontFamily: "Montserrat",
      fontSize: "24px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.21,
      letterSpacing: "normal",
      color: "#004277",
      paddingLeft : '48px'
    }
  })
)(withBoatIdComponent(BoatInsuranceDetailToast));
