//@ts-nocheck
import React from "react";
import MarinaDashboardCheckInController, {
  Props,
} from "./MarinaDashboardCheckInController.web";

import { withStyles, createStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { Grid, Avatar, Container ,TableRow, Collapse, TableCell,TablePagination} from "@material-ui/core/";
import Button from "@material-ui/core/Button";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
import withDialog from "../../../components/src/withDialog.web";
import { withRouter } from "react-router";
import BookingDetailPopup from "./BookingDetailPopup.web";
import moment from "moment";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const TabPanel=(props: TabPanelProps)=> {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

const a11yProps=(index: any)=> {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}



class BookIngDetailRow extends React.Component {
  constructor(props: Props) {
    super(props);
    this.state = { open: false };
  }
  render() {
    const { classes, data, checkInStatus,makeCheckIn } = this.props;
    return (
      <>
        <Box className={classes.box} py={3} key={data.id}>
          <Grid
            container
            spacing={3}
            pl={0}
          >
            <Grid item xs={12} md={5}>
              <img
                src={data?.attributes?.boat_image}
                width="100%"
                className={classes.boatImage}
              />
            </Grid>
            <Grid container item xs={12} md={7}>
              <Box pb={3}>
                <Typography variant="h5" className={classes.blueHeading}>
                  {/* {data?.attributes?.boat?.attributes?.name} */}
                  {data.attributes?.boat_name}
                </Typography>
                <Typography variant="h7">{`${data.attributes?.boat_location?.address1 || "Not Available"},${data.attributes?.boat_location?.city || "Not Available"}`}</Typography>
              </Box>

              <Grid container item>
                <Grid item xs={5}>
                  <Typography variant="h7" className={classes.detailName}>
                    Rented By
                  </Typography>
                  <Box>
                    <Box display="inline-block" pt={1}>
                      <Avatar component="span" src={data?.attributes?.renter?.avatar?.url} className={classes.avatar} />
                    </Box>
                    <Box display="inline-block" className={classes.textCentre}>
                      <Typography variant="h7" component="div">
                        {(data?.attributes?.renter?.first_name || "Not Available") + " " + (data?.attributes?.renter?.last_name || "Not Available")}
                      </Typography>
                      <Typography
                        variant="h7"
                        component="div"
                        className={classes.blueDetails}
                      >
                        Call Renter
                      </Typography>
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={7}>
                  {data?.attributes?.booking_details?.data?.attributes?.approval_status === "pending" ? (
                    <Typography variant="h7" className={classes.pendingStatus}>
                      <ErrorIcon /> Approval Pending
                     </Typography>
                    
                  ) : (
                    <Typography variant="h7" className={classes.approvalStatus}>
                      <CheckCircleIcon /> Approved by Owner
                    </Typography>
                  )}

                  <Box>
                    <Box display="inline-block" pt={1}>
                      <Avatar component="span" src={data?.attributes?.boat_owner?.avatar?.url} className={classes.avatar} />
                    </Box>
                    <Box display="inline-block" className={classes.textCentre}>
                      <Typography variant="h7" component="div">
                        {(data?.attributes?.boat_owner?.first_name || "Not Available") + " " + (data?.attributes?.boat_owner?.last_name || "Not Available") }
                      </Typography>
                      <Typography
                        variant="h7"
                        component="div"
                        className={classes.blueDetails}
                      >
                        Call Owner
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              <Grid container item>
                <Grid item xs={5}>
                  <Box display="flex" justifyContent="flex-start">
                    <Box />
                    <Box>
                      <Typography variant="h6" className={classes.detailName}>
                        Trip date
                      </Typography>
                      <Typography
                        variant="h7"
                        component="div"
                        className={classes.timeDetails}
                      >
                        {moment(new Date(data?.attributes?.booking_details?.data?.attributes?.trip_date).getTime()).format(
                          "DD/MM/YYYY"
                        )}
                      </Typography>
                      <Typography
                        variant="h7"
                        component="div"
                        className={classes.timeDetails}
                      >
                        {moment(new Date(data?.attributes?.booking_details?.data?.attributes?.start_time).getTime()).format(
                          "hh:mm A"
                        )}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid
                  container
                  item
                  xs={7}
                  spacing={2}
                  justify="space-between"
                  alignContent="center"
                >
                  <Grid item>
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={() => this.setState({ open: true })}
                      className={classes.roundButtons}
                    >
                      Booking Detail
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.approveButton}
                      onClick={()=>{makeCheckIn(data?.attributes?.booking_details?.data?.attributes)}}
                      disabled={data.attributes.booking_details.data.attributes.approval_status !== 'approved' || data.attributes.booking_details.data.attributes.checkin_status !== 'pending'}
                    >
                      Check-in
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Box>
            <Collapse in={this.state.open} timeout="auto" unmountOnExit>
              <Box py={3.75} className={classes.tripDetailsBox}>
                <Grid container spacing={2}>
                  <Grid container item xs={12} sm={3}>
                    <Box
                      display="grid"
                      alignContent="space-between"
                      spacing={2}
                    >
                      <Typography
                        className={classes.tripDetailsTitle}
                        align="center"
                      >
                        Trip Details
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item container xs={12} sm={4} spacing={2}>
                    <Grid container item xs={7}>
                      <Box>
                        <Typography
                          className={classes.tripDetailsHead}
                          gutterBottom
                        >
                          Date:
                        </Typography>
                        <Typography
                          className={classes.tripDetailsHead}
                          gutterBottom
                        >
                          Passengers:
                        </Typography>
                        <Typography
                          className={classes.tripDetailsHead}
                          gutterBottom
                        >
                          Duration:
                        </Typography>
                        <Typography
                          className={classes.tripDetailsHead}
                          gutterBottom
                        >
                          Start Time:
                        </Typography>
                        <Typography
                          className={classes.tripDetailsHead}
                          gutterBottom
                        >
                          Caption:
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={5}>
                      <Typography
                        className={classes.tripDetailsText}
                        gutterBottom
                      >
                        {moment(data?.attributes?.booking_details?.data?.attributes?.created_at).format("DD/MM/YYYY")}
                      </Typography>
                      <Typography
                        className={classes.tripDetailsText}
                        gutterBottom
                      >
                        {data?.attributes?.booking_details?.data?.attributes?.passengers ? data?.attributes?.booking_details?.data?.attributes?.passengers : "0"}
                      </Typography>
                      <Typography
                        className={classes.tripDetailsText}
                        gutterBottom
                      >
                        {data?.attributes?.booking_details?.data?.attributes?.trip_duration ? data?.attributes?.booking_details?.data?.attributes?.trip_duration : "0"}
                      </Typography>
                      <Typography
                        className={classes.tripDetailsText}
                        gutterBottom
                      >
                        {moment(data?.attributes?.booking_details?.data?.attributes?.start_time).format("hh:mm A")}
                      </Typography>
                      <Typography
                        className={classes.tripDetailsText}
                        gutterBottom
                      >
                        {data?.attributes?.booking_details?.data?.attributes.captain_included === true ? (
                      <Typography
                        className={classes.tripDetailsHead}
                        gutterBottom
                      >
                        {"Captain included"}
                      </Typography>
                    ) : "No Captain included"}
                      </Typography>
                    </Grid>
                    
                  </Grid>
                  <Grid item container xs={12} sm={5} spacing={2}>
                    <Grid item xs={4}>
                      <Typography
                        className={classes.tripDetailsHead}
                        gutterBottom
                      >
                        Trip date
                      </Typography>
                      <Typography
                        className={classes.tripDetailsText}
                        gutterBottom
                      >
                        {moment(data?.attributes?.booking_details?.data?.attributes?.trip_date).format("DD/MM/YYYY")}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography
                        className={classes.tripDetailsHead}
                        gutterBottom
                      >
                        Checkout date
                      </Typography>
                      <Typography
                        className={classes.tripDetailsText}
                        gutterBottom
                      >
                        {moment(data?.attributes?.booking_details?.data?.attributes?.end_time).format("DD/MM/YYYY")}
                        
                      </Typography>
                      <Typography
                        className={classes.tripDetailsText}
                        gutterBottom
                      >
                        {moment(data?.attributes?.booking_details?.data?.attributes?.end_time).format("hh:mm A")}
                      </Typography>
                    </Grid>
                    <Grid container item xs={4} spacing={0}>
                      <Box display="flex" justifyContent="flex-end">
                        <Button
                          onClick={() => this.setState({ open: false })}
                          className={classes.minimize}
                        >
                          See Less ^
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={7}>
                    <Box>
                      <Typography
                        className={classes.tripDetailsHead}
                        component="span"
                      >
                        Payment Status
                      </Typography>
                      {data?.attributes?.booking_details?.data?.attributes?.payment_status === "paid" ? (
                        <Typography
                          component="span"
                          size="small"
                          className={classes.paymentPaid}
                        >
                          PAID
                        </Typography>
                      ) : (
                        <Typography
                          component="span"
                          size="small"
                          className={classes.paymentUnpaid}
                        >
                          UNPAID
                        </Typography>
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    <Box>
                      <Typography
                        className={classes.tripDetailsHead}
                        gutterBottom
                      >
                        Message for Owner
                      </Typography>
                      <Typography
                        className={classes.tripDetailsText}
                        gutterBottom
                      >
                        {data?.attributes?.booking_details?.data?.attributes?.message || "Not Available"}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Collapse>
          </Box>
        </Box>
      </>
    );
  }
}

class MarinaDashboardCheckIn extends MarinaDashboardCheckInController {
  constructor(props: Props) {
    super(props);
  }
  handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({ selectedBookingType: newValue },()=>{
      if(this.state.selectedBookingType === 'TODAY'){
          this.getTodayCheckins(this.state.marinaId); 
        } else if(this.state.selectedBookingType === 'UPCOMING'){
          this.getUpComingCheckins(this.state.marinaId);  
        }
    });
  };
  async componentDidMount() {
    const authToken = await StorageProvider.get("authToken");
    const marinaId = this.props?.marinaId || await StorageProvider.get("marinaId");
    if (authToken && marinaId) {
      this.setState({
        authToken: authToken,
        marinaId : marinaId
      },()=>{
        this.getUpComingCheckins(marinaId);
        this.getTodayCheckins(marinaId);
      });
    }
  }
  openBookingDetailsDialog = (data,e) => {
    e.stopPropagation();
    this.props
      .openDialogBox({
        title: "Booking Details",
        withCustomDialog: false,
        renderedComponent: BookingDetailPopup,
        dataToPass: {bookingDetail : data,isApproved : false,isRenterView : false},
        catchOnCancel: true,
      })
      .then((data) => {
        
      });
  };
  handleTodayCheckinPage = (event: React.ChangeEvent<unknown>, value: number) => {
    this.setState(
      {
        todayCheckInListPage: value,
      },
      () => {
        this.getTodayCheckins(this.state.marinaId);
      }
    );
  };
  handleUpcomingPageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    this.setState(
      {
        upcomingCheckInListPage: value,
      },
      () => {
        this.getUpComingCheckins(this.state.marinaId);
      }
    );
  };
  handleTodayCheckinRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    this.setState(
      {
        recordsPerPagetodayCheckInList: parseInt(event.target.value, 10),
        todayCheckInListPage: 0,
      },
      () => {
        this.getUpComingCheckins(this.state.marinaId);
      }
    );
  };
  handleChangeRowsPerPageUpcoming = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    this.setState(
      {
        recordsPerPageupcomingCheckInList: parseInt(event.target.value, 10),
        upcomingCheckInListPage: 0,
      },
      () => {
        this.getUpComingCheckins(this.state.marinaId);
      }
    );
  };
  
  render() {
    const { classes } = this.props;
    return (
      <Container maxWidth="lg">
        <Box pt={5} pb={2}>
          <Typography variant="h4">Check in</Typography>
        </Box>
        <Grid container>
          <Grid item xs={12} md={10}>
            <AppBar position="static" className={classes.removeBoxShadow}>
              <Tabs
                value={this.state.selectedBookingType}
                onChange={this.handleChange}
                aria-label="simple tabs example"
                className={classes.tabPanelcolor}
              >
                <Tab value="TODAY" label="TODAY" {...a11yProps(0)} />
                <Tab value="UPCOMING" label="UPCOMING"{...a11yProps(1)} />
              </Tabs>
            </AppBar>
            <TabPanel
              value={this.state.selectedBookingType}
              index={"TODAY"}
              className={classes.removePadding}
            >
              {this.state.todayCheckInList.length > 0 ? this.state.todayCheckInList.map((data,ind) => (
                <BookIngDetailRow key={ind} data={data} makeCheckIn={this.makeCheckIn} classes={classes} checkInStatus={true} openBookingDetailsDialog={this.openBookingDetailsDialog}/>
              ))
               : <div>
              <Typography align="center" color="textPrimary" variant="h4"> Bookings Not Available </Typography></div>}
              <Grid container item xs={12}>
                    <TablePagination
                            component="div"
                            align="right"
                            count={this.state.todayCheckInListTotalCount}
                            page={this.state.todayCheckInListPage}
                            onChangePage={this.handleTodayCheckinPage}
                            rowsPerPage={this.state.recordsPerPagetodayCheckInList}
                            onChangeRowsPerPage={this.handleTodayCheckinRowsPerPage}
                          />
                      </Grid>
             </TabPanel>
            <TabPanel value={this.state.selectedBookingType} 
            index={"UPCOMING"}
            className={classes.removePadding}
            >
            {this.state.upcomingCheckInList.length > 0 ? this.state.upcomingCheckInList.map((data,ind) => (
                <BookIngDetailRow key={ind} data={data} classes={classes} makeCheckIn={this.makeCheckIn} checkInStatus={true} openBookingDetailsDialog={this.openBookingDetailsDialog}/>
              )) : <div>
                <Typography align="center" color="textPrimary" variant="h4"> Bookings Not Available </Typography></div>}
                 <Grid container item xs={12}>
                    <TablePagination
                            component="div"
                            align="right"
                            count={this.state.upcomingCheckInListTotalCount}
                            page={this.state.upcomingCheckInListPage}
                            onChangePage={this.handleUpcomingPageChange}
                            rowsPerPage={this.state.recordsPerPageupcomingCheckInList}
                            onChangeRowsPerPage={this.handleChangeRowsPerPageUpcoming}
                          />
                      </Grid>
            </TabPanel>
          </Grid>
        </Grid>
      </Container>
    );
  }
}
const MarinaDashboardCheckInWithRouter = withRouter(MarinaDashboardCheckIn);
const MarinaDashboardCheckInAlertBox = withAlertBox(MarinaDashboardCheckInWithRouter);
const MarinaDashboardCheckInLoader = withLoader(MarinaDashboardCheckInAlertBox);
const MarinaDashboardCheckInToast = withToast(MarinaDashboardCheckInLoader);
export default withStyles((theme) =>
  createStyles({
    box: {
      border: "1px #dee2e3",
      borderStyle: "none none solid none",
    },
    removePadding: {
      margin: "-24px",
    },
    blueHeading: {
      fontFamily: "Montserrat",
      color: "#004277",
      fontWeight: 600,
    },
    smallText: {
      fontFamily: "Montserrat",
      color: "#343a40",
    },
    amount: {
      fontFamily: "Montserrat",
      fontSize: "2rem",
      fontWeight: 600,
      color: "#000000",
    },
    detailName: {
      fontFamily: "Montserrat",
      fontSize: "1rem",
      fontWeight: 600,
      color: "#343a40",
    },
    blueDetails: {
      fontFamily: "Montserrat",
      fontSize: "0.875rem",
      color: "#0174cf",
    },
    paidStatus: {
      fontFamily: "Montserrat",
      color: "#b8b8b8",
    },
    timeDetails: {
      fontFamily: "Montserrat",
      fontSize: "0.875rem",
      color: "#343a40",
    },
    tabPanelcolor: {
      fontFamily: "Montserrat",
      backgroundColor: "white",
      color: "black",
    },
    approveButton: {
      fontFamily: "Montserrat",
      borderRadius: "5px",
      
    },
    roundButtons: {
      fontFamily: "Montserrat",
      borderRadius: "5px",
      border: "solid 2px #0174cf",
    },
    avatar: {
      fontFamily: "Montserrat",
      height: "60px",
      width: "60px",
    },
    removeBoxShadow: {
      fontFamily: "Montserrat",
      boxShadow: "none",
      border: "1px #dee2e3",
      borderStyle: "none none solid none",
    },
    selected: {
      fontFamily: "Montserrat",
      opacity: 1.0,
      color: "black",
    },
    notselected: {
      fontFamily: "Montserrat",
      opacity: 0.3,
      color: "#b8b8b8",
    },
    boatImage: {
      height: "280px",
    },
    trashbin: {
      opacity: 0.5,
    },
    textCentre: {
      verticalAlign: "text-bottom",
      "@media only screen and (min-width: 768px)": {
        paddingLeft:"24px"
      }
    },
    deleteButton:{
      color:theme.palette.error.main
    },
    approvalStatus:{
      fontSize:"0.875rem",
      fontFamily: "Montserrat",
      color:"#00a676",
    }, 
    pendingStatus:{
      fontSize:"0.875rem",
      fontFamily: "Montserrat",
      color:"#e9724c",
    },
    tripDetailsBox: {
      margin: "0 -16px",
    },
    tripDetailsTitle: {
      paddingTop: "0.25rem",
      color: "#004277",
      fontFamily: "Montserrat",
      fontSize: "1.25rem",
      fontWeight: 600,
      lineHeight: 1.56,
    },
    tripDetailsHead: {
      fontFamily: "Montserrat",
      fontSize: "0.8rem",
      fontWeight: 600,
      color: "#343a40",
      lineHeight: 2,
    },
    tripDetailsText: {
      fontFamily: "Montserrat",
      fontSize: "1rem",
      fontWeight: 500,
      color: "#343a40",
      lineHeight: 2,
    },
    paymentPaid:{
      marginLeft:"10px",
      backgroundColor:"#b9e6d9",
      color:"#00a676",
      padding:"5px",
      borderRadius: "5px",
      border: "solid 2px #00a676"
    },
    paymentUnpaid:{
      marginLeft:"10px",
      backgroundColor:"#f5b29d",
      color:"#e9724c",
      padding:"5px",
      borderRadius: "5px",
      border: "solid 2px #e9724c"
    },
    extraDetails: {
      paddingTop: "0px",
      paddingBottom: "0px",
    },
    minimize:{
      display:"flex",
      alignItems:"flex-start"
    }
  })
)(withDialog(MarinaDashboardCheckInToast));
