//@ts-nocheck
import React from "react";
import BoatReviewController, { Props } from "./BoatReviewController.web";
import {
  withStyles,
  Grid,
  Typography,
  createStyles,
  Button,
  Paper,
  Link,
  Box,
  Container,
  TextField,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
} from "@material-ui/core";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { boatImage } from "./assets";
import { withRouter } from "react-router";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { boatImageDemo1, boatImageDemo2 } from "./assets";
import Rating from "@material-ui/lab/Rating";

const Schema = Yup.object().shape({
  rating: Yup.string().required("This field is required."),
  review: Yup.string().required("This field is required."),
});
class BoatReview extends BoatReviewController {
  constructor(props: Props) {
    super(props);
  }
  goToSelectedRoute = (routeName) => {
    const { history } = this.props;
    history.push(routeName);
  };
  closeDialog = (data) => {
    const { onSubmit } = this.props;
    onSubmit(data);
  };
  async componentDidMount() {
    const authToken = await StorageProvider.get("authToken");
    const accountInfo = await StorageProvider.get("accountInfo");
    if (authToken && accountInfo) {
      this.setState({
        senderId: JSON.parse(accountInfo).id,
        authToken: authToken,
      });
    }
  }
  render() {
    const { classes } = this.props;
    return (
      <Formik
        initialValues={{
          review: "",
          rating: "",
        }}
        validationSchema={Schema}
        onSubmit={(values, actions) => {
          this.createReview(values);
        }}
      >
        {(formikProps) => {
          const {
            values,
            setFieldValue,
            handleChange,
            isValid,
            dirty,
            errors,
            touched,
          } = formikProps;
          return (
            <Form noValidate autoComplete="off">
              <Grid container justify="space-between" spacing={2}>
                <Grid item xs={6}>
                  <Grid container justify="center" spacing={4}>
                    <Grid item xs={12}>
                      <Grid container spacing={2} justify="space-between">
                        <Grid xs={10} item>
                          <Typography>Booking for:</Typography>
                        </Grid>
                        <Grid
                          className={classes.hotelMainImage}
                          style={{
                            backgroundImage: `url(${
                              this.props?.dataToPass?.bookingDetail
                              ?.attributes.boat.attributes?.thumbnail_image?.url || boatImageDemo1
                            })`,
                          }}
                          xs={8}
                          item
                        />
                        <Grid item xs={10}>
                          <Typography gutterBottom className={classes.boatName}>
                            {`${this.props?.dataToPass?.bookingDetail
                              ?.attributes.boat.attributes?.name}`}
                          </Typography>
                          <Typography
                            gutterBottom
                            className={classes.boatAddress}
                          >
                            {`${this.props?.dataToPass?.bookingDetail
                              ?.attributes.boat.attributes?.address?.address1},${this.props?.dataToPass?.bookingDetail
                                ?.attributes.boat.attributes?.address?.city}`}
                          </Typography>
                        </Grid>
                        <Grid item xs={10}>
                          <ListItem>
                            <ListItemAvatar>
                              <Avatar src={boatImageDemo1} />
                            </ListItemAvatar>
                            <ListItemText
                              primary={
                                this.props?.dataToPass?.bookingDetail
                              ?.attributes.boat.attributes?.account
                                  ?.user_name
                              }
                              secondary="Verified Owner"
                              classes={{
                                primary: classes.ownerName,
                                secondary: classes.ownerNameHeading,
                              }}
                            />
                          </ListItem>
                        </Grid>
                        <Grid item xs={10}>
                          <Typography>
                            Hope you had a pleasant experience!
                          </Typography>
                          <Typography>
                            You'll receive your security deposit in 2-3 working
                            days
                          </Typography>
                          <Typography>Thank you!</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid container justify="center" spacing={4}>
                    <Grid item xs={12}>
                      <Box component="fieldset" p={3} borderColor="transparent">
                        <Typography component="legend">
                          Please rate your experience
                        </Typography>
                        <Rating
                          name="rating"
                          size="large"
                          value={values.rating}
                          onChange={(event, newValue) => {
                            setFieldValue("rating", newValue);
                          }}
                        />
                        {touched.rating && errors.rating && (
                          <Typography color="error">{errors.rating}</Typography>
                        )}
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box component="fieldset" p={3} borderColor="transparent">
                        <Typography component="legend">
                          We'd love to have your reviews
                        </Typography>
                        <TextField
                          variant="outlined"
                          label="Share your experience"
                          fullWidth
                          name="review"
                          value={values.review}
                          multiline
                          rows={6}
                          onChange={handleChange}
                        />
                        {touched.review && errors.review && (
                          <Typography color="error">{errors.review}</Typography>
                        )}
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container justify="flex-end">
                        <Grid item xs={3}>
                          <Button
                            onClick={() => {
                              this.props.onCancel();
                            }}
                            variant="outlined"
                            color="primary"
                          >
                            Cancel
                          </Button>
                        </Grid>
                        <Grid item xs={3}>
                          <Button
                            variant="contained"
                            type="submit"
                            color="primary"
                          >
                            Submit
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    );
  }
}
const BoatReviewWithRouter = withRouter(BoatReview);
const BoatReviewWithToast = withToast(BoatReviewWithRouter);
const BoatReviewWithLoader = withLoader(BoatReviewWithToast);
const BoatReviewWithAlertBox = withAlertBox(BoatReviewWithLoader);

export default withStyles((theme) =>
  createStyles({
    hotelMainImage: {
      position: "relative",
      backgroundImage: `url(${boatImageDemo1})`,
      backgroundRepeat: "no-repeat",
      backgroundColor:
        theme.palette.type === "light"
          ? theme.palette.grey[50]
          : theme.palette.grey[900],
      backgroundSize: "cover",
      backgroundPosition: "center",
      height: theme.spacing(15),
      [theme.breakpoints.down("xs")]: {
        height: theme.spacing(10),
      },
      borderRadius: theme.spacing(1),
    },
    boatName: {
      fontFamily: "Montserrat",
      fontSize: "28px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "normal",
      color: "#004277",
    },
    boatAddress: {
      fontFamily: "Montserrat",
      fontSize: "12px",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "normal",
      color: "#343a40",
    },
  })
)(BoatReviewWithAlertBox);
