import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { dialogBoxProps  } from "../../../components/src/withDialog.web";
import { withAlertBoxProps } from "../../../components/src/withAlertBox.Web";
import { withToastProps } from "../../../components/src/withSnackBar.Web";
import { withLoaderProps } from "../../../components/src/withLoader.Web";
export const configJSON = require("./config");
import moment from "moment";
export type Props = RouterProps & dialogBoxProps & withAlertBoxProps & withToastProps & withLoaderProps & {
  id: string;
  openConfirmDialog : string;
  // Customizable Area Start
  // Customizable Area End
};

interface S {
  // Customizable Area Start
  authToken : String,
  upcomingCheckoutList : Array<any>;
  todayCheckOutList : Array<any>;
  pastCheckOutList : Array<any>,
  selectedBookingType : string,
  marinaId : any,
  selectedMonth : any,
  selectedDate : any,
  pastCheckOutListTotalCount : number,
  pastCheckOutListPage : number,
  recordsPerPagepastCheckOutList : number,
  todayCheckOutListTotalCount : number,
  todayCheckOutListPage : number,
  recordsPerPagetodayCheckOutList : number,
  upcomingCheckOutListTotalCount : number,
  upcomingCheckOutListPage : number,
  recordsPerPageupcomingCheckOutList : number
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class MarinaDashboardCheckOutController extends BlockComponent<
  Props,
  S,
  SS
> {
    getUpcomingCheckoutListApiCallId : String = "";
    getTodayCheckoutListApiCallId : String = "";
    getPastCheckoutListApiCallId : String = "";
    checkOutBookingCallId : String = ""
    constructor(props: Props) {
      super(props);
      this.receive = this.receive.bind(this);
  
      // Customizable Area Start
      this.subScribedMessages = [
        // Customizable Area Start
        getName(MessageEnum.RestAPIResponceMessage),
        getName(MessageEnum.AlertMessage),
        // Customizable Area End
      ];
  
      this.state = {
        authToken : '',
        selectedBookingType : 'TODAY',
        upcomingCheckoutList : [],
        todayCheckOutList : [],
        pastCheckOutList : [],
        marinaId : null,
        selectedMonth : new Date(),
        selectedDate : null,
        pastCheckOutListTotalCount : 0,
        pastCheckOutListPage : 0,
        recordsPerPagepastCheckOutList : 10,
        todayCheckOutListTotalCount : 0,
        todayCheckOutListPage : 0,
        recordsPerPagetodayCheckOutList : 10,
        upcomingCheckOutListTotalCount : 0,
        upcomingCheckOutListPage : 0,
        recordsPerPageupcomingCheckOutList : 10
        
      };
      // Customizable Area End
      runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }
  
    async receive(from: string, message: Message) {
      runEngine.debugLog("Message Recived", message);
      if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
        const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );
  
        var responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
  
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        if (apiRequestCallId === this.getTodayCheckoutListApiCallId) {
              this.props.hideLoader();
              if (responseJson && responseJson?.data) {
              this.setState({
                  todayCheckOutList : responseJson?.data
              })
              } else {
              this.parseApiErrorResponse(responseJson);
              }
          } else if (apiRequestCallId === this.getUpcomingCheckoutListApiCallId) {
            this.props.hideLoader();
            if (responseJson && responseJson?.data) {
            this.setState({
                upcomingCheckoutList : responseJson?.data
            })
            } else {
            this.parseApiErrorResponse(responseJson);
            }
        } else if (apiRequestCallId === this.getPastCheckoutListApiCallId) {
          this.props.hideLoader();
          if (responseJson && responseJson?.data) {
          this.setState({
              pastCheckOutList : responseJson?.data,
              pastCheckOutListTotalCount : responseJson?.meta.pagination.total_count
          })
          } else {
          this.parseApiErrorResponse(responseJson);
          }
        } else if (apiRequestCallId === this.checkOutBookingCallId) {
          this.props.hideLoader();
          if (responseJson && responseJson?.data) {
            this.props.showToast({type : 'success',message : 'Checkout successfully'})
          } else {
          this.parseApiErrorResponse(responseJson);
          }
        }  
      } else if (getName(MessageEnum.AlertMessage) === message.id) {
        const title = message.getData(getName(MessageEnum.AlertTitleMessage));
        var AlertBodyMessage = message.getData(
          getName(MessageEnum.AlertBodyMessage)
        );
        const msg = await this.props.showAlert({title : 'Error',message : AlertBodyMessage})
        if(msg === 'Your session has expired, please login again.' || msg === 'Invalid token'){
          await StorageProvider.remove("authToken")
          await StorageProvider.remove("accountInfo")  
          this.props.history.push("/basicAuth/login")
        }
      }
    }
    getUpcomingCheckoutListing(marinaId : any) {
      this.props.showLoader();
      const headers = {
        "content-type": configJSON.ApiContentType,
        token : this.state.authToken
      };
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.getUpcomingCheckoutListApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_address_management/marinas/checkout?type=upcoming&marina_id=${marinaId}`
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.methodGET
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    getTodayCheckoutListing(marinaId : any) {
      this.props.showLoader();
      const headers = {
        "content-type": configJSON.ApiContentType,
        token : this.state.authToken
      };
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.getTodayCheckoutListApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_address_management/marinas/checkout?type=today&marina_id=${marinaId}`
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.methodGET
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    getPastCheckoutListing(marinaId : any,month:any,year:any,selectedDate:any) {
      this.props.showLoader();
      const headers = {
        "content-type": configJSON.ApiContentType,
        token : this.state.authToken
      };
      let url = `bx_block_reservations/visits?marina_id=${marinaId}&page=${this.state.pastCheckOutListPage+1}&per_page=${this.state.recordsPerPagepastCheckOutList}`
      if(month && year){
        url += "&month="+month+"&year="+year
      }
      if(selectedDate){
        url += "&search_date="+moment(selectedDate).format("DD/MM/YYYY")
      }
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.getPastCheckoutListApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        url
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.methodGET
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    makeCheckOut = (values: any,checkOut : any) => {
      this.props.showLoader();
      const headers = {
        "Content-Type": configJSON.ApiContentType,
        "token" : this.state.authToken
      };
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.checkOutBookingCallId = requestMessage.messageId;
      
      const httpBody = {
        "visit": 
        {
            
            "booking_id": values.id,
            "checkout_status": "completed", /** pending / completed  **/
            "checkout_date": moment(checkOut.checkOutTime,"hh:mm").format("YYYY-MM-DD hh:mm"),
            "fuellevel": checkOut.fuelLevel,
            "comments": checkOut.comment
        }
      };
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_reservations/visits/checkout`
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.methodPOST
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    
    }
}
