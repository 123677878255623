import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { dialogBoxProps  } from "../../../components/src/withDialog.web";
import { withAlertBoxProps } from "../../../components/src/withAlertBox.Web";
import { withToastProps } from "../../../components/src/withSnackBar.Web";
import { withLoaderProps } from "../../../components/src/withLoader.Web";
export const configJSON = require("./config");
import moment from "moment";
export type Props = RouterProps & dialogBoxProps & withAlertBoxProps & withToastProps & withLoaderProps & {
  id: string;
  openConfirmDialog : string;
  // Customizable Area Start
  // Customizable Area End
};

interface S {
  // Customizable Area Start
  authToken : String,
  boatId : any,
  unavailabeDate : Array<any>
  isSaveAndExit : boolean,
  shifts : Array<any>
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class BoatAvailableDateController extends BlockComponent<
  Props,
  S,
  SS
> {
  updateBoatCancelationApiCallId: String = "";
  getBoatDetailApiCallId : String = "";
  getBoatShiftApiCallId : String = "";
  deleteBoatCancelationEventApiCallId : String = ""
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
      // Customizable Area End
    ];

    this.state = {
      authToken : '',
      boatId : '',
      unavailabeDate : [],
      shifts : [],
      isSaveAndExit : false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.getBoatDetailApiCallId) {
        this.props.hideLoader();
        if (responseJson && responseJson?.data && responseJson?.data?.id && responseJson?.data?.attributes && responseJson?.data?.attributes?.unavailable_boats) {
          let unavailabeDate = responseJson?.data?.attributes?.unavailable_boats;
          unavailabeDate = unavailabeDate.map((date : any)=>{
              return {
                id : date.id,
                title  : 'Boat Not Available',
                start  : new Date(date.start_hour),
                end : new Date(date.end_hour),
                allDay : date.default_time_window === 'full_day'  ? true : false,
                window : date.default_time_window,
                isfromdatabase : true
              }
          })  
          this.setState({
            unavailabeDate : unavailabeDate
          })
        } else {
          this.parseApiErrorResponse(responseJson);
        }
      } else if (apiRequestCallId === this.updateBoatCancelationApiCallId) {
        this.props.hideLoader();
        if (responseJson && responseJson?.data && responseJson?.data?.id && responseJson?.data?.attributes) {
          this.props.showToast({
            type: "success",
            message: "Boat unavailable date save successfully",
          });
           const { history } = this.props;
           if(this.state.isSaveAndExit){
            await StorageProvider.remove("boatId")  
            history.push('/boatListingLandingPage');
          } else {
            history.push('/boatDetails');
          }
        } else {
          this.parseApiErrorResponse(responseJson);
        }
      } else if(this.deleteBoatCancelationEventApiCallId === apiRequestCallId){
        this.props.hideLoader();
        if (responseJson && responseJson?.data && responseJson?.data?.id && responseJson?.data?.attributes) {
          this.props.showToast({
            type: "success",
            message: "Boat unavailable date save successfully",
          });

        } else {
          this.parseApiErrorResponse(responseJson);
        } 
      } else if(this.getBoatShiftApiCallId === apiRequestCallId) {
        this.props.hideLoader();
        if (responseJson && responseJson?.data) {
          this.setState({
            shifts : responseJson?.data
          })
        } else {
          this.parseApiErrorResponse(responseJson);
        }
      }
    } else if (getName(MessageEnum.AlertMessage) === message.id) {
      const title = message.getData(getName(MessageEnum.AlertTitleMessage));
      var AlertBodyMessage = message.getData(
        getName(MessageEnum.AlertBodyMessage)
      );
      const msg = await this.props.showAlert({title : 'Error',message : AlertBodyMessage})
      if(msg === 'Your session has expired, please login again.' || msg === 'Invalid token'){
        await StorageProvider.remove("authToken")
        await StorageProvider.remove("accountInfo")  
        this.props.history.push("/basicAuth/login")
      }
    }
  }

  deleteEvent = (id : any) => {
    this.props.showLoader();
    const headers = {
      token : this.state.authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteBoatCancelationEventApiCallId = requestMessage.messageId;
    const httpBody = new FormData();
      
      httpBody.append(`unavailable_boats_attributes[${0}][id]`,id + "")
      httpBody.append(`unavailable_boats_attributes[${0}][_destroy]`,true + "")
      
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createBoatEndPoint}/${this.state.boatId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      (httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPUT
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
   
  updateUnavailableDate(values: any) {
    this.props.showLoader();
    const headers = {
      token : this.state.authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateBoatCancelationApiCallId = requestMessage.messageId;
    const httpBody = new FormData();
    this.state.unavailabeDate.forEach((date,index)=>{
      httpBody.append(`unavailable_boats_attributes[${index}][start_date]`,moment(date.start).format("DD/MM/YYYY"))
      httpBody.append(`unavailable_boats_attributes[${index}][end_date]`,moment(date.end).format("DD/MM/YYYY"))
      httpBody.append(`unavailable_boats_attributes[${index}][default_time_window]`,date.window)
      httpBody.append(`unavailable_boats_attributes[${index}][start_hour]`,new Date(date.start).getUTCHours() + ":"+new Date(date.start).getUTCMinutes())
      httpBody.append(`unavailable_boats_attributes[${index}][end_hour]`,new Date(date.end).getUTCHours() + ":"+new Date(date.end).getUTCMinutes())
    })
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createBoatEndPoint}/${this.state.boatId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      (httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPUT
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getBoatDetail() {
    this.props.showLoader();
    const headers = {
      "content-type": configJSON.ApiContentType,
      token : this.state.authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getBoatDetailApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getBoatDetailPoint}/${this.state.boatId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getShifts = ()=>{
    this.props.showLoader();
    const headers = {
      "content-type": configJSON.ApiContentType,
      token : this.state.authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getBoatShiftApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_reservations/shifts`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
}
