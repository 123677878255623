import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { dialogBoxProps  } from "../../../components/src/withDialog.web";
import { withAlertBoxProps } from "../../../components/src/withAlertBox.Web";
import { withToastProps } from "../../../components/src/withSnackBar.Web";
import { withLoaderProps } from "../../../components/src/withLoader.Web";
export const configJSON = require("./config");

export type Props = RouterProps & dialogBoxProps & withAlertBoxProps & withToastProps & withLoaderProps & {
  id: string;
  // Customizable Area Start
  // Customizable Area End
};

interface S {
  // Customizable Area Start
  boat_operator : String;
  boatId : String;
  authToken : String;
  boat_price_detail : any
  isSaveAndExit : boolean
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class BoatDetailsPricePageController extends BlockComponent<
  Props,
  S,
  SS
> {
  updateBoatPriceApiCallId: String = "";
  getBoatDetailApiCallId : String = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
      // Customizable Area End
    ];

    this.state = {
      boatId : "",
      authToken : "",
      boat_operator : "",
      boat_price_detail : null,
      isSaveAndExit : false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.getBoatDetailApiCallId) {
        this.props.hideLoader();
        if (responseJson && responseJson?.data && responseJson?.data?.id && responseJson?.data?.attributes && responseJson?.data?.attributes?.owner_approval_status) {
          this.setState({
            boat_operator : responseJson?.data?.attributes.boat_operator
          },()=>{
             this.setState({
               boat_price_detail : responseJson?.data?.attributes.boat_price
             })
          })
        } else {
          this.parseApiErrorResponse(responseJson);
        }
      } else if (apiRequestCallId === this.updateBoatPriceApiCallId) {
        this.props.hideLoader();
        if (responseJson && responseJson?.data && responseJson?.data?.id) {
          this.props.showToast({type : 'success',message : 'Boat Price Detail updated successfully'})
          const { history } = this.props;
          if(this.state.isSaveAndExit){
            await StorageProvider.remove("boatId")  
            history.push('/boatListingLandingPage');
          } else {
            this.props.history.push("/boatInsurance");
          }
        } else {
          this.parseApiErrorResponse(responseJson);
        }
      }
    } else if (getName(MessageEnum.AlertMessage) === message.id) {
      const title = message.getData(getName(MessageEnum.AlertTitleMessage));
      var AlertBodyMessage = message.getData(
        getName(MessageEnum.AlertBodyMessage)
      );
      const msg = await this.props.showAlert({title : 'Error',message : AlertBodyMessage})
      if(msg === 'Your session has expired, please login again.' || msg === 'Invalid token'){
        await StorageProvider.remove("authToken")
        await StorageProvider.remove("accountInfo")  
        this.props.history.push("/basicAuth/login")
      }
    }
  }
  getBoatDetail() {
    this.props.showLoader();
    const headers = {
      "content-type": configJSON.ApiContentType,
      token : this.state.authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getBoatDetailApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getBoatDetailPoint}/${this.state.boatId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  updateBoatOpertor(values: any) {
    this.props.showLoader();
    const headers = {
      "content-type": configJSON.ApiContentType,
      token : this.state.authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateBoatPriceApiCallId = requestMessage.messageId;
    const httpBody = values.isCaptionOperates ? 
    {
      "boat_price_attributes" : {
          "half_day_rate": values.is4hourcaptionamount,
          "full_day_rate": values.is8hourcaptionamount,
          "twenty_four_hours_rate": values.is24hourcaptionamount,
          "three_days_rate": values.is3dayscaptionamount,
          "seven_days_rate": values.is7dayscaptionamount,
          "fuel_policy": values.captionfuelpolicy === 'renterPays' ? 0 : 1   
      }
    } :
    {
      "boat_price_attributes" : {
          "half_day_rate": values.is4hourrenteramount,
          "full_day_rate": values.is8hourrenteramount,
          "twenty_four_hours_rate": values.is24hourrenteramount,
          "three_days_rate": values.is3daysrenteramount,
          "seven_days_rate": values.is7daysrenteramount,
          "fuel_policy": values.renterfuelpolicy === 'renterPays' ? 0 : 1   
      }
    } 
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createBoatEndPoint}/${this.state.boatId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPUT
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
}
