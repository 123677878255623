//@ts-nocheck
import React from "react";
import BoatListingLandingPageController, {
  Props,
} from "./BoatListingLandingPageController.web";
import clsx from "clsx";
import {
  withStyles,
  Grid,
  Typography,
  createStyles,
  Button,
  Paper,
  AppBar,
  Toolbar,
  Box,
  Container,
  Avatar,
} from "@material-ui/core";
import Footer from ".../../blocks/email-account-login/src/footer.web";
import Card from "@material-ui/core/Card";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import InstagramIcon from "@material-ui/icons/Instagram";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { upload,  tick, logo,boatDetail, quoteSign } from "./assets";
import { withRouter } from "react-router";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
const cards = [1, 2, 3, 4];
import withConfirmBox from "../../../components/src/withConfirmBox.Web";

class BoatListingLandingPage extends BoatListingLandingPageController {
  constructor(props: Props) {
    super(props);
  }
  async componentDidMount(){
    const authToken = await StorageProvider.get("authToken");
    if(authToken){
      this.setState({
        authToken :authToken
      },()=>{
        this.getPersonalInfo();
        this.getFrequentlyQuestions();
      })
    }
  }
  goToSelectedRoute = (routeName) => {
    const { history } = this.props;
    history.push(routeName);
  };

  render() {
    const { classes } = this.props;
    return (
      <>
        <Paper
          className={classes.mainPost}
          style={{
            backgroundImage: `url('https://source.unsplash.com/random')`,
          }}
        >
          {
            <img
              style={{ display: "none" }}
              src={"https://source.unsplash.com/random"}
              alt={"aaaaaaaaaaaaaaaa"}
            />
          }
          <div className={classes.overlay} />
          <AppBar
            position="relative"
            color="default"
            elevation={0}
            className={classes.appBar}
          >
            <Toolbar className={classes.toolbar}>
              <Grid container spacing={5}>
                <Grid xs={2} md={6} item>
                  <img onClick={()=>this.props.history.push("/")}  src={logo} style={{width:'40%'}} alt="boaturu" />
                </Grid>

                <Grid xs={12} md={6} item>
                <Grid container justify="space-between" alignItems="center">
                    <Grid item xs={2}>
                      <Typography align="center" className={classes.link} onClick={() => {
                        if(this.state.isValidSession){
                          this.goToSelectedRoute("/ownerDashboard/myBoats");
                        } else {
                          this.goToSelectedRoute("/basicAuth/login");
                        }
                        }}>
                        List your boat
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography align="center" onClick={()=>{this.props.history.push("/faq")}} className={classes.link}>
                        Support
                      </Typography>
                    </Grid>
                    {this.state.isValidSession ? <Grid item xs={2}>
                      <Typography
                        align="center"
                        onClick={() => {
                          const onClose = async (result) => {
                            if(result === 'Yes'){
                              await StorageProvider.clearStorage();
                              this.props.history.push("/")
                              window.location.reload();
                            }
                          }
                          this.props.openConfirmDialog(
                            'Logout','Are you sure you want to logout from application',onClose
                          )
                        }}
                        className={classes.link}
                      >
                        Logout
                      </Typography>
                    </Grid> : <Grid item xs={2}>
                      <Typography
                        align="center"
                        onClick={() => {
                          this.goToSelectedRoute("/basicAuth/login");
                        }}
                        className={classes.link}
                      >
                        Login
                      </Typography>
                    </Grid>}
                    {!this.state.isValidSession && 
                    <Grid item xs={2}>
                      <Button
                        variant="contained"
                        onClick={() => {
                          this.goToSelectedRoute("/basicAuth/signup");
                        }}
                        className={classes.loginButton}
                      >
                        Signup
                      </Button>
                    </Grid>
                   } 
                  </Grid>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
          
          <Grid container alignItems="center">
            <Grid item md={5}>
              <div className={classes.mainPostContent}>
                <Typography
                  style={{
                    color: "#fff",
                  }}
                  paragraph
                  gutterBottom
                >
                  EASY AND QUICK
                </Typography>
                <Typography
                  variant="h4"
                  style={{
                    color: "#fff",
                  }}
                  paragraph
                >
                  Turn your boat into an asset
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={async () => {
                    await StorageProvider.remove("boatId");
                    this.goToSelectedRoute("/boatCategory");
                  }}
                >
                  Get Started
                </Button>
              </div>
            </Grid>
          </Grid>
          <Grid
            container
            justify="space-evenly"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={12} md={3}>
              <div className={classes.imagetxtcontainer}>
                <img src={tick} />
                <Typography
                  display="inline"
                  className={classes.leftPageHeading}
                >
                  Charge What You want
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} md={3}>
              <div className={classes.imagetxtcontainer}>
                <img src={tick} />
                <Typography
                  display="inline"
                  className={classes.leftPageHeading}
                >
                  Verified Renters
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} md={3}>
              <div className={classes.imagetxtcontainer}>
                <img src={tick} />
                <Typography
                  display="inline"
                  className={classes.leftPageHeading}
                >
                  Get Paid Quickly
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Paper>

        {/* Process */}
        <Box pt={5} />
        <Grid
          container
          justify="space-evenly"
          spacing={2}
          className={classes.contentContainer}
        >
          <Grid item xs={11}>
            <Typography
              variant="subtitle2"
              color="primary"
              gutterBottom
              className={classes.smallHeading}
            >
              PROCESS
            </Typography>
          </Grid>
          <Grid item xs={11}>
            <Typography variant="h4" gutterBottom className={classes.headers}>
              How to list your boat
            </Typography>

            <Box pt={5} />
          </Grid>

          <Grid container item xs={11} justify="space-evenly" spacing={2}>
            <Grid item xs={12} md={7}>
              <Box position="relative" mb={16}>
                <img
                  src={boatDetail}
                  ref={this.imgRef}
                  className={classes.listBoatImage1}
                />
                <img src={boatDetail} className={classes.listBoatImage2} />
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={5}
              container
              spacing={2}
              // style={{ marginLeft: "18px" }}
              justify="space-between"
            >
              <Grid item xs={12}>
                <Box display="flex">
                  <Box>
                    <Typography className={classes.numberIcon}>1</Typography>
                  </Box>
                  <Box>
                    <Typography variant="h6" color="primary">
                      List Your Boat
                    </Typography>
                    <Typography
                      display="inline"
                      className={classes.listInsurance}
                    >
                      Sample text Clean and refuel your boat before the next
                      trip so that its a enjoyable experience for the next guest
                    </Typography>
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Box display="flex">
                  <Box>
                    <Typography className={classes.numberIcon}>2</Typography>
                  </Box>
                  <Box>
                    <Typography variant="h6" color="primary">
                      Recieve approval
                    </Typography>
                    <Typography
                      display="inline"
                      className={classes.listInsurance}
                    >
                      Sample text Clean and refuel your boat before the next
                      trip so that its a enjoyable experience for the next guest
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor
                    </Typography>
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Box display="flex">
                  <Box>
                    <Typography className={classes.numberIcon}>3</Typography>
                  </Box>
                  <Box>
                    <Typography variant="h6" color="primary">
                      Connect with first renter
                    </Typography>
                    <Typography
                      display="inline"
                      className={classes.listInsurance}
                    >
                      Sample text Clean and refuel your boat before the next
                      trip so that its a enjoyable experience for the next guest
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* Benefits */}
        <Box pt={1} />
        <Grid
          container
          justify="center"
          spacing={1}
          className={clsx(`${classes.cardGrid} ${classes.contentContainer}`)}
        >
          <Grid item xs={11}>
            <Typography
              variant="subtitle2"
              color="primary"
              className={classes.smallHeading}
            >
              BENEFITS
            </Typography>
          </Grid>
          <Grid item xs={11}>
            <Box mb={6}>
              <Typography variant="h4" className={classes.headers}>
                Why list your boat on Boaturu ?
              </Typography>
            </Box>
          </Grid>

          <Grid container item xs={11} spacing={4}>
            {[1, 2, 3, 4, 5, 6].map((card) => (
              <Grid item key={card} xs={12} sm={6} md={4}>
                <Card className={classes.card}>
                  {/* <CardMedia
                    className={classes.cardMedia}
                    image="https://source.unsplash.com/random"
                    title="Image title"
                  /> */}
                  <CardContent className={classes.cardContent}>
                    <Box className={classes.cardLike}>
                      <ThumbUpIcon fontSize="large" />
                    </Box>
                    <Box my={3}>
                      <Typography
                        color="primary"
                        gutterBottom
                        variant="h5"
                        component="h2"
                        className={classes.montserrat}
                        style={{ fontWeight: 500 }}
                      >
                        Verified Renters
                      </Typography>
                    </Box>
                    <Typography lassName={classes.montserrat}>
                      We verify all the renters before booking to avoid any
                      problem while check in.
                    </Typography>
                  </CardContent>
                  {/* <CardActions>
                    <Button size="small" color="primary">
                      View
                    </Button>
                    <Button size="small" color="primary">
                      Edit
                    </Button>
                  </CardActions> */}
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>

        <Grid
          container
          justify="space-evenly"
          className={`${classes.contentContainer} ${classes.contentPadding}`}
        >
          <Grid container xs={11} spacing={2}>
            <Grid item xs={12} md={6}>
              <Box pr={{ xs: 0, md: 20 }}>
                <Typography variant="h4" className={classes.headers}>
                  Boaturu lets you rent your boat with ease.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography
                variant="h6"
                color="primary"
                className={classes.boaturuDesc}
              >
                We provide an easy platform to rent and hire boats and yatches.
              </Typography>
              <Typography
                variant="h6"
                color="primary"
                className={classes.boaturuDesc}
              >
                We truly care about out customers and stive to serve a better
                qualtity experience and help the community towards a better
                goal.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Box mt={0} />
        {/* Customer feedback */}
        <Grid
          container
          spacing={2}
          justify="space-evenly"
          className={classes.contentContainer}
        >
          <Grid container xs={11} spacing={2} justify="space-between">
            <Grid item xs={12}>
              <img src={quoteSign} className={classes.customIcon} />
            </Grid>
            <Grid item xs={12} md={7} justify="center">
              <Box ml={{ xs: 0, md: 10 }} mt={2}>
                <Box mb={2}>
                  <Typography
                    variant="h4"
                    color="primary"
                    className={classes.headers}
                    gutterBottom
                  >
                    Float seems to provide a great service to earn upto a couple
                    hundered dollars in a day. That's quite amazing!.
                  </Typography>
                </Box>
                <Box display="flex">
                  <Box>
                    <Typography>-</Typography>
                  </Box>
                  <Box>
                    <Typography
                      vraint="body1"
                      className={classes.montserrat}
                      style={{ fontWeight: 500 }}
                    >
                      John Doe
                    </Typography>
                    <Typography variant="body1" className={classes.montserrat}>
                      Bussiness Owner in Miami
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} md={4}>
              <Box ml={3} my={2}>
                <img
                  src={boatDetail}
                  className={clsx(
                    `${classes.customImage} ${classes.roundCorner}`
                  )}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>

        {/* Divider */}
        <Grid container className={classes.contentContainer} justify="center">
          <Grid item container xs={6} justify="space-around">
            <Grid item xs={5} className={classes.borderBott} />
            <Grid item xs={2}>
              <Box
                textAlign="center"
                position="relative"
                top="10px"
                left="-2px"
              >
                <img src={upload} />
              </Box>
            </Grid>
            <Grid item xs={5} className={classes.borderBott} />
          </Grid>
        </Grid>

        {/* Frequently asked Questions */}
        <Grid
          container
          justify="center"
          spacing={2}
          className={classes.contentContainer}
        >
          <Grid item xs={10} justify="space-evenly" spacing={3} container>
            <Grid item xs={12}>
              <Typography
                variant="h5"
                align="center"
                className={classes.headers}
              >
                Frequently Asked Questions
              </Typography>
            </Grid>
            {this.state.FAQS && this.state.FAQS.length > 0 ? this.state.FAQS.map((faq)=>{return <Grid key={faq.id} item xs={12} md={5}>
              <Typography
                gutterBottom
                className={classes.askedQuestions}
                align="center"
              >
                {faq?.attributes.question}
              </Typography></Grid>}) : null}
            </Grid>
        </Grid>

        {/* Boat Asset  */}
        <Grid
          container
          justify="center"
          spacing={0}
          className={`${classes.contentContainer} ${classes.contentPadding}`}
        >
          <Grid item xs={12} md={11}>
            <Paper
              className={classes.mainPost}
              style={{
                backgroundImage: `url('https://source.unsplash.com/random')`,
              }}
            >
              {
                <img
                  style={{ display: "none" }}
                  src={"https://source.unsplash.com/random"}
                  alt={"aaaaaaaaaaaaaaaa"}
                />
              }
              <div className={classes.overlay} />
              <Grid container alignItems="center">
                <Grid item md={5}>
                  <div className={classes.mainPostContent}>
                    <Typography
                      variant="h4"
                      style={{
                        color: "#fff",
                      }}
                    >
                      Get started
                    </Typography>
                    <Typography
                      variant="h4"
                      style={{
                        color: "#fff",
                      }}
                      paragraph
                    >
                      with your first renter!
                    </Typography>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.listButton}
                      onClick={() => {
                        this.goToSelectedRoute("/boatCategory");
                      }}
                    >
                      List my boat
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        <Footer></Footer>
         </>
    );
  }
}
const BoatListingLandingPageWithRouter = withRouter(BoatListingLandingPage);
const BoatListingLandingPageAlertBox = withAlertBox(
  BoatListingLandingPageWithRouter
);
const BoatListingLandingPageLoader = withLoader(BoatListingLandingPageAlertBox);
const BoatListingLandingPageToast = withToast(BoatListingLandingPageLoader);
export default withStyles((theme) =>
  createStyles({
    mainPost: {
      position: "relative",
      backgroundColor: theme.palette.grey[800],
      color: theme.palette.common.white,
      marginBottom: theme.spacing(4),
      backgroundImage: "url(https://source.unsplash.com/random)",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
    },
    overlay: {
      position: "absolute",
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      backgroundColor: "rgba(0,0,0,.3)",
    },
    mainPostContent: {
      position: "relative",
      padding: theme.spacing(3),
      paddingTop: theme.spacing(10),
      color: "#FFF",
      [theme.breakpoints.up("md")]: {
        padding: theme.spacing(6),
        paddingTop: theme.spacing(10),
        paddingRight: 0,
      },
    },
    appBar: {
      background: "transparent",
    },
    toolbar: {
      flexWrap: "wrap",
    },
    toolbarTitle: {
      flexGrow: 1,
    },
    link: {
      margin: theme.spacing(1, 1.5),
      color: "#FFF",
    },
    imagetxtcontainer: {
      display: "flex",
      alignItems: "center",
      flexDirection: "row",
      marginLeft: "25px",
    },
    leftPageHeading: {
      fontFamily: "Montserrat",
      fontSize: "20px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.21,
      letterSpacing: "normal",
      color: "#fff",
      paddingLeft: "8px",
    },
    image: {
      position: "relative",
      backgroundImage: `url(${boatDetail})`,
      backgroundRepeat: "no-repeat",
      backgroundColor:
        theme.palette.type === "light"
          ? theme.palette.grey[50]
          : theme.palette.grey[900],
      backgroundSize: "cover",
      backgroundPosition: "center",
    },
    listInsurance: {
      fontFamily: "Montserrat",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "normal",
      color: "#343a40",
      textAlign: "center",
    },
    listnumber: {
      fontFamily: "Montserrat",
      fontSize: "32px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.22,
      letterSpacing: "normal",
      color: "#0174cf",
      textAlign: "center",
    },
    cardGrid: {
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(8),
      backgroundColor: "#EFF3FC",
      marginTop: "24px",
    },
    card: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
    },
    cardLike: {
      display: "inline-block",
      padding: "8px",
      color: "#0174CF",
      backgroundColor: "#E5F1FA",
      borderRadius: "5px",
    },
    cardContent: {
      flexGrow: 1,
      paddingLeft: "40px",
      padding: "32px",
    },
    mediaButtonBox: {
      paddingTop: "1.8rem",
      [theme.breakpoints.down("sm")]: {
        paddingTop: "0.8rem",
      },
    },
    footerLinkHeading: {
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.15,
      marginBottom: "0.9rem",
      marginTop: "2rem",
      letterSpacing: "normal",
      color: "#545454",
    },
    footerLink: {
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.15,
      letterSpacing: "normal",
      color: "#545454",
    },
    footer: {
      // padding: theme.spacing(0, 3.5),
      paddingTop: "50px",
      backgroundColor: "#f6f6f6",
    },
    footerTable: {
      [theme.breakpoints.up("sm")]: {
        display: "block",
      },
    },
    listButton: {
      fontFamily: "Montserrat",
      borderRadius: "3px",
      backgroundColor: "#FFFFFF",
      borderWidth: "2px",
      color: "#0174cf",
    },
    contentContainer: {
      marginBottom: "130px !important",
    },

    headers: {
      fontFamily: "Montserrat",
      fontWeight: 600,
      lineHeight: "45px",
    },
    askedQuestions: {
      fontFamily: "Montserrat",
      margin: "0.5rem 0rem",
      fontWeight: 500,
      lineHeight: "40px",
      cursor : 'pointer'
    },
    numberIcon: {
      display: "flex",
      margin: "0 10px",
      justifyContent: "center",
      alignItems: "center",
      color: "white",
      backgroundColor: "#00A1DF",
      height: "2.5em",
      fontFamily: "Montserrat",
      width: "2.5em",
      fontSize: "1rem",
      borderRadius: "50%",
      border: "5px #CCE3F6 solid",
    },
    customImage: {
      width: "200px",
      height: "200px",
    },
    customIcon: {
      height: "100px",
      width: "128px",
    },
    roundCorner: {
      borderRadius: "5px",
    },
    smallHeading: {
      fontFamily: "Montserrat",
      fontWeight: 600,
      color: "#0FA6E0",
    },
    boaturuDesc: {
      fontFamily: "Montserrat",
      fontWeight: 500,
    },
    listBoatImage1: {
      width: "100%",

      borderRadius: "5px",
      [theme.breakpoints.up("sm")]: {
        width: "70%",
      },
    },
    listBoatImage2: {
      width: "170px",
      position: "absolute",
      // left: "317px",
      // bottom: "-71px",
      borderRadius: "5px",
      [theme.breakpoints.down("sm")]: {
        left: "0px",
        bottom: "20px",
      },
      [theme.breakpoints.up("sm")]: {
        left: "auto",
        right: "15%",
        bottom: "-10%",
      },
      [theme.breakpoints.up("md")]: {
        right: "10%",
        left: "auto",
        bottom: "-10%",
      },
      [theme.breakpoints.up("lg")]: {
        right: "15%",
        left: "auto",
        bottom: "-7%",
      },
    },
    montserrat: {
      fontFamily: "Montserrat",
    },
    divider: {
      margin: "0 20%",
      backgroundColor: "#2085D5",
    },
    borderBott: {
      borderBottom: "1px blue solid",
    },
    contentPadding: {
      paddingTop: "50px",
    },
    link: {
      fontFamily: "Montserrat",
      fontSize: "14px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "normal",
      color: "#FFF",
      cursor: "pointer",
    },
    loginButton: {
      backgroundColor: "#fff",
      color: "blue",
      fontFamily: "Montserrat",
      fontSize: "12px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
      color: "#1565d8",
      borderRadius:"5px"
    },
  })
)(withConfirmBox(BoatListingLandingPageToast));
