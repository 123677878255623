//@ts-nocheck
import React from "react";
import MarinaDashboardCheckOutController, {
  Props,
} from "./MarinaDashboardCheckOutController.web";
import "date-fns";
import { withStyles, createStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import {
  Grid,
  Avatar,
  Container,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Collapse,
  TableCell,
  Paper,
  TextField,
  TablePagination,
  IconButton
} from "@material-ui/core/";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { boatImage } from "./assets";
import { boatImage1 } from "./assets";
import DeleteIcon from "@material-ui/icons/Delete";
import Button from "@material-ui/core/Button";
import Pagination from "@material-ui/lab/Pagination";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
import withDialog from "../../../components/src/withDialog.web";
import { withRouter } from "react-router";
import BookingDetailPopup from "./BookingDetailPopup.web";
import RejectBoatRequest from "./RejectBoatRequest.web";
import moment from "moment";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { DatePicker } from "@material-ui/pickers";


interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}


const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const a11yProps = (index: any) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const InnerTableCell = withStyles({
  root: {
    borderBottom: "0px",
    fontFamily: "Montserrat",
    fontSize: "1rem",
    fontWeight: 500,
    lineHeight: 1.56,
    color: "#343a40",
  },
})(TableCell);

class BookingDetailRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = { open: false,fuelLevel : "",checkOutTime : "",comment : "" };
  }

  render() {
    const { classes, data,makeCheckOut } = this.props;
    return (
      <>
        <Box className={classes.box} py={3} key={data.id}>
          <Grid
            container
            spacing={3}
            pl={0}
            onClick={() => this.setState({ open: true })}
          >
            <Grid item xs={12} md={5}>
              <img
                src={data?.attributes?.boat_image}
                width="100%"
                className={classes.boatImage}
              />
            </Grid>
            <Grid container item xs={12} md={7}>
              <Box pb={3}>
                <Typography variant="h5" className={classes.blueHeading}>
                {data.attributes?.boat_name || "Not Available"}
                </Typography>
                <Typography variant="h7">{`${data.attributes?.boat_location?.address1 || "Not Available"},${data.attributes?.boat_location?.city || "Not Available"}`}</Typography>
              </Box>

              <Grid container item>
                <Grid item xs={5}>
                  <Typography variant="h7" className={classes.detailName}>
                    Rented By
                  </Typography>
                  <Box>
                    <Box display="inline-block" pt={1}>
                    <Avatar component="span" src={data?.attributes?.renter?.avatar?.url} className={classes.avatar} />
                    </Box>
                    <Box display="inline-block" className={classes.textCentre}>
                      <Typography variant="h7" component="div">
                      {(data?.attributes?.renter?.first_name || "Not Available") + " " + (data?.attributes?.renter?.last_name || "Not Available")}
                      </Typography>
                      <Typography
                        variant="h7"
                        component="div"
                        className={classes.blueDetails}
                      >
                        Call Renter
                      </Typography>
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={7}>
                  {data?.attributes?.booking_details?.data?.attributes?.approval_status === "pending" ? (
                    <Typography variant="h7" className={classes.pendingStatus}>
                        <ErrorIcon /> Approval Pending
                     </Typography>
                  ) : (
                    <Typography variant="h7" className={classes.approvalStatus}>
                      <CheckCircleIcon /> Approved by Owner
                    </Typography>
                  )}

                  <Box>
                    <Box display="inline-block" pt={1}>
                         <Avatar component="span" src={data?.attributes?.boat_owner?.avatar?.url} className={classes.avatar} />
                    </Box>
                    <Box display="inline-block" className={classes.textCentre}>
                      <Typography variant="h7" component="div">
                      {(data?.attributes?.boat_owner?.first_name || "Not Available") + " " + (data?.attributes?.boat_owner?.last_name || "Not Available") }
                      </Typography>
                      <Typography
                        variant="h7"
                        component="div"
                        className={classes.blueDetails}
                      >
                        Call Owner
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12}>
                  <Typography variant="h7" className={classes.detailName}>
                    Payment Status
                  </Typography>
                  <Box pt={1}>
                    {data?.attributes?.booking_details?.data?.attributes?.payment_status === "paid" ? (
                      <Typography
                        component="span"
                        size="small"
                        className={classes.paymentPaid}
                      >
                        PAID
                      </Typography>
                    ) : (
                      <Typography
                        component="span"
                        size="small"
                        className={classes.paymentUnpaid}
                      >
                        UNPAID
                      </Typography>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <InnerTableCell className={classes.extraDetails} colSpan={6}>
            <Collapse in={this.state.open} timeout="auto" unmountOnExit>
              <Box py={3.75} className={classes.tripDetailsBox}>
                <Grid container item xs={12} spacing={2}>
                  <Grid container item xs={12} sm={2}>
                    <Typography className={classes.tripDetailsTitle}>
                      Trip Details
                    </Typography>
                  </Grid>
                  <Grid item container xs={12} sm={4} spacing={2}>
                    <Grid container item xs={6}>
                      <Box
                        display="grid"
                        alignContent="space-between"
                        spacing={2}
                      >
                        <Box>
                          <Typography
                            className={classes.tripDetailsHead}
                            gutterBottom
                          >
                            Date:
                          </Typography>
                          <Typography
                            className={classes.tripDetailsHead}
                            gutterBottom
                          >
                            Passengers:
                          </Typography>
                          <Typography
                            className={classes.tripDetailsHead}
                            gutterBottom
                          >
                            Duration:
                          </Typography>
                          <Typography
                            className={classes.tripDetailsHead}
                            gutterBottom
                          >
                            Start Time:
                          </Typography>
                          {data?.attributes?.booking_details?.data?.attributes.captain_included === true ? (
                            <Typography
                              className={classes.tripDetailsHead}
                              gutterBottom
                            >
                              Captain Included
                            </Typography>
                          ) : <Typography
                          className={classes.tripDetailsHead}
                          gutterBottom
                        >
                          No Captain Included
                        </Typography>}
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        className={classes.tripDetailsText}
                        gutterBottom
                      >
                        {moment(data?.attributes?.booking_details?.data?.attributes?.created_at).format("DD/MM/YYYY")}
                      </Typography>
                      <Typography
                        className={classes.tripDetailsText}
                        gutterBottom
                      >
                        {data?.attributes?.booking_details?.data?.attributes?.passengers ? data?.attributes?.booking_details?.data?.attributes?.passengers : "0"}
                      </Typography>
                      <Typography
                        className={classes.tripDetailsText}
                        gutterBottom
                      >
                        {data?.attributes?.booking_details?.data?.attributes?.trip_duration ? data?.attributes?.booking_details?.data?.attributes?.trip_duration : "0"}
                      </Typography>
                      <Typography
                        className={classes.tripDetailsText}
                        gutterBottom
                      >
                        {moment(data?.attributes?.booking_details?.data?.attributes?.start_time).format("hh:mm A")}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid item container xs={12} sm={6} spacing={2}>
                    <Grid container justify="flex-end" item xs={12} spacing={0}>
                      <Button
                        onClick={() => this.setState({ open: false })}
                        className={classes.minimize}
                      >
                        See less ^
                      </Button>
                    </Grid>
                    <Grid item xs={7}>
                    {data.attributes.booking_details.data.attributes.checkin_status === 'checkin' && 
                      <>
                      <Typography
                        className={classes.tripDetailsHead}
                        gutterBottom
                      >
                        Fuel level at the trip end
                      </Typography>
                      <Box>
                        <Paper elevation={1}>
                          {" "}
                          <TextField
                            variant="outlined"
                            fullWidth
                            value={this.state.fuelLevel}
                            onChange={(evt)=>{this.setState({
                              fuelLevel : evt.target.value
                            })}}
                            name="fuelLevel"
                            placeholder="Please fill the details before checking out"
                          />
                        </Paper>
                      </Box>
                      </>}
                    </Grid>
                    <Grid item xs={5}>
                    {data.attributes.booking_details.data.attributes.checkin_status === 'checkin' && <>
                      <Typography
                        className={classes.tripDetailsHead}
                        gutterBottom
                      >
                        Check out time
                      </Typography>
                        <TextField
                          id="time"
                          type="time"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={this.state.checkOutTime}
                            onChange={(evt)=>{this.setState({
                              checkOutTime : evt.target.value
                            })}}
                            name="checkOutTime"
                          inputProps={{
                            step: 300, // 5 min
                          }}
                          placeholder="select time"
                        />
                      </>
                      }
                    </Grid>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <Box style={{ display: "inline-block" }} pr={3}>
                      <Typography
                        className={classes.tripDetailsHead}
                        gutterBottom
                      >
                        Checkout Date
                      </Typography>
                      <Typography
                        className={classes.tripDetailsText}
                        gutterBottom
                      >
                        {moment(data?.attributes?.booking_details?.data?.attributes?.trip_date).format("DD/MM/YYYY")}
                      </Typography>
                      <Typography
                        className={classes.tripDetailsText}
                        gutterBottom
                      >
                        {moment(data?.attributes?.booking_details?.data?.attributes?.start_time).format("hh:mm A")}
                      </Typography>
                    </Box>

                    <Box style={{ display: "inline-block" }}>
                      <Typography
                        className={classes.tripDetailsHead}
                        gutterBottom
                      >
                        Checkin date
                      </Typography>
                      <Typography
                        className={classes.tripDetailsText}
                        gutterBottom
                      >
                        {data?.attributes?.booking_details?.data?.attributes?.end_time  ? moment(data?.attributes?.booking_details?.data?.attributes?.end_time).format("DD/MM/YYYY") : "Not Available"}
                      </Typography>
                      <Typography
                        className={classes.tripDetailsText}
                        gutterBottom
                      >
                        {data?.attributes?.booking_details?.data?.attributes?.end_time ? moment(data?.attributes?.booking_details?.data?.attributes?.end_time).format("hh:mm A") : "Not Available"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
              {data.attributes.booking_details.data.attributes.checkin_status === 'checkin' && <Box>
                      <Typography
                        className={classes.tripDetailsHead}
                        gutterBottom
                      >
                        Comments
                      </Typography>

                      <Box>
                        <Paper elevation={1}>
                          <TextField
                            variant="outlined"
                            fullWidth
                            value={this.state.comment}
                            onChange={(evt)=>{this.setState({
                              comment : evt.target.value
                            })}}
                            name="comment"
                            multiline
                            rows={6}
                          />
                        </Paper>
                      </Box>
                      <Box pt={1} display="flex" justifyContent="flex-end">
                        <Button
                          color="primary"
                          variant="contained"
                          className={classes.roundButtons}
                          disabled={data.attributes.booking_details.data.attributes.approval_status !== 'approved' || data.attributes.booking_details.data.attributes.checkin_status === 'checkout'}
                          onClick={()=>{makeCheckOut(data?.attributes?.booking_details?.data?.attributes,this.state)}}
                        >
                          Check out
                        </Button>
                      </Box>
                    </Box>
               }     
                  </Grid>
                </Grid>
              </Box>
            </Collapse>
          </InnerTableCell>
        </Box>
      </>
    );
  }
}
class MarinaDashboardCheckOut extends MarinaDashboardCheckOutController {
  constructor(props: Props) {
    super(props);
  }
  handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({ selectedBookingType: newValue },()=>{
      if(this.state.selectedBookingType === 'PAST'){
        this.getPastCheckoutListing(this.state.marinaId,new Date().getMonth()+1,new Date().getFullYear(),null)
      } else if(this.state.selectedBookingType === 'UPCOMING'){
        this.getUpcomingCheckoutListing(this.state.marinaId);
      } else if(this.state.selectedBookingType === 'TODAY'){
        this.getTodayCheckoutListing(this.state.marinaId);
      }
    });
  };
  async componentDidMount() {
    const authToken = await StorageProvider.get("authToken");
    const marinaId = this.props?.marinaId || await StorageProvider.get("marinaId");
    if (authToken && marinaId) {
      this.setState(
        {
          authToken: authToken,
          marinaId : marinaId
        },
        () => {
          this.getPastCheckoutListing(this.state.marinaId,new Date(this.state.selectedMonth).getMonth()+1,new Date(this.state.selectedMonth).getFullYear(),this.state.selectedDate)
          this.getUpcomingCheckoutListing(marinaId);
          this.getTodayCheckoutListing(marinaId);
        }
      );
    }
  }
  handlePastCheckOutPage = (event: React.ChangeEvent<unknown>, value: number) => {
    this.setState(
      {
        pastCheckOutListPage: value,
      },
      () => {
        this.getPastCheckoutListing(this.state.marinaId,new Date(this.state.selectedMonth).getMonth()+1,new Date(this.state.selectedMonth).getFullYear(),this.state.selectedDate)
      }
    );
  };
  handlePastCheckOutPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    this.setState(
      {
        recordsPerPagepastCheckOutList: parseInt(event.target.value, 10),
        pastCheckOutListPage: 0,
      },
      () => {
        this.getPastCheckoutListing(this.state.marinaId,new Date(this.state.selectedMonth).getMonth()+1,new Date(this.state.selectedMonth).getFullYear(),this.state.selectedDate)
      }
    );
  };

  render() {
    const { classes } = this.props;
    
    return (
      <Container maxWidth="lg">
        <Box pt={5} pb={2}>
          <Typography variant="h4">Check out</Typography>
        </Box>
        <Grid container>
          <Grid item xs={12} md={10}>
            <AppBar position="static" className={classes.removeBoxShadow}>
              <Tabs
                value={this.state.selectedBookingType}
                onChange={this.handleChange}
                aria-label="simple tabs example"
                className={classes.tabPanelcolor}
              >
                <Tab value="TODAY" label="TODAY" {...a11yProps(0)} />
                <Tab value="UPCOMING" label="UPCOMING" {...a11yProps(1)} />
                <Tab value="PAST" label="PAST" {...a11yProps(2)} />
              </Tabs>
            </AppBar>
            <TabPanel
              value={this.state.selectedBookingType}
              index={"TODAY"}
              className={classes.removePadding}
            >
              {this.state.todayCheckOutList.length > 0 ? this.state.todayCheckOutList.map((data, ind) => (
                <BookingDetailRow
                  key={ind}
                  data={data}
                  classes={classes}
                  checkInStatus={true}
                  makeCheckOut={this.makeCheckOut}
                />
              )) : <div><Typography align="center" variant="h4" color="textPrimary">No Booking Available</Typography></div>}
            </TabPanel>
            <TabPanel value={this.state.selectedBookingType} index={"UPCOMING"}>
              {this.state.upcomingCheckoutList.length > 0 ? this.state.upcomingCheckoutList.map((data, ind) => (
                <BookingDetailRow
                  key={ind}
                  data={data}
                  classes={classes}
                  checkInStatus={false}
                  makeCheckOut={this.makeCheckOut}
                />
              )) : <div><Typography align="center" variant="h4" color="textPrimary">No Booking Available</Typography></div>}
            </TabPanel>
            <TabPanel
              value={this.state.selectedBookingType}
              index={"PAST"}
              className={classes.removePadding}
            >
              <Box pt={3}>
                <Grid container item xs={12} alignItems="center">
                  <Grid item xs={3}>
                    <Box spacing={1}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        variant="inline"
                        openTo="year"
                        views={["year", "month"]}
                        label="Year and Month"
                        maxDate={new Date()}
                        value={this.state.selectedMonth}
                        onChange={(date)=>{this.setState({
                          selectedMonth : date,
                          selectedDate : null
                        },()=>{
                          this.getPastCheckoutListing(this.state.marinaId,new Date(this.state.selectedMonth).getMonth()+1,new Date(this.state.selectedMonth).getFullYear(),null)
                        })}}
                      />
                     </MuiPickersUtilsProvider>
                    </Box>
                  </Grid>
                  <Grid item xs={9}>
                    <Box
                      display="flex"
                      justifyContent="flex-end"
                      alignItems="center"
                    >
                      <Box mr={1}>
                        {" "}
                        <Typography variant="h7" component="span">
                          Select Date
                        </Typography>
                      </Box>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Box container>
                          <DatePicker
                            margin="normal"
                            id="date-picker-dialog"
                            format="MM/dd/yyyy"
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                            placeholder="Select Date"
                            maxDate={new Date()}
                            value={this.state.selectedDate}
                            onChange={(date)=>{this.setState({
                              selectedDate : date,
                              selectedMonth : null
                            },()=>{
                              this.getPastCheckoutListing(this.state.marinaId,new Date(this.state.selectedMonth).getMonth()+1,new Date(this.state.selectedMonth).getFullYear(),null)
                            })}}
                          />
                        </Box>
                      </MuiPickersUtilsProvider>
                      <Button>Search</Button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>

              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Boat</TableCell>
                      <TableCell align="center">Renter</TableCell>
                      <TableCell align="center">Checkin</TableCell>
                      <TableCell align="center">Checkout</TableCell>
                      <TableCell align="center">Rent</TableCell>
                      <TableCell align="center">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.pastCheckOutList.length > 0 ?  this.state.pastCheckOutList.map((item, ind) => (
                      <TableRow key={ind}>
                        <TableCell align="center">{`${item.attributes?.boat || "-"}`}</TableCell>
                        <TableCell align="center">{`${item.attributes?.renter || "-"}`}</TableCell>
                        <TableCell align="center">
                        {(item?.attributes.checkin_date &&  moment(item?.attributes.checkin_date).format("DD/MM/YY")) || "-"}
                        </TableCell>
                        <TableCell align="center">
                          {(item?.attributes.checkout_date &&  moment(item?.attributes.checkout_date).format("DD/MM/YY")) || "-"}
                        </TableCell >
                        <TableCell align="center">{item.rentAmount}</TableCell>
                        <TableCell align="center">
                            <IconButton onClick={(event) => {
                                  this.setState({anchorEl : (event.currentTarget)})
                                }}>
                                 <MoreVertIcon></MoreVertIcon>
                            </IconButton>
                            <Menu
                                id="simple-menu"
                                anchorEl={this.state.anchorEl}
                                keepMounted
                                open={Boolean(this.state.anchorEl)}
                                onClick={(event) => {
                                  this.setState({anchorEl : null})
                                }}
                              >
                                <MenuItem onClick={(event) => {
                                  this.setState({anchorEl : null})
                                }}>Booking History</MenuItem>
                                <MenuItem onClick={(event) => {
                                  this.setState({anchorEl : null})
                                }}>Download </MenuItem>
                                <MenuItem onClick={(event) => {
                                  this.setState({anchorEl : null})
                                }}>Boat Details</MenuItem>
                              </Menu>
                        </TableCell>
                      </TableRow>
                    )) : <TableRow>
                        <TableCell colSpan={6} align="center">
                            <Typography variant="h5">No booking available</Typography>
                        </TableCell>
                      </TableRow>}
                  </TableBody>
                </Table>
                <TablePagination
                            component="div"
                            align="right"
                            count={this.state.pastCheckOutListTotalCount}
                            page={this.state.pastCheckOutListPage}
                            onChangePage={this.handlePastCheckOutPage}
                            rowsPerPage={this.state.recordsPerPagepastCheckOutList}
                            onChangeRowsPerPage={this.handlePastCheckOutPerPage}
                          />
              </TableContainer>
            </TabPanel>
          </Grid>
        </Grid>
      </Container>
    );
  }
}
const MarinaDashboardCheckOutWithRouter = withRouter(MarinaDashboardCheckOut);
const MarinaDashboardCheckOutAlertBox = withAlertBox(
  MarinaDashboardCheckOutWithRouter
);
const MarinaDashboardCheckOutLoader = withLoader(
  MarinaDashboardCheckOutAlertBox
);
const MarinaDashboardCheckOutToast = withToast(MarinaDashboardCheckOutLoader);
export default withStyles((theme) =>
  createStyles({
    box: {
      border: "1px #dee2e3",
      borderStyle: "none none solid none",
    },
    removePadding: {
      margin: "-24px",
    },
    blueHeading: {
      fontFamily: "Montserrat",
      color: "#004277",
      fontWeight: 600,
    },
    smallText: {
      fontFamily: "Montserrat",
      color: "#343a40",
    },
    amount: {
      fontFamily: "Montserrat",
      fontSize: "2rem",
      fontWeight: 600,
      color: "#000000",
    },
    detailName: {
      fontFamily: "Montserrat",
      fontSize: "1rem",
      fontWeight: 600,
      color: "#343a40",
    },
    blueDetails: {
      fontFamily: "Montserrat",
      fontSize: "0.875rem",
      color: "#0174cf",
    },
    paidStatus: {
      fontFamily: "Montserrat",
      color: "#b8b8b8",
    },
    timeDetails: {
      fontFamily: "Montserrat",
      fontSize: "0.875rem",
      color: "#343a40",
    },
    tabPanelcolor: {
      fontFamily: "Montserrat",
      backgroundColor: "white",
      color: "black",
    },
    approveButton: {
      fontFamily: "Montserrat",
      borderRadius: "5px",
    },
    roundButtons: {
      fontFamily: "Montserrat",
      borderRadius: "5px",
      border: "solid 2px #0174cf",
    },
    avatar: {
      fontFamily: "Montserrat",
      height: "60px",
      width: "60px",
    },
    removeBoxShadow: {
      fontFamily: "Montserrat",
      boxShadow: "none",
      border: "1px #dee2e3",
      borderStyle: "none none solid none",
    },
    selected: {
      fontFamily: "Montserrat",
      opacity: 1.0,
      color: "black",
    },
    notselected: {
      fontFamily: "Montserrat",
      opacity: 0.3,
      color: "#b8b8b8",
    },
    boatImage: {
      height: "280px",
    },
    trashbin: {
      opacity: 0.5,
    },
    textCentre: {
      verticalAlign: "text-bottom",
      "@media only screen and (min-width: 768px)": {
        paddingLeft: "21px",
      },
    },
    deleteButton: {
      color: theme.palette.error.main,
    },
    approvalStatus: {
      fontSize: "0.875rem",
      fontFamily: "Montserrat",
      color: "#00a676",
    },
    pendingStatus: {
      fontSize: "0.875rem",
      fontFamily: "Montserrat",
      color: "#e9724c",
    },
    tripDetailsBox: {
      margin: "0 -16px",
    },
    tripDetailsTitle: {
      paddingTop: "0.25rem",
      color: "#004277",
      fontFamily: "Montserrat",
      fontSize: "1.25rem",
      fontWeight: 600,
      lineHeight: 1.56,
    },
    tripDetailsHead: {
      fontFamily: "Montserrat",
      fontSize: "1rem",
      fontWeight: 600,
      color: "#343a40",
      lineHeight: 2,
    },
    tripDetailsText: {
      fontFamily: "Montserrat",
      fontSize: "1rem",
      fontWeight: 500,
      color: "#343a40",
      lineHeight: 2,
    },
    paymentPaid: {
      backgroundColor: "#b9e6d9",
      padding: "5px",
      color: "#00a676",
      borderRadius: "5px",
      border: "solid 2px #00a676",
    },
    paymentUnpaid: {
      backgroundColor: "#f5b29d",
      color: "#e9724c",
      padding: "5px",
      borderRadius: "5px",
      border: "solid 2px #e9724c",
    },
    extraDetails: {
      paddingTop: "0px",
      paddingBottom: "0px",
    },
    minimize: {
      display: "flex",
      alignItems: "flex-start",
    },
  })
)(withDialog(MarinaDashboardCheckOutToast));
