import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { dialogBoxProps  } from "../../../components/src/withDialog.web";
import { withAlertBoxProps } from "../../../components/src/withAlertBox.Web";
import { withToastProps } from "../../../components/src/withSnackBar.Web";
import { withLoaderProps } from "../../../components/src/withLoader.Web";
export const configJSON = require("./config");

export type Props = RouterProps & dialogBoxProps & withAlertBoxProps & withToastProps & withLoaderProps & {
  id: string;
  openConfirmDialog : string;
  // Customizable Area Start
  // Customizable Area End
};

interface S {
  // Customizable Area Start
  authToken : String
  boatDetails : any;
  boatId : string;
  booking_details : any;
  bookingId : any;
  isPromoCode : boolean,
  transactionID : any;
  paymentSuccessMsg : any
  paymentErrorMsg : any,
  transactionData : any
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class BookBoatPaymentController extends BlockComponent<
  Props,
  S,
  SS
> {
  updateBoatBookingApiCallId: String = "";
  getBoatDetailApiCallId : String = "";
  checkPromoApiCallId:String="";
  getPromoCodesApiCallId : String = "";
  createTransactionApiCallId : String = ""
  checkBoatAvailableApiCallId : string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
      // Customizable Area End
    ];

    this.state = {
      authToken : '',
      boatDetails : {},
      boatId : '',
      booking_details : {},
      bookingId : null,
      isPromoCode : false,
      transactionID : "",
      paymentSuccessMsg : "",
      paymentErrorMsg : "",
      transactionData : {}
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
       if (apiRequestCallId === this.getBoatDetailApiCallId) {
        this.props.hideLoader();
        if (responseJson && responseJson.data && responseJson.data.id) {
            this.setState({
              boatDetails : responseJson.data?.attributes
            })
        } else {
          this.parseApiErrorResponse(responseJson);
          // this.setState({loading:  false})
        }
      } else if (apiRequestCallId === this.updateBoatBookingApiCallId) {
        this.props.hideLoader();
        if (responseJson && responseJson.data && responseJson.data.id) {
            this.props.showToast({type:"success",message : "your booking created sucessfully"})
            this.props.history.push("/userDashboard/myBookings");
        } else {
          this.parseApiErrorResponse(responseJson);
          // this.setState({loading:  false})
        }
      }else if (apiRequestCallId === this.checkPromoApiCallId) {	
        this.props.hideLoader();	
        if (responseJson && responseJson?.data?.id) {	
            this.props.showToast({type:"success",message : "Promo code applied successfully"})	
            this.setState({
              isPromoCode : true,
              booking_details : responseJson.data.attributes
            })
        } else {	
          this.parseApiErrorResponse(responseJson);	
        }	
      } else if(this.createTransactionApiCallId === apiRequestCallId){
        this.props.hideLoader();
        if (responseJson && responseJson?.data?.id) {	
          this.createBooking();
        } else {	
        this.parseApiErrorResponse(responseJson);	
       }
      } else if(apiRequestCallId === this.checkBoatAvailableApiCallId){
        this.props.hideLoader();
        if (responseJson && responseJson?.success && responseJson?.success.length > 0) {	
          this.createTransaction();
        } else {	
        this.parseApiErrorResponse(responseJson);	
       }
      }

    } else if (getName(MessageEnum.AlertMessage) === message.id) {
      const title = message.getData(getName(MessageEnum.AlertTitleMessage));
      var AlertBodyMessage = message.getData(
        getName(MessageEnum.AlertBodyMessage)
      );
      const msg = await this.props.showAlert({title : 'Error',message : AlertBodyMessage})
      if(msg === 'Your session has expired, please login again.' || msg === 'Invalid token'){
        await StorageProvider.remove("authToken")
        await StorageProvider.remove("accountInfo")  
        this.props.history.push("/basicAuth/login")
      }
    }
  }
  checkPromoCode(data:String) {	
    this.props.showLoader();	
    const headers = {	
      token : this.state.authToken	
    };	
    const requestMessage = new Message(	
      getName(MessageEnum.RestAPIRequestMessage)	
    );	
    this.checkPromoApiCallId = requestMessage.messageId;	
    requestMessage.addData(	
      getName(MessageEnum.RestAPIResponceEndPointMessage),	
      `bx_block_reservations/bookings/${this.state.bookingId}/apply_promocode?promocode=${data}`	
    );	
    requestMessage.addData(	
      getName(MessageEnum.RestAPIRequestHeaderMessage),	
      JSON.stringify(headers)	
    );	
    requestMessage.addData(	
      getName(MessageEnum.RestAPIRequestMethodMessage),	
      configJSON.methodGET	
    );	
    runEngine.sendMessage(requestMessage.id, requestMessage);	
  }
  getPromoCodes = () => {
    this.props.showLoader();
    const headers = {
      "content-type": configJSON.ApiContentType,
      token : this.state.authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getPromoCodesApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_promotions/promocodes`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getBoatDetail() {
    this.props.showLoader();
    const headers = {
      "content-type": configJSON.ApiContentType,
      token : this.state.authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getBoatDetailApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getBoatDetailPoint}/${this.state.boatId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  createBooking = (values? : any) => {
    this.props.showLoader();
    const headers = {
      "content-type": configJSON.ApiContentType,
      token : this.state.authToken
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    // const tripTime = new Date(values.tripTime).getHours();
    const httpBody = {
      "booking": {
        "payment_status" : "paid",
        "message"  : "this is for testing"
      }
    }
    this.updateBoatBookingApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${"bx_block_reservations/bookings/"}${this.state.booking_details.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPUT
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  createTransaction = () => {
    this.props.showLoader();
    const headers = {
      "content-type": configJSON.ApiContentType,
      token : this.state.authToken
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const {transactionData : values} = this.state;
    const httpBody = {
      
      "payments": {
        "booking_id": this.state.booking_details.id,
          "payment_capture_id": values.id,
          "amount": values?.purchase_units[0]?.amount?.value,
          "currency_code": values?.purchase_units[0]?.amount?.currency_code,
          "payee_email": values.payer.email_address,
          "status": "COMPLETED",
          "payer_id": values.payer?.name?.payer_id, 
          "payer_name": values.payer?.name?.given_name
      }
    }
    this.createTransactionApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_payments/payments`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPOST
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }   
  checkBoatAvailable = (boatId : any) => {
    this.props.showLoader();
    const headers = {
      "content-type": configJSON.ApiContentType,
      token : this.state.authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.checkBoatAvailableApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_reservations/bookings/${this.state.booking_details.id}/check_booking_availibility`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }   
}
