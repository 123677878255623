import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { dialogBoxProps } from "../../../components/src/withDialog.web";
import { withAlertBoxProps } from "../../../components/src/withAlertBox.Web";
import { withToastProps } from "../../../components/src/withSnackBar.Web";
import { withLoaderProps } from "../../../components/src/withLoader.Web";
export const configJSON = require("./config");

export type Props = RouterProps &
  dialogBoxProps &
  withAlertBoxProps &
  withToastProps &
  withLoaderProps & {
    id: string;
    // Customizable Area Start
    // Customizable Area End
  };

interface S {
  // Customizable Area Start
  boatAddressDetail: any;
  authToken : String
  boatId : String,
  isSaveAndExit : boolean,
  marinaList : Array<any>,
  searchSuggestionList : Array<any>,
  suggestionloading : boolean
  marina : any
  
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class BoatAddressDetailController extends BlockComponent<
  Props,
  S,
  SS
> {
  getBoatDetailApiCallId: String = "";
  updateBoatAddressDetailApiId : String = "";
  getMarinaListApiCallId : String = ""
  getLocationSearchApiCallId : string = "";
  
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
      // Customizable Area End
    ];

    this.state = {
      boatAddressDetail: {
        zipcode: "",
        location: "",
        address: "",
        addressline2: "",
        city: "",
        state: "",
        locationType: "personalLocation",
      },
      marina : null,
      authToken : "",
      boatId : "",
      isSaveAndExit : false,
      marinaList : [],
      searchSuggestionList : [],
      suggestionloading : false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.getLocationSearchApiCallId) {
        if (responseJson && responseJson?.candidates && responseJson?.candidates.length > 0 && responseJson?.status === "OK" ) {
          const locations = responseJson.candidates.map((location : any)=>{
            return {
              name : location?.name || "Not Available",
              latitude : location?.geometry.location.lat,
              longitude : location?.geometry.location.lng,
              address : location?.formatted_address
            }
          }); 
          this.setState({
            searchSuggestionList : locations,
            suggestionloading : false
          })
        } else {
          this.parseApiErrorResponse(responseJson);
          // this.setState({loading:  false})
        }
      }
      else if (apiRequestCallId === this.getMarinaListApiCallId) {
        this.props.hideLoader();
        if (responseJson && responseJson.data) {
            this.setState({
              marinaList : responseJson.data
            })
        } else {
          this.parseApiErrorResponse(responseJson);
          // this.setState({loading:  false})
        }
      } else if (apiRequestCallId === this.getBoatDetailApiCallId) {
        this.props.hideLoader();
        if (responseJson && responseJson.data && responseJson.data.id) {
          if(responseJson?.data?.attributes?.address){
            this.setState({
              boatAddressDetail : {
                zipcode: responseJson?.data?.attributes?.address?.zipcode,
                address: responseJson?.data?.attributes?.address?.address1,
                addressline2: responseJson?.data?.attributes?.address?.address2,
                city: responseJson?.data?.attributes?.address?.city,
                state: responseJson?.data?.attributes?.address?.state,
                "latitude": responseJson?.data?.attributes?.address?.latitude || null,
                "longitude": responseJson?.data?.attributes?.address?.longitude || null,
                locationType: responseJson?.data?.attributes?.location_type === "personal" ? "personalLocation" : "marinaLocation",
              },
              marina : {
                id : responseJson?.data?.attributes?.marina_id
              }
            },()=>{
              // this.getMarinaList();
            })
          }
        } else {
          this.parseApiErrorResponse(responseJson);
          // this.setState({loading:  false})
        }
      } else if (apiRequestCallId === this.updateBoatAddressDetailApiId) {
        this.props.hideLoader();
        if (responseJson && responseJson?.data && responseJson?.data?.id) {
           this.props.showToast({type : 'success',message : 'Boat Address Detail Updated successfully'})
           const { history } = this.props;
           if(this.state.isSaveAndExit){
             await StorageProvider.remove("boatId")  
             history.push('/boatListingLandingPage');
           } else {
            if(this.state.boatAddressDetail.locationType === 'personalLocation'){
              history.push('/boatLocation');      
            } else {
              history.push('/boatHandsFreeExprience');      
            }
           }
        } else {
          this.parseApiErrorResponse(responseJson);
          // this.setState({loading:  false})
        }
      }
    } else if (getName(MessageEnum.AlertMessage) === message.id) {
      const title = message.getData(getName(MessageEnum.AlertTitleMessage));
      var AlertBodyMessage = message.getData(
        getName(MessageEnum.AlertBodyMessage)
      );
      this.props.showAlert({
        title: "Error",
        message: AlertBodyMessage,
      });
    }
  }
  getBoatDetail() {
    this.props.showLoader();
    const headers = {
      "content-type": configJSON.ApiContentType,
      token : this.state.authToken
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getBoatDetailApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getBoatDetailPoint}/${this.state.boatId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getLocationSuggestion = (location : any) => {
    if(location){
      this.setState({
        suggestionloading : true
      },()=>{
        // const headers = {
        //   "content-type": configJSON.ApiContentType,
        //   token : this.state.authToken
        // };
        let url = `bx_block_elasticsearch/search_address?location=${location}`
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getLocationSearchApiCallId = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          url
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify({})
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.methodGET
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
      })
    }
  }
  getMarinaList() {
    this.props.showLoader();
    const headers = {
      "content-type": configJSON.ApiContentType,
      token : this.state.authToken
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getMarinaListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getMarinaListEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  updateBoatAddress(values: any) {
    this.props.showLoader();
    const headers = {
      "content-type": configJSON.ApiContentType,
      token : this.state.authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateBoatAddressDetailApiId = requestMessage.messageId;
    let httpBody = {
      "address_attributes": {
        "zipcode": values.zipcode,
        "address1": values.address,
        "address2": values.addressline2,
        "city": values.city,
        "state": values.state,
        "latitude": this.state?.boatAddressDetail?.latitude || null,
        "longitude": this.state?.boatAddressDetail?.longitude || null,
     },
     marina_id : null,
     "location_type": values.locationType === "personalLocation" ? 0 : 1,
    }
    if(this.state.marina?.id){
      httpBody = {...httpBody,"marina_id" : this.state.marina.id}
    }
    this.setState({
      boatAddressDetail : {
        zipcode: values.zipcode,
        address: values.address,
        addressline2: values.addressline2,
        city: values.city,
        state: values.state,
        locationType: values.locationType,
      },
    },()=>{
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        this.state.boatId ? `${configJSON.createBoatEndPoint}/${this.state.boatId}` : `${configJSON.createBoatEndPoint}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        this.state.boatId ? configJSON.methodPUT : configJSON.methodPOST
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    })
    
  }
}
