//@ts-nocheck
import { withStyles, createStyles } from "@material-ui/core";
import React from "react";
import MuiTextField, {
  TextFieldProps as MuiTextFieldProps,
} from "@material-ui/core/TextField";
import { FieldProps, getIn } from "formik";

export interface TextFieldProps
  extends FieldProps,
    Omit<MuiTextFieldProps, "name" | "value" | "error"> {}

export function fieldToTextField({
  disabled,
  field: { ...field },
  form: { touched, errors },
  helperText,
  ...props
}: TextFieldProps): MuiTextFieldProps {
  const fieldError = getIn(errors, field.name);
  const showError = getIn(touched, field.name) && !!fieldError;

  return {
    variant: props.variant,
    error: showError,
    helperText: showError ? fieldError : helperText,
    disabled: disabled,
    ...field,
    ...props,
  };
}

const InputField = ({
  children,
  InputLabelProps = {},
  InputProps = {},
  classes,
  ...props
}: TextFieldProps) => {
  return (
    <MuiTextField
      {...fieldToTextField(props)}
      variant="outlined"
      InputLabelProps={{ ...InputLabelProps, shrink: false,classes : {
        asterisk : classes?.astriklabel,
        outlined : classes?.outlinedLabel,
        shrink : classes?.shrink,
        root : classes?.lableroot
      }}}
      InputProps={{
        ...InputProps,
        classes: {
          input: classes?.input,
          root : classes?.inputfieledroot,
        },
      }}
    >
      {children}
    </MuiTextField>
  );
};

export default withStyles((theme) =>
  createStyles({
    inputfieledroot : {
      'label + &': {
              marginTop: 40
            }
    },
    input: {
      position: "relative",
      backgroundColor: "#FFF",
      borderRadius: "5px",
      border: "solid 2px #cbcbcb",
      fontSize: "16px",
      fontFamily: "Montserrat",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.19,
      letterSpacing: "normal",
      color: "#1b4050",
      padding : '10.5px 14px'
    },
    astriklabel : {
        fontFamily: 'Montserrat',
        fontSize: '14px',
        fontWeight: 600,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.14,
        letterSpacing: 'normal',
        color: '#ef3e22'
    },
    lableroot : {
          fontFamily: 'Montserrat',
        fontSize: '14px',
        fontWeight: 600,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.14,
        letterSpacing: 'normal',
        color: '#717b90',
    },
    outlinedLabel : {
      transform : 'translate(0px, 16px) scale(1)'
    },
    shrink : {
      transform : 'translate(0px, 16px) scale(1)'
    }
  })
)(InputField);
