//@ts-nocheck
import React from "react";
import OwnerTaxController, { Props } from "./OwnerTaxController.web";

import { withStyles, createStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import {
  Grid,
  Avatar,
  Container,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Collapse,
  TableCell,
  Paper,
  TextField,
  InputBase,
} from "@material-ui/core/";
import moment from "moment";
import SearchIcon from "@material-ui/icons/Search";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import Button from "@material-ui/core/Button";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
import withDialog from "../../../components/src/withDialog.web";
import { withRouter } from "react-router";

const past = [
  {
    boatName: "2014 Sea Ray 52'",
    boatAddress: "Test Address",
    boatCity: "Test City",
    renterFirstName: "Vishal",
    rentertLastName: "Mathur",
    approvalStatus: "approved",
    boatOwnerName: "John Doe",
    tripDate: "2021-07-12T00:00:00.000Z",
    checkInDate: "2021-07-12T00:00:00.000Z",
    checkoutDate: "2021-07-12T00:00:00.000Z",
    rentAmount: "1500",
    action: "",
  },
  {
    boatName: "2014 Sea Ray 52'",
    boatAddress: "Test Address",
    boatCity: "Test City",
    renterFirstName: "Vishal",
    rentertLastName: "Mathur",
    approvalStatus: "pending",
    boatOwnerName: "John Doe",
    tripDate: "2021-07-12T00:00:00.000Z",
    checkInDate: "2021-07-12T00:00:00.000Z",
    checkoutDate: "2021-07-12T00:00:00.000Z",
    rentAmount: "1500",
    action: "",
  },
  {
    boatName: "2014 Sea Ray 52'",
    boatAddress: "Test Address",
    boatCity: "Test City",
    renterFirstName: "Vishal",
    rentertLastName: "Mathur",
    approvalStatus: "approved",
    boatOwnerName: "John Doe",
    tripDate: "2021-07-12T00:00:00.000Z",
    checkInDate: "2021-07-12T00:00:00.000Z",
    checkoutDate: "2021-07-12T00:00:00.000Z",
    rentAmount: "1500",
    action: "",
  },
  {
    boatName: "2014 Sea Ray 52'",
    boatAddress: "Test Address",
    boatCity: "Test City",
    renterFirstName: "Vishal",
    rentertLastName: "Mathur",
    approvalStatus: "pending",
    boatOwnerName: "John Doe",
    tripDate: "2021-07-12T00:00:00.000Z",
    checkInDate: "2021-07-12T00:00:00.000Z",
    checkoutDate: "2021-07-12T00:00:00.000Z",
    rentAmount: "1500",
    action: "",
  },
];
const OuterTableCell = withStyles({
  head: {
    borderBottom: "0px",
    fontFamily: "Montserrat",
    fontSize: "0.75rem",
    fontWeight: 600,
    lineHeight: 1.56,
    color: "#8b98a9;",
    backgroundColor: "#f7f9fc",
  },
  body: {
    fontFamily: "Montserrat",
    fontSize: "0.875rem",
    fontWeight: 500,
    lineHeight: 1.21,
    color: "#343a40;",
    cursor: "pointer",
    borderBottom: "solid 1px #eaecef",
  },
})(TableCell);

class OwnerTransaction extends OwnerTaxController {
  constructor(props: Props) {
    super(props);
  }
  //   handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
  //     this.setState({ selectedBookingType: newValue });
  //   };
  async componentDidMount() {
    const authToken = await StorageProvider.get("authToken");
    if (authToken) {
      this.setState(
        {
          authToken: authToken,
        },
        () => {
        //   this.getTerms();
        }
      );
    }
  }

  render() {
    const { classes } = this.props;

    return (
      <Container maxWidth="lg">
        <Box my={5}>
          <Typography variant="h4" className={classes.title}>
            Owner {this.props.name} Information
          </Typography>
        </Box>
        <Grid container>
          {this.state.terms ? (
            <Grid xs={12} item>
              <Typography>{this.state.terms?.attributes?.body}</Typography>
            </Grid>
          ) : (
            <Grid item xs={12}>
              <Typography align="center">No Information available</Typography>
            </Grid>
          )}
        </Grid>
      </Container>
    );
  }
}
const OwnerTransactionWithRouter = withRouter(OwnerTransaction);
const OwnerTransactionAlertBox = withAlertBox(OwnerTransactionWithRouter);
const OwnerTransactionLoader = withLoader(OwnerTransactionAlertBox);
const OwnerTransactionToast = withToast(OwnerTransactionLoader);
export default withStyles((theme) =>
  createStyles({
    title: {
      fontFamily: "Montserrat",
      fontSize: "2rem",
      color: "#343a40",
    },
    tableHead: {
      fontFamily: "Montserrat",
      backgroundColor: "#f7f9fc",
    },

    avatar: {
      height: "20px",
      width: "20px",
      margin: "5px",
      display: "inline-block",
      verticalAlign: "middle",
    },
    searchButton: {
      border: "solid 2px #0174cf",
      fontFamily: "Montserrat",
      borderRadius: "5px",
      paddingTop: "26px",
      paddingBottom: "26px",
    },
    searchField: {
      maxWidth: "300px",
      border: "solid 1px #d7e2ec",
      fontFamily: "Montserrat",
      backgroundColor: "rgba(247,249,252,0.5)",
      boxShadow: "2px 2px #e8eaed",
      position: "relative",
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    inputRoot: {
      color: "inherit",
    },
    inputInput: {
      padding: theme.spacing(2, 1, 2, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("md")]: {
        width: "20ch",
      },
    },
  })
)(withDialog(OwnerTransactionToast));
