import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { dialogBoxProps  } from "../../../components/src/withDialog.web";
import { withAlertBoxProps } from "../../../components/src/withAlertBox.Web";
import { withToastProps } from "../../../components/src/withSnackBar.Web";
import { withLoaderProps } from "../../../components/src/withLoader.Web";
import VerifyOTP from "./verifyOTP.web";
export const configJSON = require("./config");

export type Props = RouterProps & dialogBoxProps & withAlertBoxProps & withToastProps & withLoaderProps & {
  id: string;
  openConfirmDialog : string;
  // Customizable Area Start
  // Customizable Area End
};

interface S {
  // Customizable Area Start
  authToken : String,
  mode : "View" | "Edit";
  profilePicturePreView : any;
  personalInfo : any;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CustomerPernoalInfoController extends BlockComponent<
  Props,
  S,
  SS
> {
  updateCustomerInfoApiCallId: String = "";
  getPersonalInfoApiCallId : String = "";
  updateProfiePictureApiCallId : String = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
      // Customizable Area End
    ];

    this.state = {
      authToken : '',
      mode : "View",
      profilePicturePreView : null,
      personalInfo : null
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.updateCustomerInfoApiCallId) {
        this.props.hideLoader();
        if (responseJson && responseJson?.data && responseJson?.data?.id && responseJson?.data?.attributes) {
          if(responseJson?.data?.attributes.full_phone_number != responseJson?.data?.attributes.unconfirmed_phone_number ||  responseJson?.data?.attributes.email != responseJson?.data?.attributes.unconfirmed_email){
            this.props.showToast({
              type: "success",
              message: "OTP sent successfully",
            });
            this.props.openDialogBox({
              dataToPass : {token : responseJson.meta.token,phoneNumber : responseJson?.data?.attributes.unconfirmed_phone_number,email : responseJson?.data?.attributes.unconfirmed_email},
              renderedComponent : VerifyOTP,
              title : 'OTP',
              withCustomDialog : false
            }).then((id : any)=>{
              if(id){
                this.props.showToast({
                  type: "success",
                  message: "User profile updated successfully",
                });
              }
            })
          } else {
            this.props.showToast({
              type: "success",
              message: "User profile updated successfully",
            });
          }
          
        } else {
          this.parseApiErrorResponse(responseJson);
        }
      } else if (apiRequestCallId === this.updateProfiePictureApiCallId) {
        this.props.hideLoader();
        if (responseJson && responseJson?.data && responseJson?.data?.id && responseJson?.data?.attributes) {
          this.props.showToast({
            type: "success",
            message: "User profile picture updated successfully",
          });
        } else {
          this.parseApiErrorResponse(responseJson);
        }
      } else if (apiRequestCallId === this.getPersonalInfoApiCallId) {
        this.props.hideLoader();
        if (responseJson && responseJson?.data && responseJson?.data?.id && responseJson?.data?.attributes) {
          this.setState({
               personalInfo : responseJson?.data?.attributes
          })
        } else {
          this.parseApiErrorResponse(responseJson);
        }
      } 
    } else if (getName(MessageEnum.AlertMessage) === message.id) {
      const title = message.getData(getName(MessageEnum.AlertTitleMessage));
      var AlertBodyMessage = message.getData(
        getName(MessageEnum.AlertBodyMessage)
      );
      const msg = await this.props.showAlert({title : 'Error',message : AlertBodyMessage})
      if(msg === 'Your session has expired, please login again.' || msg === 'Invalid token'){
        await StorageProvider.remove("authToken")
        await StorageProvider.remove("accountInfo")  
        this.props.history.push("/basicAuth/login")
      }
    }
  }

  getPersonalInfo() {
    this.props.showLoader();
    const headers = {
      "content-type": configJSON.ApiContentType,
      token : this.state.authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getPersonalInfoApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.personalProfieInfo}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  updateProfileImage = (values : any) => {
    this.props.showLoader();
    const headers = {
      token : this.state.authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateProfiePictureApiCallId = requestMessage.messageId;
    const fromdata = new FormData();
   
    if(this.state.profilePicturePreView?.length > 0){
        fromdata.append("data[avatar]",this.state.profilePicturePreView[0].file)
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.personalProfieInfo}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      fromdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPUT
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    
  }

   
  updateUserInfo(values: any) {
    this.props.showLoader();
    const headers = {
      token : this.state.authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateCustomerInfoApiCallId = requestMessage.messageId;
    const fromdata = new FormData();
    fromdata.append("data[first_name]",values?.firstName)
    fromdata.append("data[last_name]",values?.lastName)
    fromdata.append("data[new_phone_number]",`91${values?.phone}`)
    fromdata.append("data[new_email]",values?.email)
    fromdata.append("data[date_of_birth]",values?.dob)
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.personalProfieInfo}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      (fromdata)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPUT
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  
}
