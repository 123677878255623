//@ts-nocheck
import React from "react";
import BoatApprovalDetailsContoller, {
  Props,
} from "./BoatApprovalDetailsContoller.web";
import {
  withStyles,
  Grid,
  AppBar,
  Toolbar,
  Typography,
  createStyles,
  Box,
  ListItem,
  List,
  ListItemText,
  ListItemIcon,
  Radio,
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  Checkbox,
} from "@material-ui/core";
import { Formik, Form, Field } from "formik";
import InputBox from "./InputBox.web";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import { logo,boatDetail, tick, commercial, boat, pin } from "./assets";
import * as Yup from "yup";
import { withRouter } from "react-router";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
const drawerWidth = 240;
const PageLinks = [
  { displayName: "Support", route: "/faq" },
  { displayName: "Save and exit", route: "saveandexit" },
];
import withBoatIdComponent from "./withBoatIdComponent.js";

class BoatApprovalDetails extends BoatApprovalDetailsContoller {
  constructor(props: Props) {
    super(props);
  }
  submitForm = null;
  async componentDidMount() {
    const authToken = await StorageProvider.get("authToken");
    const boatId = await StorageProvider.get("boatId");
    if (authToken) {
      this.setState(
        {
          authToken: authToken,
          boatId: boatId,
        },
        () => {
          this.getBoatDetail();
        }
      );
    }
  }
  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };
  goToSelectedRoute = (routeName) => {
    if (routeName === "saveandexit") {
      this.setState({
        isSaveAndExit : true
      },()=>{
        if(this.state.selectedCategory){
          this.submitForm && this.submitForm();
        } else {
          this.props.showToast({type:'error',message : 'Please select atleast 1 method'})
        }
      })
    } else {
      const { history } = this.props;
      history.push(routeName);
    }
  };
  handleCategorySelection = (category) => {
    this.setState({
      selectedCategory: category,
    });
  };
  render() {
    const { classes } = this.props;
    return (
      <>
        <AppBar
          position="static"
          color="default"
          elevation={0}
          className={classes.appBar}
        >
          <Toolbar className={classes.toolbar}>
            <Box className={classes.toolbarLogo}>
              <img onClick={()=>this.props.history.push("/")}  src={logo} style={{width:'22%'}} alt="boaturu" />
            </Box>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              edge="start"
              onClick={this.handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <Hidden xsDown implementation="css">
              <nav>
                {PageLinks.map((link) => {
                  return (
                    <Typography
                      key={link.displayName}
                      variant="button"
                      className={classes.link}
                      onClick={() => {
                        this.goToSelectedRoute(link.route);
                      }}
                    >
                      {link.displayName}
                    </Typography>
                  );
                })}
              </nav>
            </Hidden>
            <Hidden smUp implementation="css">
              <Drawer
                variant="persistent"
                anchor={"right"}
                open={this.state.mobileOpen}
                onClose={this.handleDrawerToggle}
                classes={{
                  paper: classes.drawerPaper,
                }}
                SlideProps={{ unmountOnExit: true }}
              >
                <IconButton
                  onClick={this.handleDrawerToggle}
                  className={classes.closeMenuButton}
                >
                  <CloseIcon />
                </IconButton>
                <List>
                  {PageLinks.map((link, index) => (
                    <ListItem
                      button
                      key={link.displayName}
                      onClick={() => {
                        this.goToSelectedRoute(link.route);
                      }}
                    >
                      <ListItemText primary={link.displayName} />
                    </ListItem>
                  ))}
                </List>
              </Drawer>
            </Hidden>
          </Toolbar>
        </AppBar>
        <Grid container className={classes.root}>
          <Grid
            item
            xs={false}
            sm={4}
            md={5}
            container
            className={classes.image}
          >
            <div className={classes.overlay} />
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={12} />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography gutterBottom className={classes.pricing}>
                Boat Details
              </Typography>
              <div className={classes.headingline} />
              <Typography gutterBottom className={classes.selectOperator}>
                Instant approvals
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography gutterBottom className={classes.leftsidelink}>
                Pricing
              </Typography>
              <Typography gutterBottom className={classes.leftsidelink}>
                Insurance
              </Typography>
              <Typography gutterBottom className={classes.leftsidelink}>
                Safety and standards
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={8} md={7}>
            <Formik
              enableReinitialize
              initialValues={{
                isBoatingLicense: this.state.required_doc.boating_license,
                isSpicialLicense: this.state.required_doc.special_license,
                isStateLicense: this.state.required_doc.state_license,
              }}
              onSubmit={(values, actions) => {
                this.updateBoatCancelationPolicy(values);
              }}
            >
              {(formikProps) => {
                const {
                  values,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  setFieldValue,
                } = formikProps;
                this.submitForm = handleSubmit;
                return (
                  <Form noValidate autoComplete="off">
                    <Grid
                      container
                      style={{ padding: "16px 32px" }}
                      spacing={5}
                    >
                      <Grid item xs={12} container justify="space-between">
                        <Grid item>
                          <Box
                            className={classes.backButtonBox}
                            onClick={() => {
                              this.goToSelectedRoute("/boatPhotoDetail");
                            }}
                          >
                            <Typography
                              className={classes.backButtonBoxContent}
                            >
                              {"<"}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} container justify="center">
                        <Grid item>
                          <Typography
                            className={classes.rightPageHeading}
                            align="center"
                            gutterBottom
                          >
                            Please select how you want to approve your renter's
                            request
                          </Typography>
                          <Typography
                            className={classes.rightPageSubHeading}
                            align="center"
                            gutterBottom
                          >
                            You can always edit this later
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} container justify="center">
                        <Grid
                          item
                          xs={10}
                          className={classes.approvalBox}
                          style={
                            this.state.selectedCategory === "automatic"
                              ? {
                                  backgroundColor: "#c8e7ff",
                                  border: "solid 4px #004277",
                                }
                              : { backgroundColor: "#ffffff" }
                          }
                        >
                          <List>
                            <ListItem
                              onClick={() => {
                                this.handleCategorySelection("automatic");
                              }}
                            >
                              <ListItemIcon>
                                <Radio
                                  checked={
                                    this.state.selectedCategory === "automatic"
                                  }
                                  value="automatic"
                                />
                              </ListItemIcon>
                              <ListItemText
                                classes={{
                                  primary: classes.primaryListText,
                                  secondary: classes.secondaryListText,
                                }}
                                primary={`Automatic approval`}
                                secondary="You can select a set criteria and ask for required documents. If renters satisfies
                                the criteria, you'll be notified when  his request is approved automatically.
                                        "
                              />
                            </ListItem>
                          </List>
                        </Grid>
                      </Grid>
                      {this.state.selectedCategory === "automatic" && (
                        <Grid item xs={12} container justify="center">
                          <Grid item xs={10}>
                            <Typography
                              align="center"
                              className={classes.checkBoxHeading}
                            >
                              You may select and ask your renters for required
                              information
                            </Typography>
                            <FormControl
                              component="fieldset"
                              className={classes.formControl}
                            >
                              <FormGroup>
                                <FormControlLabel
                                  classes={{
                                    label: classes.checkBoxLabel,
                                  }}
                                  control={
                                    <Checkbox
                                      checked={values.isBoatingLicense}
                                      onChange={handleChange}
                                      name="isBoatingLicense"
                                    />
                                  }
                                  label="An image for boating License"
                                />
                                <FormControlLabel
                                  classes={{
                                    label: classes.checkBoxLabel,
                                  }}
                                  control={
                                    <Checkbox
                                      checked={values.isStateLicense}
                                      onChange={handleChange}
                                      name="isStateLicense"
                                    />
                                  }
                                  label="State License"
                                />
                                <FormControlLabel
                                  classes={{
                                    label: classes.checkBoxLabel,
                                  }}
                                  control={
                                    <Checkbox
                                      checked={values.isSpicialLicense}
                                      onChange={handleChange}
                                      name="isSpicialLicense"
                                    />
                                  }
                                  label="Need special License certificate as this boat comes under Long Category (>50 feet)."
                                />
                              </FormGroup>
                            </FormControl>
                          </Grid>
                        </Grid>
                      )}
                      <Grid item xs={12} container justify="center">
                        <Grid
                          item
                          xs={10}
                          className={classes.approvalBox}
                          style={
                            this.state.selectedCategory === "manual"
                              ? {
                                  backgroundColor: "#c8e7ff",
                                  border: "solid 4px #004277",
                                }
                              : { backgroundColor: "#ffffff" }
                          }
                        >
                          <List>
                            <ListItem
                              onClick={() => {
                                this.handleCategorySelection("manual");
                              }}
                            >
                              <ListItemIcon>
                                <Radio
                                  checked={
                                    this.state.selectedCategory === "manual"
                                  }
                                  value="manual"
                                />
                              </ListItemIcon>
                              <ListItemText
                                classes={{
                                  primary: classes.primaryListText,
                                  secondary: classes.secondaryListText,
                                }}
                                primary={`Manual Approval`}
                                secondary="You have to approve the request manually, when a renters tries to book your boat"
                              />
                            </ListItem>
                          </List>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} container justify="flex-end">
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          disabled={this.state.selectedCategory === ''}
                        >
                          Proceed
                        </Button>
                      </Grid>
                    </Grid>
                  </Form>
                );
              }}
            </Formik>
          </Grid>
        </Grid>
      </>
    );
  }
}
const BoatApprovalDetailsWithRouter = withRouter(BoatApprovalDetails);
const BoatApprovalDetailsAlertBox = withAlertBox(BoatApprovalDetailsWithRouter);
const BoatApprovalDetailsLoader = withLoader(BoatApprovalDetailsAlertBox);
const BoatApprovalDetailsToast = withToast(BoatApprovalDetailsLoader);
export default withStyles((theme) =>
  createStyles({
    root: {
      height: "calc(100vh - 70px)",
      overflowX: "hidden",
    },
    image: {
      position: "relative",
      backgroundImage: `url(${boatDetail})`,
      backgroundRepeat: "no-repeat",
      backgroundColor:
        theme.palette.type === "light"
          ? theme.palette.grey[50]
          : theme.palette.grey[900],
      backgroundSize: "cover",
      backgroundPosition: "center",
      padding: "120px 68px",
      height: "calc(100vh - 72px)",
    },
    appBar: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      width: "100%",
      boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
      backgroundColor: "#337eb0",
      height: theme.spacing(8.75),
    },
    toolbar: {
      flexWrap: "nowrap",
    },
    toolbarLogo: {
      flexGrow: 1,
    },
    link: {
      margin: theme.spacing(1, 2.5),
      fontFamily: "Roboto",
      fontSize: "14px",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.14,
      letterSpacing: "normal",
      textTransform: "none",
      cursor: "pointer",
      color: "#fff",
    },
    margin: {
      marginRight: theme.spacing(2),
    },
    drawer: {
      [theme.breakpoints.up("sm")]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },
    drawerPaper: {
      width: drawerWidth,
    },
    closeMenuButton: {
      marginRight: 0,
      marginLeft: "auto",
    },
    marginTop: {
      marginTop: theme.spacing(4),
    },
    backButtonBox: {
      width: "50px",
      height: "50px",
      borderRadius: "5px",
      border: "solid 2px #0174cf",
      backgroundColor: "#ffffff",
      textAlign: "center",
      cursor : 'pointer'
    },
    backButtonBoxContent: {
      color: "#0174cf",
      fontFamily: "Montserrat",
      fontSize: "20px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "50px",
    },
    pageHeading: {
      fontFamily: "Montserrat",
      fontSize: "30px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.23,
      letterSpacing: "normal",
      textAlign: "center",
      color: "#183b56",
    },
    pageSubHeading: {
      fontFamily: "Montserrat",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "normal",
      textAlign: "center",
      color: "#343a40",
    },
    rightRoot: {
      justifyContent: "center",
      display: "flex",
    },
    overlay: {
      position: "absolute",
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
    },
    mainPostContent: {
      position: "relative",
      padding: theme.spacing(3),
      color: "#FFF",
      [theme.breakpoints.up("md")]: {
        padding: theme.spacing(6),
        paddingRight: 0,
      },
    },
    imagetxtcontainer: {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      margin: "auto",
    },
    leftPageHeading: {
      fontFamily: "Montserrat",
      fontSize: "20px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.21,
      letterSpacing: "normal",
      color: "#ffffff",
      paddingLeft: "8px",
    },
    pricing: {
      fontFamily: "Montserrat",
      fontSize: "32px",
      fontWeight: "600",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.22,
      letterSpacing: "normal",
      color: "#ffffff",
    },
    selectOperator: {
      fontFamily: "Montserrat",
      fontSize: "40px",
      fontWeight: "600",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.22,
      letterSpacing: "normal",
      color: "#ffffff",
    },
    leftsidelink: {
      fontFamily: "Montserrat",
      fontSize: "20px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.21,
      letterSpacing: "normal",
      color: "#004277",
    },
    headingline: {
      height: 0,
      width: "90%",
      border: "solid 1px #fff",
    },
    rightPageHeading: {
      fontFamily: "Montserrat",
      fontSize: "20px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.21,
      letterSpacing: "normal",
      color: "#004277",
      textAlign: "center",
    },
    rightPageSubHeading: {
      fontFamily: "Montserrat",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "normal",
      color: "#343a40",
      textAlign: "center",
      paddingTop: "16px",
    },
    inputLable: {
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.2,
      letterSpacing: "normal",
      color: "#343a40",
      textAlign: "center",
      padding: "12px",
    },
    approvalBox: {
      width: "100%",
      height: "120px",
      border: "solid 2px #343a40",
      borderRadius: "6px",
    },
    primaryListText: {
      fontFamily: "Montserrat",
      fontSize: "20px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.21,
      letterSpacing: "normal",
      color: "#343a40",
    },
    secondaryListText: {
      fontFamily: "Montserrat",
      fontSize: "12px",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "normal",
      color: "#343a40",
      paddingTop: "12px",
    },
    formControl: {
      margin: theme.spacing(3),
    },
    checkBoxRoot: {
      display: "flex",
    },
    checkBoxHeading: {
      fontFamily: "Montserrat",
      fontSize: "18px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.21,
      letterSpacing: "normal",
      color: "#004277",
    },
    checkBoxLabel: {
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "normal",
      color: "#343a40",
    },
  })
)(withBoatIdComponent(BoatApprovalDetailsToast));
