import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import StorageProvider from "../../../framework/src/StorageProvider.web";

import { withAlertBoxProps } from "../../../components/src/withAlertBox.Web";
import { withToastProps } from "../../../components/src/withSnackBar.Web";
import { withLoaderProps } from "../../../components/src/withLoader.Web";
import { DialogProps } from "../../../components/src/DialogContext";

export const configJSON = require("./config");

export type Props = RouterProps &
DialogProps &
  withAlertBoxProps &
  withToastProps &
  withLoaderProps & {
    id: string;
    // Customizable Area Start
    // Customizable Area End
  };

interface S {
  // Customizable Area Start
  authToken : String,
  data : any
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ContactOwnerController extends BlockComponent<
  Props,
  S,
  SS
> {
  getContentApiCallId : String = "";
  sendMsgApiCallId : String = ""
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
      // Customizable Area End
    ];

    this.state = {
      authToken : '',
      data : []
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

    async receive(from: string, message: Message) {
      runEngine.debugLog("Message Recived", message);
      if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
        const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );

        var responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        if (apiRequestCallId === this.getContentApiCallId) {
          if (responseJson && responseJson?.data) {
              this.setState({
                data : responseJson?.data
              })
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
      } else if (getName(MessageEnum.AlertMessage) === message.id) {
        const title = message.getData(getName(MessageEnum.AlertTitleMessage));
        var AlertBodyMessage = message.getData(
          getName(MessageEnum.AlertBodyMessage)
        );
        const msg = await this.props.showAlert({title : 'Error',message : AlertBodyMessage})
      if(msg === 'Your session has expired, please login again.' || msg === 'Invalid token'){
        await StorageProvider.remove("authToken")
        await StorageProvider.remove("accountInfo")  
        this.props.history.push("/basicAuth/login")
      }
      }
    }
    getContent = () => {
    //   this.props.showLoader();
      const headers = {
        "content-type": configJSON.ApiContentType,
        token : this.state.authToken
      };
      let url = "";
      if(this.props.dataToPass.type === 'Terms Of Service'){
        url = "bx_block_adminconsole/terms_of_services";
      } else if(this.props.dataToPass.type === 'Rental Aggrement'){
        url = "bx_block_adminconsole/rental_agreements";
      } else if(this.props.dataToPass.type === 'Cookie Policy'){
        url = "bx_block_adminconsole/cookies_policies";
      } else if(this.props.dataToPass.type === 'Contact us'){
        url = "bx_block_adminconsole/rules_and_regulations";
      }
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.getContentApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${url}`
      );
      
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        'GET'
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
   
  }
