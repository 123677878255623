//@ts-nocheck
import React from "react";
import ownerBoatListController, { Props } from "./ownerBoatListController.web";
import {
  withStyles,
  Grid,
  AppBar,
  Toolbar,
  Typography,
  createStyles,
  Box,
  ListItem,
  List,
  ListItemText,
  ListItemAvatar,
  ListItemIcon,
  Radio,
  Button,
  Avatar,
  Switch,
  IconButton,
  Menu,
  MenuItem,
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

import { withRouter, Route, Redirect } from "react-router";
import Add from "@material-ui/icons/Add";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
import { boatImage } from "./assets";
import FormControlLabel from "@material-ui/core/FormControlLabel";
const drawerWidth = 240;
const PageLinks = [
  { displayName: "List your boat", route: "ListYourBoat" },
  { displayName: "Inbox", route: "Inbox" },
  { displayName: "Bookings", route: "Bookings" },
];
const sideBarLinks = [
  { displayName: "Overview", route: "/ownerDashboard/overview" },
  { displayName: "Bookings", route: "/ownerDashboard/bookings" },
  { displayName: "Messages", route: "/ownerDashboard/messages" },
  { displayName: "My boats", route: "/ownerDashboard/myBoats" },
  { displayName: "Payment", route: "/ownerDashboard/payment" },
  {
    displayName: "Transaction History",
    route: "/ownerDashboard/transactionHistory",
  },
  { displayName: "Tax forms", route: "/ownerDashboard/taxforms" },
  {
    displayName: "Terms & Agreement",
    route: "/ownerDashboard/termsAndAgreement",
  },
];
class OwnerBoatList extends ownerBoatListController {
  constructor(props: Props) {
    super(props);
  }
  async componentDidMount() {
    const authToken = await StorageProvider.get("authToken");
    if (authToken) {
      this.setState(
        {
          authToken: authToken,
        },
        () => {
          this.getBoatListing();
        }
      );
    }
  }
  goToSelectedRoute = (routeName) => {
    const { history } = this.props;
    history.push(routeName);
  };
  createBoat = async () => {
    await StorageProvider.remove("boatId");
    this.goToSelectedRoute("/boatListingLandingPage");
  };
  editBoat = (boatId) => {
    this.goToSelectedRoute(`/boatCategory?boatId=${boatId}&mode=${"edit"}`);
  };
  completeBoatDetail = (boatId) => {
    this.goToSelectedRoute(`/boatCategory?boatId=${boatId}&mode=${"edit"}`);
  };
  updateVisiblityOfBoat = (boatId, visible) => {
    this.updateNameDetail(boatId, visible);
  };
  openBookingHistory = (boatId) => {
    this.goToSelectedRoute(`/ownerDashboard/boatDetails?boatId=${boatId}`);
  };
  openDurationMenu = (event) => {
    this.setState({
      durationAnchorEl: event.currentTarget,
    });
  };
  selectDurationAndCloseMenu = () => {
    this.setState({
      durationAnchorEl: null,
    });
  };
  render() {
    const { classes } = this.props;
    return (
      <Grid
        container
        justify="center"
        spacing={3}
        className={classes.marginTop}
      >
        <Grid item xs={10}>
          <Grid container justify="space-between">
            <Grid xs={4} item>
              <Typography align="left" className={classes.pageHeading}>
                {" "}
                My Boat{" "}
              </Typography>
            </Grid>
            <Grid xs={6} item style={{ textAlign: "end" }}>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  this.createBoat();
                }}
                startIcon={<Add />}
              >
                Add new boat
              </Button>
            </Grid>
          </Grid>
        </Grid>
        {this.state.boatList.length > 0 ? (
          this.state.boatList.map((boat) => {
            return (
              <Grid item key={boat.id} xs={10}>
                <Grid
                  container
                  justify="space-between"
                  className={classes.boatItemContainer}
                  spacing={1}
                >
                  <Grid
                    item
                    xs={4}
                    style={{
                      backgroundImage: `url(${
                        boat?.attributes?.thumbnail_image?.url
                          ? boat?.attributes?.thumbnail_image?.url
                          : boatImage
                      })`,
                    }}
                    className={classes.boatImage}
                  />
                  <Grid item xs={8} container spacing={2}>
                    <Grid xs={12} item container justify={"space-between"}>
                      <Grid xs={6} item>
                        <Typography className={classes.boatName}>
                          {boat?.attributes?.name}
                        </Typography>
                      </Grid>
                      <Grid xs={6} item style={{ textAlign: "end" }}>
                        <Typography
                          align="center"
                          className={
                            boat?.attributes?.complete
                              ? classes.completeStatus
                              : classes.incompleteStatus
                          }
                        >
                          {boat?.attributes?.complete
                            ? "COMPLETE"
                            : "INCOMPLETE"}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid xs={12} item>
                      <Grid container justify={"space-between"}>
                        <Grid xs={6} md={3} item>
                          <ListItem disableGutters>
                            <ListItemText
                              primary={
                                boat?.attributes?.view_count
                                  ? boat?.attributes?.view_count
                                  : "0"
                              }
                              classes={{
                                primary: classes.listPrimaryText,
                                secondary: classes.listSecondaryText,
                              }}
                              secondary="Views"
                            />
                          </ListItem>
                        </Grid>
                        <Grid xs={6} md={3} item>
                          <ListItem disableGutters>
                            <ListItemText
                              primary={
                                boat?.attributes?.bookings
                                  ? boat?.attributes?.bookings
                                  : "-"
                              }
                              classes={{
                                primary: classes.listPrimaryText,
                                secondary: classes.listSecondaryText,
                              }}
                              secondary="Bookings"
                            />
                          </ListItem>
                        </Grid>
                        <Grid xs={6} md={3} item>
                          <ListItem disableGutters>
                            <ListItemText
                              classes={{
                                primary: classes.listPrimaryText,
                                secondary: classes.listSecondaryText,
                              }}
                              primary={
                                boat?.attributes?.earning
                                  ? boat?.attributes?.earning
                                  : "-"
                              }
                              secondary="Earning"
                            />
                          </ListItem>
                        </Grid>
                        <Grid xs={5} md={3} item>
                          <FormControlLabel
                            classes={{
                              label: classes.swtichLable,
                            }}
                            value="top"
                            control={
                              <Switch
                                color="primary"
                                checked={boat?.attributes?.visible_for_audience}
                                onChange={(event) => {
                                  this.updateVisiblityOfBoat(
                                    boat.id,
                                    event.target.checked
                                  );
                                }}
                              />
                            }
                            label="Visible for audience"
                            labelPlacement="top"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid xs={12} item>
                      <Grid
                        spacing={1}
                        container
                        justify={"space-between"}
                        alignItems="center"
                      >
                        <Grid md={3} xs={5} item>
                          <Button
                            onClick={() => {
                              this.editBoat(boat.id);
                            }}
                            variant="contained"
                            color="primary"
                          >
                            {boat?.attributes?.complete
                              ? "Edit Listing"
                              : "Complete listing"}
                          </Button>
                        </Grid>
                        <Grid md={3} xs={5} item>
                          {boat?.attributes?.complete && (
                            <Button
                              variant="outlined"
                              onClick={() => {
                                this.openBookingHistory(boat.id);
                              }}
                              color="primary"
                            >
                              history
                            </Button>
                          )}
                        </Grid>
                        <Grid md={3} xs={5} item style={{ textAlign: "end" }}>
                          <div style={{ display: "flex",alignItems:'center' }}>
                            <div style={{ flexGrow: 1, }}>
                              <Typography
                                display="inline"
                                // className={classes.sortBy}
                              >
                                Action
                              </Typography>
                              {/* <IconButton
                                aria-controls="simple-menu"
                                
                                aria-haspopup="true"
                                
                              > */}
                                
                            </div>
                            <ArrowDropDownIcon onClick={(event) => {
                                  this.openDurationMenu(event);
                                }} fontSize={"small"}/>
                              {/* </IconButton> */}
                              <Menu
                                id="simple-menu"
                                anchorEl={this.state.durationAnchorEl}
                                keepMounted
                                open={!!this.state.durationAnchorEl}
                                onClose={() => {
                                  this.setState({
                                    durationAnchorEl: null,
                                  });
                                }}
                              >
                                {[{ name: "Delete", value: "Delete" }].map(
                                  (hour) => {
                                    return (
                                      <MenuItem
                                        onClick={() => {
                                          this.deleteBoat(boat);
                                          this.selectDurationAndCloseMenu();
                                        }}
                                      >
                                        {hour.name}
                                      </MenuItem>
                                    );
                                  }
                                )}
                              </Menu>
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            );
          })
        ) : (
          <Grid item xs={10}>
            <Typography align="center">No Boat found</Typography>
          </Grid>
        )}
      </Grid>
    );
  }
}
const OwnerBoatListWithRouter = withRouter(OwnerBoatList);
const OwnerBoatListAlertBox = withAlertBox(OwnerBoatListWithRouter);
const OwnerBoatListLoader = withLoader(OwnerBoatListAlertBox);
const OwnerBoatListToast = withToast(OwnerBoatListLoader);
export default withStyles((theme) =>
  createStyles({
    root: {
      height: "calc(100vh - 70px)",
      overflowX: "hidden",
    },
    marginTop: {
      marginTop: "24px",
    },
    pageHeading: {
      fontFamily: "Montserrat",
      fontSize: "24px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "1px",
      color: "#343a40",
    },
    boatItemContainer: {
      boxShadow: "0 3px 8px 0 rgba(0, 0, 0, 0.1)",
      border: "solid 1px #b8b8b8",
      backgroundColor: "#ffffff",
      height: "auto",
    },
    boatImage: {
      position: "relative",
      backgroundImage: `url(${boatImage})`,
      backgroundRepeat: "no-repeat",
      backgroundColor:
        theme.palette.type === "light"
          ? theme.palette.grey[50]
          : theme.palette.grey[900],
      backgroundSize: "cover",
      backgroundPosition: "center",
    },
    boatName: {
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "normal",
      color: "#004277",
    },
    incompleteStatus: {
      fontFamily: "Montserrat",
      fontSize: "12px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "1px",
      color: "#e9724c",
    },
    completeStatus: {
      fontFamily: "Montserrat",
      fontSize: "12px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "1px",
      color: "#00a676",
    },
    listPrimaryText: {
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "normal",
      color: "#000000",
    },
    listSecondaryText: {
      fontFamily: "Montserrat",
      fontSize: "12px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "normal",
      color: "#343a40",
    },
    swtichLable: {
      fontFamily: "Montserrat",
      fontSize: "10px",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "normal",
      color: "#343a40",
    },
  })
)(OwnerBoatListToast);
