import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { DialogProps } from "../../../components/src/DialogContext";
import { withAlertBoxProps } from "../../../components/src/withAlertBox.Web";
import { withToastProps } from "../../../components/src/withSnackBar.Web";
import { withLoaderProps } from "../../../components/src/withLoader.Web";
export const configJSON = require("./config");

export type Props = RouterProps &
  DialogProps &
  withAlertBoxProps &
  withToastProps &
  withLoaderProps & {
    id: string;
    // Customizable Area Start
    // Customizable Area End
  };
interface S {
  // Customizable Area Start
  authToken : any
  isValidSession : boolean,
  FAQS : []
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class BoatListingLandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  getPersonalInfoApiCallId: String = "";
  getFrequentlyQuestionsApiCallId : String = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
      // Customizable Area End
    ];

    this.state = {
      authToken : '',
      isValidSession : false,
      FAQS : []
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.getPersonalInfoApiCallId) {
        this.props.hideLoader();
        if (responseJson && responseJson?.data && responseJson?.data?.id && responseJson?.data?.attributes) {
          this.setState({
               isValidSession : true
          })
        } else {
          this.setState({
            isValidSession : false
          })
        }
      } else if(this.getFrequentlyQuestionsApiCallId === apiRequestCallId){
        this.props.hideLoader();
        if (responseJson && responseJson?.data) {
          this.setState({
               FAQS : responseJson?.data
          })
        } else {
          this.setState({
               FAQS : []
          })
        }
      }
    } else if (getName(MessageEnum.AlertMessage) === message.id) {
      const title = message.getData(getName(MessageEnum.AlertTitleMessage));
      var AlertBodyMessage = message.getData(
        getName(MessageEnum.AlertBodyMessage)
      );
      const msg = await this.props.showAlert({title : 'Error',message : AlertBodyMessage})
      if(msg === 'Your session has expired, please login again.' || msg === 'Invalid token'){
        await StorageProvider.remove("authToken")
        await StorageProvider.remove("accountInfo")  
        this.props.history.push("/basicAuth/login")
      }
    }
  }
  getPersonalInfo() {
    this.props.showLoader();
    const headers = {
      "content-type": configJSON.ApiContentType,
      token : this.state.authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getPersonalInfoApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.personalProfieInfo}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getFrequentlyQuestions = () => {
    //
    this.props.showLoader();
    const headers = {
      "content-type": configJSON.ApiContentType,
      token : this.state.authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getFrequentlyQuestionsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${"bx_block_catalogue/faqs"}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }  
}
