//@ts-nocheck
import {
  Avatar,
  Box,
  Button,
  Container,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/core/styles";
import StarIcon from "@material-ui/icons/Star";
import React from "react";
import OwnerOverviewController, { Props } from "./OwnerOverviewController.web";
import Chart from "react-apexcharts";
import { boatBackground } from "./assets";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
import { withRouter } from "react-router";
import StorageProvider from "../../../framework/src/StorageProvider.web";


class OwnerOverView extends OwnerOverviewController {
  constructor(props: Props) {
    super(props);
  }

  async componentDidMount() {
    const authToken = await StorageProvider.get("authToken");
    // const accountInfo = await StorageProvider.get("accountInfo");
    if (authToken) {
      this.setState(
        {
          authToken: authToken,
          // accountInfo : accountInfo && JSON.parse(accountInfo)
        },
        () => {
          this.getOwnerOverview();
          this.getPersonalInfo();
        }
      );
    }
    
  }
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.main}>
        <img alt="hello" src={boatBackground} width="100%" />
        <Container maxWidth="lg">
          <Box
            display="flex"
            flexWrap="wrap"
            justifyContent="space-between"
            bgcolor="background.paper"
          >
            <Box display="flex">
             {this.state.ratingInfo && this.state.ratingInfo?.avatar?.thumb?.url ?  <Avatar src={this.state.ratingInfo?.avatar?.thumb?.url} className={classes.large}></Avatar> : <Avatar className={classes.large}>{`${this.state.ratingInfo?.first_name?.charAt(0) || ""} ${this.state.ratingInfo?.last_name?.charAt(0) || ""}`}</Avatar>}
              <Box mx={4} my={3}>
                <Typography
                  variant="h5"
                  className={classes.blueHeading}
                  gutterBottom
                >
                  {`${this.state.ratingInfo?.first_name || ""} ${this.state.ratingInfo?.last_name || ""}`}
                </Typography>
                <Typography variant="h7" className={classes.greySubtext}>
                  Rating - {this.state?.ratingInfo?.average_rating} <StarIcon className={classes.star} />
                </Typography>
              </Box>
            </Box>
            <Box my={4}>
              <Button variant="outlined" color="primary" onClick={()=>{this.props.history.push("/userDashboard/personalInformation")}}>
                Edit Profile
              </Button>
            </Box>
          </Box>
          <Box>
            <Grid container spacing={3}>
              <Grid item xs={6} md={3}>
                <Box className={classes.card}>
                  <Typography
                    variant="h5"
                    className={classes.blueHeading}
                    gutterBottom
                  >
                    {this.state?.overviewData?.total_earning ||
                      "No Data Available"}
                  </Typography>
                  <Typography variant="h7" className={classes.greySubtext}>
                    Total earnings
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={6} md={3}>
                <Box className={classes.card}>
                  <Typography
                    variant="h5"
                    className={classes.blueHeading}
                    gutterBottom
                  >
                    {this.state?.overviewData?.completed_booking ||
                      0}
                  </Typography>
                  <Typography variant="h7" className={classes.greySubtext}>
                    Completed Bookings
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={6} md={3}>
                <Box className={classes.card} style={{ height: "100%" }}>
                  <Typography
                    variant="h5"
                    className={classes.blueHeading}
                    gutterBottom
                  >
                    {this.state?.overviewData?.completed_booking1 || "100%"}
                  </Typography>
                  <Typography variant="h7" className={classes.greySubtext}>
                    Response Rate
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={6} md={3}>
                <Box className={classes.card}>
                  <Typography
                    variant="h5"
                    className={classes.blueHeading}
                    gutterBottom
                  >
                    {this.state?.overviewData?.this_month_view_count ||
                      0}
                  </Typography>
                  <Typography variant="h7" className={classes.greySubtext}>
                    Boat visits this month
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Box style={{ height: "100%" }} className={classes.card1}>
                  <Box
                    className={classes.card2}
                    display="flex"
                    flexWrap="wrap"
                    justifyContent="space-between"
                  >
                    <Box>
                      <Typography
                        variant="h6"
                        className={classes.blueHeading}
                        gutterBottom
                      >
                        Boat bookings
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="h7" className={classes.greySubtext}>
                        Last 3 months
                      </Typography>
                    </Box>
                  </Box>
                  <Box>
                    {this.state.tableRows && this.state.tableRows.length > 0 ?   <TableContainer
                      style={{ boxShadow: "none" }}
                      component={Paper}
                    >
                      <Table
                        className={classes.table}
                        aria-label="simple table"
                      >
                        <TableHead className={classes.Head}>
                          <TableRow>
                            <TableCell>Name</TableCell>
                            {this.state.tableHeaders.map((month) => {
                              return (
                                <TableCell key={month} align="right">
                                  {month}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {this.state.tableRows.map((boat) => {
                            return (
                              <TableRow key={boat.boatName}>
                                <TableCell component="th" scope="row">
                                  {boat.boatName || "Not Availalbe"}
                                </TableCell>
                                {this.state.tableHeaders.map((month) => {
                                  return (
                                    <TableCell key={month} align="right">
                                      {boat[month] || 0}
                                    </TableCell>
                                  );
                                })}  
                                
                              </TableRow>
                            );
                          })}

                        </TableBody>
                      </Table>
                    </TableContainer>
                   : <Typography align="center">{"No Booking Available"}</Typography>}
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box className={classes.card1 + " " + classes.card2}>
                  <Typography
                    align="left"
                    variant="h6"
                    gutterBottom
                    className={classes.blueHeading}
                    gutterBottom
                  >
                    Profile Visits
                  </Typography>
                  {this.state.chartOption && <Chart
                    options={this.state.chartOption.options}
                    series={this.state.chartOption.series}
                    type="bar"
                  />}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </div>
    );
  }
}
const OwnerOverViewWithRouter = withRouter(OwnerOverView);
const OwnerOverViewAlertBox = withAlertBox(OwnerOverViewWithRouter);
const OwnerOverViewLoader = withLoader(OwnerOverViewAlertBox);
const OwnerOverViewToast = withToast(OwnerOverViewLoader);
export default withStyles((theme) =>
  createStyles({
    large: {
      width: theme.spacing(20),
      height: theme.spacing(20),
      marginTop: "-25%",
    },
    card: {
      paddingLeft: theme.spacing(5.6),
      paddingTop: theme.spacing(4.4),
      paddingBottom: theme.spacing(4),
      paddingRight: theme.spacing(4),
      margin: "auto 0",
      border: "solid 2px #e4e4e4",
      borderRadius: "5px",
    },
    card1: {
      border: "solid 2px #e4e4e4",
      borderRadius: "5px",
    },
    card2: {
      padding: theme.spacing(4),
    },
    Head: {
      backgroundColor: "#e4e4e4",
    },
    main: {
      fontFamily: "Montserrat",
    },
    blueHeading: {
      color: "#004277",
      fontWeight: "600",
      lineHeight: 1.2,
    },
    greySubtext: {
      fontSize: "16px",
      fontWeight: 500,
      lineHeight: 1.25,
      color: "#343a40",
    },
    star: {
      color: "gold",
      verticalAlign: "bottom",
    },
  })
)(OwnerOverViewToast);
