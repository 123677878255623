//@ts-nocheck
import React from "react";
import EmailAccountVerifyMobileNoController, {
  Props,
} from "./EmailAccountVerifyMobileNoController.web";
import {
  withStyles,
  Grid,
  Typography,
  createStyles,
  Button,
  AppBar,
  Toolbar,
  Box,
  IconButton,
  Hidden,
  Drawer,
  ListItem,
  List,
  ListItemText,
  TextField,
  MenuItem
} from "@material-ui/core";
import Footer from "./footer.web";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { withRouter, Redirect } from "react-router";
import withDialogBox from "../../../components/src/withDialog.web";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import { arrow, logo } from "./assets";


const drawerWidth = 240;
const PageLinks = [
  { displayName: "Login", route: "/basicAuth/login" },
  { displayName: "Signup", route: "/basicAuth/signup" },
  { displayName: "How it works", route: "/howItWorks" },
  { displayName: "Support", route: "/faq" },
];

class EmailAccountVerifyMobileNo extends EmailAccountVerifyMobileNoController {
  constructor(props: Props) {
    super(props);
  }
  async componentDidMount() {
    let verificationToken = await StorageProvider.get("signuptoken")
    if (verificationToken) {
      this.setState(
        {
          verificationToken: verificationToken,
          loading : false
        }
      );
    }
  }
  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };
  
  render() {
    const { classes } = this.props;
    return !this.state.loading ? (
      this.state.verificationToken === null ? (
        <Redirect to="/basicAuth/signup" />
      ) : (
       <>
          <AppBar
            position="static"
            color="default"
            elevation={0}
            className={classes.appBar}
          >
            <Toolbar className={classes.toolbar}>
              <Box className={classes.toolbarLogo}>
                <img onClick={()=>this.props.history.push("/")}  src={logo} style={{width:'40%'}} alt="boaturu" />
              </Box>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                edge="start"
                onClick={this.handleDrawerToggle}
                className={classes.menuButton}
              >
                <MenuIcon />
              </IconButton>
              <Hidden xsDown implementation="css">
                <nav>
                  {PageLinks.map((link) => {
                    return (
                      <Typography
                        key={link.displayName}
                        variant="button"
                        color="secondary"
                        className={classes.link}
                        onClick={() => {
                          this.goToSelectedRoute(link.route);
                        }}
                      >
                        {link.displayName}
                      </Typography>
                    );
                  })}
                </nav>
              </Hidden>
              <Hidden smUp implementation="css">
                <Drawer
                  variant="persistent"
                  anchor={"right"}
                  open={this.state.mobileOpen}
                  onClose={this.handleDrawerToggle}
                  classes={{
                    paper: classes.drawerPaper,
                  }}
                  SlideProps={{ unmountOnExit: true }}
                >
                  <IconButton
                    onClick={this.handleDrawerToggle}
                    className={classes.closeMenuButton}
                  >
                    <CloseIcon />
                  </IconButton>
                  <div>
                    <List>
                      {PageLinks.map((link, index) => (
                        <ListItem button key={link.displayName} onClick={() => {
                          this.goToSelectedRoute(link.route);
                        }}>
                          <ListItemText 
                            className={classes.link}
                            primary={link.displayName} 
                          />
                        </ListItem>
                      ))}
                    </List>
                  </div>
                </Drawer>
              </Hidden>
            </Toolbar>
          </AppBar>
          <Grid
            container
            justify="center"
            style={{ paddingTop: "20px" }}
          >
            <Grid md={5} xs={12} item container spacing={3}>
            <Grid item xs={12} container spacing={1}>
              <Grid xs={12} item>
                <Typography
                  className={classes.verifyAccountHeading}
                  gutterBottom
                >
                  Please verify your account
                </Typography>
                <Typography className={classes.verifyAccountSubHeading}>
                  In order to rent or list a boat on Float, we need to verify
                  your account
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} container spacing={1} justify="center">
              <Grid item xs={12}>
                <div style={{ height: 0, border: "solid 1px #0174cf" }} />
              </Grid>
            </Grid>
            <Grid item xs={12} container spacing={1}>
              <Grid xs={12} item>
                <Typography className={classes.sectionHeading} gutterBottom>
                3. Enter your mobile number we'll text you a verification code
                </Typography>
              </Grid>
              <Grid xs={2} item>
                  <TextField variant="standard" select name="countaryCode" value={this.state.countaryCode} onChange={(evt)=>{this.setState({countaryCode : evt.target.value})}} fullWidth>
                    <MenuItem value={91}>91</MenuItem>
                  </TextField>
              </Grid>
              <Grid xs={8} item>
                  <TextField variant="standard" name="mobileno" value={this.state.mobileNo} onChange={(evt)=>{this.setState({mobileNo : evt.target.value})}} fullWidth></TextField>
              </Grid>
            </Grid>
            
            <Grid item xs={12} container spacing={1} justify="center">
              <Grid xs={10} item>
                <Button color="primary" style={{width : '350px'}} variant="contained" disabled={!this.state.countaryCode && !this.state.mobileNo} onClick={()=>{this.sendOTP()}}>
                  Proceed
                </Button>
              </Grid>
            </Grid>
           </Grid>
          </Grid>
          <Footer></Footer>
           </>
      )
    ) : null;
  }
}
const EmailAccountVerifyMobileNoWithRouter = withRouter(
  EmailAccountVerifyMobileNo
);
const EmailAccountVerifyMobileNoWithDialogBox = withDialogBox(
  EmailAccountVerifyMobileNoWithRouter
);
const EmailAccountVerifyMobileNoAlertBox = withAlertBox(
  EmailAccountVerifyMobileNoWithDialogBox
);
const EmailAccountVerifyMobileNoLoader = withLoader(
  EmailAccountVerifyMobileNoAlertBox
);
const EmailAccountVerifyMobileNoToast = withToast(
  EmailAccountVerifyMobileNoLoader
);
export default withStyles((theme) =>
  createStyles({
    paper: {
      margin: "48px auto",
    },
    pageHeading: {
      fontFamily: "Montserrat",
      fontSize: "20px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.15,
      letterSpacing: "normal",
      textTransform: "none",
      color: "#1b4050",
    },
    pageSubheading: {
      fontFamily: "Montserrat",
      fontSize: "15px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.2,
      letterSpacing: "0.08px",
      textTransform: "none",
      color: "#354f6c",
    },
    marginTop1: {
      marginTop: "30px",
    },
    marginTop2: {
      marginTop: "50px",
    },
    appBar: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      width: "100%",
      boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
      backgroundColor: "#ffffff",
      height: theme.spacing(7.5),
    },
    toolbar: {
      flexWrap: "nowrap",
    },
    toolbarLogo: {
      flexGrow: 1,
    },
    link: {
      margin: theme.spacing(1, 2.5),
      fontFamily: "Roboto",
      fontSize: "14px",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.14,
      letterSpacing: "normal",
      textTransform: "none",
      color : '#545454',
      cursor: "pointer",
      opacity: 0.6
    },
    verifyAccountHeading: {
      fontFamily: "Montserrat",
      fontSize: "24px",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.19,
      letterSpacing: "normal",
      color: "#183b56",
    },
    verifyAccountSubHeading: {
      fontFamily: "Roboto",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.19,
      letterSpacing: "normal",
      color: "#545454",
    },
    sectionHeading: {
      fontFamily: "Roboto",
      fontSize: "16px",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.15,
      letterSpacing: "normal",
      color: "#545454",
    },

    drawer: {
      [theme.breakpoints.up("sm")]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },
    drawerPaper: {
      width: drawerWidth,
    },
    closeMenuButton: {
      marginRight: 0,
      marginLeft: "auto",
    },
    marginTop: {
      marginTop: theme.spacing(4),
    },
    footerLinkHeading: {
      fontFamily: "Roboto",
      fontSize: "16px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.15,
      letterSpacing: "normal",
      color: "#545454",
    },
    footerLink: {
      fontFamily: "Roboto",
      fontSize: "16px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.15,
      letterSpacing: "normal",
      color: "#545454",
    },
    footer: {
      padding: theme.spacing(3, 2),
      marginTop: "120px",
      backgroundColor: "#f6f6f6",
    },
  })
)(EmailAccountVerifyMobileNoToast);
