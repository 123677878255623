//@ts-nocheck
import React from "react";
import {
  withStyles,
  Grid,
  Typography,
  Button,
  createStyles,
} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import { success, arrow } from "./assets";
import SignUpVerifyLinkDialogController, {
  Props,
} from "./SignUpVerifyLinkDialogController.web";
import { withRouter } from "react-router";
import  withAlertBox  from "../../../components/src/withAlertBox.Web";
import  withToast  from "../../../components/src/withSnackBar.Web";
import  withLoader  from "../../../components/src/withLoader.Web";

class SignUpVerifyLinkDialog extends SignUpVerifyLinkDialogController {
  constructor(props: Props) {
    super(props);
  }
  goToSelectedRoute = (routeName) => {
    this.props.onSubmit(routeName);
  };
  render() {
    const { classes } = this.props;
    return (
      <DialogContent className={classes.dialogStyles}>
        <Grid container justify="center" spacing={2}>
          <Grid item xs={12}>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <img src={success} />
            </div>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="h6"
              gutterBottom
              align="center"
              className={classes.confirmationText}
            >
              Account Verified Successfully!
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography align="center" className={classes.confirmationSubTitle}>
              Login to continue
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  this.goToSelectedRoute("/basicAuth/login");
                }}
              >
                Login
              </Button>
            </div>
          </Grid>
        </Grid>
      </DialogContent>
    );
  }
}
const SignUpVerifyLinkDialogAlertBox = withAlertBox(SignUpVerifyLinkDialog);
const SignUpVerifyLinkDialogLoader = withLoader(SignUpVerifyLinkDialogAlertBox);
const SignUpVerifyLinkDialogToast = withToast(SignUpVerifyLinkDialogLoader)
const SignUpVerifyLinkDialogWithRouter = withRouter(SignUpVerifyLinkDialogToast);
export default withStyles((theme) =>
  createStyles({
    confirmationText: {
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
      fontFamily: "Roboto",
      fontSize: "20px",
      fontWeight: 500,
      lineHeight: 1.15,
      color: "#1b4050",
    },
    confirmationSubTitle: {
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
      fontFamily: "Roboto",
      fontSize: "16px",
      fontWeight: "normal",
      lineHeight: 1.19,
      color: "#1b4050",
    },
  })
)(SignUpVerifyLinkDialogWithRouter);
