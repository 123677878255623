//@ts-nocheck
import React from "react";
import EmailAccountForgotPasswordController, {
  Props,
} from "./EmailAccountForgotPasswordController.web";
import {
  withStyles,
  Grid,
  Typography,
  createStyles,
  Button,
} from "@material-ui/core";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { google, facebook, arrow,backArrow } from "./assets";
import  InputField  from "../../../components/src/InputFiled.web";
import { withRouter } from "react-router";
import withDialogBox from "../../../components/src/withDialog.web";
import  withAlertBox  from "../../../components/src/withAlertBox.Web";
import  withToast  from "../../../components/src/withSnackBar.Web";
import  withLoader  from "../../../components/src/withLoader.Web";


const ForgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email("Email must be in valid format!")
    .required("This field is required."),
});
class EmailAccountForgotPassword extends EmailAccountForgotPasswordController {
  constructor(props: Props) {
    super(props);
  }
  goToSelectedRoute = (routeName) => {
    const {history } = this.props;
    history.push(routeName)
  };
  render() {
    const { classes } = this.props;
    return (
      <Formik
        initialValues={{
          email: "",
        }}
        validationSchema={ForgotPasswordSchema}
        onSubmit={(values, actions) => {
          this.handleForgotPassword(values);
        }}
      >
        {(formikProps) => {
          return (
            <Form autoComplete="off" noValidate>
              <Grid container item xs={12} sm={12} spacing={3}>
              <Grid item xs={12} onClick={()=>{this.goToSelectedRoute('/basicAuth/login')}} style={{cursor : 'pointer'}}>
                  <img src={backArrow} className={classes.backbuttoniamge}/>
                  <Typography
                    variant="button"
                    className={classes.BackButtonHeading}
                    align="left"
                    onClick={()=>{this.goToSelectedRoute('/basicAuth/login')}}
                  >
                    Back to Login
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    component="div"
                    className={classes.pageHeading}
                    align="left"
                  >
                    Forgot Password?
                  </Typography>
                </Grid>
                <Grid item xs={12} container spacing={2}>
                    <Grid item xs={12}>
                    <Typography
                      variant="caption"
                      color="textSecondary"
                      className={classes.pageSubheading}
                    >
                      Enter your registered email address below and
                      we will send you the password reset link.
                    </Typography>
                    </Grid>
                  </Grid>
                
                <Grid item xs={12}>
                  <Field
                    component={InputField}
                    required
                    fullWidth
                    label="Email"
                    name="email"
                  />
                </Grid>
                <Grid item xs={12}>
                  <div className={classes.marginTop1}>
                    <Button
                      color="primary"
                      type="submit"
                      variant="contained"
                      endIcon={<img style={{ height: "10px" }} src={arrow} />}
                    >
                      Send Link
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    );
  }
}
const EmailAccountForgotPasswordWithRouter = withRouter(EmailAccountForgotPassword);
const EmailAccountForgotPasswordAlertBox = withAlertBox(EmailAccountForgotPasswordWithRouter);
const EmailAccountForgotPasswordLoader = withLoader(EmailAccountForgotPasswordAlertBox);
const EmailAccountForgotPasswordToast = withToast(EmailAccountForgotPasswordLoader)
export default withStyles((theme) =>
  createStyles({
    paper: {
      margin: "48px auto",
    },
    backbuttoniamge : {
      width : '12px',
      height: '12px',
      objectFit: 'contain'
    },
    BackButtonHeading: {
      marginLeft : '8px',
      fontFamily: "Roboto",
      fontSize: "12px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.17,
      letterSpacing: "normal",
      textTransform: "none",
      color: "#0174ce",
      cursor : 'pointer'
    },
    pageHeading: {
      fontFamily: "Montserrat",
      fontSize: "20px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.15,
      letterSpacing: "normal",
      textTransform: "none",
      color: "#1b4050",
    },
    pageSubheading: {
      fontFamily: "Montserrat",
      fontSize: "15px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.4,
      letterSpacing: "normal",
      textTransform: "none",
      color: "#717b90",
    },
    forgetPassword: {
      marginLeft: theme.spacing(4),
    },
    donttext: {
      fontFamily: "Roboto",
      fontSize: "14px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.14,
      letterSpacing: "0.42px",
      textTransform: "none",
      color: "#1b4050",
    },
  })
)(withDialogBox(EmailAccountForgotPasswordToast));
