import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import SignUpVerifyLinkDialog from "./SignUpVerifyLinkDialog.web";

import { RouterProps } from "react-router";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { dialogBoxProps } from "../../../components/src/withDialog.web";
import { withAlertBoxProps } from "../../../components/src/withAlertBox.Web";
import { withToastProps } from "../../../components/src/withSnackBar.Web";
import { withLoaderProps } from "../../../components/src/withLoader.Web";
export const configJSON = require("./config");

export type Props = RouterProps &
  dialogBoxProps &
  withAlertBoxProps &
  withToastProps &
  withLoaderProps & {
    id: string;
    // Customizable Area Start
    // Customizable Area End
  };
interface S {
  // Customizable Area Start
  loading : boolean;
  otpToken : string;
  mobile : string;
  mobileOpen : boolean
  otp : string

  // Customizable Area End
}

interface SS {
  id: any;
}

export default class EmailAccountVerifyOTPController extends BlockComponent<
  Props,
  S,
  SS
> {
  verifyotpLinkCallId : String = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
      // Customizable Area End
    ];
    this.state = {
      mobile : "",
      loading : true,
      otpToken : "",
      mobileOpen : false,
      otp : ""
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if(apiRequestCallId === this.verifyotpLinkCallId){
        this.props.hideLoader();
        if (responseJson && responseJson?.meta && responseJson?.meta?.message) {
          this.props.showToast({
            type: "success",
            message: responseJson?.meta?.message,
          });
          const { history } = this.props;
          history.push("/basicAuth/login");
        } else {
          this.parseApiErrorResponse(responseJson);
        }
      }
    } else if (getName(MessageEnum.AlertMessage) === message.id) {
      const title = message.getData(getName(MessageEnum.AlertTitleMessage));
      var AlertBodyMessage = message.getData(
        getName(MessageEnum.AlertBodyMessage)
      );
      this.props.showAlert({title: 'Error',message : AlertBodyMessage})
    }
  }
  verifyOTP = () => {
    this.props.showLoader();
    const headers = {
      "Content-Type": configJSON.ApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.verifyotpLinkCallId = requestMessage.messageId;
    const httpBody = {
      "pin": this.state.otp,
      "token": this.state.otpToken
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "accounts/verify_phone_number"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPOST
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  
}
