//@ts-nocheck
import React, { Component } from "react";
import {
  withStyles,
  Grid,
  Typography,
  createStyles,
  Button,
  Paper,
  AppBar,
  Toolbar,
  TextField,
  Menu,
  MenuItem,
  InputAdornment,
  IconButton,
  Box,
  Avatar,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemSecondaryAction,
  Hidden,
} from "@material-ui/core";
import FooterController, { Props } from "./FooterController.web";

import withDialog from "../../../components/src/withDialog.web";
import InstagramIcon from "@material-ui/icons/Instagram";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import { withRouter } from "react-router";
import FooterDialog from "./FooterDialog.web";
import { logo } from "./assets";
class Footer extends FooterController {
    openTermsOfService = () => {
        this.props
          .openDialogBox({
            title: "Terms Of Service",
            withCustomDialog: false,
            renderedComponent: FooterDialog,
            dataToPass: { type : "Terms Of Service" },
            catchOnCancel: true,
            fullScreen : true
          })
          
      };
      openRentalAggremanet = () => {
        this.props
          .openDialogBox({
            title: "Rental Aggrement",
            withCustomDialog: false,
            renderedComponent: FooterDialog,
            dataToPass: { type : "Rental Aggrement" },
            catchOnCancel: true,
            fullScreen : true
          })
          
      };
      openCookiePolicy = () => {
        this.props
          .openDialogBox({
            title: "Cookie Policy",
            withCustomDialog: false,
            renderedComponent: FooterDialog,
            dataToPass: { type : "Cookie Policy" },
            catchOnCancel: true,
            fullScreen : true
          })
          
      };
      openContactus = ()=>{
        this.props
          .openDialogBox({
            title: "Contact us",
            withCustomDialog: false,
            renderedComponent: FooterDialog,
            dataToPass: { type : "Contact us" },
            catchOnCancel: true,
            fullScreen : true
          })
      }
        
  render() {
    const { classes } = this.props;
    const { theme } = this.props;
    return (
      <footer className={classes.footer}>
        <Grid container justify="center">
          <Grid item xs={11}>
            <Box display="inline-flex" alignItems="center" pb={2}>
              <Box>
                {/* <Avatar component="span" /> */}
                <img
                    onClick={() => this.props.history.push("/")}
                    src={logo}
                    style={{ width: "40%" }}
                    alt="boaturu"
                  />
              </Box>
              {/* <Typography variant="h5" component="span">
                Logo
              </Typography> */}
            </Box>
          </Grid>

          <Grid container item xs={11} justify="space-between">
            <Grid item xs={12} md={3} className={classes.mediaButtonBox}>
              <Typography>Rent boats and yatch with ease.</Typography>
              <Typography>Sample Tagline</Typography>
              <Box pt={2} pb={1}>
                <Box pr={2} display="inline">
                  <FacebookIcon />
                </Box>
                <Box pr={2} display="inline">
                  <TwitterIcon />
                </Box>
                <Box pr={2} display="inline">
                  <InstagramIcon />
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={2}
              container
              className={classes.footerTable}
              spacing={2}
            >
              <Grid item xs={12}>
                <Typography
                  display="block"
                  className={classes.footerLinkHeading}
                  gutterBottom
                >
                  About
                </Typography>
              </Grid>

              <Grid item xs={6} sm={12}>
                <Typography
                  display="block"
                  className={classes.footerLink}
                  gutterBottom
                  onClick={() => {
                    window.location.replace("/#aboutBoatru");
                  }}
                >
                  About Us
                </Typography>
              </Grid>
              <Grid item xs={6} sm={12}>
                <Typography
                  display="block"
                  className={classes.footerLink}
                  gutterBottom
                  onClick={()=>{this.props.history.push("/faq")}}
                >
                  Support
                </Typography>
              </Grid>
              <Grid item xs={6} sm={12}>
                <Typography
                  display="block"
                  className={classes.footerLink}
                  gutterBottom
                  onClick={() => {
                    window.location.replace("/#Testimonials");
                  }}
                >
                  Testimonials
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={2}
              container
              className={classes.footerTable}
              spacing={2}
            >
              <Grid item xs={12}>
                <Typography
                  display="block"
                  className={classes.footerLinkHeading}
                  gutterBottom
                >
                  Company
                </Typography>
              </Grid>

              <Grid item xs={6} sm={12}>
                <Typography
                  display="block"
                  className={classes.footerLink}
                  gutterBottom
                  onClick={()=>{this.openTermsOfService()}}
                >
                  Terms of service
                </Typography>
              </Grid>
              <Grid item xs={6} sm={12}>
                <Typography
                  display="block"
                  className={classes.footerLink}
                  gutterBottom
                  onClick={()=>{this.openRentalAggremanet()}}
                >
                  Rental agreements
                </Typography>
              </Grid>
              <Grid item xs={6} sm={12}>
                <Typography
                  display="block"
                  className={classes.footerLink}
                  gutterBottom
                  onClick={()=>{this.openCookiePolicy()}}
                >
                  Cookies policy
                </Typography>
              </Grid>
              <Grid item xs={6} sm={12}>
                <Typography
                  display="block"
                  className={classes.footerLink}
                  gutterBottom
                  onClick={()=>{this.downloadFile()}}
                >
                  Rules and regulations
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={2}
              container
              className={classes.footerTable}
              spacing={2}
            >
              <Grid item xs={12}>
                <Typography
                  display="block"
                  className={classes.footerLinkHeading}
                  gutterBottom
                >
                  Learn More
                </Typography>
              </Grid>

              <Grid item xs={6} sm={12}>
                <Typography
                  display="block"
                  className={classes.footerLink}
                  gutterBottom
                  onClick={()=>{this.props.history.push("/faq")}}
                >
                  FAQs
                </Typography>
              </Grid>
              <Grid item xs={6} sm={12}>
                <Typography
                  display="block"
                  className={classes.footerLink}
                  gutterBottom
                  onClick={()=>{this.props.history.push("/tipsForRenters")}}
                >
                  Tips for renters
                </Typography>
              </Grid>
              <Grid item xs={6} sm={12}>
                <Typography
                  display="block"
                  className={classes.footerLink}
                  gutterBottom
                  onClick={()=>{this.props.history.push("/tipsForOwners")}}
                >
                  Tips for owners
                </Typography>
              </Grid>
              <Grid item xs={6} sm={12}>
                <Typography
                  display="block"
                  className={classes.footerLink}
                  gutterBottom
                  onClick={()=>{this.openContactus()}}
                >
                  Contact us
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </footer>
    );
  }
}
const FooterWithDialog = withDialog(Footer)
const FooterWithRouter = withRouter(FooterWithDialog);
export default withStyles(
  (theme) =>
    createStyles({
      ItemContainer: {
        boxShadow: "0 3px 8px 0 rgba(0, 0, 0, 0.1)",
        border: "solid 1px #b8b8b8",
        borderRadius: "10px",
        backgroundColor: "#ffffff",
        height: "auto",
        marginTop: "20px",
      },
      mainPost: {
        position: "relative",
        backgroundColor: theme.palette.grey[800],
        color: theme.palette.common.white,
        marginBottom: theme.spacing(4),
        backgroundImage: "url(https://source.unsplash.com/random)",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        height: theme.spacing(50),
      },
      overlay: {
        position: "absolute",
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        backgroundColor: "rgba(0,0,0,.3)",
      },
      mainPostContent: {
        position: "relative",
        padding: theme.spacing(2),
        color: "#FFF",
        fontFamily: "Montserrat",
        [theme.breakpoints.up("md")]: {
          padding: theme.spacing(3),
          paddingRight: 0,
        },
      },
      mainPostSecondaryContent: {
        position: "relative",
        padding: theme.spacing(1),
        top: "50%",

        fontFamily: "Montserrat",
        [theme.breakpoints.down("sm")]: {
          borderRadius: "0px",
        },
        borderRadius: "8px",
        boxShadow: "0 10px 12px 0 rgba(0, 0, 0, 0.11)",
        backgroundColor: "rgb(1, 116, 207)",
      },
      boatNearme: {
        [theme.breakpoints.up("xs")]: {
          marginTop: "280px",
        },
        [theme.breakpoints.up("sm")]: {
          marginTop: "165px",
        },
        [theme.breakpoints.up("md")]: {
          marginTop: "0px",
        },
      },
      contentContainer: {
        marginBottom: "50px !important",
      },
      ListTourPost: {
        position: "relative",
        backgroundColor: theme.palette.grey[800],
        color: theme.palette.common.white,
        backgroundImage: "url(https://source.unsplash.com/random)",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        height: theme.spacing(50),
      },
      ListTourPostContent: {
        position: "relative",
        padding: theme.spacing(2),
        color: "#FFF",
        paddingTop: theme.spacing(6),
        [theme.breakpoints.up("md")]: {
          paddingLeft: theme.spacing(6),
        },
      },
      appBar: {
        background: "transparent",
      },
      toolbar: {
        display: "flex",
      },
      toolbarTitle: {
        flexGrow: 1,
      },
      link: {
        fontFamily: "Montserrat",
        fontSize: "14px",
        fontWeight: "bold",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1.33,
        letterSpacing: "normal",
        color: "#FFF",
        cursor: "pointer",
      },
      loginButton: {
        backgroundColor: "#fff",
        color: "blue",
        fontFamily: "Montserrat",
        fontSize: "12px",
        fontWeight: "bold",
        fontStretch: "normal",
        fontStyle: "normal",
        letterSpacing: "normal",
        borderRadius: "5px",
      },
      inputfieledroot: {
        borderRadius: "10px",
        backgroundColor: "rgb(52, 144, 217)",
        color: "#fff",
      },
      input: {
        fontSize: "16px",
        fontFamily: "Montserrat",
        fontWeight: 600,
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1.19,
        letterSpacing: "normal",
        color: "#fff",
      },
      lableroot: {
        fontFamily: "Montserrat",
        fontSize: "14px",
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1.14,
        letterSpacing: "normal",
        color: "#fff",
      },
      footerLinkHeading: {
        fontFamily: "Montserrat",
        fontSize: "16px",
        fontWeight: "bold",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1.15,
        marginBottom: "0.9rem",
        marginTop: "2rem",
        letterSpacing: "normal",
        color: "#545454",
      },
      footerLink: {
        fontFamily: "Montserrat",
        fontSize: "16px",
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1.15,
        letterSpacing: "normal",
        color: "#545454",
        cursor : 'pointer'
      },
      footer: {
        // padding: theme.spacing(0, 3.5),
        marginTop: "50px",
        // backgroundColor: "#f6f6f6",
      },
      footerTable: {
        [theme.breakpoints.up("sm")]: {
          display: "block",
        },
      },
      mediaButtonBox: {
        paddingTop: "1.8rem",
        [theme.breakpoints.down("sm")]: {
          paddingTop: "0.8rem",
        },
      },
      searchHeading: {
        fontFamily: "Montserrat",
      },
      subHeading: {
        fontFamily: "Montserrat",
        fontWeight: 600,
      },
      seeButton: {
        fontFamily: "Montserrat",
        borderRadius: "5px",
        borderWidth: "2px",
      },
      getStartedButton: {
        fontFamily: "Montserrat",
        borderRadius: "3px",
        borderWidth: "2px",
      },
      listButton: {
        fontFamily: "Montserrat",
        borderRadius: "3px",
        backgroundColor: "#FFFFFF",
        borderWidth: "2px",
        color: "#0174cf",
      },
      learnButton: {
        fontFamily: "Montserrat",
        borderRadius: "3px",
        backgroundColor: "transparent",
        color: "#FFFFFF",
        border: "solid 2px #FFFFFF",
      },
      smallHeading: {
        fontFamily: "Montserrat",
        fontWeight: 600,
        color: "#0FA6E0",
      },
      roundCorner: {
        borderRadius: "5px",
      },
      nearMeSlider: {
        width: "95%",
        height: "400px",
        borderRadius: "5px",
      },
      nearYouSlider: {
        width: "95%",
        borderRadius: "5px",
      },
      boatsInArea: {
        width: "95%",
        height: theme.spacing(20),
        margin: "2% 10%",
        borderRadius: "5px",
        cursor: "pointer",
      },
      seeAllxsUp: {
        fontFamily: "Montserrat",
        borderRadius: "5px",
        borderWidth: "2px",
        [theme.breakpoints.down("sm")]: {
          display: "none",
        },
      },
      seeAllxsDown: {
        display: "none",
        fontFamily: "Montserrat",
        borderRadius: "5px",
        borderWidth: "2px",
        [theme.breakpoints.down("sm")]: {
          display: "inherit",
        },
      },
      StepIcon: {
        root: {
          height: "1.5em",
          width: "1.5em",
        },
      },
      breakpoints: {
        lg: theme.breakpoints.values.lg,
      },
      customStepper: {
        display: "flex",
        margin: "10px",
        justifContent: "center",
        alignItems: "center",
        color: "white",
        backgroundColor: "#00A1DF",
        height: "2em",
        width: "2em",
        fontSize: "1rem",
        borderRadius: "50%",
        border: "5px #CCE3F6 solid",
      },
      activityImage: {
        position: "relative",
        color: theme.palette.common.black,
        marginBottom: theme.spacing(1),
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        height: theme.spacing(50),
        width: "95%",
      },
      papularImage: {
        position: "relative",
        color: theme.palette.common.black,
        marginBottom: theme.spacing(1),
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        height: theme.spacing(30),
        width: "95%",
      },
      primaryListText: {
        fontFamily: "Montserrat",
        fontSize: "20px",
        fontWeight: "bold",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1.21,
        letterSpacing: "normal",
        color: "#fff",
      },
      secondaryListText: {
        fontFamily: "Montserrat",
        fontSize: "14px",
        fontWeight: 500,
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1.25,
        letterSpacing: "normal",
        color: "#fff",
      },
      primaryStepText: {
        fontFamily: "Montserrat",
        fontSize: "20px",
        fontWeight: "bold",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1.21,
        letterSpacing: "normal",
        color: "#004277",
      },
      secondaryStepText: {
        fontFamily: "Montserrat",
        fontSize: "14px",
        fontWeight: 900,
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1.25,
        letterSpacing: "normal",
      },
    }),
  { withTheme: true }
)(FooterWithRouter);
