//@ts-nocheck
import React from "react";
import BoatAdditionalphotoDetailController, {
  Props,
} from "./BoatAdditionalphotoDetailController.web";
import {
  withStyles,
  Grid,
  AppBar,
  Toolbar,
  Typography,
  createStyles,
  Box,
  ListItem,
  List,
  ListItemText,
  Button,
} from "@material-ui/core";
import UploadBox from "./UploadBox.Web";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import { logo,boatDetail, priview, upload } from "./assets";
import { withRouter } from "react-router";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
import withBoatIdComponent from "./withBoatIdComponent.js";

const drawerWidth = 240;
const PageLinks = [
  { displayName: "Support", route: "/faq" },
  { displayName: "Save and exit", route: "saveandexit" },
];


class BoatAdditionalphotoDetail extends BoatAdditionalphotoDetailController {
  constructor(props: Props) {
    super(props);
  }
  fileFrontInputRef = React.createRef();
  fileLeftInputRef = React.createRef();
  fileRightInputRef = React.createRef();
  fileRearInputRef = React.createRef();
  async componentDidMount() {
    const authToken = await StorageProvider.get("authToken");
    const boatId = await StorageProvider.get("boatId");
    if (authToken) {
      this.setState({
        authToken: authToken,
        boatId: boatId,
      },()=>{
        this.getBoatDetail();
      });
    }
  }
  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };
  goToSelectedRoute = (routeName) => {
    if (routeName === "saveandexit") {
    } else {
      const { history } = this.props;
      history.push(routeName);
    }
  };
  removeUploadedImage = (id, type) => {
    if (type === "front") {
      const files = this.state.frontSideImage.filter((file) => {
        return file.id !== id;
      });
      this.setState({
        frontSideImage: files,
      });
    } else if (type === "rear") {
      const files = this.state.rearSideImage.filter((file) => {
        return file.id !== id;
      });
      this.setState({
        rearSideImage: files,
      });
    } else if (type === "left") {
      const files = this.state.leftSideImage.filter((file) => {
        return file.id !== id;
      });
      this.setState({
        leftSideImage: files,
      });
    } else if (type === "right") {
      const files = this.state.rightSideImage.filter((file) => {
        return file.id !== id;
      });
      this.setState({
        rightSideImage: files,
      });
    }
  };
  fileInputClicked(type) {
    if (type === "front") {
      this.fileFrontInputRef?.current?.click();
    } else if (type === "rear") {
      this.fileRearInputRef?.current?.click();
    } else if (type === "left") {
      this.fileLeftInputRef?.current?.click();
    } else if (type === "right") {
      this.fileRightInputRef?.current?.click();
    }
  }

  filesSelected(type) {
    if (type === "front") {
      if (this.fileFrontInputRef?.current?.files?.length) {
        this.handleFiles(this.fileFrontInputRef?.current?.files, type);
      }
    } else if (type === "rear") {
      if (this.fileRearInputRef?.current?.files?.length) {
        this.handleFiles(this.fileRearInputRef?.current?.files, type);
      }
    } else if (type === "left") {
      if (this.fileLeftInputRef?.current?.files?.length) {
        this.handleFiles(this.fileLeftInputRef?.current?.files, type);
      }
    } else if (type === "right") {
      if (this.fileRightInputRef?.current?.files?.length) {
        this.handleFiles(this.fileRightInputRef?.current?.files, type);
      }
    }
  }

  handleFiles(files: FileList, type) {
    if (type === "front") {
      this.imgBase64(files[0], (result: any) => {
        const base64 = result;
        const a = {
          file: files[0],
          id: new Date().getTime(),
          base64: base64,
          direction : 'front'
        };
        this.setState({
          frontSideImage: [...this.state.frontSideImage, a],
        });
      });
    } else if (type === "rear") {
      this.imgBase64(files[0], (result: any) => {
        const base64 = result;
        const a = {
          file: files[0],
          id: new Date().getTime(),
          base64: base64,
          direction : 'rear'
        };
        this.setState({
          rearSideImage: [...this.state.rearSideImage, a],
        });
      });
    } else if (type === "left") {
      this.imgBase64(files[0], (result: any) => {
        const base64 = result;
        const a = {
          file: files[0],
          id: new Date().getTime(),
          base64: base64
          ,
          direction : 'left'
        };
        this.setState({
          leftSideImage: [...this.state.leftSideImage, a],
        });
      });
    } else if (type === "right") {
      this.imgBase64(files[0], (result: any) => {
        const base64 = result;
        const a = {
          file: files[0],
          id: new Date().getTime(),
          base64: base64,
          direction : 'right'
        };
        this.setState({
          rightSideImage: [...this.state.rightSideImage, a],
        });
      });
    }
  }
  handleDocument = (file) => {
    this.imgBase64(file, (result: any) => {});
  };
  imgBase64 = (file: any, cb: any) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      cb(reader.result);
    };
  };
  removeUploadedImageFromServer = (id,side) => {
    if (side === "front") {
      this.setState({
        frontSideImageServer :  this.state.frontSideImageServer.filter((image)=>{return image.id !== id})
      },()=>{
        this.removeImageFromServer(id);
      })
    } else if (side === "rear") {
      this.setState({
        rearSideImageServer :  this.state.rearSideImageServer.filter((image)=>{return image.id !== id})
      },()=>{
        this.removeImageFromServer(id);
      })
    } else if (side === "left") {
      this.setState({
        leftSideImageServer :  this.state.leftSideImageServer.filter((image)=>{return image.id !== id})
      },()=>{
        this.removeImageFromServer(id);
      })
    } else if (side === "right") {
      this.setState({
        rightSideImageServer :  this.state.rightSideImageServer.filter((image)=>{return image.id !== id})
      },()=>{
        this.removeImageFromServer(id);
      })
    }
  }
  render() {
    const { classes } = this.props;
    return (
      <>
        <AppBar
          position="static"
          color="default"
          elevation={0}
          className={classes.appBar}
        >
          <Toolbar className={classes.toolbar}>
            <Box className={classes.toolbarLogo}>
              <img onClick={()=>this.props.history.push("/")}  src={logo} style={{width:'22%'}} alt="boaturu" />
            </Box>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              edge="start"
              onClick={this.handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <Hidden xsDown implementation="css">
              <nav>
                {PageLinks.map((link) => {
                  return (
                    <Typography
                      key={link.displayName}
                      variant="button"
                      className={classes.link}
                      onClick={() => {
                        this.goToSelectedRoute(link.route);
                      }}
                    >
                      {link.displayName}
                    </Typography>
                  );
                })}
              </nav>
            </Hidden>
            <Hidden smUp implementation="css">
              <Drawer
                variant="persistent"
                anchor={"right"}
                open={this.state.mobileOpen}
                onClose={this.handleDrawerToggle}
                classes={{
                  paper: classes.drawerPaper,
                }}
                SlideProps={{ unmountOnExit: true }}
              >
                <IconButton
                  onClick={this.handleDrawerToggle}
                  className={classes.closeMenuButton}
                >
                  <CloseIcon />
                </IconButton>
                <List>
                  {PageLinks.map((link, index) => (
                    <ListItem
                      button
                      key={link.displayName}
                      onClick={() => {
                        this.goToSelectedRoute(link.route);
                      }}
                    >
                      <ListItemText primary={link.displayName} />
                    </ListItem>
                  ))}
                </List>
              </Drawer>
            </Hidden>
          </Toolbar>
        </AppBar>
        <Grid container className={classes.root}>
          <Grid
            item
            xs={false}
            sm={4}
            md={5}
            container
            className={classes.image}
          >
            <div className={classes.overlay} />
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={12} />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography gutterBottom className={classes.pricing}>
                Boat Details
              </Typography>
              <div className={classes.headingline} />
              <Typography gutterBottom className={classes.selectOperator}>
                Boat Photos
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography gutterBottom className={classes.leftsidelink}>
                Pricing
              </Typography>
              <Typography gutterBottom className={classes.leftsidelink}>
                Insurance
              </Typography>
              <Typography gutterBottom className={classes.leftsidelink}>
                Safety and standards
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={8} md={7}>
            <Grid
              container
              justify="center"
              style={{ padding: "16px 32px" }}
              spacing={3}
            >
              <Grid item xs={12} container justify="space-between">
                <Grid item>
                  <Box className={classes.backButtonBox} onClick={() => {
                      this.goToSelectedRoute("/boatActivity");
                    }}>
                    <Typography className={classes.backButtonBoxContent}>
                      {"<"}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  align="center"
                  className={classes.rightPageHeading}
                  gutterBottom
                >
                  It's important for renters to see your boat before they book
                  it.
                </Typography>
                <Typography
                  align="center"
                  className={classes.rightPageSubHeading}
                  gutterBottom
                >
                  We need at least 4 photos for listing your boat.
                </Typography>
              </Grid>
              <Grid item xs={12} container justify="center">
                <Grid item xs={4}>
                  {this.state.frontSideImageServer.length > 0 && <Grid
                      container
                      justify="flex-start"
                      className={classes.uploadedImageContainer}
                    >
                      <Grid
                        item
                        xs={12}
                        style={{ paddingTop: "8px", paddingLeft: "4px" }}
                      >
                        <Typography className={classes.uploadimgetitle}>
                          {" "}
                          Front Side Images
                        </Typography>
                      </Grid>
                      {this.state.frontSideImageServer.map((file, index) => {
                        return (
                          <div key={file.id} style={{ margin: "4px" }}>
                            <div style={{ position: "relative" }}>
                              <CloseIcon
                                onClick={() => {
                                  this.removeUploadedImageFromServer(file.id, "front");
                                }}
                                className={classes.crossOverImage}
                              />
                              <img
                                src={file?.photo?.url}
                                alt="uploaded image"
                                style={{
                                  height: "65px",
                                  width: "65px",
                                  borderRadius: "6px",
                                }}
                              />
                            </div>
                          </div>
                        );
                      })}
                      <>
                        <div
                          className={classes.DropContainer}
                          onClick={this.fileInputClicked.bind(this, "front")}
                        >
                          <div className={classes.dropMessage}>
                            <div className={classes.uploadIcon}></div>
                            <div className={classes.marginTop}> {""} </div>
                          </div>
                        </div>
                        <input
                          ref={this.fileFrontInputRef}
                          className={classes.fileInput}
                          type="file"
                          accept="image/*"
                          onChange={this.filesSelected.bind(this, "front")}
                        />
                      </>
                    </Grid>
                  }

                  {!(this.state.frontSideImageServer.length > 0) && <>
                    {this.state.frontSideImage  && 
                  !(this.state.frontSideImage.length > 0) ? (
                    <>
                      <Typography
                        align="center"
                        className={classes.addImageHeading}
                        onClick={this.fileInputClicked.bind(this, "front")}
                      >
                        Add
                      </Typography>
                      <Typography
                        onClick={this.fileInputClicked.bind(this, "front")}
                        align="center"
                        className={classes.addImageHeading}
                      >
                        Front side images
                      </Typography>
                      <input
                        ref={this.fileFrontInputRef}
                        className={classes.fileInput}
                        type="file"
                        accept="image/*"
                        onChange={this.filesSelected.bind(this, "front")}
                      />
                    </>
                  ) : (
                    <Grid
                      container
                      justify="flex-start"
                      className={classes.uploadedImageContainer}
                    >
                      <Grid
                        item
                        xs={12}
                        style={{ paddingTop: "8px", paddingLeft: "4px" }}
                      >
                        <Typography className={classes.uploadimgetitle}>
                          {" "}
                          Front Side Images
                        </Typography>
                      </Grid>
                      {this.state.frontSideImage.map((file, index) => {
                        return (
                          <div key={file.id} style={{ margin: "4px" }}>
                            <div style={{ position: "relative" }}>
                              <CloseIcon
                                onClick={() => {
                                  this.removeUploadedImage(file.id, "front");
                                }}
                                className={classes.crossOverImage}
                              />
                              <img
                                src={file.base64}
                                style={{
                                  height: "65px",
                                  width: "65px",
                                  borderRadius: "6px",
                                }}
                              />
                            </div>
                          </div>
                        );
                      })}
                      <>
                        <div
                          className={classes.DropContainer}
                          onClick={this.fileInputClicked.bind(this, "front")}
                        >
                          <div className={classes.dropMessage}>
                            <div className={classes.uploadIcon}></div>
                            <div className={classes.marginTop}> {""} </div>
                          </div>
                        </div>
                        <input
                          ref={this.fileFrontInputRef}
                          className={classes.fileInput}
                          type="file"
                          accept="image/*"
                          onChange={this.filesSelected.bind(this, "front")}
                        />
                      </>
                    </Grid>
                  )}</>}
                  
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                container
                justify="space-between"
                alignItems="center"
              >
                <Grid item xs={4}>
                 { this.state.leftSideImageServer.length > 0 && 
                    <Grid
                    container
                    justify="flex-start"
                    className={classes.uploadedImageContainer}
                  >
                    <Grid
                      item
                      xs={12}
                      style={{ paddingTop: "8px", paddingLeft: "4px" }}
                    >
                      <Typography className={classes.uploadimgetitle}>
                        {" "}
                        Left Side Images
                      </Typography>
                    </Grid>
                    {this.state.leftSideImageServer.map((file, index) => {
                      return (
                        <div key={file.id} style={{ margin: "4px" }}>
                          <div style={{ position: "relative" }}>
                            <CloseIcon
                              onClick={() => {
                                this.removeUploadedImageFromServer(file.id, "left");
                              }}
                              className={classes.crossOverImage}
                            />
                            <img
                              src={file?.photo?.url}
                                alt="uploaded image"
                              style={{
                                height: "65px",
                                width: "65px",
                                borderRadius: "6px",
                              }}
                            />
                          </div>
                        </div>
                      );
                    })}
                    <>
                      <div
                        className={classes.DropContainer}
                        onClick={this.fileInputClicked.bind(this, "left")}
                      >
                        <div className={classes.dropMessage}>
                          <div className={classes.uploadIcon}></div>
                          <div className={classes.marginTop}> {""} </div>
                        </div>
                      </div>
                      <input
                        ref={this.fileLeftInputRef}
                        className={classes.fileInput}
                        type="file"
                        accept="image/*"
                        onChange={this.filesSelected.bind(this, "left")}
                      />
                    </>
                  </Grid>
                 }
                 {!(this.state.leftSideImageServer.length > 0) && <>
                  {this.state.leftSideImage &&
                  !(this.state.leftSideImage.length > 0) ? (
                    <>
                      <Typography
                        align="center"
                        className={classes.addImageHeading}
                        onClick={this.fileInputClicked.bind(this, "left")}
                      >
                        Add
                      </Typography>
                      <Typography
                        align="center"
                        className={classes.addImageHeading}
                        onClick={this.fileInputClicked.bind(this, "left")}
                      >
                        Left side images
                      </Typography>
                      <input
                        ref={this.fileLeftInputRef}
                        className={classes.fileInput}
                        type="file"
                        accept="image/*"
                        onChange={this.filesSelected.bind(this, "left")}
                      />
                    </>
                  ) : (
                    <Grid
                      container
                      justify="flex-start"
                      className={classes.uploadedImageContainer}
                    >
                      <Grid
                        item
                        xs={12}
                        style={{ paddingTop: "8px", paddingLeft: "4px" }}
                      >
                        <Typography className={classes.uploadimgetitle}>
                          {" "}
                          Left Side Images
                        </Typography>
                      </Grid>
                      {this.state.leftSideImage.map((file, index) => {
                        return (
                          <div key={file.id} style={{ margin: "4px" }}>
                            <div style={{ position: "relative" }}>
                              <CloseIcon
                                onClick={() => {
                                  this.removeUploadedImage(file.id, "left");
                                }}
                                className={classes.crossOverImage}
                              />
                              <img
                                src={file.base64}
                                style={{
                                  height: "65px",
                                  width: "65px",
                                  borderRadius: "6px",
                                }}
                              />
                            </div>
                          </div>
                        );
                      })}
                      <>
                        <div
                          className={classes.DropContainer}
                          onClick={this.fileInputClicked.bind(this, "left")}
                        >
                          <div className={classes.dropMessage}>
                            <div className={classes.uploadIcon}></div>
                            <div className={classes.marginTop}> {""} </div>
                          </div>
                        </div>
                        <input
                          ref={this.fileLeftInputRef}
                          className={classes.fileInput}
                          type="file"
                          accept="image/*"
                          onChange={this.filesSelected.bind(this, "left")}
                        />
                      </>
                    </Grid>
                  )}</>}
                  
                </Grid>
                <Grid item xs={4}>
                  <img
                    src={priview}
                    style={{
                      height: "150px",
                      display: "flex",
                      margin: "auto",
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                { this.state.rightSideImageServer.length > 0 && (
                    <Grid
                      container
                      justify="flex-start"
                      className={classes.uploadedImageContainer}
                    >
                      <Grid
                        item
                        xs={12}
                        style={{ paddingTop: "8px", paddingLeft: "4px" }}
                      >
                        <Typography className={classes.uploadimgetitle}>
                          {" "}
                          Right Side Images
                        </Typography>
                      </Grid>
                      {this.state.rightSideImageServer.map((file, index) => {
                        return (
                          <div key={file.id} style={{ margin: "4px" }}>
                            <div style={{ position: "relative" }}>
                              <CloseIcon
                                onClick={() => {
                                  this.removeUploadedImageFromServer(file.id, "right");
                                }}
                                className={classes.crossOverImage}
                              />
                              <img
                                src={file.photo.url}
                                style={{
                                  height: "65px",
                                  width: "65px",
                                  borderRadius: "6px",
                                }}
                              />
                            </div>
                          </div>
                        );
                      })}
                      <>
                        <div
                          className={classes.DropContainer}
                          onClick={this.fileInputClicked.bind(this, "right")}
                        >
                          <div className={classes.dropMessage}>
                            <div className={classes.uploadIcon}></div>
                            <div className={classes.marginTop}> {""} </div>
                          </div>
                        </div>
                        <input
                          ref={this.fileRightInputRef}
                          className={classes.fileInput}
                          type="file"
                          accept="image/*"
                          onChange={this.filesSelected.bind(this, "right")}
                        />
                      </>
                    </Grid>
                  )}
                  {!(this.state.rightSideImageServer.length > 0) && <>{this.state.rightSideImage &&
                  !(this.state.rightSideImage.length > 0) ? (
                    <>
                      <Typography
                        align="center"
                        className={classes.addImageHeading}
                        onClick={this.fileInputClicked.bind(this, "right")}
                      >
                        Add
                      </Typography>
                      <Typography
                        align="center"
                        className={classes.addImageHeading}
                        onClick={this.fileInputClicked.bind(this, "right")}
                      >
                        Right side images
                      </Typography>
                      <input
                        ref={this.fileRightInputRef}
                        className={classes.fileInput}
                        type="file"
                        accept="image/*"
                        onChange={this.filesSelected.bind(this, "right")}
                      />
                    </>
                  ) : (
                    <Grid
                      container
                      justify="flex-start"
                      className={classes.uploadedImageContainer}
                    >
                      <Grid
                        item
                        xs={12}
                        style={{ paddingTop: "8px", paddingLeft: "4px" }}
                      >
                        <Typography className={classes.uploadimgetitle}>
                          {" "}
                          Right Side Images
                        </Typography>
                      </Grid>
                      {this.state.rightSideImage.map((file, index) => {
                        return (
                          <div key={file.id} style={{ margin: "4px" }}>
                            <div style={{ position: "relative" }}>
                              <CloseIcon
                                onClick={() => {
                                  this.removeUploadedImage(file.id, "right");
                                }}
                                className={classes.crossOverImage}
                              />
                              <img
                                src={file.base64}
                                style={{
                                  height: "65px",
                                  width: "65px",
                                  borderRadius: "6px",
                                }}
                              />
                            </div>
                          </div>
                        );
                      })}
                      <>
                        <div
                          className={classes.DropContainer}
                          onClick={this.fileInputClicked.bind(this, "right")}
                        >
                          <div className={classes.dropMessage}>
                            <div className={classes.uploadIcon}></div>
                            <div className={classes.marginTop}> {""} </div>
                          </div>
                        </div>
                        <input
                          ref={this.fileRightInputRef}
                          className={classes.fileInput}
                          type="file"
                          accept="image/*"
                          onChange={this.filesSelected.bind(this, "right")}
                        />
                      </>
                    </Grid>
                  )}</>}
                  
                </Grid>
              </Grid>
              <Grid item xs={12} container justify="center">
                <Grid item xs={4}>
                { this.state.rearSideImageServer.length > 0 && (
                    <Grid
                      container
                      justify="flex-start"
                      className={classes.uploadedImageContainer}
                    >
                      <Grid
                        item
                        xs={12}
                        style={{ paddingTop: "8px", paddingLeft: "4px" }}
                      >
                        <Typography className={classes.uploadimgetitle}>
                          {" "}
                          Rear Side Images
                        </Typography>
                      </Grid>
                      {this.state.rearSideImageServer.map((file, index) => {
                        return (
                          <div key={file.id} style={{ margin: "4px" }}>
                            <div style={{ position: "relative" }}>
                              <CloseIcon
                                onClick={() => {
                                  this.removeUploadedImageFromServer(file.id, "rear");
                                }}
                                className={classes.crossOverImage}
                              />
                              <img
                                src={file?.photo?.url}
                                style={{
                                  height: "65px",
                                  width: "65px",
                                  borderRadius: "6px",
                                }}
                              />
                            </div>
                          </div>
                        );
                      })}
                      <>
                        <div
                          className={classes.DropContainer}
                          onClick={this.fileInputClicked.bind(this, "rear")}
                        >
                          <div className={classes.dropMessage}>
                            <div className={classes.uploadIcon}></div>
                            <div className={classes.marginTop}> {""} </div>
                          </div>
                        </div>
                        <input
                          ref={this.fileRearInputRef}
                          className={classes.fileInput}
                          type="file"
                          accept="image/*"
                          onChange={this.filesSelected.bind(this, "rear")}
                        />
                      </>
                    </Grid>
                  )}
                  {!(this.state.rearSideImageServer.length > 0) && <>
                    {this.state.rearSideImage &&
                  !(this.state.rearSideImage.length > 0) ? (
                    <>
                      <Typography
                        align="center"
                        className={classes.addImageHeading}
                        onClick={this.fileInputClicked.bind(this, "rear")}
                      >
                        Add
                      </Typography>
                      <Typography
                        align="center"
                        className={classes.addImageHeading}
                        onClick={this.fileInputClicked.bind(this, "rear")}
                      >
                        Rear side images
                      </Typography>
                      <input
                        ref={this.fileRearInputRef}
                        className={classes.fileInput}
                        type="file"
                        accept="image/*"
                        onChange={this.filesSelected.bind(this, "rear")}
                      />
                    </>
                  ) : (
                    <Grid
                      container
                      justify="flex-start"
                      className={classes.uploadedImageContainer}
                    >
                      <Grid
                        item
                        xs={12}
                        style={{ paddingTop: "8px", paddingLeft: "4px" }}
                      >
                        <Typography className={classes.uploadimgetitle}>
                          {" "}
                          Rear Side Images
                        </Typography>
                      </Grid>
                      {this.state.rearSideImage.map((file, index) => {
                        return (
                          <div key={file.id} style={{ margin: "4px" }}>
                            <div style={{ position: "relative" }}>
                              <CloseIcon
                                onClick={() => {
                                  this.removeUploadedImage(file.id, "rear");
                                }}
                                className={classes.crossOverImage}
                              />
                              <img
                                src={file.base64}
                                style={{
                                  height: "65px",
                                  width: "65px",
                                  borderRadius: "6px",
                                }}
                              />
                            </div>
                          </div>
                        );
                      })}
                      <>
                        <div
                          className={classes.DropContainer}
                          onClick={this.fileInputClicked.bind(this, "rear")}
                        >
                          <div className={classes.dropMessage}>
                            <div className={classes.uploadIcon}></div>
                            <div className={classes.marginTop}> {""} </div>
                          </div>
                        </div>
                        <input
                          ref={this.fileRearInputRef}
                          className={classes.fileInput}
                          type="file"
                          accept="image/*"
                          onChange={this.filesSelected.bind(this, "rear")}
                        />
                      </>
                    </Grid>
                  )}</>}
                  
                </Grid>
              </Grid>

              <Grid item xs={12} container justify="flex-end">
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={(!(this.state.frontSideImage.length > 0 || this.state.frontSideImageServer.length > 0) || !(this.state.rearSideImage.length > 0 || (this.state.rearSideImageServer.length > 0)) || !(this.state.leftSideImage.length > 0 || (this.state.leftSideImageServer.length > 0)) || !(this.state.rightSideImage.length > 0 || (this.state.rightSideImageServer.length > 0)))}
                  onClick={()=>{this.uploadImages()}}
                >
                  Proceed
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }
}
const BoatAdditionalphotoDetailWithRouter = withRouter(
  BoatAdditionalphotoDetail
);
const BoatAdditionalphotoDetailAlertBox = withAlertBox(
  BoatAdditionalphotoDetailWithRouter
);
const BoatAdditionalphotoDetailLoader = withLoader(BoatAdditionalphotoDetailAlertBox);
const BoatAdditionalphotoDetailToast = withToast(BoatAdditionalphotoDetailLoader);
export default withStyles((theme) =>
  createStyles({
    root: {
      height: "calc(100vh - 70px)",
      overflowX: "hidden",
    },
    image: {
      position: "relative",
      backgroundImage: `url(${boatDetail})`,
      backgroundRepeat: "no-repeat",
      backgroundColor:
        theme.palette.type === "light"
          ? theme.palette.grey[50]
          : theme.palette.grey[900],
      backgroundSize: "cover",
      backgroundPosition: "center",
      padding: "120px 68px",
      height: "calc(100vh - 72px)",
    },
    appBar: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      width: "100%",
      boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
      backgroundColor: "#337eb0",
      height: theme.spacing(8.75),
    },
    toolbar: {
      flexWrap: "nowrap",
    },
    toolbarLogo: {
      flexGrow: 1,
    },
    link: {
      margin: theme.spacing(1, 2.5),
      fontFamily: "Roboto",
      fontSize: "14px",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.14,
      letterSpacing: "normal",
      textTransform: "none",
      cursor: "pointer",
      color: "#fff",
    },
    margin: {
      margin: theme.spacing(1),
    },
    drawer: {
      [theme.breakpoints.up("sm")]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },
    drawerPaper: {
      width: drawerWidth,
    },
    closeMenuButton: {
      marginRight: 0,
      marginLeft: "auto",
    },
    marginTop: {
      marginTop: theme.spacing(4),
    },
    backButtonBox: {
      width: "50px",
      height: "50px",
      borderRadius: "5px",
      border: "solid 2px #0174cf",
      backgroundColor: "#ffffff",
      textAlign: "center",
      cursor : 'pointer'
    },
    backButtonBoxContent: {
      color: "#0174cf",
      fontFamily: "Montserrat",
      fontSize: "20px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "50px",
    },
    pageHeading: {
      fontFamily: "Montserrat",
      fontSize: "20px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.21,
      letterSpacing: "normal",
      textAlign: "center",
      color: "#004277",
    },
    pageSubHeading: {
      fontFamily: "Montserrat",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "normal",
      textAlign: "center",
      color: "#343a40",
    },
    rightRoot: {
      justifyContent: "center",
      display: "flex",
    },
    overlay: {
      position: "absolute",
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
    },
    mainPostContent: {
      position: "relative",
      padding: theme.spacing(3),
      color: "#FFF",
      [theme.breakpoints.up("md")]: {
        padding: theme.spacing(6),
        paddingRight: 0,
      },
    },
    imagetxtcontainer: {
      display: "flex",
      alignItems: "center",
      flexDirection: "row",
      marginTop: "6px",
    },
    leftPageHeading: {
      fontFamily: "Montserrat",
      fontSize: "20px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.21,
      letterSpacing: "normal",
      color: "#ffffff",
      paddingLeft: "8px",
    },
    pricing: {
      fontFamily: "Montserrat",
      fontSize: "32px",
      fontWeight: "600",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.22,
      letterSpacing: "normal",
      color: "#ffffff",
    },
    selectOperator: {
      fontFamily: "Montserrat",
      fontSize: "40px",
      fontWeight: "600",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.22,
      letterSpacing: "normal",
      color: "#ffffff",
    },
    leftsidelink: {
      fontFamily: "Montserrat",
      fontSize: "20px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.21,
      letterSpacing: "normal",
      color: "#004277",
    },
    headingline: {
      height: 0,
      width: "90%",
      border: "solid 1px #fff",
    },
    listInsurance: {
      fontFamily: "Montserrat",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "normal",
      color: "#343a40",
      textAlign: "center",
    },
    listnumber: {
      fontFamily: "Montserrat",
      fontSize: "32px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.22,
      letterSpacing: "normal",
      color: "#0174cf",
      textAlign: "center",
    },
    rightPageHeading: {
      fontFamily: "Montserrat",
      fontSize: "20px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.21,
      letterSpacing: "normal",
      color: "#004277",
    },
    addImageHeading: {
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.38,
      letterSpacing: "normal",
      color: "#b8b8b8",
      cursor: "pointer",
    },
    fileInput: {
      display: "none",
    },
    uploadedImageContainer: {
      borderRadius: "5px",
      boxShadow: "0 3px 8px 0 rgba(1, 116, 207, 0.26)",
      backgroundColor: "#d5e7f6",
      height: "auto",
      width: "100%",
    },
    DropContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      margin: "8px",
      width: "65px",
      height: "65px",
      border: "dashed 1px #0174cf",
      flexDirection: "column",
      borderRadius: "6px",
    },
    uploadIcon: {
      width: "16px",
      height: "16px",
      objectFit: "contain",
      background: `url(${upload}) no-repeat center center`,
      backgroundSize: "100%",
      textAlign: "center",
      margin: "0 auto",
      paddingTop: "30px",
    },
    dropMessage: {
      fontSize: "16px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "normal",
      textAlign: "center",
      color: "#343a40",
      fontFamily: "Montserrat",
      marginTop: "16px",
    },
    marginTop: {
      marginTop: theme.spacing(2),
    },
    fileInput: {
      display: "none",
    },
    uploadimgetitle: {
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "normal",
      color: "#0174cf",
    },
    crossOverImage: {
      right: "0px",
      top: "-2px",
      position: "absolute",
      backgroundColor: "#fff",
      width: "16px",
      height: "16px",
      borderRadius: "50%",
    },
  })
)(withBoatIdComponent(BoatAdditionalphotoDetailToast));
