//@ts-nocheck
import React from "react";
import BoatMainPhotoDetailController, {
  Props,
} from "./BoatMainPhotoDetailController.web";
import {
  withStyles,
  Grid,
  AppBar,
  Toolbar,
  Typography,
  createStyles,
  Box,
  ListItem,
  List,
  ListItemText,
  Button,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import { logo,boatDetail, tick } from "./assets";
import UploadBox from "./UploadBox.Web";
import { withRouter } from "react-router";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
const drawerWidth = 240;
const PageLinks = [
  { displayName: "Support", route: "/faq" },
  { displayName: "Save and exit", route: "saveandexit" },
];
import withBoatIdComponent from "./withBoatIdComponent.js";

class BoatMainPhotoDetail extends BoatMainPhotoDetailController {
  fileInputRef = React.createRef();
  constructor(props: Props) {
    super(props);
  }
  fileInputClicked() {
    this.fileInputRef?.current?.click();
  }
  async componentDidMount() {
    const authToken = await StorageProvider.get("authToken");
    const boatId = await StorageProvider.get("boatId");
    if (authToken) {
      this.setState({
        authToken: authToken,
        boatId: boatId,
      },()=>{
        this.getBoatDetail();
      });
    }
  }
  filesSelected = () => {
    if (this.fileInputRef?.current?.files?.length) {
        this.handleFiles(this.fileInputRef?.current?.files);
    }
  }
  toBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve({base64 : reader.result,file : file,id : new Date().getTime()});
      reader.onerror = error => reject(error);
    });
  };

  tobase64Handler = async (files) => {
    const filePathsPromises = [];
    files.forEach(file => {
      filePathsPromises.push(this.toBase64(file));
    });
    const filePaths = await Promise.all(filePathsPromises);
    this.setState({
      additionalPhoto : [...this.state.additionalPhoto,...filePaths]
    })
  }

  handleFiles(files: FileList){
    const filepaths = this.tobase64Handler([...files]);
  }
  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };
  goToSelectedRoute = (routeName) => {
    if (routeName === "saveandexit") {
    } else {
      const { history } = this.props;
      history.push(routeName);
    }
  };
  removeUploadedImage = (id) => {
      const files = this.state.additionalPhoto.filter((file) => {
        return file.id !== id;
      });
      this.setState({
        additionalPhoto: files,
      });
  };
  removeThumbnailImage = () => {
    this.setState({
      thumbnail_image : null
    })
  }
  removeThumbnailImageFromServer = () => {
    this.setState({
      thumbnail_imageServer : null
    })
  }
  handleThumbnailImage = async (files : FileList) => {
    const filePathsPromises = [];
    [...files].forEach(file => {
      filePathsPromises.push(this.toBase64(file));
    });
    const filePaths = await Promise.all(filePathsPromises);
    this.setState({
      thumbnail_image : filePaths[0]
    })
  }
  
  removeUploadedImageFromServer = (id) => {
    const files = this.state.additionalPhotoServer.filter((file) => {
      return file.id !== id;
    });
    this.setState({
      additionalPhotoServer: files,
    });
  }
  
  render() {
    const { classes } = this.props;
    return (
      <>
        <AppBar
          position="static"
          color="default"
          elevation={0}
          className={classes.appBar}
        >
          <Toolbar className={classes.toolbar}>
            <Box className={classes.toolbarLogo}>
              <img onClick={()=>this.props.history.push("/")}  src={logo} style={{width:'22%'}} alt="boaturu" />
            </Box>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              edge="start"
              onClick={this.handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <Hidden xsDown implementation="css">
              <nav>
                {PageLinks.map((link) => {
                  return (
                    <Typography
                      key={link.displayName}
                      variant="button"
                      className={classes.link}
                      onClick={() => {
                        this.goToSelectedRoute(link.route);
                      }}
                    >
                      {link.displayName}
                    </Typography>
                  );
                })}
              </nav>
            </Hidden>
            <Hidden smUp implementation="css">
              <Drawer
                variant="persistent"
                anchor={"right"}
                open={this.state.mobileOpen}
                onClose={this.handleDrawerToggle}
                classes={{
                  paper: classes.drawerPaper,
                }}
                SlideProps={{ unmountOnExit: true }}
              >
                <IconButton
                  onClick={this.handleDrawerToggle}
                  className={classes.closeMenuButton}
                >
                  <CloseIcon />
                </IconButton>
                <List>
      {PageLinks.map((link, index) => (
        <ListItem button key={link.displayName} onClick={() => {
          this.goToSelectedRoute(link.route);
        }}>
          <ListItemText primary={link.displayName} />
        </ListItem>
      ))}
    </List>
              </Drawer>
            </Hidden>
          </Toolbar>
        </AppBar>
        <Grid container className={classes.root}>
          <Grid
            item
            xs={false}
            sm={4}
            md={5}
            container
            className={classes.image}
          >
            <div className={classes.overlay} />
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={12} />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography gutterBottom className={classes.pricing}>
                Boat Details
              </Typography>
              <div className={classes.headingline} />
              <Typography gutterBottom className={classes.selectOperator}>
                Boat Photos
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography gutterBottom className={classes.leftsidelink}>
                Pricing
              </Typography>
              <Typography gutterBottom className={classes.leftsidelink}>
                Insurance
              </Typography>
              <Typography gutterBottom className={classes.leftsidelink}>
                Safety and standards
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={8} md={7}>
            <Grid container style={{ padding: "16px 32px" }} spacing={3}>
              <Grid item xs={12} container justify="space-between">
                <Grid item>
                  <Box className={classes.backButtonBox} onClick={() => {
                              this.goToSelectedRoute("/boatAdditionalPhotoDetail");
                            }}>
                    <Typography className={classes.backButtonBoxContent}>
                      {"<"}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  align="center"
                  className={classes.rightPageHeading}
                  gutterBottom
                >
                  We would like you to set a thumbnail for your boat profile
                </Typography>
                <Typography
                  align="center"
                  className={classes.rightPageSubHeading}
                  gutterBottom
                >
                  An attractive thumbnail helps to grab more viewers, thus
                  increasing renting chances
                </Typography>
              </Grid>
              <Grid xs={12} item container justify={"space-between"}>
                <Grid xs={7} item>
                  <Typography className={classes.tip}>Tips:</Typography>
                  <Typography className={classes.tip}>
                    1) Make sure you highlight features of your boat.
                  </Typography>
                  <Typography className={classes.tip}>
                    2) Try to avoid group of people covering your boat.
                  </Typography>
                  <Typography className={classes.tip}>
                    3) Make sure you highlight features of your boat.
                  </Typography>
                  <Typography className={classes.tip}>
                    4) Try to avoid group of people covering your boat.
                  </Typography>
                </Grid>
                <Grid xs={5} item>
                 {this.state.thumbnail_imageServer ? <div  style={{ position: "relative" }}>
                              <CloseIcon
                                onClick={() => {
                                  this.removeThumbnailImageFromServer();
                                }}
                                className={classes.crossOverImage}
                              />
                              <img
                                src={this.state.thumbnail_imageServer}
                                style={{
                                  height: "100%",
                                  width: "100%",
                                  borderRadius: "6px",
                                }}
                              />
                            </div> :  this.state.thumbnail_image ? <div  style={{ position: "relative" }}>
                              <CloseIcon
                                onClick={() => {
                                  this.removeThumbnailImage();
                                }}
                                className={classes.crossOverImage}
                              />
                              <img
                                src={this.state.thumbnail_image?.base64}
                                style={{
                                  height: "100%",
                                  width: "100%",
                                  borderRadius: "6px",
                                }}
                              />
                            </div> : <UploadBox
                    multiple={false}
                    uploadMsg="Add Image"
                    acceptedFormat={"image/*"}
                    onTouched={() => {}}
                    onSelectFile={(files: any) => {
                      this.handleThumbnailImage(files);
                    }}
                  />}
                </Grid>
              </Grid>
              <Grid xs={12} item container justify={"flex-start"}>
                <Grid xs={10} item style={{ alignSelf: "flex-end" }}>
                  <Typography className={classes.addHeading}>
                    Add additional images of your boat (Optional){" "}
                  </Typography>
                </Grid>
                <Grid xs={2} item>
                  {" "}
                  <Button variant="outlined" color="primary" onClick={()=>{this.fileInputClicked()}}>
                    {" "}
                    + Add Photo
                  </Button>{" "}
                  <input
          ref={this.fileInputRef}
          className={classes.fileInput}
          type="file"
          accept="image/*"
          multiple
          onChange={this.filesSelected.bind(this)}
        />
                </Grid>
              </Grid>
              <Grid xs={12} className={classes.imgContainer} item container justify="space-between">
              {this.state.additionalPhotoServer.map((additionalPhoto)=>{
                  return (
                    <Grid item key={additionalPhoto.id} style={{ margin: "4px" }}>
                      <div  style={{ position: "relative" }}>
                        <CloseIcon
                          onClick={() => {
                            this.removeUploadedImageFromServer(additionalPhoto.id);
                          }}
                          className={classes.crossOverImage}
                        />
                        <img
                          src={additionalPhoto?.image?.url}
                          style={{
                            height: "100px",
                            width: "100px",
                            borderRadius: "6px",
                          }}
                        />
                      </div>
                    </Grid>
                  );
              })}
              {this.state.additionalPhoto.map((file, index) => {
                        return (
                          <Grid item key={file.id} style={{ margin: "4px" }}>
                            <div  style={{ position: "relative" }}>
                              <CloseIcon
                                onClick={() => {
                                  this.removeUploadedImage(file.id);
                                }}
                                className={classes.crossOverImage}
                              />
                              <img
                                src={file.base64}
                                style={{
                                  height: "100px",
                                  width: "100px",
                                  borderRadius: "6px",
                                }}
                              />
                            </div>
                          </Grid>
                        );
                      })}
                      <>
                        <div
                          className={classes.DropContainer}
                          onClick={this.fileInputClicked.bind(this, "left")}
                        >
                          <div className={classes.dropMessage}>
                            <div className={classes.uploadIcon}></div>
                            <div className={classes.marginTop}> {""} </div>
                          </div>
                        </div>
                        <input
                          ref={this.fileLeftInputRef}
                          className={classes.fileInput}
                          type="file"
                          accept="image/*"
                          onChange={this.filesSelected.bind(this, "left")}
                        />
                      </>
              </Grid>
              <Grid item xs={12} container justify="flex-end">
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={!(this.state.thumbnail_image || this.state.thumbnail_imageServer)}
                  onClick={()=>{this.updateBoatThumbNailImage()}}
                >
                  Proceed
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }
}
const BoatMainPhotoDetailWithRouter = withRouter(BoatMainPhotoDetail);
const BoatSafetyDetailFormAlertBox = withAlertBox(
  BoatMainPhotoDetailWithRouter
);
const BoatMainPhotoDetailFormLoader = withLoader(BoatSafetyDetailFormAlertBox);
const BoatMainPhotoDetailFormToast = withToast(BoatMainPhotoDetailFormLoader);

export default withStyles((theme) =>
  createStyles({
    root: {
      height: "calc(100vh - 70px)",
      overflowX: "hidden",
    },
    image: {
      position: "relative",
      backgroundImage: `url(${boatDetail})`,
      backgroundRepeat: "no-repeat",
      backgroundColor:
        theme.palette.type === "light"
          ? theme.palette.grey[50]
          : theme.palette.grey[900],
      backgroundSize: "cover",
      backgroundPosition: "center",
      padding: "120px 68px",
      height: "calc(100vh - 72px)",
    },
    appBar: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      width: "100%",
      boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
      backgroundColor: "#337eb0",
      height: theme.spacing(8.75),
    },
    toolbar: {
      flexWrap: "nowrap",
    },
    toolbarLogo: {
      flexGrow: 1,
    },
    link: {
      margin: theme.spacing(1, 2.5),
      fontFamily: "Roboto",
      fontSize: "14px",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.14,
      letterSpacing: "normal",
      textTransform: "none",
      cursor: "pointer",
      color: "#fff",
    },
    margin: {
      margin: theme.spacing(1),
    },
    drawer: {
      [theme.breakpoints.up("sm")]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },
    drawerPaper: {
      width: drawerWidth,
    },
    closeMenuButton: {
      marginRight: 0,
      marginLeft: "auto",
    },
    marginTop: {
      marginTop: theme.spacing(4),
    },
    backButtonBox: {
      width: "50px",
      height: "50px",
      borderRadius: "5px",
      border: "solid 2px #0174cf",
      backgroundColor: "#ffffff",
      textAlign: "center",
      cursor : 'pointer'
    },
    backButtonBoxContent: {
      color: "#0174cf",
      fontFamily: "Montserrat",
      fontSize: "20px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "50px",
    },
    pageHeading: {
      fontFamily: "Montserrat",
      fontSize: "20px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.21,
      letterSpacing: "normal",
      textAlign: "center",
      color: "#004277",
    },
    pageSubHeading: {
      fontFamily: "Montserrat",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "normal",
      textAlign: "center",
      color: "#343a40",
    },
    rightRoot: {
      justifyContent: "center",
      display: "flex",
    },
    overlay: {
      position: "absolute",
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
    },
    mainPostContent: {
      position: "relative",
      padding: theme.spacing(3),
      color: "#FFF",
      [theme.breakpoints.up("md")]: {
        padding: theme.spacing(6),
        paddingRight: 0,
      },
    },
    imagetxtcontainer: {
      display: "flex",
      alignItems: "center",
      flexDirection: "row",
      marginTop: "6px",
    },
    leftPageHeading: {
      fontFamily: "Montserrat",
      fontSize: "20px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.21,
      letterSpacing: "normal",
      color: "#ffffff",
      paddingLeft: "8px",
    },
    pricing: {
      fontFamily: "Montserrat",
      fontSize: "32px",
      fontWeight: "600",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.22,
      letterSpacing: "normal",
      color: "#ffffff",
    },
    selectOperator: {
      fontFamily: "Montserrat",
      fontSize: "40px",
      fontWeight: "600",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.22,
      letterSpacing: "normal",
      color: "#ffffff",
    },
    leftsidelink: {
      fontFamily: "Montserrat",
      fontSize: "20px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.21,
      letterSpacing: "normal",
      color: "#004277",
    },
    headingline: {
      height: 0,
      width: "90%",
      border: "solid 1px #fff",
    },
    listInsurance: {
      fontFamily: "Montserrat",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "normal",
      color: "#343a40",
      textAlign: "center",
    },
    listnumber: {
      fontFamily: "Montserrat",
      fontSize: "32px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.22,
      letterSpacing: "normal",
      color: "#0174cf",
      textAlign: "center",
    },
    rightPageHeading: {
      fontFamily: "Montserrat",
      fontSize: "20px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.21,
      letterSpacing: "normal",
      color: "#004277",
      paddingLeft: "48px",
    },
    rightPageSubHeading: {
      fontFamily: "Montserrat",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "normal",
      color: "#343a40",
      textAlign: "center",
    },
    tip: {
      fontFamily: "Montserrat",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.56,
      letterSpacing: "normal",
      color: "#343a40",
    },
    addHeading: {
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.56,
      letterSpacing: "normal",
      color: "#343a40",
    },
    imgContainer: {
      borderRadius: "5px",
      backgroundColor: "#f5f5f5",
      maxHeight : '250px',
      height : '250px',
      overflowY : 'auto'
    },
    fileInput : {
      display: 'none'
  },
  crossOverImage: {
    right: "0px",
    top: "-2px",
    position: "absolute",
    backgroundColor: "#fff",
    width: "16px",
    height: "16px",
    borderRadius: "50%",
  },
  })
)(withBoatIdComponent(BoatMainPhotoDetailFormToast));
