//@ts-nocheck
import React from "react";
import EmailLoginSignUpContainerController, {
  Props,
} from "./EmailLoginSignUpContainerController.web";
import {
  withStyles,
  Grid,
  AppBar,
  Toolbar,
  Typography,
  createStyles,
  Box,
  ListItem,
  List,
  ListItemText,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import { banner, logo } from "./assets";
import { withRouter, Route, Switch, Redirect } from "react-router";
import EmailAccountLogin from "./EmailAccountLogin.web";
import EmailAccountSignup from "./EmailAccountSignup.web";
import EmailAccontResetPassword from "./EmailAccountResetPassword.web";
import EmailAccountForgotPassword from "./EmailAccountForgotPassword.web";
import EmailAccountVerifyAccount from "./EmailAccountVerifyAccount.web";
import MarinaEmailAccountSignUp from "./MarinaEmailAccountSignUp.web";
const drawerWidth = 240;
const PageLinks = [
  { displayName: "Login", route: "/basicAuth/login" },
  { displayName: "Signup", route: "/basicAuth/signup" },
  { displayName: "How it works", route: "/howItWorks" },
  { displayName: "Support", route: "/faq" },
];
const drawer = (
  <div>
    <List>
      {PageLinks.map((link, index) => (
        <ListItem button key={link.displayName}>
          <ListItemText primary={link.displayName} />
        </ListItem>
      ))}
    </List>
  </div>
);
class EmailLoginSignUpContainer extends EmailLoginSignUpContainerController {
  constructor(props: Props) {
    super(props);
  }
  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };
  goToSelectedRoute = (routeName) => {
    const {history } = this.props;
    history.push(routeName)
  };
  render() {
    const { classes } = this.props;
    return (
      <>
        <AppBar
          position="static"
          color="default"
          elevation={0}
          className={classes.appBar}
        >
          <Toolbar className={classes.toolbar}>
            <Box className={classes.toolbarLogo}>
              <img onClick={()=>this.props.history.push("/")}  src={logo} style={{width:'25%'}} alt="boaturu" />
            </Box>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              edge="start"
              onClick={this.handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <Hidden xsDown implementation="css">
              <nav>
                {PageLinks.map((link) => {
                  return (
                    <Typography
                    key={link.displayName}
                      variant="button"
                      color="secondary"
                      className={classes.link}
                      onClick={()=>{this.goToSelectedRoute(link.displayName)}}
                    >
                      {link.displayName}
                    </Typography>
                  );
                })}
              </nav>
            </Hidden>
            <Hidden smUp implementation="css">
              <Drawer
                variant="persistent"
                anchor={"right"}
                open={this.state.mobileOpen}
                onClose={this.handleDrawerToggle}
                classes={{
                  paper: classes.drawerPaper,
                }}
                SlideProps={{ unmountOnExit: true }}
              >
                <IconButton
                  onClick={this.handleDrawerToggle}
                  className={classes.closeMenuButton}
                >
                  <CloseIcon />
                </IconButton>
                {drawer}
              </Drawer>
            </Hidden>
          </Toolbar>
        </AppBar>
        <Grid container className={classes.root}>
          <Grid item xs={false} sm={4} md={7} className={classes.image} />
          <Grid item xs={12} sm={8} md={5} container justify="center" className={classes.marginTop}>
            <Grid item sm={9} md={9} xs={9}>
              <Switch>
                <Route path="/basicAuth/login" exact>
                  <EmailAccountLogin />
                </Route>
                <Route path="/basicAuth/signup" exact>
                  <EmailAccountSignup />
                </Route>
                <Route path="/basicAuth/resetPassword" exact>
                  <EmailAccontResetPassword />
                </Route>
                <Route path="/basicAuth/forgetPassword" exact>
                  <EmailAccountForgotPassword />
                </Route>
                <Route path="/basicAuth/marinaSignup" exact>
                  <MarinaEmailAccountSignUp />
                </Route>
                <Redirect exact from="/basicAuth" to="/basicAuth/login" />
              </Switch>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }
}
const EmailLoginSignUpContainerWithRouter = withRouter(
  EmailLoginSignUpContainer
);
export default withStyles((theme) =>
  createStyles({
    root: {
      height: "calc(100vh - 60px)",
      overflow: "hidden",
    },
    image: {
      backgroundImage: `url(${banner})`,
      backgroundRepeat: "no-repeat",
      backgroundColor:
        theme.palette.type === "light"
          ? theme.palette.grey[50]
          : theme.palette.grey[900],
      backgroundSize: "cover",
      backgroundPosition: "center",
    },
    appBar: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      width: "100%",
      boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
      backgroundColor: "#ffffff",
      height: theme.spacing(7.5),
    },
    toolbar: {
      flexWrap: "nowrap",
    },
    toolbarLogo: {
      flexGrow: 1,
    },
    link: {
      margin: theme.spacing(1, 2.5),
      fontFamily: "Roboto",
      fontSize: "14px",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.14,
      letterSpacing: "normal",
      textTransform: "none",
      cursor: "pointer",
    },
    drawer: {
      [theme.breakpoints.up("sm")]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },
    drawerPaper: {
      width: drawerWidth,
    },
    closeMenuButton: {
      marginRight: 0,
      marginLeft: "auto",
    },
    marginTop : {
      marginTop : theme.spacing(4)
    }
  })
)(EmailLoginSignUpContainerWithRouter);
