//@ts-nocheck
import React, { Component } from "react";
import { createMuiTheme } from "@material-ui/core/styles";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import LandingPageController, { Props } from "./LandingPageController.web";
import clsx from "clsx";
import {
  withStyles,
  Grid,
  Typography,
  createStyles,
  Button,
  Paper,
  AppBar,
  Toolbar,
  TextField,
  Menu,
  MenuItem,
  InputAdornment,
  IconButton,
  Box,
  Avatar,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemSecondaryAction,
  Hidden,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import InstagramIcon from "@material-ui/icons/Instagram";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import { arrow, logo } from "./assets";

import { withRouter } from "react-router";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import StepConnector from "@material-ui/core/StepConnector";
import moment from "moment";
import { boatImage, boatImageDemo1, boatImageDemo2 } from "./assets";
import { withTheme } from "material-ui/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import { borderRadius } from "react-select/src/theme";
import withConfirmBox from "../../../components/src/withConfirmBox.Web";
import Footer from "./footer.web";
const curDate = moment(new Date()).format("YYYY-MM-DD").toString();
const QontoConnector = withStyles({
  line: {
    borderStyle: "dotted",
    border: 1,
    backgroundColor: "#784af4",
  },
})(StepConnector);

class CustomIcon extends Component {
  render() {
    return (
      <>
        <div
          style={{
            display: "flex",
            margin: "10px",
            justifyContent: "center",
            alignItems: "center",
            color: "white",
            backgroundColor: "#00A1DF",
            height: "3em",
            position: "relative",
            top: "-20px",
            width: "3em",
            fontSize: "1rem",
            borderRadius: "50%",
            border: "5px #CCE3F6 solid",
          }}
        >
          {this.props.icon}
        </div>
      </>
    );
  }
}
function SampleNextArrow(props) {
  const { className, style, onClick } = props;

  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        background: "lightblue",
        borderRadius: "50%",
      }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        background: "lightblue",
        borderRadius: "50%",
      }}
      onClick={onClick}
    />
  );
}

class LandingPage extends LandingPageController {
  constructor(props: Props) {
    super(props);
  }
  handleClose = () => {
    this.setState({
      anchorEl: null,
    });
  };
  handleMenu = (event) => {
    if (this.state.authToken) {
      this.setState({
        anchorEl: event.currentTarget,
      });
    }
  };

  async componentDidMount() {
    const authToken = await StorageProvider.get("authToken");
    this.getPreciseLocation().then((result) => {
      this.setState(
        {
          lat: result.lat,
          lng: result.lng,
        },
        () => {
          if (authToken) {
            this.setState(
              {
                authToken: authToken,
              },
              () => {
                this.getPersonalInfo();
                this.getCaursoalAvailablity();
                this.getTestimonials();
              }
            );
          } else {
            this.getCaursoalAvailablity();
            this.getTestimonials();
          }
        }
      );
    }).catch((error)=>{
      this.setState(
        {
          lat: null,
          lng: null,
        },
        () => {
          if (authToken) {
            this.setState(
              {
                authToken: authToken,
              },
              () => {
                this.getPersonalInfo();
                this.getCaursoalAvailablity();
                this.getTestimonials();
              }
            );
          } else {
            this.getCaursoalAvailablity();
            this.getTestimonials();
          }
        }
      );
    });
  }
  goToSelectedRoute = (routeName) => {
    if (routeName === "logout") {
      const onClose = async (result) => {
        if (result === "Yes") {
          await StorageProvider.clearStorage();
          this.props.history.push("/");
          window.location.reload();
        }
      };
      this.props.openConfirmDialog(
        "Logout",
        "Are you sure you want to logout from application",
        onClose
      );
    } else {
      const { history } = this.props;
      history.push(routeName);
    }
  };
  searchBoat = () => {
    if (this.state.location) {
      this.props.history.push({
        pathname: "/userDashboard/boatSearchResult/" + this.state.location.name,
        state: {
          captainFilter: this.state.captain,
          dateFilter: this.state.tripDate,
          searchId: this.state.location.name,
          searchType: "location",
          ...this.state.location,
        },
      });
    }
  };
  onSelectLocation = (location) => {
    this.setState({
      suggestionloading: false,
      location: location,
    });
  };
  getPreciseLocation = () => {
    return new Promise(function (resolve, reject) {
      navigator.geolocation.getCurrentPosition(
        function (position) {
          resolve({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        (error) => {
          console.log(error);
          reject("Geolocation is not supported by your browser");
        },
        { maximumAge: 10000, timeout: 50000, enableHighAccuracy: true }
      );
    });
  };
  gotoBoatDetails = (boatId) => {
    const { history } = this.props;
    history.push({
      pathname: "/userDashboard/selectedBoatDetails",
      state: { boatId: boatId, duration: null },
    });
  };
  searchBoatByActivity = (activity) => {
    this.props.history.push({
      pathname: "/userDashboard/boatSearchResult/activity",
      state: {
        searchId: activity.id,
        searchType: "activity",
      },
    });
  };
  searchBoatByArea = (area) => {
    this.props.history.push({
      pathname: "/userDashboard/boatSearchResult/area",
      state: {
        searchId: area.id,
        searchType: "area",
      },
    });
  };
  seeAllBoat() {
    // "bx_block_catalogue/boats/all_boats"
    this.props.history.push({
      pathname: "/userDashboard/boatSearchResult/All",
      state: {
        searchType: "All",
      },
    });
  }
  render() {
    const { classes } = this.props;
    const { theme } = this.props;
    const menuLinks = [
      { displayName: "My Account", route: "/userDashboard" },
      { displayName: "Logout", route: "logout" },
    ];
    const settingsPopular = {
      className: "center",
      infinite: false,
      centerPadding: "50px",
      slidesToShow: 3,
      swipeToSlide: true,
      responsive: [
        {
          breakpoint: theme.breakpoints.values.lg,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: theme.breakpoints.values.md,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: theme.breakpoints.values.sm,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    };
    const settingsNearMe = {
      className: "center",
      infinite: false,
      centerPadding: "50px",
      slidesToShow: 4,
      swipeToSlide: true,
      responsive: [
        {
          breakpoint: theme.breakpoints.values.lg,
          settings: {
            slidesToShow: 4,
          },
        },
        {
          breakpoint: theme.breakpoints.values.md,
          settings: {
            slidesToShow: 5,
          },
        },
        {
          breakpoint: theme.breakpoints.values.sm,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    };
    const settingsAboutUs = {
      className: "center",
      infinite: false,
      centerPadding: "60px",
      slidesToShow: 2,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      swipeToSlide: true,
      responsive: [
        {
          breakpoint: theme.breakpoints.values.lg,
          settings: {
            slidesToShow: 4,
          },
        },
        {
          breakpoint: theme.breakpoints.values.md,
          settings: {
            slidesToShow: 5,
          },
        },
        {
          breakpoint: theme.breakpoints.values.sm,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    };

    return (
      <>
        <Paper
          className={classes.mainPost}
          style={{
            backgroundImage: `url(${boatImageDemo1})`,
          }}
        >
          {
            <img
              style={{ display: "none" }}
              src={boatImageDemo1}
              alt={"aaaaaaaaaaaaaaaa"}
            />
          }
          <div className={classes.overlay} />
          <AppBar
            position="relative"
            color="default"
            elevation={0}
            className={classes.appBar}
          >
            <Toolbar className={classes.toolbar}>
              <Grid container spacing={5}>
                <Grid xs={2} md={6} item>
                  <img
                    onClick={() => this.props.history.push("/")}
                    src={logo}
                    style={{ width: "40%" }}
                    alt="boaturu"
                  />
                </Grid>

                <Grid xs={12} md={6} item>
                  <Grid container justify="space-between" alignItems="center">
                    <Grid item xs={2}>
                      <Typography
                        align="center"
                        className={classes.link}
                        onClick={() => {
                          if (this.state.isValidSession) {
                            this.goToSelectedRoute("/ownerDashboard/myBoats");
                          } else {
                            this.goToSelectedRoute("/basicAuth/login");
                          }
                        }}
                      >
                        List your boat
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography align="center" onClick={()=>{this.props.history.push("/faq")}} className={classes.link}>
                        Support
                      </Typography>
                    </Grid>

                    {this.state.isValidSession ? (
                      <Grid item xs={2}>
                        {/* <Typography
                        align="center"
                        onClick={() => {
                          const onClose = async (result) => {
                            if(result === 'Yes'){
                              await StorageProvider.clearStorage();
                              this.props.history.push("/")
                              window.location.reload();
                            }
                          }
                          this.props.openConfirmDialog(
                            'Logout','Are you sure you want to logout from application',onClose
                          )
                        }}
                        className={classes.link}
                      >
                        Logout
                      </Typography> */}
                        {this.state?.accountInfo?.avatar?.url ? (
                          <Avatar
                            src={this.state?.accountInfo?.avatar?.url}
                            onClick={this.handleMenu}
                          />
                        ) : (
                          <Avatar onClick={this.handleMenu}>{`${
                            this.state.accountInfo?.first_name?.charAt(0) || ""
                          } ${
                            this.state.accountInfo?.last_name?.charAt(0) || ""
                          }`}</Avatar>
                        )}

                        <Menu
                          id="menu-appbar"
                          anchorEl={this.state.anchorEl}
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                          keepMounted
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                          open={!!this.state.anchorEl}
                          onClose={this.handleClose}
                        >
                          {menuLinks.map((link) => {
                            return (
                              <MenuItem
                                key={link.displayName}
                                classes={{
                                  root: classes.menuItem,
                                }}
                                onClick={() => {
                                  this.goToSelectedRoute(link.route);
                                }}
                              >
                                {link.displayName}
                              </MenuItem>
                            );
                          })}
                        </Menu>
                      </Grid>
                    ) : (
                      <Grid item xs={2}>
                        <Typography
                          align="center"
                          onClick={() => {
                            this.goToSelectedRoute("/basicAuth/login");
                          }}
                          className={classes.link}
                        >
                          Login
                        </Typography>
                      </Grid>
                    )}
                    {!this.state.isValidSession && (
                      <Grid item xs={2}>
                        <Button
                          variant="contained"
                          onClick={() => {
                            this.goToSelectedRoute("/basicAuth/signup");
                          }}
                          className={classes.loginButton}
                        >
                          Signup
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
          <Grid container justify="center" alignItems="center">
            <Grid item md={12}>
              <div className={classes.mainPostContent}>
                <Typography
                  component="h1"
                  variant="h6"
                  style={{
                    color: "#fff",
                  }}
                  align="center"
                  gutterBottom
                >
                  Boaturu
                </Typography>
                <Box>
                  <Typography
                    variant="h4"
                    align="center"
                    style={{
                      color: "#fff",
                    }}
                  >
                    Because Life is better on the water
                  </Typography>
                </Box>
              </div>
            </Grid>
          </Grid>
          <Grid container justify="center" alignItems="center">
            <Grid item md={10} xs={12}>
              <Grid
                container
                spacing={2}
                justify="space-evenly"
                className={classes.mainPostSecondaryContent}
              >
                <Grid item xs={12}>
                  <Typography
                    variant="h5"
                    gutterBottom
                    className={classes.searchHeading}
                  >
                    Lets find the perfect boat for you
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Autocomplete
                    id="search boat"
                    freeSolo
                    style={{ width: "100%" }}
                    filterOptions={(x) => x}
                    getOptionLabel={(option) => option?.name}
                    options={this.state.searchSuggestionList}
                    loading={false}
                    onInputChange={(evt) => {
                      this.getLocationSuggestion(evt.target.value);
                    }}
                    onChange={(evt, value) => {
                      this.onSelectLocation(value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label=""
                        placeholder="Where are you going?"
                        variant="outlined"
                        InputLabelProps={{
                          classes: { root: classes.lableroot },
                        }}
                        InputProps={{
                          ...params.InputProps,
                          classes: {
                            input: classes?.input,
                            root: classes?.inputfieledroot,
                          },
                          endAdornment: (
                            <React.Fragment>
                              {this.state.suggestionloading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {
                                <InputAdornment position="end">
                                  <IconButton aria-label="search">
                                    <SearchIcon />
                                  </IconButton>
                                </InputAdornment>
                              }
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                  <TextField
                    name="tripDate"
                    label={this.state.tripDate ? "" : "Trip Date"}
                    type="date"
                    InputProps={{
                      classes: {
                        input: classes?.input,
                        root: classes?.inputfieledroot,
                      },
                      inputProps: {
                        min: curDate,
                      },
                    }}
                    onChange={(evt) => {
                      this.setState({
                        tripDate: evt.target.value,
                      });
                    }}
                    value={this.state.tripDate}
                    InputLabelProps={{
                      classes: { root: classes.lableroot },
                    }}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                  <TextField
                    name="captain"
                    label="Captain"
                    label={this.state.captain ? "" : "Captain"}
                    select
                    InputProps={{
                      classes: {
                        input: classes?.input,
                        root: classes?.inputfieledroot,
                      },
                    }}
                    InputLabelProps={{
                      classes: { root: classes.lableroot },
                    }}
                    variant="outlined"
                    onChange={(evt) => {
                      this.setState({
                        captain: evt.target.value,
                      });
                    }}
                    value={this.state.captain}
                    fullWidth
                  >
                    <MenuItem value={"caption"}>With Captain</MenuItem>
                    <MenuItem value={"No_Captain"}>Without Captain</MenuItem>
                  </TextField>
                </Grid>
                <Grid
                  item
                  md={2}
                  xs={8}
                  style={{ alignSelf: "center", textAlign: "center" }}
                >
                  <Button
                    className={classes.loginButton}
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      this.searchBoat();
                    }}
                  >
                    Search
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>

        <Grid container justify="space-around">
          {/* Boats Near me */}
          <Grid item xs={11}>
            {this.state.carouselAvailable?.activity ? (
              <>
                <Grid
                  container
                  className={clsx(
                    `${classes.boatNearme} ${classes.contentContainer}`
                  )}
                  spacing={2}
                >
                  <Grid item xs={12}>
                    <div style={{ display: "flex" }}>
                      <div style={{ flexGrow: 1 }}>
                        <Typography
                          // button
                          // onClick={() => {
                          //   this.removeAll();
                          // }}
                          // style={{ lineHeight: "40px" }}
                          variant="h4"
                          className={classes.subHeading}
                        >
                          Search by Activity
                        </Typography>
                      </div>
                      <Button
                        variant="outlined"
                        color="primary"
                        endIcon={<ArrowForwardIcon></ArrowForwardIcon>}
                        className={classes.seeButton}
                        onClick={() => {
                          this.seeAllBoat();
                        }}
                      >
                        See All
                      </Button>
                    </div>
                  </Grid>

                  <Grid item xs={12}  justify="space-between">
                    {this.state.boatActivities.length > 0 && (
                      <Slider {...settingsNearMe}>
                        {this.state.boatActivities.map((activity) => {
                          return (
                            <Box
                              key={activity.id}
                              onClick={() => {
                                this.searchBoatByActivity(activity);
                              }}
                            >
                              <div
                                style={{
                                  backgroundImage: `url(${
                                    activity?.attributes.image.url || boatImage
                                  })`,
                                }}
                                className={classes.activityImage}
                              >
                                
                                    <Typography
                                      style={{position:'absolute',bottom:15,left:15}}
                                      className={classes.primaryListText}
                                      align="center"
                                    >
                                      {activity?.attributes?.name}
                                    </Typography>
                                  
                              </div>
                            </Box>
                          );
                        })}
                      </Slider>
                    )}
                  </Grid>
                </Grid>
              </>
            ) : (
              <Typography align="center" variant="h3" color="textPrimary">{"No NearBy Activities Available"}</Typography>
            )}
          </Grid>
          <Grid item xs={11}>
            {this.state.carouselAvailable?.destination ? (
              <>
                <Grid
                  container
                  justify="space-between"
                  className={classes.contentContainer}
                  spacing={2}
                >
                  <Grid item xs={12}>
                    <div style={{ display: "flex" }}>
                      <div style={{ flexGrow: 1 }}>
                        <Typography
                          style={{ lineHeight: "40px" }}
                          variant="subtitle2"
                          color="primary"
                          className={classes.smallHeading}
                        >
                          GETAWAY
                        </Typography>
                      </div>
                    </div>
                    <div style={{ display: "flex", margin: "10px 0px" }}>
                      <div style={{ flexGrow: 1 }}>
                        <Typography variant="h4" className={classes.subHeading}>
                        Popular Marinas and boats Around Me
                        </Typography>
                      </div>
                      <Button
                        variant="outlined"
                        color="primary"
                        endIcon={<ArrowForwardIcon></ArrowForwardIcon>}
                        className={classes.seeAllxsUp}
                        onClick={() => {
                          this.seeAllBoat();
                        }}
                      >
                        See All
                      </Button>
                    </div>
                  </Grid>
                  {this.state.nearDesinations.length > 0 && (
                    <Grid item xs={12}>
                      <Slider {...settingsPopular}>
                        {this.state.nearDesinations.map((ddestination) => {
                          return (
                            <Box
                              key={ddestination.id}
                              style={{ width: "92%" }}
                              onClick={() => {
                                this.searchBoatByArea(ddestination);
                              }}
                            >
                              <div
                                key={ddestination.id}
                                style={{
                                  backgroundImage: `url(${
                                    ddestination?.attributes?.destination_image
                                      ?.url || boatImage
                                  })`,
                                }}
                                className={classes.papularImage}
                              >
                                <div style={{ padding: "150px 10px" }}>
                                  <ListItem>
                                    <ListItemText
                                      classes={{
                                        primary: classes.primaryListText,
                                        secondary: classes.secondaryListText,
                                      }}
                                      primary={ddestination?.attributes.name}
                                      secondary={
                                        ddestination?.attributes?.title
                                      }
                                    ></ListItemText>
                                    <ListItemSecondaryAction>
                                      <Button
                                        variant="contained"
                                        onClick={() => {
                                          this.searchBoatByArea(ddestination);
                                        }}
                                      >
                                        Book a boat
                                      </Button>
                                    </ListItemSecondaryAction>
                                  </ListItem>
                                </div>
                              </div>
                            </Box>
                          );
                        })}
                      </Slider>
                    </Grid>
                  )}
                </Grid>
              </>
            ) : (
              <Typography align="center" variant="h3" color="textPrimary">{"No Near Destination available"}</Typography>
            )}
          </Grid>

          {/* Getaway */}
          <Grid item xs={11}>
            <Grid
              container
              className={classes.contentContainer}
              spacing={2}
            >
              <Grid item xs={12}>
                <div style={{ display: "flex" }}>
                  <div style={{ flexGrow: 1 }}>
                    <Typography
                      style={{ lineHeight: "40px" }}
                      variant="subtitle2"
                      color="primary"
                      className={classes.smallHeading}
                    >
                      BOOKING STEPS
                    </Typography>
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div style={{ flexGrow: 1 }}>
                    <Typography
                      style={{ lineHeight: "40px" }}
                      variant="h4"
                      className={classes.subHeading}
                    >
                      It's as simple As 1-2-3!
                    </Typography>
                  </div>
                </div>
              </Grid>

              <Grid item xs={12}>
                <Stepper alternativeLabel connector={<QontoConnector />}>
                  <Step active={true}>
                    <StepLabel StepIconComponent={CustomIcon}>
                      <Typography gutterBottom className={classes.primaryStepText}>Select</Typography>
                      <Typography color="textPrimary" gutterBottom className={classes.secondaryStepText}>
                        Find and compare your favorite from our vast collections
                        of rentals
                      </Typography>
                    </StepLabel>
                  </Step>
                  <Step active={true}>
                    <StepLabel StepIconComponent={CustomIcon}>
                      <Typography gutterBottom className={classes.primaryStepText}>Book</Typography>
                      <Typography gutterBottom color="textPrimary" className={classes.secondaryStepText}>
                        Pick your date and rental period
                      </Typography>
                    </StepLabel>
                  </Step>
                  <Step active={true}>
                    <StepLabel StepIconComponent={CustomIcon}>
                      <Typography gutterBottom className={classes.primaryStepText}>Boat</Typography>
                      <Typography gutterBottom color="textPrimary" className={classes.secondaryStepText}>
                        Enjoy your worth free boaturu Exprience
                      </Typography>
                    </StepLabel>
                  </Step>
                </Stepper>
              </Grid>
            </Grid>
          </Grid>

          {/* Booking Steps */}

          {/* About Boaturu     */}
          <Grid item xs={11} id={'aboutBoatru'}>
          <Grid
            container
            
            className={classes.contentContainer}
            spacing={2}
          >
            <Grid
              container
              justify="space-between"
              spacing={2}
              style={{ paddingLeft: "20px" }}
            >
              <Grid md={5} xs={12} item>
                <Typography
                  gutterBottom
                  variant="subtitle2"
                  color="primary"
                  className={classes.smallHeading}
                >
                  ABOUT BOATURU
                </Typography>
                <Box mt={2.5}>
                  <Typography
                    variant="h4"
                    className={classes.subHeading}
                    gutterBottom
                  >
                    A efficient and innovative ways to rent boat
                  </Typography>
                </Box>
                <div
                  className={clsx(
                    `${classes.boatImage1} ${classes.roundCorner}`
                  )}
                ></div>
              </Grid>
              <Grid md={5} xs={12} item>
                <div
                  className={clsx(
                    `${classes.boatImage1} ${classes.roundCorner}`
                  )}
                ></div>
                <Box>
                <Typography
                  gutterBottom
                  style={{ paddingTop: "16px" }}
                  variant="subtitle2"
                >
                  Boaturu was founded to connect boat owners, marinas, and renters around the globe. At Boaturu, we strive to offer endless days of carefree fun for you, friends, and family. We provide owners with a truly hassle-free rental process that turns your boat into an asset.

                </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>

          </Grid>
         
          {/* New to Boating */}
          <Grid
            item
            container
            xs={11}
            className={clsx(
              `${classes.ItemContainer} ${classes.contentContainer}`
            )}
            spacing={2}
          >
            <Grid
              item
              xs={12}
              md={5}
              container
              alignItems="center"
              style={{ alignSelf: "center" }}
            >
              <Box p={2}>
                <Typography
                  gutterBottom
                  variant="h4"
                  className={classes.subHeading}
                >
                  New to Boating
                </Typography>
                <Typography gutterBottom>
                Don’t worry. We’ve got you covered. Boaturu provides new boaters with everything they need to know to get on the water for the first time as well as offers fully-captained all inclusive rentals.
                </Typography>
                <Box pt={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      this.goToSelectedRoute("/basicAuth/login");
                    }}
                    className={classes.getStartedButton}
                  >
                    Get Started
                  </Button>
                </Box>
              </Box>
            </Grid>
            <Grid xs={12} md={7} item className={classes.boatImage}></Grid>
          </Grid>

          {/* We Offer */}
          <Grid item xs={11}>
          {this.state.carouselAvailable?.near_boats && (
            <Grid
              container
              className={classes.contentContainer}
              spacing={2}
            >
              <Grid
                container
                item
                xs={12}
                justify="space-between"
                alignItems="center"
              >
                <Typography
                  variant="subtitle2"
                  color="primary"
                  className={classes.smallHeading}
                >
                  WE OFFER
                </Typography>

                <Button
                  variant="outlined"
                  color="primary"
                  endIcon={<ArrowForwardIcon></ArrowForwardIcon>}
                  className={classes.seeAllxsDown}
                  onClick={() => {
                    this.seeAllBoat();
                  }}
                >
                  See All
                </Button>
              </Grid>

              <Grid
                container
                item
                xs={12}
                justify="space-between"
                alignItems="center"
              >
                <Typography variant="h4" className={classes.subHeading}>
                  Boats in your area
                </Typography>

                <Box my={2}>
                  <Typography variant="h6" color="primary">
                    Dont know where to start?
                  </Typography>

                  <Typography variant="h6" color="primary">
                    Take a look at some popular listing near you!
                  </Typography>
                </Box>

                <Button
                  variant="outlined"
                  color="primary"
                  endIcon={<ArrowForwardIcon></ArrowForwardIcon>}
                  className={classes.seeAllxsUp}
                  onClick={() => {
                    this.seeAllBoat();
                  }}
                >
                  See All
                </Button>
              </Grid>
              <Grid
                container
                item
                xs={12}
                justify="space-between"
                alignItems="center"
              >
                {this.state.nearboats.length > 0 ? (
                <Grid item xs={12}>
                  <Slider {...settingsPopular}>
                    {this.state.nearboats.map((boat) => {
                      return (
                        <Box key={boat.id}>
                          <img
                            onClick={() => {
                              this.gotoBoatDetails(boat.id);
                            }}
                            alt="img"
                            src={
                              boat.attributes.thumbnail_image.thumb.url ||
                              boatImage
                            }
                            className={classes.boatsInArea}
                          />
                          <Box position="relative" left="10%">
                            <Typography variant="h6">
                              {boat?.attributes?.name || "Not Available"}
                            </Typography>
                            <Typography variant="body1">
                              {boat?.attributes?.address?.address1}
                              {boat?.attributes?.address?.city && ","}
                              {boat?.attributes?.address?.city}
                              {boat?.attributes?.address?.state && ","}
                              {boat?.attributes?.address?.state}
                            </Typography>
                          </Box>
                        </Box>
                      );
                    })}
                  </Slider>
                </Grid>
              ) : <Grid item xs={12}><Typography align="center" variant="h3" color="textPrimary">{"No Near By Boats Available"}</Typography></Grid>}
          
              </Grid>
                </Grid>
          )}       
          </Grid>
          

          {/* Testimonial */}
          <Grid item xs={11} id="Testimonials">
          <Grid
            container
            className={classes.contentContainer}
            spacing={2}
          >
            <Grid item xs={12}>
              <Typography
                variant="subtitle2"
                color="primary"
                className={classes.smallHeading}
              >
                TESTIMONIALS
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h4" className={classes.subHeading}>
                What people say about us
              </Typography>
            </Grid>

            <Grid item xs={12} container justify="space-between">
            {this.state.testimonial && this.state.testimonial.length > 0 ? <Slider {...settingsPopular}>
                    {this.state.testimonial.map((test)=>{
                        return <Box px={4} key={test.id}>
                        <Box>
                          <ListItem disableGutters>
                            <ListItemAvatar>
                              <Avatar src={test.attributes.account.avatar.url} />
                            </ListItemAvatar>
                            <ListItemText
                              primary={`${test.attributes.account.first_name} ${test.attributes.account.last_name}` }
                              secondary={test.attributes.account.email}
                            />
                          </ListItem>
                        </Box>
                        <Box ml={1}>
                          <Box mb={1}>
                            <Typography variant="h6" color="primary">
                              {test.attributes.title || "NA"}
                            </Typography>
                          </Box>
                          <Box>
                            <Typography>
                              {test.attributes.body || "NA"}
                            </Typography>
                          </Box>
                        </Box>
                        <Box style={{backgroundImage: `url(${test.attributes.image.url})`}} className={classes.boatImage}></Box>
                      </Box>
                      
    
                    })}
                  
                  
                  
                  {/* <Box px={4}>
                    <Box>
                      <ListItem disableGutters>
                        <ListItemAvatar>
                          <Avatar />
                        </ListItemAvatar>
                        <ListItemText
                          primary="Daniel Cooper"
                          secondary="Designer"
                        />
                      </ListItem>
                    </Box>
                    <Box ml={1}>
                      <Box mb={1}>
                        <Typography variant="h6" color="primary">
                          Great service with good quality
                        </Typography>
                        <Typography variant="h6" color="primary">
                          Really happy with experience
                        </Typography>
                      </Box>
                      <Box className={classes.boatImage}></Box>
                    </Box>
                  </Box> */}

                </Slider> : null}
                
            </Grid>
          </Grid>
     
          </Grid>
          
          {/* Own a Boat */}
          <Grid item xs={12} style={{ paddingRight: "0px" }}>
            <Paper
              className={classes.ListTourPost}
              style={{
                backgroundImage: `url(${boatImageDemo1})`,
              }}
            >
              {
                <img
                  style={{ display: "none" }}
                  src={boatImageDemo1}
                  alt={"aaaaaaaaaaaaaaaa"}
                />
              }
              <div className={classes.overlay} />
              <Grid container>
                <Grid item xs={12} md={7}>
                  <div className={classes.ListTourPostContent}>
                    <Typography
                      component="h1"
                      variant="h3"
                      style={{
                        color: "#fff",
                      }}
                      // align="center"
                      gutterBottom
                    >
                      Want your boat listed with Boaturu? 
                    </Typography>
                    <Typography
                      variant="body1"
                      style={{
                        color: "#fff",
                      }}
                      gutterBottom
                    >
                      Stop letting your boat sit unused the majority of the year. Turn your boat into a free cash-flowing asset and let your boat work for you!
                    </Typography>
                    <Box mt={4}>
                      <Box mr={2} component="span">
                        <Button
                          variant="contained"
                          color="primary"
                          className={classes.listButton}
                          onClick={() => {
                            if (this.state.isValidSession) {
                              this.goToSelectedRoute("/ownerDashboard/myBoats");
                            } else {
                              this.goToSelectedRoute("/basicAuth/login");
                            }
                          }}
                        >
                          List your boat
                        </Button>
                      </Box>
                      <Button
                        variant="outlined"
                        color="primary"
                        className={classes.learnButton}
                        onClick={()=>{this.props.history.push("/faq")}}
                      >
                        Learn More
                      </Button>
                    </Box>
                  </div>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        <Footer></Footer>
        </>
    );
  }
}
const LandingPageWithRouter = withRouter(LandingPage);
const LandingPageAlertBox = withAlertBox(LandingPageWithRouter);
const LandingPageLoader = withLoader(LandingPageAlertBox);
const LandingPageToast = withToast(LandingPageLoader);
export default withStyles(
  (theme) =>
    createStyles({
      ItemContainer: {
        boxShadow: "0 3px 8px 0 rgba(0, 0, 0, 0.1)",
        border: "solid 1px #b8b8b8",
        borderRadius: "10px",
        backgroundColor: "#ffffff",
        height: "auto",
        marginTop: "20px",
      },
      mainPost: {
        position: "relative",
        backgroundColor: theme.palette.grey[800],
        color: theme.palette.common.white,
        marginBottom: theme.spacing(4),
        backgroundImage: `url(${boatImageDemo1})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        height: theme.spacing(50),
      },
      overlay: {
        position: "absolute",
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        backgroundColor: "rgba(0,0,0,.3)",
      },
      mainPostContent: {
        position: "relative",
        padding: theme.spacing(2),
        color: "#FFF",
        fontFamily: "Montserrat",
        [theme.breakpoints.up("md")]: {
          padding: theme.spacing(3),
          paddingRight: 0,
        },
      },
      mainPostSecondaryContent: {
        position: "relative",
        padding: theme.spacing(1),
        top: "50%",

        fontFamily: "Montserrat",
        [theme.breakpoints.down("sm")]: {
          borderRadius: "0px",
        },
        borderRadius: "8px",
        boxShadow: "0 10px 12px 0 rgba(0, 0, 0, 0.11)",
        backgroundColor: "rgb(1, 116, 207)",
      },
      boatNearme: {
        [theme.breakpoints.up("xs")]: {
          marginTop: "280px",
        },
        [theme.breakpoints.up("sm")]: {
          marginTop: "165px",
        },
        [theme.breakpoints.up("md")]: {
          marginTop: "0px",
        },
      },
      contentContainer: {
        marginBottom: "50px !important",
      },
      ListTourPost: {
        position: "relative",
        backgroundColor: theme.palette.grey[800],
        color: theme.palette.common.white,
        backgroundImage: `url(${boatImageDemo1})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        height: theme.spacing(50),
      },
      ListTourPostContent: {
        position: "relative",
        padding: theme.spacing(2),
        color: "#FFF",
        paddingTop: theme.spacing(6),
        [theme.breakpoints.up("md")]: {
          paddingLeft: theme.spacing(6),
        },
      },
      appBar: {
        background: "transparent",
      },
      toolbar: {
        display: "flex",
      },
      toolbarTitle: {
        flexGrow: 1,
      },
      link: {
        fontFamily: "Montserrat",
        fontSize: "14px",
        fontWeight: "bold",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1.33,
        letterSpacing: "normal",
        color: "#FFF",
        cursor: "pointer",
      },
      loginButton: {
        backgroundColor: "#fff",
        color: "blue",
        fontFamily: "Montserrat",
        fontSize: "12px",
        fontWeight: "bold",
        fontStretch: "normal",
        fontStyle: "normal",
        letterSpacing: "normal",
        color: "#1565d8",
        borderRadius: "5px",
      },
      inputfieledroot: {
        borderRadius: "10px",
        backgroundColor: "rgb(52, 144, 217)",
        color: "#fff",
      },
      input: {
        fontSize: "16px",
        fontFamily: "Montserrat",
        fontWeight: 600,
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1.19,
        letterSpacing: "normal",
        color: "#fff",
      },
      lableroot: {
        fontFamily: "Montserrat",
        fontSize: "14px",
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1.14,
        letterSpacing: "normal",
        color: "#fff",
      },
      boatImage: {
        position: "relative",
        backgroundImage: `url(${boatImageDemo1})`,
        backgroundRepeat: "no-repeat",
        backgroundColor:
          theme.palette.type === "light"
            ? theme.palette.grey[50]
            : theme.palette.grey[900],
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: theme.spacing(30),
        borderRadius: "5px",
      },
      boatImage1: {
        position: "relative",
        backgroundImage: `url(${boatImageDemo1})`,
        backgroundRepeat: "no-repeat",
        backgroundColor:
          theme.palette.type === "light"
            ? theme.palette.grey[50]
            : theme.palette.grey[900],
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: theme.spacing(50),
        borderRadius: "16px",
      },
      footerLinkHeading: {
        fontFamily: "Montserrat",
        fontSize: "16px",
        fontWeight: "bold",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1.15,
        marginBottom: "0.9rem",
        marginTop: "2rem",
        letterSpacing: "normal",
        color: "#545454",
      },
      footerLink: {
        fontFamily: "Montserrat",
        fontSize: "16px",
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1.15,
        letterSpacing: "normal",
        color: "#545454",
      },
      footer: {
        // padding: theme.spacing(0, 3.5),
        marginTop: "50px",
        // backgroundColor: "#f6f6f6",
      },
      footerTable: {
        [theme.breakpoints.up("sm")]: {
          display: "block",
        },
      },
      mediaButtonBox: {
        paddingTop: "1.8rem",
        [theme.breakpoints.down("sm")]: {
          paddingTop: "0.8rem",
        },
      },
      searchHeading: {
        fontFamily: "Montserrat",
      },
      subHeading: {
        fontFamily: "Montserrat",
        fontWeight: 600,
      },
      seeButton: {
        fontFamily: "Montserrat",
        borderRadius: "5px",
        borderWidth: "2px",
      },
      getStartedButton: {
        fontFamily: "Montserrat",
        borderRadius: "3px",
        borderWidth: "2px",
      },
      listButton: {
        fontFamily: "Montserrat",
        borderRadius: "3px",
        backgroundColor: "#FFFFFF",
        borderWidth: "2px",
        color: "#0174cf",
      },
      learnButton: {
        fontFamily: "Montserrat",
        borderRadius: "3px",
        backgroundColor: "transparent",
        color: "#FFFFFF",
        border: "solid 2px #FFFFFF",
      },
      smallHeading: {
        fontFamily: "Montserrat",
        fontWeight: 600,
        color: "#0FA6E0",
      },
      roundCorner: {
        borderRadius: "5px",
      },
      nearMeSlider: {
        width: "95%",
        height: "400px",
        borderRadius: "5px",
      },
      nearYouSlider: {
        width: "95%",
        borderRadius: "5px",
      },
      boatsInArea: {
        width: "95%",
        height: theme.spacing(20),
        margin: "2% 10%",
        borderRadius: "5px",
        cursor: "pointer",
      },
      seeAllxsUp: {
        fontFamily: "Montserrat",
        borderRadius: "5px",
        borderWidth: "2px",
        [theme.breakpoints.down("sm")]: {
          display: "none",
        },
      },
      seeAllxsDown: {
        display: "none",
        fontFamily: "Montserrat",
        borderRadius: "5px",
        borderWidth: "2px",
        [theme.breakpoints.down("sm")]: {
          display: "inherit",
        },
      },
      StepIcon: {
        root: {
          height: "1.5em",
          width: "1.5em",
        },
      },
      breakpoints: {
        lg: theme.breakpoints.values.lg,
      },
      customStepper: {
        display: "flex",
        margin: "10px",
        justifContent: "center",
        alignItems: "center",
        color: "white",
        backgroundColor: "#00A1DF",
        height: "2em",
        width: "2em",
        fontSize: "1rem",
        borderRadius: "50%",
        border: "5px #CCE3F6 solid",
      },
      activityImage: {
        position: "relative",
        color: theme.palette.common.black,
        marginBottom: theme.spacing(1),
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        height: theme.spacing(50),
        width: "95%",
      },
      papularImage: {
        position: "relative",
        color: theme.palette.common.black,
        marginBottom: theme.spacing(1),
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        height: theme.spacing(30),
        width: "95%",
      },
      primaryListText: {
        fontFamily: "Montserrat",
        fontSize: "20px",
        fontWeight: "bold",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1.21,
        letterSpacing: "normal",
        color: "#fff",
      },
      secondaryListText: {
        fontFamily: "Montserrat",
        fontSize: "14px",
        fontWeight: 500,
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1.25,
        letterSpacing: "normal",
        color: "#fff",
      },
      primaryStepText: {
        fontFamily: "Montserrat",
        fontSize: "20px",
        fontWeight: "bold",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1.21,
        letterSpacing: "normal",
        color: "#004277",
      },
      secondaryStepText: {
        fontFamily: "Montserrat",
        fontSize: "14px",
        fontWeight: 900,
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1.25,
        letterSpacing: "normal",
      },
    }),
  { withTheme: true }
)(withConfirmBox(LandingPageToast));
