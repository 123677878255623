//@ts-nocheck
import React from "react";

import SelectedBoatDetailsController, {
  Props,
} from "./SelectedBoatDetailsController.web";
import {
  withStyles,
  Grid,
  Typography,
  createStyles,
  Box,
  Button,
  Avatar,
  Checkbox,
  IconButton,
  Paper,
  Divider
} from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import { withRouter } from "react-router";
import StarIcon from "@material-ui/icons/Star";
import FavoriteIcon from "@material-ui/icons/Favorite";
import ShareIcon from "@material-ui/icons/Share";
import Close from "@material-ui/icons/Close";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
import withDialog from "../../../components/src/withDialog.web";
import { Redirect, RouterProps, Route } from "react-router-dom";
import {
  boatImage,
  boatImageDemo1,
  boatImageDemo2,
  clock,
  team,
  captionIcon,
  shield,
  hook,
  ownerPin,
  marinaIcon,
} from "./assets";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Carousel from "react-multi-carousel";
import ImgsViewer from "react-images-viewer";
import "react-multi-carousel/lib/styles.css";
import ContactOwner from "./ContactOwner.web";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 3,
    partialVisibilityGutter: 30,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 2,
    partialVisibilityGutter: 30,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    partialVisibilityGutter: 30,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    partialVisibilityGutter: 30,
  },
};
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap as Map,
  Marker,
} from "react-google-maps";
const containerStyle = {
  width: "100%",
  height: "100%",
};
const options = {
  disableDefaultUI: true,
  zoomControl: true,
};
const defaultMapOptions = {
  fullscreenControl: false,
  mapTypeControl: false,
  streetViewControl: false,
};

const MapWithAMarker = withScriptjs(
  withGoogleMap((props) => (
    <Map
      defaultZoom={props.zoom}
      defaultCenter={props.center}
      defaultOptions={defaultMapOptions}
    >
      {props.lat && props.lng && (
        <Marker
          position={{
            lat: props.lat,
            lng: props.lng,
          }}
          icon={ownerPin}
        />
      )}
    </Map>
  ))
);
var items = [
  {
    name: "Random Name #1",
    description: "Probably the most random thing you have ever seen!",
  },
  {
    name: "Random Name #2",
    description: "Hello World!",
  },
  {
    name: "Random Name #3",
    description: "Hello World!",
  },
  {
    name: "Random Name #4",
    description: "Probably the most random thing you have ever seen!",
  },
  {
    name: "Random Name #5",
    description: "Hello World!",
  },
  {
    name: "Random Name #6",
    description: "Hello World!",
  },
];

const theme = {
  // container
  container: {
    background: "rgba(255, 255, 255, .9)",
  },

  // arrows
  arrow: {
    backgroundColor: "rgba(255, 255, 255, .8)",
    fill: "#222",
    opacity: 0.6,
    transition: "opacity 200ms",

    ":hover": {
      opacity: 1,
    },
  },
  arrow__size__medium: {
    borderRadius: 40,
    height: 40,
    marginTop: -20,

    "@media (min-width: 768px)": {
      height: 70,
      padding: 15,
    },
  },
  arrow__direction__left: { marginLeft: 10 },
  arrow__direction__right: { marginRight: 10 },
  close: {
    fill: "#d40000",
    opacity: 0.6,
    transition: "all 200ms",
    ":hover": {
      opacity: 1,
    },
  },

  // footer
  footer: {
    color: "#000",
  },
  footerCount: {
    color: "rgba(0, 0, 0, .6)",
  },

  // thumbnails
  thumbnail: {},
  thumbnail__active: {
    boxShadow: "0 0 0 2px #00d8ff",
  },
};

class SelectedBoatDetails extends SelectedBoatDetailsController {
  constructor(props: Props) {
    super(props);
  }
  async componentDidMount() {
    const authToken = await StorageProvider.get("authToken");
    const boatCompareList =
      (await StorageProvider.get("boatCompareList")) || "[]";
    const boatId = this.props?.history?.location?.state?.boatId;
    const duration =
      this.props?.history?.location?.state?.duration || "half_day";
    const actionType = this.props?.history?.location?.state?.actionType;
    if (boatId) {
      await this.setState({
        boatId: boatId,
        actionType: actionType,
        loading: false,
        duration: duration,
      });
    } else {
      await this.setState({
        boatId: null,
        actionType: null,
        loading: false,
        duration: duration,
      });
    }
    if (authToken) {
      this.setState(
        {
          authToken: authToken,
        },
        () => {
          if (this.state.boatId) {
            this.getBoatDetails();
            this.getSimilorBoatDetails();
          }
        }
      );
    } else {
      if (this.state.boatId) {
        this.getBoatDetails();
        this.getSimilorBoatDetails();
      }
    }
    if (boatCompareList) {
      this.setState({
        compareBoatList: JSON.parse(boatCompareList),
      });
    }
  }
  goToSelectedRoute = (routeName) => {
    const { history } = this.props;
    history.push({
      pathname: routeName,
      state: { boatId: this.state.boatId, duration: this.state.duration },
    });
  };
  openMoreImageView = () => {
    this.setState(
      {
        openImageView: true,
        currImg: 0,
      },
      () => {
        setTimeout(() => {
          document
            .getElementById("viewerBackdrop")
            ?.parentNode?.classList?.remove("fade");
        }, 200);
      }
    );
  };
  closeMoreImageView = () => {
    this.setState({
      openImageView: false,
      currImg: 0,
    });
  };
  gotoPrevious = () => {
    this.setState({
      currImg:
        this.state.currImg > 0 ? this.state.currImg - 1 : this.state.currImg,
    });
  };
  gotoNext = (totalImage) => {
    this.setState({
      currImg:
        this.state.currImg > totalImage
          ? this.state.currImg
          : this.state.currImg + 1,
    });
  };
  removeAll = () => {
    this.setState(
      {
        compareBoatList: [],
      },
      async () => {
        await StorageProvider.set(
          "boatCompareList",
          JSON.stringify(this.state.compareBoatList)
        );
      }
    );
  };
  removeComparedItem = (boat) => {
    const compareBoatList = [...this.state.compareBoatList].filter((item) => {
      return item.id != boat.id;
    });
    this.setState(
      {
        compareBoatList,
      },
      async () => {
        await StorageProvider.set(
          "boatCompareList",
          JSON.stringify(this.state.compareBoatList)
        );
      }
    );
  };
  addToCompare = async (event, boat) => {
    let compareBoatList = [...this.state.compareBoatList];
    const index = compareBoatList.findIndex((compareBaot) => {
      return compareBaot.id == boat.id;
    });
    if (index === -1) {
      compareBoatList = [...compareBoatList, boat];
      this.setState(
        {
          compareBoatList: compareBoatList,
        },
        async () => {
          await StorageProvider.set(
            "boatCompareList",
            JSON.stringify(this.state.compareBoatList)
          );
        }
      );
    } else {
      compareBoatList.splice(index, 1);
      this.setState(
        {
          compareBoatList: compareBoatList,
        },
        async () => {
          await StorageProvider.set(
            "boatCompareList",
            JSON.stringify(this.state.compareBoatList)
          );
        }
      );
    }
  };

  saveCureentPathIfNotLoggedIn = async (action) => {
    if (action && action?.actionType === "bookBoat") {
      await StorageProvider.set("currentPath", "/bookBoat");
      await StorageProvider.set(
        "currentRouteState",
        JSON.stringify({ boatId: this.state.boatId })
      );
    } else {
      const currentPath = this.props?.history?.location?.pathname;
      let currentRouteState = this.props?.history?.location?.state || {};
      if (action) {
        currentRouteState = action && { ...currentRouteState, ...action };
      }
      await StorageProvider.set("currentPath", currentPath);
      await StorageProvider.set(
        "currentRouteState",
        JSON.stringify(currentRouteState)
      );
    }

    this.props.history.push("/basicAuth");
  };
  getHours = () => {
    if (this.state.duration) {
      if (this.state.duration === "half_day") {
        return "0-4 Hours ";
      } else if (this.state.duration === "full_day") {
        return "8 Hours ";
      } else if (this.state.duration === "twentyfour_hours") {
        return "24 Hours ";
      } else if (this.state.duration === "three_days") {
        return "3 Days ";
      } else if (this.state.duration === "seven_days") {
        return "7 Days ";
      }
    } else {
      return "0-4 Hours ";
    }
  };
  getPrice = (boatPrice1 = {}) => {
    const boatPrice = boatPrice1 ? boatPrice1 : {}
    if (this.state.duration) {
      if (this.state.duration === "half_day") {
        return `$ ${boatPrice["half_day_rate"] || "-"}`;
      } else if (this.state.duration === "full_day") {
        return `$ ${boatPrice["full_day_rate"] || "-"}`;
      } else if (this.state.duration === "twentyfour_hours") {
        return `$ ${boatPrice["twenty_four_hours_rate"] || "-"}`;
      } else if (this.state.duration === "three_days") {
        return `$ ${boatPrice["three_days_rate"] || "-"}`;
      } else if (this.state.duration === "seven_days") {
        return `$ ${boatPrice["seven_days_rate"] || "-"}`;
      }
    } else {
      return `$ ${boatPrice["half_day_rate"] || "-"}`;
    }
  };
  gotoBoatDetails = (boatId) => {
    const { history } = this.props;
    history.push({pathname :  "/userDashboard/selectedBoatDetails",state : {boatId : boatId}});
    window.location.reload();
  };
  render() {
    const { classes } = this.props;
    const reviews =
      this.state.boatDetails?.attributes?.reviews?.reviews.data || [];
    const images = this.state?.boatDetails?.attributes?.boat_additional_images?.map(
      (photo) => {
        return { src: photo?.image?.url };
      }
    );
    const totalImage = images?.length || 0;
    return !this.state.loading ? (
      this.state.boatId === null ? (
        <Redirect to="/" />
      ) : (
        <>
          {images && (
            <div style={{ opacity: 1 }}>
              <ImgsViewer
                imgs={images}
                backdropCloseable={true}
                showThumbnails={true}
                ref={this.ref}
                currImg={this.state.currImg}
                isOpen={this.state.openImageView}
                onClickPrev={this.gotoPrevious}
                onClickNext={() => {
                  this.gotoNext(totalImage);
                }}
                theme={theme}
                onClose={this.closeMoreImageView}
              />
            </div>
          )}
          <Grid
            container
            justify="space-around"
            spacing={3}
            className={classes.marginTop}
          >
            <Grid item xs={12} md={12}>
              {this.state?.boatDetails?.attributes?.galleries &&
                this.state?.boatDetails?.attributes?.galleries.length > 0 && (
                  <Carousel responsive={responsive}>
                    {this.state?.boatDetails?.attributes?.galleries?.map(
                      (photo, i) => (
                        <Grid container spacing={1} key={photo.id}>
                          <Grid
                            item
                            xs={12}
                            className={classes.boatMainImage}
                            style={{
                              backgroundImage: `url(${photo?.photo?.url})`,
                            }}
                          />
                        </Grid>
                      )
                    )}
                  </Carousel>
                )}
            </Grid>
            <Grid item xs={10} md={10}>
              <Grid container justify="space-around" spacing={3}>
                <Grid item md={8} xs={6}>
                  <Typography gutterBottom className={classes.boatName}>
                    {this.state.boatDetails?.attributes?.name}
                  </Typography>
                  <Typography gutterBottom className={classes.boatAddress}>
                    {`${this.state.boatDetails?.attributes?.address?.address1},${this.state.boatDetails?.attributes?.address?.city}`}
                  </Typography>
                  <Box px={1} className={classes.ratingBox}>
                    <Typography display="inline" className={classes.rating}>
                      {parseFloat(
                        this.state.boatDetails?.attributes?.reviews
                          ?.avg_rating || 0
                      )}{" "}
                    </Typography>
                    <StarIcon style={{ color: "white" }} fontSize="small" />
                  </Box>
                  <Typography display="inline" className={classes.boatReview}>
                    {`${reviews.length} Reviews`}
                  </Typography>
                </Grid>
                <Grid item md={2} xs={3}>
                  <ListItem>
                    <ListItemText
                      primary="Starting from"
                      classes={{
                        primary: classes.boatPriceHeading,
                        secondary: classes.boatPrice,
                      }}
                      secondary={`${this.getPrice(
                        this.state.boatDetails?.attributes?.boat_price
                      )} +`}
                    />
                  </ListItem>
                </Grid>
                <Grid item md={2} xs={3}>
                  <Grid container justify="space-between">
                    <Grid xs={6} item>
                      <IconButton
                        disableFocusRipple
                        style={{
                          padding: "6px",
                          backgroundColor: "bbb",
                          borderRadius: "50%",
                          border: "solid 1px #0174cf",
                        }}
                        onClick={() => {
                          if (this.state.authToken) {
                            this.setState({ like: !this.state.like }, () => {
                              this.likeAndUnLikeAboat(this.state.like);
                            });
                          } else {
                            this.saveCureentPathIfNotLoggedIn({
                              actionType: "LikeABoat",
                            });
                          }
                        }}
                      >
                        <FavoriteIcon
                          fontSize="small"
                          style={{ color: this.state.like ? "red" : "grey" }}
                        />
                      </IconButton>
                    </Grid>
                    <Grid xs={6} item>
                      <IconButton
                        disableFocusRipple
                        style={{
                          padding: "6px",
                          backgroundColor: "fff",
                          borderRadius: "50%",
                          border: "solid 1px #0174cf",
                        }}
                      >
                        <ShareIcon fontSize="small" style={{ color: "grey" }} />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={10} md={10}>
              <Grid container spacing={5} justify="space-between">
                <Grid item xs={12} md={5}>
                  <Typography gutterBottom className={classes.overViewHeading}>
                    Overview :
                  </Typography>
                  <Grid container spacing={2} justify="space-between">
                    <Grid item xs={5}>
                      <ListItem>
                        <ListItemIcon>
                          <img src={clock} className={classes.listItemClass} />
                        </ListItemIcon>
                        <ListItemText
                          primary={this.getHours()}
                          classes={{ primary: classes.featureHeading }}
                        />
                      </ListItem>
                    </Grid>
                    <Grid item xs={5}>
                      <ListItem>
                        <ListItemIcon>
                          <img src={team} className={classes.listItemClass} />
                        </ListItemIcon>
                        <ListItemText
                          primary={`Up to ${this.state.boatDetails?.attributes?.max_passengers} passengers`}
                          classes={{ primary: classes.featureHeading }}
                        />
                      </ListItem>
                    </Grid>
                    <Grid item xs={5}>
                      <ListItem>
                        <ListItemIcon>
                          <img src={hook} className={classes.listItemClass} />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            this.state.boatDetails?.attributes?.tags?.length > 0
                              ? this.state.boatDetails?.attributes?.tags
                                  ?.map((tag) => {
                                    return tag.name;
                                  })
                                  .join(",")
                              : "Not Available"
                          }
                          classes={{ primary: classes.featureHeading }}
                        />
                      </ListItem>
                    </Grid>
                    <Grid item xs={5}>
                      <ListItem>
                        <ListItemIcon>
                          <img
                            src={captionIcon}
                            className={classes.listItemClass}
                          />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            this.state.boatDetails?.attributes
                              ?.boat_operator === "renter"
                              ? "No Captain Included"
                              : "Captained"
                          }
                          classes={{ primary: classes.featureHeading }}
                        />
                      </ListItem>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={2}>
                  <Typography gutterBottom className={classes.overViewHeading}>
                    Offered by :
                  </Typography>
                  <Grid container justify="space-between" alignItems="center">
                    <Grid item xs={2}>
                       <Avatar src={boatImage} /> 
                    </Grid>
                    <Grid xs={9} item>
                         <Typography style={{marginLeft:'10px'}} variant="body2" >{this.state.boatDetails?.attributes?.account?.user_name}</Typography>
                        <Typography  style={{color:'green',fontSize:'10px'}} display="inline">&#10004; </Typography>
                        <Typography variant="body1" style={{fontSize:'10px'}} display="inline">Verified Owner</Typography>
                    </Grid>
                  </Grid>
                  {/* <ListItem>
                    <ListItemAvatar>
                      <Avatar src={boatImage} />
                    </ListItemAvatar>
                    
                  </ListItem> */}
                  <div>
                     
                     <div>
                        
                     </div> 
                     
                  </div>
                </Grid>
                <Grid item xs={12} md={2}>
                  <Grid container spacing={2}>
                    <Grid item md={12} xs={4}>
                      <div style={{ textAlign: "center" }}>
                        <Button
                          variant="contained"
                          onClick={() => {
                            if (this.state.authToken) {
                              this.goToSelectedRoute("/bookBoat");
                            } else {
                              this.saveCureentPathIfNotLoggedIn({
                                actionType: "bookBoat",
                              });
                            }
                          }}
                          color="primary"
                        >
                          Book Now
                        </Button>
                      </div>
                    </Grid>
                    <Grid item md={12} xs={4}>
                      <div style={{ textAlign: "center" }}>
                        <Button
                          variant="outlined"
                          onClick={() => {
                            if (this.state.authToken) {
                              this.openContactOwnerDialog();
                            } else {
                              this.saveCureentPathIfNotLoggedIn({
                                actionType: "ContactOwner",
                              });
                            }
                          }}
                          color="primary"
                        >
                          Contact Owner
                        </Button>
                      </div>
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <div style={{ textAlign: "center" }}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="checkedB"
                              color="primary"
                              checked={
                                this.state.compareBoatList.findIndex((cc) => {
                                  return cc.id === this?.state?.boatDetails?.id;
                                }) !== -1
                              }
                              onChange={(event) => {
                                this.addToCompare(
                                  event,
                                  this?.state?.boatDetails
                                );
                              }}
                            />
                          }
                          label="Add to compare"
                        />
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={3}>
                  {this.state.compareBoatList.length > 0 && (
                    <Grid container spacing={1} component={Paper}>
                      <Grid xs={12} item>
                        <List>
                          {this.state.compareBoatList.map((item) => {
                            return (
                              <ListItem key={item.id}>
                                <ListItemAvatar>
                                  <img
                                    src={boatImageDemo1}
                                    style={{ width: "50px", height: "50px" }}
                                  />
                                </ListItemAvatar>
                                <ListItemText
                                  primary={item?.attributes?.name}
                                  secondary={`${this.getPrice(
                                    item?.attributes?.boat_price
                                  )}`}
                                />
                                <ListItemSecondaryAction>
                                  <IconButton
                                    onClick={() => {
                                      this.removeComparedItem(item);
                                    }}
                                    edge="end"
                                    aria-label="delete"
                                  >
                                    <Close />
                                  </IconButton>
                                </ListItemSecondaryAction>
                              </ListItem>
                            );
                          })}
                        </List>
                      </Grid>
                      <Grid xs={12} item>
                        <div style={{ display: "flex" }}>
                          <div style={{ flexGrow: 1 }}>
                            <Typography
                              button
                              onClick={() => {
                                this.removeAll();
                              }}
                              style={{ lineHeight: "40px" }}
                            >
                              Remove All
                            </Typography>
                          </div>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              this.goToSelectedRoute(
                                "/userDashboard/boatCompasison"
                              );
                            }}
                          >
                            Compare
                          </Button>
                        </div>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={10} md={10}>
              <Grid container spacing={3} justify="space-between">
                <Grid item xs={12} md={8}>
                  <div className={classes.sectionHeadingContainer}>
                    <Typography className={classes.sectionHeading}>
                      Description
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={12} md={8}>
                  <Box px={3}>
                    <Typography
                      gutterBottom
                      className={classes.sectionDescription}
                    >
                      {this.state.boatDetails?.attributes?.description}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={8}>
                  <Box px={4}>
                    <Typography
                      gutterBottom
                      display="inline"
                      className={classes.sectionSubHeading}
                    >
                      Specifications
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={8}>
                  <Box px={6}>
                    <Grid container>
                      <Grid item xs={5}>
                        <ListItem>
                          <ListItemText
                            classes={{
                              primary: classes.sectionListPrimartText,
                              secondary: classes.sectionListSecondaryTest,
                            }}
                            primary="Model"
                            secondary={
                              this.state.boatDetails?.attributes?.model ||
                              "Not Available"
                            }
                          />
                        </ListItem>
                      </Grid>
                      <Grid item xs={5}>
                        <ListItem>
                          <ListItemText
                            classes={{
                              primary: classes.sectionListPrimartText,
                              secondary: classes.sectionListSecondaryTest,
                            }}
                            primary="Length"
                            secondary={
                              this.state.boatDetails?.attributes?.boat_length ||
                              "Not Available"
                            }
                          />
                        </ListItem>
                      </Grid>
                      <Grid item xs={5}>
                        <ListItem>
                          <ListItemText
                            classes={{
                              primary: classes.sectionListPrimartText,
                              secondary: classes.sectionListSecondaryTest,
                            }}
                            primary="Capacity"
                            secondary={
                              (this.state.boatDetails?.attributes
                                ?.max_passengers &&
                                `${this.state.boatDetails?.attributes?.max_passengers}`) ||
                              "Not Available"
                            }
                          />
                        </ListItem>
                      </Grid>
                      <Grid item xs={5}>
                        <ListItem>
                          <ListItemText
                            classes={{
                              primary: classes.sectionListPrimartText,
                              secondary: classes.sectionListSecondaryTest,
                            }}
                            primary="Year"
                            secondary={
                              this.state.boatDetails?.attributes
                                ?.manufactured_year || "Not Available"
                            }
                          />
                        </ListItem>
                      </Grid>
                      <Grid item xs={5}>
                        <ListItem>
                          <ListItemText
                            classes={{
                              primary: classes.sectionListPrimartText,
                              secondary: classes.sectionListSecondaryTest,
                            }}
                            primary="Boat Type"
                            secondary={
                              this.state.boatDetails?.attributes
                                ?.boat_specification_type || "Not Available"
                            }
                          />
                        </ListItem>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={10} md={10}>
              <Grid container spacing={3} justify="space-between">
                <Grid item xs={12} md={8}>
                  <div className={classes.sectionHeadingContainer}>
                    <Typography className={classes.sectionHeading}>
                      Location
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={12} md={8}>
                  <Box px={2}>
                    <Typography
                      gutterBottom
                      className={classes.sectionDescription}
                    >
                      Exact location information is provided after a booking is
                      confirmed by the owner.
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={8}>
                  <Grid container>
                    <Grid item xs={12}>
                      <div className={classes.map_parent_cont}>
                        <div className={classes.mapContainer}>
                          {this.state.lat && this.state.lng && (
                            <MapWithAMarker
                              googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCmM-iLmhPns_mUWUErYXe2SpaJMbNvodc&v=3.exp&libraries=geometry,drawing,places"
                              loadingElement={
                                <div style={{ height: `100%` }} />
                              }
                              containerElement={
                                <div style={{ height: `100%` }} />
                              }
                              mapElement={<div style={{ height: `100%` }} />}
                              center={{
                                lat: this.state.lat,
                                lng: this.state.lng,
                              }}
                              zoom={13}
                              dataSource={[]}
                              lat={this.state.lat}
                              lng={this.state.lng}
                            />
                          )}
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={10} md={10}>
              <Grid container spacing={3} justify="space-between">
                <Grid item xs={12} md={8}>
                  <div className={classes.sectionHeadingContainer}>
                    <Typography className={classes.sectionHeading}>
                      Owner's Info
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={12} md={8}>
                  <Box px={5}>
                    <Grid container spacing={1} justify="space-between">
                      <Grid item xs={6}>
                        
                        <Grid container justify="space-between" alignItems="center">
                    <Grid item xs={2}>
                       <Avatar src={boatImage} /> 
                    </Grid>
                    <Grid xs={9} item>
                         <Typography style={{marginLeft:'10px'}} variant="body2" >{this.state.boatDetails?.attributes?.account?.user_name}</Typography>
                        <Typography  style={{color:'green',fontSize:'10px'}} display="inline">&#10004; </Typography>
                        <Typography variant="body1" style={{fontSize:'10px'}} display="inline">Verified Owner</Typography>
                    </Grid>
                  </Grid>
                      </Grid>
                      <Grid item xs={6}>
                        <div style={{ textAlign: "end" }}>
                          <Button
                            variant="outlined"
                            onClick={() => {
                              if (this.state.authToken) {
                                this.openContactOwnerDialog();
                              } else {
                                this.saveCureentPathIfNotLoggedIn({
                                  actionType: "ContactOwner",
                                });
                              }
                            }}
                            color="primary"
                          >
                            Contact Owner
                          </Button>
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div>
                          <Typography
                            display="inline"
                            className={classes.ownerName}
                          >
                            Response Rate
                          </Typography>
                          &nbsp;&nbsp;
                          <Typography
                            display="inline"
                            className={classes.ownerNameHeading}
                          >
                            100%
                          </Typography>
                        </div>
                        <div>
                          <Typography
                            display="inline"
                            className={classes.ownerName}
                          >
                            {" "}
                            Response Time
                          </Typography>
                          &nbsp;&nbsp;
                          <Typography
                            display="inline"
                            className={classes.ownerNameHeading}
                          >
                            2 Hours
                          </Typography>
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div style={{ textAlign: "end" }}>
                          <Typography
                            display="inline"
                            className={classes.ownerName}
                          >
                            Member since
                          </Typography>
                          &nbsp;&nbsp;
                          <Typography
                            display="inline"
                            className={classes.ownerNameHeading}
                          >
                            March 2020
                          </Typography>
                        </div>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>

                <Grid item xs={12} md={8}>
                  <Box px={5}>
                    <Typography
                      gutterBottom
                      className={classes.sectionDescription}
                    >
                      Our small company is happy to provide cruises by the boat
                      for a pleasure and a relax. Captain Andrew is fully
                      licensed by the US Coast Guard, and the boat is insured in
                      accordance with the federal regulations. Be assured you
                      are in good hands as Andrew has more than 30 years of boat
                      operating experience in various countries! Andrew will
                      ensure your trip is safe, fun and enjoyable!​
                    </Typography>
                  </Box>
                </Grid>
                <Grid item md={8} xs={12}>
                  <Box px={5}>
                    <Grid
                      container
                      justify="space-around"
                      style={{
                        borderRadius: "9px",
                        border: "solid 2px #0174cf",
                        backgroundColor: "#f3fcfe",
                      }}
                    >
                      <Grid item xs={12}>
                        <Typography
                          align="center"
                          className={classes.ownerTerm}
                        >
                          Owner's Terms
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          align="left"
                          className={classes.boatPreference}
                          gutterBottom
                        >
                          Boat Preference
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Grid container>
                          {this.state.boatDetails?.attributes?.preferences?.map(
                            (preference) => {
                              return (
                                <Grid item xs={5} key={preference.id}>
                                  <ListItem>
                                    <ListItemIcon>
                                      <img src={shield} />
                                    </ListItemIcon>
                                    <ListItemText
                                      classes={{
                                        primary: classes.ownerNameHeading,
                                        secondary: classes.ownerName,
                                      }}
                                      primary={preference?.name}
                                    />
                                  </ListItem>
                                </Grid>
                              );
                            }
                          )}
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          align="left"
                          className={classes.boatPreference}
                          gutterBottom
                        >
                          Cancellation Policy
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Grid container>
                          <Grid item xs={12}>
                            <Typography align="left" gutterBottom>
                              {
                                this.state.boatDetails?.attributes
                                  ?.cancellation_policy?.body
                              }
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          align="left"
                          className={classes.boatPreference}
                          gutterBottom
                        >
                          Security Deposit
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Grid container>
                          <Grid item xs={12}>
                            <Typography align="left" gutterBottom>
                              A captained boat means that your job is to kick
                              back, relax, and enjoy being on the water! Captain
                              Andrew has more than 20 years of boat experience.
                              Be assured you are in good hands!
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={10} md={10}>
              <Grid container spacing={3} justify="space-between">
                <Grid item xs={12} md={8}>
                  <div className={classes.sectionHeadingContainer}>
                    <Typography className={classes.sectionHeading}>
                      More Photos
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={12} md={8}>
                  <Grid container justify="center">
                    <Grid
                      item
                      xs={8}
                      style={{
                        backgroundImage: `url(${
                          this.state.boatDetails?.attributes?.thumbnail_image
                            ?.url
                            ? this.state.boatDetails?.attributes
                                ?.thumbnail_image?.url
                            : boatImageDemo1
                        })`,
                      }}
                      className={classes.hotelMainImage}
                    />
                  </Grid>
                  <Grid container justify="space-between">
                    {this.state.boatDetails.attributes?.boat_additional_images
                      .length > 0 &&
                      [0, 1, 2].map((boat) => {
                        return (
                          <Grid xs={4} key={boat} item>
                            {this.state.boatDetails.attributes
                              ?.boat_additional_images[boat] ? (
                              <div
                                className={classes.hoteloptionalImage}
                                style={{
                                  backgroundImage: `url(${
                                    this.state.boatDetails.attributes
                                      ?.boat_additional_images[boat]?.image?.url
                                      ? this.state.boatDetails.attributes
                                          ?.boat_additional_images[boat]?.image
                                          ?.url
                                      : boatImageDemo1
                                  })`,
                                }}
                                onClick={() => {
                                  if (boat === 2) this.openMoreImageView();
                                }}
                              >
                                {boat === 2 &&
                                  this.state.boatDetails.attributes
                                    ?.boat_additional_images.length > 3 && (
                                    <Box p={3}>
                                      <Typography color="error">{`${this.state
                                        .boatDetails.attributes
                                        ?.boat_additional_images.length -
                                        3} More photos`}</Typography>
                                    </Box>
                                  )}
                              </div>
                            ) : (
                              <></>
                            )}
                          </Grid>
                        );
                      })}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={10} md={10}>
              <Grid container spacing={3} justify="space-between">
                <Grid item xs={12} md={8}>
                  <div
                    className={classes.sectionHeadingContainer}
                    style={{ display: "flex" }}
                  >
                    <div style={{ flexGrow: 1 }}>
                      <Typography className={classes.sectionHeading}>
                        Reviews
                      </Typography>
                    </div>
                    <Typography className={classes.sectionLinkHeading}>See All Reviews</Typography>
                  </div>
                </Grid>
                <Grid item xs={12} md={8}>
                  <Box px={5}>
                    <Carousel
                      responsive={responsive}
                      itemClass={classes.carouselItemPadding40}
                    >
                      {reviews.map((item, i) => (
                        <Paper elevation={0} key={item.id}>
                          <Grid container spacing={1}>
                            <Grid item xs={10}>
                              <ListItem>
                                <ListItemAvatar>
                                  <Avatar
                                    src={item.attributes?.account?.avatar?.url}
                                  />
                                </ListItemAvatar>
                                <ListItemText
                                  primary={item.attributes?.account.user_name}
                                ></ListItemText>
                              </ListItem>
                            </Grid>
                            <Grid item xs={10}>
                              <Typography align="left" gutterBottom>
                                {item.attributes.comment}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Paper>
                      ))}
                    </Carousel>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={10} md={10}>
              <Grid container spacing={3} justify="space-between">
                <Grid item xs={12}>
                  <div
                    className={classes.sectionHeadingContainer}
                    style={{ display: "flex" }}
                  >
                    <div style={{ flexGrow: 1 }}>
                      <Typography className={classes.sectionHeading}>
                        Similar Boats in Miami
                      </Typography>
                    </div>
                    <Typography className={classes.sectionLinkHeading} onClick={()=>{this.props.history.goBack();}}>See All Boats</Typography>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <Box px={5}>
                    <Grid container justify="space-between" spacing={1}>
                      {this.state.similorBoats.length > 0 ? (
                        this.state.similorBoats?.map((boat) => {
                          return (
                            <Grid
                              item
                              container
                              key={boat.id}
                              xs={10}

                              md={4}

                              onClick={() => {
                                this.gotoBoatDetails(boat.id);
                              }}
                            >
                              <Grid
                                container
                                justify="space-between"
                                spacing={1}
                              >
                                <Grid
                                  item
                                  xs={4}
                                  style={{
                                    backgroundImage: `url(${
                                      boat?.attributes?.thumbnail_image?.url
                                        ? boat?.attributes?.thumbnail_image?.url
                                        : boatImage
                                    })`,
                                  }}
                                  className={classes.boatImage}
                                />
                                <Grid item xs={8}>
                                  <Box p={1}>
                                    <Grid
                                      container
                                      justify={"space-between"}
                                      spacing={2}
                                    >
                                      <Grid xs={12} item>
                                        <div style={{ display: "flex" }}>
                                          <div style={{ flexGrow: 1 }}>
                                            <Typography
                                              className={classes.boatName}
                                            >
                                              {boat?.attributes?.name}
                                            </Typography>
                                            <Typography
                                              className={classes.boatAddress}
                                            >
                                              {`${boat?.attributes?.address?.address1},${boat?.attributes?.address?.city}`}
                                            </Typography>
                                          </div>
                                          {/* <IconButton
                                            onClick={(evt) => {
                                              evt.stopPropagation();
                                              this.saveCureentPathIfNotLoggedIn(
                                                boat
                                              );
                                            }}
                                          >
                                            <FavoriteIcon
                                              fontSize={"small"}
                                              style={{
                                                color: boat?.attributes
                                                  ?.like_boat
                                                  ? "red"
                                                  : "grey",
                                                backgroundColor: "#fff",
                                                borderRadius: "50%",
                                              }}
                                            ></FavoriteIcon>
                                          </IconButton> */}
                                        </div>
                                      </Grid>
                                      <Grid xs={12} item>
                                        <Typography
                                          className={classes.boatAddress}
                                        >
                                          {this.getHours()}
                                          {`Up to ${boat?.attributes?.max_passengers} passengers`}
                                        </Typography>
                                        <Typography
                                          className={classes.boatAddress}
                                        >
                                          {boat?.attributes?.boat_operator ===
                                          "renter"
                                            ? "No Captain Included"
                                            : "Captained"}
                                        </Typography>
                                      </Grid>
                                      <Grid xs={12} item>
                                        <Box
                                          px={1}
                                          className={classes.ratingBox}
                                        >
                                          <Typography
                                            display="inline"
                                            className={classes.rating}
                                          >
                                            {parseFloat(
                                              boat?.attributes?.average_rating
                                                ? boat?.attributes?.average_rating.toFixed(
                                                    "2"
                                                  )
                                                : boat?.attributes?.reviews
                                                    ?.avg_rating
                                                ? Number(
                                                    boat?.attributes?.reviews
                                                      ?.avg_rating
                                                  ).toFixed(2)
                                                : 0
                                            )}{" "}
                                          </Typography>
                                          <StarIcon
                                            style={{ color: "white" }}
                                            fontSize="small"
                                          ></StarIcon>
                                        </Box>
                                        <Typography
                                          display="inline"
                                          className={classes.boatReview}
                                        >
                                          {`${
                                            boat?.attributes?.reviews.length > 0
                                              ? boat?.attributes?.reviews.length
                                              : boat?.attributes?.reviews
                                                  ?.reviews?.data?.length > 0
                                              ? boat?.attributes?.reviews
                                                  ?.reviews?.data?.length
                                              : 0
                                          } Reviews`}
                                        </Typography>
                                      </Grid>
                                      <Grid xs={12} item>
                                        <div style={{ display: "flex" }}>
                                          <div style={{ flexGrow: 1 }}>
                                            <FormControlLabel
                                              control={<Checkbox />}
                                              label="Add to Compare"
                                              name="AddtoCompare"
                                              checked={
                                                this.state.compareBoatList.findIndex(
                                                  (cc) => {
                                                    return cc.id === boat.id;
                                                  }
                                                ) !== -1
                                              }
                                              onClick={(event) => {
                                                event.stopPropagation();
                                              }}
                                              onChange={(event) => {
                                                event.stopPropagation();
                                                this.addToCompare(event, boat);
                                              }}
                                            />
                                          </div>
                                          <Typography
                                            display={"inline"}
                                            className={classes.boatPrice}
                                          >
                                            {this.getPrice(
                                              boat?.attributes?.boat_price
                                            )
                                            // boat?.attributes?.boat_price ? boat?.attributes?.boat_price[this.state.filter?.durationFilter] :
                                            // boat?.attributes?.boat_price
                                            //   ?.full_day_rate
                                            }
                                            +
                                          </Typography>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </Box>
                                </Grid>
                                <Grid item xs={12}>
                                  <Divider variant="fullWidth"></Divider>
                                </Grid>
                              </Grid>
                            </Grid>
                          );
                        })
                      ) : (
                        <Grid item xs={10}>
                          <Typography color="primary" align="center" variant="h5">
                            No similot boats, that match your criteria found.
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      )
    ) : null;
  }
}
const SelectedBoatDetailsWithRouter = withRouter(SelectedBoatDetails);
const SelectedBoatDetailsAlertBox = withAlertBox(SelectedBoatDetailsWithRouter);
const SelectedBoatDetailsLoader = withLoader(SelectedBoatDetailsAlertBox);
const SelectedBoatDetailsToast = withToast(SelectedBoatDetailsLoader);
export default withStyles((theme) =>
  createStyles({
    root: {
      height: "calc(100vh - 70px)",
      overflowX: "hidden",
    },
    marginTop: {
      marginTop: "2px",
      overflowX: "hidden",
    },
    pageHeading: {
      fontFamily: "Montserrat",
      fontSize: "24px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "1px",
      color: "#343a40",
    },
    boatItemContainer: {
      boxShadow: "0 3px 8px 0 rgba(0, 0, 0, 0.1)",
      border: "solid 1px #b8b8b8",
      backgroundColor: "#ffffff",
      height: "auto",
    },
    boatImage: {
      position: "relative",
      backgroundImage: `url(${boatImage})`,
      backgroundRepeat: "no-repeat",
      backgroundColor:
        theme.palette.type === "light"
          ? theme.palette.grey[50]
          : theme.palette.grey[900],
      backgroundSize: "cover",
      backgroundPosition: "center",
    },
    boatName: {
      fontFamily: "Montserrat",
      fontSize: "28px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "normal",
      color: "#004277",
    },
    boatAddress: {
      fontFamily: "Montserrat",
      fontSize: "12px",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "normal",
      color: "#343a40",
    },
    ratingBox: {
      borderRadius: "5px",
      backgroundColor: "#f4c064",
      textAlign: "center",
      display: "inline-block",
    },
    rating: {
      fontFamily: "Roboto",
      fontSize: "14px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.19,
      letterSpacing: "normal",
      color: "#ffffff",
    },
    boatReview: {
      fontFamily: "Roboto",
      fontSize: "14px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.19,
      letterSpacing: "normal",
      color: "#979797",
      paddingLeft: theme.spacing(1.5),
    },
    boatPriceHeading: {
      fontFamily: "Montserrat",
      fontSize: "14px",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.19,
      letterSpacing: "normal",
      color: "#343a40",
    },
    boatPrice: {
      fontFamily: "Roboto",
      fontSize: "20px",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.19,
      letterSpacing: "normal",
      color: "#000000",
    },
    overViewHeading: {
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.19,
      letterSpacing: "normal",
      color: "#343a40",
    },
    featureHeading: {
      fontFamily: "Montserrat",
      fontSize: "12px",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.19,
      letterSpacing: "normal",
      color: "#343a40",
    },
    ownerName: {
      fontFamily: "Montserrat",
      fontSize: "12px",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.19,
      letterSpacing: "normal",
      color: "#000",
    },
    ownerNameHeading: {
      fontFamily: "Montserrat",
      fontSize: "12px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.19,
      letterSpacing: "normal",
      color: "#343a40",
    },
    sectionHeadingContainer: {
      backgroundColor: "#d4f2fc",
      paddingLeft: "8px",
    },
    sectionHeading: {
      fontFamily: "Montserrat",
      fontSize: "18px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.29,
      color: "#0174cf",
    },
    sectionLinkHeading: {
      fontFamily: "Montserrat",
      fontSize: "12px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      color: "#0174cf",
      cursor:'pointer'
    },
    sectionDescription: {
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      color: "#343a40",
    },
    sectionSubHeading: {
      fontFamily: "Roboto",
      fontSize: "16px",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.19,
      color: "#004277",
    },
    sectionListPrimartText: {
      fontFamily: "Roboto",
      fontSize: "16px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.19,
      color: "#545454",
    },
    sectionListSecondaryTest: {
      fontFamily: "Roboto",
      fontSize: "16px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.15,
      color: "#545454",
    },
    ownerTerm: {
      fontFamily: "Montserrat",
      fontSize: "20px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      color: "#0174cf",
    },
    boatPreference: {
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      color: "#004277",
    },
    incompleteStatus: {
      fontFamily: "Montserrat",
      fontSize: "12px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "1px",
      color: "#e9724c",
    },
    completeStatus: {
      fontFamily: "Montserrat",
      fontSize: "12px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "1px",
      color: "#00a676",
    },
    listPrimaryText: {
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "normal",
      color: "#000000",
    },
    listSecondaryText: {
      fontFamily: "Montserrat",
      fontSize: "12px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "normal",
      color: "#343a40",
    },
    swtichLable: {
      fontFamily: "Montserrat",
      fontSize: "10px",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "normal",
      color: "#343a40",
    },
    mapContainer: {
      height: "100%",
      width: "100%",
      position: "absolute",
      top: 0,
      left: 0,
    },
    map_parent_cont: {
      width: "100%",
      display: "flex",
      minHeight: "calc(100vh - 350px)",
      margin: "auto",
      position: "relative",
      justifyContent: "space-between",
      alignItems: "center",
    },
    boatMainImage: {
      position: "relative",
      backgroundImage: `url(${boatImageDemo1})`,
      backgroundRepeat: "no-repeat",
      backgroundColor:
        theme.palette.type === "light"
          ? theme.palette.grey[50]
          : theme.palette.grey[900],
      backgroundSize: "cover",
      backgroundPosition: "center",
      height: theme.spacing(40),
      [theme.breakpoints.down("xs")]: {
        height: theme.spacing(30),
      },
      borderTopLeftRadius: theme.spacing(1),
      borderTopRightRadius: theme.spacing(1),
    },
    hotelMainImage: {
      position: "relative",
      backgroundImage: `url(${boatImage})`,
      backgroundRepeat: "no-repeat",
      backgroundColor:
        theme.palette.type === "light"
          ? theme.palette.grey[50]
          : theme.palette.grey[900],
      backgroundSize: "cover",
      margin: theme.spacing(0.1),
      backgroundPosition: "center",
      height: theme.spacing(25),
      [theme.breakpoints.down("xs")]: {
        height: theme.spacing(15),
      },
      borderTopLeftRadius: theme.spacing(1),
      borderTopRightRadius: theme.spacing(1),
    },
    hoteloptionalImage: {
      position: "relative",
      margin: theme.spacing(0.1),
      backgroundImage: `url(${boatImage})`,
      backgroundRepeat: "no-repeat",
      backgroundColor:
        theme.palette.type === "light"
          ? theme.palette.grey[50]
          : theme.palette.grey[900],
      backgroundSize: "cover",
      backgroundPosition: "center",
      height: theme.spacing(12),
      [theme.breakpoints.down("xs")]: {
        height: theme.spacing(6),
      },
      borderTopLeftRadius: theme.spacing(1),
      borderTopRightRadius: theme.spacing(1),
    },
    carouselItemPadding40: {
      padding: "10px",
    },
    listItemClass: {
      backgroundColor: "#d4f2fc",
      borderRadius: "2px",
      width: "24px",
    },
  })
)(withDialog(SelectedBoatDetailsToast));
