import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { dialogBoxProps  } from "../../../components/src/withDialog.web";
import { withAlertBoxProps } from "../../../components/src/withAlertBox.Web";
import { withToastProps } from "../../../components/src/withSnackBar.Web";
import { withLoaderProps } from "../../../components/src/withLoader.Web";
export const configJSON = require("./config");

export type Props = RouterProps & dialogBoxProps & withAlertBoxProps & withToastProps & withLoaderProps & {
  id: string;
  // Customizable Area Start
  // Customizable Area End
};

interface S {
  // Customizable Area Start
  selectedCategory : String
  authToken : String,
  boatId : any,
  required_doc : {
    boating_license: boolean
    special_license: boolean
    state_license: boolean
  }
  isSaveAndExit : boolean
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class BoatApprovalDetailsContoller extends BlockComponent<
  Props,
  S,
  SS
> {
  approveDetailApiCallId: String = "";
  getBoatDetailApiCallId : String = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
      // Customizable Area End
    ];
    this.state = {
        selectedCategory : '',
        authToken : '',
         boatId : '',
         required_doc : {
          boating_license: false,
          special_license: false,
          state_license: false
        },
        isSaveAndExit : false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.getBoatDetailApiCallId) {
        this.props.hideLoader();
        if (responseJson && responseJson?.data && responseJson?.data?.id && responseJson?.data?.attributes && responseJson?.data?.attributes?.owner_approval_status) {
          this.setState({
              selectedCategory : responseJson?.data?.attributes?.owner_approval_status,
              required_doc : {
                boating_license : responseJson?.data?.attributes.required_doc?.boating_license,
                special_license:responseJson?.data?.attributes.required_doc?.special_license,
                state_license : responseJson?.data?.attributes.required_doc?.state_license
              }

            })
        } else {
          this.parseApiErrorResponse(responseJson);
        }
      } else if (apiRequestCallId === this.approveDetailApiCallId) {
        this.props.hideLoader();
        if (responseJson && responseJson?.data && responseJson?.data?.id) {
          this.props.showToast({type : 'success',message : 'Boat approval details updated SuccessFully'})
          const { history } = this.props;
          if(this.state.isSaveAndExit){
            await StorageProvider.remove("boatId")  
            history.push('/boatListingLandingPage');
          } else {
            this.props.history.push("/boatAvailableDates");
          }
        } else {
          this.parseApiErrorResponse(responseJson);
        }
      }
    } else if (getName(MessageEnum.AlertMessage) === message.id) {
      const title = message.getData(getName(MessageEnum.AlertTitleMessage));
      var AlertBodyMessage = message.getData(
        getName(MessageEnum.AlertBodyMessage)
      );
      const msg = await this.props.showAlert({title : 'Error',message : AlertBodyMessage})
      if(msg === 'Your session has expired, please login again.' || msg === 'Invalid token'){
        await StorageProvider.remove("authToken")
        await StorageProvider.remove("accountInfo")  
        this.props.history.push("/basicAuth/login")
      }
    }
  }
  getBoatDetail() {
    this.props.showLoader();
    const headers = {
      "content-type": configJSON.ApiContentType,
      token : this.state.authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getBoatDetailApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getBoatDetailPoint}/${this.state.boatId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  updateBoatCancelationPolicy(values: any) {
    this.props.showLoader();
    const headers = {
      "content-type": configJSON.ApiContentType,
      token : this.state.authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.approveDetailApiCallId = requestMessage.messageId;
    const httpBody = 
    {
      "owner_approval_status": this.state.selectedCategory === 'automatic' ? 0 : 1,
      "required_doc" : {
          "boating_license" : values.isBoatingLicense,
          "special_license":  values.isSpicialLicense,
          "state_license": values.isStateLicense
  
      }
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createBoatEndPoint}/${this.state.boatId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPUT
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
   
  
}
