//@ts-nocheck
import React from "react";
import MarinaListContainerController, { Props } from "./MarinaListContainerController.web";
import {
  withStyles,
  Grid,
  AppBar,
  Toolbar,
  Typography,
  createStyles,
  Box,
  ListItem,
  List,
  ListItemText,
  ListItemAvatar,
  ListItemIcon,
  Radio,
  Button,
  Avatar,
  Switch,
  Hidden,
  Menu,
  MenuItem
} from "@material-ui/core";
import { withRouter, Route, Redirect } from "react-router";
import Add from "@material-ui/icons/Add";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
import { boatImage,boatDetail,logo } from "./assets";
import withConfirmBox from "../../../components/src/withConfirmBox.Web";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const drawerWidth = 240;

const sideBarLinks = [
  { displayName: "Marina Dashboard", route: "/marinaDashboard/overview" },
  { displayName: "Notifications", route: "/marinaDashboard/Notifications" },
  { displayName: "Checkin", route: "/marinaDashboard/Checkin" },
  { displayName: "Check out", route: "/marinaDashboard/Checkout" },
];
class MarinaListContainer extends MarinaListContainerController {
  constructor(props: Props) {
    super(props);
  }
  async componentDidMount() {
    const authToken = await StorageProvider.get("authToken");
    const accountInfo = await StorageProvider.get("accountInfo");
    if (authToken) {
      this.setState(
        {
          authToken: authToken,
          accountInfo : accountInfo && JSON.parse(accountInfo)
        },
        () => {
          this.getMarinaListing();
        }
      );
    }
  }
  goToSelectedRoute = async (routeName,marina) => {
    const { history } = this.props;
    if(routeName === "logout"){
      const onClose = async (result) => {
        if(result === 'Yes'){
          await StorageProvider.clearStorage();
          this.props.history.push("/")
        }
      }
      this.props.openConfirmDialog(
        'Logout','Are you sure you want to logout from application',onClose
      )
    } else if(marina){
      await StorageProvider.remove("marinaId");
      await StorageProvider.set("marinaId",marina.id);
      history.push({pathname : routeName})
    } else {
      history.push({pathname : routeName})
    }
    
  };
  
  handleClose = () => {
    this.setState({
      anchorEl : null
    })
  };
  handleMenu = (event) => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };
  
  render() {
    const { classes } = this.props;
    let PageLinks = [];
    const menuLinks = [{ displayName: "My Account", route: "/userDashboard" },
    { displayName: "Logout", route: "logout" }]
    if(this.state.authToken){
      PageLinks = [
        { displayName: "List your boat", route: "/boatListingLandingPage" },
        { displayName: "Bookings", route: "/marinaDashboard/Checkin" },
      ]
    } else {
      PageLinks = [
        { displayName: "List your boat", route: "/boatListingLandingPage" },
        { displayName: "Login", route: "/basicAuth/login" },
        { displayName: "Signup", route: "/basicAuth/signup" },
      ];
    }
    return (
      <>
        <AppBar
          position="static"
          color="default"
          elevation={0}
          className={classes.appBar}
        >
          <Toolbar className={classes.toolbar}>
            <Box className={classes.toolbarLogo}>
            <img onClick={()=>this.props.history.push("/")}  src={logo} style={{width:'40%'}} alt="boaturu" />
            </Box>
            <Hidden xsDown>
            <nav>
              {PageLinks.map((link) => {
                return (
                  <Typography
                    key={link.displayName}
                    variant="button"
                    className={classes.link}
                    onClick={() => {
                      this.goToSelectedRoute(link.route);
                    }}
                  >
                    {link.displayName}
                  </Typography>
                );
              })}
            </nav>
            </Hidden>
            <div>
            <Hidden xsDown>
            <Avatar alt="profie image" src={this.state?.accountInfo?.avatar?.url} onClick={this.handleMenu} />
              <Menu
                id="menu-appbar"
                anchorEl={this.state.anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={!!this.state.anchorEl}
                onClose={this.handleClose}
              >
                {menuLinks.map((link) => {
                return (
                  <MenuItem 
                  key={link.displayName}
                  classes={{
                    root : classes.menuItem
                  }}
                  onClick={() => {
                    this.goToSelectedRoute(link.route);
                  }}>{link.displayName}</MenuItem>
                );
              })}
              </Menu>
            </Hidden>
            <Hidden smUp>
            
            <Avatar alt="profie image" src={this.state?.accountInfo?.avatar?.url} onClick={this.handleMenu} />
              <Menu
                id="menu-appbar"
                anchorEl={this.state.anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={!!this.state.anchorEl}
                onClose={this.handleClose}
              >
                {PageLinks.concat(menuLinks).map((link) => {
                return (
                  <MenuItem 
                  key={link.displayName}
                  classes={{
                    root : classes.menuItem
                  }}
                  onClick={() => {
                    this.goToSelectedRoute(link.route);
                  }}>{link.displayName}</MenuItem>
                );
              })}
              </Menu>
            </Hidden>
            </div>
           
            </Toolbar>
        </AppBar>
        <Grid
        container
        justify="center"
        spacing={3}
        className={classes.marginTop}
      >
        <Grid item xs={10}>
          <Grid container justify="space-between">
            <Grid xs={12} item>
              <Typography align="left" className={classes.pageHeading}>
                {" "}
                My Marina{" "}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {this.state.marinaList.map((boat) => {
          return (
            <Grid item key={boat.id} xs={10} >
              <Grid
                container
                justify="space-between"
                className={classes.boatItemContainer}
                spacing={1}
              >
                <Grid item xs={4} style={{backgroundImage : `url(${boat?.attributes?.image?.url ?  boat?.attributes?.image?.url : boatImage})`}} className={classes.boatImage} />
                <Grid item xs={8} container spacing={2}>
                  <Grid xs={12} item container justify={"space-between"}>
                    <Grid xs={6} item>
                      <Typography className={classes.boatName}>{boat?.attributes?.name}</Typography>
                      <Typography className={classes.listSecondaryText}>{boat?.attributes?.address?.address1},{boat?.attributes?.address?.city},{boat?.attributes?.address?.state}</Typography>
                    </Grid>
                    <Grid xs={2} item>
                          <ListItem disableGutters>
                            <ListItemText
                              primary={"Total Boats"}
                              classes={{
                                primary: classes.listPrimaryText,
                                secondary: classes.listSecondaryText,
                              }}
                              secondary={boat?.attributes?.boats ? boat?.attributes?.boats.length : "0"}
                            />
                          </ListItem>
                        </Grid>
                  </Grid>
                  <Grid xs={12} item>
                    <Grid spacing={1} container justify={"center"}>
                      <Grid xs={3} item>
                        <Button onClick={()=>{this.goToSelectedRoute("/marinaDashboard",boat)}} variant="contained" color="primary">
                           View Dashboard
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
      </>
      );
  }
}
const MarinaListContainerWithRouter = withRouter(MarinaListContainer);
const MarinaListContainerAlertBox = withAlertBox(MarinaListContainerWithRouter);
const MarinaListContainerLoader = withLoader(MarinaListContainerAlertBox);
const MarinaListContainerToast = withToast(MarinaListContainerLoader);
export default withStyles((theme) =>
  createStyles({
    root: {
      height: "calc(100vh - 70px)",
      overflowX: "hidden",
    },
    marginTop: {
      marginTop: "24px",
    },
    appBar: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      width: "100%",
      boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
      backgroundColor: "#337eb0",
      height: theme.spacing(8.75),
    },
    toolbar: {
      flexWrap: "nowrap",
    },
    toolbarLogo: {
      flexGrow: 1,
    },
    link: {
      margin: theme.spacing(1, 2.5),
      fontFamily: "Montserrat",
      fontSize: "14px",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "normal",
      textTransform: "none",
      cursor: "pointer",
      color: "#fff",
    },
    menuItem : {
      fontFamily: "Montserrat",
      fontSize: "14px",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "normal",
      textTransform: "none",
      cursor: "pointer",
      color: "#343a40",
    },
    pageHeading: {
      fontFamily: "Montserrat",
      fontSize: "24px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "1px",
      color: "#343a40",
    },
    boatItemContainer: {
      boxShadow: "0 3px 8px 0 rgba(0, 0, 0, 0.1)",
      border: "solid 1px #b8b8b8",
      backgroundColor: "#ffffff",
      height: "auto",
    },
    boatImage: {
      position: "relative",
      backgroundImage: `url(${boatImage})`,
      backgroundRepeat: "no-repeat",
      backgroundColor:
        theme.palette.type === "light"
          ? theme.palette.grey[50]
          : theme.palette.grey[900],
      backgroundSize: "cover",
      backgroundPosition: "center",
    },
    boatName : {
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "normal",
      color: "#004277",
    },
    incompleteStatus : {
      fontFamily: "Montserrat",
      fontSize: "12px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "1px",
      color: "#e9724c",
    },
    completeStatus : {
        fontFamily: "Montserrat",
        fontSize: "12px",
        fontWeight: 600,
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1.25,
        letterSpacing: "1px",
        color: "#00a676",
    },
    listPrimaryText : {
        fontFamily: "Montserrat",
        fontSize: "16px",
        fontWeight: 600,
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1.25,
        letterSpacing: "normal",
        color: "#000000",
    },
    listSecondaryText : {
        fontFamily: "Montserrat",
        fontSize: "12px",
        fontWeight: 600,
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1.25,
        letterSpacing: "normal",
        color: "#343a40",
    },
    swtichLable : {
        fontFamily: "Montserrat",
        fontSize: "10px",
        fontWeight: 500,
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1.25,
        letterSpacing: "normal",
        color: "#343a40",
    }
  })
)(withConfirmBox(MarinaListContainerToast));
