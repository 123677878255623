//@ts-nocheck
import {
  Box,
  Button,
  Checkbox,
  createStyles,
  Divider,
  Grid,
  Hidden,
  IconButton,
  Menu,
  MenuItem,
  Popover,
  Slider,
  TextField,
  Typography,
  withStyles,
} from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import FavoriteIcon from "@material-ui/icons/Favorite";
import StarIcon from "@material-ui/icons/Star";
import React from "react";
import {
  GoogleMap as Map,
  withGoogleMap,
  withScriptjs,
  Marker,
  InfoWindow,
} from "react-google-maps";
import { withRouter } from "react-router";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withLoader from "../../../components/src/withLoader.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { boatImage, pin, marinaIcon, ownerPin } from "./assets";
import moment from "moment";
import BoatSearchResultController, {
  Props,
} from "./BoatSearchResultController.web";
const containerStyle = {
  width: "100%",
  height: "100%",
};
const options = {
  disableDefaultUI: true,
  zoomControl: true,
};
const defaultMapOptions = {
  fullscreenControl: false,
  mapTypeControl: false,
  streetViewControl: false,
};
class RestaurantMarker extends React.Component {
  // State.
  state = { open: false };

  // Render.
  render() {
    // Extract Props.
    const { name, latitude, longitude, icon } = this.props;

    // Return Restaurant Marker Component.
    return (
      <Marker
        icon={icon}
        key={name}
        position={{ lat: latitude, lng: longitude }}
      >
        {/* <InfoWindow onClick={() => this.setState(state => ({open: !state.open}))}> {name} </InfoWindow> */}
      </Marker>
    );
  }
}
const MapWithAMarker = withScriptjs(
  withGoogleMap((props) => {
    return (
      <Map
        defaultZoom={props.zoom}
        defaultCenter={props.center}
        defaultOptions={defaultMapOptions}
      >
        {props.lat &&
          props.lng &&
          props.markers &&
          props.markers.length > 0 &&
          props.markers.map((marker) => {
            return (
              <RestaurantMarker
                key={marker.id}
                latitude={marker.lat}
                longitude={marker.lng}
                name={marker.name}
                icon={marker.location_type === "marina" ? marinaIcon : ownerPin}
              />
            );
          })}
      </Map>
    );
  })
);

const curDate = moment(new Date())
  .format("YYYY-MM-DD")
  .toString();
class BoatSearchResult extends BoatSearchResultController {
  constructor(props: Props) {
    super(props);
  }
  async componentDidMount() {
    const authToken = await StorageProvider.get("authToken");
    const boatCompareList = await StorageProvider.get("boatCompareList");
    const boatSearchData = this.props?.history?.location?.state || {};
    const actionType = this.props?.history?.location?.state?.actionType;
    const searchType = this.props?.history?.location?.state?.searchType;
    const searchId = this.props?.history?.location?.state?.searchId;

    const {
      match: { params },
    } = this.props;
    if (authToken) {
      await this.setState(
        {
          authToken: authToken,
        },
        async () => {
          await this.setState(
            {
              filter: {
                ...this.state.filter,
                ...boatSearchData,
                location: params.id,
              },
              actionType: actionType || null,
              searchType: searchType,
            },
            async () => {
              if (searchType === "location") {
                const lat = this.props.location.state.latitude;
                const lng = this.props.location.state.longitude;
                if (lat && lng) {
                  this.setState(
                    {
                      lat: lat,
                      lng: lng,
                      showMap : true
                    },
                    () => {
                      this.getBoatListing(this.state.filter);
                    }
                  );
                } else {
                  this.getBoatListing(this.state.filter);
                }
              } else if (searchType === "activity") {
                const latlng = await this.getPreciseLocation();
                this.setState({
                  lat: latlng.lat,
                  lng: latlng.lng,
                  showMap : true
                },()=>{
                  this.getBoatsByActivity(searchId, latlng);
                })
                
              } else if (searchType === "area") {
                const latlng = await this.getPreciseLocation();
                this.setState({
                  lat: latlng.lat,
                  lng: latlng.lng,
                  showMap : true
                },()=>{
                  this.getBoatsByArea(searchId, latlng);
                })
              } else if (searchType === "All") {
                const latlng = await this.getPreciseLocation();
                this.setState(
                  {
                    lat: latlng.lat,
                    lng: latlng.lng,
                    showMap : true
                  },
                  () => {
                    this.getAllBoat();
                  }
                );
              } else {
                const lat = this.props.location.state.latitude;
                const lng = this.props.location.state.longitude;
                if (lat && lng) {
                  this.setState(
                    {
                      lat: lat,
                      lng: lng,
                      showMap : true
                    },
                    () => {
                      this.getBoatListing(this.state.filter);
                    }
                  );
                } else {
                  this.getBoatListing(this.state.filter);
                }
              }
            }
          );
        }
      );
    } else {
      this.setState(
        {
          filter: {
            ...this.state.filter,
            ...boatSearchData,
            location: params.id,
            searchType: searchType,
          },
        },
        async () => {
          if (searchType === "location") {
            const lat = this.props.location.state.latitude;
            const lng = this.props.location.state.longitude;
            if (lat && lng) {
              this.setState(
                {
                  lat: lat,
                  lng: lng,
                  showMap : true
                },
                () => {
                  this.getBoatListing(this.state.filter);
                }
              );
            } else {
              this.getBoatListing(this.state.filter);
            }
          } else if (searchType === "activity") {
            const latlng = await this.getPreciseLocation();
            this.setState({
              lat: latlng.lat,
                  lng: latlng.lng,
              showMap : true
            },()=>{
              this.getBoatsByActivity(searchId, latlng);
            })
          } else if (searchType === "area") {
            const latlng = await this.getPreciseLocation();
            this.setState({
              lat: latlng.lat,
                  lng: latlng.lng,
              showMap : true
            },()=>{
              this.getBoatsByArea(searchId, latlng);
            })
            
          } else if (searchType === "All") {
            const latlng = await this.getPreciseLocation();
            this.setState(
              {
                lat: latlng.lat,
                lng: latlng.lng,
                showMap : true
              },
              () => {
                this.getAllBoat();
              }
            );
          } else {
            const lat = this.props.location.state.latitude;
            const lng = this.props.location.state.longitude;
            if (lat && lng) {
              this.setState(
                {
                  lat: lat,
                  lng: lng,
                  showMap : true
                },
                () => {
                  this.getBoatListing(this.state.filter);
                }
              );
            } else {
              this.getBoatListing(this.state.filter);
            }
          }
        }
      );
    }
    if (boatCompareList) {
      this.setState({
        compareBoatList: JSON.parse(boatCompareList),
      });
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      match: { params },
    } = this.props;
    const {
      match: { params: prev },
    } = prevProps;
    if (params.id !== prev.id) {
      this.setState(
        {
          filter: { location: params.id },
        },
        () => {
          const lat = this.props.location.state.latitude;
          const lng = this.props.location.state.longitude;
          if (lat && lng) {
            this.setState(
              {
                lat: lat,
                lng: lng,
                showMap: false,
              },
              () => {
                this.setState(
                  {
                    showMap: true,
                  },
                  () => {
                    this.getBoatListing(this.state.filter);
                  }
                );
              }
            );
          } else {
            this.getBoatListing(this.state.filter);
          }
        }
      );
    }
  }

  getPreciseLocation = () => {
    return new Promise(function(resolve, reject) {
      navigator.geolocation.getCurrentPosition(
        function(position) {
          resolve({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        (error) => {
          console.log(error);
          reject("Geolocation is not supported by your browser");
        },
        { maximumAge: 10000, timeout: 50000, enableHighAccuracy: true }
      );
    });
  };

  goToSelectedRoute = (routeName) => {
    const { history } = this.props;
    history.push({pathname : routeName,state: { boatId: this.state.boatId, duration: this.state.duration }});
  };
  toggleMap = () => {
    this.setState({
      showMap: !this.state.showMap,
    });
  };

  openSortByMenu = (event) => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };
  selectValueAndCloseMenu = (sortValue, sortType, name) => {
    this.setState(
      {
        sortValueHeading: name,
        sortValue: sortValue,
        sortType: sortType,
        anchorEl: null,
      },
      () => {
        this.getBoatListing(this.state.filter, sortValue, sortType);
      }
    );
    
  };
  gotoBoatDetails = (boatId) => {
    const { history } = this.props;
    history.push({
      pathname: "/userDashboard/selectedBoatDetails",
      state: {
        boatId: boatId,
        duration: this.state.filter.durationFilter || null,
      },
    });
  };
  addToCompare = async (event, boat) => {
    let compareBoatList = [...this.state.compareBoatList];
    const index = compareBoatList.findIndex((compareBaot) => {
      return compareBaot.id == boat.id;
    });
    if (index === -1) {
      compareBoatList = [...compareBoatList, boat];
      this.setState(
        {
          compareBoatList: compareBoatList,
        },
        async () => {
          await StorageProvider.set(
            "boatCompareList",
            JSON.stringify(this.state.compareBoatList)
          );
        }
      );
    } else {
      compareBoatList.splice(index, 1);
      this.setState(
        {
          compareBoatList: compareBoatList,
        },
        async () => {
          await StorageProvider.set(
            "boatCompareList",
            JSON.stringify(this.state.compareBoatList)
          );
        }
      );
    }
  };
  saveCureentPathIfNotLoggedIn = async (boat) => {
    if (this.state.authToken) {
      const boatList = [...this.state.boatListCopy];
      let newBoatIndex = boatList.findIndex((newBaot) => {
        return newBaot.id === boat.id;
      });
      const newBoat = {
        ...boatList[newBoatIndex],
        attributes: {
          ...boatList[newBoatIndex].attributes,
          like_boat: !boatList[newBoatIndex]?.attributes?.like_boat,
        },
      };
      boatList[newBoatIndex] = { ...newBoat };
      this.setState(
        {
          boatListCopy: [...boatList],
        },
        () => {
          this.likeAndUnLikeAboat(boat?.attributes?.like_boat, boat.id);
        }
      );
    } else {
      const currentPath = this.props?.history?.location?.pathname;
      let currentRouteState = { actionType: "LikeABoat", boatDetails: boat };
      await StorageProvider.set("currentPath", currentPath);
      await StorageProvider.set(
        "currentRouteState",
        JSON.stringify(currentRouteState)
      );
      this.props.history.push("/basicAuth");
    }
  };
  openDurationFilter = (ev) => {
    this.setState({
      durationanchorEl: ev.currentTarget,
    });
  };

  closeDurationFilter = () => {
    this.setState({ durationanchorEl: null });
  };

  changeDurationValue = (evt, value) => {
    // let durationFilter = (this.state.filter?.durationFilter && [...this.state.filter?.durationFilter]) || [];
    if (evt.target.checked) {
      // durationFilter = [...durationFilter,value]
      this.setState(
        (prevState) => {
          return {
            ...prevState,
            filter: { ...prevState.filter, durationFilter: value },
          };
        },
        () => {
          this.getBoatListing(this.state.filter);
        }
      );
    } else {
      // durationFilter = durationFilter.filter((category) => {
      //   return category !== value;
      // })
      this.setState(
        (prevState) => {
          return {
            ...prevState,
            filter: { ...prevState.filter, durationFilter: null },
          };
        },
        () => {
          this.getBoatListing(this.state.filter);
        }
      );
    }
  };
  openCaptainFilter = (ev) => {
    this.setState({
      captionAnchorEl: ev.currentTarget,
    });
  };
  closeCaptainFilter = () => {
    this.setState({ captionAnchorEl: null });
  };

  changeCaptainValue = (evt, val) => {
    if (evt.target.checked) {
      this.setState(
        (prevState) => {
          return {
            ...prevState,
            filter: { ...prevState.filter, captainFilter: val },
          };
        },
        () => {
          this.getBoatListing(this.state.filter);
        }
      );
    } else {
      this.setState(
        (prevState) => {
          return {
            ...prevState,
            filter: { ...prevState.filter, captainFilter: null },
          };
        },
        () => {
          this.getBoatListing(this.state.filter);
        }
      );
    }
  };
  openPassengerFilter = (ev) => {
    this.setState({
      passengerAnchorEl: ev.currentTarget,
    });
  };
  closePassengerFilter = () => {
    this.setState({ passengerAnchorEl: null });
  };
  applyclosePassengerFilter = () => {
    this.setState({ passengerAnchorEl: null }, () => {
      this.getBoatListing(this.state.filter);
    });
  };

  changePassengerValue = (val) => {
    let passengerFilter = this.state.filter.passengerFilter || 0;
    if (val === "+") {
      passengerFilter = passengerFilter + 1;
      if (passengerFilter < 50) {
        this.setState(
          (prevState) => {
            return {
              ...prevState,
              filter: { ...prevState.filter, passengerFilter: passengerFilter },
            };
          },
          () => {
            // this.getBoatListing(this.state.filter)
          }
        );
      }
    } else {
      passengerFilter = passengerFilter - 1;
      if (passengerFilter > 0) {
        this.setState(
          (prevState) => {
            return {
              ...prevState,
              filter: { ...prevState.filter, passengerFilter: passengerFilter },
            };
          },
          () => {
            // this.getBoatListing(this.state.filter)
          }
        );
      }
    }
  };

  openMoreFilter = (ev) => {
    this.setState({
      moreEl: ev.currentTarget,
    });
  };
  closeMoreFilter = () => {
    this.setState({ moreEl: null }, () => {
      // this.getBoatListing(this.state.filter);
    });
  };
  applyAndCloseMoreFilter = () => {
    this.setState({ moreEl: null }, () => {
      this.getBoatListing(this.state.filter);
    });
  };
  getHours = () => {
    if (this.state.filter.durationFilter) {
      if (this.state.filter.durationFilter === "half_day") {
        return "0-4 Hours ";
      } else if (this.state.filter.durationFilter === "full_day") {
        return "8 Hours ";
      } else if (this.state.filter.durationFilter === "twentyfour_hours") {
        return "24 Hours ";
      } else if (this.state.filter.durationFilter === "three_days") {
        return "3 Days ";
      } else if (this.state.filter.durationFilter === "seven_days") {
        return "7 Days ";
      }
    } else {
      return "0-4 Hours ";
    }
  };
  getPrice = (boatPrice1 = {}) => {
    const boatPrice = boatPrice1 ? boatPrice1 : {}
    if (this.state.filter.durationFilter) {
      if (this.state.filter.durationFilter === "half_day") {
        return `$ ${boatPrice["half_day_rate"] || "-"}`;
      } else if (this.state.filter.durationFilter === "full_day") {
        return `$ ${boatPrice["full_day_rate"] || "-"}`;
      } else if (this.state.filter.durationFilter === "twentyfour_hours") {
        return `$ ${boatPrice["twenty_four_hours_rate"] || "-"}`;
      } else if (this.state.filter.durationFilter === "three_days") {
        return `$ ${boatPrice["three_days_rate"] || "-"}`;
      } else if (this.state.filter.durationFilter === "seven_days") {
        return `$ ${boatPrice["seven_days_rate"] || "-"}`;
      }
    } else {
      return `$ ${boatPrice["half_day_rate"] || "-"}`;
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <Grid container className={classes.marginTop} spacing={2}>
        <Grid item xs={12}>
          {this.state.compareBoatList && this.state.compareBoatList.length > 0 && (
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                this.goToSelectedRoute("/userDashboard/boatCompasison");
              }}
              className={classes.comparebutton}
            >
              Compare ({this.state.compareBoatList.length})
            </Button>
          )}
        </Grid>
        {this.state.searchType === "location" ? (
          <Grid item md={this.state.showMap ? 8 : 10} xs={12}>
            <Grid container justify="space-around" spacing={1}>
              <Grid item md={9} xs={12}>
                <Grid container spacing={1} justify="center">
                  <Grid item md={3} xs={4}>
                    <TextField
                      id="date"
                      type="date"
                      fullWidth
                      variant="standard"
                      InputProps={{
                        inputProps: {
                          min: curDate,
                          style: {
                            padding: "6px 0 12px!important",
                          },
                        },
                        classes: {
                          input: classes?.input,
                        },
                        style: {
                          padding: "6px 0 12px!important",
                        },
                      }}
                      name="dateFilter"
                      value={this.state.filter.dateFilter}
                      onChange={(evt) => {
                        const date = evt.target.value;
                        this.setState(
                          (prevState) => {
                            return {
                              ...prevState,
                              filter: { ...prevState.filter, dateFilter: date },
                            };
                          },
                          () => {
                            this.getBoatListing(this.state.filter);
                          }
                        );
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      style={{
                        color: "#000",
                        border: "solid 2px #cccccc",
                        height: "40px",
                      }}
                    />
                  </Grid>
                  <Grid item md={2} xs={4}>
                    <Button
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      variant="outlined"
                      fullWidth
                      style={{
                        color: "#000",
                        backgroundColor: "#FFF",
                        border: "solid 2px #cccccc",
                      }}
                      onClick={(ev) => this.openDurationFilter(ev)}
                    >
                      Duration
                    </Button>
                    <Popover
                      open={!!this.state?.durationanchorEl}
                      anchorEl={this.state?.durationanchorEl}
                      onClose={this.closeDurationFilter}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                    >
                      <Box
                        p={2}
                        display="flex"
                        flexDirection="column"
                        alignItems="flex-end"
                      >
                        <Box>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={
                                  this.state.filter?.durationFilter ===
                                  "half_day"
                                }
                                onChange={(e) =>
                                  this.changeDurationValue(e, "half_day")
                                }
                                name="duration"
                                color="primary"
                              />
                            }
                            label="4 Hour"
                          />
                        </Box>
                        <Box>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={
                                  this.state.filter?.durationFilter ===
                                  "full_day"
                                }
                                onChange={(e) =>
                                  this.changeDurationValue(e, "full_day")
                                }
                                name="duration"
                                color="primary"
                              />
                            }
                            label="8 Hour"
                          />
                        </Box>
                        <Box>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={
                                  this.state.filter?.durationFilter ===
                                  "twentyfour_hours"
                                }
                                onChange={(e) =>
                                  this.changeDurationValue(
                                    e,
                                    "twentyfour_hours"
                                  )
                                }
                                name="duration"
                                color="primary"
                              />
                            }
                            label="24 Hour"
                          />
                        </Box>
                        <Box>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={
                                  this.state.filter?.durationFilter ===
                                  "three_days"
                                }
                                onChange={(e) =>
                                  this.changeDurationValue(e, "three_days")
                                }
                                name="duration"
                                color="primary"
                              />
                            }
                            label="3 Days"
                          />
                        </Box>
                        <Box>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={
                                  this.state.filter?.durationFilter ===
                                  "seven_days"
                                }
                                onChange={(e) =>
                                  this.changeDurationValue(e, "seven_days")
                                }
                                name="duration"
                                color="primary"
                              />
                            }
                            label="7 Days"
                          />
                        </Box>
                        <Box textAlign="right">
                          <Button
                            disableRipple
                            disableTouchRipple
                            onClick={this.closeDurationFilter}
                          >
                            Close
                          </Button>
                        </Box>
                      </Box>
                    </Popover>
                  </Grid>
                  <Grid item md={2} xs={4}>
                    <Button
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      variant="outlined"
                      fullWidth
                      style={{
                        color: "#000",
                        backgroundColor: "#FFF",
                        border: "solid 2px #cccccc",
                      }}
                      onClick={(ev) => this.openCaptainFilter(ev)}
                    >
                      Captain
                    </Button>
                    <Popover
                      open={!!this.state?.captionAnchorEl}
                      anchorEl={this.state?.captionAnchorEl}
                      onClose={this.closeCaptainFilter}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                    >
                      <Box p={2}>
                        <Box>
                          <Typography variant="h6">
                            {" "}
                            Please Select do you want a captain for your boat or
                            not{" "}
                          </Typography>
                          <Box>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    this.state?.filter?.captainFilter ===
                                    "caption"
                                  }
                                  onChange={(ev) =>
                                    this.changeCaptainValue(ev, "caption")
                                  }
                                  name="captain"
                                  color="primary"
                                />
                              }
                              label="Captain"
                            />
                          </Box>
                          <Box>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    this.state?.filter?.captainFilter ===
                                    "No_Captain"
                                  }
                                  onChange={(ev) =>
                                    this.changeCaptainValue(ev, "No_Captain")
                                  }
                                  name="captain"
                                  color="primary"
                                />
                              }
                              label="No Captain"
                            />
                          </Box>
                        </Box>
                        <Box textAlign="right">
                          <Button
                            disableRipple
                            disableTouchRipple
                            onClick={this.closeCaptainFilter}
                          >
                            Close
                          </Button>
                        </Box>
                      </Box>
                    </Popover>
                  </Grid>
                  <Grid item md={2} xs={4}>
                    <Button
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      variant="outlined"
                      fullWidth
                      style={{
                        color: "#000",
                        backgroundColor: "#FFF",
                        border: "solid 2px #cccccc",
                      }}
                      onClick={(ev) => this.openPassengerFilter(ev)}
                    >
                      Passenger
                    </Button>
                    <Popover
                      open={!!this.state?.passengerAnchorEl}
                      anchorEl={this.state?.passengerAnchorEl}
                      onClose={this.closePassengerFilter}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                    >
                      <Box p={3} pb={1}>
                        <Box>
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => this.changePassengerValue("-")}
                            className={classes.passengerButtons}
                          >
                            -
                          </Button>
                          <Typography component="span">
                            {this.state.filter.passengerFilter || 0}
                          </Typography>
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => this.changePassengerValue("+")}
                            className={classes.passengerButtons}
                          >
                            +
                          </Button>
                        </Box>
                        <Box textAlign="right">
                          <Button
                            disableRipple
                            disableTouchRipple
                            onClick={this.closePassengerFilter}
                          >
                            Close
                          </Button>
                          <Button
                            disableRipple
                            disableTouchRipple
                            onClick={this.applyclosePassengerFilter}
                          >
                            Apply
                          </Button>
                        </Box>
                      </Box>
                    </Popover>
                  </Grid>
                  <Grid item md={2} xs={4}>
                    <Button
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      variant="outlined"
                      fullWidth
                      style={{
                        color: "#000",
                        backgroundColor: "#FFF",
                        border: "solid 2px #cccccc",
                      }}
                      onClick={(ev) => this.openMoreFilter(ev)}
                    >
                      More Filters
                    </Button>
                    <Popover
                      open={!!this.state?.moreEl}
                      anchorEl={this.state?.moreEl}
                      onClose={this.closeMoreFilter}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                    >
                      <Box p={3} minWidth={300}>
                        <Box>
                          <Typography variant="h5">Price</Typography>
                          <Box textAlign="right">
                            <Typography variant="h6">
                              {this.state.filter?.priceFilter
                                ? `${this.state.filter?.priceFilter &&
                                    this.state.filter?.priceFilter[0]}-${this
                                    .state.filter?.priceFilter &&
                                    this.state.filter.priceFilter[1]}`
                                : `0-5000`}
                            </Typography>
                          </Box>
                          <Slider
                            value={this.state.filter.priceFilter || [0, 5000]}
                            onChange={(evt, newValue) => {
                              const date = evt.target.value;
                              this.setState(
                                (prevState) => {
                                  return {
                                    ...prevState,
                                    filter: {
                                      ...prevState.filter,
                                      priceFilter: newValue,
                                    },
                                  };
                                },
                                () => {
                                  // this.getBoatListing(this.state.filter)
                                }
                              );
                            }}
                            aria-labelledby="range-slider"
                            step={100}
                            min={0}
                            max={5000}
                          />
                        </Box>
                        <Box textAlign="right">
                          <Button
                            disableRipple
                            disableTouchRipple
                            variant="text"
                            onClick={this.applyAndCloseMoreFilter}
                          >
                            Apply
                          </Button>
                        </Box>
                      </Box>
                    </Popover>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={3} xs={12}>
                <Hidden xsDown>
                  <div style={{ textAlign: "right" }}>
                    <Typography display="inline" className={classes.sortBy}>
                      Sort by :
                    </Typography>
                    <Typography display="inline" className={classes.sortBy}>
                      &nbsp;&nbsp;{this.state.sortValueHeading}
                    </Typography>
                    <IconButton
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      onClick={this.openSortByMenu}
                    >
                      <ArrowDropDownIcon />
                    </IconButton>
                  </div>
                </Hidden>
                <Menu
                  id="simple-menu"
                  anchorEl={this.state.anchorEl}
                  keepMounted
                  open={!!this.state.anchorEl}
                  onClose={() => {
                    this.setState({
                      anchorEl: null,
                    });
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      this.selectValueAndCloseMenu(
                        "price",
                        "desc",
                        "Highest Price"
                      );
                    }}
                  >
                    Highest Price
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      this.selectValueAndCloseMenu(
                        "price",
                        "asc",
                        "Lowest Price"
                      );
                    }}
                  >
                    Lowest Price
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      this.selectValueAndCloseMenu(
                        "rating",
                        "asc",
                        "Lowest Rating"
                      );
                    }}
                  >
                    Lowest Rating
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      this.selectValueAndCloseMenu(
                        "rating",
                        "desc",
                        "Highest Rating"
                      );
                    }}
                  >
                    Highest Rating
                  </MenuItem>
                </Menu>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid item md={this.state.showMap ? 8 : 10} xs={12}></Grid>
        )}
        <Hidden xsDown>
          <Grid item md={2} xs={12}>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                this.toggleMap();
              }}
            >
              {this.state.showMap ? "Hide Map" : "Show Map"}
            </Button>
          </Grid>
        </Hidden>

        <Grid item xs={12} md={this.state.showMap ? 8 : 11}>
          <Grid container justify="space-around" spacing={2}>
            {this.state.boatListCopy.length > 0 ? (
              this.state.boatListCopy?.map((boat) => {
                return (
                  <Grid
                    item
                    container
                    key={boat.id}
                    xs={11}
                    onClick={() => {
                      this.gotoBoatDetails(boat.id);
                    }}
                  >
                    <Grid container justify="space-between" spacing={1}>
                      <Grid
                        item
                        xs={4}
                        style={{
                          backgroundImage: `url(${
                            boat?.attributes?.thumbnail_image?.url
                              ? boat?.attributes?.thumbnail_image?.url
                              : boatImage
                          })`,
                        }}
                        className={classes.boatImage}
                      />
                      <Grid item xs={8}>
                        <Box p={1}>
                          <Grid container justify={"space-between"} spacing={2}>
                            <Grid xs={12} item>
                              <div style={{ display: "flex" }}>
                                <div style={{ flexGrow: 1 }}>
                                  <Typography className={classes.boatName}>
                                    {boat?.attributes?.name}
                                  </Typography>
                                  <Typography className={classes.boatAddress}>
                                    {`${boat?.attributes?.address?.address1},${boat?.attributes?.address?.city}`}
                                  </Typography>
                                </div>
                                <IconButton
                                  onClick={(evt) => {
                                    evt.stopPropagation();
                                    this.saveCureentPathIfNotLoggedIn(boat);
                                  }}
                                >
                                  <FavoriteIcon
                                    fontSize={"small"}
                                    style={{
                                      color: boat?.attributes?.like_boat
                                        ? "red"
                                        : "grey",
                                      backgroundColor: "#fff",
                                      borderRadius: "50%",
                                    }}
                                  ></FavoriteIcon>
                                </IconButton>
                              </div>
                            </Grid>
                            <Grid xs={12} item>
                              <Typography className={classes.boatAddress}>
                                {this.getHours()}
                                {`Up to ${boat?.attributes?.max_passengers} passengers`}
                              </Typography>
                              <Typography className={classes.boatAddress}>
                                {boat?.attributes?.boat_operator === "renter"
                                  ? "No Captain Included"
                                  : "Captained"}
                              </Typography>
                            </Grid>
                            <Grid xs={12} item>
                              <Box px={1} className={classes.ratingBox}>
                                <Typography
                                  display="inline"
                                  className={classes.rating}
                                >
                                  {parseFloat(
                                    boat?.attributes?.average_rating
                                      ? boat?.attributes?.average_rating.toFixed(
                                          "2"
                                        )
                                      : boat?.attributes?.reviews?.avg_rating
                                      ? Number(
                                          boat?.attributes?.reviews?.avg_rating
                                        ).toFixed(2)
                                      : 0
                                  )}{" "}
                                </Typography>
                                <StarIcon
                                  style={{ color: "white" }}
                                  fontSize="small"
                                ></StarIcon>
                              </Box>
                              <Typography
                                display="inline"
                                className={classes.boatReview}
                              >
                                {`${
                                  boat?.attributes?.reviews.length > 0
                                    ? boat?.attributes?.reviews.length
                                    : boat?.attributes?.reviews?.reviews?.data
                                        ?.length > 0
                                    ? boat?.attributes?.reviews?.reviews?.data
                                        ?.length
                                    : 0
                                } Reviews`}
                              </Typography>
                            </Grid>
                            <Grid xs={12} item>
                              <div style={{ display: "flex" }}>
                                <div style={{ flexGrow: 1 }}>
                                  <FormControlLabel
                                    control={<Checkbox />}
                                    label="Add to Compare"
                                    name="AddtoCompare"
                                    checked={
                                      this.state.compareBoatList.findIndex(
                                        (cc) => {
                                          return cc.id === boat.id;
                                        }
                                      ) !== -1
                                    }
                                    onClick={(event) => {
                                      event.stopPropagation();
                                    }}
                                    onChange={(event) => {
                                      event.stopPropagation();
                                      this.addToCompare(event, boat);
                                    }}
                                  />
                                </div>
                                <Typography
                                  display={"inline"}
                                  className={classes.boatPrice}
                                >
                                  {this.getPrice(boat?.attributes?.boat_price)
                                  // boat?.attributes?.boat_price ? boat?.attributes?.boat_price[this.state.filter?.durationFilter] :
                                  // boat?.attributes?.boat_price
                                  //   ?.full_day_rate
                                  }
                                  +
                                </Typography>
                              </div>
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider variant="fullWidth"></Divider>
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })
            ) : (
              <Grid item xs={10}>
                <Typography color="primary" variant="h5">
                  No boats, that match your criteria found.
                </Typography>
                <Typography color="textPrimary" variant="body">
                  Try refining your search.
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
        {this.state.showMap ? (
          <Grid item xs={12} md={4}>
            <Grid container>
              <Grid item xs={12}>
                <div className={classes.map_parent_cont}>
                  <div className={classes.mapContainer}>
                    {this.state.lat && this.state.lng && (
                      <MapWithAMarker
                        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCmM-iLmhPns_mUWUErYXe2SpaJMbNvodc&v=3.exp&libraries=geometry,drawing,places"
                        loadingElement={<div style={{ height: `100%` }} />}
                        containerElement={<div style={{ height: `100%` }} />}
                        mapElement={<div style={{ height: `100%` }} />}
                        center={{
                          lat: this.state.lat || 0,
                          lng: this.state.lng || 0,
                        }}
                        zoom={14}
                        markers={
                          this.state.markers.length > 0
                            ? this.state.markers
                            : []
                        }
                        lat={this.state.lat}
                        lng={this.state.lng}
                      />
                    )}
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
    );
  }
}
const BoatSearchResultWithRouter = withRouter(BoatSearchResult);
const BoatSearchResultAlertBox = withAlertBox(BoatSearchResultWithRouter);
const BoatSearchResultLoader = withLoader(BoatSearchResultAlertBox);
const BoatSearchResultToast = withToast(BoatSearchResultLoader);
export default withStyles((theme) =>
  createStyles({
    root: {
      height: "calc(100vh - 70px)",
      overflowX: "hidden",
    },
    marginTop: {
      marginTop: "8px",
      position: "absolute",
    },
    dateInput: {
      height: "40px",
    },
    pageHeading: {
      fontFamily: "Montserrat",
      fontSize: "24px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "1px",
      color: "#343a40",
    },
    boatItemContainer: {
      boxShadow: "0 3px 8px 0 rgba(0, 0, 0, 0.1)",
      border: "solid 1px #b8b8b8",
      backgroundColor: "#ffffff",
      height: "auto",
    },
    boatImage: {
      position: "relative",
      backgroundImage: `url(${boatImage})`,
      backgroundRepeat: "no-repeat",
      backgroundColor:
        theme.palette.type === "light"
          ? theme.palette.grey[50]
          : theme.palette.grey[900],
      backgroundSize: "cover",
      backgroundPosition: "center",
      borderRadius: theme.spacing(1),
    },
    boatHiddenMapImage: {
      position: "relative",
      backgroundImage: `url(${boatImage})`,
      backgroundRepeat: "no-repeat",
      backgroundColor:
        theme.palette.type === "light"
          ? theme.palette.grey[50]
          : theme.palette.grey[900],
      backgroundSize: "cover",
      backgroundPosition: "center",
      borderRadius: theme.spacing(1),
      height: theme.spacing(25),
    },
    boatName: {
      fontFamily: "Roboto",
      fontSize: "16px",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.15,
      letterSpacing: "normal",
      color: "#000000",
      cursor: "pointer",
    },
    boatAddress: {
      fontFamily: "Roboto",
      fontSize: "14px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.19,
      letterSpacing: "normal",
      color: "#545454",
    },
    ratingBox: {
      borderRadius: "5px",
      backgroundColor: "#f4c064",
      textAlign: "center",
      display: "inline-block",
    },
    rating: {
      fontFamily: "Roboto",
      fontSize: "14px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.19,
      letterSpacing: "normal",
      color: "#ffffff",
    },
    boatReview: {
      fontFamily: "Roboto",
      fontSize: "14px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.19,
      letterSpacing: "normal",
      color: "#979797",
      paddingLeft: theme.spacing(1.5),
    },
    sortBy: {
      opacity: 0.59,
      fontFamily: "Roboto",
      fontSize: "14px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.19,
      letterSpacing: "normal",
      color: "#545454",
    },
    boatPrice: {
      fontFamily: "Roboto",
      fontSize: "24px",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "normal",
      color: "#000000",
    },
    boatPriceWithoutMap: {
      fontFamily: "Roboto",
      fontSize: "16px",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1,
      letterSpacing: "normal",
      color: "#000000",
    },
    incompleteStatus: {
      fontFamily: "Montserrat",
      fontSize: "12px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "1px",
      color: "#e9724c",
    },
    completeStatus: {
      fontFamily: "Montserrat",
      fontSize: "12px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "1px",
      color: "#00a676",
    },
    listPrimaryText: {
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "normal",
      color: "#000000",
    },
    listSecondaryText: {
      fontFamily: "Montserrat",
      fontSize: "12px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "normal",
      color: "#343a40",
    },
    swtichLable: {
      fontFamily: "Montserrat",
      fontSize: "10px",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "normal",
      color: "#343a40",
    },
    mapContainer: {
      height: "100%",
      width: "100%",
      position: "absolute",
      top: 0,
      left: 0,
    },
    map_parent_cont: {
      width: "100%",
      display: "flex",
      minHeight: "calc(100vh - 200px)",
      margin: "auto",
      position: "relative",
      justifyContent: "space-between",
      alignItems: "center",
    },
    date: {
      color: "transparent",
    },
    passengerButtons: {
      height: "3rem",
      minWidth: "3rem",
      margin: "10px",
      borderRadius: "50%",
      border: "solid 2px #0174cf",
    },
    input: {
      padding: "6px 0 12px!important",
    },
    comparebutton: {
      position: "absolute",
      bottom: "2%",
      right: "5%",
      zIndex: 1,
    },
  })
)(BoatSearchResultToast);
