import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import StorageProvider from "../../../framework/src/StorageProvider.web";

import { withAlertBoxProps } from "../../../components/src/withAlertBox.Web";
import { withToastProps } from "../../../components/src/withSnackBar.Web";
import { withLoaderProps } from "../../../components/src/withLoader.Web";
import { DialogProps } from "../../../components/src/DialogContext";

export const configJSON = require("./config");

export type Props = RouterProps &
DialogProps &
  withAlertBoxProps &
  withToastProps &
  withLoaderProps & {
    id: string;
    // Customizable Area Start
    // Customizable Area End
  };

interface S {
  // Customizable Area Start
  authToken : String,
  otp : string
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class VerifyOTPController extends BlockComponent<
  Props,
  S,
  SS
> {
  verifyOTPApiCallId : String = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
      // Customizable Area End
    ];

    this.state = {
      authToken : '',
      otp : ''
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

    async receive(from: string, message: Message) {
      runEngine.debugLog("Message Recived", message);
      if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
        const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );

        var responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        if (apiRequestCallId === this.verifyOTPApiCallId) {
          this.props.hideLoader();
          if (responseJson && responseJson?.data && responseJson?.data.id) {
                
               this.props.onSubmit(responseJson.data.id);
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        } 
      } else if (getName(MessageEnum.AlertMessage) === message.id) {
        const title = message.getData(getName(MessageEnum.AlertTitleMessage));
        var AlertBodyMessage = message.getData(
          getName(MessageEnum.AlertBodyMessage)
        );
        const msg = await this.props.showAlert({title : 'Error',message : AlertBodyMessage})
      if(msg === 'Your session has expired, please login again.' || msg === 'Invalid token'){
        await StorageProvider.remove("authToken")
        await StorageProvider.remove("accountInfo")  
        this.props.history.push("/basicAuth/login")
      }
      }
    }
    verifyOTP = (otp:any) => {
      this.props.showLoader();
      const headers = {
        "content-type": configJSON.ApiContentType,
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.verifyOTPApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `accounts/verify_phone_number`
      );
      const httpBody =  {
          "pin": otp,
          "token": this.props.dataToPass.token
       }
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(httpBody)
        );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.methodPOST
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    
  }
