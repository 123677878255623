import React from "react";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import { upload } from "./assets";
export type UploadBoxProps = {
    onSelectFile : (Files : FileList) => {},
    onTouched : () => {},
    multiple : boolean,
    classes : any,
    uploadMsg : String,
    acceptedFormat : string,
    uploadimg? : any
}
class UploadBox extends React.Component<UploadBoxProps> {
  fileInputRef = React.createRef<HTMLInputElement>();
  dragOver(e: { preventDefault: () => void }) {
    e.preventDefault();
  }

  dragEnter(e: { preventDefault: () => void }) {
    e.preventDefault();
  }

  dragLeave(e: { preventDefault: () => void }) {
    e.preventDefault();
  }

  fileDrop(e: { preventDefault: () => void; dataTransfer: { files: any } }) {
    e.preventDefault();
    const files = e.dataTransfer.files;
    this.handleFiles(files);
  }


  fileInputClicked() {
    this.props.onTouched();
    this.fileInputRef?.current?.click();
  }

  filesSelected() {
    if (this.fileInputRef?.current?.files?.length) {
        this.handleFiles(this.fileInputRef?.current?.files);
    }
  }

  handleFiles(files: FileList){
    this.props.onSelectFile(files);
  }
  
  render() {
    const {classes} = this.props;
    return (
      <>
        <div
          className={classes.DropContainer}
          onClick={this.fileInputClicked.bind(this)}
        >
          <div className={classes.dropMessage}>
            <div className={classes.uploadIcon} ></div>
            <div className={classes.marginTop}> {this.props.uploadMsg ? this.props.uploadMsg : ''}</div>
          </div>
        </div>
        <input
          ref={this.fileInputRef}
          className={classes.fileInput}
          type="file"
          accept={this.props.acceptedFormat}
          multiple={this.props.multiple}
          onChange={this.filesSelected.bind(this)}
        />
      </>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    DropContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      margin: "auto",
      width: "100%",
      height: "150px",
      border:"dashed 1px #343a40",
      flexDirection:"column",
      borderRadius:'6px',
      padding:'20px 10px'
    },
    uploadIcon: {
      width: '25px',
      height: '30px',
      objectFit: 'contain',
      background: `url(${upload}) no-repeat center center`,
      backgroundSize: "100%",
      textAlign: "center",
      margin: "0 auto",
      paddingTop: "30px",
    },
    dropMessage: {
      fontSize: '16px',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.25,
      letterSpacing: 'normal',
      textAlign: 'center',
      color: "#343a40",
      fontFamily: "Montserrat",
      marginTop : '16px'
    },
    marginTop : {
      marginTop : theme.spacing(2)
    },
    fileInput : {
        display: 'none'
    } 
  });
  export default withStyles(styles, { withTheme: true })(UploadBox);