//@ts-nocheck
import React from "react";
import BookingDetailPopupController, { Props } from "./BookingDetailPopupController.web";
import {
  withStyles,
  Grid,
  Typography,
  createStyles,
  Button,
  Paper,
  Link,
  Box,
  Container,
  TextField,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  List,
  Avatar,
  Divider
} from "@material-ui/core";
import withDialog from "../../../components/src/withDialog.web";
import ErrorIcon from "@material-ui/icons/Error";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import moment from "moment";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { boatImage } from "./assets";
import { withRouter } from "react-router";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { boatImageDemo1, boatImageDemo2 } from "./assets";
import Rating from "@material-ui/lab/Rating";
import RejectBoatRequest from "./RejectBoatRequest.web";
import ViewCustomerDocument from "./ViewCustomerDocument.web";
class BookingDetailPopup extends BookingDetailPopupController {
  constructor(props: Props) {
    super(props);
  }
  goToSelectedRoute = (routeName) => {
    const { history } = this.props;
    history.push(routeName);
  };
  closeDialog = (data) => {
    const { onSubmit } = this.props;
    onSubmit(data);
  };
  async componentDidMount() {
    const authToken = await StorageProvider.get("authToken");
    const accountInfo = await StorageProvider.get("accountInfo");
    if (authToken && accountInfo) {
      this.setState({
        senderId: JSON.parse(accountInfo).id,
        authToken: authToken,
      });
    }
  }
  openRejectRequest = (data) => {
    this.props
      .openDialogBox({
        title: "Reject Request",
        withCustomDialog: false,
        renderedComponent: RejectBoatRequest,
        dataToPass: {bookingDetail : data},
        catchOnCancel: true,
        width : '500px'
      })
      .then((data) => {
        if (data) {
          // this.props.showToast({
          //   type: "success",
          //   message: "Message sent successfully",
          // });
          this.props.onSubmit("a")
        }
      });
  }
  viewDocumnet = (doc) => {
    // this.download(doc[0].document.url,"aa.png")  
    this.props
    .openDialogBox({
      title: "View Documnets",
      withCustomDialog: false,
      renderedComponent: ViewCustomerDocument,
      dataToPass: {Documents : doc},
      catchOnCancel: true,
    })
    .then((data) => {
      if(data){
        // this.props.showToast({
        //   type: "success",
        //   message: "Boat request approved successfully",
        // });
        
      }
    });
    // window.open(doc[0].document.url, '_blank').focus();
  }
  openApproveRequest = (data) => {
    this.props
    .openDialogBox({
      title: "Booking Details",
      withCustomDialog: false,
      renderedComponent: BookingDetailPopup,
      dataToPass: {bookingDetail : data,isApproved : true,isRenterView : false},
      catchOnCancel: true,
    })
    .then((data) => {
      if(data){
        this.props.showToast({
          type: "success",
          message: "Boat request approved successfully",
        });
        
      }
    });
  }
  
  getHours = (durationFilter) => {
    if(durationFilter){
      if(durationFilter === 'half_day'){
        return "0-4 Hours "
      } else if(durationFilter === 'full_day'){
        return "8 Hours "
      } else if(durationFilter === 'twentyfour_hours'){
        return "24 Hours "
      } else if(durationFilter === 'three_days'){
        return "3 Days "
      }
      else if(durationFilter === 'seven_days'){
        return "7 Days "
      }
    } else {
      return "0-4 Hours "
    }
  }
  render() {
    const { classes } = this.props;
    return (
              <Grid container justify="space-between" spacing={2} className={classes.mainContainer}>
                <Grid item xs={12} md={6}>
                  <Grid container justify="center" spacing={4}>
                    <Grid item xs={12}>
                      <Grid container  justify="space-between">
                        <Grid xs={10} item>
                          <Typography>Booking for:</Typography>
                        </Grid>
                        <Grid item xs={10}>
                          <Typography gutterBottom className={classes.boatName}>
                            {`${this.props?.dataToPass?.bookingDetail?.attributes?.boat?.attributes?.name}`}
                          </Typography>
                          <Typography
                            gutterBottom
                            className={classes.boatAddress}
                          >
                            {`${this.props?.dataToPass?.bookingDetail?.attributes?.boat?.attributes?.address?.address1},${this.props?.dataToPass?.bookingDetail?.attributes?.boat?.attributes?.address?.city}`}
                          </Typography>
                        </Grid>
                        <Grid
                          className={classes.hotelMainImage}
                          style={{
                            backgroundImage: `url(${
                              this.props?.dataToPass?.bookingDetail?.attributes?.boat?.attributes?.thumbnail_image?.url || boatImageDemo1
                            })`,
                          }}
                          xs={8}
                          item
                        />
                        <Grid item xs={10}>
                            <Box mt={5}>
                            <Typography className={classes.paymentHeading}>Payment Details</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={10}>
                        <List className={classes.paymentDetais} >
                            <ListItem dense={true} disableGutters>
                              <ListItemText primary="Deposit Amount" />
                              <ListItemSecondaryAction>
                                $ {this.props?.dataToPass?.bookingDetail?.attributes?.security_deposite}
                              </ListItemSecondaryAction>
                            </ListItem>
                            <ListItem dense={true} disableGutters>
                              <ListItemText primary="Boat Price" />
                              <ListItemSecondaryAction>
                                $ {this.props?.dataToPass?.bookingDetail?.attributes?.boat_price}
                              </ListItemSecondaryAction>
                            </ListItem>
                            <ListItem dense={true} disableGutters>
                              <ListItemText primary="Service Fee" />
                              <ListItemSecondaryAction>
                                $ {this.props?.dataToPass?.bookingDetail?.attributes?.service_fee}
                              </ListItemSecondaryAction>
                            </ListItem>
                            <ListItem dense={true} disableGutters>
                              <ListItemText primary="Sales tax" />
                              <ListItemSecondaryAction>
                                $ {this.props?.dataToPass?.bookingDetail?.attributes?.sales_tax}
                              </ListItemSecondaryAction>
                            </ListItem>
                            {this.props?.dataToPass?.bookingDetail?.attributes?.promocode_id && <ListItem dense={true} disableGutters>
                              <ListItemText primary="Coupon code" />
                              <ListItemSecondaryAction>
                                <Typography color="error">$ -{this.props?.dataToPass?.bookingDetail?.attributes?.promocode_amount}</Typography>
                              </ListItemSecondaryAction>
                            </ListItem>}
                            <Divider variant="middle" />
                            <ListItem dense={true} disableGutters >
                              <ListItemText primary="Total Paid" />
                              <ListItemSecondaryAction >
                                $ {this.props?.dataToPass?.bookingDetail?.attributes?.total_amount}
                              </ListItemSecondaryAction>
                            </ListItem>
                          </List>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Grid container justify="center" spacing={4}>
                    <Grid item xs={12}>
                       <Grid container justify="space-between">
                          <Grid item >
                            <Box >
                            <Typography className={classes.detailTitle}>Check-in Date</Typography>
                             <Typography className={classes.detailsText}>{moment(new Date(this.props?.dataToPass?.bookingDetail?.attributes?.trip_date).getTime()).format("DD/MM/YYYY")}</Typography>
                             <Typography className={classes.detailsText}>{moment(new Date(this.props?.dataToPass?.bookingDetail?.attributes?.trip_date).getTime()).format("hh:mm")}</Typography>
                            </Box>
                             
                          </Grid>
                          <Grid item >
                         <Box>
                         <Typography className={classes.detailTitle}>{(this.props?.dataToPass?.bookingDetail?.attributes?.passengers)} Passengers</Typography>
                          <Typography className={classes.detailsText}>{this.getHours(this.props?.dataToPass?.bookingDetail?.attributes?.trip_duration)} </Typography>
                          <Typography className={classes.detailsText}>{(this.props?.dataToPass?.bookingDetail?.attributes?.captain_included) ? 'Captain Included' : 'No Captain Included'}   </Typography>
                         </Box>
                          </Grid> 
                       </Grid>
                    </Grid>
                    <Grid item xs={12}>
                   <Box>
                   <Typography className={classes.detailsText}>{this.props.dataToPass?.isRenterView ? 'Owned by' : 'Rented by'}</Typography>
                      <ListItem disableGutters>
                         <Box>
                         <ListItemAvatar>
                            <Avatar src={this.props?.dataToPass?.bookingDetail?.attributes?.boat?.attributes?.account?.avatar.url ? this.props?.dataToPass?.bookingDetail?.attributes?.boat?.attributes?.account?.avatar.url : boatImage}></Avatar>
                         </ListItemAvatar>
                         </Box>
                         <Box>
                         <ListItemText className={classes.ownerName} primary={`${this.props?.dataToPass?.bookingDetail?.attributes?.boat?.attributes?.account?.first_name} ${this.props?.dataToPass?.bookingDetail?.attributes?.boat?.attributes?.account?.last_name}`} secondary={this.props.dataToPass?.isRenterView ? 'Call Renter': 'Call renter' }>
                         </ListItemText>
                         </Box>
                      </ListItem>
                   </Box>
                    </Grid>
                    <Grid item xs={12}>
                       <Grid container justify="space-between">
                        
                          <Grid item xs={8}>
                             <Box>
                             <Typography className={classes.detailsText} gutterBottom>Auto-approval Status</Typography>
                               </Box>
                              <Typography component="span" className={this.props?.dataToPass?.bookingDetail?.attributes?.approval_status === 'approved' ? classes.successButton : classes.failedButton} >
                              {this.props?.dataToPass?.bookingDetail?.attributes?.approval_status ? this.props?.dataToPass?.bookingDetail?.attributes?.approval_status : "pending"}
                              </Typography>
                          </Grid>
                        
                          
                          <Grid container item xs={4} justify="center"  className={classes.documentBox}>
                          
                          <Box px={1}><CloudDownloadIcon color="primary"/></Box>
                               <Box px={1}>
                                 <Typography variant onClick={()=>{
                                   this.viewDocumnet(this.props?.dataToPass?.bookingDetail?.attributes?.renter_doc);
                                 }}>
                                     View All
                                     Document
                                   </Typography>
                                 </Box>   

                          
                          </Grid>
                        
                       </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={classes.detailsText} gutterBottom>Message for Owner</Typography>
                        <Typography>
                              {this.props?.dataToPass?.bookingDetail?.attributes?.message}
                        </Typography>
                    </Grid>
                   {this.props.dataToPass?.isApproved && <Grid item xs={12}>
                      <Grid container  justify="center">
                        <Grid item >
                         <Box px={1} pb={1}>
                         <Button
                            onClick={() => {
                              // this.props.onCancel();
                              this.openRejectRequest(this.props?.dataToPass?.bookingDetail)
                            }}
                            variant="outlined"
                            color="primary"
                            className={classes.rejectButton}
                          >
                             Reject
                          </Button>
                         </Box>
                        </Grid>
                        <Grid item >
                          <Box px={1}>
                          <Button
                            variant="contained"
                            type="submit"
                            color="primary"
                            onClick={()=>this.AcceptRenterRequest(this.props?.dataToPass?.bookingDetail?.id)}
                            className={classes.approveButton}
                          >
                            Approve
                          </Button>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                   } 
                   </Grid>
                </Grid>
              </Grid>
    );
  }
}
const BookingDetailPopupWithRouter = withRouter(BookingDetailPopup);
const BookingDetailPopupWithToast = withToast(BookingDetailPopupWithRouter);
const BookingDetailPopupWithLoader = withLoader(BookingDetailPopupWithToast);
const BookingDetailPopupWithAlertBox = withAlertBox(BookingDetailPopupWithLoader);

export default withStyles((theme) =>
  createStyles({
    hotelMainImage: {
      position: "relative",
      backgroundImage: `url(${boatImageDemo1})`,
      backgroundRepeat: "no-repeat",
      backgroundColor:
        theme.palette.type === "light"
          ? theme.palette.grey[50]
          : theme.palette.grey[900],
      backgroundSize: "cover",
      backgroundPosition: "center",
      height: theme.spacing(15),
      [theme.breakpoints.down("xs")]: {
        height: theme.spacing(10),
      },
      borderRadius: theme.spacing(1),
    },
    boatName: {
      fontFamily: "Montserrat",
      fontSize: "1.5rem",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "normal",
      marginBottom:"23px",
      color: "#004277",
    },
    boatAddress: {
      fontFamily: "Montserrat",
      marginBottom:"22px",
      fontSize: "1rem",
      color: "#343a40",
    },
    detailTitle: {
      fontFamily: "Montserrat",
      fontSize: "1rem",
      color: "#343a40",
    },
    mainContainer:{
      marginTop:"0.625rem",
      paddingTop:"25px",
      border:"1px #b8b8b8",
      borderStyle:"solid none none none"
    },
    ownerName:{
      fontSize:"1rem",
      fontFamily: "Montserrat",
      color:"#343a40"
      },
      detailsText:{
        fontSize:"1rem",
        fontWeight:600,
        fontFamily: "Montserrat",
        color:"#343a40"
      },
      rejectButton:{
        borderRadius:"5px",
        borderWidth:"2px",
        fontFamily:"Montserrat"
      },
      approveButton:{
        borderRadius:"5px",
        borderWidth:"2px",
        fontFamily:"Montserrat"
      },
      failedButton:{
        color:"#c5283d",
        borderRadius:"5px",
        padding:"3px 8px",
        fontFamily:"Montserrat",
        border:"solid 2px #c5283d",
      },
      successButton:{
        color:"green",
        borderRadius:"5px",
        padding:"3px 8px",
        fontFamily:"Montserrat",
        border:"solid 2px #c5283d",
      },
      documentBox:{
        fontSize:"1rem",
        color:"#0174cf",
        alignContent:"center",
        textAlign:"center"
      },
      paymentHeading:{
        fontSize:"1.25rem",
        fontWeight:600,
        color:"#004277"
      },
      paymentDetais:{
        fontSize:"1rem",
        color: "#545454"
      }
    
  })
)(withDialog(BookingDetailPopupWithAlertBox));