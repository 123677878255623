import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { dialogBoxProps  } from "../../../components/src/withDialog.web";
import { withAlertBoxProps } from "../../../components/src/withAlertBox.Web";
import { withToastProps } from "../../../components/src/withSnackBar.Web";
import { withLoaderProps } from "../../../components/src/withLoader.Web";
export const configJSON = require("./config");
import moment from "moment";
export type Props = RouterProps & dialogBoxProps & withAlertBoxProps & withToastProps & withLoaderProps & {
  id: string;
  // Customizable Area Start
  // Customizable Area End
};

interface S {
  // Customizable Area Start
  authToken : String,
  passengersAnchorEl : any;
  durationAnchorEl : any;
  isOpenDatePicker : boolean,
  captionAnchorEl : any,
  isOpenTimePicker : boolean,
  boatDetails : any;
  boatId : string;
  accountId : string;
  userDocuments : Array<any>;
  selectedBoatPrice : any
  duration : any
  bookingValue : any
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class BookBoatController extends BlockComponent<
  Props,
  S,
  SS
> {
  getBoatDetailApiCallId : String = "";
  createBoatBookingApiCallId : string = "";
  getDocumentApiCallId : String = "";
  check_booking_availibilityApiCallId : String = ""
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
      // Customizable Area End
    ];

    this.state = {
      authToken : '',
      passengersAnchorEl : null,
      durationAnchorEl : null,
      captionAnchorEl : null,
      isOpenDatePicker : false,
      isOpenTimePicker : false,
      boatDetails : {},
      boatId : "",
      accountId : "",
      userDocuments : [],
      selectedBoatPrice : "",
      duration : "",
      bookingValue : null
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.getBoatDetailApiCallId) {
        this.props.hideLoader();
        if (responseJson && responseJson.data && responseJson.data.id) {
            this.setState({
              boatDetails : responseJson.data?.attributes
            },()=>{
              if(this.state.boatDetails.owner_approval_status === 'automatic'){
                  this.getAllDocument();
              }
            })
        } else {
          this.parseApiErrorResponse(responseJson);
          // this.setState({loading:  false})
        }
      } else if (apiRequestCallId === this.createBoatBookingApiCallId) {
        this.props.hideLoader();
        if (responseJson && responseJson.data && responseJson.data.id) {
            this.props.history.replace({pathname:"/bookBoatPayment",state:responseJson.data})
        } else {
          this.parseApiErrorResponse(responseJson);
          // this.setState({loading:  false})
        }
      } else if(apiRequestCallId === this.getDocumentApiCallId){
        this.props.hideLoader();
        if (responseJson && responseJson.data) {
            this.setState({
              userDocuments : responseJson.data.map((doc : any)=>{return doc.attributes?.type || 'boating_license'})
            })
        } else {
          this.parseApiErrorResponse(responseJson);
          // this.setState({loading:  false})
        }
      } else if(this.check_booking_availibilityApiCallId === apiRequestCallId){
        this.props.hideLoader();
        if (responseJson && responseJson.data) {
          this.state.bookingValue && this.createBooking(this.state.bookingValue)
            // this.setState({
            //   userDocuments : responseJson.data.map((doc : any)=>{return doc.attributes?.type || 'boating_license'})
            // })
        } else {
          this.parseApiErrorResponse(responseJson);
          // this.setState({loading:  false})
        }
      }
    } else if (getName(MessageEnum.AlertMessage) === message.id) {
      const title = message.getData(getName(MessageEnum.AlertTitleMessage));
      var AlertBodyMessage = message.getData(
        getName(MessageEnum.AlertBodyMessage)
      );
      const msg = await this.props.showAlert({title : 'Error',message : AlertBodyMessage})
      if(msg === 'Your session has expired, please login again.' || msg === 'Invalid token'){
        await StorageProvider.remove("authToken")
        await StorageProvider.remove("accountInfo")  
        this.props.history.push("/basicAuth/login")
      }
    }
  }
   
  getBoatDetail() {
    this.props.showLoader();
    const headers = {
      "content-type": configJSON.ApiContentType,
      token : this.state.authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getBoatDetailApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getBoatDetailPoint}/${this.state.boatId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  checkBookingAvailibility() {
    this.props.showLoader();
    const headers = {
      "content-type": configJSON.ApiContentType,
      token : this.state.authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.check_booking_availibilityApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_reservations/bookings/${this.state.boatId}/check_booking_availibility`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  
  createBooking = (values : any) => {
    this.props.showLoader();
    const headers = {
      "content-type": configJSON.ApiContentType,
      token : this.state.authToken
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const tripTime = values?.tripTime?.time ? new Date(values.tripTime.start_time).getHours() : new Date(values.tripTime).getHours();
    const httpBody = {
      "booking": {
          "boat_id": this.state.boatId,
          "boat_owner_id": this.state.boatDetails?.account.id,
          "account_id": this.state.accountId,
          "trip_date": moment(values.tripDate).format("DD-MM-YYYY"),
          "start_time": moment(values.tripDate).hour(tripTime).minute(0).format("DD-MM-YYYY HH:mm:ss"),
          "captain_included": values.captionIncluded === 'Captain' ? true : false,
          "passengers": values.noOfPassenger,
          "trip_duration" : values.tripDuration.value
      }
    }
    this.createBoatBookingApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.bookingEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPOST
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getAllDocument = () => {
    this.props.showLoader();
    const headers = {
      token : this.state.authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getDocumentApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.documentEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
}
