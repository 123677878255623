import React from "react";
import { withRouter, Link } from "react-router-dom";
import Navbar from 'react-bootstrap/Navbar';
// import './css/Topbar.css';
// import logo from '../assets/logo.png';

const TopNav = ({ history }) => {
  return (
   <>
    <Navbar expand="sm" variant="light" className="navbar-div">
      <Link className="navbar-brand" to={'/'}>
        <img src={logo} alt="boaturu" />
      </Link>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav" className="navbar-collapse-div">
        <ul className="navbar-nav ml-auto">
          <li className="nav-item">
            <Link className="nav-link" to={'/EmailAccountLoginBlock'}>Login</Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to={'/EmailAccountRegistration'}>Signup</Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to={'/'}>How it works</Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to={'/'}>Support</Link> 
          </li>
        </ul>
      </Navbar.Collapse>
    </Navbar>

   </>
  );
};



export default withRouter(TopNav);
