import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { dialogBoxProps  } from "../../../components/src/withDialog.web";
import { withAlertBoxProps } from "../../../components/src/withAlertBox.Web";
import { withToastProps } from "../../../components/src/withSnackBar.Web";
import { withLoaderProps } from "../../../components/src/withLoader.Web";
export const configJSON = require("./config");

export type Props = RouterProps & dialogBoxProps & withAlertBoxProps & withToastProps & withLoaderProps & {
  id: string;
  // Customizable Area Start
  // Customizable Area End
};

interface S {
  // Customizable Area Start
  lat : any;
  lng : any;
  authToken : String
  boatId : String,
  isSaveAndExit : boolean
  loading : boolean
  boatAddressDetail: any;
  

  // Customizable Area End
}

interface SS {
  id: any;
}

export default class BoatLocationOnMapController extends BlockComponent<
  Props,
  S,
  SS
> {
  updateBoatLocationDetailApiId: String = "";
  getBoatDetailApiCallId : String = ""
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
      // Customizable Area End
    ];

    this.state = {
        lat : null,
        lng : null,
        authToken : "",
        boatId : "",
        isSaveAndExit : false,
        loading : true,
        boatAddressDetail: {
          zipcode: "",
          location: "",
          address: "",
          addressline2: "",
          city: "",
          state: "",
          locationType: "personalLocation",
        }
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.getBoatDetailApiCallId) {
        this.props.hideLoader();
        if (responseJson && responseJson.data && responseJson.data.id) {
          if(responseJson?.data?.attributes?.address){
            this.setState({ 
              boatAddressDetail : {
                zipcode: responseJson?.data?.attributes?.address?.zipcode,
                address: responseJson?.data?.attributes?.address?.address1,
                addressline2: responseJson?.data?.attributes?.address?.address2,
                city: responseJson?.data?.attributes?.address?.city,
                state: responseJson?.data?.attributes?.address?.state,
              }
            },async ()=>{
              if(responseJson?.data?.attributes?.address && responseJson?.data?.attributes?.address?.latitude && responseJson?.data?.attributes?.address?.longitude){
                this.setState({
                  lat :responseJson?.data?.attributes?.address?.latitude,
                  lng : responseJson?.data?.attributes?.address?.longitude
                })
              } else {
                let location : any = await this.getPreciseLocation();
                this.setState({
                  lat: location?.lat,
                  lng: location?.lng,
                });
              }
            })    
          }
        } else {
          this.parseApiErrorResponse(responseJson);
          // this.setState({loading:  false})
        }
      } else if (apiRequestCallId === this.updateBoatLocationDetailApiId) {
        this.props.hideLoader();
        if (responseJson && responseJson?.data && responseJson?.data?.id) {
           this.props.showToast({type : 'success',message : 'Boat Location Updated successfully'})
           const { history } = this.props;
           if(this.state.isSaveAndExit){
            await StorageProvider.remove("boatId")  
            history.push('/boatListingLandingPage');
          } else {
            history.push('/boatHandsFreeExprience');
          }
        } else {
          this.parseApiErrorResponse(responseJson);
          // this.setState({loading:  false})
        }
      }
    } else if (getName(MessageEnum.AlertMessage) === message.id) {
      const title = message.getData(getName(MessageEnum.AlertTitleMessage));
      var AlertBodyMessage = message.getData(
        getName(MessageEnum.AlertBodyMessage)
      );
      const msg = await this.props.showAlert({title : 'Error',message : AlertBodyMessage})
      if(msg === 'Your session has expired, please login again.' || msg === 'Invalid token'){
        await StorageProvider.remove("authToken")
        await StorageProvider.remove("accountInfo")  
        this.props.history.push("/basicAuth/login")
      }
    }
  }
  getPreciseLocation = () => {
    return new Promise(function(resolve, reject) {
      navigator.geolocation.getCurrentPosition(
        function(position) {
          resolve({lat : position.coords.latitude,lng : position.coords.longitude});
        },
        (error) => {
          console.log(error);
          reject("Geolocation is not supported by your browser");
        },
        { maximumAge: 10000, timeout: 50000, enableHighAccuracy: true }
      );
    });
  }
   
  getBoatDetail() {
    this.props.showLoader();
    const headers = {
      "content-type": configJSON.ApiContentType,
      token : this.state.authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getBoatDetailApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getBoatDetailPoint}/${this.state.boatId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  updateBoatLocation(values: any) {
    this.props.showLoader();
    const headers = {
      "content-type": configJSON.ApiContentType,
      token : this.state.authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    this.updateBoatLocationDetailApiId = requestMessage.messageId;
    
    const httpBody = {
      "address_attributes": {
        "zipcode": this.state.boatAddressDetail.zipcode,
        "address1": this.state.boatAddressDetail.address,
        "address2": this.state.boatAddressDetail.addressline2,
        "city": this.state.boatAddressDetail.city,
        "state": this.state.boatAddressDetail.state,
        "latitude": this.state.lat,
        "longitude": this.state.lng
     },
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      this.state.boatId ? `${configJSON.createBoatEndPoint}/${this.state.boatId}` : `${configJSON.createBoatEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      this.state.boatId ? configJSON.methodPUT : configJSON.methodPOST
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
}
