//@ts-nocheck
import React from "react";
import OwnerBookingsController, {
  Props,
} from "./OwnerBookingsController.web";

import { withStyles, createStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { Grid, Avatar, Container } from "@material-ui/core/";
import { boatImage } from "./assets";
import { boatImage1 } from "./assets";
import DeleteIcon from "@material-ui/icons/Delete";
import Button from "@material-ui/core/Button";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
import withDialog from "../../../components/src/withDialog.web";
import { withRouter } from "react-router";
import BookingDetailPopup from "./BookingDetailPopup.web";
import RejectBoatRequest from "./RejectBoatRequest.web";
import moment from "moment";
import OwnerBookingComplain from "./OwnerBookingCompain.web";
import CheckInPopup from "./CheckInPopup.web";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}



const TabPanel=(props: TabPanelProps)=> {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const a11yProps=(index: any)=> {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
class OwnerBookings extends OwnerBookingsController {
  constructor(props: Props) {
    super(props);
  }
  handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({ selectedBookingType: newValue });
  };
  async componentDidMount() {
    const authToken = await StorageProvider.get("authToken");
    if (authToken) {
      this.setState({
        authToken: authToken,
      },()=>{
        this.getOwnerApprovedBooking();
        this.getOwnerPastBooking();
        this.getOwnerUpcomingtBooking();
      });
    }
  }
  openBookingDetailsDialog = (data) => {
    this.props
      .openDialogBox({
        title: "Booking Details",
        withCustomDialog: false,
        renderedComponent: BookingDetailPopup,
        dataToPass: {bookingDetail : data,isApproved : false,isRenterView : false},
        catchOnCancel: true,
      })
      .then((data) => {
        
      });
  };
  openRejectRequest = (data) => {
    this.props
      .openDialogBox({
        title: "Reject Request",
        withCustomDialog: false,
        renderedComponent: RejectBoatRequest,
        dataToPass: {bookingDetail : data},
        catchOnCancel: true,
        width : '500px'
      })
      .then((data) => {
        if (data) {
          this.props.showToast({
            type: "success",
            message: "Boat request rejected successfully",
          });
          this.getOwnerApprovedBooking();
        this.getOwnerPastBooking();
        this.getOwnerUpcomingtBooking();
        }
      });
  }
  openApproveRequest = (data) => {
    this.props
    .openDialogBox({
      title: "Booking Details",
      withCustomDialog: false,
      renderedComponent: BookingDetailPopup,
      dataToPass: {bookingDetail : data,isApproved : true,isRenterView : false},
      catchOnCancel: true,
    })
    .then((data) => {
      if(data){
        this.props.showToast({
          type: "success",
          message: "Boat request approved successfully",
        });
        this.getOwnerApprovedBooking();
        this.getOwnerPastBooking();
        this.getOwnerUpcomingtBooking();
      }
    });
  }
  openComplain = (data) => {
    this.props.openDialogBox({
      title : `We're sorry you didn't enjoy our experience`,
      withCustomDialog : false,
      renderedComponent : OwnerBookingComplain,
      dataToPass : {bookingId : data.id },
      catchOnCancel : true
    }).
    then((data : any)=>{
      if(data){
        this.props.showToast({
          type : 'success',
          message : 'Complain submitted successfully'
        })
      }
    })
  }
  openCheckInDialog = (data) => {
    this.props
      .openDialogBox({
        title: "Check-In",
        withCustomDialog: false,
        renderedComponent: CheckInPopup,
        dataToPass: { bookingDetail: data },
        catchOnCancel: true,
      })
      .then((id) => {
        if (id) {
          this.props.showToast({
            type: "success",
            message: "Checked In Succssfully",
          });
          this.getOwnerApprovedBooking();
        this.getOwnerPastBooking();
        this.getOwnerUpcomingtBooking();
        }
      });
  };
  render() {
    const { classes } = this.props;
    const  approvedRequest = this.state.ownerCurrentBookings || []
    const pendIngRequest = this.state.ownerUpcomingBookings || []
    const approvedOrRejectRequest = this.state.ownerPastBookings || []
    return (
      <Container maxWidth="lg">
        <Box pt={5} pb={2}>
          <Typography variant="h4">My Bookings</Typography>
        </Box>
        <Grid container>
          <Grid item xs={12} md={10}>
            <AppBar position="static" className={classes.removeBoxShadow}>
              <Tabs
                value={this.state.selectedBookingType}
                onChange={this.handleChange}
                aria-label="simple tabs example"
                className={classes.tabPanelcolor}
              >
                <Tab label="READY TO APPROVE" {...a11yProps(0)} />
                <Tab label="APPROVED" {...a11yProps(1)} />
                <Tab label="PAST" {...a11yProps(2)} />
              </Tabs>
            </AppBar>
            <TabPanel
              value={this.state.selectedBookingType}
              index={0}
              className={classes.removePadding}
            >
              {pendIngRequest.length > 0 ? pendIngRequest.map((data) => (
                <Box className={classes.box} py={3} key={data.id}>
                  <Grid container spacing={3} pl={0}>
                    <Grid item xs={12} md={5}>
                      <img
                        src={data?.attributes?.boat?.attributes?.thumbnail_image?.url || boatImage}
                        width="100%"
                        className={classes.boatImage}
                      />
                    </Grid>
                    <Grid container item xs={12} md={7}>
                      <Box pb={3}>
                        <Typography
                          variant="h5"
                          className={classes.blueHeading}
                        >
                          {data?.attributes?.boat?.attributes?.name}
                        </Typography>
                        <Typography variant="h7">{`${data?.attributes?.boat?.attributes?.address?.address1},${data?.attributes?.boat?.attributes?.address?.city}`}</Typography>
                      </Box>

                      <Grid container item>
                        <Grid item xs={8}>
                          <Typography
                            variant="h7"
                            className={classes.detailName}
                          >
                            Rented By
                          </Typography>
                          <Box>
                            <Box display="inline-block" pt={1}>
                              <Avatar
                                component="span"
                                className={classes.avatar}
                              />
                            </Box>
                            <Box
                              display="inline-block"
                              
                              className={classes.textCentre}
                            >
                              <Typography variant="h7" component="div">
                                {data?.attributes?.account?.user_name || "-"}
                              </Typography>
                              <Typography
                                variant="h7"
                                component="div"
                                className={classes.blueDetails}
                              >
                                Call Renter
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>

                        <Grid item xs={4}>
                          <Box display="flex" justifyContent="space-around">
                            <Box />
                            <Box>
                              <Typography
                                variant="h6"
                                className={classes.detailName}
                              >
                                Trip date
                              </Typography>
                              <Typography
                                variant="h7"
                                component="div"
                                className={classes.timeDetails}
                              >
                                {moment(new Date(data?.attributes?.trip_date).getTime()).format("DD/MM/YYYY")}
                              </Typography>
                              <Typography
                                variant="h7"
                                component="div"
                                className={classes.timeDetails}
                              >
                                {moment(new Date(data?.attributes?.start_time).getTime()).format("hh:mm")}
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                      <Grid container item>
                        <Grid item xs={12}>
                          <Box
                            display="flex"
                            justifyContent="flex-end"
                            
                          >
                            <Button color="primary" onClick={() => {this.openBookingDetailsDialog(data)}}>Booking Details</Button>

                            {/* <Button
                              
                              startIcon={
                                <DeleteIcon className={classes.trashbin} />
                              }
                              className={classes.deleteButton}
                            >
                              Delete
                            </Button> */}
                          </Box>
                        </Grid>
                      </Grid>
                      <Grid container item>
                        <Grid item xs={4}>
                          <Box>
                            <Typography
                              variant="h7"
                              className={classes.paidStatus}
                            >
                              Paid in advance
                            </Typography>
                            <Typography variant="h4" className={classes.amount}>
                              {data?.attributes?.total_amount || "-"}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid
                          container
                          item
                          xs={8}
                          spacing={2}
                          justify="flex-end"
                          alignContent="center"
                        >
                          <Grid item>
                            <Button
                              color="primary"
                              variant="outlined"
                              onClick={()=>this.openRejectRequest(data)}
                              className={classes.roundButtons}
                            >
                              Reject
                            </Button>
                          </Grid>
                          <Grid item>
                            
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={()=>this.openApproveRequest(data)}
                              className={`${classes.approveButton} ${
                                classes.roundButtons
                              }`}
                            >
                              Approve
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              )) : <Grid container justify="center" spacing={3} >
              <Grid item xs={12} md={6}>
                <Typography align="center" color="textPrimary">
                    No pending request found
                  </Typography></Grid></Grid>}
            </TabPanel>
            <TabPanel value={this.state.selectedBookingType} index={1}>
            {approvedRequest.length > 0 ? approvedRequest.map((data) => (
                <Box className={classes.box} py={3} key={data.id}>
                  <Grid container spacing={3} pl={0}>
                    <Grid item xs={12} md={5}>
                      <img
                        src={data?.attributes?.boat?.attributes?.thumbnail_image?.url || boatImage}
                        width="100%"
                        className={classes.boatImage}
                      />
                    </Grid>
                    <Grid container item xs={12} md={7}>
                      <Box pb={3}>
                        <Typography
                          variant="h5"
                          className={classes.blueHeading}
                        >
                          {data?.attributes?.boat?.attributes?.name}
                        </Typography>
                        <Typography variant="h7">{`${data?.attributes?.boat?.attributes?.address?.address1},${data?.attributes?.boat?.attributes?.address?.city}`}</Typography>
                      </Box>

                      <Grid container item>
                        <Grid item xs={8}>
                          <Typography
                            variant="h7"
                            className={classes.detailName}
                          >
                            Rented By
                          </Typography>
                          <Box>
                            <Box display="inline-block" pt={1}>
                              <Avatar
                                component="span"
                                className={classes.avatar}
                              />
                            </Box>
                            <Box
                              display="inline-block"
                              
                              className={classes.textCentre}
                            >
                              <Typography variant="h7" component="div">
                                {data?.attributes?.account?.user_name || "-"}
                              </Typography>
                              <Typography
                                variant="h7"
                                component="div"
                                className={classes.blueDetails}
                              >
                                Call Renter
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>

                        <Grid item xs={4}>
                          <Box display="flex" justifyContent="space-around">
                            <Box />
                            <Box>
                              <Typography
                                variant="h6"
                                className={classes.detailName}
                              >
                                Trip date
                              </Typography>
                              <Typography
                                variant="h7"
                                component="div"
                                className={classes.timeDetails}
                              >
                                {moment(new Date(data?.attributes?.trip_date).getTime()).format("DD/MM/YYYY")}
                              </Typography>
                              <Typography
                                variant="h7"
                                component="div"
                                className={classes.timeDetails}
                              >
                                {moment(new Date(data?.attributes?.start_time).getTime()).format("hh:mm")}
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                      <Grid container item>
                        <Grid item xs={12}>
                          <Box
                            display="flex"
                            justifyContent="flex-end"
                            
                          >
                            <Button color="primary" onClick={() => {this.openBookingDetailsDialog(data)}}>Booking Details</Button>

                            {/* <Button
                              
                              startIcon={
                                <DeleteIcon className={classes.trashbin} />
                              }
                              className={classes.deleteButton}
                            >
                              Delete
                            </Button> */}
                          </Box>
                        </Grid>
                      </Grid>
                      <Grid container item>
                        <Grid item xs={4}>
                          <Box>
                            <Typography
                              variant="h7"
                              className={classes.paidStatus}
                            >
                              Paid in advance
                            </Typography>
                            <Typography variant="h4" className={classes.amount}>
                            {data?.attributes?.total_amount || "-"}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid
                          container
                          item
                          xs={8}
                          spacing={2}
                          justify="flex-end"
                          alignContent="center"
                        >
                          <Grid item>
                            <Button
                              color="primary"
                              variant="outlined"
                              onClick={()=>this.openRejectRequest(data)}
                              className={classes.roundButtons}
                            >
                              Reject
                            </Button>
                          </Grid>
                          <Grid item>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={()=>this.openCheckInDialog(data)}
                              className={`${classes.approveButton} ${
                                classes.roundButtons
                              }`}
                            >
                              Check-In
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              )) : <Grid container justify="center" spacing={3} >
              <Grid item xs={12} md={6}>
                <Typography align="center" color="textPrimary">
                    No booking found
                  </Typography></Grid></Grid>}
            </TabPanel>
            <TabPanel value={this.state.selectedBookingType} index={2}>
            {approvedOrRejectRequest.length >0 ? approvedOrRejectRequest.map((data) => (
                <Box className={classes.box} py={3} key={data.id}>
                  <Grid container spacing={3} pl={0}>
                    <Grid item xs={12} md={5}>
                      <img
                        src={data?.attributes?.boat?.attributes?.thumbnail_image?.url || boatImage}
                        width="100%"
                        className={classes.boatImage}
                      />
                    </Grid>
                    <Grid container item xs={12} md={7}>
                      <Box pb={3}>
                        <Typography
                          variant="h5"
                          className={classes.blueHeading}
                        >
                          {data?.attributes?.boat?.attributes?.name}
                        </Typography>
                        <Typography variant="h7">{`${data?.attributes?.boat?.attributes?.address?.address1},${data?.attributes?.boat?.attributes?.address?.city}`}</Typography>
                      </Box>

                      <Grid container item>
                        <Grid item xs={8}>
                          <Typography
                            variant="h7"
                            className={classes.detailName}
                          >
                            Rented By
                          </Typography>
                          <Box>
                            <Box display="inline-block" pt={1}>
                              <Avatar
                                component="span"
                                className={classes.avatar}
                              />
                            </Box>
                            <Box
                              display="inline-block"
                              
                              className={classes.textCentre}
                            >
                              <Typography variant="h7" component="div">
                                {data?.attributes?.account?.user_name || "-"}
                              </Typography>
                              <Typography
                                variant="h7"
                                component="div"
                                className={classes.blueDetails}
                              >
                                Call Renter
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>

                        <Grid item xs={4}>
                          <Box display="flex" justifyContent="space-around">
                            <Box />
                            <Box>
                              <Typography
                                variant="h6"
                                className={classes.detailName}
                              >
                                Trip date
                              </Typography>
                              <Typography
                                variant="h7"
                                component="div"
                                className={classes.timeDetails}
                              >
                                {moment(new Date(data?.attributes?.trip_date).getTime()).format("DD/MM/YYYY")}
                              </Typography>
                              <Typography
                                variant="h7"
                                component="div"
                                className={classes.timeDetails}
                              >
                                {moment(new Date(data?.attributes?.start_time).getTime()).format("hh:mm")}
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                      <Grid container item>
                        <Grid item xs={12}>
                          <Box
                            display="flex"
                            justifyContent="flex-end"
                            
                          >
                            
                            <Button color="primary" onClick={() => {this.openBookingDetailsDialog(data)}}>Booking Details</Button>

                            {/* <Button
                              
                              startIcon={
                                <DeleteIcon className={classes.trashbin} />
                              }
                              className={classes.deleteButton}
                            >
                              Delete
                            </Button> */}
                          </Box>
                        </Grid>
                      </Grid>
                      <Grid container item>
                        <Grid item xs={4}>
                          <Box>
                            <Typography
                              variant="h7"
                              className={classes.paidStatus}
                            >
                              Paid in advance
                            </Typography>
                            <Typography variant="h4" className={classes.amount}>
                            {data?.attributes?.total_amount || "-"}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid
                          container
                          item
                          xs={8}
                          spacing={2}
                          justify="flex-end"
                          alignContent="center"
                        >
                          <Grid item>
                          <Button
                              color="primary"
                              variant="outlined"
                              onClick={()=>{this.openComplain(data)}}
                              className={classes.roundButtons}
                            >
                            Complaint
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              )) : <Grid container justify="center" spacing={3} >
              <Grid item xs={12} md={6}>
                <Typography align="center" color="textPrimary">
                    No booking found
                  </Typography></Grid></Grid>}
            </TabPanel>
          </Grid>
        </Grid>
      </Container>
    );
  }
}
const OwnerBookingsWithRouter = withRouter(OwnerBookings);
const OwnerBookingsAlertBox = withAlertBox(OwnerBookingsWithRouter);
const OwnerBookingsLoader = withLoader(OwnerBookingsAlertBox);
const OwnerBookingsToast = withToast(OwnerBookingsLoader);
export default withStyles((theme) =>
  createStyles({
    box: {
      border: "1px #dee2e3",
      borderStyle: "none none solid none",
    },
    removePadding: {
      margin: "-24px",
    },
    blueHeading: {
      fontFamily: "Montserrat",
      color: "#004277",
      fontWeight: 600,
    },
    smallText: {
      fontFamily: "Montserrat",
      color: "#343a40",
    },
    amount: {
      fontFamily: "Montserrat",
      fontSize: "2rem",
      fontWeight: 600,
      color: "#000000",
    },
    detailName: {
      fontFamily: "Montserrat",
      fontSize: "1rem",
      fontWeight: 600,
      color: "#343a40",
    },
    blueDetails: {
      fontFamily: "Montserrat",
      fontSize: "0.875rem",
      color: "#0174cf",
    },
    paidStatus: {
      fontFamily: "Montserrat",
      color: "#b8b8b8",
    },
    timeDetails: {
      fontFamily: "Montserrat",
      fontSize: "0.875rem",
      color: "#343a40",
    },
    tabPanelcolor: {
      fontFamily: "Montserrat",
      backgroundColor: "white",
      color: "black",
    },
    approveButton: {
      fontFamily: "Montserrat",
      
    },
    roundButtons: {
      fontFamily: "Montserrat",
      borderRadius: "5px",
      border: "solid 2px #0174cf",
    },
    avatar: {
      fontFamily: "Montserrat",
      height: "60px",
      width: "60px",
    },
    removeBoxShadow: {
      fontFamily: "Montserrat",
      boxShadow: "none",
      border: "1px #dee2e3",
      borderStyle: "none none solid none",
    },
    selected: {
      fontFamily: "Montserrat",
      opacity: 1.0,
      color: "black",
    },
    notselected: {
      fontFamily: "Montserrat",
      opacity: 0.3,
      color: "#b8b8b8",
    },
    boatImage: {
      height: "280px",
    },
    trashbin: {
      opacity: 0.5,
    },
    textCentre: {
      verticalAlign: "text-bottom",
      "@media only screen and (min-width: 768px)": {
        paddingLeft:"24px"
      }
    },
    deleteButton:{
      color:theme.palette.error.main
    }
  })
)(withDialog(OwnerBookingsToast));