import Montserrat from './Montserrat-Regular.woff2';
import Roboto from './Roboto-Regular.woff2';
import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

const montserratRegular = {
  fontFamily: 'Montserrat-Regular',
  src: `
      local('ProximaNova-Regular'),
      url(${Montserrat}) format('woff2')
  `
};
const robotoRegular = {
  fontFamily: 'Roboto-Regular',
  src: `
      local('ProximaNova-Regular'),
      url(${Roboto}) format('woff2')
  `
};
export const themeOptions = {
  palette: {
    type: 'light',
    primary: {
      main: '#0174cf',
      contrastText: '#fff'
    },
    secondary: {
      main: '#0174cf'
    },
    background: {
      default: '#ffffff',
      paper: '#ffffff'
    },
    text: {
      primary: '#1b4050',
      secondary: '#717b90'
    },
    error: {
      main: '#ef3e22'
    }
  }
};
const theme = createMuiTheme({
  ...themeOptions,
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [montserratRegular, robotoRegular]
      }
    },
    // MuiOutlinedInput : {
    //   input : {
    //     padding : '10.5px 14px'
    //   }
    // },
    // MuiInputBase: {
    //   input: {
    //     padding : "6px 0 12px!important"
    //   },
    // },
    // MuiInputLabel: {
    //   asterisk: {
    //     fontFamily: 'Montserrat',
    //     fontSize: '14px',
    //     fontWeight: 600,
    //     fontStretch: 'normal',
    //     fontStyle: 'normal',
    //     lineHeight: 1.14,
    //     letterSpacing: 'normal',
    //     color: '#ef3e22'
    //   },
    //   root: {
    //     fontFamily: 'Montserrat',
    //     fontSize: '14px',
    //     fontWeight: 600,
    //     fontStretch: 'normal',
    //     fontStyle: 'normal',
    //     lineHeight: 1.14,
    //     letterSpacing: 'normal',
    //     color: '#717b90',
    //   },
    //   outlined : {
    //     transform : 'translate(0px, 16px) scale(1)'
        
    //   },
    //   shrink : {
    //     transform : 'translate(0px, 16px) scale(1)'
    //   }
    // },
    MuiFormHelperText : {
      root : {
            fontFamily: 'Montserrat',
        fontSize: '12px',
        fontWeight: 500,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.25,
        letterSpacing: 'normal',
        color: '#000000',
      }
    },
    MuiButton : {
      root : {
        height : '40px',
        borderRadius: '2px',
        padding : "8px 20px"
      },
      endIcon : {
        marginLeft : '12px'
      },
      label : {
        fontFamily: 'Roboto',
        fontSize: '14px',
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.14,
        letterSpacing: '0.84px',
        textTransform : 'none'
      },
      contained : {
        fontFamily: 'Roboto',
        fontSize: '14px',
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.14,
        letterSpacing: '0.84px',
        textTransform : 'none'
      },
      outlined : {

      },
      text : {
        fontFamily: 'Roboto',
        fontSize: '15px',
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.2,
        letterSpacing: '0.9px',
        textAlign: 'right',
        color: '#0174ce',
        textTransform : 'none' 
      }
    }
  }
});

export default responsiveFontSizes(theme);
