import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { dialogBoxProps } from "../../../components/src/withDialog.web";
import { withAlertBoxProps } from "../../../components/src/withAlertBox.Web";
import { withToastProps } from "../../../components/src/withSnackBar.Web";
import { withLoaderProps } from "../../../components/src/withLoader.Web";
export const configJSON = require("./config");

export type Props = RouterProps &
  dialogBoxProps &
  withAlertBoxProps &
  withToastProps &
  withLoaderProps & {
    id: string;
    // Customizable Area Start
    // Customizable Area End
  };

interface S {
  authToken : any;
  isValidSession : boolean
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  id: any;
}
export type loginTypeValue = {
  email: String;
  password: String;
  unique_auth_id? : String;
};
export default class EmailAccountLoginController extends BlockComponent<
  Props,
  S,
  SS
> {
  loginApiEmailCallId: String = "";
  loginApiFacebookEmailCallId : String = "";
  getPersonalInfoApiCallId : String = "";
  loginApiGoogleEmailCallId : String = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
      // Customizable Area End
    ];

    this.state = {
      authToken : '',
      isValidSession : false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.loginApiEmailCallId) {
        this.props.hideLoader();
        if (responseJson && responseJson?.meta && responseJson?.meta?.token) {
          const profileData = responseJson?.meta?.account;
          const token = responseJson.meta.token;
          this.props.showToast({
            type: "success",
            message: "Logged In Successfully",
          });
          await StorageProvider.set("authToken",token);
          await StorageProvider.set("accountInfo",JSON.stringify(profileData));
          const currentPath : any = await StorageProvider.get("currentPath");
          const currentRouteState : any = await StorageProvider.get("currentRouteState") || "{}";
          if(currentPath){
            this.props.history.push({pathname : currentPath,state : JSON.parse(currentRouteState)})
          } else {
            if(profileData.marina_user){
              this.props.history.push("/marinaList")
            } else {
              this.props.history.push("/")
            }
          }
        } else {
          this.props.showAlert({
            title : 'Error',message : 'No Account Found'
          })
          this.parseApiErrorResponse(responseJson);
        }
      } else if(apiRequestCallId === this.loginApiFacebookEmailCallId){
        this.props.hideLoader();
        if (responseJson && responseJson?.meta && responseJson?.meta?.token) {
          const token = responseJson.meta.token;
          this.props.showToast({
            type: "success",
            message: "Logged In Successfully",
          });
          await StorageProvider.set("authToken",token);
          await StorageProvider.set("accountInfo",JSON.stringify(responseJson.data));
          const currentPath : any = await StorageProvider.get("currentPath");
          const currentRouteState : any = await StorageProvider.get("currentRouteState") || "{}";
          if(currentPath){
            this.props.history.push({pathname : currentPath,state : JSON.parse(currentRouteState)})
          } else {
              this.props.history.push("/")
          }
        } else {
          this.props.showAlert({
            title : 'Error',message : 'No Account Found'
          })
          this.parseApiErrorResponse(responseJson);
        }
      } else if(this.loginApiGoogleEmailCallId === apiRequestCallId){
        this.props.hideLoader();
        if (responseJson && responseJson?.meta && responseJson?.meta?.token) {
          const token = responseJson.meta.token;
          this.props.showToast({
            type: "success",
            message: "Logged In Successfully",
          });
          await StorageProvider.set("authToken",token);
          await StorageProvider.set("accountInfo",JSON.stringify(responseJson.data));
          const currentPath : any = await StorageProvider.get("currentPath");
          const currentRouteState : any = await StorageProvider.get("currentRouteState") || "{}";
          if(currentPath){
            this.props.history.push({pathname : currentPath,state : JSON.parse(currentRouteState)})
          } else {
              this.props.history.push("/")
          }
        } else {
          this.props.showAlert({
            title : 'Error',message : 'No Account Found'
          })
          this.parseApiErrorResponse(responseJson);
        }
      } else if (apiRequestCallId === this.getPersonalInfoApiCallId) {
        this.props.hideLoader();
        if (responseJson && responseJson?.data && responseJson?.data?.id && responseJson?.data?.attributes) {
          this.setState({
               isValidSession : true
          },()=>{
            this.props.history.push("/");
          })
        } else {
          this.setState({
            isValidSession : false
          },()=>{
            this.parseApiErrorResponse(responseJson)
          })
        }
      }
    } else if (getName(MessageEnum.AlertMessage) === message.id) {
      const title = message.getData(getName(MessageEnum.AlertTitleMessage));
      var AlertBodyMessage = message.getData(
        getName(MessageEnum.AlertBodyMessage)
      );
      const msg = await this.props.showAlert({title : 'Error',message : AlertBodyMessage})
      if(msg === 'Your session has expired, please login again.' || msg === 'Invalid token'){
        await StorageProvider.remove("authToken")
        await StorageProvider.remove("accountInfo")  
        this.props.history.push("/basicAuth/login")
      }
    }
  }
  handleFacebookAccountLogin = (access_token: string) => {
    this.props.showLoader();
    const headers = {
      "Content-Type": configJSON.ApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.loginApiFacebookEmailCallId = requestMessage.messageId;
    const httpBody = {
      "data": {
        "type": "facebook",
        "attributes": {
                "access_token": access_token
        }
      }
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${"bx_block_login/social_login"}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPOST
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleGoogleAccountLogin = (access_token: string) => {
    this.props.showLoader();
    const headers = {
      "Content-Type": configJSON.ApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.loginApiGoogleEmailCallId = requestMessage.messageId;
    const httpBody = {
      "data": {
        "type": "google",
        "attributes": {
                "access_token": access_token
        }
      }
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${"bx_block_login/social_login"}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPOST
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleLogin(values: loginTypeValue) {
    this.props.showLoader();
    const headers = {
      "Content-Type": configJSON.ApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.loginApiEmailCallId = requestMessage.messageId;
    const httpBody = {
      data: {
        type: "email_account",
        attributes: {
          email: values.email,
          password: values.password,
        },
      },
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.loginInAPiEmailEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPOST
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getPersonalInfo() {
    this.props.showLoader();
    const headers = {
      "content-type": configJSON.ApiContentType,
      token : this.state.authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getPersonalInfoApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.personalProfieInfo}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
}
