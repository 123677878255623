//@ts-nocheck
import {
  Box,
  Container,
  createStyles,
  Grid,
  Typography,
  withStyles,
} from "@material-ui/core";
import StarIcon from "@material-ui/icons/Star";
import React, { Component } from "react";
import { withRouter } from "react-router";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withLoader from "../../../components/src/withLoader.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import { boatImage, boatImage1, MarinaBayBackground } from "./assets";
import MarinaDashboardController,{ Props } from "./MarinaDashboardController.web";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import withConfirmBox from "../../../components/src/withConfirmBox.Web";
const DataValues = [
  {
    boatPic: boatImage,

    boatName: "Sea Ray Yatch",
    captained: true,

    rating: "4.0",

    time: "4-8",

    limit: "9",

    reviewCount: "24",

    price: "$1200+",
  },

  {
    boatPic: boatImage1,

    boatName: "Luxury Sports Cruizer",
    captained: false,

    rating: "4.0",

    time: "4-8",

    limit: "9",

    reviewCount: "24",

    price: "$1200+",
  },

  {
    boatPic: boatImage,

    boatName: "Luxury Sport Cruizer",
    captained: true,

    rating: "4.0",

    time: "4-8",

    limit: "9",

    reviewCount: "24",

    price: "$1200+",
  },

  {
    boatPic: boatImage1,

    boatName: "Luxury Sportzz Cruizer",
    captained: false,

    rating: "4.0",

    time: "4-8",

    limit: "9",

    reviewCount: "24",

    price: "$1200+",
  },
];

class MarinaDashboard extends MarinaDashboardController {
  constructor(props: Props) {
    super(props);
  }
  async componentDidMount() {
    const authToken = await StorageProvider.get("authToken");
    const marinaId = this.props?.marinaId || await StorageProvider.get("marinaId");
    const accountInfo = await StorageProvider.get("accountInfo");
    if (authToken && marinaId) {
      this.setState({
        authToken: authToken,
        marinaId : marinaId,
        accountInfo : accountInfo && JSON.parse(accountInfo)
      },()=>{
        this.getMarinaBoatListing(marinaId);
      });
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <Box>
        <Box className={classes.headingMain}>
          <Box className={classes.headingMainImage}>
            <img
              src={this.state?.marinaDetail?.image?.url || MarinaBayBackground}
              style={{ objectFit: "contain", width: "100%" }}
            />
          </Box>
          <Box className={classes.headingMainText}>
            <Typography className={classes.headingMainTextH1}>
              {this.state?.marinaDetail?.name || "Not Available"}
            </Typography>
            <Typography className={classes.headingMainTextBody}>
              {this.state?.marinaDetail?.address?.address1 || "Not Available"},{this.state?.marinaDetail?.address?.city || "Not Available"}
            </Typography>
          </Box>
        </Box>
        <Container className={classes.root}>
          <Box>
            <Typography className={classes.heading}>
              Boats Listed on this Marina
            </Typography>
          </Box>
          <Grid spacing={2} container className={classes.content}>
            {this.state.boatList.map((boat) => (
              <Grid
                className={classes.card}
                key={boat.boat?.id}
                item
                xs={12}
                sm={6}
                md={4}
              >
                <Box>
                  <Box>
                    <img
                      src={boat.boat?.thumbnail_image?.url}
                      style={{ width: "100%", objectFit: "contain" }}
                    />
                  </Box>
                  <Grid className={classes.cardContent} container>
                    <Grid item xs={8}>
                      <Typography className={classes.cardContentH1}>
                        {boat.boat?.name}
                      </Typography>
                      <Typography className={classes.cardContentBody1}>
                        {"4-8 Hours"} · Up to {boat.boat?.max_passengers || 0} passengers
                      </Typography>
                      {boat.boat?.boat_operator && (
                        <Typography className={classes.cardContentBody1}>
                          {boat.boat?.boat_operator === 'render' ? "No captain included" : 'captain included'}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item className={classes.cardContentRight} xs={4}>
                      <Typography
                        className={classes.ratingButton}
                        variant="subtitle1"
                        component="div"
                      >
                        {boat.boat?.rating || 3}{" "}
                        <StarIcon
                          fontSize="small"
                          style={{ verticalAlign: "sub" }}
                        />
                      </Typography>
                      <Typography className={classes.cardContentBody2}>
                        {boat.boat?.reviewCount || 3} Reviews
                      </Typography>
                      <Typography className={classes.cardContentBody3}>
                        {boat.boat?.price || "$1200"}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
    );
  }
}

const MarinaDashboardWithRouter = withRouter(MarinaDashboard);
const MarinaDashboardAlertBox = withAlertBox(MarinaDashboardWithRouter);
const MarinaDashboardLoader = withLoader(MarinaDashboardAlertBox);
const MarinaDashboardToast = withToast(MarinaDashboardLoader);
export default withStyles((theme) =>
  createStyles({
    headingMain: {
      position: "relative",
      width: "100%",
      height: "100%",
      backgroundColor: "Grey",
    },
    headingMainImage: {
      width: "100%",
    },
    headingMainText: {
      position: "absolute",
      marginLeft: "4.8%",
      bottom: "0",
    },
    headingMainTextH1: {
      marginBottom: "5px",
      fontFamily: "Montserrat",
      fontSize: "60px",
      fontWeight: "600",
      color: "#ffffff",
    },
    headingMainTextBody: {
      fontFamily: "Montserrat",
      fontSize: "24px",
      color: "#ffffff",
      marginBottom: "32px",
    },
    card: {
      borderBottom: "solid 1px #70707050;",
    },
    root: {
      padding: "0 4.8%",
      paddingRight: "3.7%",
      margin: "0",
      marginTop: "40px",
      maxWidth: "1393px",
    },
    heading: {
      fontFamily: "Montserrat",
      fontSize: "24px",
      fontWeight: "600",
      color: "#343a40",
    },
    content: {
      marginTop: "32px",
      // justifyContent: "space-between",
    },
    ratingButton: {
      fontSize: "1rem",
      backgroundColor: "#f4c064",
      color: "white",
      borderRadius: "4px",
      textAlign: "center",
      margin: "0 0 5px 30%",
      fontWeight: 600,
    },
    cardContent: {
      paddingLeft: "15px",
      paddingRight: "8px",
      marginTop: "16px",
    },
    cardContentH1: {
      fontFamily: "Roboto",
      fontSize: "20px",
      fontWeight: "500",
      color: "#000000",
      marginBottom: "8px",
    },
    cardContentBody1: {
      fontFamily: "Roboto",
      fontSize: "16px",
      fontWeight: "bold",
      color: "#5d5d5d",
    },
    cardContentRight: {
      textAlign: "Right",
    },
    cardContentH2: {
      // backgroundColor: '#f4c064',
      fontFamily: "Roboto",
      fontSize: "16px",
      fontWeight: "bold",
      color: "Black",
      marginBottom: "6px",
    },
    cardContentBody2: {
      fontFamily: "Roboto",
      fontSize: "16px",
      color: "#979797",
      marginBottom: "7px",
    },
    cardContentBody3: {
      fontFamily: "Roboto",
      fontSize: "20px",
      fontWeight: "500",
      color: "#000000",
    },
  })
)(withConfirmBox(MarinaDashboardToast));
