//@ts-nocheck
import React from "react";
import BookBoatPaymentController, {
  Props,
} from "./BookBoatPaymentController.web";
import clsx from 'clsx'
import {
  withStyles,
  Grid,
  Typography,
  createStyles,
  ListItem,
  List,
  ListItemText,
  ListItemSecondaryAction,
  Button,
  Paper,
  Divider,
  TextField,
  Link,
  Box
} from "@material-ui/core";
import {  boatImageDemo1, boatImageDemo2 } from "./assets";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import AppleIcon from '@material-ui/icons/Apple';
import { PayPalButtons } from "@paypal/react-paypal-js";

import { Formik, Form, Field } from "formik";
import { withRouter } from "react-router";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
import InputBox from "./InputBox.web";
import moment from 'moment';
import { classNames } from "react-select/src/utils";
class BookBoatPayment extends BookBoatPaymentController {
  constructor(props: Props) {
    super(props);
  }
  async componentDidMount() {
    const authToken = await StorageProvider.get("authToken");
    const boatId = this.props?.history?.location?.state?.attributes?.boat_id || 1;
    if (authToken && boatId) {
      this.setState({
        authToken: authToken,
        boatId : boatId,
        booking_details : this.props?.history?.location?.state?.attributes,
        bookingId : this.props?.history?.location?.state?.id,
        promoCode:"",
        isPromoCode : false
      },()=>{
          this.getBoatDetail();
          this.getPromoCodes();
          
      });
    }
  }

  validatePromo = () => {
    this.checkPromoCode(this.state.promoCode)
  }

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };

  goToSelectedRoute = (routeName) => {
    const { history } = this.props;
    history.push(routeName);
  };

  openPassengersMenu = (event) => {
    this.setState({
      passengersAnchorEl: event.currentTarget,
    });
  };
  selectPassengersAndCloseMenu = () => {
    this.setState({
      passengersAnchorEl: null,
    });
  };
  openDurationMenu = (event) => {
    this.setState({
      durationAnchorEl: event.currentTarget,
    });
  };
  selectDurationAndCloseMenu = () => {
    this.setState({
      durationAnchorEl: null,
    });
  };
  openCaptionMenu = (event) => {
    this.setState({
      captionAnchorEl: event.currentTarget,
    });
  };
  selectCaptionAndCloseMenu = () => {
    this.setState({
      captionAnchorEl: null,
    });
  };
  getPrice = (boatPrice1 = {}) => {
    const boatPrice = boatPrice1 ? boatPrice1 : {}
    if(this.state.duration){
      if(this.state.duration === 'half_day'){
        return `$ ${boatPrice["half_day_rate"] || "-"}`
      } else if(this.state.duration === 'full_day'){
        return `$ ${boatPrice["full_day_rate"] || "-"}`
      } else if(this.state.duration === 'twentyfour_hours'){
        return `$ ${boatPrice["twenty_four_hours_rate"] || "-"}`
      } else if(this.state.duration === 'three_days'){
        return `$ ${boatPrice["three_days_rate"] || "-"}`
      }
      else if(this.state.duration === 'seven_days'){
        return `$ ${boatPrice["seven_days_rate"] || "-"}`
      }
    } else {
      return `$ ${boatPrice["half_day_rate"] || "-"}`
    }
  }
  getHours = (duration) => {
    if(duration){
      if(duration === 'half_day'){
        return "0-4 Hours "
      } else if(duration === 'full_day'){
        return "8 Hours "
      } else if(duration === 'twentyfour_hours'){
        return "24 Hours "
      } else if(duration === 'three_days'){
        return "3 Days "
      }
      else if(duration === 'seven_days'){
        return "7 Days "
      }
    } else {
      return "0-4 Hours "
    }
  }
  onApprove = (data: OnApproveData, actions: OnApproveActions) => {
    return actions.order.capture().then((details) => {
          this.setState({
            transactionData : details
          },()=>{
            this.checkBoatAvailable(this.state.boatId);
          })
            
            
    });
  }

  onError = (err: Record<string, unknown>) => {
      // this.setState({
      //   paymentErrorMsg : err.toString()
      // },()=>{
      //   this.props.showToast({type: 'error',message : 'payment failed'})
      // })
  }
  createOrder = (
    data: Record<string, unknown>,
    actions: CreateOrderActions
   ) => {
    return actions.order
        .create({
            purchase_units: [
                {
                    amount: {
                        value: this.state?.booking_details?.total_amount,
                        
                    },
                },
            ],
        })
        .then((orderID) => {
            this.setState({
              transactionID : orderID
            })
            return orderID;
        });
  }
  render() {
    const { classes } = this.props;
    console.log("State",this.state)
    return (
      <>
        <Grid container className={classes.root}>
          <Grid item xs={12} sm={12} md={12}>
            <div className={classes.root}>
              <main className={classes.layout}>
                <Paper className={classes.paper}>
                 <Grid xs={12}>
                 
                 <Typography component="h1" variant="h4" className={classes.mainHeading}>
                    Complete your Booking
                  </Typography>
                 
                 </Grid>
                  <Grid container justify="space-between" spacing={4}>
                    <Grid container item md={6} xs={12}>
                      
                        
                            <Grid xs={10} item>
                              <Typography className={classes.heading}>Your Selection:</Typography>
                            </Grid>
                            <Grid
                              className={classes.hotelMainImage}
                              style={{backgroundImage : `url(${this.state.boatDetails?.thumbnail_image?.url})` || boatImageDemo1,
                               minHeight: "230px", maxWidth:"400px"
                              }}
                              xs={8}
                              item
                            />
                            <Grid item xs={10}>
                             <Box mb={3}>
                             <Typography
                                
                                className={classes.boatName}
                              >
                                {`${this.state.boatDetails?.name}`}
                              </Typography>
                              <Typography
                                gutterBottom
                                className={classes.boatAddress}
                              >
                                {`${this.state.boatDetails?.address?.address1},${this.state.boatDetails?.address?.city}`}
                              </Typography>
                             </Box>
                            </Grid>
                        
                        <Grid item xs={12} classes={{item: classes.removePadding}}>
                          <List>
                          <ListItem className={classes.removePadding}>
                              <ListItemText primary="Security Deposit" />
                              <ListItemSecondaryAction classes={{root: classes.amount}}>
                                $ {this.state?.booking_details?.security_deposite}
                              </ListItemSecondaryAction>
                            </ListItem>
                            <ListItem className={classes.removePadding}>
                              <ListItemText primary="Boat Price" />
                              <ListItemSecondaryAction classes={{root: classes.amount}}>
                                $ {this.state?.booking_details?.boat_price}
                              </ListItemSecondaryAction>
                            </ListItem>
                            <ListItem className={classes.removePadding}>
                              <ListItemText primary="Service Fee" />
                              <ListItemSecondaryAction classes={{root: classes.amount}}>
                              $ {this.state?.booking_details?.service_fee}
                              </ListItemSecondaryAction>
                            </ListItem>
                            <ListItem className={classes.removePadding}>
                              <ListItemText primary="Sales Tax" />
                              <ListItemSecondaryAction classes={{root: classes.amount}}>
                              $ {this.state?.booking_details?.sales_tax}
                              </ListItemSecondaryAction>
                            </ListItem>
                            {this.state.isPromoCode && <ListItem className={classes.removePadding}>
                              <ListItemText primary="Promo Code" />
                              <ListItemSecondaryAction classes={{root: classes.promocodeAmount}}>
                                -$ {this.state?.booking_details?.promocode_amount}
                              </ListItemSecondaryAction>
                            </ListItem>}
                            <Divider variant="middle" />
                            <ListItem >
                              <ListItemText primary="Booking Total" classes={{primary:classes.total}}/>
                              <ListItemSecondaryAction className={clsx(classes.amount,classes.total)}>
                              $ {this.state?.booking_details?.total_amount}
                              </ListItemSecondaryAction>
                            </ListItem>
                          </List>
                        </Grid>

                        <Grid container item xs={12} style={{marginTop : 'auto'}} spacing={1} >
                            <Grid item md={6} xs={12} className={classes.removeSidePadding}>
                              <Typography display="inline" className={classes.rentalTermHeading}>Rental Term : </Typography>
                              <Typography display="inline" className={classes.rentalTermValue}> Security Deposit $500</Typography>
                            </Grid>
                            <Grid item md={6} xs={12} className={classes.removeSidePadding}>
                              <Typography display="inline" className={classes.rentalTermHeading}>Cancelation Policy :</Typography> 
                              <Typography display="inline" className={classes.rentalTermValue}>Moderate</Typography>
                              <Link className={classes.seeDetails}>See Details</Link>
                            </Grid>
                          </Grid>
                        
                      
                    </Grid>
                    
                    <Grid item md={6} xs={12}>
                      <Formik
                        initialValues={{
                          message: "",
                          cardHolderName: "",
                          billingZip: "",
                          cardNumber: "",
                          securityCode: "",
                          expiryCode: "",
                          promoCode: "",
                        }}
                        enableReinitialize
                        // validationSchema={boatDesSchema}
                        
                        onSubmit={async (values, actions) => {
                          this.createBooking(values);
                         
                        }}
                       
                      >
                        {(formikProps) => {
                          const {
                            values,
                            handleBlur,
                            handleChange,
                            handleSubmit,
                            setFieldValue,
                            isSubmitting,
                            dirty,
                            isValid,
                            
                          } = formikProps;
                          return (
                            <Form noValidate autoComplete="off">
                              <Grid
                                container
                                justify="space-between"
                                spacing={4}
                              >
                                <Grid item xs={12}>
                                  <Grid
                                    container
                                    spacing={3}
                                    className={classes.detailBox}
                                  >
                                    <Grid lg={3} md={12} xs={12} item className={classes.removeBottomPadding}>
                                      <Typography
                                        className={classes.subHeading}
                                      >
                                        Trip Details
                                      </Typography>
                                    </Grid>
                                    <Grid lg={5} md={7} xs={12} item>
                                      <Grid
                                        container
                                        spacing={2}
                                        justify="space-between"
                                      >
                                        <Grid container item xs={12}>
                                          <Grid xs={6}>
                                              <Typography
                                                className={classes.labelHeading}
                                              >
                                                Date:
                                              </Typography>

                                          </Grid>
                                           <Grid xs={6}>

                                            <Typography
                                              className={classes.valueHeading}
                                            >
                                              {moment(this.state?.booking_details?.trip_date).format("DD MMM YYYY")}
                                            </Typography>
                                           </Grid>
                                           
                                         
                                        </Grid>
                                        <Grid container item xs={12}>
                                          <Grid xs={6}>
                                          <Typography
                                                className={classes.labelHeading}
                                              >
                                                Passengers:
                                              </Typography>
                                          </Grid>
                                           <Grid xs={6}>
                                            <Typography
                                              className={classes.valueHeading}
                                            >
                                              {this.state?.booking_details?.passengers || 0} Passengers
                                            </Typography> 
                                            </Grid>   
                                            
                                            
                                          
                                        </Grid>
                                        <Grid container item xs={12}>
                                          <Grid  xs={6}>
                                              <Typography
                                                className={classes.labelHeading}
                                              >
                                                Duration:
                                              </Typography>
                                          </Grid>
                                           <Grid xs={6}>
                                            <Typography
                                              className={classes.valueHeading}
                                            >
                                              {this.getHours(this.state?.booking_details?.trip_duration) || "Not Available"}
                                            </Typography>

                                             </Grid>   
                                           
                                         
                                        </Grid>
                                        <Grid container item xs={12}>
                                          <Grid xs={6}>
                                          <Typography
                                                className={classes.labelHeading}
                                              >
                                                Start Time: 
                                              </Typography>
                                           
                                          </Grid>
                                          <Grid xs={6}>
                                            <Typography
                                              className={classes.valueHeading}
                                            >
                                              {moment(this.state?.booking_details?.start_time).format("hh:mm A")}
                                            </Typography>
                                          </Grid>
                                             
                                            
                                    
                                        </Grid>
                                        
                                      
                                      
                                    </Grid>
                                    </Grid>
                                    <Grid lg={4} md={5} xs={12} item className={classes.removeTopPadding}>
                                      <Grid
                                        container
                                        spacing={2}
                                        justify="space-between"
                                      >
                                        
                                        
                                        
                                        <Grid container item xs={12}>
                                          <Grid  xs={6}>
                                          <Typography
                                                className={classes.labelHeading}
                                              >
                                                Security Deposit:
                                              </Typography>
                                          </Grid>
                                          <Grid xs={6}>
                                          <Typography
                                              className={classes.valueHeading}
                                            >
                                              $ 500
                                            </Typography>
                                          </Grid>
                                              
                                           
                                           
                                          
                                        </Grid>
                                        <Grid container item xs={12}>
                                          <Grid  xs={6}>
                                              <Typography
                                                className={classes.labelHeading}
                                              >
                                                Captain:
                                              </Typography>
                                          </Grid>
                                          <Grid xs={6}>

                                            <Typography
                                              className={classes.valueHeading}
                                            >
                                              {this.state?.booking_details?.captain_included === true ? "captain included" :"no captain included"}
                                            </Typography>
                                          </Grid>
                                             
                                           
                                            
                                        
                                        </Grid>
                                      </Grid>
                                      
                                   
                                    </Grid>
                                    <Grid item xs={9}  style={{marginLeft:"auto  "}}>
                                      
                                        <Box display="flex">
                                        <Box >
                                        <CheckCircleIcon   className={classes.checkIcon}/>
 
                                        </Box>
                                        <Box>
                                        <Typography component={`span`}>
                                        {this.state.boatDetails.owner_approval_status === 'automatic' ? "Your request will be approved by the automatically." : "Your request will be manually approved by the onwer"}
                                        </Typography>

                                        </Box>
                                        </Box>

                                     
                                      {/* <Grid item xs={1}>
                                     
                                      </Grid>
                                       <Grid item xs={10}>
                                     
                                       </Grid> */}
                                      </Grid>
                                  </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                  <Grid
                                    container
                                    spacing={2}
                                    justify="space-around"
                                  >
                                    <Grid xs={12} sm={3} item>
                                      <Typography
                                        className={classes.heading}
                                      >
                                        Message for owner
                                      </Typography>
                                    </Grid>
                                    <Grid xs={12} sm={9} item>
                                      <Field
                                        placeholder="Hey there!"
                                        component={InputBox}
                                        variant="outlined"
                                        name="message"
                                        fullWidth
                                        multiline
                                        rows={3}
                                      ></Field>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid item xs={12} className={classes.removePadding}>
                                  <Grid
                                    container
                                    spacing={2}
                                    justify="space-around"
                                  >
                                    <Grid xs={12} sm={3} item>
                                      {/* <Typography
                                        className={clsx(classes.heading,classes.paymentHeading)}
                                      >
                                        Payment
                                      </Typography> */}
                                    </Grid>
                                    <Grid xs={12} sm={9} item>
                                      <Grid
                                        container
                                        justify="space-between"
                                        alignItems="center"
                                        spacing={1}
                                      >
                                        {/* <Grid item xs={8}>
                                          <Field
                                            label="Name on Card"
                                            placeholder="Enter Name on Card"
                                            component={InputBox}
                                            variant="outlined"
                                            name={"cardHolderName"}
                                            fullWidth
                                           
                                          />
                                        </Grid>
                                        <Grid item xs={4}>
                                          <Field
                                            label="Billing Zip"
                                            placeholder="Enter Billing Zip"
                                            component={InputBox}
                                            variant="outlined"
                                            name={"billingZip"}
                                            fullWidth
                                          />
                                        </Grid>
                                        <Grid item xs={8}>
                                          <Field
                                            label="Card Number"
                                            placeholder="Enter Card Number"
                                            component={InputBox}
                                            variant="outlined"
                                            name={"cardNumber"}
                                            fullWidth
                                          />
                                        </Grid>
                                        <Grid item xs={4}>
                                        <Field
                                            label="Security Code"
                                            placeholder="Enter Security Code"
                                            component={InputBox}
                                            variant="outlined"
                                            name={"securityCode"}
                                            fullWidth
                                          />
                                        </Grid>
                                        <Grid item xs={8}> */}
                                        {/* <Field
                                            label="Expiry Date"
                                            placeholder="Enter Expiry Date"
                                            component={InputBox}
                                            variant="outlined"
                                            name={"expiryCode"}
                                            fullWidth
                                          />
                                        </Grid> */}
                                        <Grid item xs={8}>
                                          <Field
                                            label="Promo Code"
                                            placeholder="Enter Promo Code"
                                            component={InputBox}
                                            onChange={(e)=>this.setState({promoCode:e.target.value})}
                                            value={this.state.promoCode}
                                            variant="outlined"
                                            name={"promoCode"}
                                            fullWidth
                                          />
                                        </Grid>
                                        <Grid item xs={4}  >
                                            <Button variant="contained" color="primary" className={classes.roundCorner} onClick={this.validatePromo} >
                                                Apply code
                                            </Button>
                                        </Grid>
                                        <Grid container item xs={12} justify="space-between" spacing={2} className={classes.marginForButons}>
                                        {/* <Grid container item sm={6} xs={12} justify="center">
                                        <Button variant="contained" type="submit" color="primary" className={clsx(classes.roundCorner,classes.mainButton)}>
                                                 Confirm & Book
                                            </Button>
                                        </Grid> */}
                                        {/* <Grid container item sm={6} xs={12} justify="center" className={classes.textAlignEnd}>
                                            <Button variant="contained" color="primary" className={clsx(classes.roundCorner,classes.appleButton,classes.mainButton)}>
                                               
                                               Pay with
                                               <AppleIcon className={classes.appleIcon}/>
                                               Pay 
                                               
                                            </Button>
                                        </Grid> */}
                                            <Grid item xs={12} justify="center" >
                                                <PayPalButtons createOrder={this.createOrder}
                                                      onApprove={this.onApprove}
                                                      onError={this.onError}
                                                      onCancel={(data)=>{console.log(data)}}
                                                      forceReRender={[this.state?.booking_details?.total_amount]} style={{layout: "vertical",color: "blue", shape: "pill", label: "pay", height: 40 }} />
                                            </Grid>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                             
                            </Form>
                          );
                        }}
                      </Formik>
                    </Grid>
                    
                  </Grid>
                </Paper>
              </main>
            </div>
          </Grid>
        </Grid>
      </>
    );
  }
}
const BookBoatPaymentWithRouter = withRouter(BookBoatPayment);
const BookBoatPaymentAlertBox = withAlertBox(BookBoatPaymentWithRouter);
const BookBoatPaymentLoader = withLoader(BookBoatPaymentAlertBox);
const BookBoatPaymentToast = withToast(BookBoatPaymentLoader);
export default withStyles((theme) =>
  createStyles({
    mainHeading:{
      color: "#004277",
      fontFamily: "Montserrat",
      fontWeight: 600,
      paddingBottom:"3%"
    },
    heading:{
      fontSize:"1.125rem",
      color: "#545454",
      fontFamily: "Roboto",
      fontWeight: 500
    },
    layout: {
      width: "auto",
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
        width: "80%",
        marginLeft: "auto",
        marginRight: "auto",
      },
    },
    paper: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
      padding: theme.spacing(4),
      paddingLeft:theme.spacing(6),
      paddingRight:theme.spacing(6),
      [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
       
      },
      [theme.breakpoints.down('xs')]:{
        paddingLeft:theme.spacing(4),
        paddingRight:theme.spacing(4),
      }
    },
    removePadding:{
      paddingTop:"0px !important",
      paddingBottom:"0px !important"
    },
     removeTopPadding:{
      [theme.breakpoints.down('md')]:{
        paddingTop:"0px !important"
      }
    },
    removeBottomPadding:{
      paddingBottom:"0px !important"
    },
    amount:{
      fontSize:"1.125rem",
      fontWeight:500,
      fontFamily: "Roboto",
      color:"#545454"
    },
    total:{
      color:"#183b56",
      fontSize:"1.125rem",
      fontWeight:"bold"
    },
    promocodeAmount:{
      fontSize:"1.125rem",
      fontWeight:500,
      fontFamily: "Roboto",
      color:"#50a456"
    },
    root: {
      backgroundImage:
        "linear-gradient(180deg, rgba(0,0,255,1) 30%, rgba(255,255,255,1) 30%)",
    },
    menuItem: {
      fontFamily: "Roboto",
      fontSize: "14px",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "normal",
      textTransform: "none",
      cursor: "pointer",
      color: "#343a40",
    },
    margin: {
      marginRight: theme.spacing(2),
    },
    marginTop: {
      marginTop: theme.spacing(4),
    },
    marginForButons:{
      marginTop: theme.spacing(2)
    },
    inputLable: {
      fontFamily: "Roboto",
      fontSize: "16px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.2,
      letterSpacing: "normal",
      color: "#343a40",
      textAlign: "center",
      padding: "12px",
    },
    hotelMainImage: {
      position: "relative",
      backgroundImage: `url(${boatImageDemo1})`,
      backgroundRepeat: "no-repeat",
      backgroundColor:
        theme.palette.type === "light"
          ? theme.palette.grey[50]
          : theme.palette.grey[900],
      backgroundSize: "cover",
      backgroundPosition: "center",
      height: theme.spacing(15),
      margin:"15px 0px",
      [theme.breakpoints.down("xs")]: {
        height: theme.spacing(10),
      },
      borderRadius: theme.spacing(1),
    },
    boatName: {
      fontFamily: "Montserrat",
      fontSize: "2rem",
      fontWeight: 500,
      lineHeight: 1.19,
      color: "#004277",
      marginBottom:"0.25rem"
    },
    boatAddress: {
      fontFamily: "Roboto",
      fontSize: "1rem",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.19,
      letterSpacing: "normal",
      color: "#343a40",
    },
    detailBox: {
      borderRadius: "5px",
      border: "solid 1px #0174cf",
      backgroundColor: "#ffffff",
      borderTop: "5px solid #0174cf",
      [theme.breakpoints.down('md')]:{
        paddingLeft:"10px"
      }
    },
    subHeading: {
      fontFamily: "Roboto",
      fontSize: "1.125rem",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "normal",
      color: "#004277",
      marginLeft:"0px",
      paddingBottom:"5px",
      [theme.breakpoints.up('lg')]:{
        marginLeft:"10px"
      }
    },
    labelHeading: {
      fontFamily: "Roboto",
      fontSize: "1rem",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "normal",
      color: "#183b56",
    },
    valueHeading: {
      fontFamily: "Roboto",
      fontSize: "1rem",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "normal",
      color: "#545454",
    },
    rentalTermHeading : {
      fontFamily: "Roboto",
      fontSize: "1rem",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "normal",
      color: "#183b56",
    },
    rentalTermValue : {
      fontFamily: "Roboto",
      fontSize: "1rem",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "normal",
      color: "#183b56",
    },
    seeDetails:{
      fontFamily: "Roboto",
      fontSize: "1rem",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.19,
      letterSpacing: "normal",
      color: "#0174cf",
      marginLeft:"8px"
    },
    removeSidePadding:{
      paddingLeft:"0px",
      paddingRight:"0px"
    },
    checkIcon:{
      color:"#00a676",
      marginRight:"15px"
    },
    appleIcon:{
      position:"relative",
      top:"-2px"
      
    },
    appleButton:{
      backgroundColor:"#000000",
      marginLeft:"5px"
    },
    roundCorner:{
      borderRadius:"5px",
      fontFamily:"Montserrat",
      
    },
    mainButton:{
      width:"180px"
    },
    paymentHeading:{
      position:"relative",
      top:"17px"
    },
    textAlignEnd:{
      textAlign:"end"
    },
    alignSelfEnd:{
      alignSelf:"flex-end"
    }
  })
)(BookBoatPaymentToast);
