//@ts-nocheck
import React from "react";
import {
  withStyles,
  Grid,
  Typography,
  Button,
  createStyles,
} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import { success, arrow } from "./assets";
import ForgotPasswordSendLinkController,{ Props } from "./ForgotPasswordSendLinkController.web";
import  withAlertBox  from "../../../components/src/withAlertBox.Web";
import  withToast  from "../../../components/src/withSnackBar.Web";
import  withLoader  from "../../../components/src/withLoader.Web";
class ForgotPasswordSendLink extends ForgotPasswordSendLinkController {
  constructor(props: Props) {
    super(props);
  }
  componentDidMount() {
    const dialogData = this.props.dataToPass;
    this.setState({
      email : dialogData.email
    })
  }
  render() {
    const { classes } = this.props;
    return (
      <DialogContent className={classes.dialogStyles}>
        <Grid container justify="center" spacing={2}>
          <Grid item xs={12}>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <img src={success} />
            </div>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="h6"
              gutterBottom
              align="center"
              className={classes.confirmationText}
            >
              Verification Link Sent!
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography align="center" className={classes.confirmationSubTitle}>
              Check your inbox for a verification link to reset your password.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                color="secondary"
                variant="contained"
                endIcon={<img src={arrow} style={{ height: "10px" }}/>}
                onClick={() => {
                  this.resendLink({email : this.state.email});
                }}
              >
                Resend Link
              </Button>
            </div>
          </Grid>
        </Grid>
      </DialogContent>
    );
  }
}
const ForgotPasswordSendLinkAlertBox = withAlertBox(ForgotPasswordSendLink);
const ForgotPasswordSendLinkLoader = withLoader(ForgotPasswordSendLinkAlertBox);
const ForgotPasswordSendLinkToast = withToast(ForgotPasswordSendLinkLoader)
export default withStyles((theme) =>
  createStyles({
    confirmationText: {
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
      fontFamily: "Montserrat",
      fontSize: "20px",
      fontWeight: 600,
      lineHeight: 1.15,
      color: "#1b4050",
    },
    confirmationSubTitle: {
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: 600,
      lineHeight: 1.38,
      color: "#1b4050",
    },
    dialogStyles :{
      width : '538px'
    }
  })
)(ForgotPasswordSendLinkToast);
