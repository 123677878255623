import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { dialogBoxProps  } from "../../../components/src/withDialog.web";
import { withAlertBoxProps } from "../../../components/src/withAlertBox.Web";
import { withToastProps } from "../../../components/src/withSnackBar.Web";
import { withLoaderProps } from "../../../components/src/withLoader.Web";
import ContactOwner from "./ContactOwner.web";
export const configJSON = require("./config");

export type Props = RouterProps & dialogBoxProps & withAlertBoxProps & withToastProps & withLoaderProps & {
  id: string;
  // Customizable Area Start
  // Customizable Area End
};

interface S {
  // Customizable Area Start
  authToken : String,
  boatId : String,
  boatDetails : any;
  lat : any,
  lng : any,
  openImageView : boolean,
  currImg : number,
  compareBoatList : Array<any>
  loading : boolean,
  like : boolean
  actionType : any,
  duration : string,
  locationType : any
  similorBoats : Array<any>
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class SelectedBoatDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  getBoatDetailsApiCallId : String = "";
  likeaboatCallId : String = "";
  getSimilorBoatDetailsApiCallId : String = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
      // Customizable Area End
    ];

    this.state = {
      authToken : '',
      boatId : '',
      boatDetails : {},
      lat : null,
      lng : null,
      openImageView : false,
      currImg : 0,
      compareBoatList : [],
      loading : true,
      like : false,
      actionType : "",
      duration : 'half_day',
      locationType : '',
      similorBoats : [       
      ]
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.getBoatDetailsApiCallId) {
        this.props.hideLoader();
        if (responseJson && responseJson?.data) {
          this.setState({
              boatDetails : responseJson?.data,
              lat : responseJson?.data?.attributes?.address.latitude || null,
              lng : responseJson?.data?.attributes?.address.longitude || null,
              locationType:responseJson?.data.attributes.location_type || null, 
              like : !!responseJson?.data.attributes?.like_boat
          },async ()=>{
            if(this.state.actionType && this.state.boatId && this.state.authToken){
              if(this.state.actionType === 'LikeABoat'){
                 await StorageProvider.remove("actionType");
                 await StorageProvider.remove("boatId"); 
                 this.likeAndUnLikeAboat(this.state.like) 
              } else if(this.state.actionType === 'ContactOwner'){
                 await StorageProvider.remove("actionType");
                 await StorageProvider.remove("boatId"); 
                 this.openContactOwnerDialog(); 
              } else if(this.state.actionType === 'bookBoat'){
                await StorageProvider.remove("actionType");
                 await StorageProvider.remove("boatId"); 
                 const { history } = this.props;
                 history.push({ pathname: '/bookBoat', state: { boatId: this.state.boatId } }); 
              }
            }
          })
        } else {
          this.parseApiErrorResponse(responseJson);
        }
        } else if(apiRequestCallId === this.likeaboatCallId){
          this.props.hideLoader();
        if (responseJson && responseJson?.data?.id) {
          
        } else {
          this.parseApiErrorResponse(responseJson);
        } 
        } else if(apiRequestCallId === this.getSimilorBoatDetailsApiCallId){
          this.props.hideLoader();
          if (responseJson && responseJson?.data) {
              this.setState({
                similorBoats : responseJson?.data
              })
          } else {
            this.parseApiErrorResponse(responseJson);
          } 
        } 
    } else if (getName(MessageEnum.AlertMessage) === message.id) {
      const title = message.getData(getName(MessageEnum.AlertTitleMessage));
      var AlertBodyMessage = message.getData(
        getName(MessageEnum.AlertBodyMessage)
      );
      const msg = await this.props.showAlert({title : 'Error',message : AlertBodyMessage})
      if(msg === 'Your session has expired, please login again.' || msg === 'Invalid token'){
        await StorageProvider.remove("authToken")
        await StorageProvider.remove("accountInfo")  
        this.props.history.push("/basicAuth/login")
      }
    }
  }
  getBoatDetails() {
    this.props.showLoader();
    const headers = {
      "content-type": configJSON.ApiContentType,
      token : this.state.authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getBoatDetailsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getBoatDetailPoint}/${this.state.boatId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  likeAndUnLikeAboat(like:boolean) {
    this.props.showLoader();
    const headers = {
      "Content-Type": configJSON.ApiContentType,
      "token" : this.state.authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.likeaboatCallId = requestMessage.messageId;
    const httpBody = {
      "data": {
          "type": like ?  "like" : "dislike",
          "attributes": {
              "boat_id": this.state.boatId
          }
      }
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_catalogue/like_boats`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPOST
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  openContactOwnerDialog = () => {
    this.props
      .openDialogBox({
        title: "Send Message to",
        withCustomDialog: false,
        renderedComponent: ContactOwner,
        dataToPass: { boat: this.state.boatDetails },
        catchOnCancel: true,
      })
      .then((data : any) => {
        if (data) {
          this.props.showToast({
            type: "success",
            message: "Message sent successfully",
          });
        }
      });
  };
  getSimilorBoatDetails() {
    this.props.showLoader();
    const headers = {
      "content-type": configJSON.ApiContentType,
      token : this.state.authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSimilorBoatDetailsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${"bx_block_catalogue/boats/similar_boats?id="}${this.state.boatId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
}
